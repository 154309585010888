<script>
import BaseImage from '@/components/BaseImage'

import BlackIcon from '@/assets/blacklist-sm.png'
import FBIcon from '@/assets/icon-fb.png'
import LineIcon from '@/assets/icons/line.png'

export default {
  name: 'BadgeImage',
  components: { BaseImage },
  props: {
    src: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: true
    },
    badgeType: {
      type: String,
      required: true
    },
    showBadge: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    badgeImg () {
      const mappingType = {
        'blacklist': BlackIcon,
        'fb': FBIcon,
        'line': LineIcon
      }

      return mappingType[this.badgeType] || false
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
