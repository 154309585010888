<script>
import { mapGetters } from 'vuex'

export default {
  skipMiddleware: ['getAuth', 'checkAuth'],

  name: 'Permission',
  data () {
    return {
      permissions: [],
      permissionsIcon: {
        email: 'fa-envelope',
        pages_messaging: 'fa-facebook-messenger'
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['apiUrl']),
    loginPath () {
      if (this.$route.query.redirect) {
        return `${this.apiUrl}/auth/facebook?redirect=${this.$route.query.redirect}`
      }

      return `${this.apiUrl}/auth/facebook?redirect=${location.origin}`
    }
  },
  mounted () {
    this.permissions = this.$route.query.missing.split(',')
  },
  methods: {
    reAuth () {
      location.href = this.loginPath
    },
    goHome () {
      location.href = this.$VUE_APP_BUYER_DOMAIN_NAME
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
