import axios from '@/plugins/http'

export function changePassword (payload) {
  const api = '/callback/user/reset-password'

  return axios.post(api, payload)
}

export function checkToken (token) {
  const api = `/api/v1/users/reset-password-token/${token}`
  return axios.get(api)
}
