import * as loadingState from '@/constants/loadingState'

import { fetchPaymentBill } from '../apis/bill'

export const types = {
  FETCH_PAYMENT_BILL: 'FETCH_PAYMENT_BILL',
  FETCH_PAYMENT_BILL_SUCCESS: 'FETCH_PAYMENT_BILL_SUCCESS',
  FETCH_PAYMENT_BILL_FAILED: 'FETCH_PAYMENT_BILL_FAILED'
}

const INIT_DATA = {
  paymentBill: [],

  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  paymentBill: state => state.paymentBill,

  billInfoLoadingState: state => state.loadingState
}

const mutations = {
  [types.FETCH_PAYMENT_BILL] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.FETCH_PAYMENT_BILL_SUCCESS] (state, response) {
    state.loadingState = loadingState.READY

    const { data } = response.data
    state.paymentBill = data
  },

  [types.FETCH_PAYMENT_BILL_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  }
}

const actions = {
  fetchPaymentBill ({ commit }, storeId) {
    commit(types.FETCH_PAYMENT_BILL)

    return fetchPaymentBill(storeId)
      .then(response => commit(types.FETCH_PAYMENT_BILL_SUCCESS, response))
      .catch(err => {
        commit(types.FETCH_PAYMENT_BILL_FAILED, err)
        throw err
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
