import axios from '@/plugins/http.js'

export default class Sku {
  get = async skuId => {
    try {
      const api = `/api/v1/skus/${skuId}/product`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }
}
