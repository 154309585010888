import axios from '@/plugins/http'

export function parentBind (token) {
  const api = '/api/v1/stores/parent-bind'
  return axios.post(api, { token })
}

export function getParent (token) {
  const api = `/api/v1/stores/parent-store/${token}`
  return axios.get(api)
}
