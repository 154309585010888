import _ from 'lodash'
import store from '@/store'
import * as REGIONS from '@/constants/regions'

export default async (to, from, next) => {
  // 已登入 且 尚未取得 currentStore 資料
  if (store.getters['Me/isLogin'] && !store.getters['Me/currentStore']) {
    store.dispatch('setLoading', true)

    await store.dispatch('Me/fetchStores')
    if (store.getters['Me/stores'].length > 0) {
      await store.dispatch('Me/fetchChildStores')
      await store.dispatch('Me/fetchOwnedStores')
    }

    const toStore = _.get(to, 'params.store', '')
    await store.dispatch('Me/checkCurrentStore', toStore)
    await store.dispatch('Me/fetchCurrentStore')
    await store.dispatch('Me/checkIsFBStore')
    await store.dispatch('Me/fetchFamilyCarrier', 'family-business')
    await store.dispatch('Me/fetchGMOStatus')

    // 後收 - 帳務帳單
    if (
      store.getters['Me/storeName'] &&
      store.getters['Me/isCurrentStoreOwner'] &&
      store.getters['Me/region'] === REGIONS.TW
    ) {
      await store.dispatch('AccountManagement/fetchPaymentBill', store.getters['Me/storeName'])
    }

    store.dispatch('setLoading', false)
  }

  next()
}
