<script>

export default {
  name: 'DialogChangePaymentNotice',

  methods: {
    close () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogChangePaymentNotice.pug"></template>
<style lang="scss" scoped>
  .fa-hand-holding-usd {
    font-size: 5rem;
  }

</style>
