<script>
import Orderer from './components/Orderer'
import Payment from './components/Payment'
import ShippingAddress from './components/ShippingAddress'
import ShoppingDetails from './components/ShoppingDetails'
import { mapGetters, mapActions, mapState } from 'vuex'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import { ORDERER_TYPE } from '@/constants/orderManual'

export default {
  name: 'OrderManual',
  components: { Orderer, Payment, ShippingAddress, ShoppingDetails },
  data: function () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName']),
    ...mapState('OrderManual/ShippingAddress', [
      'shippingType',
      'storeType',
      'storeId'
    ]),
    ...mapState('OrderManual/ShoppingDetails', ['selectedProducts']),
    ...mapState('OrderManual/Orderer', ['ordererType', 'memberSlug'])
  },
  destroyed () {
    this.resetAll()
  },
  methods: {
    ...mapActions('OrderManual', ['createManualOrder', 'resetAll']),
    toOrderList () {
      this.$router.push({ path: `/${this.storeName}/order` })
    },
    async validateAllForms () {
      let isDataValid = true
      let errorMessage = ''
      // 檢查超商地址
      if (
        [SHIPPING_TYPES.SEVEN, SHIPPING_TYPES.FAMILY].includes(
          this.shippingType
        )
      ) {
        if (this.shippingType !== this.storeType || !this.storeId) {
          errorMessage += `${this.$t('OrderManual.Shipping.cvs_error')}<br />`
          isDataValid = false
        }
      }

      // 檢查是否有選擇商品
      if (this.selectedProducts.length === 0) {
        errorMessage += `${this.$t('OrderManual.DialogUpdateOrder.at_least_one')}<br />`
        isDataValid = false
      }

      // 檢查會員
      if (
        this.ordererType === ORDERER_TYPE.SelectFromMembers &&
        !this.memberSlug
      ) {
        errorMessage += `${this.$t('OrderManual.Orderer.selectMember')}<br />`
        isDataValid = false
      }

      // 檢查剩餘欄位
      const isOrdererValid = await this.$refs.Orderer.validateForm()
      const isShippingInfoValid =
        await this.$refs.ShippingAddress.validateForm()
      const isShippingAddressValid =
        await this.$refs.ShippingAddress.validateAddress()

      if (isOrdererValid && isShippingInfoValid && isShippingAddressValid && isDataValid) {
        this.isLoading = true
        try {
          await this.createManualOrder()
          this.isLoading = false
          this.toOrderList()
        } catch (error) {
          this.isLoading = false
        }
      } else {
        errorMessage += `${this.$t('OrderManual.DialogUpdateOrder.unexpected_error')}<br />`

        this.$message({
          message: errorMessage,
          type: 'error',
          showClose: true,
          duration: 6000,
          dangerouslyUseHTMLString: true
        })
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
