<script>
import dateDifferenceInSeconds from 'date-fns/difference_in_seconds'
import { mapGetters, mapActions } from 'vuex'

import ApiTime from '@/api/time'
import { elapsedTime } from '@/utils/timeOperation'

const BIDDING_DEFAULT_LIMIT = 30 // 喊單模組 - 每人限購數量

export default {
  name: 'BiddingForm',
  data () {
    return {
      isEdit: false,
      isLoading: false,
      showLimitTooltip: false,
      bidForm: {
        title: '',
        quantity: null,
        price: null,
        limit: BIDDING_DEFAULT_LIMIT
      },
      bidRules: {
        title: [
          { message: '必填', trigger: 'blur', required: true }
        ],
        quantity: [
          { type: 'number', message: '必填', trigger: 'blur', required: true }
        ],
        price: [
          { type: 'number', message: '請輸入數字', trigger: 'blur', required: false }
        ]
      },
      nowTime: null
    }
  },
  computed: {
    ...mapGetters('Post', ['postId']),
    ...mapGetters('Post', ['hasCurrentBidding', 'currentBidding', 'currentBiddingAnalysed']),

    biddingAnalysed () {
      return this.currentBiddingAnalysed || _.get(this.currentBidding, 'skus[0].lock_quantity', 0)
    },

    getElapsedTime () {
      if (this.currentBidding && this.nowTime) {
        const startedAt = this.currentBidding.started_at || this.currentBidding.created_at
        return elapsedTime(startedAt, this.nowTime)
      } else {
        return `00:00`
      }
    }
  },

  mounted () {
    if (this.hasCurrentBidding) {
      this.initData()
      this.handleElapsedTime()
    }
  },

  methods: {
    ...mapActions('Post', ['createBidding', 'updateBidding']),

    initData () {
      this.bidForm = {
        title: this.currentBidding.title,
        quantity: this.currentBidding.skus[0].available_quantity,
        price: this.currentBidding.skus[0].price * 1,
        limit: this.currentBidding.limit
      }
    },
    async validateForm () {
      try {
        await this.$refs['bidForm'].validate()
        return true
      } catch (error) {
        return false
      }
    },
    async handleStart () {
      const isValid = await this.validateForm()
      if (!isValid) return

      this.isLoading = true
      this.isEdit = false

      this.createBidding({
        postId: this.postId,
        postData: this.bidForm
      })
        .then(() => {
          this.handleElapsedTime()
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    toggleEdit (flag) {
      this.isEdit = flag || !this.isEdit
      this.initData()
    },

    async handleSave () {
      const isValid = await this.validateForm()
      if (!isValid) return

      const modifiedQuantity = this.bidForm.quantity - this.currentBidding.skus[0].available_quantity

      const updateData = {
        title: this.bidForm.title,
        modified_quantity: modifiedQuantity,
        price: this.bidForm.price,
        limit: this.bidForm.limit
      }

      this.isLoading = true

      this.updateBidding({
        postId: this.postId,
        biddingId: this.currentBidding.id,
        updateData
      })
        .then(() => {
          this.isEdit = false
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    handleEnd () {
      this.$emit('toggleBiddingBeforeEndDialog', true)
    },

    // 活動時間
    async handleElapsedTime () {
      const apiTime = new ApiTime()
      const { 'server_time': serverTimeStr } = await apiTime.get()

      const diffSeconds = dateDifferenceInSeconds(new Date(), serverTimeStr)

      this.timeNum = setInterval(() => {
        this.nowTime = new Date()
        this.nowTime.setSeconds(this.nowTime.getSeconds() - diffSeconds)
      }, 1000)
    }
  }
}
</script>

<template lang="pug" src="./BiddingForm.pug"></template>
<style lang="scss" src="./BiddingForm.scss" scoped></style>
