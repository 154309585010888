import Vue from 'vue'
import store from '@/store'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-TW'
import langZhTW from '@/lang/zh-TW'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale
  },
  'zh-TW': {
    ...zhLocale,
    ...langZhTW
  }
}

const i18n = new VueI18n({
  locale: 'zh-TW',
  // fallbackLocale: 'zh-TW',
  silentFallbackWarn: true,
  messages
})

/**
 * @param {String} locale
 */
export async function loadMessages (locale) {
  const messages = await import(
    /* webpackChunkName: "lang-[request]" */ `@/lang/${locale}`
  )
  const elLocale = await import(
    /* webpackChunkName: "lang-[request]" */ `element-ui/lib/locale/lang/${locale}`)

  document.documentElement.lang = locale
  i18n.setLocaleMessage(locale, {
    ...i18n.getLocaleMessage(locale),
    ...elLocale.default,
    ...messages
  })

  if (i18n.locale !== locale) {
    i18n.locale = locale
  }
}

;(async function () {
  await loadMessages(store.getters['Lang/locale'])
})()

export default i18n
