<script>
import KerryThTip from '@/components/KerryThTip'

export default {
  name: 'DialogKerryThShipment',
  components: { KerryThTip },
  data () {
    return {
      isBtnLoading: false,
      method: 'drop_off'
    }
  },
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    submit () {
      this.$emit('submit', this.method)
    }
  }
}
</script>

<template lang="pug" src="./DialogKerryThShipment.pug"></template>
<style lang="scss" src="./DialogKerryThShipment.scss" scoped></style>
