import FileSaver from 'file-saver'

import * as loadingState from '@/constants/loadingState'

import { exportPostBuyerList } from '../../apis/post'

export const types = {
  EXPORT_BUYER_LIST: 'EXPORT_BUYER_LIST',
  EXPORT_BUYER_LIST_SUCCESS: 'EXPORT_BUYER_LIST_SUCCESS',
  EXPORT_BUYER_LIST_FAILED: 'EXPORT_BUYER_LIST_FAILED'
}

const INIT_DATA = {
  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  exportLoadingState: state => state.loadingState
}

const mutations = {
  [types.EXPORT_BUYER_LIST] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.EXPORT_BUYER_LIST_SUCCESS] (state, payload) {
    state.loadingState = loadingState.READY
  },

  [types.EXPORT_BUYER_LIST_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  }
}

const actions = {
  downloadPostBuyerList ({ getters, commit }) {
    commit(types.EXPORT_BUYER_LIST)

    return exportPostBuyerList(getters.postId)
      .then((response) => {
        commit(types.EXPORT_BUYER_LIST_SUCCESS)
        FileSaver.saveAs(response.data, 'buyer-list.xlsx')
      })
      .catch(err => {
        commit(types.EXPORT_BUYER_LIST_FAILED, err)
        throw err
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
