<script>
import { mapGetters } from 'vuex'

import { normalizeImage } from '@/utils/normalizeImage'

export default {
  name: 'DialogPickTickets',

  props: {
    selectedProducts: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dialogVisible: false,
      activeName: 'first',
      searchRule: {
        keyword: ''
      },
      products: [],
      selectedItem: [],
      currentPage: 1,
      lastPage: 1
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'currencySymbol']),
    filterSearchRule () {
      return Object.keys(this.searchRule).reduce((prev, key) => {
        if (this.searchRule[key]) prev[key] = this.searchRule[key]
        return prev
      }, {})
    },
    normalizeProducts () {
      if (this.products === null) return null

      const result = this.products.map(item => {
        // 售價
        const maxPrice = Math.floor(item.max_price)
        const minPrice = Math.floor(item.min_price)
        let priceRange
        if (maxPrice !== minPrice) {
          priceRange = `${this.currencySymbol} ${minPrice} ~ ${maxPrice}`
        } else {
          priceRange = `${this.currencySymbol} ${maxPrice}`
        }

        return Object.assign(item, { priceRange })
      })

      return result
    },
    filterProduct () {
      return this.normalizeProducts
        .filter(product => !this.selectedProducts.some(item => item.id === product.id))
        .filter(product => !this.selectedItem.some(item => item.id === product.id))
    }
  },
  created () {
    this.fetchProducts()
  },
  methods: {
    async fetchProducts (isFirst = true) {
      // @todo 調整
      // TODO：搜尋的時機點，現在混合「先搜自己 + API撈回來的」
      try {
        let api = `/api/v1/stores/${this.storeName}/tickets`

        const params = Object.assign(this.filterSearchRule, {
          page: this.currentPage
        })

        const { data: { data: products, meta } } = await this.axios.get(api, { params })

        if (isFirst) this.products = []

        this.lastPage = meta.last_page
        this.products.push(...products)
      } catch (e) {
        console.error(e)
      }
    },
    infiniteLoad () {
      if (this.currentPage < this.lastPage) {
        this.currentPage += 1
        this.fetchProducts(false)
      }
    },
    handleSearch: _.debounce(function () {
      this.currentPage = 1
      this.fetchProducts(true)
    }, 500),
    getImgUrl (data) {
      if (!data.images || data.images.length === 0) return
      return normalizeImage(data.images[0].url, 40)
    },
    selectItem (item) {
      this.selectedItem.push(item)
      // 當畫面上的商品數少於七個，就 load 剩下商品
      const currentItem = this.products.length - this.selectedItem.length
      if (currentItem < 7) this.infiniteLoad()
    },
    selectUrlItem (item) {
      this.selectedItem.push(Object.assign(item, { selectSource: 'url' }))
    },
    removeItem (item) {
      this.selectedItem = this.selectedItem.filter(selected => selected.id !== item.id)
    },
    handlePickProduct () {
      this.$emit('handlePickProduct', this.selectedItem)
      this.handleCloseDialog()
    },
    handleCloseDialog () {
      this.activeName = 'first'
      this.toggle(false)
    },
    handleTabClick () {
      this.searchRule.keyword = ''
    },
    toggle (isOpen = null) {
      this.selectedItem = []
      this.dialogVisible = isOpen === null ? !this.dialogVisible : isOpen
    },
    goCreateTicket () {
      this.$router.push(`/${this.storeName}/ticket`)
    }
  }
}
</script>

<template lang="pug" src="./DialogPickTickets.pug"></template>
<style lang="scss" src="./DialogPickTickets.scss" scoped></style>
