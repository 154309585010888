<script>
import ApiProduct from '@/api/product'
import AppAlert from '@/components/AppAlert'
import { normalizeImage } from '@/utils/normalizeImage'
import { mapState } from 'vuex'
import VueTypes from 'vue-types'
import { PRODUCT_ACTION_ADD, PRODUCT_ACTION_EDIT } from '@/constants/orderManual'

export default {
  name: 'DialogUpdateOrder',
  components: { AppAlert },
  props: {
    buttonAction: VueTypes.string.def(PRODUCT_ACTION_ADD)
  },
  data () {
    return {
      PRODUCT_ACTION_ADD,
      PRODUCT_ACTION_EDIT,
      allProducts: [],
      products: []
    }
  },
  computed: {
    ...mapState('OrderManual/ShoppingDetails', ['selectedProducts']),
    title () {
      if (this.buttonAction === PRODUCT_ACTION_EDIT) {
        return this.$t('OrderManual.DialogUpdateOrder.title')
      }
      return this.$t('OrderManual.DialogUpdateOrder.title_new')
    }
  },
  mounted () {
    this.products = _.cloneDeep(this.selectedProducts)
  },
  methods: {
    normalizeImage,
    async remoteMethod (query) {
      if (!query) {
        this.allProducts = []
        return
      }

      const apiProduct = new ApiProduct()
      const params = query ? { keyword: query } : null

      const { data: allProducts } = await apiProduct.list(params, true)
      this.allProducts = allProducts
    },
    getImageUrl (data) {
      if (!data.images || data.images.length === 0) return
      return normalizeImage(data.images[0].url, 30)
    },
    handleSelect (item) {
      const newItem = _.cloneDeep(item)
      const skuLeft = newItem.skus
      for (const sku of skuLeft) {
        sku.price = parseInt(sku.price)
      }
      newItem.sku = skuLeft[0]
      newItem.quantity = 1
      newItem.price = parseInt(skuLeft[0].price)
      newItem.sortKey = Math.random().toString()
      delete newItem['id']
      this.products.push(newItem)
      this.allProducts = []

      this.products.forEach(p => this.validateQuantityLimit(p))
    },

    validatePriceLimit (product) {
      if (product.price < 0) {
        product.hasPriceLimitError = true
      } else {
        product.hasPriceLimitError = false
      }
    },
    validateQuantityLimit (product) {
      const salableQuantity = _.get(product, 'sku.salable_quantity', Infinity)
      if (salableQuantity === 0) {
        product.hasQuantityLimitError = true
        return
      }

      const limitMin = 1
      const limitMax = salableQuantity

      if (limitMin <= product.quantity && product.quantity <= limitMax) {
        product.hasQuantityLimitError = false
      } else {
        product.hasQuantityLimitError = true
      }
    },
    handleDelete (item) {
      this.products = this.products.filter(x => {
        return x.sortKey !== item.sortKey
      })
    },
    changeSku (product) {
      product.price = product.sku.price
      product.quantity = 1
    },

    handleSubmit () {
      if (this.products.length === 0) {
        this.toggleDeleteNotice()
      } else {
        this.submit()
      }
    },
    toggleDeleteNotice () {
      this.$alert(this.$t('OrderManual.DialogUpdateOrder.at_least_one'), '', {
        confirmButtonText: this.$t('button.confirm'),
        callback: () => {}
      })
    },
    valiadateProduct () {
      let hasError = false
      let duplicateItem = []

      this.products.forEach(product => {
        this.validatePriceLimit(product)
        this.validateQuantityLimit(product)

        if (product.hasPriceLimitError || product.hasQuantityLimitError) {
          hasError = true
        }

        const sameSku = this.products
          .filter(x => x.sku.id === product.sku.id)
          .filter(x => x.price === product.price)

        if (sameSku.length > 1) {
          duplicateItem.push(product.name + ' ' + product.sku.name)
          hasError = true
        }
      })

      // 商品重複 Error Message
      if (duplicateItem.length) {
        const duplicateItemMsg = '<br><br>' + [...new Set(duplicateItem)].join('<br>')
        const message = this.$t('Order.DialogUpdateOrder.error_sku_duplication', { item: duplicateItemMsg })

        this.$message({
          dangerouslyUseHTMLString: true,
          showClose: true,
          message,
          type: 'error'
        })
      }
      return !hasError
    },
    submit () {
      if (!this.valiadateProduct()) return

      const items = this.products.map(product => {
        const item = {
          sku_name: product.sku.name,
          sku_id: product.sku.id,
          product_name: product.name || product.product_name,
          quantity: product.quantity,
          price: product.price,
          sortKey: product.sortKey,
          images: product.images,
          skus: product.skus,
          sku: product.sku,
          name: product.name
        }
        if (product.id) {
          item.id = product.id
        }
        if (product.images && product.images.length > 0) {
          item.image_id = product.images[0].id
        } else if (product.image) {
          item.image_id = product.image_id
        }
        return item
      })

      this.$emit('submit', items)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
