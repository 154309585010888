<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

import AppTable from '@/components/AppTable'

import imgEmpty from './images/empty-ticket@2x.png'
import columns from '../../utils/columns'

export default {
  name: 'TicketTable',
  components: {
    AppTable
  },

  props: {
    data: VueTypes.array
  },

  data: () => ({
    imgEmpty
  }),

  computed: {
    ...mapGetters('Me', ['currencySymbol']),
    columns () {
      return columns.map(column => ({
        ...column,
        name: this.$t(column.name)
      }))
    }
  },

  methods: {
    handleEdit (item) {
      this.$emit('handleEdit', item)
    },
    handleDelete (item) {
      this.$emit('handleDelete', item)
    },
    handleCreateTicket () {
      this.$emit('handleCreateTicket')
    }
  }
}
</script>

<template lang="pug" src="./TicketTable.pug"></template>
<style lang="scss" src="./TicketTable.scss" scoped></style>
