<script>
import { mapGetters, mapActions } from 'vuex'
import VueTypes from 'vue-types'

import * as loadingState from '@/constants/loadingState'

export default {
  name: 'Memo',
  props: {
    orderId: VueTypes.number.isRequired
  },
  data () {
    return {
      isEditing: false,
      memoInput: ''
    }
  },
  computed: {
    ...mapGetters('OrderDetail', ['memoData', 'memoLoadingState']),
    isLoading () {
      return this.memoLoadingState === loadingState.LOADING
    }
  },
  mounted () {
    this.getMemo(this.orderId)
  },
  methods: {
    ...mapActions('OrderDetail', ['getMemo', 'updateMemo']),
    startEdit () {
      this.memoInput = this.memoData
      this.isEditing = !this.isEditing
    },
    cancelEdit () {
      this.isEditing = !this.isEditing
    },
    async updateOrderMemo () {
      const payload = { orderId: this.orderId, memo: this.memoInput.trim() }
      try {
        await this.updateMemo(payload)
        await this.getMemo(this.orderId)
        this.$message.success(this.$t('Order.Show.success_memo'))
        this.isEditing = !this.isEditing
      } catch {
        this.$message.error(this.$t('Order.Show.error_memo'))
      }
    }
  }
}
</script>

<template lang="pug" src="./Memo.pug"></template>
<style lang="scss" src="./Memo.scss" scoped></style>
