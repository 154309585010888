import axios from '@/plugins/http.js'

export default class OrderMessage {
  // 列出訂單留言
  list = async (id, all = false) => {
    const api = `/api/v1/orders/${id}/messages`
    const params = all ? { all: '', role: 'seller' } : { role: 'seller' }

    try {
      const {
        data: { data }
      } = await axios.get(api, { params })

      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }

  // 建立訂單留言
  create = async (id, message) => {
    const api = `/api/v1/orders/${id}/messages`
    const params = { message, role: 'seller' }

    try {
      await axios.post(api, params)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }
}
