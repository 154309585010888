import * as LOCALES from '@/constants/locales'

export default (language) => {
  const languages = [LOCALES.TW, LOCALES.JP]
  const fullLanguage = language
  const splitLanguage = language.split('-')[0]
  let locale = LOCALES.JP

  if (languages.includes(fullLanguage)) {
    locale = fullLanguage
  } else if (languages.includes(splitLanguage)) {
    locale = splitLanguage
  } else if (languages.find(x => x.includes(splitLanguage))) {
    locale = languages.find(x => x.includes(splitLanguage))
  }

  return locale
}
