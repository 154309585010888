<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

import AppXBox from '@/components/AppXBox'

import { PAID } from '@/constants/paymentStatus'

export default {
  name: 'DialogOfflineAtm',
  components: { AppXBox },
  props: {
    payment: VueTypes.object.def({}),
    payments: VueTypes.array.def([]),
    paymentStatus: VueTypes.string.def('init'),
    amountPayable: VueTypes.string.def('0'),
    bankReceipt: VueTypes.object.def({})
  },
  data () {
    return {
      PAID,

      form: {
        paid_at: null,
        bank_name: '',
        bank_account: '',
        memo: '',
        has_mistake: false
      },
      formPaymentStatus: ''
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol']),

    editDisabled () {
      if (this.payments.length) {
        // 若目前 payment 不是 payments 最新一筆的話，
        // 代表有新的付款方式，所以不可再編輯
        return this.payment.id !== this.payments[this.payments.length - 1].id
      } else {
        return false
      }
    }
  },
  mounted () {
    this.formPaymentStatus = this.paymentStatus
    if (!this.bankReceipt) return
    this.form = {
      ...this.bankReceipt,
      has_mistake: !!this.bankReceipt.has_mistake,
      memo: this.bankReceipt.extra && this.bankReceipt.extra.memo
    }
  },
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    submit () {
      const formFields = ['paid_at', 'bank_name', 'bank_account', 'has_mistake', 'memo']
      const payload = {
        bankReceipt: _.pick(this.form, formFields),
        paymentStatus: this.formPaymentStatus
      }
      this.$emit('submit', payload)
    }
  }
}
</script>

<template lang="pug" src="./DialogOfflineAtm.pug"></template>
<style lang="scss" src="./DialogOfflineAtm.scss" scoped></style>
