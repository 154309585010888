<script>
import { mapGetters } from 'vuex'
import AccountInfo from './components/AccountInfo'
import TransactionBalanceTable from '../TransactionBalanceTable'
import TransactionPendingTable from '../TransactionPendingTable'

export default {
  name: 'Transaction',
  components: { TransactionBalanceTable, TransactionPendingTable, AccountInfo },
  data () {
    return {
      activeTabName: 'first',
      showWarning: false,
      accountVerified: null,
      infoVisible: false
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore', 'isPhoneVerfied']),
    showInfoTrigger () {
      if (this.currentStore.region !== 'TW') return false

      return this.isPhoneVerfied
    },
    showTooltip () {
      if (!this.currentStore || !this.currentStore.region) return false

      return this.currentStore.region === 'TW'
    }
  },
  methods: {
    toSettingSeller () {
      // 前往賣家設定
      this.$emit('updateActiveMenu', {
        name: 'menu_seller',
        component: 'SettingSeller'
      })
    },
    showInfo () {
      this.infoVisible = true
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
