<script>
import VueTypes from 'vue-types'
import { mapActions } from 'vuex'

export default {
  name: 'HoverElement',
  props: {
    setting: VueTypes.string.isRequired
  },
  methods: {
    ...mapActions('LiveSetting', ['updateHoverElement']),
    updateHover (key, value) {
      this.updateHoverElement({ key, value })
    }
  }
}
</script>

<template lang="pug">
.hoverElement(
  @mouseover="updateHover(setting, true)"
  @mouseleave="updateHover(setting, false)")
  slot
</template>

<style lang="scss" scoped>
.hoverElement {
  @apply px-4 py-3;
  &:hover {
    @apply bg-gray-300;
  }
}
</style>
