<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'BiddingResultDialog',

  computed: {
    ...mapGetters('Post', ['selectBidding', 'selectBiddingWinners']),

    normalizeComments () {
      return this.selectBiddingWinners.map(item => {
        const commentId = item.comment_id
        const fromName = item.comment.from_name
        const message = item.comment.message
        const originalTime = item.comment.original_time
        const quantity = item.quantity

        return { commentId, fromName, message, originalTime, quantity }
      })
    },
    total () {
      return this.selectBiddingWinners.reduce((prev, current) => {
        return prev + current.quantity
      }, 0)
    }
  },

  methods: {
    ...mapActions('Post', ['clearSelectBidding']),

    close () {
      this.clearSelectBidding()
    }
  }
}
</script>

<template lang="pug" src="./BiddingResultDialog.pug"></template>
<style lang="scss" src="./BiddingResultDialog.scss" scoped></style>
