import axios from '@/plugins/http'

export const getStreamInfo = (postId) => (
  axios.get(`/api/v1/liveweb/posts/${postId}/stream-info`)
)

export const getReplayInfo = postId => (
  axios.get(`/api/v1/liveweb/posts/${postId}/replay-stream-info`)
)

export const getViewersCount = postId => (
  axios.get(`/api/v1/liveweb/posts/${postId}/viewers-count`)
)
