<script>
import dateDifferenceInSeconds from 'date-fns/difference_in_seconds'
import { mapGetters, mapActions } from 'vuex'

import ApiTime from '@/api/time'
import { LIVE } from '@/constants/liveStatus'

import { elapsedTime } from '@/utils/timeOperation'

import defaultImgGift from '@/assets/post/default-img-gift.png'

const DRAW_RANGE = {
  ALL: 'all',
  PARTIAL: 'partial'
}

const DRAW_STATUS = {
  INIT: 'init',
  STARTED: 'started'
}

export default {
  name: 'DrawForm',

  data () {
    return {
      DRAW_STATUS,
      isEdit: false,
      isLoading: false,
      isUploadImage: false,
      isSelectedProduct: true,
      formDraw: {
        range: 'all',
        image: null,
        image_id: null,
        title: '',
        keyword: '',
        quality: null,
        free_shipping: false
      },
      nowTime: null
    }
  },

  computed: {
    ...mapGetters('Me', ['token', 'imageUploadUrl']),

    ...mapGetters('Post', ['celebrityToken', 'postId', 'liveStatus']),
    ...mapGetters('Post', ['selectDraw']),

    selectDrawValue () {
      return [
        { value: 'all', label: this.$t('Post.Show.ShowActivityNew.draw_immediately') }, // 所有留言抽獎
        { value: 'partial', label: this.$t('Post.Show.ShowActivityNew.draw_range') } // 區間抽獎
      ]
    },
    rules () {
      return {
        image: [
          { required: false, message: this.$t('Post.Show.ShowActivityNew.rules.image'), trigger: 'blur' }
        ],
        title: [
          { required: true,
            message: this.$t('Post.Show.ShowActivityNew.rules.title', { product: this.product }),
            trigger: 'blur'
          }
        ],
        quality: [
          { required: true,
            type: 'number',
            message: this.$t('Post.Show.ShowActivityNew.rules.quality_min', { product: this.product }),
            trigger: 'blur'
          }
        ],
        keyword: [
          { required: false, message: this.$t('Post.Show.ShowActivityNew.rules.keyword'), trigger: 'blur' }
        ]
      }
    },

    uploadHeaders () {
      return { Authorization: `Bearer ${this.token}` }
    },

    getDrawImage () {
      return this.formDraw.image
        ? this.formDraw.image.url
        : defaultImgGift
    },

    isActivityStarted () {
      return !!this.selectDraw && this.selectDraw.status === DRAW_STATUS.STARTED
    },

    disabledBtnSave () {
      return !!this.celebrityToken
    },

    disabledBtnStarted () {
      return this.liveStatus !== LIVE
    },

    displayBtnStartName () {
      return this.formDraw.range === DRAW_RANGE.ALL
        ? this.$t('Post.Show.ShowActivityNew.start_draw')
        : this.$t('button.start')
    },

    getElapsedTime () {
      if (this.selectDraw && this.nowTime) {
        const startedAt = this.selectDraw.started_at || this.selectDraw.created_at
        return elapsedTime(startedAt, this.nowTime)
      } else {
        return `00:00`
      }
    }
  },

  mounted () {
    if (this.selectDraw) {
      this.initData()
      this.handleElapsedTime()
    }
  },

  beforeDestroy () {
    this.clearSelectDraw()
  },

  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('Post', ['fetchPost']),
    ...mapActions('Post', [
      'fetchActivityDraws',
      'toggleDrawForm',
      'createDraw',
      'updateDraw',
      'endDraw',
      'setSelectDraw',
      'clearSelectDraw',
      'updateDrawAnimation'
    ]),

    getSelectDrawTooltip (item) {
      const tooltips = {
        [DRAW_RANGE.ALL]: this.$t('Post.Show.ShowActivityNew.info_text1'),
        [DRAW_RANGE.PARTIAL]: this.$t('Post.Show.ShowActivityNew.info_text2')
      }

      return tooltips[item]
    },

    /* ---- upload image ---- */
    handleUploadSuccess (response, file) {
      this.formDraw.image_id = response.id
      this.formDraw.image = response
      this.isUploadImage = false
    },
    handleFileError () {
      this.isUploadImage = false
      this.$message.error(this.$t('Post.Show.ShowActivityNew.message.upload_fail'))
    },
    handleBeforeUpload (file) {
      // console.log(file)
      const fileMB = file.size / (1024 ** 2)

      if (fileMB > 4) {
        this.$message.error(this.$t('Post.Show.ShowActivityNew.message.file_size'))
        return false
      }
      this.isUploadImage = true
    },
    handleDeleteImage () {
      this.formDraw.image_id = null
      this.formDraw.image = null
    },
    /* ---- end upload image ---- */

    initData () {
      this.formDraw = {
        range: this.selectDraw.range,
        image: _.get(this.selectDraw, 'skus[0].product.images[0]'),
        image_id: _.get(this.selectDraw, 'skus[0].product.images[0].id'),
        title: _.get(this.selectDraw, 'skus[0].product.name'),
        keyword: this.selectDraw.keyword,
        quality: _.get(this.selectDraw, 'skus[0].salable_quantity'),
        free_shipping: _.get(this.selectDraw, 'skus[0].free_shipping', false)
      }
    },

    async validateForm () {
      try {
        await this.$refs.form.validate()
        this.isSelectedProduct = true
        return true
      } catch (e) {
        this.isSelectedProduct = false
        return false
      }
    },
    getProduct () {
      const form = this.formDraw
      const product = {
        name: form.title,
        description: form.description || null,
        image_ids: form.image_id ? [form.image_id] : [],
        image_urls: ['https://lh3.googleusercontent.com/bnR-lJYAAej3jFMIbwbklvT27j1Swkt7yIgm6KYXJ0kmUgkWZt8WmugchOTeb92kpyGs-E5cBoavHPXjRV5EiL0=s40'],
        info: null,
        skus: [{
          name: this.$t('Post.Show.ShowActivityNew.draw_product'),
          price: form.price || 0,
          available_quantity: form.quality || null,
          is_giftable: true,
          free_shipping: form.free_shipping
        }],
        // 贈品、拍賣品，不需關鍵字
        no_keyword: true
      }

      return product
    },

    createDrawActivity (postId, status) {
      const drawData = {
        product: this.getProduct(),
        title: this.formDraw.title,
        keyword: this.formDraw.keyword,
        status,
        quota: this.formDraw.quality,
        range: this.formDraw.range,
        image_ids: this.formDraw.image_id ? [this.formDraw.image_id] : [],
        free_shipping: this.formDraw.free_shipping
      }
      return this.createDraw({ postId, postData: drawData })
    },

    updateDrawActivity (postId, status) {
      const skuId = this.selectDraw.skus[0].id
      const drawData = {
        sku_id: skuId,
        title: this.formDraw.title,
        keyword: this.formDraw.keyword,
        status,
        quota: this.formDraw.quality,
        range: this.formDraw.range,
        image_ids: this.formDraw.image_id ? [this.formDraw.image_id] : [],
        free_shipping: this.formDraw.free_shipping
      }
      return this.updateDraw({ postId, drawId: this.selectDraw.id, updateData: drawData })
    },

    async handleSubmitDraw (status) {
      this.isLoading = true

      // 驗證表單
      const valid = await this.validateForm()
      if (!valid) {
        this.isLoading = false
        return
      }

      // 建立或更新抽獎
      try {
        const drawFunc = this.selectDraw ? this.updateDrawActivity : this.createDrawActivity
        const response = await drawFunc(this.postId, status)
        const drawId = _.get(response, 'data.data.id')

        if (status === DRAW_STATUS.INIT) {
          // 草稿
          await Promise.all([
            this.fetchPost(),
            this.fetchActivityDraws(this.postId)
          ])

          this.toggleDrawForm(false)
        } else if (status === DRAW_STATUS.STARTED) {
          // 活動開始

          if (this.formDraw.range === DRAW_RANGE.ALL) {
            // 所有留言抽獎
            // 當抽獎一開始後，會馬上呼叫 endDraw 結束活動。
            // 刷新 活動資訊，等接收到 Pubnub 的 draw.ended 事件後，
            // 才觸發 fetchActivityDraws 事件。

            await this.handleEnd({ postId: this.postId, drawId })
          } else if (this.formDraw.range === DRAW_RANGE.PARTIAL) {
            // 區間抽獎
            await Promise.all([
              this.fetchPost(),
              this.fetchActivityDraws(this.postId)
            ])
            this.setSelectDraw(drawId)
            this.handleElapsedTime()
          }
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isLoading = false
      }
    },

    async handleEnd ({ postId, drawId }) {
      this.updateDrawAnimation(true)

      await this.endDraw({ postId, drawId })
      this.toggleDrawForm(false)
    },

    // 活動時間
    async handleElapsedTime () {
      const apiTime = new ApiTime()
      const { 'server_time': serverTimeStr } = await apiTime.get()

      const diffSeconds = dateDifferenceInSeconds(new Date(), serverTimeStr)

      this.timeNum = setInterval(() => {
        this.nowTime = new Date()
        this.nowTime.setSeconds(this.nowTime.getSeconds() - diffSeconds)
      }, 1000)
    }
  }
}
</script>

<template lang="pug" src="./DrawForm.pug"></template>
<style lang="scss" src="./DrawForm.scss" scoped></style>
