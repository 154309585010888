var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"productTable"},[_c('AppTable',{attrs:{"columns":_vm.columns,"data":_vm.getCurrentProducts,"expandValue":"skus","expandDisppearCol":"product"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"empty-placeholder"},[_c('img',{attrs:{"src":require("@/assets/empty-product.png")}}),_c('div',{staticClass:"empty-message"},[_vm._v(_vm._s(_vm.$t('Post.Products.no_product')))]),(!_vm.enabledMerchandise)?_c('el-tooltip',{staticClass:"item",attrs:{"content":_vm.$t('merchandise_disabled'),"placement":"top"}},[_c('div',[_c('el-button',{staticClass:"px-5",attrs:{"disabled":"","type":"primary","plain":""}},[_vm._v(_vm._s(_vm.$t('Post.Create.pick_product')))])],1)]):(!_vm.isEnterprise)?_c('el-button',{staticClass:"px-5",attrs:{"type":"primary","plain":""},on:{"click":_vm.openDialogPickProductVisible}},[_vm._v(_vm._s(_vm.$t('Post.Products.pick_product')))]):_vm._e()],1)]},proxy:true},{key:"top-header",fn:function(){return [_c('div',{staticClass:"search-bar w-full"},[_c('el-input',{staticClass:"search-input mr-3",attrs:{"placeholder":_vm.$t('input.search_placeholder')},on:{"input":_vm.handleChangeSearch},model:{value:(_vm.searchRule.keyword),callback:function ($$v) {_vm.$set(_vm.searchRule, "keyword", $$v)},expression:"searchRule.keyword"}}),_c('el-button',{staticClass:"px-2",attrs:{"type":"text"},on:{"click":_vm.handleRefresh}},[_c('i',{staticClass:"far fa-redo mr-2"}),_c('span',{staticClass:"text-black"},[_vm._v(_vm._s(_vm.$t('button.refresh')))])])],1)]},proxy:true},{key:"col-keyword",fn:function(){return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Top Center","placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(_vm.$t(_vm.keywordTip)))]),_c('i',{staticClass:"fas fa-info-circle ml-2 text-black cursor-pointer"})])]},proxy:true},{key:"td-product",fn:function(ref){
var ref_item = ref.item;
var name = ref_item.name;
var images = ref_item.images;
var skuItem = ref.skuItem;
var isExpandRow = ref.isExpandRow;
return [_c('div',{staticClass:"product-image flex-none"},[_c('BaseImage',{attrs:{"src":_vm.getImgUrl(images),"type":"product","size":"36"}})],1),_c('div',{staticClass:"product-name ml-3 mb-0",class:{ ellipsis: !isExpandRow }},[_vm._v(_vm._s(name))])]}},{key:"td-keyword",fn:function(ref){
var ref_item = ref.item;
var keyword = ref_item.keyword;
var skus = ref_item.skus;
var skuItem = ref.skuItem;
return [_c('div',{staticClass:"keyword"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(keyword)),(skus.length > 1)?_c('span',[_vm._v(_vm._s(skuItem.name))]):_vm._e()])])]}},{key:"td-sku",fn:function(ref){
var skuItem = ref.skuItem;
return [_c('span',[_vm._v(_vm._s(skuItem.name))])]}},{key:"td-price",fn:function(ref){
var skuItem = ref.skuItem;
return [_c('span',[_vm._v(_vm._s(_vm.currencySymbol)+" "+_vm._s(_vm._f("currency")(skuItem.price)))])]}},{key:"td-sold",fn:function(ref){
var skuItem = ref.skuItem;
return [_c('span',[_vm._v(_vm._s(skuItem.sold_quantity)+" / "+_vm._s(skuItem.salable_quantity))])]}},{key:"btns",fn:function(ref){
var item = ref.item;
return [_c('el-popover',{staticClass:"cursor-pointer",attrs:{"placement":"bottom","trigger":"hover","popper-class":"el-popover-post-action"}},[_c('el-button',{staticClass:"btn-edit cursor-pointer",attrs:{"type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.handleSelectProduct(item)}}},[_vm._v(_vm._s(_vm.$t('button.edit')))]),_c('el-button',{staticClass:"btn-delete cursor-pointer",attrs:{"type":"text"},on:{"click":function($event){$event.stopPropagation();return _vm.handleDeleteProduct(item)}}},[_vm._v(_vm._s(_vm.$t('button.delete')))]),_c('i',{staticClass:"fal fa-ellipsis-v text-lg px-3",attrs:{"slot":"reference"},slot:"reference"})],1)]}},(_vm.getCurrentProducts && _vm.getCurrentProducts.length > 0)?{key:"footer",fn:function(){return [_c('el-pagination',{attrs:{"background":"","current-page":_vm.currentPage,"page-size":_vm.pageSize,"layout":"prev, pager, next, jumper","total":_vm.filterProducts.length},on:{"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})]},proxy:true}:null],null,true)}),(_vm.dialogEditProductVisible)?_c("DialogEditProduct",{tag:"DialogEditProduct",staticClass:"z-20",attrs:{"size":"lg","dialogVisible":_vm.dialogEditProductVisible,"selectedProducts":_vm.products},on:{"update:dialogVisible":function($event){_vm.dialogEditProductVisible=$event},"update:dialog-visible":function($event){_vm.dialogEditProductVisible=$event},"handleUpdateProduct":_vm.handleUpdateProduct,"handleDeleteProduct":_vm.handleDeleteProduct}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }