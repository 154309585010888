<script>
import { mapGetters } from 'vuex'

import PricingSheet from './components/PricingSheet'

export default {
  name: 'PurchasePrice',

  components: { PricingSheet },

  computed: {
    ...mapGetters('Me', ['currencySymbol', 'region']),
    ...mapGetters('OrderDetail', ['orderData', 'items'])
  }
}
</script>

<template lang="pug" src="./PurchasePrice.pug"></template>
