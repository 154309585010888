export default ticket => {
  const {
    id,
    name,
    skus,
    price,
    images,
    sold_quantity: sold,
    salable_quantity: salable,
    lock_quantity: lock,
    available_quantity: available,
    'sale_started_at': saleStartedAt,
    'sale_ended_at': saleEndedAt,
    description
  } = ticket

  const {
    id: skuId,
    name: skuName
  } = skus[0]

  return {
    id,
    name,
    price,
    skuId,
    skuName,
    images,
    sold,
    salable,
    lock,
    available,
    saleStartedAt,
    saleEndedAt,
    description
  }
}
