<script>
import VueTypes from 'vue-types'
import { getMinutes, splitMinutes } from '@/utils/timeOperation'

export default {
  name: 'DialogSetCheckoutTime',
  props: {
    preCartItemLife: VueTypes.number
  },
  data () {
    const validateCheckoutRule = (rule, checkoutRule, callback) => {
      // 無限制
      if (!checkoutRule.hasLimit) {
        callback()
        return
      }

      if (checkoutRule.day || checkoutRule.hour || checkoutRule.minute) {
        callback()
      } else {
        callback(new Error(this.$t('Post.Show.DialogSetCheckTime.message.limit_error')))
      }
    }

    return {
      isBtnLoading: false,
      liveForm: {
        checkoutRule: {
          hasLimit: false,
          day: 3,
          hour: 0,
          minute: 0
        }
      },
      rules: {
        checkoutRule: [
          { validator: validateCheckoutRule, trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    isDayUpperLimit () {
      const upperLimitNumber = 30

      return this.liveForm.checkoutRule.day === upperLimitNumber
    }
  },
  created () {
    if (this.preCartItemLife) {
      this.$set(
        this.liveForm,
        'checkoutRule',
        { hasLimit: true, ...splitMinutes(this.preCartItemLife) }
      )
    }
  },
  methods: {
    updateCheckoutDay () {
      if (this.isDayUpperLimit) {
        this.liveForm.checkoutRule.hour = 0
        this.liveForm.checkoutRule.minute = 0
      }
    },
    submit () {
      this.$refs['liveForm'].validate((valid) => {
        if (!valid) return

        this.isBtnLoading = true

        // 計算 買家結帳商品自動取消時間
        const cartItemLife = this.liveForm.checkoutRule.hasLimit
          ? getMinutes(this.liveForm.checkoutRule)
          : null

        this.$emit('handleUpdateCartItemLife', cartItemLife)
      })
    },
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
