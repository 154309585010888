<script>
import { mapGetters } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import { format, subDays } from 'date-fns'
import { saleStats } from '../../services/apis/saleStats'

const DAYS = [7, 30, 60]

export default {
  name: 'SalesChart',
  components: { VueApexCharts },
  data () {
    return {
      DAYS,
      days: DAYS[0],
      isLoading: false,
      series: [
        {
          name: this.$t('merchant_dashboard.order_amount'),
          type: 'line',
          data: []
        }, {
          name: this.$t('merchant_dashboard.order_quantity'),
          type: 'column',
          data: []
        }
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: [2, 0]
        },
        markers: {
          colors: '#008FFB'
        },
        legend: {
          show: false
        },
        xaxis: {
          tickAmount: 30
        },
        yaxis: [
          {
            title: {
              text: this.$t('merchant_dashboard.order_amount')
            }
          }, {
            opposite: true,
            title: {
              text: this.$t('merchant_dashboard.order_quantity')
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol', 'storeName', 'childStores'])
  },
  mounted () {
    this.fetchStats()
  },
  methods: {
    async fetchStats () {
      const today = new Date()
      const filter = ['order_amounts', 'order_quantities'].join(',')
      const orderQuantities = []
      const orderAmounts = []
      this.isLoading = true
      try {
        const params = {
          from: format(subDays(today, this.days), 'YYYY-MM-DD'),
          to: format(today, 'YYYY-MM-DD'),
          filter,
          with_children: this.childStores.length > 0
        }
        const { data: { data } } = await saleStats(this.storeName, params)
        for (const date in data.order_amounts) {
          // YYYY-MM-DD => MM/DD
          const dateString = format(date, 'MM/DD')
          orderAmounts.push({ x: dateString, y: data.order_amounts[date] })
          orderQuantities.push({ x: dateString, y: data.order_quantities[date] })
        }

        this.series = [
          { data: orderAmounts },
          { data: orderQuantities }
        ]
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    }
  }
}
</script>

<template lang="pug" src="./SalesChart.pug"></template>
<style lang="scss" src="./SalesChart.scss" scoped></style>
