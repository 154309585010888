import ApiPost from '@/api/post'
import { getPublication } from '@/utils/getPublication'
import { HANDSUP } from '@/constants/channelType'
import * as loadingState from '@/constants/loadingState'
import * as LIVE_STATUS from '@/constants/liveStatus'

import * as TABS from '@/views/Post/constants/tabs'

import { stopPost as apiStopPost } from '../../apis/post'
import { sortImage } from '@/utils/sortImage'

const types = {
  FETCH_POST: 'FETCH_POST',
  UPDATE_POST: 'UPDATE_POST',
  FETCH_POST_FAILED: 'FETCH_POST_FAILED'
}

const INIT_DATA = {
  activeTab: TABS.CONTENT,
  celebrityToken: '',
  postAgent: null, // 網紅授權貼文
  loading: loadingState.EMPTY,
  postId: null,
  post: null,
  isSelfStop: false, // 是否自己手動關閉，有可能網紅授權模式，被商家關閉直播
  isCelebrityEnterprise: false,
  isCelebrityStoreOwner: false,
  selectedStreamChannels: [] // 紀錄已選擇的開播渠道
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  activeTab: state => state.activeTab,
  celebrityToken: state => state.celebrityToken,
  postAgent: state => state.postAgent,
  postId: state => state.postId,
  post: state => state.post,
  publications: state => _.get(state.post, 'publications', []),

  isSelfStop: state => state.isSelfStop,
  isCelebrityEnterprise: state => state.isCelebrityEnterprise,
  isCelebrityStoreOwner: state => state.isCelebrityStoreOwner,

  liveStatus: state => {
    let liveStatus = 'ready'
    if (!state.post) return liveStatus

    // 網紅代理開播
    if (state.post.agent) {
      liveStatus = state.post.agent && state.post.agent.status
    }

    const publication = getPublication(state.post.publications)

    if (publication) {
      liveStatus = publication.live_status
    }
    return liveStatus
  },

  publication: state => {
    let data = { embed_html: null, live_status: null }

    const publication = getPublication(state.post.publications)

    if (publication) {
      data = {
        embed_html: publication.info ? publication.info.embed_html : null,
        live_status: publication.live_status
      }
    }

    return data
  },

  isClipAsReplay (state) {
    return state.post.replay_type === 'clip'
  },

  selectedStreamChannels (state) {
    return state.selectedStreamChannels
  }
}

const mutations = {
  [types.FETCH_POST] (state) {
    state.loading = loadingState.LOADING
  },

  [types.UPDATE_POST] (state, payload) {
    state.loading = loadingState.READY
    state.post = payload
  },

  [types.FETCH_POST_FAILED] (state, { errMessage }) {
    console.error(errMessage)
    state.errMessage = errMessage
    state.loading = loadingState.ERROR
  },

  UPDATE_ACTIVE_TAB (state, tab) {
    state.activeTab = tab
  },

  UPDATE_SELF_STOP (state, bool) {
    state.isSelfStop = bool
  },

  UPDATE_CELEBRITY_TOKEN (state, token) {
    state.celebrityToken = token
  },

  UPDATE_POST_AGENT (state, payload) {
    state.postAgent = payload
  },

  UPDATE_CELEBRITY_ENTERPRISE (state, flag) {
    state.isCelebrityEnterprise = flag
  },

  UPDATE_CELEBRITY_STORE_OWNER (state, flag) {
    state.isCelebrityStoreOwner = flag
  },

  UPDATE_POST_ID (state, id) {
    state.postId = id
  },

  RESET_POST_DATA (state) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))
  },

  updateSelectedStreamChannels (state, payload) {
    state.selectedStreamChannels = [...payload]
  }

}

const actions = {
  updateActiveTab ({ commit }, tab) {
    commit('UPDATE_ACTIVE_TAB', tab)
  },
  updateSelfStop ({ commit }, bool) {
    commit('UPDATE_SELF_STOP', bool)
  },
  updateCelebrityToken ({ commit }, token) {
    commit('UPDATE_CELEBRITY_TOKEN', token)
  },

  updatePostId ({ commit }, id) {
    commit('UPDATE_POST_ID', id)
  },

  async fetchPostAgent ({ commit, state }) {
    // 網紅授權貼文
    const apiPost = new ApiPost()
    const params = { token: state.celebrityToken }

    return apiPost.getPostAgent(params)
      .then(response => {
        commit('UPDATE_POST_AGENT', response.data)
        return response.data.post.id
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  },

  checkCelebrityData ({ commit, state }, stores) {
    // 是否為 企業版網紅授權
    const isCelebrityEnterprise = !!_.get(state.postAgent, 'store.enterprise', false)
    commit('UPDATE_CELEBRITY_ENTERPRISE', isCelebrityEnterprise)

    // 是否為 授權網紅之賣家本人
    // 因為有可能「賣家本人」使用網紅授權連結，進直播間看貼文
    const isCelebrityStoreOwner = stores.some(store => {
      if (store.pivot && store.pivot.type !== 'owner') return false

      const postAgentSlug = state.postAgent.store && state.postAgent.store.slug

      if (store.slug === postAgentSlug) return true
    })
    commit('UPDATE_CELEBRITY_STORE_OWNER', isCelebrityStoreOwner)
  },

  async fetchPost ({ commit, state }) {
    commit(types.FETCH_POST)
    const apiPost = new ApiPost()

    return apiPost.getPost(state.postId)
      .then(resp => {
        // sort product images
        resp.data.products.forEach(product => {
          product.images = product.images.sort(sortImage)
        })
        commit(types.UPDATE_POST, resp.data)
        commit('updateSelectedStreamChannels', resp.data.channels.filter(channel => channel.type !== HANDSUP).map(channel => channel.id))
      })
      .catch(err => commit(types.FETCH_POST_FAILED, { errMessage: err.message }))
  },

  async checkStopPost ({ getters, dispatch, rootGetters }) {
    try {
      await dispatch('fetchPost')

      if (getters.liveStatus !== LIVE_STATUS.LIVE_STOPPED) return

      dispatch('fetchAnalysis', getters.postId)

      // 不是網紅 && 有開放「回放影片時間 或 買家結帳期限」，才能自動開啟 回放與結帳期限設定
      if (!getters.celebrityToken && !getters.isCelebrityEnterprise && rootGetters['Me/enabledDialogSetPostTime']) {
        dispatch('toggleDialogSetPostTime', true)
      }

      // 有開放直播分析功能 且 不是企業版網紅，才切換 分析 tab
      if (rootGetters['Me/enabledPostAnalysis'] && !getters.isCelebrityEnterprise) {
        dispatch('updateActiveTab', TABS.ANALYSIS)
      }

      // 網紅被動結束直播
      if (!getters.isSelfStop && getters.post.agent && getters.celebrityToken) {
        dispatch('toggleDialogCloseNotice', true)
      }
    } catch (err) {
      console.error(err)
    }
  },

  async stopPost ({ getters, dispatch }) {
    try {
      await apiStopPost(getters.postId)
      dispatch('updateSelfStop', true)
      dispatch('checkStopPost')
    } catch (err) {
      console.error(err)
    }

    dispatch('toggleDialogStopLive', false)
  },

  resetPostData ({ commit, dispatch }) {
    commit('RESET_POST_DATA')

    dispatch('resetStreamInfo')
    dispatch('resetViewersCount')

    dispatch('resetDialogVisible')

    // 喊單模組
    dispatch('resetDrawData')
    dispatch('resetBiddingData')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
