<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'
import AddressSelect from '@/components/AddressSelect'

export default {
  name: 'DialogStorePickup',
  components: { AddressSelect },
  props: {
    setting: VueTypes.object
  },
  data () {
    return {
      normalSetting: {
        enabled: false,
        fee: 0,
        free_shipping_threshold: 0,
        fee_type: 'free',
        storeName: null,
        address: {},
        sender_name: null,
        sender_phone: null,
        memo: null
      },
      senderRules: {
        storeName: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['stores', 'currentStore'])
  },
  created () {
    this.normalSetting.type = this.setting.type
    if (this.setting.normal) {
      this.normalSetting = { ...this.normalSetting, ...this.setting.normal }
      if (this.normalSetting.address) {
        this.normalSetting.storeName = this.normalSetting.address.info.name
      }
    } else if (this.setting.lastAddress) {
      this.normalSetting.sender_address = this.setting.lastAddress.address
      this.normalSetting.sender_phone = this.setting.lastAddress.sender_phone
      this.normalSetting.sender_name = this.setting.lastAddress.sender_name
    }
  },
  methods: {
    handleClose () {
      this.$emit('update:dialogVisible', false)
    },
    validateAddress () {
      if (this.$refs['addressForm']) {
        return this.$refs['addressForm'].validateForm()
      } else {
        return true
      }
    },
    async validateSender () {
      if (this.$refs['senderForm']) {
        return this.$refs['senderForm'].validate()
      } else {
        return true
      }
    },
    async handleSubmit () {
      try {
        await this.validateAddress()
        await this.validateSender()
      } catch (err) {
        this.$message.error(this.$t('Setting.Payment.DialogStorePickup.validate'))
        return
      }
      let data = {}
      data['normal'] = this.normalSetting
      data['normal'].address.info = { name: this.normalSetting.storeName }
      this.normalSetting.enabled = true

      this.$emit('submit', data)
    },
    updateAddress (obj) {
      this.normalSetting.address = obj
    }
  }
}
</script>

<template lang="pug" src="./DialogStorePickup.pug"></template>
<style lang="scss" src="./DialogStorePickup.scss" scoped></style>
