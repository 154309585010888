import BaseApi from './baseApi'
import storeId from './_storeId'
import axios from '@/plugins/http.js'

export default class Cart extends BaseApi {
  uri = `/api/v1/carts`
  uriList = `/api/v1/stores/${storeId()}/carts`

  getCartPostTags = async id => {
    try {
      const api = `/api/v1/stores/${storeId()}/carts/posts?all`
      const {
        data: { data }
      } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }
}
