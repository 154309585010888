import { updateProduct as apiUpdateProduct } from '../apis'

import * as loadingState from '@/constants/loadingState'

export const types = {
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_FAILED: 'UPDATE_PRODUCT_FAILED'
}

const INIT_DATA = {
  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  updateProductLoadingState: state => state.loadingState
}

const mutations = {
  [types.UPDATE_PRODUCT] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.UPDATE_PRODUCT_SUCCESS] (state) {
    state.loadingState = loadingState.READY
  },

  [types.UPDATE_PRODUCT_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  }
}

const actions = {
  updateProduct ({ getters, commit, rootGetters }, id) {
    commit(types.UPDATE_PRODUCT)

    return apiUpdateProduct(rootGetters['Me/storeName'], id, getters.calcProductData)
      .then(response => commit(types.UPDATE_PRODUCT_SUCCESS))
      .catch(err => {
        commit(types.UPDATE_PRODUCT_FAILED, err)
        throw err
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
