/* eslint-disable */
window.fbAsyncInit = function() {
  FB.init({
    appId: process.env.VUE_APP_FACEBOOK_APP_ID,
    autoLogAppEvents: true,
    xfbml: true,
    version: process.env.VUE_APP_FACEBOOK_APP_VERSION
  })

  FB.Event.subscribe('messenger_checkbox', function(e) {
    // console.log('messenger_checkbox event')
    // console.log(e)

    if (e.event == 'rendered') {
      // console.log('Plugin was rendered')
    } else if (e.event == 'checkbox') {
      var checkboxState = e.state
      // console.log('Checkbox state: ' + checkboxState)
    } else if (e.event == 'not_you') {
      // console.log("User clicked 'not you'")
    } else if (e.event == 'hidden') {
      // console.log('Plugin was hidden')
    }
  })
}
;(function(d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0]
  if (d.getElementById(id)) {
    return
  }
  js = d.createElement(s)
  js.id = id
  js.src = 'https://connect.facebook.net/en_US/sdk.js'
  fjs.parentNode.insertBefore(js, fjs)
})(document, 'script', 'facebook-jssdk')
