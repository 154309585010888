<script>
import VueTypes from 'vue-types'
import { mapGetters, mapActions } from 'vuex'

import isKeyInNumber from '@/utils/isKeyInNumber'

const QUANTITY_LIMIT_MAX = 999999

export default {
  name: 'ProductFormSku',

  props: {
    isPostEditProduct: VueTypes.bool.def(false)
  },

  data () {
    return {
      QUANTITY_LIMIT_MAX,

      skus: [],
      skusErrorList: [],
      duplicateNames: []
    }
  },

  computed: {
    ...mapGetters('Me', ['maxPrice', 'maxSkuName']),
    ...mapGetters('Me', ['isEnterprise', 'enabledLivewebSimpleRedirectPurchase']),
    ...mapGetters('ProductDetail', ['editProduct']),
    ...mapGetters('ProductDetail', { productSkus: 'skus' })
  },

  watch: {
    skus: {
      handler: _.debounce(function () {
        if (this.isValidate) {
          this.checkSkuRule()
          this.checkDuplicateName()
        }
      }, 500),
      deep: true
    }
  },

  mounted () {
    this.skus = _.cloneDeep(this.productSkus)

    if (!this.editProduct) {
      this.skus = [
        {
          ...this.skus[0],
          name: this.$t('Product.DialogAddProduct.default_sku_name')
        }
      ]
    }
  },

  methods: {
    ...mapActions('ProductDetail', ['updateSkus']),
    isKeyInNumber,

    getQuantity (sku) {
      // 數量 = 商品庫存 + 已售
      const modifyQuantity = sku.modify_quantity || 0
      return sku.salable_quantity + modifyQuantity >= 0
        ? sku.salable_quantity + modifyQuantity
        : 0
    },

    addSkusRow () {
      const copySkus = _.pick(
        this.skus[this.skus.length - 1],
        ['price', 'available_quantity', 'name', 'number', 'info.source_id', 'info.purchase_url']
      )

      this.skus.push(copySkus)
    },

    removeSkus (index) {
      this.skus.splice(index, 1)
    },

    checkValidate (index, name) {
      return _.get(this.skusErrorList, `[${index}].${name}`, false)
    },

    checkSkuRule () {
      let validate = true
      const regex = /\s/

      const skusErrorList = this.skus.reduce((acc, current) => {
        const { name, price, available_quantity: count } = current

        let errorStatus = {
          isNullName: false,
          isBlankName: false,

          isNullPrice: false,
          isLimitOverPrice: false,

          isNullCount: false,
          isLimitOverCount: false,

          isOverStock: false
        }

        // 商品規格名稱
        if (!name) errorStatus.isNullName = true
        if (regex.test(name)) errorStatus.isBlankName = true

        // 價錢
        if (!price) errorStatus.isNullPrice = true
        if (price > this.maxPrice) errorStatus.isLimitOverPrice = true

        // 庫存
        if (_.isNil(count) || count === '') errorStatus.isNullCount = true
        if (count > QUANTITY_LIMIT_MAX) errorStatus.isLimitOverCount = true

        // 庫存 不可低於 可售數量
        if (current.available_quantity < current.lock_quantity) errorStatus.isOverStock = true

        if (Object.values(errorStatus).includes(true)) {
          validate = false
        }

        acc.push(errorStatus)
        return acc
      }, [])

      this.$set(this, 'skusErrorList', skusErrorList)

      return validate
    },

    checkDuplicateName () {
      // 判斷商品規格名稱 有無重複
      let validate = true

      const nameCount = this.skus.reduce((acc, current) => {
        if (acc[current.name]) {
          acc[current.name] = acc[current.name] + 1
        } else {
          acc[current.name] = 1
        }

        return acc
      }, {})

      const duplicateNames = Object.keys(nameCount).filter(key => nameCount[key] > 1)
      this.duplicateNames = duplicateNames

      if (duplicateNames.length) {
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          message: this.$t(
            'Product.DialogAddProduct.error_specification_duplicated'
            , { duplicate: duplicateNames.join('<br>') }
          ),
          showClose: true
        })

        validate = false
      }

      return validate
    },

    validateForm () {
      this.isValidate = true

      // Set Sku Modify Quantity
      for (let sku of this.skus) {
        // 因為新增規格會有沒有 original_quantity 的情況所以要判斷
        if (sku['original_quantity']) {
          sku['modify_quantity'] = sku['available_quantity'] - sku['original_quantity']
        } else {
          sku['modify_quantity'] = sku['available_quantity']
        }
      }

      this.updateSkus(_.cloneDeep(this.skus))

      return this.checkSkuRule() && this.checkDuplicateName()
    }
  }
}
</script>

<template lang="pug" src="./ProductFormSku.pug"></template>
<style lang="scss" src="./ProductFormSku.scss" scoped></style>
