<script>
import { format, startOfMonth, endOfMonth, setISOWeek, startOfISOWeek, endOfISOWeek, differenceInMonths, isBefore } from 'date-fns'
import { mapGetters } from 'vuex'
import FileSaver from 'file-saver'
import { saleStats, exportSales } from '../../services/apis/saleStats'

const RANGE_TYPES = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly'
}

const ALL_CHILDS = '_all_'
const MAX_MONTH = 6

export default {
  name: 'SalesTable',
  data () {
    return {
      ...RANGE_TYPES,
      ALL_CHILDS,
      intervalType: RANGE_TYPES.DAILY,
      dateFrom: startOfMonth(new Date()),
      dateTo: new Date(),
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate: (date) => {
          return date > Date.now()
        }
      },
      data: [],
      amountTotal: 0,
      quantityTotal: 0,
      postTotal: 0,
      isLoading: false,
      childStore: ALL_CHILDS
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore', 'storeName', 'childStores', 'isCurrentStoreOwner']),
    queryParams () {
      // Default to daily
      let from = this.dateFrom
      let to = this.dateTo

      if (this.intervalType === RANGE_TYPES.WEEKLY) {
        from = startOfISOWeek(from)
        to = endOfISOWeek(to)
      } else if (this.intervalType === RANGE_TYPES.MONTHLY) {
        from = startOfMonth(from)
        to = endOfMonth(to)
      }

      return {
        from: format(from, 'YYYY-MM-DD'),
        to: format(to, 'YYYY-MM-DD'),
        period: this.intervalType,
        filter: ['order_amounts', 'order_quantities', 'post_quantities'].join(','),
        with_children: this.childStore === ALL_CHILDS && this.childStores.length > 0
      }
    },
    queryStoreId () {
      return this.childStore === ALL_CHILDS
        ? this.storeName
        : this.childStore
    }
  },
  mounted () {
    this.fetchStats()
  },
  methods: {
    validateDates () {
      if (isBefore(this.dateTo, this.dateFrom)) {
        this.$message.error(this.$t('merchant_dashboard.date_end'))
        return false
      }

      if (Math.abs(differenceInMonths(this.dateFrom, this.dateTo)) >= MAX_MONTH) {
        this.$message.error(this.$t('merchant_dashboard.date_month', { month: MAX_MONTH }))
        return false
      }

      return true
    },
    async exportSales () {
      if (!this.validateDates()) {
        return
      }

      try {
        const { data, headers } = await exportSales(this.queryStoreId, this.queryParams)
        const filename = headers['content-disposition']
          ? headers['content-disposition'].split('filename=')[1].split(';')[0]
          : 'sales.csv'
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8' })
        FileSaver.saveAs(blob, filename)
      } catch (e) {
        console.error(e)
      }
    },
    async fetchStats () {
      if (!this.validateDates()) {
        return
      }

      let newData = []
      this.isLoading = true
      try {
        const { data: { data } } = await saleStats(this.queryStoreId, this.queryParams)
        for (const date of Object.keys(data.order_amounts).sort()) {
          newData.push({
            dateObj: this.formatDate(date),
            amount: data.order_amounts[date],
            orders: data.order_quantities[date],
            posts: data.post_quantities[date]
          })
        }
        this.data = newData
        this.amountTotal = data.order_amounts_sum
        this.quantityTotal = data.order_quantities_sum
        this.postTotal = data.post_quantities_sum
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    },
    formatDate (date) {
      // YYYY-MM-DD => MM/DD
      if (this.intervalType !== RANGE_TYPES.WEEKLY) {
        return {
          week: '',
          date: date.replace(/-/g, '/')
        }
      }
      // 2020W01 => W1 12/30 - 1/5
      const [year, weekNumber] = date.split('W')
      const weekDate = setISOWeek(new Date(year, 0), weekNumber)
      const weekStart = format(startOfISOWeek(weekDate), 'MM/DD')
      const weekEnd = format(endOfISOWeek(weekDate), 'MM/DD')
      return {
        week: weekNumber,
        date: `${weekStart} - ${weekEnd}`
      }
    }
  }
}
</script>

<template lang="pug" src="./SalesTable.pug"></template>
<style lang="scss" src="./SalesTable.scss" scoped></style>
