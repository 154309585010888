<script>
import AddressSelect from '@/components/AddressSelect'

import mixin from '../../mixins'

export default {
  name: 'FormHomeDelivery',

  components: { AddressSelect },

  mixins: [mixin],

  data () {
    return {
      fields: ['shipping_type', 'receiver_name', 'receiver_phone', 'receiver_address'],

      form: {
        receiver_name: '',
        receiver_phone: '',
        receiver_address: {}
      },

      rules: {
        receiver_name: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ],
        receiver_phone: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ]
      }
    }
  }
}
</script>

<template lang="pug" src="./FormHomeDelivery.pug"></template>
