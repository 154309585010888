import * as loadingState from '@/constants/loadingState'
import ApiCoupon from '@/api/coupon'

export const actionTypes = {
  CREATE_COUPON: 'CREATE_COUPON',
  CREATE_COUPON_SUCCESS: 'CREATE_COUPON_SUCCESS',
  CREATE_COUPON_FAILED: 'CREATE_COUPON_FAILED'
}

const state = {
  loadingState: loadingState.EMPTY,
  error: null
}

const getters = {}

const mutations = {
  [actionTypes.CREATE_COUPON] (state) {
    state.loadingState = loadingState.LOADING
    state.error = null
  },

  [actionTypes.CREATE_COUPON_SUCCESS] (state) {
    state.loadingState = loadingState.READY
  },

  [actionTypes.CREATE_COUPON_FAILED] (state) {
    state.loadingState = loadingState.ERROR
    // state.error = error
  }
}

const actions = {
  async createCoupon ({ state, commit }, postDate) {
    commit('SET_LOADING', true, { root: true })

    const apiCoupon = new ApiCoupon()

    const data = await apiCoupon.create(postDate)

    if (data) {
      commit(actionTypes.CREATE_COUPON_SUCCESS)
    } else {
      commit(actionTypes.CREATE_COUPON_FAILED)
    }

    commit('SET_LOADING', false, { root: true })
    return !!data
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
