<script>
import { mapGetters } from 'vuex'
import { getPublication } from '@/utils/getPublication'
import { getUpcomingPosts } from '../../services/apis/saleStats'
import emptyUpcoming from '../../images/empty-live-upcoming.svg'
import liveDefault from '../../images/img_live_default.svg'

export default {
  name: 'UpcomingPosts',
  data () {
    return {
      emptyUpcoming,
      posts: [],
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName'])
  },
  mounted () {
    this.fetchPosts()
  },
  methods: {
    async fetchPosts () {
      this.isLoading = true

      try {
        const { data: { data } } = await getUpcomingPosts(this.storeName)
        this.posts = data
      } catch (e) {
        console.error(e)
      }

      this.isLoading = false
    },
    goPost (post) {
      this.$router.push(`/${this.storeName}/posts/${post.id}`)
    },
    getPostImage (post) {
      const image = post.image
      let postImage
      if (image.length > 0) {
        postImage = image[0].url
      } else {
        postImage = post.info ? post.info.picture : liveDefault
      }

      return postImage
    },
    getLiveStatus (post) {
      const publication = getPublication(post.publications) || {}
      return publication.live_status
    }
  }
}
</script>

<template lang="pug" src="./UpcomingPosts.pug"></template>
<style lang="scss" src="./UpcomingPosts.scss" scoped></style>
