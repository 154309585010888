<script>
import Lottie from '@/components/Lottie'

import * as animationData from './ribbon.json'

export default {
  name: 'RibbonAnimation',

  components: {
    'lottie': Lottie
  },

  data () {
    return {
      defaultOptions: { animationData: animationData.default },
      animationSpeed: 1
    }
  }
}
</script>

<template lang="pug" src="./RibbonAnimation.pug"></template>
<style lang="scss" src="./RibbonAnimation.scss" scoped></style>
