import axios from '@/plugins/http'

export const getVoucherList = (storeName, params) => (
  axios.get(`/api/v1/stores/${storeName}/vouchers`, { params })
)

export const createVoucher = (storeName, postData) => (
  axios.post(`/api/v1/stores/${storeName}/vouchers`, postData)
)

export const updateVoucher = (voucherId, putData) => (
  axios.put(`/api/v1/vouchers/${voucherId}`, putData)
)

export const interruptVoucher = (voucherId) => (
  axios.post(`/api/v1/vouchers/${voucherId}/interrupt`)
)
