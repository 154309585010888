import axios from '@/plugins/http.js'

export const getPaymentGateway = async (store, name) => {
  const api = `/api/v1/stores/${store}/payment-gateways/${name} `

  return axios.get(api)
}

export const updatePaymentGateway = async (store, name, payload) => {
  const api = `/api/v1/stores/${store}/payment-gateways/${name} `

  return axios.put(api, payload)
}
