const actions = {
  async connectSocketIO ({ getters, dispatch }) {
    const channelGroup = await dispatch(
      'ChatMessage/ChannelNames/fetchChannelNames',
      {
        postId: getters.postId
      },
      {
        root: true
      }
    )

    dispatch(
      'Pubnub/subscribe',
      {
        channelGroup,
        publications: getters.publications
      },
      {
        root: true
      }
    )

    const {
      cartChans,
      commentChans,
      failedChans
    } = await dispatch(
      'ChatMessage/Channels/fetchChannels',
      {
        groupName: channelGroup
      },
      {
        root: true
      }
    )

    dispatch(
      'ChatMessage/Messages/fetchHistoricalMessage',
      {
        cartChans,
        commentChans,
        failedChans
      },
      {
        root: true
      }
    )
  }
}

export default {
  actions
}
