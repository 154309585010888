<script>
import ApiOrder from '@/api/order'
import { mapGetters } from 'vuex'

export default {
  name: 'DialogExportData',
  data () {
    return {
      dialogVisible: false,
      exportDateRang: null
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName']),
    pickerOptions () {
      return {
        disabledDate: (time) => {
          const curDate = (new Date()).getTime()
          const day = 32 * 24 * 3600 * 1000
          const dateRegion = curDate - day

          return time.getTime() < dateRegion || time.getTime() > Date.now()
        }
      }
    }
  },
  methods: {
    toggle (isOpen = null) {
      this.exportDateRang = null
      this.dialogVisible = isOpen === null ? !this.dialogVisible : isOpen
    },
    async handleExport () {
      const apiOrder = new ApiOrder()
      const res = await apiOrder.downloadUndeliveredOrder(this.exportDateRang[0], this.exportDateRang[1])

      if (res) {
        this.$message({
          showClose: true,
          duration: 1000,
          message: this.$t('Order.DialogExportData.message_success'),
          type: 'success'
        })

        this.toggle()
      } else {
        this.$message({
          showClose: true,
          duration: 1000,
          message: this.$t('Order.DialogExportData.message_error'),
          type: 'error'
        })
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
