<script>
import VueTypes from 'vue-types'
import { mapActions, mapGetters } from 'vuex'

import * as SUBSCRIPTION_STATUS from '@/constants/subscriptionStatus'

import AppIntroduce from '@/components/AppIntroduce'

import SubscriptionForm from './components/SubscriptionForm'

export default {
  name: 'DialogSubscriptionSetting',

  components: {
    AppIntroduce,
    SubscriptionForm
  },

  props: {
    subscriptionList: VueTypes.array.def([]),
    subscriptionDescription: VueTypes.string.def('')
  },

  data () {
    return {
      list: [],
      description: '',
      isLoading: false
    }
  },

  computed: {
    ...mapGetters('Me', ['storeName']),

    disableEditDescription () {
      // 當其一方案已生效後，就不能再編輯 備註說明
      return this.subscriptionList.some(ticket => ticket.status === SUBSCRIPTION_STATUS.ONGOING)
    },

    disableSubmit () {
      // 未選擇任何方案則儲存 disable
      return this.list.every(item => !item.active)
    }
  },

  created () {
    this.initData()
  },

  methods: {
    ...mapActions('Subscription', ['updateSubscription']),

    initData () {
      this.list = this.subscriptionList.map(ticket => {
        const { id, name, active, status } = ticket

        return {
          id,
          name,
          active,
          'price': Number(ticket.price) || null,
          'subscription_frequency': ticket.subscription_frequency,
          'subscription_started_at': ticket.subscription_started_at,
          'subscription_updated_at': ticket.subscription_updated_at,
          'showUpdateTime': status !== SUBSCRIPTION_STATUS.INIT,
          'disabledEdit': status === SUBSCRIPTION_STATUS.ONGOING
        }
      })

      this.description = this.subscriptionDescription
    },

    updateForm (form) {
      this.list = this.list.map(item => {
        return item.id === form.id
          ? { ...item, ...form }
          : item
      })
    },

    async validateForm () {
      if (!this.$refs.validateForm) return true

      const validates = this.$refs.validateForm.map(form => {
        return form.validateForm()
      })

      try {
        await Promise.all(validates)
        return true
      } catch (error) {
        return false
      }
    },

    calcUpdateData () {
      const data = this.list.map(ticket => {
        const { id, name, active } = ticket

        return {
          id,
          name,
          active,
          'price': Number(ticket.price),
          'subscription_started_at': ticket.subscription_started_at
            ? new Date(ticket.subscription_started_at).toISOString()
            : null
        }
      })

      return {
        data,
        'description': this.description
      }
    },

    handleCloseDialog () {
      this.$emit('close')
      this.$emit('update:dialogVisible', false)
    },

    async handleSubmit () {
      if (!await this.validateForm()) return

      this.isLoading = true

      try {
        const putData = this.calcUpdateData()
        await this.updateSubscription({ storeSlug: this.storeName, putData })
        this.$emit('submit')
        this.handleCloseDialog()

        this.$message({
          showClose: true,
          duration: 1000,
          message: this.$t('Post.Show.message.update_success'),
          type: 'success'
        })
      } catch (err) {
        console.error(err)
      }

      this.isLoading = false
    }
  }
}
</script>

<template lang="pug" src="./DialogSubscriptionSetting.pug"></template>
