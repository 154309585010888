import ApiBidding from '@/api/bidding'

import * as ACTIVITY_STATUS from '@/constants/activityStatus'

const apiBidding = new ApiBidding()

const INIT_DATA = {
  activityBiddings: [],
  selectBidding: null,
  selectBiddingWinners: [],

  currentBiddingAnalysed: 0,

  biddingFormVisible: false,
  biddingBeforeEndDialogVisible: false
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  activityBiddings: state => state.activityBiddings,
  selectBidding: state => state.selectBidding,
  selectBiddingWinners: state => state.selectBiddingWinners,

  hasCurrentBidding: state => {
    // 正在進行中：有 開始時間 且 無結束時間
    return state.activityBiddings.some(activity => activity.status === ACTIVITY_STATUS.STARTED)
  },
  currentBidding: state => {
    // 目前正在進行中的活動
    return state.activityBiddings.find(activity => activity.status === ACTIVITY_STATUS.STARTED)
  },
  currentBiddingAnalysed: state => state.currentBiddingAnalysed,

  showBiddingResult: state => !!state.selectBidding,

  biddingFormVisible: state => state.biddingFormVisible,
  biddingBeforeEndDialogVisible: state => state.biddingBeforeEndDialogVisible
}

const mutations = {
  UPDATE_ACTIVITY_BIDDINGS (state, payload) {
    state.activityBiddings = payload
  },
  UPDATE_SELECT_BIDDING (state, { bidding, winners }) {
    state.selectBidding = bidding
    state.selectBiddingWinners = winners
  },
  UPDATE_BIDDING_ANALYSED (state, count) {
    state.currentBiddingAnalysed = count
  },
  RESET_BIDDING_DATA (state) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))
  },

  UPDATE_BIDDING_FORM_VISIBLE (state, bol) {
    state.biddingFormVisible = bol
  },
  UPDATE_BIDDING_BEFORE_END_DIALOG (state, bol) {
    state.biddingBeforeEndDialogVisible = bol
  }
}

const actions = {
  async fetchActivityBiddings ({ commit }, postId) {
    if (!postId) return

    return apiBidding.getBiddings(postId)
      .then(response => {
        const { data } = response.data
        commit('UPDATE_ACTIVITY_BIDDINGS', data)
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  },

  checkBiddingActivity ({ getters, dispatch }) {
    if (getters.hasCurrentBidding) {
      dispatch('toggleBiddingForm', true)
    }
  },

  async createBidding ({ dispatch }, { postId, postData }) {
    return apiBidding.create(postId, postData)
      .then(async () => {
        await dispatch('fetchActivityBiddings', postId)
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  },
  async updateBidding ({ dispatch }, { postId, biddingId, updateData }) {
    return apiBidding.update(biddingId, updateData)
      .then(async () => {
        await dispatch('fetchActivityBiddings', postId)
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  },
  async endBidding ({ dispatch }, { postId, biddingId, postData }) {
    return apiBidding.end(biddingId, postData)
      .then(async () => {
        dispatch('updateBiddingAnalysed', 0)
        await dispatch('fetchActivityBiddings', postId)
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  },

  async updateBiddingAnalysed ({ commit }, count) {
    commit('UPDATE_BIDDING_ANALYSED', count)
  },

  async getBiddingWinners ({ commit, getters }, biddingId) {
    return apiBidding.getWinners(biddingId)
      .then(response => {
        const { data } = response.data
        commit('UPDATE_SELECT_BIDDING', {
          bidding: getters.activityBiddings.find(item => item.id === biddingId),
          winners: data
        })
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  },
  clearSelectBidding ({ commit }) {
    commit('UPDATE_SELECT_BIDDING', {
      bidding: null,
      winners: []
    })
  },
  resetBiddingData ({ commit }) {
    commit('RESET_BIDDING_DATA')
  },

  toggleBiddingForm ({ getters, commit }, bol = null) {
    const flag = bol !== null
      ? bol
      : !getters.biddingFormVisible

    commit('UPDATE_BIDDING_FORM_VISIBLE', flag)
  },

  toggleBiddingBeforeEndDialog ({ getters, commit }, bol = null) {
    const flag = bol !== null
      ? bol
      : !getters.biddingBeforeEndDialogVisible

    commit('UPDATE_BIDDING_BEFORE_END_DIALOG', flag)
  }

}

export default {
  state,
  getters,
  mutations,
  actions
}
