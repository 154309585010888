<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

export default {
  name: 'DialogCheckList',
  props: {
    dialogVisible: VueTypes.bool.def(false),
    sellerChecked: VueTypes.bool.def(false),
    paymentShippingChecked: VueTypes.bool.def(false)
    // checkedListCount: VueTypes.number,
    // checkListCount: VueTypes.number
  },
  computed: {
    ...mapGetters('Me', ['isCurrentStoreOwner'])
  },
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
