import VueTypes from 'vue-types'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'

export default {
  props: {
    region: VueTypes.string.isRequired,
    originForm: VueTypes.object.def({}),
    senderAddress: VueTypes.object.def({}),
    isChangeShipping: VueTypes.bool.def(false)
  },

  created () {
    this.initData()
  },

  methods: {
    initData () {
      if (!this.isChangeShipping) {
        this.fields.forEach(field => {
          if (this.originForm[field]) {
            this.form[field] = _.cloneDeep(this.originForm[field])
          }
        })
      } else {
        const copyFields = ['shipping_type', 'receiver_name', 'receiver_phone']
        copyFields.forEach(key => {
          this.form[key] = this.originForm[key]
        })
      }

      // 從 全家 或 7-11 選擇門市導回來
      const orderEditShipping = localStorage.getItem('orderEditShipping')
      if (!orderEditShipping) return
      const { type } = JSON.parse(orderEditShipping)
      if (type === this.originForm.shipping_type && location.search) {
        const decodedSearch = decodeURIComponent(location.search)
        const searchStr = decodedSearch.substring(1).split('&')
        const params = searchStr.reduce((prev, str) => {
          const name = str.split('=')[0]
          const value = decodeURIComponent(str.split('=')[1])
          prev[name] = value

          return prev
        }, {})

        if (params.store_id) {
          this.form = {
            ...this.form,
            info: params,
            receiver_address: params.address }
        }
      }
    },

    updateAddress (obj) {
      this.form.receiver_address = obj
    },

    async validateForm () {
      try {
        await this.$refs.form.validate()

        if (this.$refs.addressForm) {
          await this.$refs.addressForm.validateForm()
        }

        // 判斷 7-11、全家 是否已選擇門市
        if ([SHIPPING_TYPES.SEVEN, SHIPPING_TYPES.FAMILY].includes(this.form.shipping_type)) {
          const selectStoreId = _.get(this.form, 'info.store_id', '')
          if (!selectStoreId) {
            this.$message({
              showClose: true,
              duration: 1000,
              message: this.$t('Order.DialogEditShipping.select_store_error'),
              type: 'error'
            })

            return false
          }
        }

        this.$emit('updateForm', this.form)

        return true
      } catch (error) {
        console.error(error)
        return false
      }
    }
  }
}
