import axios from '@/plugins/http'

export function sendVerification (userId) {
  const api = `/api/v1/users/${userId}/verification-email`
  return axios.post(api)
}

export const changePassword = (data) => (
  axios.put(`/api/v1/me/password`, data)
)

export const updateMe = (data) => (
  axios.put(`/api/v1/me`, data)
)

export function getParentStore (token) {
  const api = `/api/v1/stores/parent-store/${token}`
  return axios.get(api)
}

export function getInviteStore (token) {
  const api = `/api/v1/stores/invite-store/${token}`
  return axios.get(api)
}

export function checkEmail (email) {
  const api = `/api/v1/users/email-exists?email=${email}`
  return axios.get(api)
}

export function register (payload) {
  const api = '/api/v1/users'
  return axios.post(api, payload)
}
