import axios from '@/plugins/http'

export const getSessionToken = () => (
  axios.get(`api/v1/fake/enterprise/get-user`)
)

export const getCartItems = token => (
  axios.get(`api/v1/fake/enterprise/get-cart-item?session_token=${token}`)
)

export const postback = data => (
  axios.post(`api/v1/fake/enterprise/order-postback`, data)
)
