<script>
import LayoutCard from '../components/LayoutCard'

export default {
  name: 'ForgotPasswordSent',
  components: { LayoutCard },
  skipMiddleware: ['getAuth', 'checkAuth'],
  data () {
    return {
      email: this.$route.query.email
    }
  },
  methods: {
    goLogin () {
      this.$router.push({ name: 'UserLogin' })
    }
  }
}
</script>

<template lang="pug" src="./ForgotPasswordSent.pug"></template>
<style lang="scss" src="./ForgotPasswordSent.scss" scoped></style>
