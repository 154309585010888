<script>

import { mapGetters, mapActions } from 'vuex'

import * as ACTIVITY_STATUS from '@/constants/activityStatus'
import * as ACTIVITY_TYPE from '@/constants/activityType'

import Tag from '@/components/Tag'

import DialogEditProduct from '@/views/Post/components/DialogEditProduct'

import { normalizeImage } from '@/utils/normalizeImage'
import { getPublication } from '@/utils/getPublication'

const GENERAL_MERCHANDISE = 'generalMerchandise'

const POST_STATUS = {
  TEMP: 'temp', // 剛開啟貼文，尚未儲存過
  DRAFT: 'draft', // 已儲存過，尚未挑選直播方式
  LIVE: 'live', // 直播中
  UNPUBLISHED: 'unpublished', // 已挑選直播方式，尚未開播
  FINISH: 'finish'// 直播結束
}

export default {
  name: 'PublishedProducts',
  components: {
    DialogEditProduct,
    Tag
  },

  data () {
    return {
      searchRule: {
        keyword: ''
      },
      popoverVisible: false,
      editProduct: {},
      expandedRow: [],

      dialogEditProductVisible: false,

      product_info_value: 'all'
    }
  },
  computed: {
    ...mapGetters('Me', [
      'storeName',
      'isEnterprise',
      'currencySymbol',
      'enabledDraw',
      'enabledAuction',
      'enabledBidding',
      'enabledMerchandise'
    ]),
    ...mapGetters('Post', ['celebrityToken', 'publications', 'liveStatus']),
    ...mapGetters('Post', ['products']),
    ...mapGetters('Post', ['activities']),

    postStatus () {
      // 建立(尚未儲存過)
      if (this.$route.name === 'PostCreate') return POST_STATUS.TEMP

      const publication = getPublication(this.publications)

      // 草稿(已儲存過)
      if (!publication) return POST_STATUS.DRAFT

      if (publication.live_status === POST_STATUS.LIVE) {
        // 直播中
        return POST_STATUS.LIVE
      } else if (publication.live_status === POST_STATUS.UNPUBLISHED) {
        // 待開播
        return POST_STATUS.UNPUBLISHED
      } else {
        // 直播結束
        return POST_STATUS.FINISH
      }
    },
    activitiesMap () {
      return this.activities.reduce((prev, activity) => {
        const id = _.get(activity, 'skus[0].product.id', null)
        prev[id] = activity

        return prev
      }, {})
    },
    normalizeProduct () {
      return this.products.map(product => {
        // 搜尋是否為 活動商品
        const activity = this.activitiesMap[product.id] || null

        return {
          ...product,
          activity
        }
      })
    },
    filteredProducts () {
      const products = this.normalizeProduct
        .filter(product => {
          let keywords = [product.name]
          // product keyword
          if (product.keyword) keywords.push(product.keyword)
          // skus keyword
          keywords = keywords.concat(product.skus.filter(x => x.keyword).map(x => x.keyword))
          const content = keywords.join('!$').toLowerCase()
          return content.includes(this.searchRule.keyword.toLowerCase())
        })
        .filter(product => {
          if (this.product_info_value === 'all') {
            return true
          } else if (this.product_info_value === 'common') {
            return !product.activity
          } else {
            const activityType = _.get(product, 'activity.type')
            return activityType === this.product_info_value
          }
        })
        .filter(product => product.skus.length > 0)

      return products
    },

    // 商品種類下拉選單
    product_info () {
      const activityCount = this.normalizeProduct.reduce((prev, product) => {
        const activityType = product?.activity?.type ?? GENERAL_MERCHANDISE

        prev[activityType] += 1

        return prev
      }, {
        [GENERAL_MERCHANDISE]: 0,
        [ACTIVITY_TYPE.DRAW]: 0,
        [ACTIVITY_TYPE.AUCTION]: 0,
        [ACTIVITY_TYPE.BIDDING]: 0
      })

      let selectList = [
        {
          value: 'all', // 全部
          label: this.$t('Post.PublishedProducts.product_info1') + ` (${this.products.length})`
        },
        {
          value: 'common', // 一般商品
          label: this.$t('Post.PublishedProducts.product_info2') + ` (${activityCount[GENERAL_MERCHANDISE]})`
        },
        {
          value: ACTIVITY_TYPE.DRAW, // 抽獎品
          label: this.$t('Post.PublishedProducts.product_info3') + ` (${activityCount[ACTIVITY_TYPE.DRAW]})`
        },
        {
          value: ACTIVITY_TYPE.AUCTION, // 拍賣品
          label: this.$t('Post.PublishedProducts.product_info4') + ` (${activityCount[ACTIVITY_TYPE.AUCTION]})`
        },
        {
          value: ACTIVITY_TYPE.BIDDING, // 喊單模組
          label: this.$t('Post.PublishedProducts.product_info5') + ` (${activityCount[ACTIVITY_TYPE.BIDDING]})`
        }
      ]

      // 判斷不同模式下，可顯示篩選的項目，如：功能是否開啟、企業版、網紅授權
      if (!this.enabledDraw || this.isEnterprise) {
        selectList = selectList.filter(item => item.value !== ACTIVITY_TYPE.DRAW)
      }
      if (!this.enabledAuction || this.isEnterprise || this.celebrityToken) {
        selectList = selectList.filter(item => item.value !== ACTIVITY_TYPE.AUCTION)
      }
      if (!this.enabledBidding || this.isEnterprise || this.celebrityToken) {
        selectList = selectList.filter(item => item.value !== ACTIVITY_TYPE.BIDDING)
      }

      return selectList
    }
  },
  methods: {
    ...mapActions('Post', ['fetchAll']),
    ...mapActions('Post', ['deleteDraw']),
    ...mapActions('ProductDetail', ['setEditProduct', 'updateDefaultKeyword']),

    getOrderedCount (sku) {
      const DISPLAY_ORDER_COUNT = 999
      const quantityTotal = sku.post_lock_quantity + sku.post_sold_quantity

      return Math.min(quantityTotal, DISPLAY_ORDER_COUNT)
    },
    handleAddProduct (command) {
      this.popoverVisible = !this.popoverVisible
      this.$emit(command)
    },

    isExpandRow (id) {
      return this.expandedRow.some(item => item === id)
    },
    handleExpandRow (id) {
      const idx = this.expandedRow.findIndex(item => item === id)

      if (idx === -1) {
        this.expandedRow.push(id)
      } else {
        this.expandedRow.splice(idx, 1)
      }
    },
    showSkus (product) {
      if (!this.isExpandRow(product.id)) return []
      return product.skus.slice(1)
    },

    getActivityType (activity) {
      const textMap = {
        [ACTIVITY_TYPE.DRAW]: this.$t(`Post.Show.ShowActivity.${ACTIVITY_TYPE.DRAW + '_' + activity.range}`),
        [ACTIVITY_TYPE.AUCTION]: this.$t(`Post.Show.ShowActivity.${ACTIVITY_TYPE.AUCTION}`),
        [ACTIVITY_TYPE.BIDDING]: this.$t(`Post.Show.ShowActivity.${ACTIVITY_TYPE.BIDDING}`)
      }

      return textMap[activity.type]
    },

    showButtons (product) {
      const activity = product.activity

      // 直播結束 且 網紅授權 且 非活動項目，不可編輯商品
      if (this.postStatus === POST_STATUS.FINISH && this.celebrityToken && !activity) {
        return false

      // 直播結束 且 活動項目 且 活動尚未結束，不可再變更
      } else if (this.postStatus === POST_STATUS.FINISH && activity && activity.status !== ACTIVITY_STATUS.DONE) {
        return false
      } else {
        return true
      }
    },

    isActivityEnded (product) {
      // 判斷活動結束標籤
      const activity = product.activity
      if (activity.type === ACTIVITY_TYPE.DRAW) {
        return !!activity.published
      } else {
        return activity.status === ACTIVITY_STATUS.DONE
      }
    },

    showKeyword (product) {
      const activity = product.activity

      if (activity) {
        // 顯示關鍵字： 喊單模組
        // 不顯示關鍵字： 抽獎、拍賣
        return activity.type === ACTIVITY_TYPE.BIDDING
      } else {
        // 一般商品
        return true
      }
    },

    productOpacity (product) {
      const activity = product.activity

      // 活動項目 且 活動已結束 且 非喊單模組
      return activity && this.isActivityEnded(product) && activity.type !== ACTIVITY_TYPE.BIDDING
    },
    showStartLottery (product, postStatus) {
      const activity = product.activity

      // 活動項目 && 活動項目為抽獎 && 抽獎尚未結束 && 直播尚未結束
      return activity && activity.type === ACTIVITY_TYPE.DRAW && !this.isActivityEnded(product) && postStatus !== POST_STATUS.FINISH
    },
    showViewList (product) {
      const activity = product.activity

      // 活動項目 且 活動已結束
      return activity && this.isActivityEnded(product)
    },
    showEdit (product) {
      const activity = product.activity

      if (activity) {
        // 顯示關鍵字： 喊單模組
        // 不顯示關鍵字： 抽獎、拍賣
        return activity.type === ACTIVITY_TYPE.BIDDING
      } else {
        // 一般商品
        return true
      }
    },
    showDeleteProduct (product, postStatus) {
      const activity = product.activity

      // 非活動項目 且 直播尚未結束
      return !activity && postStatus !== POST_STATUS.FINISH
    },
    showDeleteActivity (product, postStatus) {
      const activity = product.activity

      // 活動項目 && 抽獎活動 && 抽獎尚未開始(init) && 直播尚未結束
      return activity && activity.type === ACTIVITY_TYPE.DRAW && activity.status === ACTIVITY_STATUS.INIT && postStatus !== POST_STATUS.FINISH
    },

    handleSelectProduct (product) {
      this.setEditProduct(_.cloneDeep(product))

      // Note:
      // 因為 Post 裡的商品有自己的 keyword 了，
      // 就不應該延用商品的 default_keyword，
      // 所以需要將 default_keyword 的值改為 Post Product Keyword。
      this.updateDefaultKeyword(product.keyword)

      this.dialogEditProductVisible = true
    },
    handleDeleteProduct (product, notShowConfirm = false) {
      // 直播結束，不提供刪除
      if (this.postStatus === POST_STATUS.FINISH) {
        this.$message.error(this.$t('Post.PublishedProducts.delete_product_error'))
        return
      }

      // 判斷是否已有售出商品
      const hasSoldCount = product.skus.some(sku => !!this.getOrderedCount(sku))

      if (hasSoldCount) {
        this.$alert(this.$t('Post.Products.cant_delete'), this.$t('Post.Products.remind'), {
          confirmButtonText: this.$t('button.confirm')
        }).catch(() => { })
        return
      }

      // 若是由 DialogEditProduct 開啟，就不需再詢問
      if (notShowConfirm) {
        this.$emit('handleDeleteProduct', product)
        return
      }

      this.$confirm(this.$t('Post.Products.cant_see'), this.$t('Post.Products.remind'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'error'
      }).then(() => {
        this.$emit('handleDeleteProduct', product)
      }).catch(() => { })
    },

    handleUpdateProduct (product) {
      this.$emit('handleUpdateProduct', product)
    },
    handleSellProduct (product) {
      this.$emit('handleSellProduct', product)
    },
    showActivityDialog ({ id, type }) {
      const activity = this.activities.find(activity => activity.id === id && activity.type === type)

      this.$emit('showActivityDialog', activity)
    },
    async handleDeleteDraw (activity) {
      try {
        await this.$confirm(
          this.$t('Post.Products.confirm_delete_draw'),
          this.$t('Post.Products.remind'),
          {
            confirmButtonText: this.$t('button.confirm'),
            cancelButtonText: this.$t('button.cancel'),
            type: 'error'
          }
        )

        await this.deleteDraw(activity.id)
        await this.fetchAll()
      } catch (err) {
        console.error(err)
      }
    },
    isSoldout (product) {
      return product.skus.every(sku => {
        return sku.salable_quantity === 0
      })
    },
    canSell (product) {
      return !!product.activity === false &&
        product.status !== 'selling' &&
        !this.isSoldout(product) &&
        this.postStatus === POST_STATUS.LIVE
    },
    getImgUrl (data) {
      if (!data.images || data.images.length === 0) return
      return normalizeImage(data.images[0].url, 64)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
