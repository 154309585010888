
import * as loadingState from '@/constants/loadingState'

import { completeOrderDetail } from '../apis/order'

export const types = {
  COMPLETE_ORDER: 'COMPLETE_ORDER',
  COMPLETE_ORDER_SUCCESS: 'COMPLETE_ORDER_SUCCESS',
  COMPLETE_ORDER_FAILED: 'COMPLETE_ORDER_FAILED'
}

const INIT_DATA = {
  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  orderCompleteLoadingState: state => state.loadingState
}

const mutations = {
  [types.COMPLETE_ORDER] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.COMPLETE_ORDER_SUCCESS] (state, payload) {
    state.loadingState = loadingState.READY
  },

  [types.COMPLETE_ORDER_FAILED] (state, error) {
    state.loadingState = loadingState.ERROR
    state.error = error

    // Bugsnag 紀錄，若不需要的話，拿掉這段。
    console.error(error)
  }
}

const actions = {
  setOrderComplete ({ commit }, { orderId, postData }) {
    commit(types.COMPLETE_ORDER)

    return completeOrderDetail(orderId, postData)
      .then(response => commit(types.COMPLETE_ORDER_SUCCESS, response))
      .catch(err => {
        commit(types.COMPLETE_ORDER_FAILED, err)
        throw err
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
