<script>
import { mapGetters } from 'vuex'
import * as SETTINGS from '@/constants/storeSettings'
import HighlightElement from '../HighlightElement'

export default {
  name: 'HotkeyList',
  components: { HighlightElement },
  data () {
    return {
      SETTINGS,
      hotkeyItems: ['A01S+1', 'A01M+1', 'A01L+1']
    }
  },
  computed: {
    ...mapGetters('LiveSetting', ['newSettings', 'hoverElements'])
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
