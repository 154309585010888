<script>
import { mapGetters, mapActions } from 'vuex'
import handsupLogo from '@/assets/handsup-logo.svg'
import AppAlert from '@/components/AppAlert'
import { loadMessages } from '@/plugins/i18n'
import getSupportedLocale from '@/utils/getSupportedLocale'

const QUERY_STATUS = {
  FAILED: 'failed'
}

export default {
  name: 'LayoutUser',
  components: { AppAlert },
  data () {
    return {
      ...QUERY_STATUS,
      handsupLogo,
      status: this.$route.query.login_status,
      message: this.$route.query.message
    }
  },
  computed: {
    ...mapGetters('Lang', ['locale'])
  },
  async mounted () {
    const queryLocale = this.$route.query.locale
    if (queryLocale) {
      const locale = getSupportedLocale(queryLocale)
      await loadMessages(locale)
      this.setLocale({ locale })
    }
  },
  methods: {
    ...mapActions('Lang', ['setLocale']),
    goHome () {
      location.href = process.env.VUE_APP_BUYER_DOMAIN_NAME
    }
  }
}
</script>

<template lang="pug" src="./LayoutUser.pug"></template>
<style lang="scss" src="./LayoutUser.scss" scoped></style>
