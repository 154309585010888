import BatchApprove from './BatchApprove'
import SingleApprove from './SingleApprove'

export default {
  namespaced: true,
  modules: {
    BatchApprove,
    SingleApprove
  }
}
