<script>
import { FXH_KEYS } from '@/constants/common'
import { mapGetters } from 'vuex'
import { emailRule, passwordRule } from '@/utils/formRules'
import { FACEBOOK } from '@/constants/provider'
import AppAlert from '@/components/AppAlert'
import LayoutCard from '../components/LayoutCard'
import { getParentStore, getInviteStore, checkEmail } from '../service/apis'

export default {
  name: 'Login',
  skipMiddleware: ['checkAuth'],
  components: { LayoutCard, AppAlert },
  metaInfo () {
    return {
      meta: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0'
      }]
    }
  },
  data () {
    return {
      loading: false,
      form: {
        email: '',
        password: ''
      },
      autoLogin: this.$route.query.autoLogin !== false,
      title: 'User.Login.title',
      store: ''
    }
  },
  computed: {
    ...mapGetters('Me', ['isLogin', 'apiUrl']),
    ...mapGetters('Lang', ['locale']),
    loginPath () {
      return `${this.apiUrl}/auth/login`
    },
    formLocale () {
      return this.locale.replace('-', '_')
    },
    rules () {
      return {
        email: [
          ...emailRule(),
          { validator: this.emailValidator, trigger: 'blur' }
        ],
        password: passwordRule()
      }
    },
    hasFBbuyRedirectPath () {
      return !!this.$route.query[FXH_KEYS.fxhRedirectPath]
    },
    // 從 FBbuy那邊過來的都需要把一般登入隱藏
    isFromFBBuy () {
      return this.hasFBbuyRedirectPath || !!this.$route.query[FXH_KEYS.fxhPayload]
    }
  },
  async created () {
    // 已登入自動轉導, 但從 FBBUY 來的話不自動轉導
    if (this.isLogin && this.autoLogin && !this.isFromFBBuy) {
      this.$router.push({ name: 'Login' })
    }
    this.handleInvite()
  },
  methods: {
    async submit () {
      try {
        await this.$refs.form.validate()
      } catch {
        return
      }

      this.loading = true
      this.$refs.form.$el.submit()
    },
    fbLogin () {
      /**
       * 1. query[FXH_KEYS.fxhRedirectGroupId]
       *     代表 FBbuy 整合的粉專授權失效 需要登入觸發授權再跳回FBbuy 團別那邊
       * 2. query[FXH_KEYS.fxhPayload]
       *     代表 FBbuy 要綁定其他渠道 留在HandsUP的頁面中
       * 3. 原本的登入流程
       */
      let redirectPath = `${this.apiUrl}/auth/facebook?redirect=${location.origin}`
      redirectPath = this.hasFBbuyRedirectPath ? `${redirectPath}&fxh_redirect_path=${this.$route.query[FXH_KEYS.fxhRedirectPath]}` : redirectPath
      location.href = redirectPath
    },
    forgotPassword () {
      this.$router.push({ name: 'UserForgotPassword' })
    },
    goRegister () {
      this.$router.push({ name: 'UserRegister' })
    },
    async handleInvite () {
      if (localStorage.getItem('localInvite')) {
        this.handleInviteStore(localStorage.getItem('localInvite'))
      } else if (localStorage.getItem('localParentInvite')) {
        this.handleParentStore(localStorage.getItem('localParentInvite'))
      }
    },
    async handleInviteStore (token) {
      try {
        const { data: { name } } = await getInviteStore(token)
        this.title = 'User.Login.title_assistant'
        this.store = name
      } catch (e) {
        console.error(e)
        this.$message.error(this.$t('Invite.auth_fail'))
        localStorage.removeItem('localInvite')
      }
    },
    async handleParentStore (token) {
      try {
        const { data: { name } } = await getParentStore(token)
        this.title = 'User.Login.title_parent'
        this.store = name
      } catch (e) {
        console.error(e)
        this.$message.error(this.$t('Invite.auth_fail'))
        localStorage.removeItem('localParentInvite')
      }
    },

    async emailValidator (rule, value, callback) {
      try {
        const { data: { data } } = await checkEmail(value)

        if (data.is_handsup_user) {
          callback()
          return
        }

        let error

        if (!data.email_exists) {
          error = this.$t('field.email.not_member')
        }

        const fb = data.sns_email_exists.find(sns => sns === FACEBOOK)
        if (fb) {
          error = this.$t('field.email.sns_exist', { sns: fb })
        } else if (!fb && data.sns_email_exists.length > 0) {
          error = this.$t('field.email.not_member')
        }

        if (error) {
          callback(new Error(error))
        } else {
          callback()
        }
      } catch (e) {
        console.error(e)
        callback(new Error())
      }
    }
  }
}
</script>

<template lang="pug" src="./Login.pug"></template>
<style lang="scss" src="./Login.scss" scoped></style>
