<script>
import VueTypes from 'vue-types'

export default {
  name: 'CvsStatus',
  props: {
    order: VueTypes.object.isRequired,
    paymentUnpaid: VueTypes.bool.isRequired.def(false),
    isCvsExpired: VueTypes.bool.isRequired.def(false)
  }
}
</script>

<template lang="pug" src="./CvsStatus.pug"></template>
<style lang="scss" src="./CvsStatus.scss" scoped></style>
