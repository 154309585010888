<script>
import flvjs from 'flv.js'
import Hls from 'hls.js'

import SoundOnIcon from '@/assets/icons/icon-sound-on.svg'
import SoundOffIcon from '@/assets/icons/icon-sound-off.svg'
import ImgShadow from '@/assets/img-shadow.svg'

import { UNPUBLISHED, LIVE_STOPPED } from '@/constants/liveStatus'
import { mapGetters } from 'vuex'

export default {
  name: 'VideoPlayer',

  data () {
    return {
      SoundOnIcon,
      SoundOffIcon,
      ImgShadow,

      UNPUBLISHED,

      isPause: true,
      videoMuted: false
    }
  },

  computed: {
    ...mapGetters('Post', ['liveStatus', 'publication']),
    ...mapGetters('Post', ['streamUrl', 'replayUrl']),
    ...mapGetters('Post', ['watchingCount']),

    hasVideoUrl () {
      return this.liveStatus !== LIVE_STOPPED
        ? !!this.streamUrl
        : !!this.replayUrl
    }
  },

  watch: {
    streamUrl: function (value) {
      if (value) {
        this.$nextTick(() => this.playStream())
      }
    },
    replayUrl: function (value) {
      if (value) {
        this.$nextTick(() => this.playStream())
      }
    }
  },

  beforeDestroy () {
    const videoElement = document.getElementById('videoElement')
    if (videoElement) videoElement.removeEventListener(this.listenPlaying)
  },

  methods: {
    playStream () {
      const videoElement = document.getElementById('videoElement')

      if (flvjs.isSupported() && this.streamUrl && this.liveStatus !== LIVE_STOPPED) {
        // 支援 Flv 且 直播未結束
        var flvPlayer = flvjs.createPlayer(
          {
            type: 'flv',
            url: this.streamUrl
          },
          {
            fixAudioTimestampGap: false, // 主要是這裡
            autoCleanupSourceBuffer: true
          }
        )
        flvPlayer.attachMediaElement(videoElement)
        flvPlayer.load()
      } else if (this.replayUrl.includes('mp4')) {
        /**
         * 因 hls.js 播放 mp4 有問題, 所以檢查到 mp4 檔案時先用原本的 html video 播放回放影片
         */
        videoElement.src = this.replayUrl
      } else if (Hls.isSupported() && this.replayUrl) {
        var hls = new Hls()
        hls.loadSource(this.replayUrl)
        hls.attachMedia(videoElement)
      }

      if (videoElement) {
        videoElement.addEventListener('timeupdate', this.listenPlaying)
        videoElement.addEventListener('play', this.listenPlaying)
        videoElement.addEventListener('pause', this.listenPause)
        videoElement.addEventListener('canplay', () => {
          this.playVideo()
        })
      }
    },

    listenPlaying () {
      this.isPause = false
    },

    listenPause () {
      this.isPause = true
    },

    playVideo () {
      const videoElement = document.getElementById('videoElement')
      videoElement.play()
    },

    toggleMuteVideo () {
      const videoElement = document.getElementById('videoElement')
      this.videoMuted = !this.videoMuted
      videoElement.muted = this.videoMuted
    },

    isLandscape (embed) {
      const parser = new DOMParser()
      const html = parser.parseFromString(embed, 'text/html')
      const iframe = html.getElementsByTagName('iframe')[0]
      const width = iframe.getAttribute('width')
      const height = iframe.getAttribute('height')
      return width > height
    }
  }
}
</script>

<template lang="pug" src="./VideoPlayer.pug"></template>
<style lang="scss" src="./VideoPlayer.scss" scoped></style>
