<script>
import VueTypes from 'vue-types'

import { formatDate } from '@/filters/index.js'
import { getMinutes, splitMinutes } from '@/utils/timeOperation'
import ApiPost from '@/api/post'

export default {
  name: 'DialogSetPostTime',

  props: {
    perPostItem: VueTypes.object,
    enabledReplay: VueTypes.bool.def(false),
    enabledCheckoutTime: VueTypes.bool.def(true)
  },
  data () {
    const validateCheckoutRule = (rule, checkoutRule, callback) => {
      // 無限制
      if (!checkoutRule.hasLimit) {
        callback()
        return
      }

      if (checkoutRule.day || checkoutRule.hour || checkoutRule.minute) {
        callback()
      } else {
        callback(new Error(this.$t('Post.Show.DialogSetCheckTime.message.limit_error')))
      }
    }

    return {
      apiPost: new ApiPost(),
      dialogConfirmVisible: false,
      isBtnLoading: false,
      liveForm: {
        replay: {
          enabled: true,
          expiredAt: null
        },
        checkoutRule: {
          hasLimit: false,
          day: 3,
          hour: 0,
          minute: 0
        }
      },
      rules: {
        checkoutRule: [
          { validator: validateCheckoutRule, trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    preReplayExpiredAt () {
      return _.get(this.perPostItem, 'replay_expired_at', null)
    },
    replayExpiredAt () {
      const time = this.preReplayExpiredAt
      return time && formatDate(time, 'YYYY-MM-DD HH:mm')
    },

    preCartItemLife () {
      return this.perPostItem.cart_item_life || 0
    },
    isDayUpperLimit () {
      const upperLimitNumber = 30

      return this.liveForm.checkoutRule.day === upperLimitNumber
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      // 直播回放
      this.liveForm.replay.enabled = !!this.preReplayExpiredAt
      this.liveForm.replay.expiredAt = this.preReplayExpiredAt

      // 結帳期限
      if (this.preCartItemLife) {
        this.$set(
          this.liveForm,
          'checkoutRule',
          { hasLimit: true, ...splitMinutes(this.preCartItemLife) }
        )
      }
    },

    updateCheckoutDay () {
      if (this.isDayUpperLimit) {
        this.liveForm.checkoutRule.hour = 0
        this.liveForm.checkoutRule.minute = 0
      }
    },

    async validateForm () {
      try {
        await this.$refs.liveForm.validate()
        return true
      } catch (e) {
        return false
      }
    },

    async  handleSubmit () {
      // 表單驗證
      if (!await this.validateForm()) return

      // 若直播回放原先開啟，但調整「關閉」後，則要跳提醒訊息
      if (this.preReplayExpiredAt && !this.liveForm.replay.enabled) {
        this.dialogConfirmVisible = true
      } else {
        this.submit()
      }
    },
    async updatePost () {
      // 計算 買家結帳商品自動取消時間
      const cartItemLife = this.liveForm.checkoutRule.hasLimit
        ? getMinutes(this.liveForm.checkoutRule)
        : null

      let params = { cart_item_life: cartItemLife }

      // 是否開關 直播回放
      // TODO：
      // 關閉直播：帶 null 值
      // 設定時間：帶 絕對時間
      // 時間格式為 2019-12-05T15:59:59.000Z (絕對時間一律使用此格式)
      const replayEnable = this.liveForm.replay.enabled
      if (!replayEnable) params['replay_expired_at'] = null

      const result = await this.apiPost.update(this.perPostItem.id, params)

      return result
    },

    async submit () {
      if (this.isBtnLoading) return

      this.isBtnLoading = true
      if (await this.updatePost()) {
        await this.$emit('reFetch')
      }
      this.isBtnLoading = false

      this.close()
    },

    close () {
      this.$emit('close')
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogSetPostTime.pug"></template>
<style lang="scss" src="./DialogSetPostTime.scss" scoped></style>
