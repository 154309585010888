import { render, staticRenderFns } from "./StoreInfo.pug?vue&type=template&id=85c9d3ce&scoped=true&lang=pug&"
import script from "./StoreInfo.vue?vue&type=script&lang=js&"
export * from "./StoreInfo.vue?vue&type=script&lang=js&"
import style0 from "./StoreInfo.scss?vue&type=style&index=0&id=85c9d3ce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85c9d3ce",
  null
  
)

export default component.exports