<script>
// https://github.com/dwightjack/vue-types#documentation
import VueTypes from 'vue-types'

const SHIPPING_TYPE = {
  FAMILY: 'family', // 全家
  FAMILY_BUSINESS: 'family_business', // 全家大宗
  SEVEN: 'seven', // 7-11
  HSINCHU: 'hsinchu', // 新竹物流
  KERRY_TH: 'kerry_th' // KERRY
}

export default {
  name: 'InfoContentOther',
  components: {},
  props: {
    shippingType: VueTypes.string,
    isFamilyB2C: VueTypes.bool.def(false),
    undeliveredTotal: VueTypes.number
  },
  data () {
    return {
      ...SHIPPING_TYPE,
      kerryMethod: 'drop_off'
    }
  },
  computed: {
    fullShippingType () {
      let shippingType = this.shippingType
      // 是否為全家大宗，如果是回傳'family_business'
      if (this.shippingType === this.FAMILY && this.isFamilyB2C) shippingType = this.FAMILY_BUSINESS
      return shippingType
    }
  },
  methods: {
    updateKerry (event) {
      this.$emit('updateKerry', event)
    }
  }
}
</script>

<template lang="pug" src="./InfoContentOther.pug"></template>
<style lang="scss" src="./InfoContentOther.scss" scoped></style>
