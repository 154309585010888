import axios from '@/plugins/http.js'

export function saleStats (storeId, params) {
  const api = `/api/v1/stores/${storeId}/sale-stats`

  return axios.get(api, { params })
}

export function getUpcomingPosts (storeId) {
  const api = `/api/v1/stores/${storeId}/posts/upcoming`
  return axios.get(api)
}

export function getAnnouncements (storeId) {
  const api = `/api/v1/stores/${storeId}/announcements`
  return axios.get(api)
}

export function exportSales (storeId, params) {
  const api = `/api/v1/stores/${storeId}/sale-stats/export`
  return axios.get(api, { responseType: 'blob', params })
}
