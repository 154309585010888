import ApiChannel from '@/api/channel'
import { sendInfoToFBbuy } from '@/api/FBBuyBinding'
import { HANDSUP, M17_LIVE, M17_LIVE_V_2, FB_PAGE } from '@/constants/channelType'

const initialState = () => ({
  channels: [],
  infoFor17Live: {
    name: '',
    userId: '',
    accessToken: ''
  }
})

const state = initialState

const getters = {
  channels: (state) =>
    state.channels.map((channel) => {
      switch (channel.type) {
        case M17_LIVE_V_2:
          return {
            ...channel,
            accessToken: state.infoFor17Live.accessToken
          }
        default:
          return channel
      }
    }),
  channelsWithoutHandsUP: (state, getters) =>
    getters.channels.filter(
      (channel) => ![HANDSUP, M17_LIVE].includes(channel.type)
    ),
  has17LIVEChannel: (state, getters) =>
    getters.channelsWithoutHandsUP.some(
      (channel) => channel.type === M17_LIVE_V_2
    ),
  hasFBFanChannel: (state, getters) =>
    getters.channelsWithoutHandsUP.some(
      (channel) => channel.type === FB_PAGE
      ),
}

const mutations = {
  UPDATE_CHANNELS (state, payload) {
    state.channels = payload
  },
  RESET_CHANNELS (state) {
    Object.assign(state, initialState())
  },
  UPDATE_17_LIVE_INFO (state, payload) {
    state.infoFor17Live.name = payload.name
    state.infoFor17Live.userId = payload.info.user_id
    state.infoFor17Live.accessToken = payload.info.access_token
  }
}

const apiChannel = new ApiChannel()

const actions = {
  async fetchChannels ({ rootState, commit }, storeId) {
    try {
      const channels = await apiChannel.getChannels(
        storeId || rootState.Me.Store.currentStore.slug
      )
      commit('UPDATE_CHANNELS', channels.data.data)
    } catch (error) {
      console.error(error)
    }
  },
  async get17LiveChannelInfo ({ commit }) {
    try {
      const response = await apiChannel.get17LiveChannelInfo()
      commit('UPDATE_17_LIVE_INFO', response)
    } catch (error) {
      console.error(error)
    }
  },
  async submit17LiveChannel ({ rootGetters }, payload) {
    const isSuccess = await apiChannel.createOrUpdate17LiveChannel(payload)

    // FBbuy 整合，綁定 17後，需要額外送資訊給 FBbuy
    if(isSuccess && rootGetters['Me/isFBBuyStore']) {
      return sendInfoToFBbuy(rootGetters['Me/currentStore'].id)
    }
    return isSuccess
  },
  async addFBChannel (_, payload) {
    return apiChannel.create(payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
