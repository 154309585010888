<script>
import { twCellPhonePattern, digitPattern } from '@/constants/regexPattern'
import { validateEmptyString } from '@/utils/validateEmptyString'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SubForm',
  data () {
    return {
      form: {
        receiverName: '',
        receiverPhone: ''
      },

      rules: {
        receiverName: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            validator: validateEmptyString,
            trigger: 'blur'
          }
        ],
        receiverPhone: [
          {
            required: true,
            pattern: twCellPhonePattern,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('OrderManual/ShippingAddress', ['receiverName', 'receiverPhone'])
  },
  mounted () {
    this.form.receiverName = this.receiverName
    this.form.receiverPhone = this.receiverPhone
  },

  methods: {
    ...mapActions('OrderManual/ShippingAddress', ['updateReceiverName', 'updateReceiverPhone']),
    validateForm () {
      return this.$ref.form.validate()
    },
    updateName (value) {
      this.updateReceiverName(value)
      this.form.receiverName = value
    },
    updatePhone (value) {
      const removeNonDigits = value.replace(digitPattern, '')
      this.updateReceiverPhone(removeNonDigits)
      this.form.receiverPhone = removeNonDigits
    }
  }
}
</script>
<template lang="pug" src="./template.pug"></template>
