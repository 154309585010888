import axios from '@/plugins/http.js'
import BaseApi from './baseApi'
import storeId from './_storeId'

export default class Plan extends BaseApi {
  uri = `/api/v1/stores/${storeId()}/plans`
}

export function planRenew (storeId, planId, cardId) {
  const api = `/api/v1/stores/${storeId}/plans/${planId}/renew`
  const payload = { 'card_id': cardId }

  return axios.post(api, payload)
}

export function planRepay (storeId, cardId) {
  const api = `/api/v1/stores/${storeId}/plans/repay`
  const payload = { 'card_id': cardId }

  return axios.post(api, payload)
}
