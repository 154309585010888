<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

export default {
  name: 'DialogBankSetting',
  props: {
    dialogVisible: VueTypes.bool.def(false),
    setting: VueTypes.object.def({}),
    editable: VueTypes.bool.def(false)
  },
  data () {
    return {
      ruleForm: {
        bank_account: '',
        bank_name: '',
        bank_code: '',
        account_name: '',
        branch_name: ''
      },
      rules: {
        bank_account: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogPaymentSetting.validate_banking_account')
          },
          {
            pattern: /^[0-9]{11,}$/,
            message: this.$t('Setting.Seller.DialogBankSetting.account_validate')
          }
        ],
        bank_name: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogPaymentSetting.validate_banking_name')
          }
        ],
        bank_code: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogPaymentSetting.validate_banking_code')
          }
        ],
        account_name: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogPaymentSetting.validate_account_name')
          }
        ],
        branch_name: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogPaymentSetting.validate_branch_name')
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore'])
  },
  mounted () {
    this.ruleForm = { ...this.ruleForm, ...this.setting.info }
  },
  methods: {
    async handleSubmit () {
      // 驗證欄位
      try {
        await this.$refs.ruleForm.validate()
      } catch (error) {
        return
      }
      const data = {
        type: this.setting.type,
        enabled: true,
        info: { ...this.ruleForm }
      }
      this.$emit('submit', data)
    },
    handleClose () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
