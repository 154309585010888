import _ from 'lodash'
import { dedupeConcat } from '@/utils/dedupe'
import * as loadingState from '@/constants/loadingState'

import {
  fetchHistoryFromAllChannels
} from '../../apis/pubnub'
import { hideComment, showComment } from '../../apis/post'

import * as MESSAGE_TYPES from '../../../constants/messageTypes'

const PAGE_MESSAGE_LIMIT = 3500

export const types = {
  FETCH_HISTORICAL_MESSAGES: 'FETCH_HISTORICAL_MESSAGE',
  FETCH_HISTORICAL_MESSAGES_SUCCESS: 'FETCH_HISTORICAL_MESSAGES_SUCCESS',
  FETCH_HISTORICAL_MESSAGES_FAILED: 'FETCH_HISTORICAL_MESSAGES_FAILED',
  HISTORICAL_MESSAGES_HAS_REACHED_TOP: 'HISTORICAL_MESSAGES_HAS_REACHED_TOP',

  APPEND_MESSAGE: 'APPEND_MESSAGE',
  APPEND_ANALYZE_FAILED_MESSAGE: 'APPEND_ANALYZE_FAILED_MESSAGE',
  APPEND_ANALYZE_SUCCESS_MESSAGE: 'APPEND_ANALYZE_SUCCESS_MESSAGE',
  APPEND_NOTIFICATION: 'APPEND_NOTIFICATION',

  SCAN_AND_APPEND_FAILED_MESSAGES: 'SCAN_AND_APPEND_FAILED_MESSAGES',
  SCAN_AND_APPEND_CART_MESSAGES: 'SCAN_AND_APPEND_CART_MESSAGES',

  RESET_MESSAGES: 'RESET_MESSAGES',
  TOGGLE_HIDE_COMMENT: 'TOGGLE_HIDE_COMMENT'
}

const COMMENT_ANALYZE_STATUS = {
  EMPTY: 'empty',
  SUCCESS: 'success',
  FAILED: 'failed'
}

const INIT_DATA = {
  historicalMessages: [],
  currentMessages: [],
  notifications: [],
  messageMap: {},

  // This map serves as an annotation if channel has reached
  // top message:
  //
  // {
  //   [comments|fb]: true
  //   [comments|handsup]: true
  // }
  //
  // If the value of the channel is set to true, that means there
  // aren't any message to load for that channels.
  channelsHasReachedTop: {},

  // Records time token from the latest retrieval of messages.
  // We are able to know where to retrieve the next segment
  // of historical comments based on this token:
  //
  //   {
  //     [channel_name_1]: time_token_1,
  //     [channel_name_2]: time_token_2
  //   }
  pubnubCommentsOffset: {},
  errMessage: null,
  loadingState: loadingState.EMPTY
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  getLoadingState: state => state.loadingState,

  getNotifications: state => state.notifications,

  getHistoricalMessages: state => state.historicalMessages,

  getCurrentMessages: state => state.currentMessages,

  getPubnubCommentsOffset: state => state.pubnubCommentsOffset,

  getChannelsHasReachedTop: state => state.channelsHasReachedTop
}

const mutations = {
  [types.FETCH_HISTORICAL_MESSAGES] (state) {
    state.loadingState = loadingState.LOADING
    state.errMessage = null
  },

  [types.FETCH_HISTORICAL_MESSAGES_SUCCESS] (state, { messages, timeTokenMap, hasReachTopMap, hiddenMessageSet }) {
    state.pubnubCommentsOffset = {
      ...state.pubnubCommentsOffset,
      ...timeTokenMap
    }

    state.channelsHasReachedTop = {
      ...state.channelsHasReachedTop,
      ...hasReachTopMap
    }

    const normalizedMessages = messages
      .map(msg => ({
        ...msg.entry.data,
        timetoken: msg.timetoken,
        type: msg.entry.type,
        analyzeStatus: COMMENT_ANALYZE_STATUS.EMPTY,
        created_time: msg.entry.data.created_time * 1000,
        hidden: hiddenMessageSet.has(msg.entry.data.comment_id)
      }))

    state.historicalMessages = dedupeConcat(normalizedMessages, state.historicalMessages, {
      idSelector: comment => comment.comment_id,
      useFirstList: true
    })
      .sort((a, b) => a.created_time - b.created_time)

    state.loadingState = loadingState.READY
    state.errMessage = null

    // Build historical message map
    for (const message of state.historicalMessages) {
      state.messageMap[message.comment_id] = message
    }
  },

  [types.FETCH_HISTORICAL_MESSAGES_FAILED] (state, { errMessage }) {
    state.errMessage = errMessage
    state.loadingState = loadingState.ERROR
  },

  [types.HISTORICAL_MESSAGES_HAS_REACHED_TOP] (state) {
    state.loadingState = loadingState.READY
  },

  [types.APPEND_MESSAGE] (state, { message, type }) {
    const newMessage = {
      ...message,
      type,
      analyzeStatus: COMMENT_ANALYZE_STATUS.EMPTY,
      hidden: false
    }
    // concat for watchers
    state.currentMessages = state.currentMessages.concat(newMessage)
    state.messageMap[newMessage.comment_id] = newMessage
  },

  // Find parsed failed message in 'rawComment' comment id.
  // append the parsed failed reason beneath that message.
  [types.APPEND_ANALYZE_FAILED_MESSAGE] (state, { message }) {
    const {
      comment_id: failedID,
      reason
    } = message

    const failedMessage = state.messageMap[failedID]
    if (failedMessage) {
      failedMessage.reasonCode = reason
      failedMessage.analyzeStatus = COMMENT_ANALYZE_STATUS.FAILED
    }
  },

  [types.APPEND_ANALYZE_SUCCESS_MESSAGE] (state, { message }) {
    const {
      comment_id: successID,
      skus
    } = message

    const successMessage = state.messageMap[successID]
    if (successMessage) {
      successMessage.skus = skus
      successMessage.analyzeStatus = COMMENT_ANALYZE_STATUS.SUCCESS
    }
  },

  [types.APPEND_NOTIFICATION] (state, { message }) {
    state.notifications = state.notifications.concat(message)
  },

  [types.SCAN_AND_APPEND_FAILED_MESSAGES] (state, failedMessages) {
    for (const message of failedMessages) {
      const commentId = message?.entry?.data?.['comment_id']
      const matchedMessage = state.messageMap[commentId]
      if (matchedMessage) {
        matchedMessage.reasonCode = message?.entry?.data?.['reason']
        matchedMessage.analyzeStatus = COMMENT_ANALYZE_STATUS.FAILED
      }
    }
  },

  [types.SCAN_AND_APPEND_CART_MESSAGES] (state, successMessages) {
    for (const message of successMessages) {
      const commentId = message?.entry?.data?.['comment_id']
      const matchedMessage = state.messageMap[commentId]
      if (matchedMessage) {
        matchedMessage.skus = message?.entry?.data?.['skus']
        matchedMessage.analyzeStatus = COMMENT_ANALYZE_STATUS.SUCCESS
      }
    }
  },

  [types.RESET_MESSAGES] (state) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))
  },

  [types.TOGGLE_HIDE_COMMENT] (state, { commentId, flag }) {
    const comment = state.messageMap[commentId]
    if (comment) {
      comment.hidden = flag
    }
  }

}

const actions = {
  async fetchHistoricalMessage ({ commit, getters, rootGetters }, { cartChans, commentChans, failedChans }) {
    commit(types.FETCH_HISTORICAL_MESSAGES)
    try {
      // Check to see if any channels have more messages to load.
      const channelsHaveMoreComments = commentChans
        .filter(channel => !getters.getChannelsHasReachedTop[channel])

      if (channelsHaveMoreComments.length === 0) {
        return commit(types.HISTORICAL_MESSAGES_HAS_REACHED_TOP)
      }

      const [messages, timeTokenMap, hasReachTopMap] = await fetchHistoryFromAllChannels(
        channelsHaveMoreComments,
        getters.getPubnubCommentsOffset
      )
      const hiddenMessageSet = new Set(rootGetters['Post/post']?.['hidden_comments'] || [])

      commit(types.FETCH_HISTORICAL_MESSAGES_SUCCESS, { messages, timeTokenMap, hasReachTopMap, hiddenMessageSet })

      const [failedMessages] = await fetchHistoryFromAllChannels(failedChans)
      const [successMessages] = await fetchHistoryFromAllChannels(cartChans)

      commit(types.SCAN_AND_APPEND_FAILED_MESSAGES, failedMessages)
      commit(types.SCAN_AND_APPEND_CART_MESSAGES, successMessages)

      return messages
    } catch (err) {
      commit(types.FETCH_HISTORICAL_MESSAGES_FAILED, {
        errMessage: err.message
      })

      return err
    }
  },

  // We need to handle following message types:
  //
  //   - comment-failed
  //   - comment
  //   - cart
  //   - notification
  //
  // The message is in the following pattern;
  //
  //   comment|fb_page|107352327358369_923256988070960
  //
  appendMessage ({ commit, dispatch }, { message }) {
    const splitComment = comment => comment.split('|')

    const { channel, message: rawMsg } = message

    const [type] = splitComment(channel)

    if (type === MESSAGE_TYPES.COMMENT_FAILED) {
      commit(types.APPEND_ANALYZE_FAILED_MESSAGE, {
        message: rawMsg.data
      })

      return
    }

    if (type === MESSAGE_TYPES.COMMENT) {
      // 判斷目前留言是否超過可承載計算量
      dispatch('checkMessageOverload')

      commit(types.APPEND_MESSAGE, {
        message: {
          ...rawMsg.data,
          created_time: rawMsg.data.created_time * 1000
        },
        type: rawMsg.type
      })

      return
    }

    if (type === MESSAGE_TYPES.CART) {
      commit(types.APPEND_ANALYZE_SUCCESS_MESSAGE, {
        message: rawMsg.data
      })

      return
    }

    if (type === MESSAGE_TYPES.NOTIFICATION) {
      commit(types.APPEND_NOTIFICATION, {
        message: rawMsg
      })

      dispatch('Post/triggerNotification', rawMsg, { root: true })
    }
  },

  checkMessageOverload: _.throttle(function ({ state }) {
    if (state.currentMessages.length > PAGE_MESSAGE_LIMIT) {
      location.reload()
    }
  }, 5000),

  resetMessages ({ commit }) {
    commit(types.RESET_MESSAGES)
  },

  hideMessage ({ commit }, commentId) {
    commit(types.TOGGLE_HIDE_COMMENT, { commentId, flag: true })
  },

  showMessage ({ commit }, commentId) {
    commit(types.TOGGLE_HIDE_COMMENT, { commentId, flag: false })
  },

  async updateHideMessage ({ dispatch, rootGetters }, comment) {
    const postId = rootGetters['Post/post']?.id

    try {
      const payload = {
        provider: comment?.from?.provider,
        provider_id: comment?.from.id,
        comment_id: comment?.['comment_id']
      }
      const { data: { success } } = await hideComment(postId, payload)
      if (success) dispatch('hideMessage', comment.comment_id)
    } catch (e) {
      console.error(e)
    }
  },

  async updateShowMessage ({ dispatch, rootGetters }, comment) {
    const postId = rootGetters['Post/post']?.id

    try {
      const { data: { success } } = await showComment(postId, comment.comment_id)
      if (success) dispatch('showMessage', comment.comment_id)
    } catch (e) {
      console.error(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
