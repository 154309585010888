// translate for seller setting form key name

const personal = {
  registrant_name: 'formfield.personal.registrant_name', // 賣家姓名
  registrant_identity_number: 'formfield.personal.registrant_identity_number', // 身分證字號
  registrant_date: 'formfield.personal.registrant_date', // 生日
  registrant_mobile: 'formfield.personal.registrant_mobile', // 手機號碼
  registrant_address: 'formfield.personal.registrant_address' // 聯絡地址
}

const company = {
  registrant_name: 'formfield.company.registrant_name', // 公司名稱
  registrant_identity_number: 'formfield.company.registrant_identity_number', // 公司統編
  registrant_date: 'formfield.company.registrant_date', // 公司核准設立日期
  registrant_mobile: 'formfield.company.registrant_mobile', // 負責人手機號碼
  registrant_company_representative: 'formfield.company.registrant_company_representative', // 負責人姓名
  registrant_address: 'formfield.company.registrant_address', // 公司地址
  registrant_company_phone: 'formfield.company.registrant_company_phone' // 聯絡電話
}

export default { personal, company }
