import User from './User'
import Store from './Store'
import Constants from './Constants'
import EnableFeature from './EnableFeature'
import StoreChannels from './StoreChannels'

export default {
  namespaced: true,
  modules: {
    User,
    Store,
    Constants,
    EnableFeature,
    StoreChannels
  }
}
