<script>
import { mapGetters, mapActions } from 'vuex'
import ApiService from '@/api/additionalService'

export default {
  name: 'BuyPoints',
  components: {},
  data () {
    return {
      fee: 55,
      points: 10,
      remainPoints: 0
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'currencySymbol']),
    price () {
      return this.points * this.fee
    }
  },
  async created () {
    this.setLoading(true)
    await this.setAdditionalInfomation()
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('AdditionalService', ['updateService', 'updateInformation']),
    handleClose () {
      this.$emit('close')
    },
    handleSubmit () {
      this.updateService({
        point: this.points,
        amount: this.points * this.fee
      })
      this.$router.push(`/${this.storeName}/payment`)
    },
    async setAdditionalInfomation () {
      const apiService = new ApiService()
      const data = await apiService.getShow('family_business')
      if (!data) {
        this.$message.error(this.$t('Components.DialogBuyPoints.points_error'))
        this.handleClose()
        return
      }
      this.remainPoints = data.summary.quantity
      this.fee = data.fee
      this.updateInformation({
        name: name,
        fee: data.fee
      })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
