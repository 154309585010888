import BaseApi from './baseApi'
import storeId from './_storeId'
import axios from '@/plugins/http.js'

export default class ProductAnchor extends BaseApi {
  getProductAnchorList = async (postId) => {
    const api = `/api/v1/stores/${storeId()}/posts/${postId}/anchors`

    const result = await axios.get(api)
    return result.data.data
  }

  updateProductAnchors = async (postId, payload) => {
    const api = `/api/v1/stores/${storeId()}/posts/${postId}/anchors`
    const result = await axios.post(api, payload)

    return result.data.data
  }
}
