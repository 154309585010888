<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProductFormOther',

  data () {
    const validateSaleStartTime = (rule, value, callback) => {
      const saleEnd = this.ruleForm.sale_ended_at

      if (saleEnd) {
        this.$refs.ruleForm.validateField('sale_ended_at')
      }

      callback()
    }

    const validateSaleEndTime = (rule, value, callback) => {
      const saleStart = this.ruleForm.sale_started_at
      const saleEnd = this.ruleForm.sale_ended_at

      if (this.ruleForm.isPermanentSale) {
        callback()
        return
      }

      if (!saleEnd) {
        callback(new Error(this.$t('Setting.Payment.DialogStorePickup.validate')))
        return
      }

      if (saleStart) {
        if (new Date(saleStart) > new Date(saleEnd)) {
          callback(new Error(this.$t('Components.ProductFormOther.validate_start_after_end')))
          return
        }
      }

      callback()
    }

    return {
      isSetSaleTime: false,

      ruleForm: {
        sale_started_at: null,
        sale_ended_at: null,
        isPermanentSale: false
      },
      rules: {
        sale_started_at: [
          { required: true, message: this.$t('Setting.Payment.DialogStorePickup.validate'), trigger: 'change' },
          { validator: validateSaleStartTime, trigger: 'blur' }
        ],
        sale_ended_at: [
          { validator: validateSaleEndTime, trigger: 'blur' }
        ]
      }

    }
  },

  computed: {
    ...mapGetters('ProductDetail', {
      productDescription: 'description',
      productSaleStartedAt: 'saleStartedAt',
      productSaleEndedAt: 'saleEndedAt',
      productAdultWarning: 'adultWarning'
    }),

    description: {
      get () {
        return this.productDescription
      },
      set (val) {
        this.updateDescription(val)
      }
    },

    adultWarning: {
      get () {
        return this.productAdultWarning
      },
      set (val) {
        this.updateAdultWarning(val)
      }
    }
  },

  watch: {
    'ruleForm.sale_started_at': function (newValue, oldValue) {
      this.updateSaleStartedAt(newValue)
    },
    'ruleForm.sale_ended_at': function (newValue, oldValue) {
      this.updateSaleEndedAt(newValue)
    }
  },

  created () {
    this.initData()
  },

  methods: {
    ...mapActions('ProductDetail', [
      'updateDescription',
      'updateSaleStartedAt',
      'updateSaleEndedAt',
      'updateAdultWarning'
    ]),

    initData () {
      // 是否有設定商品銷售時間
      this.isSetSaleTime = !!this.productSaleStartedAt || !!this.productSaleEndedAt

      if (this.isSetSaleTime) {
        this.ruleForm.sale_started_at = this.productSaleStartedAt
          ? new Date(this.productSaleStartedAt)
          : null
        this.ruleForm.sale_ended_at = this.productSaleEndedAt
          ? new Date(this.productSaleEndedAt)
          : null

        // 如果有銷售起始時間，但沒有銷售結束時間，代表商品永久販售
        if (!!this.productSaleStartedAt && !this.productSaleEndedAt) {
          this.ruleForm.isPermanentSale = true
        }
      }
    },

    handlePermanentSale (bol) {
      if (bol) {
        this.ruleForm.sale_ended_at = ''
        this.$refs.ruleForm.validateField('sale_ended_at')
      }
    },

    validateForm () {
      if (this.isSetSaleTime) {
        return this.$refs['ruleForm'].validate()
      } else {
        this.ruleForm.sale_started_at = null
        this.ruleForm.sale_ended_at = null
        this.ruleForm.isPermanentSale = false
        return true
      }
    }
  }
}
</script>

<template lang="pug" src="./ProductFormOther.pug"></template>
