import Cookies from 'js-cookie'
import getSupportedLocale from '@/utils/getSupportedLocale'
import * as LOCALES from '@/constants/locales'

// locale cookie is read only for development and testing
// set manually in browser [zh-TW, ja, en]
const locale =
  process.env.VUE_APP_STAGE === 'production' ? null : Cookies.get('locale_ccc')

// state
export const state = {
  locale: locale
}

// getters
const getters = {
  locale: (state, getters, rootState, rootGetters) => {
    if (state.locale) return state.locale

    const currentStore = rootGetters['Me/currentStore']
    if (!currentStore || !currentStore.region) {
      return getSupportedLocale(navigator.language)
    }
    const region = currentStore.region.toLowerCase()

    const regionLocales = {
      tw: LOCALES.TW,
      jp: LOCALES.JP
    }

    return regionLocales[region] || LOCALES.JP
  }
}

// mutations
const mutations = {
  SET_LOCALE (state, { locale }) {
    state.locale = locale
  }
}

// actions
const actions = {
  setLocale ({ commit }, { locale }) {
    commit('SET_LOCALE', { locale })

    document.documentElement.lang = locale
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
