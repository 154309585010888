<script>
import ApiPlan from '@/api/plan'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  skipMiddleware: ['checkAuth'],

  name: 'Payment',
  data () {
    return {
      apiPlan: null,
      plan: {},
      checked: false, // TODO：有合約時，要改為 false
      paymentRequestIsSent: false
    }
  },

  computed: {
    ...mapGetters('Me', ['currentStore', 'currencySymbol']),
    ...mapState('Plan', ['buyingPlan']),
    frequencyText () {
      return this.buyingPlan.frequency === 'monthly' ? '月' : '年'
    },
    amount () {
      return this.plan[`${this.buyingPlan.frequency}_fee`]
    }
  },

  async created () {
    if (!this.buyingPlan.frequency) {
      this.$router.push({ name: 'PlanSelect' })
      return
    }
    this.apiPlan = new ApiPlan()
    await this.fetchPlan()
    // [Facebook Pixel] 追蹤開始結帳流程事件
    let initCheckoutTrackParams = {
      Level: this.plan.title,
      Frequency: this.buyingPlan.frequency,
      currency: this.currentStore.currency,
      value: Math.floor(this.plan.amount)
    }
    // 實際交易資料僅回傳公司自有兩個像素
    this.$analytics.fbq.query('trackSingle', process.env.VUE_APP_FB_ANALYTICS_PIXEL_ID, 'InitiateCheckout', initCheckoutTrackParams)
    this.$analytics.fbq.query('trackSingle', process.env.VUE_APP_FB_ADS_PIXEL_ID, 'InitiateCheckout', initCheckoutTrackParams)
    this.$ga.event({
      eventCategory: 'InitiateCheckout-' + this.plan.title,
      eventAction: 'Plan-' + this.plan.region,
      eventLabel: 'Merchant-Platform',
      eventValue: Math.floor(this.plan.amount)
    })
  },

  mounted () {
    // TAPPAY SDK REMOVED
    this.setTappay()
  },

  methods: {
    ...mapActions('Me', ['fetchStores']),
    ...mapActions(['setLoading']),
    async fetchPlan () {
      const data = await this.apiPlan.get(this.buyingPlan.id, {
        frequency: this.buyingPlan.frequency
      })

      if (!data) {
        this.$router.push({ name: 'PlanSelect' })
      }
      this.plan = data
    },
    setTappay () {
      TPDirect.card.setup({
        fields: {
          number: {
            element: '.card-number',
            placeholder: '**** **** **** ****'
          },
          expirationDate: {
            element: '.card-expiration-date',
            placeholder: 'MM / YY'
          },
          ccv: {
            element: '.card-ccv',
            placeholder: 'ccv'
          }
        },
        styles: {
          'input': {
            'color': 'gray'
          },
          'input.card-number': {
            'font-size': '0.875rem'
          },
          'input.expiration-date': {
            'font-size': '0.875rem'
          },
          'input.cvc': {
            'font-size': '0.875rem'
          },
          '.valid': {
            'color': 'green'
          },
          '.invalid': {
            'color': 'red'
          }
        }
      })
    },

    update () {
      const tappayStatus = TPDirect.card.getTappayFieldsStatus()

      if (tappayStatus.canGetPrime === false) {
        this.$message.error('付款資訊填寫不正確')
        return
      }

      const that = this

      TPDirect.card.getPrime(async (result) => {
        that.paymentRequestIsSent = true

        try {
          let success = false

          if (result.status === 0) {
            success = await this.apiPlan.update(this.plan.id, {
              frequency: this.buyingPlan.frequency,
              prime: result.card.prime
            })
          }

          if (success) {
            // 追蹤成功購買方案事件
            let purchaseTrackParams = {
              Level: this.plan.title,
              Frequency: this.frequencyText + '繳',
              currency: 'TWD',
              value: Math.floor(this.plan.amount)
            }
            // 實際交易資料僅回傳公司自有兩個像素
            this.$analytics.fbq.query('trackSingle', process.env.VUE_APP_FB_ANALYTICS_PIXEL_ID, 'Purchase', purchaseTrackParams)
            this.$analytics.fbq.query('trackSingle', process.env.VUE_APP_FB_ADS_PIXEL_ID, 'Purchase', purchaseTrackParams)

            await this.fetchStores()
            this.$message.success('購買方案成功')
            this.$router.push('/')
          } else {
            this.$message.error('購買方案失敗')
          }
        } catch (e) {
          console.error(e)
        }

        that.paymentRequestIsSent = false
      })
    },
    toSettingSeller () {
      this.$router.push({ name: 'Setting', query: { activeMenu: 'SettingSeller', 'seller': true } },
        () => {
          this.emitMenuSettingSeller()
        },
        () => {
          this.emitMenuSettingSeller()
        })
    },
    emitMenuSettingSeller () {
      this.$emit('updateActiveMenu', {
        name: 'menu_seller',
        component: 'SettingSeller'
      })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
