import * as loadingState from '@/constants/loadingState'

import { updateSubscription as apiUpdateSubscription } from '../apis'

export const types = {
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
  UPDATE_SUBSCRIPTION_SUCCESS: 'UPDATE_SUBSCRIPTION_SUCCESS',
  UPDATE_SUBSCRIPTION_FAILED: 'UPDATE_SUBSCRIPTION_FAILED'
}

const INIT_DATA = {
  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  updateSubscriptionLoadingState: state => state.loadingState
}

const mutations = {
  [types.UPDATE_SUBSCRIPTION] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.UPDATE_SUBSCRIPTION_SUCCESS] (state) {
    state.loadingState = loadingState.READY
  },

  [types.UPDATE_SUBSCRIPTION_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  }
}

const actions = {
  updateSubscription ({ commit }, { storeSlug, putData }) {
    commit(types.UPDATE_SUBSCRIPTION)

    return apiUpdateSubscription(storeSlug, putData)
      .then(response => commit(types.UPDATE_SUBSCRIPTION_SUCCESS))
      .catch(err => {
        commit(types.UPDATE_SUBSCRIPTION_FAILED, err)
        throw err
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
