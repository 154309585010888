import * as loadingState from '@/constants/loadingState'

import { getStreamInfo, getReplayInfo } from '../../apis/liveweb'

export const types = {
  FETCH_STREAM_INFO: 'FETCH_STREAM_INFO',
  FETCH_STREAM_INFO_SUCCESS: 'FETCH_STREAM_INFO_SUCCESS',
  FETCH_STREAM_INFO_FAILED: 'FETCH_STREAM_INFO_FAILED',

  RESET_STREAM_INFO: 'RESET_STREAM_INFO'
}

const INIT_DATA = {
  streamInfo: null,
  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  streamInfo: state => state.streamInfo,
  streamUrl: state => (state?.streamInfo?.['stream_url'] ?? '').replace('http:', 'https:'),
  replayUrl: state => {
    const replayUrl = state?.streamInfo?.['replay_url'] ?? ''

    let videoUrl = ''

    // Note:
    // 若無回放影片網址的話，後端回傳 Array 格式，
    // 所以需判斷是否為 string，代表有無回放網址
    if (typeof replayUrl === 'string') {
      videoUrl = replayUrl
    }

    return videoUrl.replace('http:', 'https:')
  },

  streamInfoLoadingState: state => state.loadingState
}

const mutations = {
  [types.FETCH_STREAM_INFO] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.FETCH_STREAM_INFO_SUCCESS] (state, response) {
    state.streamInfo = response.data
    state.loadingState = loadingState.READY
  },

  [types.FETCH_STREAM_INFO_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  },

  [types.RESET_STREAM_INFO] (state) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))
  }
}

const actions = {
  fetchStreamInfo ({ commit }, postId) {
    if (!postId) return

    commit(types.FETCH_STREAM_INFO)

    return getStreamInfo(postId)
      .then(response => commit(types.FETCH_STREAM_INFO_SUCCESS, response))
      .catch(err => {
        if (err.data.type !== `HandsUp\\Stream\\Exceptions\\LiveStreamException`) {
          console.error(err)
          commit(types.FETCH_STREAM_INFO_FAILED, err)
        }

        throw err
      })
  },

  fetchReplayInfo ({ commit }, postId) {
    if (!postId) return

    commit(types.FETCH_STREAM_INFO)

    return getReplayInfo(postId)
      .then(response => commit(types.FETCH_STREAM_INFO_SUCCESS, response))
      .catch(err => {
        throw err
      })
  },

  resetStreamInfo ({ commit }) {
    commit(types.RESET_STREAM_INFO)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
