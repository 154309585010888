<script>
import { mapGetters } from 'vuex'
import VueTypes from 'vue-types'
import AddressSelect from '@/components/AddressSelect'
import GmoFormMixin from '../../mixins/GmoFormMixin'

export default {
  name: 'Step3',
  components: { AddressSelect },
  mixins: [GmoFormMixin],
  props: {
    form: VueTypes.object.def({}),
    editDisabled: VueTypes.bool.def(false)
  },
  data () {
    return {
      errorStep3: {
        identify: [ { required: true, message: 'を入力してください' } ],
        commercial_company_name: [ { required: true, message: '法人名を入力してください' } ],
        commercial_name_last: [ { required: true, message: '代表者または運営統括責任者姓を入力してください' } ],
        commercial_name_first: [ { required: true, message: '代表者または運営統括責任者名を入力してください' } ],
        commercial_name_kana_last: [ { required: true, message: '代表者または運営統括責任者姓（ﾌﾘｶﾞﾅ）を入力してください' } ],
        commercial_name_kana_first: [ { required: true, message: '代表者または運営統括責任者名（ﾌﾘｶﾞﾅ）を入力してください' } ],
        commercial_url: [ { required: true, message: 'ホームページを入力してください' } ],
        commercial_email: [
          { required: true, message: '電子メールを入力してください' },
          { type: 'email', message: '正しいメールアドレスを入力してください' }
        ],
        commercial_phone: [
          { required: true, message: '事業者の連絡先(電話番号)を入力してください' },
          { pattern: /^[0-9]+$/, message: '番号を入力してください' }
        ],
        opening_time: [ { required: true, message: 'その他(営業時間・定休日等)を入力してください' } ],
        selling_price: [ { required: true, message: '販売価格についてを入力してください' } ],
        payment_info: [ { required: true, message: '代金(対価)の支払方法と時期を入力してください' } ],
        delivery_info: [ { required: true, message: '商品等の引き渡し時期（日数）、発送方法を入力してください' } ],
        return_info: [ { required: true, message: '返品についての特約に関する事項を入力してください' } ],
        extra_fees: [ { required: true, message: '商品代金以外に必要な費用/送料、消費税等を入力してください' } ],
        defective_info: [ { required: true, message: '不良品の取扱条件を入力してください' } ],
        is_jasrac: [ { required: true, message: '選択してください' } ]
      },
      identifyOption: [
        { text: '個人', value: 'individual' },
        { text: '法人', value: 'company' }
      ]
    }
  },
  computed: {
    ...mapGetters('Me', ['region']),
    validateStep3 () {
      return {
        identify: this.form.identify,
        commercial_company_name: this.form.commercial_company_name,
        commercial_name_last: this.form.commercial_name_last,
        commercial_name_first: this.form.commercial_name_first,
        commercial_name_kana_last: this.form.commercial_name_kana_last,
        commercial_name_kana_first: this.form.commercial_name_kana_first,
        commercial_url: this.form.commercial_url,
        commercial_email: this.form.commercial_email,
        commercial_phone: this.form.commercial_phone[0] && this.form.commercial_phone[1] && this.form.commercial_phone[2]
          ? this.form.commercial_phone[0] + this.form.commercial_phone[1] + this.form.commercial_phone[2] : '',
        opening_time: this.form.opening_time,
        selling_price: this.form.selling_price,
        payment_info: this.form.payment_info,
        delivery_info: this.form.delivery_info,
        return_info: this.form.return_info,
        extra_fees: this.form.extra_fees,
        defective_info: this.form.defective_info,
        is_jasrac: this.form.is_jasrac
      }
    }
  },
  methods: {
    async validate () {
      try {
        await this.$refs['formStep3'].validate()
        await this.$refs['commercial_address'].validateForm()
        return true
      } catch (error) {
        throw error
      }
    }
  }
}
</script>

<template lang="pug" src="./Step3.pug"></template>
