import ApiAuction from '@/api/auction'

import * as ACTIVITY_STATUS from '@/constants/activityStatus'

const apiAuction = new ApiAuction()

const INIT_DATA = {
  activityAuctions: [],
  selectAuction: null,

  dialogAuctionVisible: false
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  activityAuctions: state => state.activityAuctions,
  selectAuction: state => state.selectAuction,
  dialogAuctionVisible: state => state.dialogAuctionVisible,

  currentAuction: state => {
    // 目前 正在進行中的活動
    return state.activityAuctions.find(activity => activity.status === ACTIVITY_STATUS.STARTED)
  },

  hasCurrentAuction: (_, getters) => {
    // 正在進行中：有 開始時間 且 無結束時間
    return !!getters.currentAuction
  }
}

const mutations = {
  UPDATE_ACTIVITY_AUCTIONS (state, payload) {
    state.activityAuctions = payload
  },

  UPDATE_SELECT_AUCTION (state, auction) {
    state.selectAuction = auction
  },

  UPDATE_DIALOG_AUCTION_VISIBLE (state, bol) {
    state.dialogAuctionVisible = bol
  }
}

const actions = {
  async fetchActivityAuctions ({ commit }, postId) {
    if (!postId) return

    return apiAuction.getAuctions(postId)
      .then(response => {
        const { data } = response.data
        commit('UPDATE_ACTIVITY_AUCTIONS', data)
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  },

  checkAuctionActivity ({ getters, dispatch }) {
    if (getters.hasCurrentAuction) {
      dispatch('setSelectAuction', getters.currentAuction.id)
      dispatch('updateDialogAuctionVisible', true)
    }
  },

  setSelectAuction ({ getters, commit }, auctionId) {
    const auction = getters.activityAuctions.find(item => item.id === auctionId)

    if (auction) {
      commit('UPDATE_SELECT_AUCTION', auction)
    } else {
      commit('UPDATE_SELECT_AUCTION', null)
    }
  },

  clearSelectAuction ({ commit }) {
    commit('UPDATE_SELECT_AUCTION', null)
  },

  updateDialogAuctionVisible ({ commit }, bol) {
    commit('UPDATE_DIALOG_AUCTION_VISIBLE', bol)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
