<script>
import { mapGetters, mapActions } from 'vuex'

import * as LIVE_STATUS from '@/constants/liveStatus'

// 抽獎模組
import DrawForm from '../DrawForm'
import DrawResultDialog from '../DrawResultDialog'

// 拍賣
import ShowActivity from '../ShowActivity'

// 喊單模組(海鮮標)
import BiddingForm from '../BiddingForm'
import BiddingBeforeEndDialog from '../BiddingBeforeEndDialog'
import BiddingResultDialog from '../BiddingResultDialog'

export default {
  name: 'Interactions',

  components: {
    DrawForm,
    DrawResultDialog,
    BiddingForm,
    BiddingBeforeEndDialog,
    BiddingResultDialog,
    ShowActivity
  },

  computed: {
    ...mapGetters('Me', ['isEnterprise', 'enabledPostActivities', 'enabledDraw', 'enabledAuction', 'enabledBidding', 'enabledMerchandise']),
    ...mapGetters('Post', ['post', 'postId', 'celebrityToken', 'liveStatus']),
    ...mapGetters('Post', ['drawFormVisible', 'hasCurrentDraw', 'showDrawResult']),
    ...mapGetters('Post', ['selectAuction', 'dialogAuctionVisible']),
    ...mapGetters('Post', ['hasCurrentBidding', 'showBiddingResult', 'showrawResult', 'biddingFormVisible', 'biddingBeforeEndDialogVisible']),

    disabledDraw () {
      return !!this.celebrityToken || ![LIVE_STATUS.UNBOUND, LIVE_STATUS.UNPUBLISHED, LIVE_STATUS.LIVE].includes(this.liveStatus)
    },
    disabledAuction () {
      return !!this.celebrityToken || this.liveStatus !== LIVE_STATUS.LIVE
    },
    disabledBidding () {
      return !!this.celebrityToken || this.liveStatus !== LIVE_STATUS.LIVE
    },

    showActivities () {
      // 網紅授權 不顯示 活動設定
      if (this.celebrityToken) return false

      // 企業版 不顯示 活動設定
      if (this.isEnterprise) return false

      return this.enabledPostActivities
    }
  },

  methods: {
    ...mapActions('Post', ['getBiddingWinners', 'setSelectDraw', 'fetchPost', 'fetchAnalysis', 'fetchActivities']),
    ...mapActions('Post', ['toggleDrawForm']),
    ...mapActions('Post', ['fetchActivityAuctions', 'checkAuctionActivity', 'setSelectAuction', 'updateDialogAuctionVisible']),
    ...mapActions('Post', ['toggleBiddingForm', 'toggleBiddingBeforeEndDialog']),

    // 喊單模組結束
    endBidding () {
      this.toggleBiddingForm(false)
    },

    toggleAuctionForm () {
      this.setSelectAuction(null)
      this.updateDialogAuctionVisible(true)
    },
    async handleCreateAuction () {
      await Promise.all([
        this.fetchPost(),
        this.fetchActivityAuctions(this.postId)
      ])

      this.updateDialogAuctionVisible(false)
      this.$nextTick(() => {
        this.checkAuctionActivity()
      })
    },
    handleUpdateAuction: _.throttle(async function () {
      await Promise.all([
        this.fetchPost(),
        this.fetchAnalysis(this.postId),
        this.fetchActivityAuctions(this.postId)
      ])

      this.checkAuctionActivity()
    }, 2500),
    handleCloseAuction () {
      this.setSelectAuction(null)
      this.updateDialogAuctionVisible(false)
    }
  }
}
</script>

<template lang="pug" src="./Interactions.pug"></template>
