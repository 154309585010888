import _ from 'lodash'
import Cookies from 'js-cookie'
import ElementUI from 'element-ui'
import store from '@/store'

const RETRY_LIMIT = 1 // 重載頁面次數上限

function handleRetry (cookieName) {
  let retryCount = Cookies.get(cookieName) || 0

  // 判斷是否重載過多次
  if (retryCount > RETRY_LIMIT) {
    console.error('Loaded:' + retryCount)
    return false
  }

  // 紀錄重載次數
  retryCount++
  const oneHour = 0.04 // 約一小時
  Cookies.set(cookieName, retryCount, { expires: oneHour })

  return true
}

function handleValidateError (response) {
  let message = ''

  if (typeof response.data.message === 'string') {
    message = response.data.message
  } else {
    _.forEach(response.data.message, (errorMessages, key) => {
      message += `${errorMessages[0]}<br>`
    })
  }

  showErrorMessage(message, true)
}

function handleExpiredError (response) {
  const domain = process.env.VUE_APP_DOMAIN
  // TODO：清 cookie 方式，待研究
  Cookies.remove('api_token')
  document.cookie = 'api_token=; path=/; domain=' + domain + '; expires=' + new Date(0).toUTCString()

  if (!handleRetry('expireCount')) return

  // 跳轉至 登入頁
  if (store.getters['Me/isEnterprise']) {
    const currentStore = store.getters['Me/currentStore']
    const enterpriseSlug = currentStore.enterprise.slug
    location.replace(`/e/${enterpriseSlug}`)
  } else {
    location.replace(`/`)
  }

  showErrorMessage('登入期限已過期，請重新登入！')
}

async function handlePageNotFoundError (response) {
  /**
   * 404 case by store slug change
   * try reload store list and redirect.
   */
  const oldStoreSlug = store.getters['Me/storeName']
  const storeSlugRegExp = new RegExp(oldStoreSlug)
  const currentStoreId =
    store.getters['Me/currentStore'] && store.getters['Me/currentStore'].id

  if (storeSlugRegExp.test(response.request.responseURL)) {
    await store.dispatch('Me/fetchStores')

    const storeList = store.getters['Me/stores']
    const targetStore = storeList.find(store => store.id === currentStoreId)
    const newStoreSlug = targetStore !== undefined ? targetStore.slug : null

    if (newStoreSlug !== null && newStoreSlug !== oldStoreSlug) {
      location.replace(location.href.replace(oldStoreSlug, newStoreSlug))
    }

    return
  }

  showErrorMessage(response.data.message)
}

function handleError (response) {
  if (response.data instanceof Blob) {
    response.data
      .text()
      .then(res => JSON.parse(res))
      .then(res => showErrorMessage(res.message))
  } else {
    showErrorMessage(response.data.message)
  }
}

function showErrorMessage (message, dangerouslyUseHTMLString = false) {
  ElementUI.Message({
    dangerouslyUseHTMLString,
    message,
    type: 'error'
  })
}

export default {
  handleError,
  handleValidateError,
  handleExpiredError,
  handlePageNotFoundError
}
