<script>
import VueTypes from 'vue-types'

export default {
  name: 'MemberInfo',
  props: {
    member: VueTypes.object,
    title: VueTypes.string,
    currencySymbol: VueTypes.string
  },
  computed: {
    iconNTD () {
      const map = {
        'payment_total': 'fa-dollar-sign',
        'average_amount': 'fa-usd-circle'
      }
      return map[this.title]
    },
    infoTitle () {
      const map = {
        'payment_total': this.$t('Member.Show.payment_total'),
        'orders_total': this.$t('Member.Show.orders_total'),
        'average_amount': this.$t('Member.Show.average_amount')
      }
      return map[this.title]
    },
    infoTip () {
      const map = {
        'payment_total': this.$t('Member.Show.payment_total.tip'),
        'orders_total': this.$t('Member.Show.orders_total.tip'),
        'average_amount': this.$t('Member.Show.average_amount.tip')
      }
      return map[this.title]
    },
    displayNumber () {
      const map = {
        'payment_total': this.member.orders_total_amount,
        'orders_total': this.member.orders_count,
        'average_amount': this.avgAmount
      }
      return map[this.title]
    },
    avgAmount () {
      const avg = this.member.orders_total_amount / this.member.orders_count
      return isFinite(avg) ? parseInt(avg) : 0
    }
  }
}
</script>

<template lang="pug" src="./MemberInfo.pug"></template>
<style lang="scss" src="./MemberInfo.scss" scoped></style>
