import axios from '@/plugins/http.js'

export default class BaseApi {
  uri = `/api/v1/model_name`

  constructor () {
    this.pagination = { links: {}, meta: {} }
  }

  paginator = {
    data: () => this.pagination,

    next: async () => this.paginator.to(this.meta.current_page + 1),

    previous: async () => this.paginator.to(this.meta.current_page - 1),

    to: async page => this.list({ ...this.pagination.params, page })
  }

  // 取得多筆資料
  list = async (params = {}, all = false) => {
    const api = this.uriList || this.uri

    if (all) {
      try {
        const { data } = await axios.get(api, {
          params: { all: '', ...params }
        })

        return data
      } catch (e) {
        console.error(e)
        return []
      }
    } else {
      try {
        const {
          data: { data, ...pagination }
        } = await axios.get(api, { params })

        this.pagination = { ...pagination, params }

        return data
      } catch (e) {
        console.error(e)
        return []
      }
    }
  }

  // 取得單筆資料
  get = async (id, params = {}) => {
    try {
      const { data } = await axios.get(`${this.uriGet || this.uri}/${id}`, {
        params
      })

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // 新建資料
  create = async data => {
    try {
      await axios.post(this.uriCreate || this.uri, data)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 更新資料
  update = async (id, data) => {
    try {
      await axios.put(`${this.uriUpdate || this.uri}/${id}`, data)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 刪除資料
  delete = async id => {
    try {
      await axios.delete(this.uriDelete || this.uri + '/' + id)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }
}
