export const legalPerson = function (rules, value, callback) {
  const coefficient = [1, 2, 1, 2, 1, 2, 4, 1]

  const product = value.match(/[0-9]/g).map((el, index) => {
    return Number.parseInt(el, 10)
  }) // Convert to array and parse to int

  const parsed = product.map((el, index) => {
    var p = el * coefficient[index]
    return p > 9 ? Math.floor(p / 10) + (p % 10) : p
  })

  const checksum = parsed.reduce((pv, cv) => pv + cv, 0)

  if (checksum % 10 === 0 || (product[6] === 7 && checksum % 10 === 9)) {
    callback()
  } else {
    callback(new Error('統一編號不符合格式'))
  }
}

export const naturalPerson = function (rules, value, callback) {
  const alphabetNumberList = {
    A: 10,
    B: 11,
    C: 12,
    D: 13,
    E: 14,
    F: 15,
    G: 16,
    H: 17,
    I: 34,
    J: 18,
    K: 19,
    L: 20,
    M: 21,
    N: 22,
    O: 35,
    P: 23,
    Q: 24,
    R: 25,
    S: 26,
    T: 27,
    U: 28,
    V: 29,
    W: 32,
    X: 30,
    Y: 31,
    Z: 33
  }
  const coefficient = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1]

  // to validate number string
  const isValid =
    alphabetNumberList[value[0]] &&
    (alphabetNumberList[value[0]].toString() + value.slice(1))
    // convert to array
      .match(/[0-9]/g)
    // validate formula
      .reduce((pv, cv, i) => pv + Number.parseInt(cv, 10) * coefficient[i], 0) %
    10 ===
    0

  if (isValid) {
    callback()
  } else {
    callback(new Error('身分證字號不符合格式'))
  }
}
