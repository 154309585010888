<script>
import VueTypes from 'vue-types'

const GMO_OK = 'OK'

export default {
  name: 'GmoDeferredStatus',
  props: {
    status: VueTypes.string.def(GMO_OK)
  },
  data () {
    return {
      GMO_OK
    }
  }
}
</script>

<template lang="pug" src="./GmoDeferredStatus.pug"></template>
