<script>
import VueTypes from 'vue-types'
import { APPLY_17_LIVE_FORM_PATH } from '@/constants/path'
import { mapActions } from 'vuex'

const accessTokenReqEx = new RegExp('^([A-Za-z0-9]|-)*$')

const fieldNames = {
  name: 'name',
  accessToken: 'accessToken'
}

export default {
  name: 'Create17LIVEChannel',
  props: {
    isShow: VueTypes.bool.def(false),
    info: VueTypes.object.def({}),
  },
  data () {
    return {
      APPLY_17_LIVE_FORM_PATH,
      fieldNames,
      isSubmitDisabled: false,

      fields: {
        [fieldNames.name]: '',
        [fieldNames.accessToken]: ''
      }
    }
  },
  computed: {
    rules () {
      return {
        [fieldNames.name]: [
          { required: true, message: this.$t('Setting.ChannelEditorFor17.error.userId.empty'), trigger: 'change' }
        ],
        [fieldNames.accessToken]: [
          { required: true, message: this.$t('Setting.ChannelEditorFor17.error.accessToken.empty'), trigger: 'change' },
          { validator: this.checkAccessToken, trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    fields: {
      handler () {
        this.isSubmitDisabled = false
      },
      deep: true
    },
    isShow (value) {
      if (value && this.$refs.fields) {
        this.$refs.fields.clearValidate()
      }
    },
    info: {
      deep: true,
      handler (info) {
        if(info.name && info.accessToken) {
          this.fields[fieldNames.name] = info.name
          this.fields[fieldNames.accessToken] = info.accessToken
        }
      }
    }
  },
  methods: {
    ...mapActions('Me/StoreChannels', ['submit17LiveChannel']),
    reset () {
      this.$refs.fields.resetFields()
    },
    checkAccessToken (rule, value, callback) {
      if (!value) {
        callback(new Error(this.$t('Setting.ChannelEditorFor17.error.accessToken.empty')))
      } else if (!accessTokenReqEx.test(value)) {
        callback(new Error(this.$t('Setting.ChannelEditorFor17.error.accessToken.format')))
      } else {
        callback()
      }
    },
    checkFields () {
      this.$refs.fields.validate((valid) => {
        this.isSubmitDisabled = !valid
      })
    },
    closeHandler () {
      this.isSubmitDisabled = false
      this.reset()
      this.$emit('close')
    },
    async submitHandler () {
      this.checkFields()
      if (!this.isSubmitDisabled) {
        this.isSubmitDisabled = true
        const isSuccess = await this.submit17LiveChannel({
          m17_user_id: this.fields[fieldNames.name],
          m17_access_token: this.fields[fieldNames.accessToken]
        })
        if (isSuccess) {
          this.$emit('success')
          this.reset()
        }
        this.isSubmitDisabled = false
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
