import axios from '@/plugins/http.js'

export default class Bidding {
  getBiddings = id => {
    const api = `/api/v1/posts/${id}/biddings?all`
    return axios.get(api)
  }

  create = (id, postData) => {
    const api = `/api/v1/posts/${id}/biddings`
    return axios.post(api, postData)
  }

  update = (id, updateData) => {
    const api = `/api/v1/biddings/${id}`
    return axios.put(api, updateData)
  }

  end = (id, postData) => {
    const api = `/api/v1/biddings/${id}/end`
    return axios.post(api, postData)
  }

  getWinners = id => {
    const api = `/api/v1/biddings/${id}/winners`
    return axios.get(api)
  }
}
