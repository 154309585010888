import ApiPost from '@/api/post'

const state = {
  commentAnalysis: null
}

const getters = {
  analysisData: state => {
    if (!state.commentAnalysis) return null

    const data = state.commentAnalysis
    return { ...data, sales_amount: parseInt(data.sales_amount) }
  }
}

const mutations = {
  UPDATE_COMMENT_ANALYSIS (state, payload) {
    state.commentAnalysis = payload
  }
}

const actions = {
  async fetchAnalysis ({ commit, state }, postId) {
    if (!postId) return

    const apiPost = new ApiPost()

    return apiPost.getCommentAnalysis(postId)
      .then(response => {
        commit('UPDATE_COMMENT_ANALYSIS', response.data)
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
