<script>
import { mapActions, mapGetters } from 'vuex'
import { loadMessages } from '@/plugins/i18n'
import getSupportedLocale from '@/utils/getSupportedLocale'
import Tw from './Tw'
import Jp from './Jp'

export default {
  skipMiddleware: ['checkAuth'],
  metaInfo () {
    return {
      meta: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0'
      }]
    }
  },
  name: 'Contract',
  components: { Tw, Jp },
  data () {
    return {
      routePath: this.$route.path.split('_')[1]
    }
  },
  computed: {
    ...mapGetters('Me', ['paymentFlow']),

    isSeller () {
      return this.routePath === 'seller'
    },
    isAgency () {
      let { agency } = this.$route.query
      return agency === 'true'
    },
    Region () {
      return 'Tw'
      // const local = this.$route.query.lang || navigator.language
      // const lang = local.split('-')[0]
      // const map = {
      //   'zh': 'Tw',
      //   'ja': 'Jp'
      // }
      // return map[lang] || 'Tw'
    },
    computedPageTitle () {
      return this.$t(`contract.page_title_${this.routePath}`)
    }
  },
  async mounted () {
    // 偵聽路由有無錨點
    const anchor = this.$route.hash || null
    if (anchor) {
      this.goAnchor(anchor)
    }
    const lang = this.$route.query.lang || navigator.language
    const locale = getSupportedLocale(lang)
    const supportedLocales = ['zh-TW', 'ja']
    if (supportedLocales.includes(locale)) {
      await this.setLocale({ locale: locale })
      await loadMessages(locale)
    }
  },
  methods: {
    ...mapActions('Lang', ['setLocale']),
    // 捲到錨點
    goAnchor (id) {
      this.$scrollTo(id)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
