import { render, staticRenderFns } from "./DrawResultDialog.pug?vue&type=template&id=652c5cc2&scoped=true&lang=pug&"
import script from "./DrawResultDialog.vue?vue&type=script&lang=js&"
export * from "./DrawResultDialog.vue?vue&type=script&lang=js&"
import style0 from "./DrawResultDialog.scss?vue&type=style&index=0&id=652c5cc2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652c5cc2",
  null
  
)

export default component.exports