import ClipVideo from '@/api/clipVideo'

const maxSortNumber = 4
const initialState = () => ({
  // exist clipVideo
  clipVideoList: [],
  editClipVideo: {},
  // for creating new clipped video
  newClip: {
    start: 0,
    end: 0,
    title: '',
    imageId: '',
    imageUrl: '',
    screenshot: null
  },
  isClipLoading: false,
  isClipAsReplay: false,
  currentEditClipId: null
})

const state = initialState()

const getters = {
  sortedClipVideoList (state) {
    return state.clipVideoList.map(clip => ({
      ...clip
    })).sort((clipA, clipB) => {
      if (clipA.sort === null) return 1
      if (clipB.sort === null) return -1
      return clipA.sort - clipB.sort
    })
  },
  findMaxSortClip (state, getters) {
    const allSort = Object.entries(state.editClipVideo).reduce((accumulator, [key, clip]) => [ ...accumulator, clip.sort ], [])

    return Math.max(...allSort)
  },
  currentEditedClip (state) {
    return state.currentEditClipId ? state.editClipVideo[state.currentEditClipId] : {
      start: state.newClip.start,
      end: state.newClip.end,
      title: state.newClip.title,
      imageUrl: state.newClip.imageUrl,
      screenshot: state.newClip.screenshot
    }
  },
  isDuplicatedTitle (state, getters) {
    return !!getters.sortedClipVideoList.filter(clip => clip.id !== state.currentEditClipId).find(clip => clip.title === getters.currentEditedClip.title)
  }
}

const mutations = {
  updateClipVideoList (state, payload) {
    state.clipVideoList = _.cloneDeep(payload)
    state.editClipVideo = payload.reduce((accumulator, clip) => ({
      ...accumulator,
      [clip.id]: {
        ...clip
      }
    }), {})
  },
  updateNewClip (state, payload) {
    state.newClip = {
      ...state.newClip,
      ...payload
    }
  },
  resetClipVideo (state, payload) {
    state = initialState()
  },
  setIsLoading (state, payload) {
    state.isClipLoading = payload
  },
  resetNewClipVideo (state) {
    state.newClip = initialState().newClip
  },
  updateSort (state, { id, sort }) {
    state.editClipVideo[id].sort = sort
  },
  shiftSort (state, removedSort) {
    const shiftedClips = Object.entries(state.editClipVideo).reduce((accumulator, [key, clip]) => {
      const isLarge = clip.sort > removedSort && clip.sort <= maxSortNumber
      return {
        ...accumulator,
        [key]: {
          ...clip,
          sort: isLarge ? clip.sort - 1 : clip.sort
        }
      }
    }, {})
    state.editClipVideo = _.cloneDeep(shiftedClips)
  },
  updateClipInfo (state, payload) {
    const targetClip = state.editClipVideo[state.currentEditClipId]
    state.editClipVideo[state.currentEditClipId] = {
      ...targetClip,
      image: {
        ...targetClip.image,
        id: payload.imageId || targetClip.image.id,
        url: payload.imageUrl || targetClip.image.url
      },
      title: payload.title || targetClip.title,
      screenshot: payload.screenshot >= 0 ? payload.screenshot : targetClip.screenshot
    }
  },

  updateCurrentEditVideoId (state, clipId) {
    state.currentEditClipId = clipId
  }
}

const actions = {
  async initializeClipVideoList ({ commit, rootGetters }) {
    try {
      const list = await new ClipVideo().fetchClipVideo(rootGetters['Post/postId'])
      commit('updateClipVideoList', list)
    } catch (error) {

    }
  },
  async createNewClip ({ commit, dispatch, state, rootGetters }) {
    commit('setIsLoading', true)
    try {
      const newClip = state.newClip
      const payload = {
        title: newClip.title,
        start: newClip.start,
        end: newClip.end,
        image_id: newClip.imageId || undefined,
        screenshot: newClip.screenshot
      }
      await new ClipVideo().createClipVideo(rootGetters['Post/postId'], payload)
      // refetch list
      await dispatch('initializeClipVideoList')
      // reset data
      commit('resetNewClipVideo')
    } catch (error) {}
    commit('setIsLoading', false)
  },
  async deleteClip ({ commit, dispatch, state, rootGetters, getters }, payload) {
    commit('setIsLoading', true)
    try {
      // 刪除前先將要刪除的 item 排序移除, & 其後的剪輯片段前移
      const removedSort = state.editClipVideo[payload].sort
      commit('updateSort', { id: payload, sort: null })
      commit('shiftSort', removedSort || maxSortNumber)
      await dispatch('updateClipSortWithoutRefresh')
      // 刪除
      await new ClipVideo().deleteClipVideo(rootGetters['Post/postId'], payload)
      // refetch list
      await dispatch('initializeClipVideoList')

      // 若剪輯片段全部被刪除 or 刪完之後沒有任何有排序的片段, 則須自動將直播改為 "使用完整影片做回放"
      if ((!getters.sortedClipVideoList.length || !getters.findMaxSortClip) && rootGetters['Post/isClipAsReplay']) {
        await new ClipVideo().updateReplayType(rootGetters['Post/postId'], { 'replay_type': null })
        await dispatch('Post/fetchPost', null, { root: true })
      }
    } catch (error) {}
    commit('setIsLoading', false)
  },
  sortClipVideo ({ state, getters, commit }, payload) {
    if (!state.editClipVideo[payload].sort) {
      const maxSort = getters.findMaxSortClip || 0
      commit('updateSort', { id: payload, sort: maxSort < maxSortNumber ? maxSort + 1 : null })
    } else {
      const removedSort = state.editClipVideo[payload].sort
      commit('updateSort', { id: payload, sort: null })
      commit('shiftSort', removedSort)
    }
  },
  async updateClipSort ({ state, getters, commit, rootGetters }) {
    commit('setIsLoading', true)
    try {
      const payload = Object.entries(state.editClipVideo).map(([id, clip]) => ({
        id,
        sort: clip.sort
      })).filter(clip => clip.sort)
      const newList = await new ClipVideo().updateClipVideoSort(rootGetters['Post/postId'], payload)
      commit('updateClipVideoList', newList)
    } catch (error) {}
    commit('setIsLoading', false)
  },
  async updateClipSortWithoutRefresh ({ state, getters, commit, rootGetters }) {
    try {
      const payload = Object.entries(state.editClipVideo).map(([id, clip]) => ({
        id,
        sort: clip.sort
      })).filter(clip => clip.sort)
      await new ClipVideo().updateClipVideoSort(rootGetters['Post/postId'], payload)
    } catch (error) {}
  },
  async useClipAsReplay ({ rootGetters, dispatch, commit, getters }) {
    commit('setIsLoading', true)
    try {
      // 如果沒有挑選剪輯片段 -> 視為使用完整影片做播放 -> 直接傳 null 視作完整剪輯
      await new ClipVideo().updateReplayType(rootGetters['Post/postId'], { 'replay_type': getters.findMaxSortClip ? 'clip' : null })
      await dispatch('Post/fetchPost', null, { root: true })
    } catch (error) {}
    commit('setIsLoading', false)
  },
  async useFullVideoAsReplay ({ rootGetters, dispatch, commit }) {
    commit('setIsLoading', true)
    try {
      await new ClipVideo().updateReplayType(rootGetters['Post/postId'], { 'replay_type': null })
      await dispatch('Post/fetchPost', null, { root: true })
      // reset sort
      state.editClipVideo = {}
      await dispatch('updateClipSort')
    } catch (error) {}
    commit('setIsLoading', false)
  },
  async saveClipInfo ({ rootGetters, state, commit, getters, dispatch }) {
    commit('setIsLoading', true)
    try {
      const clip = state.editClipVideo[state.currentEditClipId]
      const params = {
        title: clip.title,
        image_id: clip.image.id,
        start: clip.start,
        end: clip.end,
        screenshot: clip.screenshot
      }
      await new ClipVideo().updateClipInfo(rootGetters['Post/postId'], state.currentEditClipId, params)
      await dispatch('initializeClipVideoList')
    } catch (error) {}
    commit('setIsLoading', false)
  },
  resetClipSort ({ state, getters, commit }) {
    commit('updateClipVideoList', getters.sortedClipVideoList)
  },
  resetClipInfo ({ state, getters, commit }) {
    const target = getters.sortedClipVideoList.find(clip => clip.id === state.currentEditClipId)
    commit('updateClipInfo', {
      ...target,
      imageId: target.image.id,
      imageUrl: target.image.url,
      title: target.title
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
