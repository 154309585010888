<script>
import ApiPost from '@/api/post'
import VueTypes from 'vue-types'
import ImageUploader from '@/components/ImageUploader'

export default {
  name: 'DialogSetCover',
  components: { ImageUploader },
  props: {
    perPostItem: VueTypes.object
  },
  data () {
    return {
      apiPost: new ApiPost(),
      calCoverImage: this.perPostItem.image.length ? this.perPostItem.image[0] : {
        url: '',
        id: null
      },
      isImageUploading: false
    }
  },
  methods: {
    // upload pic
    // ============ start ============
    handleBeforeUpload (file) {
      const fileMB = file.size / (1024 ** 2)

      if (fileMB > 2) {
        this.$message.error(this.$t('Post.PushNotification.error_upload_file'))
        return false
      }
    },
    handleSubmit () {
      if (this.calCoverImage) {
        this.updateCoverImage()
      } else {
        this.$message.error(this.$t('Post.PushNotification.enter_coverImage'))
      }
    },
    handleClose () {
      this.$emit('close')
      this.$emit('update:dialogVisible', false)
    },
    async updateCoverImage () {
      let params = {
        image_id: this.calCoverImage ? this.calCoverImage.id : null
      }
      const success = await this.apiPost.updatePost(this.perPostItem.id, params)
      if (success) {
        await this.$emit('reFetch')
        this.handleClose()
      } else {
        this.$message.error(this.$t('Post.PushNotification.enter_coverImage'))
      }
    },
    updateImage (image) {
      this.calCoverImage = {
        id: image.id,
        url: image.url
      }
    }
  }
}
</script>

<template lang="pug" src="./DialogSetCover.pug"></template>
<style lang="scss" src="./DialogSetCover.scss" scoped></style>
