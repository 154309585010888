import { interruptVoucher as apiInterruptVoucher } from '../apis'

import * as loadingState from '@/constants/loadingState'

export const types = {
  INTERRUPT_VOUCHER: 'INTERRUPT_VOUCHER',
  INTERRUPT_VOUCHER_SUCCESS: 'INTERRUPT_VOUCHER_SUCCESS',
  INTERRUPT_VOUCHER_FAILED: 'INTERRUPT_VOUCHER_FAILED'
}

const INIT_DATA = {
  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  interruptVoucherLoadingState: state => state.loadingState
}

const mutations = {
  [types.INTERRUPT_VOUCHER] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.INTERRUPT_VOUCHER_SUCCESS] (state) {
    state.loadingState = loadingState.READY
  },

  [types.INTERRUPT_VOUCHER_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  }
}

const actions = {
  interruptVoucher ({ commit }, id) {
    commit(types.INTERRUPT_VOUCHER)

    return apiInterruptVoucher(id)
      .then(() => commit(types.INTERRUPT_VOUCHER_SUCCESS))
      .catch(err => {
        commit(types.INTERRUPT_VOUCHER_FAILED, err)
        throw err
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
