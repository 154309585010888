<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'
import axios from '@/plugins/http.js'
import LineImg from '@/assets/line-add-friend-zh.png'

const PAYPAL_URL = process.env.VUE_APP_STAGE === 'production'
  ? 'https://api-m.paypal.com'
  : 'https://api-m.sandbox.paypal.com'

export default {
  name: 'DialogPaypalSetting',
  props: {
    setting: VueTypes.object.def({}),
    editable: VueTypes.bool.def(false)
  },
  data () {
    return {
      LineImg,
      isLoading: false,
      ruleForm: {
        client: '',
        secret: ''
      },
      rules: {
        client: [
          {
            required: true,
            message: this.$t('Setting.Seller.DialogPaypalSetting.validate_client')
          }
        ],
        secret: [
          {
            required: true,
            message: this.$t('Setting.Seller.DialogPaypalSetting.validate_secret')
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['lineLink'])
  },
  methods: {
    handleClose () {
      this.$emit('update:dialogVisible', false)
    },
    async handleSubmit () {
      // 驗證欄位
      try {
        await this.$refs.ruleForm.validate()
      } catch {
        return
      }
      this.isLoading = true

      if (!await this.checkPaypalToken()) {
        this.isLoading = false
        return
      }

      const info = {
        client: this.ruleForm.client,
        secret: { secret: this.ruleForm.secret }
      }

      const data = {
        type: this.setting.type,
        enabled: true,
        info
      }
      this.$emit('submit', data)
    },

    async checkPaypalToken () {
      try {
        await axios.post(`${PAYPAL_URL}/v1/oauth2/token`,
          'grant_type=client_credentials', {
            auth: {
              username: this.ruleForm.client, password: this.ruleForm.secret
            }
          })
      } catch {
        this.$message.error(this.$t('Setting.Seller.DialogPaypalSetting.validate_error'))
        return false
      }
      return true
    }
  }
}
</script>

<template lang="pug" src="./DialogPaypalSetting.pug"></template>
<style lang="scss" src="./DialogPaypalSetting.scss" scoped></style>
