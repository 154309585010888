<script>
import { mapActions } from 'vuex'

import BaseButton from '@/components/BaseButton'

import DetectorPanel from './components/DetectorPanel'

// This component is responsible for the follwing things
//   1. Retrieve seller account name
//   2. Retrieve seller store name
//   3. Detect account setting
//   4. Detect store / shippment setting
export default {
  name: 'BroadcastDetector',

  skipMiddleware: ['getAuth', 'checkAuth'],

  components: {
    BaseButton,
    DetectorPanel
  },

  data () {
    return {
      startInspection: false
    }
  },
  computed: {
    currentStoreRegistrant () {
      const storeRegistrant = (
        this.$store.getters['Me/currentStore'] &&
        this.$store.getters['Me/currentStore'].store_registrant
      ) || {}

      return storeRegistrant
    }
  },
  // @todo instead of performing series of async actions in "mounted"
  // we should dispatch an action that performs series of async actions.
  created () {
    this.fetchStores()
  },
  methods: {
    ...mapActions('Me', [
      'fetchStores'
    ]),

    handleStartInspection () {
      this.startInspection = true
    }
  }

}
</script>

<template lang="pug" src="./template.pug" />
<style lang="scss" src="./style.scss" />
