import * as loadingState from '@/constants/loadingState'
import { createSeller, createCelebrityInfo } from '../apis'

export const actionTypes = {
  CREATE_SELLER: 'CREATE_SELLER',
  CREATE_SELLER_SUCCESS: 'CREATE_SELLER_SUCCESS',
  CREATE_SELLER_FAILED: 'CREATE_SELLER_FAILED',
  CREATE_CELEBRITY: 'CREATE_CELEBRITY',
  CREATE_CELEBRITY_SUCCESS: 'CREATE_CELEBRITY_SUCCESS',
  CREATE_CELEBRITY_FAILED: 'CREATE_CELEBRITY_FAILED'
}

const state = {
  createSellerloadingState: loadingState.EMPTY,
  createCelebrityloadingState: loadingState.EMPTY,
  error: null
}

const getters = {
  createAccountLoadingState: state => {
    if (state.createCelebrityloadingState !== loadingState.READY) {
      return state.createSellerloadingState
    }
    return state.createCelebrityloadingState
  }
}

const mutations = {
  [actionTypes.CREATE_SELLER] (state) {
    state.createSellerloadingState = loadingState.LOADING
  },

  [actionTypes.CREATE_SELLER_SUCCESS] (state) {
    state.createSellerloadingState = loadingState.READY
  },

  [actionTypes.CREATE_SELLER_FAILED] (state, error) {
    state.createSellerloadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  },

  [actionTypes.CREATE_CELEBRITY] (state) {
    state.createCelebrityloadingState = loadingState.LOADING
  },

  [actionTypes.CREATE_CELEBRITY_SUCCESS] (state) {
    state.createCelebrityloadingState = loadingState.READY
  },

  [actionTypes.CREATE_CELEBRITY_FAILED] (state, error) {
    state.createCelebrityloadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  }
}

const actions = {

  createAccount ({ state, commit, dispatch }, { hasStore, form }) {
    // 只能先 createSeller 後 createCelebrity
    if (!hasStore) {
      return dispatch('createSeller', form)
        .then(() => {
          if (state.createSellerloadingState === loadingState.READY) {
            dispatch('createCelebrity', form)
          }
        })
    }

    return dispatch('createCelebrity', form)
  },
  createSeller ({ commit, dispatch }, form) {
    commit(actionTypes.CREATE_SELLER)

    const {
      // eslint-disable-next-line camelcase
      first_name,
      // eslint-disable-next-line camelcase
      last_name,
      region,
      email,
      // eslint-disable-next-line camelcase
      phone_number
    } = form

    return createSeller({
      first_name,
      last_name,
      region,
      email,
      phone_number
    })
      .then(response => {
        commit(actionTypes.CREATE_SELLER_SUCCESS, response)
        dispatch('Me/fetchMe', _, { root: true })
      })
      .catch(err => commit(actionTypes.CREATE_SELLER_FAILED, err))
  },

  createCelebrity ({ commit }, form) {
    commit(actionTypes.CREATE_CELEBRITY)

    const {
      // eslint-disable-next-line camelcase
      phone_number,
      gender,
      introduction
    } = form

    return createCelebrityInfo({
      contact_phone: phone_number,
      gender,
      introduction
    })
      .then(response => commit(actionTypes.CREATE_CELEBRITY_SUCCESS, response))
      .catch(err => commit(actionTypes.CREATE_CELEBRITY_FAILED, err))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
