<script>
import { mapGetters } from 'vuex'
import DialogBindFailed from './components/DialogBindFailed'

export default {
  name: 'ContainerSetting',
  components: { DialogBindFailed },
  data () {
    return {
      dialogBindFailedVisible: false
    }
  },

  computed: {
    ...mapGetters('Me', ['providers', 'region', 'apiUrl']),
    bindFacebookUrl () {
      return `${this.apiUrl}/auth/facebook?user_bind=true&redirect=${location.origin + location.pathname}`
    }
  },

  created () {
    // User back from FB auth
    this.handleBindStatus()
  },

  methods: {
    handleBindStatus () {
      if (this.$route.query.user_bind_status === 'false') {
        this.dialogBindFailedVisible = true
      } else if (this.$route.query.user_bind_status === 'true') {
        this.$message.success(this.$t('bind.success.fb'))
      }
    }
  }
}
</script>

<template lang="pug" src="./ContainerSetting.pug"></template>
