<template>
  <el-dialog
    :title="clipVideo.title"
    width="50%"
    visible
    @close="$emit('closePreviewDialog')"
  >
    <div class="p-4">
      <video-player
        :video-url="videoUrl"
        :preview-start-time="clipVideo.start"
        :preview-end-time="clipVideo.end"
        :can-edit-range="false"
        show-range-selector
        is-loop-preview
      />
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        type="primary"
        @click="$emit('closePreviewDialog')"
      >{{ $t('ReplayEdit.button.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import VueTypes from 'vue-types'
import VideoPlayer from '@/components/VideoPlayer'

export default {
  name: 'DialogPreviewVideo',
  components: { VideoPlayer },
  props: {
    videoTitle: VueTypes.string.def(''),
    videoUrl: VueTypes.string.def(''),
    clipVideo: VueTypes.object.def({})
  }
}
</script>
