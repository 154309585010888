<script>
export default {
  name: 'DialogLiveLater',
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
