<script>
import { mapGetters } from 'vuex'
import * as SETTINGS from '@/constants/storeSettings'
import HighlightElement from '../HighlightElement'

export default {
  name: 'TopShadow',
  components: { HighlightElement },
  data () {
    return {
      SETTINGS
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore']),
    ...mapGetters('LiveSetting', ['newSettings', 'hoverElements']),

    showViewCount () {
      return this.enabledLivewebMemberCount
    },

    enabledLivewebShopIcon () {
      return this.newSettings[SETTINGS.SHOP_ICON_SHOW]
    },

    enabledLivewebMemberCount () {
      return this.newSettings[SETTINGS.MEMBER_COUNT_SHOW]
    },

    storeName () {
      return this.currentStore.name
    },

    storeImage () {
      return this.currentStore.avatar_url
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
