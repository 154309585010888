<script>
import { mapActions } from 'vuex'
import { loadMessages } from '@/plugins/i18n'
import * as LOCALES from '@/constants/locales'

export default {
  name: 'DropDownLocale',
  props: {
    locale: {
      type: String,
      default: LOCALES.JP
    }
  },
  data () {
    return {
      localeOptions: {
        [LOCALES.TW]: '繁體中文',
        [LOCALES.JP]: '日本語'
      }
    }
  },
  computed: {
    isShown () {
      // First hide by pages, then hide when production
      const hiddenPages = ['ContractService', 'ContractPrivacy']
      if (hiddenPages.includes(this.$route.name)) return false

      return process.env.VUE_APP_STAGE !== 'production'
    }
  },
  methods: {
    ...mapActions('Lang', ['setLocale']),
    async changeLocale (locale) {
      await loadMessages(locale)
      this.setLocale({ locale })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
