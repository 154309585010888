<script>
import { mapGetters, mapActions } from 'vuex'

import ApiPost from '@/api/post'

import { jpCheckPlan } from '@/middleware/checkPlan'

import * as REGIONS from '@/constants/regions'
import * as PAYMENT_FLOW from '@/constants/paymentFlow'
import { BILL_DEADLINE_END } from '@/constants/BillPaymentDeadline'

import Pagination from '@/components/Pagination'
import DialogLiveAuthorization from '@/components/DialogLiveAuthorization'
import DialogSetLiveTime from '@/components/DialogSetLiveTime'
import DialogSetCover from '@/components/DialogSetCover'
import DialogBillingReminder from '@/components/DialogBillingReminder'
import DialogNewFeature from '@/components/DialogNewFeature'
import DialogPrivateSetting from '@/views/Post/components/DialogPrivateSetting'

import ChannelList from './components/ChannelList'
import CardAdd from './components/CardAdd'
import CardPost from './components/CardPost'
import DialogGMOResult from './components/DialogGMOResult'
import JasracApply from './components/JasracApply'

export default {
  name: 'Home',
  components: {
    ChannelList,
    CardAdd,
    CardPost,
    Pagination,
    DialogLiveAuthorization,
    DialogGMOResult,
    DialogSetLiveTime,
    DialogSetCover,
    DialogBillingReminder,
    DialogNewFeature,
    JasracApply,
    DialogPrivateSetting
  },
  data () {
    return {
      dialogLiveAuthorizationVisible: false,
      dialogGMOResultVisible: false,
      dialogSetLiveTimeVisible: false,
      dialogSetCoverVisible: false,
      dialogBillingReminderVisible: false,
      dialogNewFeatureVisible: false,
      dialogPrivateVisible: false,

      apiPost: new ApiPost(),
      posts: [],
      paginator: null,
      liveStatusOption: [
        { value: 'all', label: 'all' },
        { value: 'unpublished', label: 'unpublished' },
        { value: 'live', label: 'live' },
        { value: 'replay', label: 'live_replay' },
        { value: 'live_stopped', label: 'live_stopped' }
      ],
      searchForm: {
        status: null,
        channel: '',
        type: null,
        from: null,
        to: null,
        date_range: null,
        keyword: ''
      },
      token: null,
      expiredAt: null,
      perPostItem: {}
    }
  },
  computed: {
    ...mapGetters('Me', [
      'gmoStatus',
      'currentStore',
      'storeName',
      'region',
      'isEnterprise',
      'isEnterpriseGeneric',
      'isCurrentStoreOwner',
      'isChildStore',
      'paymentFlow',
      'enabledReplay',
      'showInstructionLink',
      'enabledTicket',
      'enabledLiveSetting'
    ]),
    ...mapGetters('AccountManagement', ['paymentBill']),
    ...mapGetters('SettingsChecked', ['checkedList', 'isAllChecked', 'paymentShippingChecked', 'sellerChecked']),
    ...mapGetters('Me/StoreChannels', ['channels']),

    checkListCount () {
      return _.size(this.checkedList)
    },
    checkedListCount () {
      return _.filter(this.checkedList, item => item === true).length
    },
    filteredLiveStatusOption () {
      return this.liveStatusOption.filter(option => {
        if (!this.enabledReplay && option.value === 'replay') return false

        return true
      })
    },

    showJasracApplyUrl () {
      // 日本音樂版權使用許可 JASRAC
      return this.region === REGIONS.JP
    }
  },
  async mounted () {
    // 追蹤事件
    this.trackEvent()

    if (!this.isEnterprise) await this.checkList()
    await this.fetchChannels()
    await this.fetchPosts()

    // 後收 - 帳務提醒繳費
    this.checkBillingReminder()

    // JP GMO 審核結果通知
    this.checkGMOInfo()

    this.showNewFeature()
  },

  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('Me/StoreChannels', ['fetchChannels']),

    trackEvent () {
      // [Facebook Pixel] 追蹤首頁瀏覽事件
      this.$analytics.fbq.event('ViewContent', {
        Page: 'Merchant-Platform',
        Content: 'Sales',
        Target: 'Sales'
      })

      // [Google Analytics] 追蹤首頁瀏覽事件
      this.$ga.event({
        eventCategory: 'ViewContent',
        eventAction: 'View-Sales',
        eventLabel: 'Merchant-Platform'
      })
    },
    async fetchPosts () {
      this.setLoading(true)

      let searchParams = { ...this.searchForm, type: 'live', sort: 'created_at,desc' }

      // @todo 這邊需要改一下寫法，這樣太 workaround
      // 網紅媒合
      if (this.searchForm.channel === 'celebrity') {
        searchParams.type = 'celebrityPost'
        searchParams.channel = null
      }

      // 判斷是否為 網紅代播
      if (this.searchForm.channel === 'postAgent') {
        searchParams.type = 'postAgent'
        searchParams.channel = null
      }

      this.posts = await this.apiPost.list(searchParams)
      this.paginator = this.apiPost.paginator

      this.setLoading(false)
    },
    handleDatePicker (value) {
      let dateForm = null
      let dateTo = null

      if (value) [dateForm, dateTo] = value

      this.searchForm.from = dateForm
      this.searchForm.to = dateTo

      this.fetchPosts()
    },
    handleFilterLiveStatus (value) {
      this.searchForm.liveStatus = value
      this.fetchPosts()
    },
    async checkList () {
      // 檢查賣家基本資料
      if (this.checkedList.seller) {
        // [Facebook Pixel] 追蹤第一次設定完成賣家基本資料
        this.$analytics.fbq.query('trackCustom', 'SetBasicInfo')
        // [Google Analytics] 追蹤第一次設定完成賣家基本資料
        this.$ga.event({
          eventLabel: 'Merchant-Platform',
          eventCategory: 'Set-Info',
          eventAction: 'BasicInfo',
          eventValue: true
        })
      }

      // 檢查運送&付款設定
      if (this.checkedList.paymentShipping) {
        // [Facebook Pixel] 追蹤第一次設定完成運送方式
        this.$analytics.fbq.query('trackCustom', 'SetDelivery')

        // [Google Analytics] 追蹤第一次設定完成運送方式
        this.$ga.event({
          eventLabel: 'Merchant-Platform',
          eventCategory: 'Set-Info',
          eventAction: 'DeliveryInfo',
          eventValue: true
        })

        // [Facebook Pixel] 追蹤第一次設定完成付款設定
        this.$analytics.fbq.query('trackCustom', 'SetPayment')

        // [Google Analytics] 追蹤第一次設定完成付款設定
        this.$ga.event({
          eventLabel: 'Merchant-Platform',
          eventCategory: 'Set-Info',
          eventAction: 'PaymentInfo',
          eventValue: true
        })
      }

      return this.isAllChecked
    },
    updateRows (rows) {
      this.posts = rows
    },
    toPost (item) {
      this.$router.push(`/${this.storeName}/posts/${item.id}`)
    },
    handleCreate () {
      if (!jpCheckPlan()) return

      this.$analytics.fbq.event('ClickReg', {
        Page: 'Merchant-Platform',
        Content: 'Sales',
        Target: 'Create-Post'
      })
      this.$ga.event({
        eventLabel: 'Merchant-Platform',
        eventCategory: 'Bottom-Click',
        eventAction: 'Create-Post'
      })
      this.$router.push(`/${this.storeName}/posts/create`)
    },
    handleCopyLink (data) {
      this.token = data.token
      this.expiredAt = data.expiredAt
      this.dialogLiveAuthorizationVisible = true
    },

    checkBillingReminder () {
      const isNotice =
        this.isCurrentStoreOwner &&
        this.paymentFlow === PAYMENT_FLOW.DIRECT &&
        this.region === REGIONS.TW &&
        this.paymentBill.length

      if (!isNotice) return

      // 十日前，才提醒
      const REMINDER_DEADLINE = Number(BILL_DEADLINE_END)
      const date = new Date().getDate()
      if (date > REMINDER_DEADLINE) return

      // 總計不為 0
      const totalAmount = this.paymentBill.reduce((acc, current) => {
        return acc + Number(current.amount)
      }, 0)
      if (totalAmount <= 0) return

      const newestBill = this.paymentBill[this.paymentBill.length - 1]
      const ignoreListString = localStorage.getItem('ignoreBillingReminderList')
      let ignoreList = ignoreListString ? JSON.parse(ignoreListString) : []

      if (!ignoreList.includes(newestBill.id)) {
        ignoreList.push(newestBill.id)
        localStorage.setItem('ignoreBillingReminderList', JSON.stringify(ignoreList))
        this.dialogBillingReminderVisible = true
      }
    },

    checkGMOInfo () {
      const isJapan = this.currentStore.region === 'JP'
      const gmoResult = this.gmoStatus === 'success' || this.gmoStatus === 'failed'
      const noGMONoticeYet = !localStorage.getItem('closeGMOResult')
      if (isJapan && gmoResult && noGMONoticeYet && !this.isChildStore) {
        this.dialogGMOResultVisible = true
      }
      if (localStorage.getItem('closeGMOResult')) {
        this.closeGMOResult = false
      }
    },
    closeDialogGMOResult () {
      localStorage.setItem('closeGMOResult', true)
      this.dialogGMOResultVisible = false
    },
    openGMOform () {
      this.$router.push({ name: 'GmoForm' })
    },
    handleSetLiveTime (data) {
      this.perPostItem = data
      this.dialogSetLiveTimeVisible = true
    },
    handleSetCover (data) {
      this.perPostItem = data
      this.dialogSetCoverVisible = true
    },
    handleSetPrivate (data) {
      this.perPostItem = data
      this.dialogPrivateVisible = true
    },
    copyLiveLink () {
      const link = `${this.$VUE_APP_BUYER_DOMAIN_NAME}/${this.storeName}/live`
      this.copyText({
        url: link,
        success: this.$t('Home.live_link_copied'),
        error: this.$t('Post.Show.message.copy_fail')
      })
    },
    toAccountManagement () {
      this.$router.push({ path: `/${this.storeName}/account-management` })
    },
    showNewFeature () {
      // 新功能跳窗一天顯示一次
      const date = Number(localStorage.getItem('newFeatureShownDate'))

      if (!date) {
        this.dialogNewFeatureVisible = true
      } else if (date > 0 && date !== new Date().getDate()) {
        this.dialogNewFeatureVisible = true
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
<style lang="scss">
.home {
  .el-tabs {
    margin-left: auto;

    .el-tabs__item {
      background-color: #ffffff;

      &.is-active {
        color: #ffffff;
        background-color: #00ccff;
      }
    }
  }
}

</style>
