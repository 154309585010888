<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

import * as ORDER_STATUS from '@/constants/orderStatus'
import * as PAYMENT_FLOW from '@/constants/paymentFlow'
import * as PAYMENT_STATUS from '@/constants/paymentStatus'
import * as PAYMENT_TYPES from '@/constants/paymentTypes'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import * as REGIONS from '@/constants/regions'

export default {
  name: 'OrderMenu',

  props: {
    order: VueTypes.object.isRequired,
    isCart: VueTypes.bool.def(true),
    btnLoading: VueTypes.array.def([]),
    storeName: VueTypes.string.isRequired,
    paymentFlow: VueTypes.string.def('')
  },

  data () {
    return {
      ...SHIPPING_TYPES,
      ORDER_STATUS
    }
  },

  computed: {
    ...mapGetters('Me', ['region']),
    ...mapGetters('OrderDetail', ['isSubscriptionType']),
    showCancelButton () {
      // 非購物車，不顯示
      if (this.isCart) {
        return false
      }

      // Subscription 類型，不顯示
      if (this.isSubscriptionType) {
        return false
      }

      // 訂單狀態：退貨申請、已完成、已取消，皆 不顯示
      const cantOrderStatus = [ORDER_STATUS.RETURNING, ORDER_STATUS.COMPLETED, ORDER_STATUS.CANCELED]
      if (cantOrderStatus.includes(this.order.status)) {
        return false
      }

      // 運送類型： 全家、7-11
      const storeShipingTypes = [SHIPPING_TYPES.FAMILY, SHIPPING_TYPES.SEVEN]
      if (storeShipingTypes.includes(this.order.shipping_type)) {
        // 過期：顯示
        // 未過期：不顯示
        return this.order.shipping.is_expired
      }

      return true
    },
    showCompleteManuallyButton () {
      // 僅提供 非代收代付
      if (this.paymentFlow !== PAYMENT_FLOW.DIRECT) {
        return false
      }

      // 非購物車，不顯示
      if (this.isCart) {
        return false
      }

      // Subscription 類型，不顯示
      if (this.isSubscriptionType) {
        return false
      }

      // 日本超商後支付，不顯示
      if (this.order.paymentType === PAYMENT_TYPES.GMO_DEFERRED) {
        return false
      }

      // 訂單狀態：送貨中、退貨申請、已完成、已取消，皆 不顯示
      const cantOrderStatus = [
        ORDER_STATUS.DELIVERING,
        ORDER_STATUS.RETURNING,
        ORDER_STATUS.COMPLETED,
        ORDER_STATUS.CANCELED
      ]

      if (cantOrderStatus.includes(this.order.status)) {
        return false
      }

      return true
    },
    showSplitOrder () {
      // Subscription 類型，不顯示
      if (this.isSubscriptionType) {
        return false
      }

      // 規則與手動完成相同, TW only
      return this.region === REGIONS.TW && this.showCompleteManuallyButton
    },
    barcodePrintable () {
      const printableTypes = [
        SHIPPING_TYPES.FAMILY,
        SHIPPING_TYPES.HSINCHU,
        SHIPPING_TYPES.SEVEN,
        SHIPPING_TYPES.KERRY_TH
      ]
      return this.order.shippingSourceId && printableTypes.includes(this.order.shippingType)
    },
    canPrintBarcodeOnly () {
      const cantTypes = [SHIPPING_TYPES.KERRY_TH]
      return !cantTypes.includes(this.order.shippingType)
    },
    orderPrintable () {
      return [ORDER_STATUS.UNDELIVERED, ORDER_STATUS.DELIVERING].includes(this.order.status)
    },
    orderShippable () {
      const shippableTypes = [
        SHIPPING_TYPES.FAMILY,
        SHIPPING_TYPES.HSINCHU,
        SHIPPING_TYPES.SEVEN,
        SHIPPING_TYPES.KERRY_TH,
        SHIPPING_TYPES.GHN
      ]
      return !this.order.shippingSourceId && shippableTypes.includes(this.order.shippingType)
    },
    showPaymentReceived () {
      // custom shipping and payment only
      if (this.order.paymentType !== PAYMENT_TYPES.CUSTOM) return false
      // canceled or completed order dont show
      if (this.isDoneOrderForCustom) return false

      return this.order.paymentStatus !== PAYMENT_STATUS.PAID
    },
    showOrderComplete () {
      // custom shipping only
      if (this.order.shippingType !== SHIPPING_TYPES.CUSTOM) return false
      // canceled or completed order dont show
      if (this.isDoneOrderForCustom) return false

      return this.order.status === ORDER_STATUS.DELIVERING
    },
    isDoneOrderForCustom () {
      const doneStatus = [
        ORDER_STATUS.CANCELED,
        ORDER_STATUS.DELIVERED,
        ORDER_STATUS.COMPLETED,
        ORDER_STATUS.RETURNING,
        ORDER_STATUS.RETURNED
      ]

      return doneStatus.includes(this.order.status)
    }
  },
  methods: {
    isBtnLoading (name) {
      return this.btnLoading.includes(name)
    },
    print (mode) {
      this.$emit('printOrder', mode)
    },
    shipmentCreate () {
      this.$emit('shipmentCreate')
    },
    toggleDialog (target) {
      this.$emit('toggleDialog', target)
    },
    handlePaymentReceived () {
      this.$emit('paymentReceived')
    },
    handleOrderComplete () {
      this.$emit('orderComplete')
    },
    async handleBack () {
      if (this.$route.query.from) {
        this.$router.replace(this.$route.query.from)
      } else {
        this.$router.push(`/${this.storeName}/order`)
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
