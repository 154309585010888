<script>
import Hls from 'hls.js'
import SoundOnIcon from '@/assets/icons/icon-sound-on.svg'
import SoundOffIcon from '@/assets/icons/icon-sound-off.svg'
import PlayIcon from '@/assets/icons/play.svg'
import PlayButton from '@/assets/icons/play-button.svg'
import PauseIcon from '@/assets/icons/pause.svg'
import PauseButton from '@/assets/icons/pause-button.svg'
import VueTypes from 'vue-types'
import { secondsToTime, timeToSeconds } from '@/utils/timeOperation.js'
import { timeDurationPattern } from '@/constants/regexPattern'

const defaultTime = '00：00：00'
const RANGE_START_CHANGE = 'RANGE_START_CHANGE'
export default {
  name: 'VideoPlayer',
  props: {
    videoUrl: VueTypes.string.def(''),
    watchingCount: VueTypes.number.def(0),
    showWatchCount: VueTypes.bool.def(false),
    showRangeSelector: VueTypes.bool.def(false),
    canEditRange: VueTypes.bool.def(true),
    // 標記預設的開始時間 e.g 標記影格擷取時間
    defaultCurrentTime: VueTypes.number.def(0),
    // 預覽剪輯片段相關參數
    previewStartTime: VueTypes.number.def(null),
    previewEndTime: VueTypes.number.def(null),
    showPreviewRange: VueTypes.bool.def(false),
    // 用來判斷預覽片段的狀況下, 何時要從頭播放判斷用 @see line 146
    isLoopPreview: VueTypes.bool.def(false)
  },
  data () {
    return {
      SoundOnIcon,
      SoundOffIcon,
      PlayIcon,
      PlayButton,
      PauseIcon,
      PauseButton,
      isPause: true,
      videoMuted: false,
      videoElement: null,
      isVideoReady: false,
      videoSrcError: false,
      currentTime: 0,
      videoDuration: 0,
      showTooltip: false,
      displayControl: false,
      clipStartTime: defaultTime,
      clipEndTime: defaultTime,
      sliderElement: null,
      RANGE_START_CHANGE
    }
  },
  computed: {
    elapsedTime () {
      return secondsToTime(this.currentTime)
    },
    duration () {
      // 在預覽模式下, 影片長度需要改為預覽結束時間 e.g 2:00 / 3:00
      return secondsToTime(this.isLoopPreview ? this.previewEndTime : this.videoDuration)
    },
    selectRangeStyle () {
      const left = this.selectedRange[0] / this.videoDuration * 100
      const width = (this.selectedRange[1] - this.selectedRange[0]) / this.videoDuration * 100
      return `left: ${left}%; width: ${width}%`
    },
    playProgressStyle () {
      const currentRangeWidth = this.selectedRange[1] - this.selectedRange[0]
      const playRange = this.currentTime - this.selectedRange[0]
      const width = playRange > 0 ? playRange / currentRangeWidth * 100 : 0
      return `width: ${width}%`
    },
    selectedRange () {
      return [timeToSeconds(this.clipStartTime, '：'), timeToSeconds(this.clipEndTime, '：')]
    },
    previewRangeStyle () {
      const previewRange = this.previewEndTime - this.previewStartTime
      const width = previewRange / this.videoDuration * 100
      const left = this.previewStartTime / this.videoDuration * 100
      return `width: ${width}%; left: ${left}%`
    }
  },
  mounted () {
    this.initializeVideo()
  },
  beforeDestroy () {
    if (this.videoElement) {
      this.videoElement.removeEventListener('loadedmetadata', this.updateVideoDuration)
      this.videoElement.removeEventListener('loadeddata', this.listenLoadedData)
      this.videoElement.removeEventListener('canplaythrough', this.listenCanPlayThrough)
      this.videoElement.removeEventListener('progress', this.updateBuffered)
      this.videoElement.removeEventListener('timeupdate', this.listenTimeUpdate)
      this.videoElement.removeEventListener('seeking', this.listenVideoSeek)
    }
  },

  methods: {
    initializeVideo () {
      const video = this.$refs.video

      if (!video) {
        return
      }

      this.videoElement = video
      if (this.videoUrl.includes('mp4')) {
        this.videoElement.src = this.videoUrl
      } else if (Hls.isSupported() && this.videoUrl) {
        var hls = new Hls()
        hls.loadSource(this.videoUrl)
        hls.attachMedia(this.videoElement)
      }
      this.videoElement.addEventListener('loadedmetadata', this.updateVideoDuration)
      this.videoElement.addEventListener('loadeddata', this.listenLoadedData)
      this.videoElement.addEventListener('canplaythrough', this.listenCanPlayThrough)
      this.videoElement.addEventListener('timeupdate', this.listenTimeUpdate)
      this.videoElement.addEventListener('seeking', this.listenVideoSeek)
    },

    getCurrentTime () {
      return Math.round(this.videoElement.currentTime)
    },

    getDuration () {
      return Math.round(this.videoElement.duration)
    },

    updateVideoDuration () {
      const duration = this.getDuration()
      this.videoDuration = duration
      if (this.isLoopPreview) {
        this.videoElement.currentTime = this.previewStartTime
        this.clipStartTime = secondsToTime(this.previewStartTime)
        this.clipEndTime = secondsToTime(this.previewEndTime)
      } else {
        this.videoElement.currentTime = this.defaultCurrentTime
        this.clipEndTime = secondsToTime(this.videoDuration)
      }
      this.$emit('videoDurationUpdated', duration)
    },
    listenCanPlayThrough () {
      this.isVideoReady = true
      this.displayControl = true
    },
    listenLoadedData () {
      this.$emit('currentFrameLoaded')
    },
    listenTimeUpdate () {
      if (this.isLoopPreview && this.getCurrentTime() >= this.previewEndTime) {
        this.videoElement.currentTime = this.previewStartTime
      } else if (this.showRangeSelector && this.getCurrentTime() >= this.selectedRange[1]) {
        this.videoElement.currentTime = this.selectedRange[0]
      } else {
        this.currentTime = this.getCurrentTime()
      }
    },
    listenVideoSeek () {
      this.currentTime = this.getCurrentTime()
    },
    toggleVideoPlay () {
      if (this.isPause || this.videoElement.end) {
        this.videoElement.play().then(() => {
          this.isPause = false
        })
      } else {
        this.videoElement.pause()
        this.isPause = true
      }
    },
    toggleMuteVideo () {
      this.videoMuted = !this.videoMuted
      this.videoElement.muted = this.videoMuted
    },
    onProgressChange (event) {
      const seconds = Math.round(event.target.value)
      this.videoElement.currentTime = seconds
    },
    onSliderStartTimeInput (event) {
      const seconds = Math.round(event.target.value)
      if (seconds > this.selectedRange[1]) {
        this.clipEndTime = seconds + 5 > this.videoDuration ? this.videoDuration : secondsToTime(seconds + 5)
      }
      this.clipStartTime = secondsToTime(seconds)
    },
    onSliderEndTimeInput (event) {
      const seconds = Math.round(event.target.value)
      if (seconds < this.selectedRange[0]) {
        this.clipStartTime = seconds - 5 > 0 ? secondsToTime(seconds - 5) : 0
      }
      this.clipEndTime = secondsToTime(seconds)
    },
    showControls () {
      this.displayControl = true
    },
    hideControls () {
      this.displayControl = false
    },
    onStartTimeTextInput (value) {
      if (!timeDurationPattern.test(value)) return '：：'

      let seconds = timeToSeconds(value, '：')
      let inputValue = value
      const isOverVideoLength = seconds > this.videoDuration
      const isOverEndTime = seconds > this.selectedRange[1]
      // 開始時間超出影片長度設為 0
      if (isOverVideoLength) {
        seconds = 0
        inputValue = secondsToTime(0)
        this.clipStartTime = inputValue
        return
      }
      // 開始時間超出結束時間, 則結束時間自動 + 5, 且不超過影片長度
      if (isOverEndTime) {
        const secondAddFive = seconds + 5
        const endTime = secondAddFive < this.videoDuration ? secondAddFive : this.videoDuration
        this.clipStartTime = inputValue
        this.clipEndTime = secondsToTime(endTime)
        return
      }
      this.clipStartTime = inputValue
    },
    onEndTimeTextInput (value) {
      if (!timeDurationPattern.test(value)) return '：：'

      let seconds = timeToSeconds(value, '：')
      let inputValue = value

      if (seconds < this.selectedRange[0]) {
        const secondMinuseFive = seconds - 5
        const startTime = secondMinuseFive < 0 ? 0 : secondMinuseFive
        this.clipStartTime = secondsToTime(startTime)
        this.clipEndTime = inputValue
        return
      }
      this.clipEndTime = seconds > this.videoDuration ? secondsToTime(this.videoDuration) : inputValue
    },
    updateRangeTime (type) {
      if (type === RANGE_START_CHANGE) { this.videoElement.currentTime = this.selectedRange[0] }
      this.$emit('updateNewClip', { start: this.selectedRange[0], end: this.selectedRange[1] })
    },
    formatStartTimeTextInput () {
      this.clipStartTime = secondsToTime(this.selectedRange[0])
      this.updateRangeTime(RANGE_START_CHANGE)
    },
    formatEndTimeTextInput () {
      this.clipEndTime = secondsToTime(this.selectedRange[1])
      this.updateRangeTime()
    },
    setSliderElement () {
      const slider = this.$refs.rangeSlider
      if (slider) {
        this.sliderElement = {
          left: slider.getBoundingClientRect().left,
          width: slider.clientWidth
        }
      }
    },
    computeCurrentTime (event) {
      if (!this.sliderElement) {
        this.setSliderElement()
      }
      const clickLeft = event.clientX
      const shiftX = clickLeft - this.sliderElement.left
      const seconds = Math.round(shiftX / this.sliderElement.width * this.videoDuration)
      this.videoElement.currentTime = seconds
    },
    resetClipData () {
      // 離開新建剪輯片段時, 應暫停影片播放, 並重設時間
      if (!this.isPause) {
        this.videoElement.pause()
        this.isPause = true
      }
      this.videoElement.currentTime = 0
      this.clipStartTime = defaultTime
      this.clipEndTime = secondsToTime(this.videoDuration)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./styles.scss" scoped></style>
