import { createProduct as apiCreateProduct } from '../apis'

import * as loadingState from '@/constants/loadingState'

export const types = {
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_FAILED: 'CREATE_PRODUCT_FAILED'
}

const INIT_DATA = {
  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  createProductLoadingState: state => state.loadingState
}

const mutations = {
  [types.CREATE_PRODUCT] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.CREATE_PRODUCT_SUCCESS] (state) {
    state.loadingState = loadingState.READY
  },

  [types.CREATE_PRODUCT_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  }
}

const actions = {
  createProduct ({ getters, commit, rootGetters }) {
    commit(types.CREATE_PRODUCT)

    return apiCreateProduct(rootGetters['Me/storeName'], getters.calcProductData)
      .then(response => {
        commit(types.CREATE_PRODUCT_SUCCESS)
        return response
      })
      .catch(err => {
        commit(types.CREATE_PRODUCT_FAILED, err)
        throw err
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
