<script>
import ApiProduct from '@/api/product'
import { mapGetters, mapActions } from 'vuex'

import MarketProducts from './components/Products'
import DialogPickProduct from '@/components/DialogPickProduct'
import Pagination from '@/components/Pagination'

import DialogPickTickets from './components/DialogPickTickets'
import DialogConfirmRemove from './components/DialogConfirmRemove'

export default {
  name: 'Market',
  components: {
    MarketProducts,
    DialogPickProduct,
    Pagination,
    DialogConfirmRemove,
    DialogPickTickets
  },

  data () {
    return {
      apiProduct: new ApiProduct(),
      products: [],
      tickets: [],
      selectProducts: [],
      visiblePickProduct: false,
      isBtnLoading: false,
      currentPage: 1,
      searchRule: {
        keyword: ''
      },
      paginator: null,
      showConfirmRemove: false
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore', 'enabledTicket', 'enabledMerchandise', 'showInstructionLink']),
    filterSearchRule () {
      let param = Object.keys(this.searchRule).reduce((prev, key) => {
        if (this.searchRule[key]) prev[key] = this.searchRule[key]
        return prev
      }, {
        page: this.currentPage,
        'with_market': true
      })

      return param
    },
    buyerPath () {
      return this.$VUE_APP_BUYER_DOMAIN_NAME + '/' + this.currentStore.slug
    }
  },
  mounted () {
    this.fetchProducts() // 取得商品資料
  },
  methods: {
    ...mapActions(['setLoading']),
    async fetchProducts () {
      const params = Object.assign(this.filterSearchRule)
      const data = await this.apiProduct.list(params)

      this.products = data
      this.paginator = this.apiProduct.paginator
    },
    async handlePickProduct (products) {
      if (products.length === 0) return
      const productIds = products.map(item => item.id)

      const updateData = {
        market: true,
        product_ids: productIds
      }
      await this.apiProduct.updateMarket(updateData)

      this.currentPage = 1
      this.fetchProducts()
      this.$refs.DialogPickProduct.fetchProducts()
    },
    async handleDelete (ids) {
      const updateData = {
        market: false,
        product_ids: ids
      }
      await this.apiProduct.updateMarket(updateData)

      this.currentPage = 1
      this.fetchProducts()
      this.$refs.DialogPickProduct.fetchProducts()
    },
    handleClickDelete (product) {
      this.handleToggleSelect(product)
      this.showConfirmRemove = true
    },
    handleToggleSelect (product) {
      const idx = this.selectProducts.findIndex(item => item.id === product.id)

      idx === -1
        ? this.selectProducts.push(_.cloneDeep(product))
        : this.selectProducts.splice(idx, 1)
    },
    handleToggleAll () {
      const isSomeCount = this.products.length === this.selectProducts.length

      if (isSomeCount) {
        // 全取消
        this.selectProducts = []
      } else {
        // 全選
        this.selectProducts = []
        this.selectProducts = _.cloneDeep(this.products)
      }
    },
    handleUndelete () {
      this.showConfirmRemove = false
      this.visiblePickProduct = false
      this.selectProducts = []
    },
    async handleRemoveSelected () {
      this.isBtnLoading = true

      const removeIds = this.selectProducts.map(item => item.id)
      await this.handleDelete(removeIds)
      this.handleUndelete() // 關閉 Dialog

      this.$message({
        message: this.$t('Market.message.success_delete'),
        type: 'success',
        showClose: true
      })

      this.isBtnLoading = false
    },
    handleChangeSearch: _.debounce(async function () {
      this.setLoading(true)
      this.currentPage = 1
      await this.fetchProducts() // 取得商品資料
      this.setLoading(false)
    }, 500),
    updateRows (rows) {
      this.products = rows
    },
    toMarketProduct (product) {
      const path = `${this.buyerPath}/market/product/${product.id}`
      window.open(path, '_blank')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
