<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentResult',
  components: {},
  props: {
    status: {
      type: Boolean,
      default: true
    },
    points: {
      type: Number,
      default: 0
    },
    price: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol'])
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    handleConfirm () {
      this.$emit('confirm')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
