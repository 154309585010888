<script>
import VueTypes from 'vue-types'
import { mapActions, mapGetters } from 'vuex'

import ProductFormName from '@/views/ProductDetail/components/ProductFormName'
import ProductFormImageSortable from '@/views/ProductDetail/components/ProductFormImageSortable'
import ProductFormSku from '@/views/ProductDetail/components/ProductFormSku'
import ProductFormOther from '@/views/ProductDetail/components/ProductFormOther'

export default {
  name: 'DialogEditProduct',

  components: {
    ProductFormName,
    ProductFormImageSortable,
    ProductFormSku,
    ProductFormOther
  },

  props: {
    selectedProducts: VueTypes.array.def([])
  },

  data () {
    return {
      showFormImage: false,
      showFormOther: false,
      isLoading: false
    }
  },

  computed: {
    ...mapGetters('ProductDetail', ['editProduct', 'calcProductData']),

    selectedProductsKeyword () {
      return this.selectedProducts
        .filter(({ id }) => id !== this.editProduct.id)
        .map(product => product.keyword)
    }
  },

  beforeDestroy () {
    this.resetEditProduct()
  },

  methods: {
    ...mapActions('ProductDetail', [
      'createProduct',
      'resetEditProduct'
    ]),

    handleCloseDialog () {
      this.$emit('close')
      this.$emit('update:dialogVisible', false)
    },

    handleDeleteProduct () {
      this.$confirm(this.$t('Components.DialogPostProduct.delete_sku_confirm'),
        this.$t('Components.DialogPostProduct.remind'), {
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel'),
          type: 'error'
        })
        .then(() => {
          this.$emit('handleDeleteProduct', this.editProduct, true)
          this.$emit('update:dialogVisible', false)
        })
        .catch(() => {})
    },

    async validateFormName () {
      try {
        await this.$refs.ProductFormName.validateForm()
        return true
      } catch (error) {
        return false
      }
    },

    validateFormSku () {
      try {
        return this.$refs.ProductFormSku.validateForm()
      } catch (error) {
        return false
      }
    },

    validateFormOther () {
      try {
        return this.$refs.ProductFormOther.validateForm()
      } catch (error) {
        return false
      }
    },

    async handleSubmit () {
      const [validateName, validateSku, validateOther] = await Promise.all([
        this.validateFormName(),
        this.validateFormSku(),
        this.validateFormOther()
      ])

      if (!validateName || !validateSku || !validateOther) return

      this.isLoading = true

      this.$emit('handleUpdateProduct', this.calcProductData)
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogEditProduct.pug"></template>
