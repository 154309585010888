<script>
import VueTypes from 'vue-types'

export default {
  name: 'AppTable',
  props: {
    columns: VueTypes.array.def([]),
    data: VueTypes.array.def([]),
    expandValue: VueTypes.string.def(null), // 展開是 depend on data 的哪個 key，必須是 array
    expandDisppearCol: VueTypes.string.def(null), // 展開時要藏起來的 column
    isNoMargin: VueTypes.bool.def(false) // ul 是否需要 margin
  },

  data: () => ({
    expandId: null,
    expandMap: {},
    sort: {
      col: null,
      desc: null
    }
  }),

  methods: {
    handleExpandRow (id) {
      this.$set(this.expandMap, id, !this.expandMap[id])
    },

    isExpandRow (id) {
      return this.expandMap[id]
    },

    handleCol (col) {
      if (col.sort) {
        this.handleSort(col)
      }
    },

    handleSort (col) {
      if (this.sort.col === col.id) {
        if (this.sort.desc === null) {
          this.sort.desc = '1'

        // 第三次點擊 還原
        } else {
          this.sort.col = null
          this.sort.desc = null
        }
      } else {
        // 第一次點擊 ASC
        this.sort.col = col.id
        this.sort.desc = null
      }

      col.sort(col)
    },

    getCellStyle (col) {
      return {
        'flex': col.width ? `0 0 ${col.width}px` : 'none',
        'justify-content': col.align ? `flex-${col.align}` : 'flex-start' // only `end`, `start`(default)
      }
    }
  }
}
</script>

<template lang="pug" src="./AppTable.pug"></template>
<style lang="scss" src="./AppTable.scss" scoped></style>
