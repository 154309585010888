<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FbMessengerCheckbox',
  props: {
    tooltip: {
      type: String
    }
  },
  computed: {
    ...mapGetters('Me', ['me', 'storeName']),
    env: () => process.env,
    userRef () {
      return (new Date()).getTime() + Math.random().toString(36).slice(0, 10) + this.myId
    },
    myId () {
      return this.me ? this.me.id : 1
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
