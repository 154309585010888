<script>
import { passwordRule } from '@/utils/formRules'
import LayoutCard from '../components/LayoutCard'
import { changePassword, checkToken } from './changePassword.js'

export default {
  name: 'ChangePassword',
  components: { LayoutCard },
  skipMiddleware: ['getAuth', 'checkAuth'],
  data () {
    return {
      loading: false,
      email: this.$route.query.email,
      token: this.$route.query.token,
      form: {
        password: '',
        password2: ''
      },
      showConfirm: false,
      tokenInvalid: false
    }
  },
  computed: {
    rules () {
      return {
        password: passwordRule(),
        password2: [
          { required: true, message: this.$t('field.password2.diff_error') },
          { validator: this.passwordSameValidator, message: this.$t('field.password2.diff_error') }
        ]
      }
    }
  },
  mounted () {
    if (!this.email || !this.token) {
      this.$router.push({ name: 'UserLogin' })
    }
    this.checkToken()
  },
  methods: {
    async submit () {
      try {
        await this.$refs.form.validate()
      } catch {
        return
      }
      this.showConfirm = true
    },
    async changePassword () {
      this.loading = true
      try {
        const payload = {
          token: this.token,
          password: this.form.password
        }
        await changePassword(payload)
        this.$message.success(this.$t('User.ChangePassword.success'))
        this.goLogin()
      } catch (e) {
        console.error(e)
        this.tokenInvalid = true
      }
      this.loading = false
      this.showConfirm = false
    },
    async checkToken () {
      try {
        const { data: { success, data: { is_valid: isValid } } } = await checkToken(this.token)
        if (!isValid || !success) {
          this.tokenInvalid = true
        }
      } catch (e) {
        this.tokenInvalid = true
        console.error(e)
      }
    },
    passwordSameValidator (rule, value, cb) {
      value === this.form.password ? cb() : cb(new Error())
    },
    goLogin () {
      this.$router.push({ name: 'UserLogin' })
    }
  }
}
</script>

<template lang="pug" src="./ChangePassword.pug"></template>
<style lang="scss" src="./ChangePassword.scss" scoped></style>
