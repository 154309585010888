<template>
  <div>
    <p class="mt-2 mb-2">
      {{ title || $t("Post.PushNotification.upcoming_video.title") }}
    </p>
    <div
      v-loading="isVideoUploading"
      class="flex items-end"
    >
      <el-upload
        name="video"
        class="upload"
        :accept="acceptedVideoType"
        :action="uploadPath"
        :headers="uploadHeaders"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :on-success="handleVideoUploadResult"
        :on-error="handleVideoUploadResult"
      >
        <i
          v-if="!videoObjectUrl"
          class="uploader-icon far fa-plus"
        />
        <i
          v-else
          class="el-icon-circle-close delete-video-button"
          @click.stop="handleDeleteVideo"
        />
        <video
          v-if="videoObjectUrl"
          class="preview-video"
          :src="videoObjectUrl"
        />
      </el-upload>
      <div class="flex-1 ml-2 leading-normal text-gray-800 text-xs">
        {{ $t("Post.PushNotification.upcoming_video.rule1") }}
        <br>
        {{ $t("Post.PushNotification.upcoming_video.rule2") }}
        <br>
        {{ $t("Post.PushNotification.upcoming_video.rule3") }}
      </div>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import { deleteVideo } from '@/api/video'
import { getUploadHeaders, getVideoUploadPath } from '@/utils/getVideoUploadInfo'

export default {
  name: 'VideoUpload',
  props: {
    title: VueTypes.string.def(''),
    acceptedVideoType: VueTypes.string.def('.mp4,video/mp4'),
    sizeLimit: VueTypes.number.def(15),
    defaultVideo: VueTypes.string.def(''),
    defaultVideoId: VueTypes.number.def(null),
    isVideoUploading: VueTypes.bool.def(false)
  },
  data () {
    return {
      videoObjectUrl: this.defaultVideo,
      videoId: this.defaultVideoId,
      uploadHeaders: getUploadHeaders(),
      uploadPath: getVideoUploadPath()
    }
  },
  methods: {
    beforeUpload (file) {
      if (!this.uploadPath) {
        return false
      }
      const isSizeValid = file.size / 1024 / 1024 <= this.sizeLimit
      if (!isSizeValid) {
        this.$message.error(this.$t('Components.VideoUpload.error.size', {size: this.sizeLimit}))
        return false
      }
      this.videoObjectUrl = URL.createObjectURL(file)
      this.$emit('update:isVideoUploading', true)
      return true
    },
    async handleDeleteVideo () {
      URL.revokeObjectURL(this.videoObjectUrl)
      this.$emit('update:isVideoUploading', true)
      this.videoObjectUrl = ''
      await deleteVideo(this.videoId)
      this.$emit('updateVideoId', null)
      this.$emit('update:isVideoUploading', false)
    },
    handleVideoUploadResult (response) {
      if (response && response.id) {
        this.videoId = response.id
        this.$emit('updateVideoId', response.id)
      } else {
        this.$message.error(this.$t('Components.VideoUpload.common_error'))
        URL.revokeObjectURL(this.videoObjectUrl)
        this.videoObjectUrl = ''
      }
      this.$emit('update:isVideoUploading', false)
    },
    
  }
}
</script>
<style lang="scss" scoped>
.upload {
  border: 1px dashed $border-dusty-gray;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 110px;
  background: $gray-100;

  ::v-deep .el-upload--text {
    width: 100%;
    height: 100%;
  }
}
.uploader-icon {
  font-size: 30px;
  display: inline-block;
  text-align: center;
  width: 100%;
  color: $primary;
  margin: auto;
}
.delete-video-button {
  position: absolute;
  font-size: 20px;
  top: 5px;
  right: 5px;
  z-index: 2;
  color: $primary;
  cursor: pointer;
}
.preview-video {
  width: 100%;
  height: 100%;
  vertical-align: baseline;
}
</style>
