<script>

export default {
  name: 'Step5',
  methods: {
    goSetting () {
      this.$router.push({ name: 'Setting', query: { activeMenu: 'SettingSeller' } })
    }
  }
}
</script>

<template lang="pug" src="./Step5.pug"></template>
