const state = {
  buyingPlan: {
    id: null,
    frequency: null
  }
}

const getters = {}

const mutations = {
  UPDATE_BUYING_PLAN (state, payload) {
    state.buyingPlan = payload
  }
}

const actions = {
  updateBuyingPlan ({ commit, dispatch }, payload) {
    commit('UPDATE_BUYING_PLAN', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
