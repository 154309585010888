<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

const STATUS_TYPES = {
  SUCCESS: 'success',
  FAILED: 'failed'
}

export default {
  name: 'DialogCreditCardNotice',
  props: {
    storeName: VueTypes.string.isRequired,
    status: VueTypes.string.isRequired,
    message: VueTypes.string.def('')
  },

  computed: {
    ...mapGetters('Me', ['apiUrl', 'isEnterprise']),

    isSuccess () {
      return this.status.toLowerCase() === STATUS_TYPES.SUCCESS
    }
  },

  methods: {
    toPayBill () {
      const endpoint = this.isEnterprise ? 'agreed-payment' : 'payment'
      const url = `${this.apiUrl}/api/v1/stores/${this.storeName}/bills/${endpoint}`
      window.location.href = url
    },
    close () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogCreditCardNotice.pug"></template>
<style lang="scss" scoped>
  .far {
    font-size: 5rem;
  }

</style>
