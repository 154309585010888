import ApiPost from '@/api/post'
import * as loadingState from '@/constants/loadingState'

const types = {
  FETCH_CHANNEL_NAMES: 'FETCH_CHANNEL_NAMES',
  FETCH_CHANNEL_NAMES_SUCCESS: 'FETCH_CHANNEL_NAMES_SUCCESS',
  FETCH_CHANNEL_NAMES_FAILED: 'FETCH_CHANNEL_NAMES_FAILED'
}

const state = {
  loading: loadingState.EMPTY,
  groupName: null,
  errMessage: null
}

const getters = {
  getGroupName: state => state.groupName
}

const mutations = {
  [types.FETCH_CHANNEL_NAMES] (state) {
    state.loading = loadingState.LOADING
    state.groupName = null
    state.errMessage = null
  },

  [types.FETCH_CHANNEL_NAMES_SUCCESS] (state, { groupName }) {
    state.loading = loadingState.READY
    state.groupName = groupName
    state.errMessage = null
  },

  [types.FETCH_CHANNEL_NAMES_FAILED] (state, { errMessage }) {
    console.error('DEBUG error message', errMessage)
    state.loading = loadingState.ERROR
    state.groupName = null
    state.errMessage = errMessage
  }
}

const actions = {
  fetchChannelNames ({ commit }, { postId }) {
    commit(types.FETCH_CHANNEL_NAMES)

    const apiPost = new ApiPost()

    return apiPost
      .getChannelNames(postId)
      .then(({ group_name: groupName }) => {
        commit(types.FETCH_CHANNEL_NAMES_SUCCESS, { groupName })

        return groupName
      })
      .catch(err => commit(types.FETCH_CHANNEL_NAMES_FAILED, {
        errMessage: err.message
      }))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
