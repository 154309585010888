<script>
import { mapGetters, mapActions } from 'vuex'

const FILTER_KEYS = {
  DATE_RANGE: 'date_range',
  KEYWORD: 'keyword',
  PER_PAGE: 'per_page',
  MISTAKE_ONLY: 'mistake_only'
}

export default {
  name: 'ApproveTableFilter',
  data () {
    return {
      ...FILTER_KEYS,
      dateRange: []
    }
  },
  computed: {
    ...mapGetters('BatchApprove', ['filter'])
  },
  methods: {
    ...mapActions('BatchApprove', [
      'fetchApproves',
      'updateFilter',
      'resetFilter'
    ]),
    debouncedFetch: _.debounce(function () {
      this.fetchApproves()
    }, 500),
    handleRefresh () {
      this.dateRange = null
      this.resetFilter()
      this.debouncedFetch()
    },
    changeFilter (val, key) {
      this.updateFilter({ ...this.filter, [key]: val })
      this.debouncedFetch()
    },
    changeDate (val) {
      this.dateRange = val
      this.updateFilter({
        ...this.filter,
        from: val ? val[0] : '',
        to: val ? val[1] : ''
      })

      this.debouncedFetch()
    }
  }
}
</script>

<template lang="pug" src="./ApproveTableFilter.pug"></template>
<style lang="scss" src="./ApproveTableFilter.scss" scoped></style>
