<script>
import { mapGetters, mapActions } from 'vuex'

import { currency } from '@/filters'
import * as LIVE_STATUS from '@/constants/liveStatus'
import TicketBadge from '@/components/TicketBadge'
import privateIcon from '@/assets/icon-channel-private@2x.png'

import * as TABS from '@/views/Post/constants/tabs'
import VueTypes from 'vue-types'

const ZERO = 0

export default {
  name: 'PublishedHeader',

  components: {
    TicketBadge
  },

  props: {
    backViewName: VueTypes.string.def(''),
    changeTab: VueTypes.func.def(() => {})
  },

  data () {
    return {
      TABS,
      privateIcon,
      ...LIVE_STATUS
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'region', 'enabledPostAnalysis', 'me']),
    ...mapGetters('Post', ['activeTab', 'celebrityToken', 'postId', 'post', 'liveStatus', 'isCelebrityEnterprise', 'isCelebrityStoreOwner']),
    ...mapGetters('Post', ['analysisData']),

    hasCelebrityToken () {
      return !!this.celebrityToken
    },

    salesCount () {
      if (!this.analysisData) return ZERO
      return currency(this.analysisData.sales_count)
    },

    salesAmount () {
      if (!this.analysisData) return ZERO
      return currency(this.analysisData.sales_amount)
    },

    isHandsup () {
      return this.post && this.post.channels.some(channel => channel.type === 'handsup')
    },

    hasAgent () {
      return this.post && !!this.post.agent
    },

    isCelebrity () {
      return this.post && !!this.post.celebrity
    },

    canCopyLink () {
      const date = this.post.replay_expired_at
      if (this.liveStatus === LIVE_STATUS.LIVE_STOPPED) {
        return new Date(date) > new Date()
      } else {
        return true
      }
    },

    canCopyAgentLink () {
      const POST_AGENT_STATUS = {
        UNBOUND: 'unbound', // 網紅授權未綁定
        EXPIRED: 'expired', // 授權過期
        LIVE: 'live', // 直播中
        END: 'end' // 已結束
      }

      // 網紅直播間不提供 複製授權連結
      if (this.hasCelebrityToken) return false

      return [POST_AGENT_STATUS.UNBOUND, POST_AGENT_STATUS.EXPIRED].includes(this.post.agent.status)
    },

    activeTabValue: {
      get () {
        return this.activeTab
      },
      set (tab) {
        this.changeTab(tab)
      }
    },

    canBroadcast () {
      if (this.region !== 'TW') return false

      return this.liveStatus === LIVE_STATUS.LIVE
    },

    showActiveTab () {
      // 顯示條件：
      // 非企業版店家 & 非企業版網紅 & 直播結束
      return this.enabledPostAnalysis && !this.isCelebrityEnterprise && this.liveStatus === LIVE_STATUS.LIVE_STOPPED
    },

    isReplaying () {
      const date = this.post.replay_expired_at
      if (!date) return false
      if (this.liveStatus === LIVE_STATUS.LIVE_STOPPED) {
        return new Date(date) > new Date()
      } else {
        return false
      }
    },

    disableStopLive () {
      return this.liveStatus !== LIVE_STATUS.LIVE ||
        this.isCelebrityStoreOwner ||
        // Custom for Fast Retailing. Break the Norm!
        (this?.me?.email && this.me.email.includes('e-guardian'))
    }
  },

  methods: {
    ...mapActions('Post', ['toggleDialogStopLive', 'toggleDialogLiveAuthorization', 'resetDialogVisible']),

    goBack () {
      if (this.backViewName) {
        this.$router.push({ name: this.backViewName })
      } else {
        this.$router.back()
      }
    }
  }
}
</script>

<template lang="pug" src="./PublishedHeader.pug"></template>
<style lang="scss" src="./PublishedHeader.scss" scoped></style>
