import GetDefaultKeyword from './GetDefaultKeyword'
import ProductList from './ProductList'

export default {
  namespaced: true,
  modules: {
    GetDefaultKeyword,
    ProductList
  }
}
