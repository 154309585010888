<script>
import VueTypes from 'vue-types'
import { validateInvoice } from '../../utils/validateInvoice'

export default {
  name: 'DialogShipmentStoreInvoice',
  props: {
    dialogVisible: VueTypes.bool.def(false)
  },
  data () {
    return {
      form: {
        invoice: null
      },
      rules: {
        invoice: [
          {
            validator: validateInvoice,
            trigger: 'blur',
            required: false
          }
        ]
      }
    }
  },
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    async submit () {
      try {
        await this.$refs['form'].validate()
        const params = {
          invoice: this.form.invoice
        }
        this.$emit('deliverStoreOrder', params)
      } catch (e) {
      }
    },
    handleContinue () {
      this.submit()
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
