<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentDetail',

  props: {
    payments: VueTypes.array.def([])
  },

  computed: {
    ...mapGetters('Me', ['currencySymbol']),

    normalizePayments () {
      // 訂閱制，若沒有 deferred_at 時，代表訂單已取消，且不會再被扣款了。
      return this.payments.filter(payment => !!payment.deferred_at)
    }
  }
}
</script>

<template lang="pug" src="./PaymentDetail.pug"></template>
<style lang="scss" src="./PaymentDetail.scss" scoped></style>
