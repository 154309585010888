<script>

import { mapGetters, mapActions } from 'vuex'

import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import * as PAYMENT_FLOW from '@/constants/paymentFlow'
import * as PLANS from '@/constants/plans'
import * as REGIONS from '@/constants/regions'

import ApiShipping from '@/api/shipping'
import ShippingSetting from '@/api/shippingSetting'
import CarrierSetting from '@/api/carrierSetting'

import DialogShippingSetting from './components/DialogShippingSetting'
import DialogLogistic from './components/DialogLogistic'
import DialogB2C from './components/DialogB2C'
import DialogOverseaShipping from './components/DialogOverseaShipping'
import DialogStorePickup from './components/DialogStorePickup'
import ShippingFee from './components/ShippingFee'

export default {
  name: 'ShippingContainer',
  components: {
    DialogLogistic,
    DialogShippingSetting,
    DialogB2C,
    DialogOverseaShipping,
    DialogStorePickup,
    ShippingFee
  },
  data () {
    return {
      SHIPPING_TYPES,

      apiShipping: new ShippingSetting(),
      apiCarrier: new CarrierSetting(),
      availableShippingTypes: [],
      codAbleShippingTypes: [],
      shippingTypes: {
        custom_self_pick_up: {
          type: 'custom_self_pick_up',
          enabled: false,
          icon: 'fal fa-hand-holding-box'
        },
        offline_custom_tcat_cod: {
          type: 'offline_custom_tcat_cod',
          enabled: false,
          icon: 'fal fa-box-heart'
        },
        home_delivery: {
          type: 'home_delivery',
          enabled: false,
          icon: 'fal fa-box-heart'
        },
        family: {
          type: 'family',
          enabled: false,
          img: require('@/assets/icon-family.png'),
          b2c: true
        },
        seven: {
          type: 'seven',
          enabled: false,
          img: require('@/assets/icon-711.png')
        },
        hsinchu: {
          type: 'hsinchu',
          enabled: false,
          icon: 'fal fa-truck',
          carrierSetting: true
        },
        custom: {
          type: 'custom',
          enabled: false,
          icon: 'fal fa-box-heart'
        },
        self_pick_up: {
          type: 'self_pick_up',
          enabled: false,
          icon: 'fal fa-hand-holding-box'
        }
      },

      shippingSetting: null,
      dialogLogisticsVisible: false,
      shippingVisible: false,
      rawShipping: [],
      carrierSetting: {
        hsinchu: {
          type: 'hsinchu',
          default: true
        },
        'seven-business': {
          type: 'seven-business',
          default: true
        },
        'family-business': {
          type: 'family-business',
          default: true
        }
      },
      currentCarrier: null,
      dialogB2Cvisible: false,
      B2Cstep: null,
      dialogOverseaVisible: false,
      dialogStorePickupVisible: false
    }
  },
  computed: {
    ...mapGetters('Me', [
      'currentStore',
      'storeName',
      'currencySymbol',
      'enabledCustomSelfPickUp',
      'paymentFlow',
      'region'
    ]),
    computedShipping () {
      let shippingTypes = {}
      for (const type of this.availableShippingTypes) {
        if (!this.shippingTypes[type]) continue
        shippingTypes[type] = _.cloneDeep(this.shippingTypes[type])
        const normalSetting = this.rawShipping.find(shipping => shipping.type === type)
        if (normalSetting) {
          shippingTypes[type]['normal'] = _.cloneDeep(normalSetting)
          shippingTypes[type]['enabled'] = shippingTypes[type]['enabled'] || normalSetting.enabled
        }
        const codSetting = this.rawShipping.find(shipping => {
          if (!shipping.type.includes('_cod')) return
          return shipping.type === type + '_cod' || shipping.type === type
        })
        if (codSetting) {
          shippingTypes[type]['cod'] = _.cloneDeep(codSetting)
          shippingTypes[type]['enabled'] = shippingTypes[type]['enabled'] || codSetting.enabled
        }
      }
      return shippingTypes
    },
    shippingDialogComponent () {
      const shippingComponent = {
        [REGIONS.TW]: 'DialogShippingSetting',
        [REGIONS.JP]: 'DialogOverseaShipping'
      }

      return shippingComponent[this.region] || 'DialogShippingSetting'
    },
    showApplyFamilyB2C () {
      // 非代收代付，不提供申請
      if (this.paymentFlow === PAYMENT_FLOW.DIRECT) return false

      return this.currentStore.plan.name !== PLANS.NOVICE && this.currentStore.plan.name !== PLANS.FREE_TRIAL
    }
  },
  async created () {
    this.setLoading(true)
    await Promise.all([
      this.fetchShippingTypes(),
      this.listShipping(),
      this.getCarrierSettings()
    ])
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['setLoading']),
    async fetchShippingTypes () {
      const apiShipping = new ApiShipping()
      const shippingTypes = await apiShipping.getTypes(this.storeName)
      if (!shippingTypes) return
      // 調整shipment順序
      // 丟丟妹的'門市取貨付款+宅配取貨付款'
      const customSelfPickUpTypes = [SHIPPING_TYPES.CUSTOM_SELF_PICK_UP, SHIPPING_TYPES.OFFLINE_CUSTOM_TCAT_COD]
      const offlineShippings = shippingTypes.types.filter(item => {
        return customSelfPickUpTypes.includes(item)
      })
      // 一般shipment
      const shippings = shippingTypes.types.filter(item => {
        return !customSelfPickUpTypes.includes(item)
      })
      // 丟丟妹才打開此shipment
      this.availableShippingTypes = this.enabledCustomSelfPickUp ? offlineShippings : shippings
      // this.availableShippingTypes = shippingTypes.types
      this.codAbleShippingTypes = shippingTypes['cod_able_types']
    },
    async listShipping () {
      const data = await this.apiShipping.list({}, 'all')
      this.rawShipping = data
    },
    async getCarrierSettings () {
      // TW only carrier settings
      if (this.region !== REGIONS.TW) return

      for (const [key, method] of Object.entries(this.shippingTypes)) {
        if (!method.carrierSetting) continue
        const data = await this.apiCarrier.get(key)
        if (!data || (Array.isArray(data) && data.length === 0)) continue
        data.type = key
        this.carrierSetting[key] = data
      }

      const data = await this.apiCarrier.get('family-business')
      if (!data || (Array.isArray(data) && data.length === 0)) return

      this.carrierSetting['family-business'] = data
    },
    async showDeliveryModal (setting) {
      const clonedSetting = _.cloneDeep(setting)
      clonedSetting.codAble = this.codAbleShippingTypes.includes(setting.type)
      // Get last set sender info
      const lastAddress = _.find(this.computedShipping, shipping => {
        return shipping.normal && shipping.normal.address
      })
      if (lastAddress) {
        clonedSetting.lastAddress = {
          sender_address: lastAddress.normal.address,
          sender_phone: lastAddress.normal.sender_phone,
          sender_name: lastAddress.normal.sender_name
        }
      }

      if ([SHIPPING_TYPES.SELF_PICK_UP, SHIPPING_TYPES.CUSTOM_SELF_PICK_UP].includes(clonedSetting.type)) {
        // 門市純取貨+門市取貨付款
        this.dialogStorePickupVisible = true
      } else {
        this.shippingVisible = true
      }

      this.shippingSetting = clonedSetting
      if (setting.normal && setting.normal.type === SHIPPING_TYPES.FAMILY) {
        this.currentCarrier = this.carrierSetting['family-business']
      } else {
        this.currentCarrier = null
      }
    },
    async showCarrierModal (key) {
      const setting = this.carrierSetting[key]
      this.dialogLogisticsVisible = true
      this.currentCarrier = setting
    },
    async updateShipping (data) {
      this.setLoading(true)
      let updateSucceeded = true
      if (data.normal) {
        updateSucceeded = updateSucceeded && await this.apiShipping.update('', data.normal)
      }

      // 小資方案 無 取貨付款，故不打 API
      if (this.currentStore.plan.name !== 'novice' && data.cod) {
        updateSucceeded = updateSucceeded && await this.apiShipping.update('', data.cod)
      }

      if (data.carrier) {
        updateSucceeded = await this.apiCarrier.update('', data.carrier)
      }

      if (updateSucceeded) {
        this.$message({
          showClose: true,
          duration: 1000,
          message: this.$t('message.save_success'),
          type: 'success'
        })
        const selfPickupTypes = [SHIPPING_TYPES.SELF_PICK_UP, SHIPPING_TYPES.CUSTOM_SELF_PICK_UP]
        if (data.normal && selfPickupTypes.includes(data.normal.type)) {
          this.dialogStorePickupVisible = false
        } else {
          this.shippingVisible = false
        }
        await this.listShipping()
        await this.getCarrierSettings()
        this.checkSetting()
      } else {
        this.$message.error(this.$t('message.save_fail'))
      }
      this.setLoading(false)
    },
    async updateShippingEnabled (data, type) {
      // Show modal on toggle enable
      // Disable settings on toggle disable
      if (data.enabled) {
        data.enabled = false
        // Show carrier setting first if not set
        if (data.carrierSetting && this.carrierSetting[type].default) {
          this.showCarrierModal(type)
        } else {
          this.showDeliveryModal(data)
        }
        return
      } else {
        if (data.normal) {
          data.normal.enabled = false
          await this.apiShipping.update('', data.normal)
        }
        if (data.cod) {
          data.cod.enabled = false
          await this.apiShipping.update('', data.cod)
        }
      }
      await this.listShipping()
      this.checkSetting()
    },
    remindSaving (done) {
      const create = this.$createElement
      this.$msgbox({
        title: '',
        center: true,
        showClose: true,
        customClass: 'remind-saving',
        message: create('h4', null, [
          create('span', null, this.$t('message.leave_page')),
          create('br', null, ''),
          create('span', null, this.$t('message.confirm_leave'))
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel')
      })
        .then(() => {
          done()
        })
        .catch(action => {
        })
    },
    checkSetting () {
      this.$emit('checkSetting')
    },
    async updateLogistic (form) {
      this.setLoading(true)
      const updateSucceeded = await this.apiCarrier.update('', form)
      if (updateSucceeded) {
        this.$message({
          showClose: true,
          duration: 1000,
          message: this.$t('message.save_success'),
          type: 'success'
        })
        await this.getCarrierSettings()
      } else {
        this.$message.error(this.$t('message.save_fail'))
      }
      this.dialogLogisticsVisible = false
      this.setLoading(false)
    },
    async handleB2CSubmit (data) {
      this.setLoading(true)
      if (data.carrierForm) {
        const success = await this.apiCarrier.update('', data.carrierForm)
        if (!success) {
          this.setLoading(false)
          return
        }
      }
      if (data.senderForm) {
        const success = await this.apiShipping.update('', data.senderForm)
        if (!success) {
          this.setLoading(false)
          return
        }
      }
      this.setLoading(false)
      await this.listShipping()
      await this.getCarrierSettings()
      this.checkSetting()
      this.B2Cstep = 'applying'
      this.$message.success(this.$t('Setting.Payment.family_b2c_success'))

      this.$emit('checkCarrierSetting')
    },
    async checkCarrierSetting (type) {
      this.$emit('checkCarrierSetting')
      this.setLoading(true)
      const res = await this.apiCarrier.get(type)
      if (!res) return

      if (res.is_verified) {
        this.B2Cstep = 'success'
      } else {
        this.B2Cstep = 'applying'
      }
      this.dialogB2Cvisible = true
      this.setLoading(false)
    },
    applyFamilyB2C (setting) {
      const clonedSetting = _.cloneDeep(setting.normal)
      this.shippingSetting = clonedSetting
      this.currentCarrier = this.carrierSetting['family-business']
      this.dialogB2Cvisible = true
      this.B2Cstep = 'submitForm'
    }
  }
}
</script>

<template lang="pug" src="./ShippingContainer.pug"></template>
<style lang="scss" src="./ShippingContainer.scss" scoped></style>
