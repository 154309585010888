<script>
// https://github.com/dwightjack/vue-types#documentation
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

import ApiCoupon from '@/api/coupon'

const ORDER_STATUS = {
  init: 'order.status.init',
  unpaid: 'order.status.unpaid',
  undelivered: 'order.status.undelivered',
  delivering: 'order.status.delivering',
  returning: 'order.status.returning',
  canceled: 'order.status.canceled',
  completed: 'order.status.completed'
}

const normalizeCouponHistory = data => {
  if (!data) return []

  return data.map(item => {
    return {
      id: item.slug,
      orderId: item.order_id,
      date: item.created_at.date,
      status: ORDER_STATUS[item.status] || item.status,
      amount: item.amount,
      discount: item.coupon_amount
    }
  })
}

export default {
  name: 'DialogCouponHistory',
  props: {
    coupon: VueTypes.oneOfType([Object, null])
  },
  data () {
    return {
      usedList: []
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol']),
    normalizeCouponHistory () {
      return normalizeCouponHistory(this.usedList)
    }
  },
  mounted () {
    this.fetchHistory(this.coupon.id)
  },
  methods: {
    async fetchHistory (couponId) {
      const apiCoupon = new ApiCoupon()
      this.usedList = await apiCoupon.getCouponHistory(couponId)
    },
    handleClose () {
      this.$emit('update:dialogVisible', false)
    },
    toOrderPage (id) {
      const storeName = this.$route.params.store
      this.$router.push({ path: `/${storeName}/order/${id}` })
    }
  }
}
</script>

<template lang="pug" src="./DialogCouponHistory.pug"></template>
<style lang="scss" src="./DialogCouponHistory.scss" scoped></style>
