import { toFullKanaCase, toHalfKanaCase, toHalfCase, toFullCase } from '@/utils/jpTrans'

const cloneDataMap = {
  'rep_address': 'corp_address',
  'rep_address_kana': 'corp_address_kana',
  'rep_tel': 'corp_tel',
  'contact_tel': 'rep_tel',
  'contact_email': 'corp_email',
  'contact_name': 'rep_name',
  'contact_name_kana': 'rep_name_kana',
  'commercial_address': 'corp_address',
  'commercial_phone': 'corp_tel',
  'send_tel': 'corp_tel',
  'send_email': 'corp_email',
  'send_address': 'corp_address'
}

export default {
  methods: {
    checkNameData (field) {
      const cloneLastName = cloneDataMap[field] + '_last'
      const cloneFirstName = cloneDataMap[field] + '_first'
      const lastName = field + '_last'
      const firstName = field + '_first'
      const isBind = _.isEqual(this.form[lastName], this.form[cloneLastName]) &&
                     _.isEqual(this.form[firstName], this.form[cloneFirstName])
      return isBind
    },
    checkAddressData (address) {
      const cloneAddressFrom = cloneDataMap[address]
      const cloneBuildingFrom = cloneDataMap[address] + '_building'
      const building = address + '_building'
      const isBind = _.isEqual(this.form[address], this.form[cloneAddressFrom]) &&
                     _.isEqual(this.form[building], this.form[cloneBuildingFrom])
      return isBind
    },
    checkData (field) {
      const cloneFieldFrom = cloneDataMap[field]
      const isBind = _.isEqual(this.form[field], this.form[cloneFieldFrom])
      return isBind
    },
    updateAddress (obj, fieldName) {
      this.form[fieldName] = obj
    },
    cloneAddressData (address) {
      const cloneAddressFrom = cloneDataMap[address]
      const cloneBuildingFrom = cloneDataMap[address] + '_building'
      const building = address + '_building'
      this.form[address] = _.cloneDeep(this.form[cloneAddressFrom])
      this.form[building] = _.clone(this.form[cloneBuildingFrom])
    },
    cloneNameData (field) {
      const cloneLastName = cloneDataMap[field] + '_last'
      const cloneFirstName = cloneDataMap[field] + '_first'
      const lastName = field + '_last'
      const firstName = field + '_first'
      this.form[lastName] = _.cloneDeep(this.form[cloneLastName])
      this.form[firstName] = _.cloneDeep(this.form[cloneFirstName])
    },
    cloneData (field) {
      const cloneFieldFrom = cloneDataMap[field]
      this.form[field] = _.cloneDeep(this.form[cloneFieldFrom])
    },
    transToFullCase (target) {
      this.form[target] = toFullCase(this.form[target])
    },
    transToHalfCase (target) {
      this.form[target] = toHalfCase(this.form[target])
    },
    transToHalfKanaCase (target) {
      this.form[target] = toHalfKanaCase(this.form[target])
    },
    transToFullKanaCase (target) {
      this.form[target] = toFullKanaCase(this.form[target])
    },
    transArrayToHalfCase (target, index) {
      this.$set(this.form[target], index, toHalfCase(this.form[target][index]))
    },
    transToCapFullCase (target) {
      this.form[target] = toFullCase(this.form[target].toUpperCase())
    },
    transToCapHalfCase (target) {
      this.form[target] = toHalfCase(this.form[target].toUpperCase())
    }
  }
}
