import Order from './Order'
import OrderComplete from './OrderComplete'
import OrderUpdate from './OrderUpdate'
import Payment from './Payment'
import Shipping from './Shipping'
import ShippingUpdate from './ShippingUpdate'
import Memo from './Memo'

// 更新 OfflineAtm
import OfflineAtm from './OfflineAtm'
// 取得 Store 運送設定
import StoreShipping from './StoreShipping'

export default {
  namespaced: true,
  modules: {
    Order,
    OrderComplete,
    OrderUpdate,
    Payment,
    Shipping,
    ShippingUpdate,
    Memo,

    OfflineAtm,
    StoreShipping
  }
}
