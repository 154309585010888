<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ApproveFooter',
  computed: {
    ...mapGetters('BatchApprove', ['selected', 'isLoading']),
    count () {
      return this.selected.length || 0
    }
  },
  methods: {
    async handleSubmit () {
      this.$emit('submit')
    }
  }
}
</script>

<template lang="pug" src="./ApproveFooter.pug"></template>
<style lang="scss" src="./ApproveFooter.scss" scoped></style>
