<script>
import { mapGetters, mapActions } from 'vuex'
import * as SETTINGS from '@/constants/storeSettings'
import HoverElement from '../HoverElement'

export default {
  name: 'FestivalToggles',
  components: { HoverElement },
  computed: {
    ...mapGetters('LiveSetting', ['newSettings', 'activeFestivals'])
  },
  created () {
    this.getFestivals()
  },
  methods: {
    ...mapActions('LiveSetting', ['updateNewSetting', 'getFestivals']),
    hasFestival (festival) {
      return this.newSettings[SETTINGS.FESTIVAL_ANIMATION] &&
        this.newSettings[SETTINGS.FESTIVAL_ANIMATION].includes(festival)
    },
    updateFestival (festival, value) {
      const oldFestivals = this.newSettings[SETTINGS.FESTIVAL_ANIMATION] || []
      const newFestivals = value
        ? oldFestivals.concat(festival)
        : oldFestivals.filter(old => old !== festival)

      // 沒節慶要null RRRRR
      this.updateNewSetting({
        setting: SETTINGS.FESTIVAL_ANIMATION,
        value: newFestivals.length ? newFestivals : null
      })
    }
  }
}
</script>

<template lang="pug" src="./FestivalToggles.pug"></template>
