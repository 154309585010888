export const REPLY_TYPE = {
  DIRECT: 'direct',
  AWAIT: 'await'
}

export const REPLY_BUTTON_TYPE = {
  checkout: 'checkout',
  goMarket: 'shop_around'
}

export const REPLY_BUTTON_TEMPLATE_TYPE = {
  checkout: 'checkout',
  checkoutAndGoMarket: 'checkoutAndGoMarket'
}
