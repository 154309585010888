<script>

export default {
  name: 'BindFb',
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    goBind () {
      this.$router.push({ name: 'User' })
      this.handleCloseDialog()
    }
  }
}
</script>

<template lang="pug" src="./BindFb.pug"></template>
<style lang="scss" src="./BindFb.scss" scoped></style>
