<script>

import dateDifferenceInSeconds from 'date-fns/difference_in_seconds'

import ApiAuction from '@/api/auction'
import ApiTime from '@/api/time'
import BadgeImage from '@/components/BadgeImage'

import { setInterval } from 'timers'

export default {
  name: 'ShowActivityProcess',

  components: { BadgeImage },

  props: {
    post: {
      type: Object,
      required: true
    },
    searchInput: {
      type: String,
      default: ''
    },
    analysedComments: {
      type: Array,
      default: () => []
    },
    activeSteps: {
      type: String,
      default: ''
    },
    currentActivity: {
      type: Object,
      default: null
    },
    preSelectedComments: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedComments: [],
      nowTime: null,
      timeNum: null,
      skuPrice: 0,
      newPrice: 0,
      priceDebounce: () => { },
      isBtnLoading: false
    }
  },

  computed: {
    calSearchInput: {
      get () {
        return this.searchInput
      },
      set (value) {
        this.$emit('update:searchInput', value)
      }
    },
    keyword () {
      if (!this.currentActivity) return ''
      return this.currentActivity.keyword
    },
    filteredComments () {
      if (!this.searchInput) {
        return this.analysedComments
      } else {
        return this.analysedComments
          .filter(comment => {
            return comment.message && comment.message.includes(this.searchInput)
          })
      }
    },
    elapsedTime () {
      let hours = 0
      let minutes = 0
      let seconds = 0

      if (this.currentActivity && this.nowTime) {
        const startedAt = this.currentActivity.started_at || this.currentActivity.created_at
        const diffTime = dateDifferenceInSeconds(this.nowTime, startedAt)

        hours = Math.floor(diffTime / (60 * 60)) || 0
        minutes = Math.floor((diffTime - (60 * 60 * hours)) / 60) || 0
        seconds = diffTime % 60 || 0
      }

      return [hours, minutes, seconds].reduce((acc, current, index) => {
        if (index === 0 && current === 0) return acc
        return [...acc, (current + '').padStart(2, '0')]
      }, []).join(':')
    }
  },

  mounted () {
    // 更新 選擇清單
    this.selectedComments = _.cloneDeep(this.preSelectedComments)
    this.priceDebounce = _.debounce(this.changePrice, 300)
    this.skuPrice = this.currentActivity.skus[0].price

    this.handleElapsedTime()
  },

  beforeDestroy () {
    // 更新 選擇清單
    this.$emit('update:preSelectedComments', this.selectedComments)

    clearInterval(this.timeNum)
  },

  methods: {
    // 活動時間
    async handleElapsedTime () {
      const apiTime = new ApiTime()
      const { 'server_time': serverTimeStr } = await apiTime.get()

      const diffSeconds = dateDifferenceInSeconds(new Date(), serverTimeStr)

      this.timeNum = setInterval(() => {
        this.nowTime = new Date()
        this.nowTime.setSeconds(this.nowTime.getSeconds() - diffSeconds)
      }, 1000)
    },
    toBottom () {
      this.$nextTick(() => {
        if (this.$refs.commentsList && this.$refs.commentsList.scrollHeight) {
          this.$refs.commentsList.scrollTop = this.$refs.commentsList.scrollHeight
        }
      })
    },

    // 選取項目
    toggleSelects (isAdd = true) {
      this.analysedComments.forEach(comment => {
        const idx = this.selectedComments.findIndex(item => comment.comment_id === item.comment_id)

        if (isAdd && idx === -1) {
          this.toggleSelect(comment)
        } else if (!isAdd && idx !== -1) {
          this.toggleSelect(comment)
        }
      })
    },
    toggleSelect (item) {
      if (!item.message || item.message.includes('----')) return

      const idx = this.selectedComments.findIndex(comment => comment.comment_id === item.comment_id)

      if (idx !== -1) {
        this.selectedComments.splice(idx, 1)
        return
      }

      // 判斷是否有同 User 已勾選
      const isSelected = this.selectedComments.some(comment => comment.from.id === item.from.id)
      if (isSelected) return

      if (this.selectedComments.length < this.currentActivity.skus[0].available_quantity) {
        this.selectedComments.push(item)
      } else {
        this.$message({
          showClose: true,
          duration: 1000,
          message: this.$t('Post.Show.ShowActivityProcess.message.available_quantity'),
          type: 'error'
        })
      }
    },
    isActive (item) {
      return this.selectedComments.some(comment => comment.comment_id === item.comment_id)
    },

    async handleCloseAuction () {
      this.isBtnLoading = true

      const apiAuction = new ApiAuction()
      const res = await apiAuction.close(this.currentActivity.id)

      if (res) {
        this.$emit('handleUpdateAuction')
        this.$emit('update:activeSteps', 'end')
      }

      this.isBtnLoading = false
    },

    handleUpdateSelect () {
      this.$emit('update:activeSteps', 'end')
    },

    async changePrice () {
      const sku = this.currentActivity.skus[0]
      const updateData = {
        id: sku.id,
        price: this.skuPrice,
        quantity: sku.available_quantity
      }

      const apiAuction = new ApiAuction()
      const res = await apiAuction.update(this.post.id, updateData)

      if (res) {
        this.$message.success(this.$t('Post.Show.ShowActivityProcess.save_success'))
        this.$emit('handleUpdateAuction')
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
