export default [
  {
    id: 'name',
    name: 'Ticket.TicketTable.column.name',
    width: 300
  },
  {
    id: 'price',
    name: 'product.price',
    width: 100
  },
  {
    id: 'sold',
    name: 'product.sold',
    width: 100
  },
  {
    id: 'salable',
    name: 'product.salable',
    width: 100
  },
  {
    id: 'available',
    name: 'product.stock',
    width: 100
  },
  {
    id: 'saleTime',
    name: 'Components.ProductFormOther.limited_sales_time',
    width: 160
  }
]
