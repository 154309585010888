<script>
import { mapActions, mapState } from 'vuex'
import { ORDERER_TYPE } from '@/constants/orderManual'
import * as PAYMENT_TYPES from '@/constants/paymentTypes'
import { getPaymentLabel } from '@/utils/getPaymentLabel'

export default {
  name: 'Payment',
  computed: {
    ...mapState('OrderManual/Orderer', ['ordererType']),
    ...mapState('OrderManual/Payment', ['sortedPaymentSettings', 'selectedPayment']),
    noOfflineAtm () {
      const isBankPaymetExist = this.sortedPaymentSettings.some(ele => ele.type === PAYMENT_TYPES.OFFLINE_ATM)
      return this.ordererType === ORDERER_TYPE.Guest && this.sortedPaymentSettings.length && !isBankPaymetExist
    },
    payment: {
      get () {
        return this.selectedPayment
      },
      set (value) {
        this.updatePaymentMethod(value)
      }
    },
    paymentMethods () {
      const methods = this.sortedPaymentSettings.map(setting => ({
        value: setting.type,
        label: getPaymentLabel(setting.type)
      }))
      // 自訂訂購人只允許銀行轉帳
      return this.ordererType === ORDERER_TYPE.Guest
        ? methods.filter(ele => ele.value === PAYMENT_TYPES.OFFLINE_ATM)
        : methods
    }
  },
  mounted () {
    this.getAvailablePaymentMethods()
    this.unsubscribeOrdererType = this.$store.watch((state) => {
      return state.OrderManual.Orderer.ordererType
    },
    (newValue) => {
      if (newValue === ORDERER_TYPE.Guest) {
        this.updatePaymentMethod(PAYMENT_TYPES.OFFLINE_ATM)
      } else {
        const defaultPayment = this.paymentMethods[0] && this.paymentMethods[0].value
        this.updatePaymentMethod(defaultPayment)
      }
    })
  },
  destroyed () {
    this.unsubscribeOrdererType()
  },
  methods: {
    ...mapActions('OrderManual/Payment', ['getAvailablePaymentMethods', 'updatePaymentMethod'])
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
