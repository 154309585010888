<script>
import VueTypes from 'vue-types'

import * as ORDER_TYPES from '@/constants/orderTypes'
import * as PAYMENT_STATUS from '@/constants/paymentStatus'

export default {
  name: 'PricingSheet',
  props: {
    region: VueTypes.string,
    currencySymbol: VueTypes.string,
    orderData: VueTypes.object.def({})
  },
  computed: {
    isSubscription () {
      return this.orderData.type === ORDER_TYPES.SUBSCRIPTION
    },

    items () {
      return _.get(this.orderData, 'items', [])
    },
    shipping () {
      return _.get(this.orderData, 'shipping', {})
    },
    payment () {
      return _.get(this.orderData, 'payment', {})
    },
    coupon () {
      return _.get(this.orderData, 'coupon', null)
    },

    pricing () {
      const itemsAmount = Math.floor(
        this.items.reduce((prev, curr) => {
          return prev + curr.price * curr.quantity
        }, 0)
      )

      const amount = _.get(this.orderData, 'amount', itemsAmount)
      const amountPayable = _.get(this.orderData, 'amount_payable', itemsAmount)
      const shippingFee = _.get(this.orderData, 'shipping_fee', 0)
      const paymentFee = _.get(this.orderData, 'payment_fee', 0)
      const totalAmount = _.get(this.orderData, 'total_amount', itemsAmount)

      return {
        amount,
        amountPayable,
        shippingFee,
        paymentFee,
        totalAmount
      }
    },

    showAmountPayable () {
      // 應付金額
      return this.payment.status !== PAYMENT_STATUS.PAID
    },

    getAmountPaid () {
      // 計算 已付金額
      return this.payment.status !== PAYMENT_STATUS.PAID
        ? Number(this.orderData.total_amount) - Number(this.orderData.amount_payable)
        : 0
    }

  }
}
</script>

<template lang="pug" src="./PricingSheet.pug"></template>
