import GetSubscription from './GetSubscription'
import UpdateSubscription from './UpdateSubscription'

export default {
  namespaced: true,
  modules: {
    GetSubscription,
    UpdateSubscription
  }
}
