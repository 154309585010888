import BaseApi from './baseApi'
import storeId from './_storeId'
import axios from '@/plugins/http.js'

export default class Store extends BaseApi {
  uri = `/api/v1/stores`

  // 取得 授權Token
  inviteToken = async () => {
    try {
      const api = `/api/v1/stores/${storeId()}/invite-token`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // 綁定授權
  bind = async postData => {
    try {
      const api = `/api/v1/stores/bind`
      const { data } = await axios.post(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  updateRegistrant = async postData => {
    try {
      const api = `/api/v1/stores/${storeId()}/registrant`
      const { data } = await axios.put(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  putReplyType = async postData => {
    try {
      const api = `/api/v1/stores/${storeId()}/message-types`
      const { data } = await axios.put(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  handlingFee = async () => {
    try {
      const api = `/api/v1/stores/${storeId()}/handling-fee`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // 日本電子商務法則
  getJpCommercial = async () => {
    try {
      const api = `/api/v1/stores/${storeId()}/jp-commercial`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  updateJpCommercial = async postData => {
    try {
      const api = `/api/v1/stores/${storeId()}/jp-commercial`
      const { data } = await axios.put(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  putFbPixel = async payload => {
    try {
      const api = `/api/v1/stores/${storeId()}/facebook-pixel`
      const { data } = await axios.put(api, payload)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }
}
