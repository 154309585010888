import axios from '@/plugins/http.js'

export default class Draw {
   // 取得貼文的抽獎活動
   getDraws = async (postId, params) => {
     const api = `/api/v1/posts/${postId}/draws?all`
     return axios.get(api, params)
   }

   // 建立抽獎活動
   create = async (postId, postData) => {
     const api = `/api/v1/posts/${postId}/draws`
     return axios.post(api, postData)
   }

  // 更新抽獎活動
  update = async (drawId, updateData) => {
    const api = `/api/v1/draws/${drawId}`
    return axios.put(api, updateData)
  }

  // 刪除抽獎活動
  delete = async drawId => {
    const api = `/api/v1/draws/${drawId}`
    return axios.delete(api)
  }

  // 結束抽獎活動時間
  end = async drawId => {
    const api = `/api/v1/draws/${drawId}/end`
    return axios.post(api)
  }

  // 抽出得獎名單
  getWinners = async drawId => {
    const api = `/api/v1/draws/${drawId}/winners`
    return axios.get(api)
  }

  // 發布得獎名單
  publishWinners = async drawId => {
    const api = `/api/v1/draws/${drawId}/winners/publish`
    return axios.post(api)
  }
}
