<script>
import VueTypes from 'vue-types'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'

const PRINT_MODES = {
  ALL: 'all',
  ORDER: 'order',
  GENERAL: 'general'
}

export default {
  name: 'DialogShipmentStore',
  props: {
    dialogVisible: VueTypes.bool.def(false),
    order: VueTypes.object
  },
  data () {
    return {
      ...SHIPPING_TYPES,
      ...PRINT_MODES
    }
  },
  computed: {
    printMode () {
      const type = this.order.shippingType
      const allTypes = [SHIPPING_TYPES.KERRY_TH]
      const orderTypes = [SHIPPING_TYPES.GHN]

      if (allTypes.includes(type)) {
        return PRINT_MODES.ALL
      } else if (orderTypes.includes(type)) {
        return PRINT_MODES.ORDER
      } else {
        return PRINT_MODES.GENERAL
      }
    }
  },
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
