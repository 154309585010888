<script>
import VueTypes from 'vue-types'
import ApiOrder from '@/api/post'

const MARQUEE_TYPE = {
  SHOW: 'show',
  HIDE: 'hide'
}

export default {
  name: 'DialogMarquee',
  props: {
    postId: VueTypes.string.isRequired
  },
  data () {
    return {
      text: ''
    }
  },
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    async submit () {
      const apiOrder = new ApiOrder()
      const payload = {
        text: this.text || MARQUEE_TYPE.HIDE,
        type: this.text ? MARQUEE_TYPE.SHOW : MARQUEE_TYPE.HIDE
      }

      try {
        await apiOrder.setMarquee(this.postId, payload)
        this.$message({
          showClose: true,
          duration: 1000,
          message: this.$t('Post.DialogMarquee.success_marquee'),
          type: 'success'
        })
      } catch (err) {
        console.error(err)
      }

      this.handleCloseDialog()
    }
  }
}
</script>

<template lang="pug" src="./DialogMarquee.pug"></template>
<style lang="scss" src="./DialogMarquee.scss" scoped></style>
