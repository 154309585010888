<script>
import VueTypes from 'vue-types'

import { passwordRule } from '@/utils/formRules'

import { changePassword } from '@/views/User/service/apis'

export default {
  name: 'DialogEditPassword',

  props: {
    hasPassword: VueTypes.bool.def(false)
  },

  data () {
    return {
      isLoading: false,
      ruleForm: {
        oldPass: '',
        pass: '',
        checkPass: ''
      },
      rules: {
        oldPass: [
          {
            required: this.hasPassword,
            message: this.$t('Setting.Payment.DialogLogistic.validate_password'),
            trigger: 'blur'
          }
        ],
        pass: passwordRule(),
        checkPass: [
          { required: true, message: this.$t('field.password2.diff_error') },
          { validator: this.passwordSameValidator, message: this.$t('field.password2.diff_error') }
        ]
      }
    }
  },
  methods: {
    passwordSameValidator (rule, value, cb) {
      value === this.ruleForm.pass ? cb() : cb(new Error())
    },

    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },

    calcPostData () {
      let result = {
        new_password: this.ruleForm.pass
      }

      if (this.hasPassword) {
        result = Object.assign({}, result, {
          old_password: this.ruleForm.oldPass
        })
      }

      return result
    },

    async handleSubmit () {
      try {
        this.isLoading = true

        // 驗證
        await this.$refs.ruleForm.validate()

        // 更新 密碼
        const postData = this.calcPostData()
        await changePassword(postData)

        this.$emit('refreshMe')
        this.$message.success(this.$t('Post.Show.ShowActivityProcess.save_success'))
        this.handleCloseDialog()
      } catch (err) {
        this.isLoading = false
        console.error(err)
      }
    }
  }
}
</script>

<template lang="pug" src="./DialogEditPassword.pug"></template>
