import BaseApi from './baseApi'
import storeId from './_storeId'
import axios from '@/plugins/http.js'

export default class Product extends BaseApi {
  uri = `/api/v1/products`
  uriList = `/api/v1/stores/${storeId()}/products`
  uriCreate = `/api/v1/stores/${storeId()}/products`
  uriUpdate = `/api/v1/stores/${storeId()}/products`

  // 取得商品List
  getProductList = (params) => {
    return axios.get(this.uriList, { params })
  }

  // 新建資料
  create = async createData => {
    try {
      const { data } = await axios.post(this.uriCreate || this.uri, createData)

      return data
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 更新資料
  update = async (id, updateData) => {
    try {
      const { data } = await axios.put(
        `${this.uriUpdate || this.uri}/${id}`,
        updateData
      )

      return data
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 刪除貼文商品
  deletePostProduct = async (postId, productId) => {
    try {
      const api = `/api/v1/posts/${postId}/products/${productId}`
      await axios.delete(api)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 單項刪除商店商品
  deleteStoreProduct = (productId) => {
    const api = `/api/v1/stores/${storeId()}/products/${productId}`
    return axios.delete(api)
  }

  // 批次刪除商店商品
  batchDeleteProduct = (productIds) => {
    const api = `/api/v1/stores/${storeId()}/products`
    return axios.delete(api, productIds)
  }

  // 更新 products 至 store 的賣場
  updateMarket = async updateData => {
    try {
      await axios.put(`/api/v1/stores/${storeId()}/market-products`, updateData)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 取得新增商品預設的關鍵字
  getDefaultKeyword = (storeName) => {
    return axios.get(`/api/v1/stores/${storeName}/products/default-keyword`)
  }

  // 確認新增商品預設的關鍵字是否重覆
  checkUniqueKeyword = async (defaultKeyword) => {
    try {
      const api = `/api/v1/stores/${storeId()}/products/verification?default_keyword=${defaultKeyword}`
      await axios.get(api)

      return true
    } catch (e) {
      console.error(e)

      return false
    }
  }
}
