<script>
import IMG404 from '@/assets/img-404.png'

export default {
  name: 'PlanExpired',

  data () {
    return {
      IMG404
    }
  },

  methods: {
    handleBack () {
      location.href = process.env.VUE_APP_BUYER_DOMAIN_NAME
    }
  }
}
</script>

<template lang="pug" src="./PlanExpired.pug"></template>
<style lang="scss" src="./PlanExpired.scss" scoped></style>
