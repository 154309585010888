<script>
export default {
  name: 'FamilyBarcode',
  components: {},
  props: {
    data: {
      type: Object,
      required: true
    },
    receiverName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      barcode1: null,
      barcode2: null
    }
  },
  computed: {
    routeClass () {
      // 偶數黑底，奇數白底
      if (parseInt(this.data.route_barcode[3]) % 2 === 0) {
        return ['bg-black', 'text-white']
      } else {
        return ['bg-white', 'text-black']
      }
    }
  },
  created () {
    if (!this.data) return
    const parser = new DOMParser()
    const barcode1 = parser.parseFromString(this.data.store_barcode[0], 'text/xml')
    this.barcode1 = this.chunkString(barcode1.getElementsByTagName('desc')[0].textContent.replace(/\*/g, ''), 3)
    const barcode2 = parser.parseFromString(this.data.store_barcode[1], 'text/xml')
    this.barcode2 = this.chunkString(barcode2.getElementsByTagName('desc')[0].textContent.replace(/\*/g, ''), 4)
  },
  methods: {
    // https://jsperf.com/chunk-string
    chunkString (str, size) {
      const chunks = new Array(str.length / size + 0.5 | 0)
      const nChunks = chunks.length

      let newo = 0
      for (var i = 0, o = 0; i < nChunks; ++i, o = newo) {
        newo += size
        chunks[i] = str.substr(o, size)
      }

      return chunks.join(' ')
    }
  }

}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
