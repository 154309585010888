
const getters = {
  activities: (_, getters) => {
    const draws = getters.activityDraws.map(item => {
      return { ...item, type: 'draw' }
    })

    const auctions = getters.activityAuctions.map(item => {
      return { ...item, type: 'auction' }
    })

    const biddings = getters.activityBiddings.map(item => {
      return { ...item, type: 'bidding' }
    })

    return [...draws, ...auctions, ...biddings]
  }
}

const actions = {
  async fetchActivities ({ dispatch }, postId) {
    await Promise.all([
      dispatch('fetchActivityDraws', postId),
      dispatch('fetchActivityAuctions', postId),
      dispatch('fetchActivityBiddings', postId)
    ])
  }
}

export default {
  getters,
  actions
}
