<template>
  <el-dialog
    :title="$t('ReplayEdit.button.clip_video_cover_title')"
    width="50%"
    visible
    @close="handleClose"
  >
    <div class="p-4">
      <el-radio
        v-model="coverType"
        :label="SET_COVER_FROM.CAPTURE_VIDEO_FRAME"
      >
        {{ $t('ReplayEdit.radio.option.choose_frame') }}
      </el-radio>
      <el-radio
        v-model="coverType"
        :label="SET_COVER_FROM.UPLOAD_IMAGE"
      >
        {{ $t('ReplayEdit.radio.option.update_image') }}
      </el-radio>
      <div
        v-show="coverType === SET_COVER_FROM.UPLOAD_IMAGE"
        class="flex"
      >
        <el-upload
          class="image-upload-container relative"
          name="image"
          :action="imageUploadPath"
          :headers="imageUploadHeader"
          :show-file-list="false"
          :on-success="handleImageUpdateSuccess"
          :before-upload="beforeImageUpload"
          :on-progress="handleImageProgress"
        >
          <template v-if="imageUrl">
            <img :src="imageUrl">
            <div class="icon">
              <i
                class="el-icon-delete"
                @click.stop="deleteImage"
              />
            </div>
          </template>
          <i
            v-else-if="isLoading"
            class="el-icon-loading"
          />
          <i
            v-else
            class="el-icon-plus avatar-uploader-icon"
          />
        </el-upload>
        <ul>
          <li>{{ $t('ReplayEdit.text.cover_size') }}</li>
          <li>{{ $t('ReplayEdit.text.image_type') }}</li>
          <li>{{ $t('ReplayEdit.text.image_size') }}</li>
        </ul>
      </div>
      <video-player
        v-show="coverType === SET_COVER_FROM.CAPTURE_VIDEO_FRAME"
        ref="coverVideo"
        :video-url="videoUrl"
        :preview-start-time="clipVideo.start"
        :preview-end-time="clipVideo.end"
        :default-current-time="clipVideo.screenshot"
        show-preview-range
      />
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">{{ $t('ReplayEdit.button.cancel') }}</el-button>
      <el-button
        type="primary"
        :disabled="isLoading"
        @click="handleSave"
      >{{ $t('ReplayEdit.button.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import VueTypes from 'vue-types'
import { SET_COVER_FROM } from '@/constants/replayEdit'
import VideoPlayer from '@/components/VideoPlayer'
import { getSnapshot } from '@/utils/getSnapshot'

export default {
  name: 'DialogCoverSetting',
  components: { VideoPlayer },
  props: {
    imageUploadPath: VueTypes.string.def(''),
    imageUploadHeader: VueTypes.object.def(null),
    videoUrl: VueTypes.string.def(''),
    clipVideo: VueTypes.object.def({}),
    uploadImageToServer: VueTypes.func.def(() => {})
  },
  data () {
    return {
      coverType: SET_COVER_FROM.CAPTURE_VIDEO_FRAME,
      canvasDataUrl: '',
      imageUrl: this.getDefaultImage(),
      imageId: null,
      previewImage: 'background-image',
      SET_COVER_FROM,
      isLoading: false
    }
  },
  methods: {
    async handleSave () {
      let payload = {}
      switch (this.coverType) {
        case SET_COVER_FROM.UPLOAD_IMAGE:
          payload = {
            imageId: this.imageId,
            imageUrl: this.imageUrl,
            clipId: this.clipVideo.id,
            screenshot: null
          }
          break
        case SET_COVER_FROM.CAPTURE_VIDEO_FRAME:
          const image = this.captureVideoFrame()
          const { id, url } = await this.uploadImageToServer(image)
          this.imageUrl = url
          payload = {
            imageId: id,
            imageUrl: url,
            clipId: this.clipVideo.id,
            screenshot: this.$refs.coverVideo.getCurrentTime()
          }
          break
        default:
          break
      }
      this.$emit('updateCoverSetting', payload)
    },
    captureVideoFrame () {
      const video = this.$refs.coverVideo.videoElement
      return getSnapshot(video)
    },
    handleImageUpdateSuccess (res, file) {
      this.imageUrl = res.url
      this.imageId = res.id
    },
    beforeImageUpload (file) {
      const isValidPic = /image\/(png|jpeg)/.test(file.type)
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isValidPic) {
        this.$message.error(this.$t('ReplayEdit.error.image_file_type'))
        return false
      }
      if (!isLt2M) {
        this.$message.error(this.$t('ReplayEdit.error.exceed_file_size'))
        return false
      }
      return isValidPic && isLt2M
    },
    handleClose () {
      this.$emit('closeCoverDialog')
    },
    deleteImage () {
      this.imageUrl = ''
      this.imageId = null
    },
    getDefaultImage () {
      // 有影格時間時, 不顯示上傳的封面圖
      if (this.clipVideo.screenshot >= 0 && this.clipVideo.screenshot !== null) return ''
      return this.clipVideo.id ? this.clipVideo.image.url : this.clipVideo.imageUrl
    },
    handleImageProgress (event) {
      this.isLoading = event.percent < 100
    }
  }
}
</script>
<style lang="scss" scoped>
.image-upload-container {
  width: 192px;
  height: 340px;
  border: 1px dashed $primary-dark;
  text-align: center;
  line-height: 340px;
  font-size: 2rem;
  margin-right: 20px;
}
.icon {
  background: rgba(0,0,0,0.8);
  border-radius: 50%;
  height: 1.5rem;
  width:1.5rem;
  text-align: center;
  line-height: 1.5rem;
  position: absolute;
  font-size: 1rem;
  right: 0.5rem;
  bottom:  0.5rem;
  color: white
}
</style>
