import axios from '@/plugins/http'
import { downloadFile } from '@/utils/downloadFile'

export function getReceipts (storeId, page) {
  const api = `/api/v1/stores/${storeId}/seller-payments?status=paid&page=${page}`
  return axios.get(api)
}

export function getLastPayment (storeId) {
  const api = `/api/v1/stores/${storeId}/seller-payments?current_order_only=true&direction=asc&status=init`
  return axios.get(api)
}

export async function downloadReceipt (storeId, receiptId) {
  const api = `/api/v1/stores/${storeId}/seller-payments/${receiptId}/download-receipt`
  const response = await axios.get(api, { responseType: 'blob' })
  downloadFile(response, 'receipt.pdf')
}
