<script>
import VueTypes from 'vue-types'
import { FAILED } from '@/constants/gmoStatus'
import gmoProcess from '../../images/img-gmo-process@2x.png'

export default {
  name: 'HeaderNote',
  props: {
    gmoStatus: VueTypes.string.def('')
  },
  data () {
    return {
      FAILED,
      gmoProcess
    }
  }
}
</script>

<template lang="pug" src="./HeaderNote.pug"></template>
<style lang="scss" src="./HeaderNote.scss" scoped></style>
