<script>
import T from 'vue-types'

import { mapActions } from 'vuex'

import * as PROVIDER from '@/constants/provider'
import * as channelTypes from '@/constants/channelType'
import BadgeImage from '@/components/BadgeImage'
import Tag from '@/components/Tag'

import * as analyzeStatus from '../../constants/analyzeStatus'
import * as failedReasonCode from '../../constants/failedReasonCode'

export default {
  components: {
    BadgeImage,
    Tag
  },

  props: {
    comment: T.object,
    celebrityToken: T.string,
    blacklist: T.array
  },

  data () {
    return {
      TAG_TYPE: Tag.TYPES,

      ANALYZE_STATUS: analyzeStatus,

      FAILED_REASON_CODE: failedReasonCode,

      CHANNEL_TYPES: channelTypes,

      FAILED_REASON_TEXT: {
        [failedReasonCode.SOLD_OUT]: 'Post.Show.Published.sold_out',
        [failedReasonCode.PARSED_FAILED]: 'Post.Show.Published.analysis_fail',
        [failedReasonCode.BLACKLIST]: 'Post.Show.Published.blacklist',
        [failedReasonCode.BUY_SELF]: 'Post.Show.Published.buy_self',
        [failedReasonCode.NOT_ON_SALE]: 'Post.Show.Published.not_on_sale'
      },

      hideMessageLoading: false
    }
  },

  methods: {
    ...mapActions('ChatMessage/Messages', ['updateHideMessage', 'updateShowMessage']),
    checkIfBlacklist (comment) {
      /**
       * handsup comment 比對 user_id
       * FB comment 比對 page_id & ps_id (comment from id)
       */
      if (this.type === 'handsup') {
        return _.includes(_.map(this.blacklist, 'user_id'), _.get(comment, 'from.id'))
      } else {
        return _.includes(_.map(this.blacklist, 'page_id'), comment.page_id) &&
          _.includes(_.map(this.blacklist, 'ps_id'), _.get(comment, 'from.id'))
      }
    },

    // @todo should normalize image url in chatmessage action
    getImgUrl (comment) {
      const {
        from: {
          asid,
          id,
          provider
        } = {}
      } = comment

      // provider 是 2020/09/16 後新加，為了不影響之前的貼文，
      // 若 provider 無值時，則不擋取 FB 頭像
      // 若 provider 有值時，則判斷是否 Facebook 身份留言，不是的話，傳回 null 值
      if (provider && provider !== PROVIDER.FACEBOOK) return null

      const assetID = asid || id
      const composeImgUrl = ID => `https://graph.facebook.com/${ID}/picture`

      return composeImgUrl(assetID)
    },

    async toggleHideMessage () {
      if (this.hideMessageLoading) return

      this.hideMessageLoading = true
      if (this.comment.hidden) {
        await this.updateShowMessage(this.comment)
      } else {
        await this.updateHideMessage(this.comment)
      }
      this.hideMessageLoading = false
    }
  }
}
</script>

<template lang="pug" src="./MessageBox.pug"></template>

<style lang="scss" src="./MessageBox.scss" scoped></style>
