<script>
import { mapGetters, mapActions } from 'vuex'

import bgLuckyDrawList from '@/assets/post/bg-lucky-draw-list.png'
import bgLuckyDrawListNone from '@/assets/post/bg-lucky-draw-list-none.png'
import emptyMsg from '@/assets/empty-msg.png'

export default {
  name: 'DrawResultDialog',

  data () {
    return {
      emptyMsg,

      isLoading: false,
      defaultAvator: `this.src='${require('@/assets/icon-default-user-md.png')}'`
    }
  },

  computed: {
    ...mapGetters('Post', ['postId']),
    ...mapGetters('Post', ['selectDraw', 'selectDrawWinners']),

    getBackgroundImage () {
      return this.selectDrawWinners.length
        ? { backgroundImage: 'url(' + bgLuckyDrawList + ')', backgroundSize: 'contain' }
        : { backgroundImage: 'url(' + bgLuckyDrawListNone + ')', backgroundSize: 'contain' }
    },

    isPublished () {
      return this.selectDraw.published
    },

    normalizeComments () {
      return this.selectDrawWinners.map(item => {
        const commentId = item.comment_id
        const fromName = item.comment.from_name
        const message = item.comment.message
        const originalTime = item.comment.original_time
        const quantity = item.quantity

        return { commentId, fromName, message, originalTime, quantity }
      })
    },
    total () {
      return this.selectDrawWinners.reduce((prev, current) => {
        return prev + current.quantity
      }, 0)
    }
  },

  methods: {
    ...mapActions('Post', ['fetchActivityDraws', 'publishDraw', 'clearSelectDraw']),

    async handlePublishDraw () {
      this.isLoading = true
      await this.publishDraw({ postId: this.postId, drawId: this.selectDraw.id })
      await this.fetchActivityDraws(this.postId)
      this.clearSelectDraw()
    },

    close () {
      this.clearSelectDraw()
    }
  }
}
</script>

<template lang="pug" src="./DrawResultDialog.pug"></template>
<style lang="scss" src="./DrawResultDialog.scss" scoped></style>
