<script>
import { mapActions, mapGetters } from 'vuex'
import VisaIcon from '@/assets/payment/card-visa.png'
import MasterIcon from '@/assets/payment/card-master.png'
import JcbIcon from '@/assets/payment/card-jcb.png'
import AmexIcon from '@/assets/payment/card-amex.png'
import DinersIcon from '@/assets/payment/card-diners.png'
import splitString from '@/utils/splitString'
import loadJs from '@/utils/jsLoader'
import { monthRegex, shortYearRegex, cvcRegex, luhnChk } from '@/utils/formRules'
import { GMO_CREDIT } from '@/constants/paymentTypes'
import { SELLER_SUBSCRIPTION } from '@/constants/creditCardApplication'
import { postCreditCard, putCreditCard } from '@/api/creditCard'

const CARD_IMAGE = {
  VISA: VisaIcon,
  MASTER: MasterIcon,
  JCB: JcbIcon,
  AMEX: AmexIcon,
  DINERS: DinersIcon
}

const GmoHelper = {
  GMO_SUCCESS_CODE: '000',

  async init () {
    await loadJs(process.env.VUE_APP_GMO_PAYMENT_SDK)
    const shopId = process.env.VUE_APP_GMO_SHOP_ID
    Multipayment.init(shopId)
  },

  getToken (cardObject) {
    return new Promise((resolve, reject) => {
      Multipayment.getToken(cardObject, response => {
        if (response.resultCode.toString() !== this.GMO_SUCCESS_CODE) {
          reject(new Error(response.resultCode))
        } else {
          resolve(response.tokenObject)
        }
      })
    })
  }
}

export default {
  name: 'GmoCreditDialog',
  props: {
    currentCard: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      CARD_IMAGE,
      isLoading: false,
      cardForm: {
        card_number: '',
        card_expire_month: '',
        card_expire_year: '',
        card_cvc: ''
      },
      rules: {
        card_number: [
          {
            required: true,
            transform: v => v.replace(/\D/g, ''),
            trigger: 'blur',
            message: this.$t('form.error.required', { msg: this.$t('Shopping.PaymentCreditCard.card_number') }),
            validator: (rules, value, callback) => {
              luhnChk(value) ? callback() : callback(new Error())
            }
          }
        ],
        card_expire_month: [
          {
            required: true,
            pattern: monthRegex,
            trigger: 'blur',
            message: this.$t('form.error.required', { msg: this.$t('Shopping.PaymentCreditCard.expiration_date') })
          }
        ],
        card_expire_year: [
          {
            required: true,
            pattern: shortYearRegex,
            trigger: 'blur',
            message: this.$t('form.error.required', { msg: this.$t('Shopping.PaymentCreditCard.expiration_date') })
          }
        ],
        card_cvc: [
          {
            required: true,
            pattern: cvcRegex,
            trigger: 'blur',
            message: this.$t('form.error.required', { msg: this.$t('Shopping.PaymentCreditCard.cvc') })
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName']),
    cardObject () {
      return {
        cardno: this.cardForm.card_number.replace(/\D/g, ''),
        expire: this.cardForm.card_expire_year + this.cardForm.card_expire_month,
        securitycode: this.cardForm.card_cvc
      }
    },
    submitText () {
      return this.currentCard.id ? this.$t('button.save') : this.$t('Order.DialogPaymentDetail.payment')
    }
  },
  async created () {
    try {
      await GmoHelper.init()
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    displayCardNum () {
      const cardNumber = this.cardForm.card_number.replace(/\D/g, '')
      this.cardForm.card_number = cardNumber ? splitString(cardNumber, 4) : ''
    },
    async validateForm () {
      try {
        return await this.$refs.cardForm.validate()
      } catch {
        return false
      }
    },
    async handleSubmit (orderId) {
      if (!await this.validateForm()) {
        return
      }

      this.isLoading = true
      try {
        if (this.currentCard.id) {
          // 編輯信用卡
          await this.updateCard()
          this.$emit('updateCard')
        } else {
          // 新增信用卡
          await this.saveCard()
          this.$emit('saveCard')
        }
        this.handleCloseDialog()
      } catch (e) {
        if (e.message) {
          this.$message.error('Error: ' + e.message)
        }
        console.error(e)
      }
      this.isLoading = false
    },
    async saveCard () {
      const token = await GmoHelper.getToken(this.cardObject)
      const payload = {
        type: GMO_CREDIT,
        application: SELLER_SUBSCRIPTION,
        ...token
      }
      await postCreditCard(payload)
    },
    async updateCard () {
      const token = await GmoHelper.getToken(this.cardObject)
      const cardId = this.currentCard.id
      const payload = {
        type: GMO_CREDIT,
        application: SELLER_SUBSCRIPTION,
        ...token
      }
      await putCreditCard(cardId, payload)
    }
  }
}
</script>

<template lang="pug" src="./GmoCreditDialog.pug"></template>
<style scoped>
  .el-form {
    min-height: 300px;
  }
</style>
