<script>
import debounce from 'lodash/debounce'
import { mapGetters, mapActions } from 'vuex'

import { jpCheckPlan } from '@/middleware/checkPlan'

import ApiOrder from '@/api/order'
import ApiProduct from '@/api/product'
import ApiPost from '@/api/post'

import DialogImportData from '@/components/DialogImportData'
import DialogImportDataGeneric from './components/DialogImportDataGeneric'
import PaginationPure from '@/components/PaginationPure'

import ProductTable from './components/ProductTable'
import DialogAddProduct from './components/DialogAddProduct'
import DialogConfirmDelete from './components/DialogConfirmDelete'

export default {
  name: 'ProductList',
  components: {
    ProductTable,
    PaginationPure,
    DialogAddProduct,
    DialogImportData,
    DialogImportDataGeneric,
    DialogConfirmDelete
  },
  data () {
    return {
      dialogAddProductVisible: false,
      dialogImportDataVisible: false,
      dialogImportDataGenericVisible: false,
      apiProduct: new ApiProduct(),
      editProduct: null,
      editSku: null,
      expandedRow: [],
      searchDateRange: '',
      showConfirmDelete: false,
      visiblePickProduct: false,
      selectProducts: [],
      livePosts: [],
      selectedLivePost: ''
    }
  },

  computed: {
    ...mapGetters('Me', ['storeName', 'currencySymbol', 'keywordTip', 'isEnterpriseGeneric', 'showInstructionLink', 'apiUrl']),
    ...mapGetters('ProductList', ['products', 'meta', 'currentPage', 'search']),

    keyword: {
      get () {
        return this.search.keyword
      },
      set (val) {
        this.updateSearch({ keyword: val })
      }
    },

    importUploadPath () {
      return `${this.apiUrl}/api/v1/stores/${this.storeName}/products/import`
    },

    importEUploadPath () {
      return `${this.apiUrl}/api/v1/g/enterprise/stores/${this.storeName}/products/import`
    }
  },

  watch: {
    searchDateRange (val) {
      const from = val ? val[0] : ''
      const to = val ? val[1] : ''

      this.updateSearch({ from, to })
    }
  },

  created () {
    // 取得商品資料
    this.fetchProducts()

    // 增加Debounce
    this.searchDebounce = debounce(this.handleSelectSearch, 700)

    this.getLivePosts()
  },

  mounted () {
    // 判斷是否第一次註冊進來
    if (this.$route.query.isCreateProduct) {
      this.dialogAddProductVisible = true
    }
  },

  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('ProductList', [
      'fetchProducts',
      'updateSearch',
      'setCurrentPage',
      'updateRows',
      'batchDelete'
    ]),
    ...mapActions('ProductDetail', ['setEditProduct']),

    tableRowClassName ({ row, rowIndex }) {
      return (row.skus && row.skus.length < 2) ? 'single' : ''
    },
    handleSortChange (name) {
      // TODO：這邊待整理邏輯

      if (this.search.sort === name) {
        // 第二次點擊 DESC
        if (this.search.desc === null) {
          this.updateSearch({ desc: '1' })

        // 第三次點擊 還原
        } else {
          this.updateSearch({ sort: null, desc: null })
        }
      } else {
        // 第一次點擊 ASC
        this.updateSearch({ sort: name, desc: null })
      }

      this.handleSelectSearch()
    },
    async handleSelectSearch () {
      this.setLoading(true)
      this.setCurrentPage(1)
      await this.fetchProducts() // 取得商品資料
      this.setLoading(false)
    },
    downloadPurchasingOrder () {
      // [Facebook Pixel] 追蹤商品點擊Tab事件
      this.$analytics.fbq.event('ClickReg', {
        Page: 'Merchant-Platform',
        Content: 'ProductManager',
        Target: 'PurchasingOrder-Download'
      })
      this.$ga.event({
        eventCategory: 'Bottom-Click',
        eventAction: 'PurchasingOrder-Download',
        eventLabel: 'Merchant-Platform'
      })
      const apiOrder = new ApiOrder()
      apiOrder.downloadPurchasingOrder()
    },
    toggleRowExpanded (row, event, column) {
      // 點擊 row 時，展開隱藏列，排除最後一列
      if (column.id === 'el-table_1_column_6') return
      if (row.skus.length === 1) return

      this.expandedRow.includes(row.id)
        ? this.expandedRow = this.expandedRow.filter(x => x !== row.id)
        : this.expandedRow.push(row.id)
    },
    handleUpdateProduct (index, data = null) {
      this.setEditProduct(data)
      this.$router.push({ name: 'ProductDetail', params: { store: this.storeName } })
    },
    handleCreateProduct () {
      if (!jpCheckPlan()) return

      // [Facebook Pixel] 追蹤商品點擊Tab事件
      this.$analytics.fbq.event('ClickReg', {
        Page: 'Merchant-Platform',
        Content: 'ProductManager',
        Target: 'Product-Create'
      })
      this.$ga.event({
        eventCategory: 'Bottom-Click',
        eventAction: 'Product-Create',
        eventLabel: 'Merchant-Platform'
      })

      this.$router.push({ name: 'ProductCreate', params: { store: this.storeName } })
    },
    handleImportProduct () {
      if (!jpCheckPlan()) return

      // [Facebook Pixel] 追蹤商品點擊Tab事件
      this.$analytics.fbq.event('ClickReg', {
        Page: 'Merchant-Platform',
        Content: 'ProductManager',
        Target: 'Product-Import'
      })
      this.$ga.event({
        eventCategory: 'Bottom-Click',
        eventAction: 'Product-Import',
        eventLabel: 'Merchant-Platform'
      })

      if (this.isEnterpriseGeneric) {
        this.dialogImportDataGenericVisible = true
      } else {
        this.dialogImportDataVisible = true
      }
    },
    refreshTableData (isEdit = false) {
      if (!isEdit) {
        // 清除搜尋條件
        this.searchDateRange = ''
        this.selectedLivePost = null
        this.updateSearch({
          from: '',
          to: '',
          keyword: '',
          sort: null,
          desc: null,
          postId: null
        })

        this.setCurrentPage(1)
      }

      this.fetchProducts()
    },

    getKeyword (defaultKeyword, skus, index) {
      if (skus.length === 1) {
        return defaultKeyword
      } else {
        return defaultKeyword ? `${defaultKeyword}${skus[index].name}` : ''
      }
    },

    handleClickDelete (product) {
      this.handleToggleSelect(product)
      this.showConfirmDelete = true
    },
    handleToggleSelect (product) {
      const idx = this.selectProducts.findIndex(item => item.id === product.id)

      idx === -1
        ? this.selectProducts.push(_.cloneDeep(product))
        : this.selectProducts.splice(idx, 1)
    },
    handleToggleAll () {
      const isSomeCount = this.products.length === this.selectProducts.length

      if (isSomeCount) {
        // 全取消
        this.selectProducts = []
      } else {
        // 全選
        this.selectProducts = _.cloneDeep(this.products)
      }
    },

    handleUpdateRows (page) {
      this.selectProducts = []
      this.updateRows(page)
    },

    handleUndelete () {
      this.showConfirmDelete = false
      this.visiblePickProduct = false
      this.selectProducts = []
    },
    async handleDeleteSelected () {
      const removeIds = this.selectProducts.map(item => item.id)
      this.batchDelete(removeIds)
        .then(() => {
          this.$message({
            message: this.$t('Product.message.success_delete'),
            type: 'success',
            showClose: true
          })
        })
        .finally(() => {
          this.handleUndelete() // 關閉 Dialog
        })
    },
    async handleLivePostsSelect (value) {
      this.selectedLivePost = value
      this.updateSearch({ postId: value })
      this.setLoading(true)
      this.setCurrentPage(1)
      await this.fetchProducts()
      this.setLoading(false)
    },
    async getLivePosts () {
      try {
        const apiPost = new ApiPost()
        const list = await apiPost.list({ type: 'live', sort: 'created_at,desc' }, true)
        this.livePosts = list.data.map(item => ({
          label: item.title,
          value: item.id
        }))
      } catch (error) {

      }
    }

  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" ></style>
