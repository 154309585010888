<script>
import { mapGetters } from 'vuex'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import AddressSelect from '@/components/AddressSelect'
import KerryThTip from '@/components/KerryThTip'

export default {
  name: 'DialogShippingSetting',
  components: { AddressSelect, KerryThTip },
  props: {
    setting: {
      type: Object
    },
    plan: {
      type: Object,
      default: () => { }
    },
    carrier: {
      type: Object,
      default: null
    }
  },
  data () {
    // const validatePhone = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error(this.$t('Setting.Payment.DialogShippingSetting.validate_sender_phone1')))
    //   } else if (!/^09\d{8}$/.test(value)) {
    //     callback(new Error(this.$t('Setting.Payment.DialogShippingSetting.validate_sender_phone2')))
    //   } else {
    //     callback()
    //   }
    // }

    return {
      ...SHIPPING_TYPES,

      step: 'step1',
      normalSetting: {
        enabled: false,
        fee: 60,
        fee_type: 'fixed',
        address: {},
        sender_name: null,
        sender_phone: null,
        memo: null
      },
      codSetting: {
        enabled: false,
        fee: 60,
        fee_type: 'fixed'
      },
      carrierSetting: {
        type: 'family-business',
        contact_name: null,
        contact_vendor_name: null,
        contact_email: null,
        contact_number: null,
        contact_address: null,
        info: {
          return_cycle: '0',
          return_day: '1',
          return_mode: '1',
          return_mode_other: null
        },
        return_cycle: '0',
        return_day: '1',
        return_mode: '1',
        return_mode_other: null
      },
      feeRules: {
        fee: [
          {
            type: 'integer',
            message: this.$t('Setting.Payment.DialogShippingSetting.validate_fee'),
            trigger: 'change',
            min: 1,
            transform: x => parseInt(x)
          }

        ],
        free_shipping_threshold: [
          {
            type: 'integer',
            message: this.$t('Setting.Payment.DialogShippingSetting.validate_fee'),
            trigger: 'change',
            min: 1,
            transform: x => parseInt(x)
          }
        ]
      },
      senderRules: {
        sender_name: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogShippingSetting.validate_sender_name'),
            trigger: 'change'
          }
        ],
        sender_phone: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogShippingSetting.validate_sender_phone1'),
            trigger: 'change'
          }
        ]
      },
      carrierRules: {
        contact_name: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_contact_name') }
        ],
        contact_vendor_name: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_contact_vendor_name') }
        ],
        contact_email: [
          { required: true, type: 'email', message: this.$t('Setting.Payment.DialogB2C.validate_contact_email') }
        ],
        contact_number: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_contact_number') }
        ],
        contact_address: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_contact_address') }
        ],
        return_cycle: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_return_cycle') }
        ],
        return_day: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_return_day') }
        ],
        return_mode: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_return_mode') }
        ],
        return_mode_other: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_return_mode_other') }
        ]
      },
      memo: null,

      // 只有COD、沒有純取貨 list
      codOnlyTypes: [
        SHIPPING_TYPES.OFFLINE_CUSTOM_TCAT_COD
      ]
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol', 'currentStore', 'shippingRateImg']),
    notSet () {
      return !this.setting.normal
    },
    returnIsSender () {
      return this.carrierSetting.contact_name === this.normalSetting.sender_name &&
        this.carrierSetting.contact_number === this.normalSetting.sender_phone &&
        this.carrierSetting.contact_address === this.normalSetting.address
    },
    // 小資方案不可取貨付款
    planNoCod () {
      return this.plan.name === 'novice'
    },
    hasMemo () {
      const memoTypes = ['custom']
      return memoTypes.includes(this.setting.type)
    },
    normalLabel () {
      const dropoffTypes = ['custom']
      return dropoffTypes.includes(this.setting.type)
        ? this.$t('Setting.Payment.dropoff_only')
        : this.$t('Setting.Payment.pickup_only')
    },
    isStore () {
      const storeTypes = ['family', 'seven']

      return storeTypes.includes(this.normalSetting.type)
    }
  },
  mounted () {
    // Overwrite default when already set, else set last address if present
    this.normalSetting.type = this.setting.type
    if (this.setting.normal) {
      this.normalSetting = { ...this.normalSetting, ...this.setting.normal }
      if (this.normalSetting.info) {
        this.normalSetting.memo = this.normalSetting.info.memo
      }
    } else if (this.setting.lastAddress) {
      this.normalSetting.sender_address = this.setting.lastAddress.address
      this.normalSetting.sender_phone = this.setting.lastAddress.sender_phone
      this.normalSetting.sender_name = this.setting.lastAddress.sender_name
    }
    if (!this.setting.codAble) this.normalSetting.enabled = true

    if (this.setting.type === SHIPPING_TYPES.OFFLINE_CUSTOM_TCAT_COD) {
      // 宅配取貨付款不用加 '_cod' 字尾
      this.codSetting.type = this.setting.type
    } else {
      this.codSetting.type = this.setting.type + '_cod'
    }

    if (this.setting.cod) {
      this.codSetting = { ...this.setting.cod }
    }

    if (this.carrier && this.carrier.info) {
      this.carrierSetting = {
        ...this.carrier,
        return_cycle: this.carrier.info.return_cycle,
        return_day: this.carrier.info.return_day,
        return_mode: this.carrier.info.return_mode,
        return_mode_other: this.carrier.info.return_mode_other
      }
    } else {
      this.carrierSetting = null
    }
  },
  methods: {
    toggle () {
      this.$emit('closeShipping')
    },
    sameAsSender () {
      this.carrierSetting.contact_name = this.normalSetting.sender_name
      this.carrierSetting.contact_number = this.normalSetting.sender_phone
      this.carrierSetting.contact_address = this.normalSetting.address
    },
    async validateFees () {
      let success = true
      // Fee and free threshold has to be greater than 0 where applicable
      if (this.$refs['normalForm']) {
        success = success && await this.$refs['normalForm'].validate()
      }
      if (this.$refs['codForm']) {
        success = success && await this.$refs['codForm'].validate()
      }

      // At least one setting has to be enabled
      success = success && (this.normalSetting.enabled || this.codSetting.enabled)

      return success
    },
    validateAddress () {
      if (this.$refs['addressForm']) {
        return this.$refs['addressForm'].validateForm()
      } else {
        return true
      }
    },
    async validateSender () {
      if (this.$refs['senderForm']) {
        return this.$refs['senderForm'].validate()
      } else {
        return true
      }
    },
    validateCarrier () {
      if (this.$refs['carrierForm']) {
        return this.$refs['carrierForm'].validate()
      } else {
        return true
      }
    },
    async nextStep () {
      if (await this.validateFees()) {
        this.step = 'step2'
      } else {
        this.$message.error(this.$t('Setting.Payment.DialogShippingSetting.validate_fees'))
      }
    },
    async submit () {
      try {
        await this.validateFees()
      } catch (err) {
        this.$message.error(this.$t('Setting.Payment.DialogShippingSetting.validate_fees'))
        return
      }
      try {
        await this.validateAddress()
        await this.validateSender()
        await this.validateCarrier()
      } catch (err) {
        this.$message.error(this.$t('Setting.Payment.DialogShippingSetting.validate_sender'))
        return
      }

      this.normalSetting.fee = parseInt(this.normalSetting.fee)
      this.normalSetting.free_shipping_threshold = parseInt(this.normalSetting.free_shipping_threshold)

      if (this.normalSetting.fee_type !== 'condition') {
        this.normalSetting.free_shipping_threshold = 0
      }
      if (this.normalSetting.fee_type === 'free') {
        this.normalSetting.fee = 0
      }

      if (this.codSetting.fee_type !== 'condition') {
        this.codSetting.free_shipping_threshold = 0
      }
      if (this.codSetting.fee_type === 'free') {
        this.codSetting.fee = 0
      }

      let data = {}

      data['normal'] = this.normalSetting
      if (this.hasMemo) {
        this.normalSetting.info = {
          memo: this.normalSetting.memo
        }
      }

      if (this.setting.codAble) {
        this.codSetting.address = this.normalSetting.address
        this.codSetting.sender_name = this.normalSetting.sender_name
        this.codSetting.sender_phone = this.normalSetting.sender_phone
        data['cod'] = this.codSetting
      }

      if (this.carrierSetting) {
        data['carrier'] = { ...this.carrierSetting }
        data['carrier']['type'] = this.normalSetting.type + '-' + this.carrierSetting.type

        const returnDay = this.carrierSetting.return_cycle === '0'
          ? this.carrierSetting.return_day
          : null

        data['carrier']['info'] = {
          return_cycle: this.carrierSetting.return_cycle,
          return_day: returnDay,
          return_mode: this.carrierSetting.return_mode,
          return_mode_other: this.carrierSetting.return_mode_other
        }
      }
      this.$emit('submit', data)
    },
    updateAddress (obj) {
      this.normalSetting.address = obj
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
