import _ from 'lodash'
import axios from 'axios'

import * as ENTERPRISE_TYPES from '@/constants/enterpriseTypes'
import * as PAYMENT_FLOW from '@/constants/paymentFlow'
import * as ONBOARDING from '@/constants/onboarding'
import * as REGIONS from '@/constants/regions'
import { FBBUY } from '@/constants/storeSettings'
import { getStoreSetting } from '@/api/FBBuyBinding'

const state = {
  stores: null,
  childStores: [],
  ownedStores: [],
  currentStore: null,
  gmoForm: {},
  isFamilyB2C: false,
  isFBBuyStore: false
}

const getters = {
  stores: (state, getters) => _.get(state.stores, 'data', []),
  ownedStores: (state, getters) => state.ownedStores || [],
  childStores: state => state.childStores || [],
  allStores: (state, getters) => getters.stores.concat(getters.childStores),
  currentStore: state => state.currentStore,
  region: state => _.get(state.currentStore, 'region'),
  currency: state => _.get(state.currentStore, 'currency'),

  gmoStatus: state => state.gmoForm.status,
  gmoData: state => state.gmoForm.data,
  isFamilyB2C: state => state.isFamilyB2C,

  hasStores: state => {
    const stores = state.stores
    return stores && stores.data && stores.data.length > 0
  },
  ownedStore: (state, getters) => {
    // 判斷是否擁有商城
    if (!getters.hasStores) return null

    // 判斷目前登入商城是否有在清單內，若無的話，取所屬的第一個商城
    const localStoreName = localStorage.getItem('localStoreName')
    if (localStoreName) {
      return getters.allStores.find(item => item.slug === localStoreName)
    } else {
      return getters.stores[0]
    }
  },

  storeName: (state, getters) => _.get(getters.currentStore, 'slug', null),
  isCurrentStoreOwner: (state, getters) => _.get(getters.currentStore, 'pivot.type', '') === 'owner',
  isEnterprise: (state, getters) => !!_.get(getters.currentStore, 'enterprise', null),
  isEnterpriseGeneric: (state, getters) => {
    // 企業通用版
    return _.get(getters.currentStore, 'enterprise.type', null) === ENTERPRISE_TYPES.E_GENERIC
  },
  enterpriseSlug: (state, getters) => _.get(getters.currentStore, 'enterprise.slug', ''),
  enterpriseDomainUrl: (state, getters) => _.get(getters.currentStore, 'enterprise.subscription.info.domain', ''),

  paymentFlow: (state, getters) => _.get(getters.currentStore, 'payment_flow', PAYMENT_FLOW.DIRECT),

  isPhoneVerfied: (state, getters) => {
    // 如果不是 owner 直接跳過驗證判斷
    if (!getters.isCurrentStoreOwner) {
      return true
    }

    return (
      getters.currentStore &&
      !!getters.currentStore.store_registrant.phone_verified_at
    )
  },

  lineLink: (state, getters) => {
    const links = {
      TW: 'https://line.me/R/ti/p/%40frf5773e',
      TH: 'https://line.me/R/ti/p/%40844cvxjl'
    }

    return links[getters.region]
  },

  isChildStore: (state, getters) => {
    return !!_.get(state.currentStore, 'parent.id', null)
  },

  isChildStoreParent: (state, getters) => {
    const currentStore = getters.currentStore
    const stores = getters.ownedStores

    const result = _.find(stores, { id: currentStore.id })

    const parent = _.find(stores, { id: currentStore.parent_id })

    return !!result && !!parent
  },

  onboarding: (state, getters, rootState, rootGetters) => {
    let onboarding = _.cloneDeep(state?.currentStore?.onboarding ?? {})

    onboarding[ONBOARDING.CHANNEL_SETTING] = {
      channels: rootGetters['Me/StoreChannels/channelsWithoutHandsUP']
    }

    // Remove ticket steps when tickets not enabled
    if (!getters.enabledTicket) {
      delete onboarding[ONBOARDING.CREATE_TICKET]
      delete onboarding[ONBOARDING.CREATE_PRIVATE_POST]
    }

    // Remove product step when products not enabled
    if (!getters.enabledMerchandise) {
      delete onboarding[ONBOARDING.CREATE_PRODUCT]
    }

    // Combine payment and shipping settings
    if (!getters.enabledMerchandise) {
      onboarding[ONBOARDING.PAYMENT_SHIPPING] = onboarding[ONBOARDING.PAYMENT_SETTING]
    } else {
      onboarding[ONBOARDING.PAYMENT_SHIPPING] = onboarding[ONBOARDING.PAYMENT_SETTING] &&
        onboarding[ONBOARDING.SHIPPING_SETTING]
    }
    delete onboarding[ONBOARDING.PAYMENT_SETTING]
    delete onboarding[ONBOARDING.SHIPPING_SETTING]

    return onboarding
  },
  // 沒有完成的指南
  onboardingLeft: (_, getters) => {
    return Object.entries(getters.onboarding).reduce((acc, [key, val]) => {
      switch (key) {
        case ONBOARDING.CHANNEL_SETTING:
          return val.channels.length > 0 ? acc : ++acc
        default:
          return val ? acc : ++acc
      }
    }, 0)
  },

  isFBBuyStore (state) {
    return state.isFBBuyStore
  }
}

const mutations = {
  UPDATE_STORES (state, data) {
    // Update localStoreName in localStorage
    const oldSlug = localStorage.getItem('localStoreName')
    if (oldSlug) {
      const oldStore =
        state.stores &&
        state.stores.data &&
        state.stores.data.find(store => store.slug === oldSlug)
      if (oldStore && oldStore.id) {
        const targetStore = data.data.find(store => store.id === oldStore.id)
        if (targetStore) {
          localStorage.setItem('localStoreName', targetStore.slug)
        }
      }
    }

    state.stores = data
  },

  UPDATE_GMO_FORM (state, data) {
    state.gmoForm = data
  },

  UPDATE_CURRENT_STORE (state, data) {
    state.currentStore = data
  },

  UPDATE_FAMILY_B2C (state, flag) {
    state.isFamilyB2C = flag
  },

  UPDATE_CHILD_STORES (state, data) {
    state.childStores = data
  },
  UPDATE_OWNED_STORES (state, data) {
    state.ownedStores = data
  },

  updateIsFBStore (state, data) {
    state.isFBBuyStore = data
  }
}

const actions = {
  async fetchStores ({ commit, dispatch }, type = 'owner') {
    try {
      const api = `/api/v1/me/stores?all`
      const {
        data: { data }
      } = await axios.get(api)

      const allStores = data

      commit('UPDATE_STORES', { data: allStores })
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },

  async fetchOwnedStores ({ commit, dispatch }) {
    try {
      const api = `/api/v1/stores/owned-stores`
      const {
        data: { data }
      } = await axios.get(api)

      commit('UPDATE_OWNED_STORES', data)
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },

  async fetchChildStores ({ commit, dispatch }, type = 'owner') {
    try {
      const api = `/api/v1/stores/child-stores?all`
      const {
        data: { data }
      } = await axios.get(api)

      commit('UPDATE_CHILD_STORES', data)
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },

  async fetchGMOStatus ({ commit, getters }) {
    const currentStore = getters.currentStore
    if (!currentStore || getters.region !== REGIONS.JP) return
    try {
      const api = `/api/v1/stores/${currentStore.slug}/gmo-form`
      const { data } = await axios.get(api)

      commit('UPDATE_GMO_FORM', data)
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async fetchCurrentStore ({ commit, getters, dispatch }) {
    if (!getters.hasStores) return

    const api = '/api/v1/me/current-store'

    try {
      const { data } = await axios.get(api)

      commit('UPDATE_CURRENT_STORE', data)
      dispatch('Me/StoreChannels/fetchChannels', getters.currentStore.slug, { root: true })
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async fetchFamilyCarrier ({ commit, getters }, type) {
    const currentStore = getters.currentStore
    if (!currentStore || getters.region !== REGIONS.TW) return

    const api = `/api/v1/stores/${currentStore.slug}/carrier-setting/${type}`

    axios.get(api)
      .then(response => {
        const { data } = response
        const isVerified = _.get(data, 'is_verified', false)

        commit('UPDATE_FAMILY_B2C', isVerified)
      })
      .catch(err => {
        console.error(err)
      })
  },
  async updateCurrentStore ({ commit }, slug) {
    const api = `/api/v1/me/${slug}/current-store`

    try {
      await axios.put(api)
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  },
  async checkCurrentStore ({ getters, dispatch }, slug) {
    const hasStore = getters.allStores.some(store => store.slug === slug)
    if (hasStore) {
      await dispatch('updateCurrentStore', slug)
    }
  },
  async checkIsFBStore ({ commit, state }) {
    try {
      const currentStoreId = state.currentStore.id
      const settingList = await getStoreSetting(currentStoreId, [FBBUY])
      if (settingList[FBBUY].enable) {
        commit('updateIsFBStore', true)
      }
    } catch (error) {}
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
