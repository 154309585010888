<script>
import ApiProduct from '@/api/product'
import { mapGetters, mapActions } from 'vuex'

import DialogPickProduct from '@/components/DialogPickProduct'
import DialogPickProductGeneric from '@/components/DialogPickProductGeneric'
import DialogLiveAuthorization from '@/components/DialogLiveAuthorization'

import ProductTable from '../../components/ProductTable'
import PostPushNotification from '../../components/PushNotification'

export default {
  name: 'Create',
  components: {
    DialogPickProduct,
    DialogPickProductGeneric,
    DialogLiveAuthorization,
    PostPushNotification,
    ProductTable
  },
  beforeRouteLeave (to, from, next) {
    if (this.saved) {
      next()
    } else {
      const create = this.$createElement
      this.$msgbox({
        title: '',
        center: true,
        showClose: true,
        customClass: 'remind-saving',
        message: create('h4', null, [
          create('span', null, this.$t('Post.Create.message.leave_1')),
          create('br', null, ''),
          create('span', null, this.$t('Post.Create.message.leave_2'))
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('Post.Create.confirm'),
        cancelButtonText: this.$t('Post.Create.cancel')
      })
        .then(() => {
          next()
        })
        .catch(action => {
        })
    }
  },
  data () {
    return {
      products: [],
      dialogPickProductGenericVisible: false,
      dialogLiveAuthorizationVisible: false,
      liveAuthorizationToken: '',
      tokenExpiredAt: '',
      saved: false
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'isEnterprise', 'isEnterpriseGeneric', 'enterpriseSlug', 'enabledMerchandise'])
  },

  created () {
    this.getTicketList({
      storeId: this.storeName
    })
  },

  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('PostCreate', ['getTicketList']),
    async fetchKeywords (newProducts) {
      let api = `/api/v1/keywords/generate`
      let params = {
        product_ids: newProducts.map(product => product.id),
        exists_keywords: this.products.map(product => {
          return {
            keywordable_type: 'product',
            keywordable_id: product.id,
            keyword: product.keyword
          }
        }) || []
      }

      const { data: keywords } = await this.axios.post(api, params)

      return keywords
    },

    async handlePickProduct (data) {
      this.setLoading(true)

      let newProducts = data

      const keywords = await this.fetchKeywords(newProducts)

      newProducts.map(product => {
        const { keyword } = keywords.find(keyword => {
          return keyword.keywordable_id === product.id && keyword.keywordable_type === 'product'
        })
        product.keyword = keyword

        product.skus = product.skus.map(sku => {
          return {
            ...sku,
            salable_quantity: sku.salable_quantity,
            salable_limit: sku.salable_quantity
          }
        })

        return product
      })

      this.products.push(...newProducts)
      this.setLoading(false)
    },

    handleImportProduct (data) {
      // 匯入商品 Excel
      this.products = data
    },

    handleDeleteProduct (item) {
      this.products = this.products.filter(product => product.id !== item.id)
    },
    async handleUpdateProduct (item) {
      const success = await this.updateProduct(item)
      if (!success) {
        this.$message.error(this.$t('message.save_fail'))
        return
      }

      const product = this.products.find(product => product.id === item.id)
      if (product) {
        const data = {
          name: item.name,
          skus: item.skus,
          image_ids: item.image_ids,
          description: item.description,
          adult_warning: item.adult_warning,
          keyword: item.default_keyword
        }
        Object.assign(product, data)
      }
    },
    updateProduct (item) {
      const apiProduct = new ApiProduct()
      const payload = {
        name: item.name,
        skus: item.skus,
        image_ids: item.image_ids,
        description: item.description,
        adult_warning: item.adult_warning,
        keyword: item.default_keyword
      }
      return apiProduct.update(item.id, payload)
    },
    async handleVideoCreate ({ form: liveFormData, isObs }) {
      // 產品 ids
      const productArr = this.products.filter(product => product.selectSource !== 'url')
      const productUrlArr = this.products.filter(product => product.selectSource === 'url')

      let selectedProductIds = productArr.map(product => product.id)
      let selectedProductUrlIds = productUrlArr.map(product => product.info.url)

      // 關鍵字
      const keywords = this.products.map(product => {
        return {
          id: product.id,
          type: 'product',
          keyword: product.keyword
        }
      })

      // 可售數量 & 限購數量
      const quantities = this.products.reduce((prev, product) => {
        const skusQuantity = product.skus.map(sku => {
          return {
            id: sku.id,
            type: 'sku',
            salable_quantity: sku.salable_quantity,
            salable_limit: sku.salable_limit
          }
        })

        return [...prev, ...skusQuantity]
      }, [])

      // 建立貼文
      const postData = Object.assign(liveFormData, {
        channel_ids: liveFormData.channel_ids ? [liveFormData.channel_ids] : [],
        product_ids: selectedProductIds,
        product_urls: selectedProductUrlIds,
        keywords,
        quantities,
        live: true
      })

      this.setLoading(true)

      try {
        const api = liveFormData.mode === 'postAgent'
          ? `/api/v1/stores/${this.storeName}/post-agents`
          : `/api/v1/${this.isEnterprise ? 'enterprise/' : ''}stores/${this.storeName}/posts`

        const { data: {
          id,
          agent: {
            token: liveAuthorizationToken,
            expired_at: tokenExpiredAt
          } = { token: '', expired_at: '' } }
        } = await this.axios.post(api, postData)

        this.saved = true

        // [Facebook Pixel] 追蹤建立直播事件
        const mode = liveFormData.mode ? liveFormData.mode : 'storePost'
        this.$analytics.fbq.event('CreatePost', {
          Page: 'Merchant-Platform',
          Content: 'Sales',
          Target: 'Create-Post',
          mode: mode
        })
        // [Google Analytics] 追蹤建立直播事件
        this.$ga.event({
          eventCategory: 'Post-Create',
          eventAction: mode + '-Post-Create',
          eventLabel: 'Merchant-Platform'
        })

        this.$analytics.fbq.event('M-Register-CreatePost', {
          Page: 'Merchant-Platform',
          Target: 'Create-Post',
          mode: mode
        })
        // [Google Analytics] 追蹤建立直播事件
        this.$ga.event({
          eventCategory: 'M-Register-CreatePost',
          eventAction: mode + '-Post-Create',
          eventLabel: 'Merchant-Platform'
        })

        if (postData.channel_ids.length) {
          if (isObs) {
            this.$router.push({ path: `/${this.storeName}/posts/${id}`, query: { 'obs': true } }, () => {
            })
          } else {
            // 觸發 子元件的 method
            this.$refs.PostPushNotification.handleCompleted(id, () => {
              this.$router.push({ path: `/${this.storeName}/posts/${id}` })
            })
          }
        } else if (liveFormData.mode === 'postAgent') {
          // 網紅授權
          this.liveAuthorizationToken = liveAuthorizationToken
          this.tokenExpiredAt = tokenExpiredAt
          this.dialogLiveAuthorizationVisible = true
        } else {
          // 前往銷售首頁
          this.toHomePage()
        }
      } catch (e) {
        console.error(e)

        if (/LivePostException/.test(e.data.type)) {
          this.$message.error(this.$t('Post.Create.message.mutiple_live'))
        } else if (e.status !== 422) {
          this.$message.error(this.$t('Post.Create.message.create_error'))
        }
      }

      this.setLoading(false)
    },
    toHomePage () {
      this.saved = true
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<template src="./template.pug" />
<style lang="scss" src="./style.scss" scoped></style>
