import Orderer from './Orderer'
import Payment from './Payment'
import ShippingAddress from './ShippingAddress'
import ShoppingDetails from './ShoppingDetails'

import ApiOrder from '@/api/order'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import { ORDERER_TYPE } from '@/constants/orderManual'

const actions = {
  async createManualOrder ({ commit, rootState, rootGetters }) {
    const ordererInfo = rootState.OrderManual.Orderer
    const shippingInfo = rootState.OrderManual.ShippingAddress
    const paymentInfo = rootState.OrderManual.Payment
    const shoppingInfo = rootState.OrderManual.ShoppingDetails

    const user = ordererInfo.ordererType === ORDERER_TYPE.Guest ? {
      slug: null,
      name: ordererInfo.ordererName,
      is_invite: ordererInfo.isGuestInvited
    } : {
      slug: ordererInfo.memberSlug
    }

    const convenientStoreInfo = shippingInfo.shippingType === SHIPPING_TYPES.FAMILY || shippingInfo.shippingType === SHIPPING_TYPES.SEVEN ? {
      store_id: shippingInfo.storeId,
      name: shippingInfo.storeName,
      address: shippingInfo.storeAddress
    } : null

    let receiverAddress = ''
    switch (shippingInfo.shippingType) {
      case SHIPPING_TYPES.SELF_PICK_UP:
        receiverAddress = shippingInfo.selfPickAddress
        break
      case SHIPPING_TYPES.SEVEN:
      case SHIPPING_TYPES.FAMILY:
        receiverAddress = shippingInfo.storeAddress
        break
      default:
        receiverAddress = `${shippingInfo.city}${shippingInfo.region}${shippingInfo.address}`
        break
    }

    let params = {
      user,
      email: ordererInfo.contactEmail,
      phone_number: shippingInfo.receiverPhone,
      payment_type: paymentInfo.selectedPayment,
      shipping_type: shippingInfo.shippingType,
      shipping: {
        receiver_name: shippingInfo.receiverName,
        receiver_phone: shippingInfo.receiverPhone,
        receiver_address: receiverAddress,
        info: convenientStoreInfo

      },
      items: shoppingInfo.selectedProducts.map(ele => ({
        sku_id: ele.sku_id,
        quantity: ele.quantity,
        price: ele.price
      }))
    }

    const apiOrder = new ApiOrder()
    await apiOrder.manuallyCreate(params)
  },
  resetAll ({ commit }) {
    commit('OrderManual/Orderer/RESET', undefined, { root: true })
    commit('OrderManual/Payment/RESET', undefined, { root: true })
    commit('OrderManual/ShippingAddress/RESET', undefined, { root: true })
    commit('OrderManual/ShoppingDetails/RESET', undefined, { root: true })
  }
}

export default {
  namespaced: true,
  actions,
  modules: {
    ShippingAddress,
    Orderer,
    Payment,
    ShoppingDetails
  }
}
