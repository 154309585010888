import axios from '@/plugins/http.js'

export const fetchPaymentBill = (storeId) => {
  const api = `/api/v1/stores/${storeId}/bills/current`

  return axios.get(api)
}

export const fetchBillItems = (storeId, params) => {
  const api = `/api/v1/stores/${storeId}/bill-items`

  return axios.get(api, { params })
}

export const fetchBills = (storeId, params) => {
  const api = `/api/v1/stores/${storeId}/bills`

  return axios.get(api, { params })
}

export const exportBillItems = async (storeId, date = null) => {
  try {
    const response = await axios({
      url: `/api/v1/stores/${storeId}/bills/export-bill-items`,
      method: 'Get',
      responseType: 'blob',
      params: {
        date
      }
    })

    const contentDisposition = response.headers['content-disposition']
    let fileName = `export-bill-items.csv`
    let matches = contentDisposition.match(/filename=(.+)/)
    if (matches != null) {
      fileName = matches[1]
    }
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    return true
  } catch (e) {
    console.error(e)
    return false
  }
}
