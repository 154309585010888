const state = {
  service: {
    point: null,
    amount: null
  },
  information: {
    name: null,
    fee: 55
  },
  origin: {
    name: null,
    query: null,
    path: null
  }
}

const getters = {
  point: state => state.service.point,
  amount: state => state.service.amount,
  origin: state => state.origin
}

const mutations = {
  UPDATE_SERVICE (state, payload) {
    state.service = payload
  },
  UPDATE_INFORMATION (state, payload) {
    state.information = payload
  },
  UPDATE_ORIGIN (state, payload) {
    state.origin = payload
  }
}

const actions = {
  updateService ({ commit, dispatch }, payload) {
    commit('UPDATE_SERVICE', payload)
  },
  updateInformation ({ commit, dispatch }, payload) {
    commit('UPDATE_INFORMATION', payload)
  },
  updateOrigin ({ commit, dispatch }, payload) {
    commit('UPDATE_ORIGIN', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
