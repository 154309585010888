import ApiShippingSetting from '@/api/shippingSetting'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import { MANUAL_ORDER_CACHE } from '@/constants/orderManual'

export const actionTypes = {
  UPDATE_RECEIVER_NAME: 'UPDATE_RECEIVER_NAME',
  UPDATE_RECEIVER_PHONE: 'UPDATE_RECEIVER_PHONE',
  UPDATE_SHIPPING_TYPE: 'UPDATE_SHIPPING_TYPE',
  UPDATE_CONVENIENCE_STORE_INFO: 'UPDATE_CONVENIENCE_STORE_INFO',
  UPDATE_SHIPPING_METHOD: 'UPDATE_SHIPPING_METHOD',
  UPDATE_HOME_ADDRESS: 'UPDATE_HOME_ADDRESS',
  UPDATE_SELF_PICK_INFO: 'UPDATE_SELF_PICK_INFO', // 門市地址自取
  RESET: 'RESET',
  RESTORE_CACHE: 'RESTORE_CACHE'
}

const INIT_DATA = {
  availableShippings: [],
  receiverName: '',
  receiverPhone: '',
  shippingType: '',
  // 宅配, 新竹物流
  city: '',
  region: '',
  address: '',
  // 超取用
  storeType: '',
  storeId: null,
  storeName: '',
  storeAddress: '',
  // 自取門市與地址
  selfPickStoreName: '',
  selfPickAddress: ''
}

const state = _.cloneDeep(INIT_DATA)

const mutations = {
  [actionTypes.UPDATE_RECEIVER_NAME] (state, payload) {
    state.receiverName = payload
  },
  [actionTypes.UPDATE_RECEIVER_PHONE] (state, payload) {
    state.receiverPhone = payload
  },
  [actionTypes.UPDATE_SHIPPING_TYPE] (state, payload) {
    state.shippingType = payload
  },
  [actionTypes.UPDATE_CONVENIENCE_STORE_INFO] (state, payload) {
    state.storeId = payload.store_id
    state.storeName = payload.name
    state.storeAddress = payload.address
    state.storeType = payload.storeType // seven or family
  },
  [actionTypes.UPDATE_SHIPPING_METHOD] (state, payload) {
    state.availableShippings = [...payload]
  },
  [actionTypes.UPDATE_HOME_ADDRESS] (state, { address, city, region }) {
    state.address = address
    state.city = city
    state.region = region
  },
  [actionTypes.UPDATE_SELF_PICK_INFO] (state, payload) {
    state.selfPickAddress = payload.address
    state.selfPickStoreName = payload.storeName
  },
  [actionTypes.RESTORE_CACHE] (state, payload = {}) {
    state.receiverName = payload.receiverName
    state.receiverPhone = payload.receiverPhone
    state.shippingType = payload.shippingType
  },
  [actionTypes.RESET] (state) {
    Object.assign(state, INIT_DATA)
  }
}

const actions = {
  async getAvailableShippingMethod ({ state, commit }) {
    const apiShippingSetting = new ApiShippingSetting()
    const settings = await apiShippingSetting.list({}, true)

    // 排序依照: 貨運宅配 全家取貨 7-11取貨 新竹物流 門市取貨
    const sortedShipping = settings.reduce((accumulator, currentValue) => {
      const baseAttributes = {
        fee: parseInt(currentValue.fee, 10),
        feeType: currentValue.fee_type,
        type: currentValue.type,
        freeShippingThreshold: parseInt(currentValue.free_shipping_threshold, 10)
      }
      if (!currentValue.enabled) {
        return accumulator
      }

      switch (currentValue.type) {
        case SHIPPING_TYPES.HOME_DELIVERY:
          accumulator.push({ ...baseAttributes, order: 0 })
          break
        case SHIPPING_TYPES.FAMILY:
          accumulator.push({ ...baseAttributes, order: 1 })
          break
        case SHIPPING_TYPES.SEVEN:
          accumulator.push({ ...baseAttributes, order: 2 })
          break
        case SHIPPING_TYPES.HSINCHU:
          accumulator.push({ ...baseAttributes, order: 3 })
          break
        case SHIPPING_TYPES.SELF_PICK_UP:
          //  自取門市地址更新
          commit(actionTypes.UPDATE_SELF_PICK_INFO, {
            address: currentValue.address.full_address,
            storeName: currentValue.address.info.name
          })
          accumulator.push({ ...baseAttributes, order: 4 })
          break
        default:
          break
      }
      return accumulator
    }, []).sort((a, b) => a.order - b.order)

    commit(actionTypes.UPDATE_SHIPPING_METHOD, sortedShipping)

    if (!state.shippingType) {
      commit(actionTypes.UPDATE_SHIPPING_TYPE, sortedShipping[0].type)
    }
  },
  updateShippingType ({ commit }, value) {
    commit(actionTypes.UPDATE_SHIPPING_TYPE, value)
  },
  updateReceiverName ({ commit }, value) {
    commit(actionTypes.UPDATE_RECEIVER_NAME, value)
  },
  updateReceiverPhone ({ commit }, value) {
    commit(actionTypes.UPDATE_RECEIVER_PHONE, value)
  },
  updateReceiverHomeAddress ({ commit }, value) {
    commit(actionTypes.UPDATE_HOME_ADDRESS, value)
  },
  updateConvenientStore ({ commit }, value) {
    commit(actionTypes.UPDATE_CONVENIENCE_STORE_INFO, value)
  },
  cacheOrderData ({ rootState, state }) {
    const { Orderer, Payment, ShoppingDetails } = rootState.OrderManual

    try {
      const orderData = {
        orderer: {
          ordererType: Orderer.ordererType,
          ordererName: Orderer.ordererName,
          contactEmail: Orderer.contactEmail,
          isGuestInvited: Orderer.isGuestInvited,
          memberSlug: Orderer.memberSlug,
          memberInfo: Orderer.memberInfo
        },
        payment: {
          selectedPayment: Payment.selectedPayment
        },
        shippingAddress: {
          receiverName: state.receiverName,
          receiverPhone: state.receiverPhone,
          shippingType: state.shippingType
        },
        shoppingDetails: {
          selectedProducts: ShoppingDetails.selectedProducts
        }
      }
      localStorage.setItem(MANUAL_ORDER_CACHE, JSON.stringify(orderData))
    } catch (error) {

    }
  },
  restoreCachedOrder ({ commit }) {
    try {
      const cachedOrder = JSON.parse(localStorage.getItem(MANUAL_ORDER_CACHE))
      if (cachedOrder.shippingAddress.shippingType === SHIPPING_TYPES.SEVEN || cachedOrder.shippingAddress.shippingType === SHIPPING_TYPES.FAMILY) {
        commit(actionTypes.RESTORE_CACHE, cachedOrder.shippingAddress)
        commit('OrderManual/Orderer/RESTORE_CACHE', cachedOrder.orderer, { root: true })
        commit('OrderManual/Payment/RESTORE_CACHE', cachedOrder.payment, { root: true })
        localStorage.removeItem(MANUAL_ORDER_CACHE)
      }
    } catch (error) {

    }
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
