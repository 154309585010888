<script>
import VueTypes from 'vue-types'

// 藍新金流之虛擬帳號匯款，可以設定買家結帳時間，範圍為 1 ~ 7天
// 預設天數為 7 天
const LIMIT_MIN = 1
const LIMIT_MAX = 7
const LIMIT_DEFAULT = 7

export default {
  name: 'DialogCheckoutSetting',

  props: {
    setting: VueTypes.object.def({}),
    editable: VueTypes.bool.def(false)
  },

  data () {
    return {
      LIMIT_MIN,
      LIMIT_MAX,

      isLoading: false,
      ruleForm: {
        nrt_payment_day: LIMIT_DEFAULT
      },
      rules: {
        nrt_payment_day: [
          {
            required: true,
            message: this.$t('Setting.Seller.DialogCheckoutSetting.validate_nrt_payment_day')
          }
        ]
      }
    }
  },

  created () {
    this.initForm()
  },

  methods: {
    initForm () {
      const initData = {
        nrt_payment_day: _.get(this.setting, 'info.nrt_payment_day', LIMIT_DEFAULT)
      }

      this.ruleForm = initData
    },
    async handleSubmit () {
      // 驗證欄位
      try {
        await this.$refs.ruleForm.validate()
      } catch (error) {
        return
      }

      const data = {
        type: null,
        enabled: true,
        info: this.ruleForm
      }

      this.isLoading = 'true'
      this.$emit('submit', data)
    },

    handleClose () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogCheckoutSetting.pug"></template>
<style lang="scss" src="./DialogCheckoutSetting.scss" scoped></style>
