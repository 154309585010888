<script>
import ApiCart from '@/api/cart'
import List from './components/List'
import Pagination from '@/components/Pagination'
import DialogOrderDetail from './components/DialogOrderDetail'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'OrderEnterPrise',
  components: { Pagination, List, DialogOrderDetail },
  data () {
    return {
      activeTab: 'cart',
      cartData: [],
      cartPaginator: null,
      cartPostTags: [],
      filterDateRange: null,
      search: {
        from: null,
        to: null,
        postId: '',
        keyword: '',
        perPage: 15
      },
      currentPage: 1,
      selectOrder: []
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName']),
    showOrderDetail () {
      return !!(this.selectOrder && this.selectOrder.length)
    },
    calcPostTags () {
      const postTags = this.cartPostTags
      return postTags.reduce((prev, current) => {
        return prev.some(item => item.value === current.id)
          ? prev
          : [...prev, { value: current.id, label: current.title }]
      }, [])
    },
    calcOrders () {
      return this.normalizerCart
    },
    normalizerCart () {
      return this.cartData.map(cart => ({
        'id': cart.id,
        'post_id': null,
        'post_name': null,
        'channel_type': cart.items.reduce((prev, current) => {
          return !prev && current.channel && current.channel.type
            ? current.channel.type
            : prev
        }, ''),
        'avatar_url': null,
        'buyer_channel_type': null,
        'buyer_name': cart.source_name,
        'amount': cart.items.reduce((prev, current) => {
          return prev + (current.price * current.quantity)
        }, 0),
        'count': cart.items.reduce((prev, current) => {
          return prev + current.quantity
        }, 0),
        'is_paid': false,
        'order_id': null,
        'created_at': cart.created_at,
        'notified_at': null
      }))
    },
    searchParams () {
      return {
        page: this.currentPage,
        from: this.filterDateRange && this.filterDateRange[0],
        to: this.filterDateRange && this.filterDateRange[1],
        post_id: this.search.postId || null,
        keyword: this.search.keyword || null,
        per_page: this.search.perPage || 15
      }
    },
    paginator () {
      return this.cartPaginator
    }
  },
  mounted () {
    this.handleReFetch()
  },
  methods: {
    ...mapActions(['setLoading']),
    handleClearSearch () {
      this.filterDateRange = null
      this.search = {
        from: '',
        to: '',
        postId: '',
        keyword: '',
        perPage: 15
      }

      this.fetchCart()
    },
    async fetchCart () {
      const apiCart = new ApiCart()
      const data = await apiCart.list(this.searchParams)

      this.cartData = data
      this.cartPaginator = apiCart.paginator
    },
    async fetchCartPostTags () {
      const apiCart = new ApiCart()

      const cartPostTags = await apiCart.getCartPostTags()
      this.cartPostTags = cartPostTags
    },
    async  fetchOrderDetail (id) {
      const mappingChannelType = {
        'fb_page': 'fb',
        'handsup': 'handsup'
      }
      const cartItem = this.cartData.find(item => item.id === id)

      this.selectOrder = cartItem.items.map(item => ({
        'id': item.id,
        'post_name': item.post && item.post.title,
        'channel_type': mappingChannelType[item.channel && item.channel.type] || null,
        'product_name': item.product_name,
        'sku_name': item.sku_name,
        'price': item.price,
        'quantity': item.quantity
      }))
    },
    handleReFetch: _.debounce(async function () {
      this.setLoading(true)

      this.currentPage = 1

      await this.fetchCart()
      await this.fetchCartPostTags()

      this.setLoading(false)
    }, 500),
    updateRows (rows) {
      this.cartData = rows
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
