<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ConfirmPaymentSetting',

  computed: {
    ...mapGetters('Me', ['storeName'])
  },

  methods: {
    toPaymentSetting () {
      this.$router.push({ path: `/${this.storeName}/setting`, query: { activeMenu: 'SettingPayment' } })
    },

    handleCloseDialog () {
      this.$emit('close')
      this.$emit('update:dialogVisible', false)
    },

    submit () {
      this.toPaymentSetting()
      this.handleCloseDialog()
    }
  }
}
</script>

<template lang="pug" src="./ConfirmPaymentSetting.pug"></template>
