<script>
export default {
  name: 'DialogGMOResult',
  components: {},
  props: {
    gmoStatus: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
