// 方案名稱
export const NOVICE = 'novice' // 小資計畫
export const MASTER = 'master' // 銷售達人計畫
export const EXPERT = 'expert' // 購物專家計畫
export const FREE_TRIAL = 'free_trial' // 試用方案

// JP
export const MERCHANDISE = 'merchandise'
export const TICKET = 'ticket'
export const FULL = 'full'
export const ENTERPRISE = 'enterprise'
