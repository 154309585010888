import axios from '@/plugins/http.js'

export const getMemo = orderId => {
  const api = `/api/v1/orders/${orderId}/memos`

  return axios.get(api)
}

export const updateMemo = (orderId, memo) => {
  const api = `/api/v1/orders/${orderId}/memos`
  const params = { memo }

  return axios.put(api, params)
}
