<script>
import T from 'vue-types'

const TAG_TYPE = Object.freeze({
  SUCCESS: 'success',
  DANGER: 'danger'
})

export default {
  name: 'Tag',
  TYPES: TAG_TYPE,
  props: {
    type: T.oneOf([TAG_TYPE.SUCCESS, TAG_TYPE.DANGER]).def(TAG_TYPE.SUCCESS)
  }
}
</script>

<template lang="pug" src="./Tag.pug" functional></template>
<style lang="scss" src="./Tag.scss" scoped></style>
