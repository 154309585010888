<script>
export default {
  name: 'ConfirmSuccess',

  methods: {
    handleCloseDialog () {
      this.$emit('close')
      this.$emit('update:dialogVisible', false)
    },
    submit () {
      this.$emit('submit')
    }
  }
}
</script>

<template lang="pug" src="./ConfirmSuccess.pug"></template>
