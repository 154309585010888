import { render, staticRenderFns } from "./InfoContentDirect.pug?vue&type=template&id=0a0dd95e&scoped=true&lang=pug&"
import script from "./InfoContentDirect.vue?vue&type=script&lang=js&"
export * from "./InfoContentDirect.vue?vue&type=script&lang=js&"
import style0 from "./InfoContentDirect.scss?vue&type=style&index=0&id=0a0dd95e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a0dd95e",
  null
  
)

export default component.exports