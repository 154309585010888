<script>
import { mapGetters, mapActions } from 'vuex'

import DialogOfflineAtm from '@/components/DialogOfflineAtm'
import ApproveTableFilter from './components/ApproveTableFilter'
import ApproveTable from './components/ApproveTable'
import ApproveFooter from './components/ApproveFooter'
import ApproveDialog from './components/ApproveDialog'

export default {
  name: 'BatchApprove',
  components: {
    ApproveTableFilter,
    ApproveTable,
    DialogOfflineAtm,
    ApproveFooter,
    ApproveDialog
  },
  data () {
    return {
      tipVisible: !localStorage.getItem('hasShowApproveTip'),
      confirmDialogVisible: false
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName']),
    ...mapGetters('BatchApprove', [
      'orders',
      'approving',
      'selected',
      'filter',
      'sort'
    ]),
    dialogOfflineAtmVisible: {
      get () {
        return !_.isEmpty(this.approving)
      },
      set () {
        this.updateSingleApprove({})
      }
    }
  },
  mounted () {

  },
  methods: {
    ...mapActions('BatchApprove', [
      'fetchApproves',
      'putBankReceipts',
      'putOfflineAtmReview',
      'updateSingleApprove',
      'batchApprove'
    ]),
    handleCloseTip () {
      localStorage.setItem('hasShowApproveTip', true)
      this.tipVisible = false
    },
    toPrint () {
      const params = _.pickBy({ ...this.filter, ...this.sort }, _.identity)
      localStorage.setItem('batchApproveParams', JSON.stringify(params))
      const path = `/${this.storeName}/batch-approve-print`
      window.open(path, '_blank')
    },
    async handleBankReceipt (payload) {
      try {
        await this.putBankReceipts(payload.bankReceipt)
        await this.putOfflineAtmReview(payload.paymentStatus)
        this.fetchApproves()
      } catch (e) {
        this.$message.error(this.$t('message.save_fail'))
      }
      this.updateSingleApprove({})
    },
    handleApproveSubmit () {
      this.confirmDialogVisible = true
    },
    async handleApproveConfirm () {
      try {
        const count = this.selected.length
        await this.batchApprove()
        await this.fetchApproves()
        this.$message.success(this.$t('Order.BatchApprove.success_message', { count }))
      } catch (e) {
        this.$message.error(this.$t('message.save_fail'))
      }
      this.confirmDialogVisible = false
    }
  }
}
</script>

<template lang="pug" src="./BatchApprove.pug"></template>
<style lang="scss" src="./BatchApprove.scss" scoped></style>
