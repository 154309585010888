import * as loadingState from '@/constants/loadingState'

import { bindFbChannel } from '../apis'

export const actionTypes = {
  BIND_CHANNEL: 'BIND_CHANNEL',
  BIND_CHANNEL_SUCCESS: 'BIND_CHANNEL_SUCCESS',
  BIND_CHANNEL_FAILED: 'BIND_CHANNEL_FAILED'
}

const state = {
  loadingState: loadingState.EMPTY,
  error: null
}

const getters = {
  bindChannelLoadingState: state => state.loadingState
}

const mutations = {
  [actionTypes.BIND_CHANNEL] (state) {
    state.loadingState = loadingState.LOADING
  },

  [actionTypes.BIND_CHANNEL_SUCCESS] (state, payload) {
    state.loadingState = loadingState.READY
  },

  [actionTypes.BIND_CHANNEL_FAILED] (state, error) {
    state.loadingState = loadingState.ERROR
    state.error = error

    console.error(error)
  }
}

const actions = {
  bindFbChannel ({ commit }, { storeId, payload }) {
    commit(actionTypes.BIND_CHANNEL)

    return bindFbChannel(storeId, payload)
      .then(response => commit(actionTypes.BIND_CHANNEL_SUCCESS, response))
      .catch(err => commit(actionTypes.BIND_CHANNEL_FAILED, err))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
