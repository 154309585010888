<script>
import { mapGetters } from 'vuex'

import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

// import Prism Editor
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css' // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism-tomorrow.css' // import syntax highlighting styles

import IMG_INTRODUCTION from './images/api-introduction.png'
import IMG_STEP_1 from './images/api-step-1@2x.png'
import IMG_STEP_2 from './images/api-step-2@2x.png'
import IMG_STEP_3 from './images/api-step-3@2x.png'
import IMG_STEP_4 from './images/api-step-4@2x.png'
import IMG_FLOW from './images/flow.jpg'

import '@/utils/copyText'

import { fetchEnterpriseSetting } from '@/views/Setting/Api/services/apis/settings'

import { getSessionToken, getCartItems, postback } from './services/apis'

export default {
  name: 'DevelopExample',

  components: {
    VueJsonPretty,
    PrismEditor
  },

  data () {
    return {
      IMG_INTRODUCTION,
      IMG_STEP_1,
      IMG_STEP_2,
      IMG_STEP_3,
      IMG_STEP_4,
      IMG_FLOW,

      apiInformation: {},

      // Step 1
      getUserToken: '',

      // Step 2
      postUserToken: '',
      cartResponseData: '',

      // Step 3
      postbackRequest: `{
  "data": [
    {
      "order_id": "ORDER-ENTERPRISE-ID-00001",
      "buyer_name": "Joe Frank",
      "buyer_email": "joefrank@test.com",
      "buyer_phone": "",
      "buyer_country_code": "JP",
      "total_price": "3600",
      "currency": "JPY",
      "session_token": "1koQnJ7LV65xYRdG7dDzOWg4ByK12EwN",
      "created_at": "2020-09-10T07:37:41+00:00",
      "paid_at": "2020-09-10T07:37:41+00:00",
      "order_status": "completed",
      "order_items": [
        {
          "merchant_product_id": "A0001",
          "handsup_product_id": "920343",
          "product_name": "Test Product A",
          "merchant_sku_id": "A0001-01",
          "handsup_sku_id": "230001",
          "sku_name": "Green",
          "price": "1200",
          "quantity": 3
        }
      ]
    }
  ],
  "info": ""
}`,
      postbackResponseData: ''
    }
  },

  computed: {
    ...mapGetters('Me', ['storeName']),

    developerSettingPath () {
      return `/${this.storeName}/setting?activeMenu=SettingApi`
    }
  },

  created () {
    this.fetchApiSetting()
  },

  methods: {
    async fetchApiSetting () {
      try {
        const { data } = await fetchEnterpriseSetting(this.storeName)
        this.apiInformation = data?.['api_information']
      } catch (e) {
        console.error(e)
      }
    },

    async handleGetUserToken () {
      try {
        const { data } = await getSessionToken()
        this.getUserToken = data.session_token
      } catch (err) {
        console.error(err)
      }
    },

    handleCopyUserToken () {
      this.copyText({
        url: this.getUserToken
      })
    },

    async handleGetCartItems () {
      try {
        const { data } = await getCartItems(this.postUserToken)
        this.cartResponseData = data
      } catch (err) {
        const { data } = err
        this.cartResponseData = data
      }
    },

    highlighter (code) {
      return highlight(code, languages.js)
    },
    async handlePostBack () {
      // 將資料轉成 Object
      let postData = ''
      try {
        postData = JSON.parse(this.postbackRequest)
      } catch (err) {
        this.postbackResponseData = err
        return
      }

      // 若 JSON.parse 可成功轉換的話，再呼叫 API
      try {
        const { data } = await postback(postData)
        this.postbackResponseData = data
      } catch (err) {
        const { data } = err
        this.postbackResponseData = data
      }
    },

    goAnchor (id) {
      this.$scrollTo(id)
    }
  }
}
</script>

<template lang="pug" src="./DevelopExample.pug"></template>
<style lang="scss" src="./DevelopExample.scss" scoped></style>
