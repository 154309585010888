<script>
import VueTypes from 'vue-types'

import { setInterval, clearInterval } from 'timers'
import { mapGetters } from 'vuex'

import * as IVSPlayerPackage from 'amazon-ivs-player'
import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm'
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js'

import SoundOnIcon from '@/assets/icons/icon-sound-on.svg'
import SoundOffIcon from '@/assets/icons/icon-sound-off.svg'
import { UNPUBLISHED } from '@/constants/liveStatus'

const createAbsolutePath = assetPath => new URL(assetPath, document.URL).toString()

export default {
  name: 'VideoPlayer',
  props: {
    stage: VueTypes.string
  },
  data: function () {
    return {
      UNPUBLISHED,
      SoundOffIcon,
      SoundOnIcon,
      player: null,
      timeout: null,
      replayTimer: null,
      isPause: true,
      isStuck: false, // 直播中斷
      video: null,
      videoMuted: false
    }
  },
  computed: {
    ...mapGetters('Post', ['streamUrl', 'watchingCount', 'liveStatus'])
  },
  watch: {
    streamUrl: function (value) {
      this.loadVideo(value)
    },
    isMute: function (value) {
      if (this.player) {
        this.player.setMuted(value)
      }
    }
  },
  mounted: function () {
    this.loadVideo(this.streamUrl)
    this.handlePlayer()
  },
  beforeDestroy () {
    if (this.player) {
      this.player.delete()
      this.player = null
    }
    this.destroy()
  },
  methods: {
    loadVideo (url) {
      if (this.player) {
        this.destroy()
        this.player = null
      }

      if (!url) {
        return
      }

      // First, check if the browser supports the IVS player.
      if (!IVSPlayerPackage.isPlayerSupported) {
        console.error('The current browser does not support the IVS player.')
        return
      }

      // Initialize player
      const player = IVSPlayerPackage.create({
        wasmWorker: createAbsolutePath(wasmWorkerPath),
        wasmBinary: createAbsolutePath(wasmBinaryPath)
      })
      player.attachHTMLVideoElement(document.getElementById('video-player'))

      // Setup stream and play
      player.setAutoplay(true)
      player.load(url)
      this.player = player
    },
    handlePlay () {
      if (!this.player) return

      if (this.player.isPaused()) {
        this.player.play()
      } else {
        this.player.pause()
      }
    },

    handlePlayer () {
      if (!this.player) return

      const PlayerState = IVSPlayerPackage.PlayerState
      const PlayerEventType = IVSPlayerPackage.PlayerEventType

      // Attach event listeners
      this.player.addEventListener(PlayerState.PLAYING, () => {
        if (this.stage === 'end') return

        this.videoMuted = this.player.isMuted()
        this.isPause = false
        this.handleMonitorVideo()
      })

      this.player.addEventListener(PlayerState.READY, () => {
        if (this.stage === 'end') return

        this.isPause = true
        this.handleStuck(false)
        if (this.timeout) {
          clearInterval(this.timeout)
        }
        if (this.replayTimer) {
          clearInterval(this.replayTimer)
        }
      })

      this.player.addEventListener(PlayerEventType.ERROR, () => {
        this.isPause = false
      })

      this.player.addEventListener(PlayerState.IDLE, () => {
        if (this.stage === 'end') return
        this.isPause = true
      })
    },

    handleStuck (on = true) {
      if (this.isStuck && on) {
        return
      }

      this.isStuck = on

      this.$emit('error', on)

      if (on) {
        if (this.timeout) {
          clearInterval(this.timeout)
        }
        this.handleReplay()
      }
    },

    handleReplay () {
      if (this.replayTimer) clearInterval(this.replayTimer)

      this.replayTimer = setInterval(() => {
        if (this.player) {
          this.player.load(this.streamUrl)
          this.player.play()
        }
      }, 1000)
    },

    handleMonitorVideo () {
      if (this.stage === 'end') return
      if (this.isStuck) return
      if (this.timeout) clearInterval(this.timeout)

      this.timeout = setInterval(() => {
        if (this.lastTime === this.player.getPosition()) {
          this.handleStuck(true)
        } else {
          this.lastTime = this.player.getPosition()
          this.isPause = false
        }
      }, 5000)
    },
    destroy () {
      if (this.timeout) clearInterval(this.timeout)
      if (this.replayTimer) clearInterval(this.replayTimer)
    },
    toggleMuteVideo () {
      this.videoMuted = !this.videoMuted
      this.player.setMuted(this.videoMuted)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
