export const CHANNEL_SETTING = 'channel_setting'
export const PAYMENT_SHIPPING = 'payment_shipping' // COMPUTED, not in API
export const PAYMENT_SETTING = 'payment_setting'
export const SHIPPING_SETTING = 'shipping_setting'
export const STORE_SETTING = 'store_setting'
export const CREATE_PRODUCT = 'create_product'
export const CREATE_TICKET = 'create_ticket'
export const MARKET_SETTING = 'market_setting'
export const CREATE_POST = 'create_post'
export const CREATE_PRIVATE_POST = 'create_private_post'
export const START_STREAMING = 'start_streaming'
