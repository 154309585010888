<script>
import VueTypes from 'vue-types'

export default {
  name: 'ShippingFee',
  props: {
    currencySymbol: VueTypes.string.def('$'),
    fee: VueTypes.string.isRequired,
    freeShippingThreshold: VueTypes.string.isRequired
  }
}
</script>

<template lang="pug" src="./ShippingFee.pug"></template>
<style lang="scss" src="./ShippingFee.scss" scoped></style>
