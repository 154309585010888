<script>
import { mapGetters } from 'vuex'

import * as SETTINGS from '@/constants/storeSettings'
import { LIKE } from '@/constants/animationTypes'
import HighlightElement from '../HighlightElement'
import SoundOnIcon from './images/icon-sound-on.svg'
import ICON_HEART from './images/icon-heart.svg'
import ICON_CART from './images/icon-cart.svg'
import ICON_SHARE from './images/icon-share.svg'
import ICON_XMAS from './images/icon-christmas.svg'

const FESTIVALS = {
  XMAS: 'xmas',
  NEW_YEAR: 'new_year'
}

export default {
  name: 'UserControls',
  components: { HighlightElement },
  data () {
    return {
      SETTINGS,
      SoundOnIcon,
      ICON_CART,
      ICON_HEART,
      ICON_SHARE,
      ICON_XMAS,
      isCartScale: false,
      isLikeScale: false,
      isSeasonalScale: false
    }
  },

  computed: {
    ...mapGetters('LiveSetting', ['newSettings', 'hoverElements']),
    topPosition () {
      let btnCount = 2
      if (this.newSettings[SETTINGS.HEART_ANIMATION]) btnCount += 1
      if (this.newSettings[SETTINGS.CART_SHOW]) btnCount += 1
      btnCount += this.newSettings[SETTINGS.FESTIVAL_ANIMATION]
        ? this.newSettings[SETTINGS.FESTIVAL_ANIMATION].length
        : 0
      const topOffset = 450
      const buttonHeight = 54
      return topOffset - ((btnCount - 1) * buttonHeight) + 'px'
    },

    showCart () {
      return this.newSettings[SETTINGS.CART_SHOW]
    },

    enabledLivewebHeartAnimation () {
      return this.newSettings[SETTINGS.HEART_ANIMATION]
    },

    festivalIcons () {
      return {
        [FESTIVALS.XMAS]: ICON_XMAS
      }
    }
  },

  methods: {
    handleLikeAnimation () {
      this.triggerAnimation(LIKE)

      this.isLikeScale = true
      setTimeout(() => {
        this.isLikeScale = false
      }, 100)
    },

    handleSeasonalAnimation (type) {
      this.triggerAnimation(type)

      this.isSeasonalScale = true
      setTimeout(() => {
        this.isSeasonalScale = false
      }, 100)
    },

    triggerAnimation (type) {
      const topOffset = 30
      // 123px => 123
      const yPosition = Number(this.topPosition.slice(0, -2)) - topOffset
      this.$emit('triggerAnimation', type, yPosition)
    }
  }

}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
