<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Products',
  props: {
    products: {
      type: Array,
      default: () => []
    },
    visiblePickProduct: {
      type: Boolean,
      default: false
    },
    selectProducts: {
      type: Array,
      default: () => []
    },
    canPickProduct: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentPage: 1,
      paginator: null,
      expandedRow: []
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol']),
    isSelectAll () {
      return this.products.length !== 0 && this.products.length === this.selectProducts.length
    }
  },
  methods: {
    getImgUrl (data) {
      const imgUrl = data.images && data.images.length && data.images[0].url

      return imgUrl || require('@/assets/icon-default-product.png')
    },
    isExpandRow (id) {
      return this.expandedRow.some(item => item === id)
    },
    handleExpandRow (id) {
      const idx = this.expandedRow.findIndex(item => item === id)

      if (idx === -1) {
        this.expandedRow.push(id)
      } else {
        this.expandedRow.splice(idx, 1)
      }
    },
    isChecked (product) {
      return this.selectProducts.some(item => item.id === product.id)
    },
    handleToggleAll () {
      this.$emit('handleToggleAll')
    },
    handleToggleSelect (prodcut) {
      this.$emit('handleToggleSelect', prodcut)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
