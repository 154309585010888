/* eslint-disable camelcase */
import _ from 'lodash'
import dateFormat from 'date-fns/format'
import store from '@/store'

import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import * as PAYMENT_STATUS from '@/constants/paymentStatus'

import UserIcon from '@/assets/icon-default-user-md.png'

function trans (key) {
  let currentLocale = store.getters['Lang/locale']
  let langFile = require(`@/lang/${currentLocale}.json`)
  return _.get(langFile, key)
}

function isOrdererGuest (orderItem) {
  const { seller_id } = orderItem.manual_order || {}
  return seller_id === orderItem.buyer_id
}

function getBuyerName (orderItem) {
  const { name: manualOrderName = '' } = orderItem.manual_order || {}
  const { name: buyerName = '' } = orderItem.buyer || {}
  return isOrdererGuest(orderItem) ? manualOrderName : buyerName
}

const strategies = {
  cart: cart => {
    // @todo 優化複雜度

    const id = cart.id
    const messageStatus = 'empty'
    const createTime = dateFormat(cart.created_at, 'YYYY-MM-DD HH:mm')
    const slug = null
    const source = null
    const buyerAvatar = (cart.buyer && cart.buyer.avatar_url) || UserIcon
    const buyerName = (cart.buyer && cart.buyer.name) || cart.source_name
    const buyerEmail = cart.buyer && cart.buyer.email
    const providerType = cart.provider && cart.provider.type
    const providerName = cart.provider && cart.provider.name
    const completeType = cart.complete_type

    const forcedReturn = null

    const amount = Math.floor(
      cart.items.reduce((prev, curr) => {
        return prev + curr.price * curr.quantity
      }, 0)
    )
    const amountPayable = amount
    const coupon = null
    const shippingFee = 0
    const totalAmount = amount
    const usedPoint = 0

    // 付款方式
    const paymentType = null
    const paymentTypeName = trans(`payment.type.${paymentType || 'undefined'}`)
    // 付款狀態
    const paymentStatus = null
    const paymentStatusName = trans(
      `payment.status.${paymentStatus || 'undefined'}`
    )

    // 收件資訊
    const receiverAddress = null
    const receiverName = null
    const receiverPhone = null
    const shippingInfo = null

    // 運送方式
    const shippingType = null
    const shippingTypeName = trans(
      `shipping.type.${shippingType || 'undefined'}`
    )
    // 運送狀態
    const shippingStatus = null
    const shippingStatusName = trans(
      `shipping.status.${shippingStatus || 'undefined'}`
    )

    const shippingId = null
    const shippingDeliveryAt = null
    const shippingExpiredAt = null
    const shippingSourceId = null
    const shippingIsExpired = null
    const shippingLogs = []

    // 訂單狀態
    const status = 'confirming'
    const statusName = trans(`order.status.${status || 'undefined'}`)

    // 取消原因
    const reason = cart.reason

    // 退貨原因
    const returnDate = null
    const returnReason = null
    const refundMemo = null

    const memo = null

    let notification
    if (cart) {
      notification = {
        canNotify: true,
        isSent: cart.cart_notifications && cart.cart_notifications.length > 0,
        sentAt:
          cart.cart_notifications &&
          cart.cart_notifications[0] &&
          dateFormat(cart.cart_notifications[0].created_at, 'YYYY-MM-DD')
      }
    }

    return {
      id,
      messageStatus,
      createTime,
      slug,
      source,
      buyerAvatar,
      buyerName,
      buyerEmail,
      providerType,
      providerName,
      completeType,
      forcedReturn,
      amount,
      amountPayable,
      coupon,
      shippingFee,
      totalAmount,
      usedPoint,
      receiverAddress,
      receiverName,
      receiverPhone,
      shippingInfo,
      paymentType,
      paymentTypeName,
      paymentStatus,
      paymentStatusName,
      shippingType,
      shippingTypeName,
      shippingStatus,
      shippingStatusName,
      shippingId,
      shippingDeliveryAt,
      shippingExpiredAt,
      shippingSourceId,
      shippingIsExpired,
      shippingLogs,
      status,
      statusName,
      reason,
      returnDate,
      returnReason,
      refundMemo,
      memo,
      notification
    }
  },
  order: item => {
    // @todo 優化複雜度
    const id = item.id
    const messageStatus = item.seller_message_status
    const createTime = dateFormat(item.created_at, 'YYYY-MM-DD HH:mm')
    const slug = item.slug
    const source = null
    const buyerAvatar = item.buyer && item.buyer.avatar_url
    const buyerName = getBuyerName(item)
    const providerType = item.provider && item.provider.type
    const providerName = item.provider && item.provider.name
    const completeType = item.complete_type
    const age = item.age

    const forcedReturn = item.forced_return
    let notification
    if (item.order_notifications) {
      notification = {
        canNotify: !!item.buyer,
        isSent: item.order_notifications.length > 0,
        sentAt:
          item.order_notifications[0] &&
          dateFormat(item.order_notifications[0].created_at, 'YYYY-MM-DD'),
        pageId:
          item.buyer &&
          item.buyer.facebook_mappings &&
          item.buyer.facebook_mappings[0] &&
          item.buyer.facebook_mappings[0].page_id
      }
    }

    // 金額資訊
    const amount = item.amount
    const amountPayable = item.amount_payable
    const coupon = item.coupon
    const shippingFee = item.shipping_fee
    const totalAmount = item.total_amount
    const usedPoint = item.used_point
    const overpayment = item.overpayment

    // 付款方式
    const paymentType = item.payment_type
    const paymentTypeName = trans(`payment.type.${paymentType || 'undefined'}`)

    // 付款狀態
    // 當 payments 小於 1筆 或 最終付款狀態為 Paid ，則依目前的 paymentStatusName 顯示，
    // 否則為 多筆付款 且 尚未付清 => 部分未付款
    const paymentStatus = item.payment_status
    let paymentStatusName = ''
    const payments = _.get(item, 'payments', [])

    // 當同時存在有 已付款 和 非已付款時，代表 部份未付款
    const isPartUnpaid =
      payments.some(payment => payment.status === PAYMENT_STATUS.PAID) &&
      payments.some(payment => payment.status !== PAYMENT_STATUS.PAID)

    if (isPartUnpaid) {
      paymentStatusName = trans('payment.status.part_unpaid')
    } else {
      paymentStatusName = trans(`payment.status.${paymentStatus || 'undefined'}`)
    }

    const paymentLogs = item.payment && item.payment.logs
    const paymentAccount = item.payment && item.payment.account
    const paymentExpireAt = item.payment && item.payment.expire_at
    const paymentInfo = item.payment && item.payment.info
    const bankReceipt = item.payment && item.payment.bank_receipt
    const gmoDeferredStatus = _.get(item, 'payment.info.result')

    // 收件資訊
    const receiverAddress = item.shipping && item.shipping.receiver_address
    const receiverName = item.shipping && item.shipping.receiver_name
    const receiverPhone = item.shipping && item.shipping.receiver_phone
    const shippingInfo = item.shipping && item.shipping.info
    // 出貨地址
    const senderAddress = item.shipping && item.shipping.sender_address

    // 運送方式
    const shippingType = item.shipping_type
    const shippingSubType = item.shipping && item.shipping.sub_type
    const shippingTypeName = trans(
      `shipping.type.${shippingType || 'undefined'}`
    )
    // 運送狀態
    const shippingStatus = item.shipping_status
    let renameShippingStatus = shippingStatus

    // 兩種自取的'待出貨'改成'備貨中'
    const selfPickupTypes = [SHIPPING_TYPES.SELF_PICK_UP, SHIPPING_TYPES.CUSTOM_SELF_PICK_UP]
    if (selfPickupTypes.includes(shippingType) && shippingStatus === 'init') {
      renameShippingStatus = 'init_self_pickup'
    }
    const shippingStatusName = trans(
      `shipping.status.${renameShippingStatus || 'undefined'}`
    )

    const buyerEmail = item.shipping && item.shipping.order.email

    const shippingId = item.shipping && item.shipping.id
    const shippingDeliveryAt =
      item.shipping && item.shipping.delivery_at
        ? dateFormat(item.shipping.delivery_at, 'YYYY-MM-DD')
        : ''
    const shippingExpiredAt =
      item.shipping && item.shipping.expired_at
        ? dateFormat(item.shipping.expired_at, 'YYYY-MM-DD HH:mm')
        : ''

    const shippingSourceId =
      item.shipping &&
      (item.shipping.display_source_id || item.shipping.source_id)

    const shippingIsExpired = item.shipping && item.shipping.is_expired
    const shippingIsCanceled =
      item.shipping && item.shipping.status === 'canceled'

    const shippingLogs = item.shipping && item.shipping.shipping_logs

    // 訂單狀態
    const status = item.status
    const statusName = trans(`order.status.${status || 'undefined'}`)

    // 取消原因
    const reason = item.reason

    // 退貨原因
    const returnDate =
      item.return && item.return.created_at
        ? dateFormat(item.return.created_at, 'YYYY-MM-DD HH:mm')
        : ''
    const returnReason = item.return && item.return.reason
    const refundMemo = item.info && item.info.refund_memo

    const memo = item.memo

    return {
      id,
      messageStatus,
      createTime,
      slug,
      source,
      buyerAvatar,
      buyerName,
      buyerEmail,
      providerType,
      providerName,
      completeType,
      forcedReturn,
      notification,
      amount,
      amountPayable,
      coupon,
      shippingFee,
      totalAmount,
      usedPoint,
      receiverAddress,
      receiverName,
      receiverPhone,
      senderAddress,
      shippingInfo,
      paymentType,
      paymentTypeName,
      paymentStatus,
      paymentStatusName,
      paymentLogs,
      paymentAccount,
      paymentExpireAt,
      paymentInfo,
      shippingType,
      shippingSubType,
      shippingTypeName,
      shippingStatus,
      shippingStatusName,
      shippingId,
      shippingDeliveryAt,
      shippingExpiredAt,
      shippingSourceId,
      shippingIsExpired,
      shippingIsCanceled,
      shippingLogs,
      status,
      statusName,
      reason,
      returnDate,
      returnReason,
      refundMemo,
      memo,
      bankReceipt,
      overpayment,
      gmoDeferredStatus,
      age,
      isManualAndGuest: isOrdererGuest(item)
    }
  }
}

export default (type, data) => strategies[type](data)
