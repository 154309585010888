<script>
import { mapGetters, mapActions } from 'vuex'
import * as SETTINGS from '@/constants/storeSettings'
import * as PURCHASE_WORD from '@/constants/livewebPurchaseButton'
import AppAlert from '@/components/AppAlert'
import SettingToggle from './components/SettingToggle'
import FestivalToggles from './components/FestivalToggles'
import SettingRadio from './components/SettingRadio'

export default {
  name: 'TogglePanel',
  components: { AppAlert, SettingToggle, FestivalToggles, SettingRadio },
  data () {
    return {
      ...SETTINGS,
      purchaseWordOptions: [
        { label: this.$t('Live.buy_now'), value: PURCHASE_WORD.PURCHASE },
        { label: this.$t('Live.to_product_page'), value: PURCHASE_WORD.PRODUCT_PAGE }
      ]
    }
  },
  computed: {
    ...mapGetters('LiveSetting', ['newSettings']),
    salesModuleDisabled () {
      return !this.newSettings[SETTINGS.PRODUCT_LIST_SHOW]
    }
  },
  mounted () {
    this.getCurrentSetting()
  },
  methods: {
    ...mapActions('LiveSetting', ['getCurrentSetting', 'updateNewSetting']),
    updateSalesModule (value) {
      // 銷售模組綁定了：銷售中商品圖，加入購物車訊息，下方商品列表，橘色購物車，關鍵字分析
      this.updateNewSetting({ setting: SETTINGS.SALES_PRODUCT_SHOW, value })
      this.updateNewSetting({ setting: SETTINGS.ADD_CART_NOTICE, value })
      this.updateNewSetting({ setting: SETTINGS.PRODUCT_LIST_SHOW, value })
      this.updateNewSetting({ setting: SETTINGS.CART_SHOW, value })
      this.updateNewSetting({ setting: SETTINGS.COMMENT_ANALYSIS, value })
    },
    scrollTop () {
      const scrollOptions = {
        container: '#inner-container'
      }
      this.$scrollTo('#phone-top', 300, scrollOptions)
    },
    scrollBottom () {
      const scrollOptions = {
        container: '#inner-container'
      }
      this.$scrollTo('#phone-bottom', 300, scrollOptions)
    }
  }
}
</script>

<template lang="pug" src="./TogglePanel.pug"></template>
<style lang="scss" src="./TogglePanel.scss" scoped></style>
