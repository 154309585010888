<script>
import VueTypes from 'vue-types'

export default {
  name: 'GmoCvsStatus',
  props: {
    order: VueTypes.object.isRequired,
    paymentUnpaid: VueTypes.bool.isRequired.def(false),
    isCvsExpired: VueTypes.bool.isRequired.def(false)
  }
}
</script>

<template lang="pug" src="./GmoCvsStatus.pug"></template>
<style lang="scss" src="./GmoCvsStatus.scss" scoped></style>
