<script>
export default {
  name: 'DialogCartSetting',
  props: {
    canEditCart: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      dataCanEditCart: null
    }
  },
  created () {
    this.dataCanEditCart = this.canEditCart
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('submit', this.dataCanEditCart)
    }
  }
}
</script>

<template lang="pug" src="./DialogCartSetting.pug"></template>
<style lang="scss" src="./DialogCartSetting.scss" scoped></style>
