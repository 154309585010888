import * as loadingState from '@/constants/loadingState'
import { getCelebrityTags } from '../apis'

export const actionTypes = {
  FETCH_TAGS: 'FETCH_TAGS',
  FETCH_TAGS_SUCCESS: 'FETCH_TAGS_SUCCESS',
  FETCH_TAGS_FAILED: 'FETCH_TAGS_FAILED'
}

const state = {
  celebrityTags: [],
  loadingState: loadingState.EMPTY,
  error: null
}

const getters = {
  getCelebrityTags: state => state.celebrityTags,
  getCelebrityTagsLoadingState: state => state.loadingState
}

const mutations = {
  [actionTypes.FETCH_TAGS] (state) {
    state.loadingState = loadingState.LOADING
    state.error = null
  },

  [actionTypes.FETCH_TAGS_SUCCESS] (state, tags) {
    state.loadingState = loadingState.READY
    state.celebrityTags = tags
  },

  [actionTypes.FETCH_TAGS_FAILED] (state, { err }) {
    state.loadingState = loadingState.ERROR
    state.error = err
    state.celebrityTags = []
  }
}

const actions = {
  getCelebrityTags ({ commit }) {
    commit(actionTypes.FETCH_TAGS)

    return getCelebrityTags()
      .then(data => {
        const { data: tags } = data
        commit(actionTypes.FETCH_TAGS_SUCCESS, tags)
      })
      .catch(err => commit(actionTypes.FETCH_TAGS_FAILED, { err }))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
