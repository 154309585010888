import _ from 'lodash'
import ApiShippingSetting from '@/api/shippingSetting'
import ApiPaymentSetting from '@/api/paymentSetting'

const state = {
  paymentSettings: [],
  shippingSettings: [],

  paymentVerified: false,
  shipmentVerified: false,
  paymentShipping: false,
  hasChecked: false // 是否曾經完成 check
}

const getters = {
  paymentSettings: state => state.paymentSettings,
  shippingSettings: state => state.shippingSettings,

  paymentShippingChecked: state => state.paymentShipping,

  sellerChecked (state, getters, rootState, rootGetters) {
    if (!rootGetters['Me/currentStore']) return false

    // 如果不是 owner 直接跳過驗證判斷
    if (!rootGetters['Me/isCurrentStoreOwner']) {
      return true
    }
    return !!rootGetters['Me/currentStore'].store_registrant.phone_verified_at
  },

  checkedList (state, getters) {
    return {
      seller: getters.sellerChecked,
      paymentShipping: state.paymentShipping
    }
  },

  isAllChecked (_, getters) {
    return getters.paymentShippingChecked && getters.sellerChecked
  },

  hasPaymentVerified (state) {
    return state.paymentVerified
  },

  hasShipmentVerified (state) {
    return state.shipmentVerified
  }
}

const actionTypes = {
  UPDATE_PAYMENT_SETTING: 'UPDATE_PAYMENT_SETTING',
  UPDATE_SHIPPING_SETTING: 'UPDATE_SHIPPING_SETTING',

  UPDATE_SHIPPING_VERIFIED_RESULT: 'UPDATE_SHIPPING_VERIFIED_RESULT',
  UPDATE_PAYMENT_VERIFIED_RESULT: 'UPDATE_PAYMENT_VERIFIED_RESULT',
  UPDATE_PAYMENT_SHIPPING: 'UPDATE_PAYMENT_SHIPPING'
}

const mutations = {
  [actionTypes.UPDATE_PAYMENT_SETTING] (state, payload) {
    state.paymentSettings = payload
  },
  [actionTypes.UPDATE_SHIPPING_SETTING] (state, payload) {
    state.shippingSettings = payload
  },

  [actionTypes.UPDATE_PAYMENT_SHIPPING] (state, isChecked) {
    state.paymentShipping = isChecked
  },

  [actionTypes.UPDATE_PAYMENT_VERIFIED_RESULT] (state, hasVerified) {
    state.paymentVerified = hasVerified
  },

  [actionTypes.UPDATE_SHIPPING_VERIFIED_RESULT] (state, hasVerified) {
    state.shipmentVerified = hasVerified
  }
}

const actions = {
  async fetchPaymentSetting ({ commit }) {
    const apiPaymentSetting = new ApiPaymentSetting()
    const paymentSettings = await apiPaymentSetting.list({}, 'all')

    commit(actionTypes.UPDATE_PAYMENT_SETTING, paymentSettings)

    return paymentSettings
  },

  async fetchShippingSetting ({ commit }) {
    const apiShippingSetting = new ApiShippingSetting()
    const shippingSettings = await apiShippingSetting.list({}, 'all')

    commit(actionTypes.UPDATE_SHIPPING_SETTING, shippingSettings)

    return shippingSettings
  },

  async checkPaymentShipping ({ commit, dispatch, rootGetters }) {
    if (rootGetters['Me/currentStore']) {
      const [paymentSettings, shippingSettings] = await Promise.all([
        dispatch('fetchPaymentSetting'),
        dispatch('fetchShippingSetting')
      ])

      // 檢查 付款設定
      const paymentChecked =
        _.filter(paymentSettings, { enabled: true }).length > 0 ||
        shippingSettings.some(
          setting => setting.enabled && setting.is_cash_on_delivery
        )

      commit(actionTypes.UPDATE_PAYMENT_VERIFIED_RESULT, paymentChecked)

      // 檢查 運送方式
      const shippingChecked =
        _.filter(shippingSettings, { enabled: true }).length > 0

      commit(actionTypes.UPDATE_SHIPPING_VERIFIED_RESULT, shippingChecked)

      // 是否皆完成設定
      commit(
        actionTypes.UPDATE_PAYMENT_SHIPPING,
        paymentChecked && shippingChecked
      )
    } else {
      commit(actionTypes.UPDATE_PAYMENT_SHIPPING, false)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
