<script>
import { mapActions, mapGetters } from 'vuex'
import AppSelectList from '@/components/AppSelectList'
import AppSelectListItem from '@/components/AppSelectListItem'
import { LOADING, READY } from '@/constants/loadingState'

import imgCry from './images/icon-heart-cry-gray@2x.png'

export default {
  name: 'Step3',
  components: {
    AppSelectList,
    AppSelectListItem
  },

  data: () => ({
    LOADING,
    imgCry,
    selectedId: null
  }),

  computed: {
    ...mapGetters('RegisterInfluencer', {
      channels: 'getChannels',
      channelsLoadingState: 'getChannelsLoadingState',
      hasBoundChannel: 'hasBoundChannel',
      bindChannelLoadingState: 'bindChannelLoadingState'
    }),
    ...mapGetters('Me', ['storeName']),

    showDoneButton () {
      if (!this.channels.length) return true
      if (this.selectedId || this.hasBoundChannel) return true

      return false
    }
  },

  created () {
    this.fetchStores()
      .then(() => this.fetchCurrentStore())
      .then(() => {
        this.fetchBoundChannels(this.storeName)
        this.fetchFbChannels(this.storeName)
      })
  },

  methods: {
    ...mapActions('Me', [
      'fetchStores',
      'fetchCurrentStore'
    ]),
    ...mapActions('RegisterInfluencer', [
      'fetchFbChannels',
      'fetchBoundChannels',
      'bindFbChannel'
    ]),
    handleSelect (channel) {
      // 如果已經有綁定過的粉專就不能再綁別的粉專
      // 因為如果網紅身份綁了多個粉專，會影響到賣家身份的 plan 可以綁定的數量
      if (this.hasBoundChannel) return
      this.selectedId = channel.id
    },

    handleNextStep () {
      if (this.selectedId) {
        this.bindFbChannel({
          storeId: this.storeName,
          payload: {
            id: this.selectedId,
            type: 'facebook-page'
          }
        })
          .then(() => {
            if (this.bindChannelLoadingState === READY) {
              this.handleDone()
            }
          })
        return
      }
      this.handleDone()
    },

    handleDone () {
      // [Facebook Pixel] 追蹤填寫完商店資料並成功建立商店，完成註冊事件
      this.$analytics.fbq.event('I-Register-Complete', {
        Step: 'Complete-Form',
        Page: 'Influencer-Platform'
      })

      // [Google Analytics] 追蹤填寫完商店資料並成功建立商店，完成註冊事件
      this.$ga.event({
        eventCategory: 'I-Register-Complete',
        eventAction: 'Complete-Form',
        eventLabel: 'Influencer-Platform'
      })
      this.$emit('done')
    }
  }
}
</script>

<template lang="pug" src="./Step3.pug"></template>
<style lang="scss" src="./Step3.scss" scoped></style>
