import { render, staticRenderFns } from "./AppXBox.pug?vue&type=template&id=3be3e0a0&scoped=true&lang=pug&"
import script from "./AppXBox.vue?vue&type=script&lang=js&"
export * from "./AppXBox.vue?vue&type=script&lang=js&"
import style0 from "./AppXBox.scss?vue&type=style&index=0&id=3be3e0a0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be3e0a0",
  null
  
)

export default component.exports