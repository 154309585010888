import * as loadingState from '@/constants/loadingState'
import ApiCoupon from '@/api/coupon'

export const actionTypes = {
  UPDATE_COUPON: 'UPDATE_COUPON',
  UPDATE_COUPON_SUCCESS: 'UPDATE_COUPON_SUCCESS',
  UPDATE_COUPON_FAILED: 'UPDATE_COUPON_FAILED'
}

const state = {
  loadingState: loadingState.EMPTY,
  error: null
}

const getters = {
  getUpdateTaskLoadingState: state => state.loadingState
}

const mutations = {
  [actionTypes.UPDATE_COUPON] (state) {
    state.loadingState = loadingState.LOADING
    state.error = null
  },

  [actionTypes.UPDATE_COUPON_SUCCESS] (state) {
    state.loadingState = loadingState.READY
  },

  [actionTypes.UPDATE_COUPON_FAILED] (state) {
    state.loadingState = loadingState.ERROR
    // state.error = err
  }
}

const actions = {
  async updateCoupon ({ commit }, { id, updateData }) {
    commit('SET_LOADING', true, { root: true })

    const apiCoupon = new ApiCoupon()

    const data = await apiCoupon.update(id, updateData)

    if (data) {
      commit(actionTypes.UPDATE_COUPON_SUCCESS)
    } else {
      commit(actionTypes.UPDATE_COUPON_FAILED)
    }

    commit('SET_LOADING', false, { root: true })

    return !!data
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
