<script>
import VueTypes from 'vue-types'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'

export default {
  name: 'InfoContentDirect',
  props: {
    undeliveredTotal: VueTypes.number,
    shippingType: VueTypes.string
  },
  data () {
    return {
      homeType: 'direct'
    }
  },
  computed: {
    isSelfPickup () {
      const types = [SHIPPING_TYPES.SELF_PICK_UP, SHIPPING_TYPES.CUSTOM_SELF_PICK_UP]
      return types.includes(this.shippingType)
    }
  },
  methods: {
    importShipping (file) {
      this.$emit('importShipping', file)
    }

  }
}
</script>

<template lang="pug" src="./InfoContentDirect.pug"></template>
<style lang="scss" src="./InfoContentDirect.scss" scoped></style>
