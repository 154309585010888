<script>
import VueTypes from 'vue-types'

import * as SHIPPING_TYPES from '@/constants/shippingTypes'

import AppAlert from '@/components/AppAlert'

import FormHomeDelivery from './components/FormHomeDelivery'
import FormHsinchu from './components/FormHsinchu'
import FormFamily from './components/FormFamily'
import FormSeven from './components/FormSeven'
import FormSelfPickUp from './components/FormSelfPickUp'

const optionShippingTypes = [
  SHIPPING_TYPES.HOME_DELIVERY,
  SHIPPING_TYPES.HSINCHU,
  SHIPPING_TYPES.FAMILY,
  SHIPPING_TYPES.SEVEN,
  SHIPPING_TYPES.SELF_PICK_UP
]

const SHIPPING_PAYLOAD = {
  [SHIPPING_TYPES.HOME_DELIVERY]: ['receiver_name', 'receiver_phone', 'receiver_address'],
  [SHIPPING_TYPES.HSINCHU]: ['receiver_name', 'receiver_phone', 'receiver_address', 'info'],
  [SHIPPING_TYPES.FAMILY]: ['receiver_name', 'receiver_phone', 'receiver_address', 'info'],
  [SHIPPING_TYPES.SEVEN]: ['receiver_name', 'receiver_phone', 'receiver_address', 'info'],
  [SHIPPING_TYPES.SELF_PICK_UP]: ['receiver_name', 'receiver_phone', 'receiver_address']
}

export default {
  name: 'DialogEditShipping',
  components: {
    AppAlert,
    FormHomeDelivery,
    FormHsinchu,
    FormFamily,
    FormSeven,
    FormSelfPickUp
  },
  props: {
    region: VueTypes.string.isRequired,
    shipping: VueTypes.object.def({}),
    enabledShippings: VueTypes.array.def([])
  },
  data () {
    return {
      form: {
        shipping_type: ''
      }
    }
  },
  computed: {
    optionShippingTypes () {
      // 過濾出有 enabled 的運送方式
      return optionShippingTypes.filter(item => {
        return this.enabledShippings.some(t => t === item)
      })
    },

    displayForm () {
      const formMapping = {
        [SHIPPING_TYPES.HOME_DELIVERY]: 'FormHomeDelivery',
        [SHIPPING_TYPES.HSINCHU]: 'FormHsinchu',
        [SHIPPING_TYPES.FAMILY]: 'FormFamily',
        [SHIPPING_TYPES.SEVEN]: 'FormSeven',
        [SHIPPING_TYPES.SELF_PICK_UP]: 'FormSelfPickUp'
      }

      return formMapping[this.form.shipping_type]
    },

    isChangeShipping () {
      return this.shipping.type !== this.form.shipping_type
    }

  },

  created () {
    this.initData()
  },

  beforeDestroy () {
    localStorage.removeItem('orderEditShipping')
  },

  methods: {
    initData () {
      let formData = { shipping_type: this.shipping.type }

      SHIPPING_PAYLOAD[this.shipping.type].forEach(key => {
        formData[key] = this.shipping[key]
      })

      // 從 7-11 或 全家 選擇門市導回後，更新 出貨方式
      const orderEditShipping = localStorage.getItem('orderEditShipping')
      if (orderEditShipping && location.search) {
        const {
          type,
          'receiver_name': name,
          'receiver_phone': phone
        } = JSON.parse(orderEditShipping)

        formData.shipping_type = type
        formData.receiver_name = name
        formData.receiver_phone = phone
      }

      this.$set(this, 'form', formData)
    },

    updateForm (data) {
      const formData = Object.assign({ shipping_type: this.form.shipping_type }, data)
      this.$set(this, 'form', formData)
    },

    async handleSubmit () {
      const isValidateForm = await this.$refs.form.validateForm()

      if (isValidateForm) {
        this.$emit('handleUpdateShipping', this.form)
      }
    },

    handleClose () {
      this.$emit('close')
    }
  }
}
</script>

<template lang="pug" src="./DialogEditShipping.pug"></template>
