<script>
import VueTypes from 'vue-types'
import MobileDetect from 'mobile-detect'
import SimpleDialog from '@/components/SimpleDialog'
import { MessageBox } from 'element-ui'

import Qrocde from './components/Qrcode'
import imgDone from './images/img-app@2x.png'

const md = new MobileDetect(navigator.userAgent)

export default {
  name: 'Done',
  components: {
    SimpleDialog
  },
  props: {
    name: VueTypes.object.def({})
  },

  data: () => ({
    imgDone
  }),

  computed: {
    fullName () {
      return `${this.name.last} ${this.name.first}`
    }
  },

  methods: {
    handleDownload () {
      // [Facebook Pixel] 追蹤填寫完商店資料並成功建立商店，完成註冊事件
      this.$analytics.fbq.event('I-Register-AppDownload', {
        Step: 'AppDownload',
        Page: 'Influencer-Platform'
      })

      // [Google Analytics] 追蹤填寫完商店資料並成功建立商店，完成註冊事件
      this.$ga.event({
        eventCategory: 'I-Register-AppDownload',
        eventAction: 'AppDownload',
        eventLabel: 'Influencer-Platform'
      })

      if (md.mobile()) {
        const appDeepLink = `${process.env.VUE_APP_APP_DOMAIN_NAME}/download`
        location.href = appDeepLink
        return
      }

      const h = this.$createElement
      MessageBox.confirm(
        Qrocde.render(h), '', {
          customClass: 'dialog-qrcode',
          dangerouslyUseHTMLString: true,
          center: true,
          showCancelButton: false,
          showConfirmButton: false
        })
    }
  }
}
</script>

<template lang="pug" src="./Done.pug"></template>
<style lang="scss" src="./Done.scss" scoped></style>

<style lang="scss">
.dialog-qrcode {
  width: 360px !important;
}
</style>
