<script>
import VueTypes from 'vue-types'

import { emailRule } from '@/utils/formRules'

import { updateMe, sendVerification } from '@/views/User/service/apis'

export default {
  name: 'DialogConfirmEmail',

  props: {
    userId: VueTypes.integer.isRequired,
    email: VueTypes.string.def('')
  },

  data () {
    return {
      isLoading: false,
      ruleForm: {
        email: ''
      },
      rules: {
        email: emailRule()
      }
    }
  },

  created () {
    this.ruleForm.email = this.email
  },

  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },

    async handleSubmit () {
      try {
        this.isLoading = true
        await this.$refs.ruleForm.validate()

        // 更新 Email
        await updateMe({
          'email': this.ruleForm.email
        })
        // 寄驗證信
        await sendVerification(this.userId)
        this.$message.success(this.$t('User.EmailVerify.send_success'))

        this.$emit('refreshMe')
        this.handleCloseDialog()
      } catch (err) {
        this.isLoading = false
        console.error(err)
      }
    }

  }
}
</script>

<template lang="pug" src="./DialogConfirmEmail.pug"></template>
