<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

import * as loadingState from '@/constants/loadingState'

export default {
  name: 'DialogCompleteOrderManually',

  props: {
    orderStatus: VueTypes.string.isRequired
  },

  data () {
    return {
      form: {
        // 如果有給後端 completed_at 的話，代表是手動完成訂單，後端會將 complete_type 設定為 "manual"
        completed_at: new Date(),
        complete_memo: ''
      }
    }
  },

  computed: {
    ...mapGetters('OrderDetail', ['orderCompleteLoadingState']),

    isLoading () {
      return this.orderCompleteLoadingState === loadingState.LOADING
    }
  },

  methods: {
    async handleSubmit () {
      this.$emit('submit', this.form)
    },
    close () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogCompleteOrderManually.pug"></template>
