
import * as loadingState from '@/constants/loadingState'

import { putBankReceipts, putOfflineAtmReview } from '../apis/order'

export const types = {
  UPDATE_BANK_RECEIPT: 'UPDATE_BANK_RECEIPT',
  UPDATE_BANK_RECEIPT_SUCCESS: 'UPDATE_BANK_RECEIPT_SUCCESS',
  UPDATE_BANK_RECEIPT_FAILED: 'UPDATE_BANK_RECEIPT_FAILED'
}

const INIT_DATA = {
  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  dialogOfflineAtmVisible: state => state.dialogOfflineAtmVisible,
  offlineAtmLoadingState: state => state.loadingState
}

const mutations = {
  [types.UPDATE_BANK_RECEIPT] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.UPDATE_BANK_RECEIPT_SUCCESS] (state, payload) {
    state.loadingState = loadingState.READY
  },

  [types.UPDATE_BANK_RECEIPT_FAILED] (state, error) {
    state.loadingState = loadingState.ERROR
    state.error = error

    console.error(error)
  }

}

const actions = {
  updateBankReceipt ({ commit }, { storeName, orderId, payload }) {
    const reviewPayload = {
      order_ids: [orderId],
      payment_status: payload.paymentStatus
    }

    return Promise.all([
      putBankReceipts(orderId, payload.bankReceipt),
      putOfflineAtmReview(storeName, reviewPayload)
    ])
      .then(response => commit(types.UPDATE_BANK_RECEIPT_SUCCESS, response))
      .catch(err => {
        commit(types.UPDATE_BANK_RECEIPT_FAILED, err)
        throw err
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
