<script>
import VueTypes from 'vue-types'

import { currency } from '@/filters'
import { mapGetters, mapActions } from 'vuex'
import ApiGhn from '@/api/ghn'

// Limits from GHN API
const MINIMUM = 1
const MAXIMUM_LENGTH = 100
const MAXIMUM_WEIGHT = 200000

export default {
  name: 'DialogShipmentGhn',
  props: {
    ghnInfo: VueTypes.object.isRequired,
    productAmount: VueTypes.number.def(0),
    amountPayable: VueTypes.number.def(0),
    shippingFee: VueTypes.number.def(0)
  },
  data () {
    return {
      apiGhn: new ApiGhn(),
      ghnForm: {
        width: null,
        height: null,
        length: null,
        weight: null
      },
      newShippingFee: 0,
      serviceName: '',
      rules: {
        width: [
          {
            type: 'number',
            required: true,
            min: MINIMUM,
            max: MAXIMUM_LENGTH,
            message: this.$t('Order.DialogShipmentGhn.number_validate',
              { field: this.$t('product.width'), min: MINIMUM, max: MAXIMUM_LENGTH })
          }
        ],
        height: [
          {
            type: 'number',
            required: true,
            min: MINIMUM,
            max: MAXIMUM_LENGTH,
            message: this.$t('Order.DialogShipmentGhn.number_validate',
              { field: this.$t('product.height'), min: MINIMUM, max: MAXIMUM_LENGTH })
          }
        ],
        length: [
          {
            type: 'number',
            required: true,
            min: MINIMUM,
            max: MAXIMUM_LENGTH,
            message: this.$t('Order.DialogShipmentGhn.number_validate',
              { field: this.$t('product.length'), min: MINIMUM, max: MAXIMUM_LENGTH })
          }
        ],
        weight: [
          {
            type: 'number',
            required: true,
            min: MINIMUM,
            max: MAXIMUM_WEIGHT,
            message: this.$t('Order.DialogShipmentGhn.number_validate',
              { field: this.$t('product.weight'), min: MINIMUM, max: MAXIMUM_WEIGHT })
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol']),
    displayShippingFee () {
      return this.newShippingFee
        ? `${this.currencySymbol} ${currency(this.newShippingFee)}`
        : this.$t('Order.DialogShipmentGhn.calculated')
    }
  },
  async mounted () {
    this.setLoading(true)
    try {
      const serviceTypes = await this.apiGhn.getServiceTypes(this.ghnInfo.from_district_id, this.ghnInfo.to_district_id)
      const service = _.get(serviceTypes, 'data.shipping_services', [])
        .find(service => service.id === this.ghnInfo.service_id)
      this.serviceName = service ? service.name : ''
    } catch (e) {
      console.error(e)
    }
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['setLoading']),
    async fetchShippingFee () {
      this.newShippingFee = 0
      for (const key in this.ghnForm) {
        if (!this.ghnForm[key]) return
      }
      try {
        await this.$refs.ghnForm.validate()
      } catch (e) {
        this.newShippingFee = 0
        return
      }
      const payload = {
        from_district_id: this.ghnInfo.from_district_id,
        to_district_id: this.ghnInfo.to_district_id,
        service_id: this.ghnInfo.service_id,
        weight: this.ghnForm.weight,
        width: this.ghnForm.width,
        length: this.ghnForm.length,
        height: this.ghnForm.height
      }
      this.setLoading(true)
      try {
        const shippingFee = await this.apiGhn.getShippingFee(payload)
        this.newShippingFee = _.get(shippingFee, 'data.shipping_fee', 0)
      } catch (e) {
        this.$message.error(this.$t('Order.DialogShipmentGhn.dimension_msg'))
      }
      this.setLoading(false)
    },
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    async submit () {
      try {
        await this.$refs.ghnForm.validate()
      } catch (e) {
        return
      }
      this.$emit('submit', this.ghnForm)
    }
  }
}
</script>

<template lang="pug" src="./DialogShipmentGhn.pug"></template>
<style lang="scss" src="./DialogShipmentGhn.scss" scoped></style>
