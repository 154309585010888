import * as loadingState from '@/constants/loadingState'

import { updateOrderShipping } from '../apis/order'

export const types = {
  UPDATE_SHIPPING: 'UPDATE_SHIPPING',
  UPDATE_SHIPPING_SUCCESS: 'UPDATE_SHIPPING_SUCCESS',
  UPDATE_SHIPPING_FAILED: 'UPDATE_SHIPPING_FAILED',

  UPDATE_DIALOG_EDIT_SHIPPING: 'UPDATE_DIALOG_EDIT_SHIPPING'
}

const INIT_DATA = {
  dialogEditShippingVisible: false,

  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  dialogEditShippingVisible: state => state.dialogEditShippingVisible,

  shippingUpdateLoadingState: state => state.loadingState
}

const mutations = {
  [types.UPDATE_SHIPPING] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.UPDATE_SHIPPING_SUCCESS] (state, payload) {
    state.loadingState = loadingState.READY
  },

  [types.UPDATE_SHIPPING_FAILED] (state, error) {
    state.loadingState = loadingState.ERROR
    state.error = error

    console.error(error)
  },

  [types.UPDATE_DIALOG_EDIT_SHIPPING] (state, flag) {
    state.dialogEditShippingVisible = flag
  }
}

const actions = {
  updateOrderShipping ({ commit }, { id, putData }) {
    commit(types.UPDATE_SHIPPING)

    return updateOrderShipping(id, putData)
      .then(response => commit(types.UPDATE_SHIPPING_SUCCESS, response))
      .catch(err => {
        commit(types.UPDATE_SHIPPING_FAILED, err)
        throw err
      })
  },

  updateDialogEditShipping ({ commit }, flag) {
    commit(types.UPDATE_DIALOG_EDIT_SHIPPING, flag)
  },

  openDialogEditShipping ({ getters, commit, dispatch }, storeId) {
    return dispatch('fetchShippingInfo', storeId)
      .then(() => {
        dispatch('updateDialogEditShipping', !getters.dialogEditShippingVisible)
      })
  }

}

export default {
  state,
  getters,
  mutations,
  actions
}
