<script>
import img from './images/newfun-01.svg'

export default {
  name: 'DialogNewFeature',
  data () {
    return {
      img,
      hide: false
    }
  },
  created () {
    localStorage.setItem('newFeatureShownDate', new Date().getDate())
  },
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
      if (this.hide) {
        localStorage.setItem('newFeatureShownDate', -1)
      }
    },
    action () {
      this.handleCloseDialog()
      this.$router.push({ name: 'Onboarding' })
    }
  }
}
</script>

<template lang="pug" src="./DialogNewFeature.pug"></template>
<style lang="scss" src="./DialogNewFeature.scss" scoped></style>
