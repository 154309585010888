<script>
import VueTypes from 'vue-types'

export default {
  name: 'DialogRejectApply',
  props: {
    dialogVisible: VueTypes.bool.def(false)
  },
  data () {
    return {
      isBtnLoading: false
    }
  },
  methods: {
    handleSubmit () {
      this.isBtnLoading = true
      this.$emit('completeOrder')
    },
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
