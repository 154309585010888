<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PlanInfo',
  data () {
    return {
      planUrl: process.env.VUE_APP_BUYER_DOMAIN_NAME + '/plans_ja.html'
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore', 'currencySymbol'])
  }
}
</script>

<template lang="pug" src="./PlanInfo.pug"></template>
<style scoped>
button {
  margin: 0
}
</style>
