<script>
import VueTypes from 'vue-types'
import { splitMinutes } from '@/utils/timeOperation'
// import { getMinutes, splitMinutes } from '@/utils/timeOperation'
import ApiPost from '@/api/post'
// import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import dateDifferenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import addDays from 'date-fns/add_days'

export default {
  name: 'DialogSetCheckoutAndReplayTime',
  props: {
    post: VueTypes.object,
    preCartItemLife: VueTypes.number
  },
  data () {
    const validateCheckoutRule = (rule, checkoutRule, callback) => {
      // 無限制
      if (!checkoutRule.hasLimit) {
        callback()
        return
      }

      // if (checkoutRule.day || checkoutRule.hour || checkoutRule.minute) {
      if (checkoutRule) {
        callback()
      } else {
        callback(new Error(this.$t('Post.Show.DialogSetCheckTime.message.limit_error')))
      }
    }
    const validateReplayRule = (rule, replayRules, callback) => {
      // 無限制
      if (!replayRules.hasLimit) {
        callback()
        return
      }

      if (replayRules) {
        callback()
      } else {
        callback(new Error(this.$t('Post.Show.DialogSetCheckTime.message.limit_error')))
      }
    }

    return {
      isBtnLoading: false,
      apiPost: new ApiPost(),
      replayForm: {
        replayDate: {
          hasLimit: !!this.post.replay_expired_at,
          date: this.post.replay_expired_at,
          datePickerOptions: {
            disabledDate (date) {
              // return date > new Date()
              return date > addDays(this.post.replay_expired_at, 10)
            }
          }
        }
      },
      replayRules: {
        replayDate: [
          { validator: validateReplayRule, trigger: 'change' }
        ]
      },
      liveForm: {
        checkoutDate: {
          hasLimit: !!this.post.cart_item_expires_at,
          date: this.post.cart_item_expires_at
        },
        checkoutRule: {
          hasLimit: false,
          day: 3,
          hour: 0,
          minute: 0
        }
      },
      rules: {
        checkoutRule: [
          { validator: validateCheckoutRule, trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    // 限制結帳期限30天內
    isDayUpperLimit () {
      const upperLimitNumber = 30

      return this.liveForm.checkoutRule.day === upperLimitNumber
    },
    // 限制回放期限10天內
    isRepalyDayUpperLimit () {
      const upperLimitNumber = 10
      let dayRange = dateDifferenceInCalendarDays(
        this.replayForm.replayDate.date,
        this.post.replay_expired_at
      )
      return dayRange > upperLimitNumber || dayRange < 1
    }
  },
  created () {
    if (this.preCartItemLife) {
      this.$set(
        this.liveForm,
        'checkoutRule',
        'replayForm',
        { hasLimit: true, ...splitMinutes(this.preCartItemLife) }
      )
    }
  },
  methods: {
    // updateCheckoutDay () {
    //   if (this.isDayUpperLimit) {
    //     this.liveForm.checkoutRule.hour = 0
    //     this.liveForm.checkoutRule.minute = 0
    //   }
    // },

    // 買家結帳期限
    async updateCheckoutDay () {
      // if (this.isDayUpperLimit) return
      let newCheckoutDate = this.liveForm.checkoutDate.date
      let params = { cart_item_expires_at: newCheckoutDate }
      const success = await this.apiPost.updatePost(this.post.id, params)
      if (success) {
        // console.log(success)
      }
    },
    // 直播回放期限
    async updateReplayDate () {
      if (this.isRepalyDayUpperLimit) {
        this.replayForm.replayDate.date = this.post.replay_expired_at
      } else {
        let newReplayDate = this.replayForm.replayDate.date
        let params = { replay_expired_at: newReplayDate }
        const success = await this.apiPost.updatePost(this.post.id, params)
        if (success) {
        // console.log(success)
        }
      }
    },
    submit () {
      // this.$refs['liveForm'].validate((valid) => {
      //   if (!valid) return

      //   this.isBtnLoading = true

      //   // 計算 買家結帳商品自動取消時間
      //   const cartItemLife = this.liveForm.checkoutRule.hasLimit ? this.liveForm.checkoutRule : null
      //   // const cartItemLife = this.liveForm.checkoutRule.hasLimit
      //   //   ? getMinutes(this.liveForm.checkoutRule)
      //   //   : null
      //   console.log('cartItemLife: ' + cartItemLife)

      //   this.$emit('handleUpdateCartItemLife', cartItemLife)
      // })

      this.updateCheckoutDay()
      this.updateReplayDate()
      // this.$emit('handleUpdateCartItemLife', cartItemLife)
    },
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
