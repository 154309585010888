import ApiMember from '@/api/member'

const state = {
  blacklist: []
}

const getters = {
  blacklist: state => state.blacklist
}

const mutations = {
  UPDATE_BLACKLISTS (state, payload) {
    state.blacklist = payload
  }
}

const actions = {
  async fetchBlacklist ({ commit }, storeId) {
    const apiMember = new ApiMember()

    return apiMember.getBlacklist(storeId)
      .then(response => {
        const { data } = response.data
        commit('UPDATE_BLACKLISTS', data)
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
