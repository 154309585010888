import BaseApi from './baseApi'
import axios from '@/plugins/http.js'

export default class Payment extends BaseApi {
  uri = `/api/v1/payments/`

  getTypes = async (id) => {
    try {
      const api = `/api/v1/stores/${id}/payments`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }
}
