export const types = {
  TOGGLE_STOP_LIVE: 'TOGGLE_STOP_LIVE',
  TOGGLE_CLOSE_NOTICE: 'TOGGLE_CLOSE_NOTICE',
  TOGGLE_SET_COVER: 'TOGGLE_SET_COVER',
  TOGGLE_SET_RTMP: 'TOGGLE_SET_RTMP',
  TOGGLE_SET_LIVE_TIME: 'TOGGLE_SET_LIVE_TIME',
  TOGGLE_SET_POST_TIME: 'TOGGLE_SET_POST_TIME',
  TOGGLE_LIVE_AUTHORIZATION: 'TOGGLE_LIVE_AUTHORIZATION',
  TOGGLE_PRIVATE: 'TOGGLE_PRIVATE',

  RESET_DIALOG_VISIBLE: 'RESET_DIALOG_VISIBLE'
}

const INIT_DATA = {
  dialogStopLiveVisible: false,
  dialogCloseNoticeVisible: false,
  dialogSetCoverVisible: false,
  dialogSetRtmpVisible: false,
  dialogSetLiveTimeVisible: false,
  dialogSetPostTimeVisible: false,
  dialogLiveAuthorizationVisible: false,
  dialogPrivateVisible: false
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  dialogStopLiveVisible: state => state.dialogStopLiveVisible,
  dialogCloseNoticeVisible: state => state.dialogCloseNoticeVisible,
  dialogSetCoverVisible: state => state.dialogSetCoverVisible,
  dialogSetRtmpVisible: state => state.dialogSetRtmpVisible,
  dialogSetLiveTimeVisible: state => state.dialogSetLiveTimeVisible,
  dialogSetPostTimeVisible: state => state.dialogSetPostTimeVisible,
  dialogLiveAuthorizationVisible: state => state.dialogLiveAuthorizationVisible,
  dialogPrivateVisible: state => state.dialogPrivateVisible
}

const mutations = {
  [types.TOGGLE_STOP_LIVE] (state, bool) {
    state.dialogStopLiveVisible = bool
  },

  [types.TOGGLE_CLOSE_NOTICE] (state, bool) {
    state.dialogCloseNoticeVisible = bool
  },

  [types.TOGGLE_SET_COVER] (state, bool) {
    state.dialogSetCoverVisible = bool
  },

  [types.TOGGLE_SET_RTMP] (state, bool) {
    state.dialogSetRtmpVisible = bool
  },

  [types.TOGGLE_SET_LIVE_TIME] (state, bool) {
    state.dialogSetLiveTimeVisible = bool
  },

  [types.TOGGLE_SET_POST_TIME] (state, bool) {
    state.dialogSetPostTimeVisible = bool
  },

  [types.TOGGLE_LIVE_AUTHORIZATION] (state, bool) {
    state.dialogLiveAuthorizationVisible = bool
  },

  [types.TOGGLE_PRIVATE] (state, bool) {
    state.dialogPrivateVisible = bool
  },

  [types.RESET_DIALOG_VISIBLE] (state) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))
  }
}

const actions = {
  toggleDialogStopLive ({ commit }, bool = false) {
    commit(types.TOGGLE_STOP_LIVE, bool)
  },

  toggleDialogCloseNotice ({ commit }, bool = false) {
    commit(types.TOGGLE_CLOSE_NOTICE, bool)
  },

  toggleDialogSetCover ({ commit }, bool = false) {
    commit(types.TOGGLE_SET_COVER, bool)
  },

  toggleDialogSetRtmp ({ commit }, bool = false) {
    commit(types.TOGGLE_SET_RTMP, bool)
  },

  toggleDialogSetLiveTime ({ commit }, bool = false) {
    commit(types.TOGGLE_SET_LIVE_TIME, bool)
  },

  toggleDialogSetPostTime ({ commit }, bool = false) {
    commit(types.TOGGLE_SET_POST_TIME, bool)
  },

  toggleDialogLiveAuthorization ({ commit }, bool = false) {
    commit(types.TOGGLE_LIVE_AUTHORIZATION, bool)
  },

  toggleDialogPrivate ({ commit }, bool = false) {
    commit(types.TOGGLE_PRIVATE, bool)
  },

  resetDialogVisible ({ commit }) {
    commit(types.RESET_DIALOG_VISIBLE)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
