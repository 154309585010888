<script>

import { mapGetters, mapActions } from 'vuex'

import ApiStore from '@/api/store'
import validate from '@/utils/validate'
const HEADER_DEFAULT_BG_IMG = require('@/assets/img-cover-default.png')

function slugValidator (rule, value, callback) {
  if (!validate.slug(value)) {
    return callback(new Error(this.$t('Setting.Store.message.slug_error')))
  } else {
    callback()
  }
}

export default {
  name: 'BasicInfoEnterpriseGeneric',
  components: {},
  data () {
    return {
      store: null,
      isEditingStore: false,
      storeRules: {
        slug: [
          {
            required: true,
            message: this.$t('Setting.Store.message.buyer_path'),
            trigger: 'blur'
          },
          {
            required: true,
            min: 4,
            max: 24,
            message: this.$t('Setting.Store.message.slug_length'),
            trigger: 'blur'
          },
          { required: true, validator: slugValidator.bind(this), trigger: 'blur' }
        ],
        name: [
          {
            required: true,
            message: this.$t('Setting.Store.message.store_name'),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['token', 'storeName', 'currentStore', 'imageUploadUrl']),
    setHeaderBGImagStyle () {
      const imgUrl = this.currentStore && this.currentStore.image_url
        ? this.currentStore.image_url
        : HEADER_DEFAULT_BG_IMG

      return {
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 30%,rgba(0, 0, 0, 0.4) 100%), url(${imgUrl})`
      }
    },
    uploadHeaders () {
      return { Authorization: `Bearer ${this.token}` }
    },
    avatarUrl () {
      return this.currentStore && this.currentStore.avatar_url
        ? this.currentStore.avatar_url
        : require('@/assets/icon-default-user-lg.png')
    },
    buyerPath () {
      return this.$VUE_APP_BUYER_DOMAIN_NAME
    }
  },
  created () {
    this.setInitStore()
  },
  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('Me', ['updateCurrentStore', 'fetchCurrentStore']),

    async handleUploadSuccess (response, file) {
      const apiStore = new ApiStore()
      await apiStore.update(this.storeName, { image_id: response.id })
      this.fetchCurrentStore()
    },
    async handleAvatarSuccess (response, file) {
      const apiStore = new ApiStore()
      await apiStore.update(this.storeName, { avatar_id: response.id })
      this.fetchCurrentStore()
    },
    async handleFileRemove (file) {
      const imageId = file.id

      if (!imageId) return

      const success = await this.apiImage.delete(file.id)

      if (success) {
        this.store.image_id = null
      }
    },
    handleBeforeUpload (file) {
      const fileMB = file.size / (1024 ** 2)

      if (fileMB > 2) {
        this.$message.error(this.$t('Setting.Store.message.upload_limit'))
        return false
      }

      return true
    },
    copyLink () {
      const copyUrl = this.buyerPath + '/e/stores/' + this.store.slug
      this.copyText({
        url: copyUrl,
        success: this.$t('Setting.Store.message.copy_link', { copyUrl }),
        error: this.$t('Setting.Store.message.copy_fail')
      })
    },
    setInitStore () {
      this.store = this.$set(this, 'store', _.cloneDeep(this.currentStore))
    },
    cancelUpdateStore () {
      // 取消更新，還原 store 資料
      this.isEditingStore = false
      this.setInitStore()
    },
    async validateForm () {
      try {
        await this.$refs.storeForm.validate()
        return true
      } catch (e) {
        this.$message.error(this.$t('Setting.Store.message.form_incomplete'))
        return false
      }
    },
    updateStore () {
      const apiStore = new ApiStore()
      return apiStore.update(this.storeName, this.store)
    },

    async handleSubmit () {
      this.setLoading(true)

      // 表單驗證 && 更新 Store
      if (!(await this.validateForm() && await this.updateStore())) {
        this.setLoading(false)
        return
      }

      // 判斷有無更改 slug，更新 CurrentStore 紀錄
      if (this.currentStore.slug !== this.store.slug) {
        await this.updateCurrentStore(this.store.slug)
        this.$router.push(`/${this.store.slug}/Setting`)
      }

      // 刷新 Vuex Store 資料
      await this.fetchCurrentStore()

      this.isEditingStore = false

      this.$message({
        showClose: true,
        duration: 1000,
        message: this.$t('Setting.Store.message.save_success'),
        type: 'success'
      })

      this.setLoading(false)
    }
  }
}
</script>

<template lang="pug" src="./BasicInfoEnterpriseGeneric.pug"></template>
<style lang="scss" src="./BasicInfoEnterpriseGeneric.scss" scoped></style>
