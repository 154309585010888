<script>
import { mapGetters } from 'vuex'
import { TW, JP } from '@/constants/regions'
import * as SETTINGS from '@/constants/storeSettings'
import * as PURCHASE_WORD from '@/constants/livewebPurchaseButton'
import HighlightElement from '../HighlightElement'

export default {
  name: 'ProductList',
  components: { HighlightElement },
  data () {
    return {
      SETTINGS
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol', 'region']),
    ...mapGetters('LiveSetting', ['newSettings', 'hoverElements']),
    showProducts () {
      return this.newSettings[SETTINGS.PRODUCT_LIST_SHOW]
    },
    products () {
      const products = {
        [TW]: [{
          id: 1,
          keyword: 'A01',
          name: '好走帆布小白鞋',
          price: 980
        }, {
          id: 2,
          keyword: 'A02',
          name: '舒適純棉襪',
          price: 980
        }],
        [JP]: [{
          id: 1,
          keyword: 'A01',
          name: 'スニーカー',
          price: 980
        }, {
          id: 2,
          keyword: 'A02',
          name: '靴下',
          price: 980
        }]
      }
      return products[this.regions] || products[JP]
    },

    productButtonText () {
      const textMap = {
        [PURCHASE_WORD.PURCHASE]: 'Live.buy_now',
        [PURCHASE_WORD.PRODUCT_PAGE]: 'Live.to_product_page'
      }
      return textMap[this.newSettings[SETTINGS.PRODUCT_PURCHASE_WORD]]
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
