import axios from '@/plugins/http.js'

export default class AUCTION {
  // 取得拍賣活動
  getAuctions = async (postId, params) => {
    const api = `/api/v1/posts/${postId}/auctions?all`
    return axios.get(api, params)
  }

  // 建立拍賣活動
  create = async (postId, postData) => {
    try {
      const api = `/api/v1/posts/${postId}/auctions`
      const { data } = await axios.post(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // 更新拍賣產品
  update = async (postId, updateData) => {
    try {
      const api = `/api/v1/posts/${postId}/auctions`
      const { data } = await axios.put(api, updateData)

      return data
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 結束拍賣活動時間
  close = async auctionId => {
    try {
      const api = `/api/v1/auctions/${auctionId}/end`
      const { data } = await axios.post(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // 加入得標商品至得標者購物車
  createAuctionBidders = async (auctionId, postData) => {
    try {
      const api = `/api/v1/auctions/${auctionId}/winning-bidders`
      const { data } = await axios.post(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // 取得拍賣活動的得標者
  getAuctionWinners = async auctionId => {
    try {
      const api = `/api/v1/auctions/${auctionId}/winning-bidders?all`
      const {
        data: { data }
      } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }
}
