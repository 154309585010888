import _ from 'lodash'
import ApiOrder from '@/api/order'
import * as loadingState from '@/constants/loadingState'

export const actionTypes = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  UPDATE_FILTER: 'UPDATE_FILTER',
  UPDATE_SORT: 'UPDATE_SORT',
  UPDATE_BATCH_SELECTED: 'UPDATE_BATCH_SELECTED',
  UPDATE_ORDERS: 'UPDATE_ORDERS',
  UPDATE_CURRENT_PAGE: 'UPDATE_CURRENT_PAGE',
  UPDATE_META: 'UPDATE_META'
}

const initFilter = {
  per_page: 50,
  keyword: '',
  from: '',
  to: '',
  mistake_only: false
}

const state = {
  loadingState: loadingState.EMPTY,
  error: null,
  meta: {},
  orders: [],
  filter: { ...initFilter },
  sort: {},
  currentPage: 1,
  selected: []
}

const getters = {
  batchApproveLoadingState: state => state.loadingState,
  meta: state => state.meta,
  orders: state => state.orders,
  filter: state => state.filter,
  sort: state => state.sort,
  selected: state => state.selected,
  isLoading: state => state.loadingState === loadingState.LOADING,
  normalizedOrders: state => {
    return state.orders.map(order => {
      return {
        id: order.id,
        slug: order.slug,
        total_amount: Number(order.total_amount),
        payment_status: order.payment_status,
        paid_at: _.get(order, 'payment.bank_receipt.paid_at', ''),
        bank_account: _.get(order, 'payment.bank_receipt.bank_account', ''),
        bank_name: _.get(order, 'payment.bank_receipt.bank_name', ''),
        has_mistake: !!_.get(order, 'payment.bank_receipt.has_mistake', false)
      }
    })
  }
}

const mutations = {
  [actionTypes.LOADING] (state) {
    state.loadingState = loadingState.LOADING

    // 是否要 清空資料 或 初始化資料
    // ...your code
  },

  [actionTypes.SUCCESS] (state, payload) {
    state.loadingState = loadingState.READY

    // 寫入 成功 的資料
    // ...your code
  },

  [actionTypes.FAILED] (state, error) {
    state.loadingState = loadingState.ERROR
    state.error = error

    // Bugsnag 紀錄，若不需要的話，拿掉這段。
    console.error(error)

    // 清空資料 或 初始化資料
    // ...your code
  },

  [actionTypes.UPDATE_FILTER] (state, payload) {
    state.filter = payload
  },

  [actionTypes.UPDATE_SORT] (state, payload) {
    state.sort = payload
  },

  [actionTypes.UPDATE_BATCH_SELECTED] (state, payload) {
    state.selected = payload
  },

  [actionTypes.UPDATE_ORDERS] (state, payload) {
    state.orders = payload
  },

  [actionTypes.UPDATE_CURRENT_PAGE] (state, payload) {
    state.currentPage = payload
  },

  [actionTypes.UPDATE_META] (state, payload) {
    state.meta = payload
  }
}

const actions = {
  async fetchApproves ({ state, commit, rootGetters }, payload) {
    const apiOrder = new ApiOrder()
    commit(actionTypes.LOADING)
    try {
      const params = _.pickBy({
        ...state.sort,
        ...state.filter,
        page: state.currentPage
      }, _.identity)
      const { data: { data, meta } } = await apiOrder.getOfflineAtmReview(rootGetters['Me/storeName'], params)
      commit(actionTypes.SUCCESS)
      commit(actionTypes.UPDATE_ORDERS, data)
      commit(actionTypes.UPDATE_META, meta)
    } catch (e) {
      commit(actionTypes.FAILED)
      throw e
    }
  },
  updateFilter ({ commit }, payload) {
    commit(actionTypes.UPDATE_FILTER, payload)
  },
  updateSort ({ commit }, payload) {
    commit(actionTypes.UPDATE_SORT, payload)
  },
  updateCurrentPage ({ commit }, payload) {
    commit(actionTypes.UPDATE_CURRENT_PAGE, payload)
  },
  resetFilter ({ commit }) {
    commit(actionTypes.UPDATE_FILTER, { ...initFilter })
  },
  updateBatchSelected ({ commit }, payload) {
    commit(actionTypes.UPDATE_BATCH_SELECTED, payload)
  },
  async batchApprove ({ state, commit, rootGetters }) {
    const apiOrder = new ApiOrder()
    commit(actionTypes.LOADING)
    try {
      const reviewPayload = {
        order_ids: state.selected.map(order => order.id),
        payment_status: 'paid'
      }
      await apiOrder.putOfflineAtmReview(rootGetters['Me/storeName'], reviewPayload)
      commit(actionTypes.SUCCESS)
    } catch (e) {
      commit(actionTypes.FAILED)
      throw e
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
