<script>
import { mapGetters } from 'vuex'

import getSubscriptionImage from '@/utils/getSubscriptionImage'

export default {
  name: 'TicketTable',

  computed: {
    ...mapGetters('Me', ['currencySymbol']),
    ...mapGetters('Subscription', ['subscriptionList']),

    normalizeSubscriptionList () {
      return this.subscriptionList
        .filter(ticket => ticket.active)
        .map(ticket => {
          return {
            ...ticket,
            iconUrl: getSubscriptionImage(ticket.subscription_frequency)
          }
        })
    }
  }
}
</script>

<template lang="pug" src="./TicketTable.pug"></template>
<style lang="scss" src="./TicketTable.scss" scoped></style>
