<script>
import { mapGetters, mapActions } from 'vuex'

import * as PAYMENT_TYPES from '@/constants/paymentTypes'

import TicketTable from './components/TicketTable'
import ConfirmPaymentSetting from './components/ConfirmPaymentSetting'
import DialogSubscriptionSetting from './components/DialogSubscriptionSetting'

export default {
  name: 'Subscription',

  components: {
    TicketTable,
    ConfirmPaymentSetting,
    DialogSubscriptionSetting
  },

  data () {
    return {
      confirmPaymentSettingVisible: false,
      dialogSubscriptionSettingVisible: false,
      showGmoCreditIntroduce: false
    }
  },

  computed: {
    ...mapGetters('Me', ['storeName']),
    ...mapGetters('Subscription', ['subscriptionList', 'subscriptionDescription']),
    ...mapGetters('SettingsChecked', ['paymentSettings'])
  },

  mounted () {
    this.checkCreditPayment()
    this.reFetch()
  },

  methods: {
    ...mapActions('SettingsChecked', ['fetchPaymentSetting']),
    ...mapActions('Subscription', ['fetchSubscription']),

    async checkCreditPayment () {
      await this.fetchPaymentSetting()
      const gmoCredit = this.paymentSettings.find(payment => payment.type === PAYMENT_TYPES.GMO_CREDIT)
      const enabledGmoCredit = gmoCredit && gmoCredit.enabled
      const hasShopId = !!_.get(gmoCredit, 'info.shop_id', null)

      // 確認是否有開啟 GMO 信用卡 且 已通過 GMO 申請
      if (!(enabledGmoCredit && hasShopId)) {
        this.showGmoCreditIntroduce = true
      }
    },

    toggleDialogSubscriptionSetting (bool = false) {
      // 當要開啟編輯時，檢查是否有開啟 GMO 信用卡 且 已通過 GMO 申請
      // 因為 showGmoCreditIntroduce 已判斷過，
      // 所以拿 showGmoCreditIntroduce 當基準
      if (bool && this.showGmoCreditIntroduce) {
        this.confirmPaymentSettingVisible = true
        return
      }

      this.dialogSubscriptionSettingVisible = bool
    },

    reFetch () {
      this.fetchSubscription(this.storeName)
    },

    toPaymentSetting () {
      this.$router.push({ path: `/${this.storeName}/setting`, query: { activeMenu: 'SettingPayment' } })
    }
  }
}
</script>

<template lang="pug" src="./Subscription.pug"></template>
