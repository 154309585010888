<script>
import { mapGetters, mapActions } from 'vuex'
import { parentBind, getParent } from './InviteParent'

export default {
  skipMiddleware: ['checkAuth'],
  name: 'InviteParent',
  data () {
    return {
      token: this.$route.params.token,
      parentStore: ''
    }
  },
  computed: {
    ...mapGetters('Me', ['isLogin', 'currentStore']),
    ...mapGetters('Lang', ['locale'])
  },
  async created () {
    if (this.isLogin) {
      this.joinPermission()
    } else {
      this.handleLogin()
    }
  },
  methods: {
    ...mapActions('Me', ['fetchStores', 'fetchCurrentStore']),
    async getParentName () {
      try {
        const { data: { name } } = await getParent(this.token)
        this.parentStore = name
      } catch (e) {
        console.error(e)
      }
    },
    confirmBind () {
      this.$msgbox({
        title: '',
        center: true,
        customClass: 'inviteParent-msgbox',
        message: `<h5>${this.$t('child_store.confirm_bind_title')}</h5>
          <div>${this.$t('child_store.confirm_bind_text', { store: this.parentStore })}</div>`,
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        confirmButtonText: this.$t('child_store.confirm_bind_button')
      })
        .then(() => {
          if (this.isLogin) {
            this.joinPermission()
          } else {
            this.handleLogin()
          }
        })
        .catch(() => {})
    },
    async joinPermission () {
      try {
        await parentBind(this.token)
        // 更新商城資料
        await this.fetchStores()
        await this.fetchCurrentStore()
        sessionStorage.setItem('parentStoreBind', true)
        // 前往商城
        if (this.currentStore) {
          this.$router.push({ path: `/${this.currentStore.slug}/setting` })
        } else {
          this.$router.push({ name: 'Register', query: { parentToken: this.token } })
        }
      } catch (e) {
        console.error(e)
        this.$message.error(this.$t('Invite.auth_fail'))
        this.$router.push({ name: 'UserLogin', query: { autoLogin: false } })
      }

      // 清除授權Token
      localStorage.removeItem('localParentInvite')
    },
    handleLogin () {
      // 紀錄前往的路徑
      localStorage.setItem('localParentInvite', this.$route.params.token)
      localStorage.removeItem('localInvite')
      this.$router.push({ name: 'UserLogin', query: { autoLogin: false } })
    }
  }
}
</script>

<template lang="pug" src="./InviteParent.pug"></template>
<style lang="scss" src="./InviteParent.scss" scoped></style>
