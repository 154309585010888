<script>
import { mapActions, mapGetters } from 'vuex'
import Pagination from '@/components/Pagination'
import List from './components/List/List.vue'
import DialogCouponDetail from './components/DialogCouponDetail/DialogCouponDetail'
import DialogCouponHistory from './components/DialogCouponHistory/DialogCouponHistory'

export default {
  name: 'Coupon',
  components: { Pagination, List, DialogCouponDetail, DialogCouponHistory },
  data () {
    return {
      showCouponDetail: false,
      editCoupon: null,
      showCouponHistory: false,
      historyCoupon: null
    }
  },
  computed: {
    ...mapGetters('Coupon', {
      coupons: 'getCoupons',
      paginator: 'getPaginator',
      search: 'getSearch'
    }),
    statusOption () {
      return [
        { value: '', label: this.$t('Coupon.status_all') },
        { value: 'coming', label: this.$t('Coupon.status_coming') },
        { value: 'ongoing', label: this.$t('Coupon.status_ongoing') },
        { value: 'expired', label: this.$t('Coupon.status_expired') }
      ]
    }
  },
  mounted () {
    this.fetchCouponList(true)
  },
  methods: {
    ...mapActions('Coupon',
      [ 'fetchCouponList',
        'updateSearchStatus',
        'updateSearchCode',
        'updateSearchPage',
        'updateCoupons']
    ),
    handleClearSearch () {
      this.fetchCouponList(true)
    },
    handleCreateCoupon () {
      this.editCoupon = null
      this.showCouponDetail = true
    },
    handleEditCoupon (coupon) {
      this.editCoupon = coupon
      this.showCouponDetail = true
    },
    handleShowHistory (coupon) {
      this.historyCoupon = coupon
      this.showCouponHistory = true
    },
    updateRows (data) {
      this.updateCoupons({ data })
    },
    updatePaginationPage (page) {
      this.updateSearchPage(page)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
