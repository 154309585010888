<script>

import ProductIcon from '@/assets/icon-default-product.png'
import UserIcon from '@/assets/icon-default-user-md.png'
import ChannelIcon from '@/assets/icon-default-channel.png'

export default {
  name: 'BaseImage',

  props: {
    type: {
      type: String,
      default: 'product'
    },
    src: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    round: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    defaultImage () {
      switch (this.type) {
        case 'product':
          return ProductIcon
        case 'user':
          return UserIcon
        case 'channel':
          return ChannelIcon
        default:
          return ProductIcon
      }
    },
    computedSrc () {
      if (!this.src) {
        return this.defaultImage
      } else {
        return this.src
      }
    },
    computedStyle () {
      return {
        width: this.size + 'px',
        height: this.size + 'px',
        ...this.round && { borderRadius: '50%' }
      }
    }
  },
  methods: {
    onError (e) {
      e.target.src = this.defaultImage
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
