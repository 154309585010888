import * as loadingState from '@/constants/loadingState'
import { fetchEnterpriseResult } from '../apis/results'

export const types = {
  FETCH_RESULTS: 'FETCH_RESULTS',
  FETCH_RESULTS_SUCCESS: 'FETCH_RESULTS_SUCCESS',
  FETCH_RESULTS_FAILED: 'FETCH_RESULTS_FAILED',
  UPDATE_QUERY: 'UPDATE_QUERY'
}

const INIT_DATA = {
  results: [],
  meta: {},
  links: {},
  query: {
    from: '',
    to: '',
    status: '',
    param: '',
    page: 1
  },

  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  postbackLoadingState: state => state.loadingState,
  query: state => state.query,
  results: state => state.results,
  meta: state => state.meta
}

const mutations = {
  [types.FETCH_RESULTS] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.FETCH_RESULTS_SUCCESS] (state, payload) {
    state.loadingState = loadingState.READY

    const { data, links, meta } = payload.data

    state.results = data
    state.meta = meta
    state.links = links
  },

  [types.FETCH_RESULTS_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  },

  [types.UPDATE_QUERY] (state, query) {
    Object.assign(state.query, query)
  }
}

const actions = {
  fetchResults ({ state, rootGetters, commit }) {
    commit(types.FETCH_RESULTS)

    return fetchEnterpriseResult(rootGetters['Me/storeName'], state.query)
      .then(response => commit(types.FETCH_RESULTS_SUCCESS, response))
      .catch(err => {
        commit(types.FETCH_RESULTS_FAILED, err)
        throw err
      })
  },

  updateQuery ({ state, commit }, payload) {
    const page = payload.page || 1

    const query = {
      ...state.query,
      ...payload,
      page
    }

    commit(types.UPDATE_QUERY, query)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
