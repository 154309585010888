<script>
import { mapGetters } from 'vuex'
import * as REGIONS from '@/constants/regions'
import PlanService from './components/PlanService'
import PaymentRecord from './components/PaymentRecord'
import Points from './components/Points'

const TABS = {
  PLAN: 'plan',
  PAYMENT: 'payment',
  POINTS: 'points'
}

export default {
  name: 'Service',

  components: { PlanService, PaymentRecord, Points },

  data () {
    return {
      TABS,
      REGIONS,
      activeTab: TABS.PLAN
    }
  },

  computed: {
    ...mapGetters('Me', ['region'])
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
