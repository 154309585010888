<script>
import { mapGetters } from 'vuex'
import ApiProduct from '@/api/product'

import { normalizeImage } from '@/utils/normalizeImage'
import Tag from '@/components/Tag'

export default {
  name: 'DialogPickProductGeneric',
  components: {
    Tag
  },
  props: {
    selectedProducts: {
      type: Array,
      required: true
    },
    isExcludeMarket: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialogVisible: false,
      keywordVisible: false,
      activeName: 'first',
      searchRule: {
        keyword: ''
      },
      products: [],
      selectedItem: [],
      currentPage: 1,
      lastPage: 1,
      keywordTarget: {},
      apiProduct: new ApiProduct(),
      form: {
        newDefaultKeyword: ''
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'currencySymbol']),
    filterSearchRule () {
      return Object.keys(this.searchRule).reduce((prev, key) => {
        if (this.searchRule[key]) prev[key] = this.searchRule[key]
        return prev
      }, {})
    },
    normalizeProducts () {
      if (this.products === null) return null

      const result = this.products.map(item => {
        // 商品分類
        let category
        if (item.categories.length) {
          category = item.categories[0].name
        } else {
          category = ''
        }

        // 售價
        const maxPrice = Math.floor(item.max_price)
        const minPrice = Math.floor(item.min_price)
        let priceRange
        if (maxPrice !== minPrice) {
          priceRange = `${this.currencySymbol} ${minPrice} ~ ${maxPrice}`
        } else {
          priceRange = `${this.currencySymbol} ${maxPrice}`
        }

        return Object.assign(item, { priceRange, category })
      })

      return result
    },
    filterProduct () {
      return this.normalizeProducts
        .filter(product => !this.selectedProducts.some(item => item.id === product.id))
        .filter(product => !this.selectedItem.some(item => item.id === product.id))
      // .filter(product => product.name.includes(this.searchRule.keyword))
    },
    selectedProductsKeyowrd () {
      return this.selectedProducts.map(product => product.keyword)
    },
    errorRule () {
      return {
        newDefaultKeyword: [
          {
            required: true,
            message: `${this.$t('Components.DialogPickProduct.enter_default_keyword')}`,
            trigger: 'blur'
          },
          {
            validator: this.checkUniqueKeyword,
            message: `${this.$t('Components.DialogPickProduct.keyword_repeat2')}`,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    this.fetchProducts()
  },
  methods: {
    async fetchProducts (isFirst = true) {
      // TODO：搜尋的時機點，現在混合「先搜自己 + API撈回來的」
      try {
        let api = `/api/v1/stores/${this.storeName}/products`

        const params = Object.assign(this.filterSearchRule, {
          page: this.currentPage,
          'with_market': this.isExcludeMarket ? false : null
        })

        const { data: { data: products, meta } } = await this.axios.get(api, { params })

        if (isFirst) this.products = []

        this.lastPage = meta.last_page
        this.products.push(...products)
      } catch (e) {
        console.error(e)
      }
    },
    infiniteLoad () {
      if (this.currentPage < this.lastPage) {
        this.currentPage += 1
        this.fetchProducts(false)
      }
    },
    handleSearch: _.debounce(function () {
      this.currentPage = 1
      this.fetchProducts(true)
    }, 500),
    getImgUrl (data) {
      if (!data.images || data.images.length === 0) return
      return normalizeImage(data.images[0].url, 40)
    },
    selectItem (item) {
      this.selectedItem.push(item)
      // 當畫面上的商品數少於七個，就 load 剩下商品
      const currentItem = this.products.length - this.selectedItem.length
      if (currentItem < 7) this.infiniteLoad()
    },
    selectUrlItem (item) {
      this.selectedItem.push(Object.assign(item, { selectSource: 'url' }))
    },
    removeItem (item) {
      this.selectedItem = this.selectedItem.filter(selected => selected.id !== item.id)
    },
    handlePickProduct () {
      this.$emit('handlePickProduct', this.selectedItem)
      this.handleCloseDialog()
    },
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    handleTabClick () {
      this.searchRule.keyword = ''
    },
    toggle (isOpen = null) {
      this.selectedItem = []
      this.dialogVisible = isOpen === null ? !this.dialogVisible : isOpen
    },
    goCreateProduct () {
      this.$router.push(`/${this.storeName}/product`)
    },
    isKeywordRepeat (item) {
      return this.selectedProductsKeyowrd.includes(item.default_keyword)
    },
    handleClickItem (item) {
      return this.isKeywordRepeat(item) ? this.showKeywordDialog(item) : this.selectItem(item)
    },
    showKeywordDialog (item) {
      this.keywordVisible = !this.keywordVisible
      this.keywordTarget = item
    },
    async checkUniqueKeyword (rules, value, callback) {
      const isSuccess = await this.apiProduct.checkUniqueKeyword(this.form.newDefaultKeyword)
      if (isSuccess) {
        callback()
      } else {
        callback(new Error())
      }
    },
    async handleSubmit () {
      const data = {
        name: this.keywordTarget.name,
        skus: this.keywordTarget.skus,
        default_keyword: this.form.newDefaultKeyword
      }
      try {
        await this.$refs['keywordForm'].validate()
        await this.apiProduct.update(this.keywordTarget.id, data)
        this.fetchProducts()
        this.keywordVisible = !this.keywordVisible
        this.keywordTarget = ''
        this.form.newDefaultKeyword = ''
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
