<script>
import BaseImage from '@/components/BaseImage'
import BaseButton from '@/components/BaseButton'

export default {
  name: 'DialogchosenPlans',
  components: {
    BaseImage, BaseButton
  },
  props: {
    channels: {
      type: Array,
      default: () => []
    },
    storeUsers: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Boolean,
      default: true
    },
    chosenPlan: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isSubmitting: false,
      chosenChannels: [],
      chosenAssistants: [],
      message: {
        channel: '',
        storeUser: ''
      }
    }
  },
  computed: {
    limitChannels () {
      return this.chosenPlan.channel_number
    },
    limitAssistants () {
      // 扣除 manager
      return this.chosenPlan.manager_number - 1
    },
    displayChannels () {
      return this.channels
        .filter(item => item.type === 'fb_page')
        .map(channel => {
          return {
            active: this.chosenChannels.includes(channel.id),
            ...channel }
        })
    },
    displayStoreUsers () {
      return this.storeUsers
        .filter(user => user.pivot.type !== 'member')
        .sort(user => user.pivot.type === 'owner' ? -1 : 1)
        .map(user => {
          return {
            bind: user.pivot.type === 'owner',
            active: this.chosenAssistants.includes(user.id),
            ...user }
        })
    },
    unbindChannels () {
      return this.channels
        .filter(item => item.type === 'fb_page')
        .map(channel => channel.id)
        .filter(id => !this.chosenChannels.includes(id))
    },
    unbindUsers () {
      return this.storeUsers
        .filter(user => user.pivot.type !== 'owner')
        .filter(user => user.pivot.type !== 'member')
        .map(user => user.id)
        .filter(id => !this.chosenAssistants.includes(id))
    }
  },
  methods: {
    chooseChannel (channelId) {
      this.message.channel = ''
      const isExist = this.chosenChannels.indexOf(channelId)
      isExist === -1
        ? this.chosenChannels.push(channelId)
        : this.chosenChannels.splice(isExist, 1)
      if (this.limitChannels < this.chosenChannels.length) {
        this.message.channel = '若要保留其他社群，請選用其他方案。'
      }
    },
    chooseStoreUser (userId, bind) {
      if (bind) return
      this.message.storeUser = ''
      const isExist = this.chosenAssistants.indexOf(userId)
      isExist === -1
        ? this.chosenAssistants.push(userId)
        : this.chosenAssistants.splice(isExist, 1)

      if (this.limitAssistants < this.chosenAssistants.length) {
        this.message.storeUser = '若要保留其他管理員，請選用其他方案。'
      }
    },
    valid () {
      if (this.chosenChannels.length === 0) {
        this.message.channel = `至少選擇 ${this.limitChannels} 個社群，其餘社群將會停用。`
      }
      return Object.values(this.message).every(item => !item)
    },
    async submit () {
      const valid = this.valid()
      if (!valid) return

      this.$emit('unbind', {
        channels: this.unbindChannels,
        users: this.unbindUsers
      })
    },
    closeDialog () {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
