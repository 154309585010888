import axios from '@/plugins/http.js'
import storeId from '@/api/_storeId'

export default class Mobile {
  // 取得驗証手機 request_id
  getRequestId = async data => {
    try {
      const api = '/api/v1/mobile'
      const {
        data: { request_id: requestId }
      } = await axios.put(api, data)

      return requestId
    } catch (e) {
      console.error(e)
      return null
    }
  }

  storesVerify = async postData => {
    try {
      const api = `/api/v1/mobile/stores/${storeId()}/verify`
      const { data } = await axios.post(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }
  // 日本電子商務法則 - 電話驗證
  personalVerify = async postData => {
    try {
      const api = `/api/v1/mobile/stores/${storeId()}/personal-verify`
      const { data } = await axios.post(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }
}
