<template>
  <el-dialog
    class="fbbuy-bind-success"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :visible="isShow"
    :before-close="closeHandler"
  >
    <template #title>
      <i18n
        path="Setting.Channel.FBbuyBindSuccessDialog.successTitle"
        class="success-title"
        tag="div"
      >
        <template #currentChannel>
          <template v-if="currentIsFBPage">
            {{ $t('Setting.Channel.FBbuyBindSuccessDialog.FBPage') }}
          </template>
          <template v-else>
            {{ $t('Setting.Channel.FBbuyBindSuccessDialog.17Live') }}
          </template>
        </template>
      </i18n>
    </template>
    <div class="body-content">
      <i18n
        path="Setting.Channel.FBbuyBindSuccessDialog.successText"
        tag="span"
      >
        <template #otherChannel>
          <template v-if="currentIsFBPage">
            {{ $t('Setting.Channel.FBbuyBindSuccessDialog.17Live') }}
          </template>
          <template v-else>
            {{ $t('Setting.Channel.FBbuyBindSuccessDialog.FBPage') }}
          </template>
        </template>
      </i18n>
    </div>
    <template #footer>
      <el-button @click="onBackToFBbuy">
        {{ $t('Setting.Channel.FBbuyBindSuccessDialog.button.backToFBbuy') }}
      </el-button>
      <el-button
        type="primary"
        @click="onBindOtherChannel"
      >
        {{ $t('Setting.Channel.FBbuyBindSuccessDialog.button.continueToBind') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { FB_PAGE } from '@/constants/channelType'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      isShow: false,
      channelType: null,
      FBbuyIntegrationBackUrl: `${process.env.VUE_APP_FBBUY_DOMAIN}/admin/group/create`
    }
  },
  computed: {
    ...mapGetters('Me', ['isFBBuyStore', 'currentStore']),
    ...mapGetters('Me/StoreChannels', [
      'channelsWithoutHandsUP',
      'has17LIVEChannel',
      'hasFBFanChannel'
    ]),
    currentIsFBPage () {
      return this.channelType === FB_PAGE
    },
    isBindAllChannels () {
      return this.isFBBuyStore && this.channelsWithoutHandsUP.length === this.currentStore.plan.channel_number
    },
    isShowAllBindAlert () {
      return this.isBindAllChannels && this.isShow
    }
  },
  watch: {
    isShowAllBindAlert (value) {
      if(value) {
        this.isShow = false
        this.$alert(this.$t('Setting.Channel.page_redirect'), this.$t('Setting.Channel.facebook_binding_success'), {
          showConfirmButton: false
        })
        setTimeout(() => {
          window.location.href = this.FBbuyIntegrationBackUrl
        }, 3000)
      }
    },
  },
  methods: {
    openHandler ({ type = null } = {}) {
      this.isShow = true
      this.channelType = type
    },
    closeHandler () {
      this.channelType = null
    },
    onBackToFBbuy () {
      window.location.href = this.FBbuyIntegrationBackUrl
    },
    onBindOtherChannel () {
      if(this.currentIsFBPage) {
        this.$emit('openBind17LiveChannel')
      } else {
        this.$emit('openBindFBChannel')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fbbuy-bind-success {
  .success-title {
    font-size: 20px;
  }
  .body-content {
    padding: 20px 10px;
    font-size: 16px;
    text-align: center;
  }
}
</style>
