<script>

import { mapGetters } from 'vuex'
import { JP } from '@/constants/regions'
import AddressSelect from '@/components/AddressSelect'
import jpOptions from './jpOptions'

export default {
  name: 'DialogOverseaShipping',
  components: { AddressSelect },
  props: {
    setting: {
      type: Object
    },
    plan: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      groups: {},
      defaultGroup: {},
      props: { multiple: true, emitPath: false },
      options: [],
      step: 'step1',
      normalSetting: {
        enabled: false,
        fee: 60,
        fee_type: 'fixed',
        address: {},
        sender_name: null,
        sender_phone: null,
        memo: null
      },
      codSetting: {
        enabled: false,
        fee: 60,
        fee_type: 'fixed'
      },
      defaultSetting: {
        enabled: false,
        fee: 60,
        fee_type: 'fixed'
      },
      feeRules: {
        fee: [
          {
            type: 'integer',
            message: this.$t('Setting.Payment.DialogShippingSetting.validate_fee'),
            trigger: 'change',
            min: 1,
            transform: x => parseInt(x)
          }

        ],
        free_shipping_threshold: [
          {
            type: 'integer',
            message: this.$t('Setting.Payment.DialogShippingSetting.validate_fee'),
            trigger: 'change',
            min: 1,
            transform: x => parseInt(x)
          }
        ]
      },
      senderRules: {
        sender_name: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogShippingSetting.validate_sender_name'),
            trigger: 'change'
          }
        ],
        sender_phone: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('Setting.Payment.DialogShippingSetting.validate_sender_phone1')
          }
        ],
        sender_address: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogShippingSetting.validate_sender_address'),
            trigger: 'change'
          }
        ]
      },
      memo: null
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol', 'currentStore', 'region']),
    notSet () {
      if (!this.setting) return true
      return !this.setting.normal
    },
    planNoCod () {
      return this.plan.name === 'novice'
    },
    hasMemo () {
      if (!this.setting) return false
      const memoTypes = ['custom']
      return memoTypes.includes(this.setting.type)
    },
    normalEnabled () {
      return this.normalSetting.enabled
    },
    codEnabled () {
      return this.codSetting.enabled
    },
    onlyCod () {
      const onlyCodRegions = []
      return onlyCodRegions.includes(this.currentStore.region.toLowerCase())
    }
  },
  mounted () {
    if (this.region === JP) {
      this.options = jpOptions
      this.senderRules.sender_name[0].required = false
      this.senderRules.sender_phone[0].required = false
      this.senderRules.sender_address[0].required = false
    }
    if (!this.setting) return

    // Overwrite default when already set, else set last address if present
    this.normalSetting.type = this.setting.type
    if (this.setting.normal) {
      this.normalSetting = { ...this.normalSetting, ...this.setting.normal }
      if (this.normalSetting.info) {
        this.normalSetting.memo = this.normalSetting.info.memo
      }
    } else if (this.setting.lastAddress) {
      this.normalSetting.address = this.setting.lastAddress.address
      this.normalSetting.sender_phone = this.setting.lastAddress.sender_phone
      this.normalSetting.sender_name = this.setting.lastAddress.sender_name
    }
    if (!this.setting.codAble) {
      this.normalSetting.enabled = true
      this.codSetting.enabled = false
    }

    this.codSetting.type = this.setting.type + '_cod'
    if (this.setting.cod) {
      this.codSetting = { ...this.setting.cod }
    }

    if (this.onlyCod) {
      this.normalSetting.enabled = false
      this.codSetting.enabled = true
    }

    if (!this.normalSetting.fees) {
      const title = Date.now()
      const group = {
        info: [],
        options: _.cloneDeep(this.options),
        normalSetting: _.cloneDeep(this.normalSetting),
        codSetting: _.cloneDeep(this.normalSetting)
      }
      group.normalSetting.title = title
      group.codSetting.title = title
      this.$set(this.groups, title, group)
      return
    }
    for (const fee of this.normalSetting.fees) {
      const group = {
        info: fee.info.level_one_code,
        options: _.cloneDeep(this.options),
        normalSetting: { ...fee },
        codSetting: { ...this.codSetting.fees.find(codFee => codFee.title === fee.title) }
      }
      this.$set(this.groups, fee.title, group)
    }
    this.updateGroupOptions()
  },
  methods: {
    toggle () {
      this.$emit('closeShipping')
    },
    validateSender () {
      if (this.$refs['senderForm']) {
        return this.$refs['senderForm'].validate()
      } else {
        return true
      }
    },
    validateAddress () {
      if (this.$refs['addressForm']) {
        return this.$refs['addressForm'].validateForm()
      } else {
        return true
      }
    },
    nextStep () {
      this.step = 'step2'
    },
    settingNormalize (setting) {
      const newSetting = { ...setting }
      newSetting.fee = parseInt(newSetting.fee)
      newSetting.free_shipping_threshold = parseInt(newSetting.free_shipping_threshold)

      if (newSetting.fee_type !== 'condition') {
        newSetting.free_shipping_threshold = 0
      }
      if (newSetting.fee_type === 'free') {
        newSetting.fee = 0
      }

      return newSetting
    },
    async submit () {
      try {
        await this.validateSender()
        await this.validateAddress()
      } catch {
        this.$message.error(this.$t('Setting.Payment.DialogShippingSetting.validate_sender'))
        return
      }
      this.normalSetting.fee = parseInt(this.normalSetting.fee)
      this.normalSetting.free_shipping_threshold = parseInt(this.normalSetting.free_shipping_threshold)

      if (this.normalSetting.fee_type !== 'condition') {
        this.normalSetting.free_shipping_threshold = 0
      }
      if (this.normalSetting.fee_type === 'free') {
        this.normalSetting.fee = 0
      }

      if (this.codSetting.fee_type !== 'condition') {
        this.codSetting.free_shipping_threshold = 0
      }
      if (this.codSetting.fee_type === 'free') {
        this.codSetting.fee = 0
      }

      const normalFees = Object.values(this.groups).map(group => {
        return {
          ...group.normalSetting,
          title: group.title || group.normalSetting.title,
          info: { level_one_code: _.flatten(group.info).filter(x => x) }
        }
      }).map(group => this.settingNormalize(group))

      const codFees = Object.values(this.groups).map(group => {
        return {
          ...group.codSetting,
          title: group.title || group.codSetting.title,
          info: { level_one_code: _.flatten(group.info).filter(x => x) }
        }
      }).map(group => this.settingNormalize(group))

      let data = {}

      data['normal'] = this.normalSetting
      if (this.hasMemo) {
        this.normalSetting.info = {
          memo: this.normalSetting.memo
        }
      }
      if (normalFees.length > 0) {
        this.normalSetting.fees = normalFees
      }

      if (this.setting.codAble) {
        this.codSetting.address = this.normalSetting.address
        this.codSetting.sender_name = this.normalSetting.sender_name
        this.codSetting.sender_phone = this.normalSetting.sender_phone
        data['cod'] = this.codSetting

        if (codFees.length > 0) {
          this.codSetting.fees = codFees
        }
      }
      this.$emit('submit', data)
    },
    createGroup () {
      const newTitle = Date.now()
      const newGroup = {
        title: newTitle,
        info: [],
        normalSetting: { title: newTitle, fee: 60, fee_type: 'fixed' },
        codSetting: { fee: 60, fee_type: 'fixed' }
      }
      this.$set(this.groups, newTitle, newGroup)
      this.updateGroupOptions()
    },
    removeGroup (group) {
      this.$delete(this.groups, group.normalSetting.title)
      this.updateGroupOptions()
    },
    getOptions (group) {
      const used = Object.values(this.groups).reduce((acc, val) => {
        if (val.normalSetting.title === group.normalSetting.title) {
          return acc
        } else {
          return acc.concat(val.info)
        }
      }, [])

      const options = _.cloneDeep(this.options)
      for (const region of options) {
        for (const levelOne of region.children) {
          levelOne.disabled = used.includes(levelOne.value)
        }
      }
      return options
    },
    updateGroupOptions () {
      for (const group of Object.values(this.groups)) {
        group.options = this.getOptions(group)
      }
    },
    updateAddress (obj) {
      this.normalSetting.address = obj
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
<style>
.labelNoMargin label {
  margin-bottom: 0;
}
</style>
