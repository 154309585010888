<script>
import VueTypes from 'vue-types'

export default {
  name: 'HighlightElement',
  props: {
    active: VueTypes.bool.def(false)
  }
}
</script>

<template lang="pug">
.highlightElement(:class="{highlight: active}")
  slot
</template>

<style lang="scss" scoped>
.highlightElement {
  &.highlight {
    box-shadow: 0 0 4px 0 $primary;
    border: solid 1px #03aaf4;
  }

  &:not(.highlight) {
    border: solid 1px transparent;
  }
}
</style>
