<script>
import AddressSelect from '@/components/AddressSelect'

import mixin from '../../mixins'

export default {
  name: 'FormHsinchu',

  components: { AddressSelect },

  mixins: [mixin],

  data () {
    return {
      fields: ['shipping_type', 'receiver_name', 'receiver_phone', 'receiver_address', 'info'],

      form: {
        receiver_name: '',
        receiver_phone: '',
        receiver_address: {},
        info: {
          memo: '不指定'
        }
      },

      rules: {
        receiver_name: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ],
        receiver_phone: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ]
      }
    }
  },

  computed: {
    deliveryTime: {
      get () {
        const memo = _.get(this.form, 'info.memo', '')
        const [memoTime] = memo.split(' ')

        return memoTime
      },
      set (val) {
        const memo = _.get(this.form, 'info.memo', '')
        const [, ...memoText] = memo.split(' ')

        this.$set(this.form.info, 'memo', val + ' ' + memoText.join(' '))
      }
    }
  }
}
</script>

<template lang="pug" src="./FormHsinchu.pug"></template>
