<script>
import { mapGetters, mapActions } from 'vuex'

import DialogStoreNew from './components/DialogStoreNew'
import PaymentContainer from './components/PaymentContainer'
import ShippingContainer from './components/ShippingContainer'

export default {
  name: 'Payment',
  components: {
    PaymentContainer,
    ShippingContainer,
    DialogStoreNew
  },
  computed: {
    ...mapGetters('Me', ['enabledMerchandise']),
    ...mapGetters('SettingsChecked', ['paymentShippingChecked', 'isAllChecked'])
  },
  methods: {
    ...mapActions('SettingsChecked', ['checkPaymentShipping']),
    async checkSetting () {
      await this.checkPaymentShipping()
      if (this.isAllChecked && localStorage.getItem('hasShowGuide') === 'false') {
        this.$refs.storeNew.toggle()
        localStorage.setItem('hasShowGuide', true)
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
