<script>
import { mapGetters } from 'vuex'

import { JP } from '@/constants/regions'
import { DRAFT } from '@/constants/gmoStatus'

export default {
  skipMiddleware: ['checkAuth'],

  metaInfo () {
    return {
      meta: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0'
      }]
    }
  },
  name: 'Login',
  computed: {
    ...mapGetters('Me', ['isLogin', 'storeName', 'gmoStatus', 'region', 'isChildStore'])
  },
  async created () {
    if (!this.isLogin) {
      this.$router.push({ name: 'UserLogin' })
      return
    }

    const localCelebrity = localStorage.getItem('localCelebrity')
    const localInvite = localStorage.getItem('localInvite')
    const localParentInvite = localStorage.getItem('localParentInvite')
    const localToFullPath = localStorage.getItem('localToFullPath')
    const localStoreName = localStorage.getItem('localStoreName')
    const loginFailPath = sessionStorage.getItem('loginFailPath')

    // 網紅授權
    if (localCelebrity) {
      this.$router.push({ path: `/celebrity/${localCelebrity}` })
      return
    }

    // 加入權限
    if (localInvite) {
      this.$router.push({ path: `/invite/${localInvite}` })
      return
    }

    // 母商店綁定
    if (localParentInvite) {
      this.$router.push({ path: `/invite-parent/${localParentInvite}` })
      return
    }

    // 進入商城
    if (localStoreName === '' || localToFullPath === '/' || localToFullPath === '/#_=_') {
      this.$analytics.fbq.event('AuthorizationDone', {
        Page: 'Merchant-Platform',
        Target: 'AuthorizationDone'
      })
      this.$ga.event({
        eventCategory: 'Authorization',
        eventAction: 'AuthorizationDone',
        eventLabel: 'Merchant-Platform'
      })

      if (this.region === JP && !this.isChildStore && this.gmoStatus === DRAFT) {
        this.$router.push({ path: `/${this.storeName}/gmo` })
      } else if (loginFailPath) {
        this.$router.push({ path: loginFailPath })
      } else {
        this.$router.push({ path: `/${this.storeName}` })
      }
    } else {
      this.$router.push({ path: `${localToFullPath}` })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
