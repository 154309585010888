<script>
import { mapGetters } from 'vuex'
import * as GMO_STATUS from '@/constants/gmoStatus'
import PlanInfo from './components/PlanInfo'
import PaymentInfo from './components/PaymentInfo'
import Receipt from './components/Receipt'

export default {
  name: 'Plan',
  components: { PlanInfo, PaymentInfo, Receipt },
  computed: {
    ...mapGetters('Me', ['gmoStatus']),
    gmoSuccess () {
      return this.gmoStatus === GMO_STATUS.SUCCESS
    }
  }
}
</script>

<template lang="pug" src="./Plan.pug"></template>
