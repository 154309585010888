<script>
export default {
  skipMiddleware: ['checkAuth'],

  name: '404'

}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
