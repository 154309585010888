<script>
import { ORDERER_TYPE } from '@/constants/orderManual'
import { validateEmptyString } from '@/utils/validateEmptyString'
import ApiMember from '@/api/member'
import { mapActions, mapState } from 'vuex'
import getSocialIcon from '@/utils/getSocialIcon'
import MemberInfo from './components/MemberInfo'

export default {
  name: 'Orderer',
  components: { MemberInfo },
  data () {
    return {
      isSearching: false,
      ORDERER_TYPE,
      receiverOptions: [{
        value: ORDERER_TYPE.SelectFromMembers,
        label: this.$t('OrderManual.Orderer.select_member')
      }, {
        value: ORDERER_TYPE.Guest,
        label: this.$t('OrderManual.Orderer.select_guest')
      }],
      selectedMember: '',
      selectedMemberInfo: {},
      form: {
        email: '',
        name: ''
      },
      formRules: {
        name: [
          { required: true, message: this.$t('OrderManual.Orderer.error.name'), trigger: 'blur', validator: validateEmptyString }
        ],
        email: [
          { required: true, message: this.$t('OrderManual.Orderer.error.email'), trigger: 'blur' },
          { type: 'email', message: this.$t('OrderManual.Oderer.error.email_format'), trigger: ['blur'] }
        ]
      },
      isEmailExist: false
    }
  },

  computed: {
    ...mapState('OrderManual/Orderer', ['isGuestInvited', 'ordererType', 'ordererName', 'contactEmail', 'memberSlug', 'memberInfo']),
    selectedOrdererType: {
      get () {
        return this.ordererType
      },
      set (value) {
        this.updateOrdererType(value)
      }
    },
    checkGuestInvitation: {
      get () {
        return this.isGuestInvited
      },
      set (value) {
        this.inviteGuest(value)
      }
    }
  },
  watch: {
    'form.email': function () {
      this.debouncedCheckEmail()
    }
  },
  beforeMount () {
    this.unsubscribe = this.$store.subscribe(({ type, payload }) => {
      if (type === 'OrderManual/Orderer/RESTORE_CACHE') {
        this.form.email = payload.contactEmail
        this.form.name = payload.ordererName
        // restore cached select member
        this.selectedMemberInfo = payload.memberInfo
      }
    })
  },
  destroyed () {
    this.unsubscribe()
  },
  methods: {
    ...mapActions('OrderManual/Orderer', ['updateOrdererType', 'updateOrdererName', 'updateContactEmail', 'inviteGuest', 'updateMemberInfo', 'memberInfo']),
    getSocialIcon,
    async validateForm () {
      try {
        await this.$refs.ordererForm.validate()
        return true
      } catch (error) {
        return false
      }
    },
    updateEmail (value) {
      const withoutSpaceValue = value.trim()
      this.updateContactEmail(withoutSpaceValue)
      this.form.email = withoutSpaceValue
    },
    updateName (value) {
      this.updateOrdererName(value)
      this.form.name = value
    },
    handleSelect (memberInfo) {
      if (!memberInfo.pivot.enabled) {
        return
      }
      this.selectedMemberInfo = memberInfo
      this.updateName(memberInfo.memberName)
      this.updateEmail(memberInfo.email || '')
      this.updateMemberInfo(memberInfo)
    },
    async searchMembers (query, cb) {
      if (!query) {
        cb()
      } else {
        const apiMember = new ApiMember()
        const member = await apiMember.list({
          keyword: query
        }, true)
        const result = member.data.map(info => ({
          memberName: info.name,
          memberAvatar: info.avatar_url,
          email: info.email,
          provider: info.providers[0] ? {
            name: info.providers[0].name,
            provider: info.providers[0].provider // might be facebook or handsup
          } : {},
          pivot: {
            enabled: info.pivot.enabled,
            memo: info.pivot.memo
          },
          slug: info.slug
        }))
        cb(result)
      }
    },
    emailMapping () {
      this.$refs.ordererForm.validateField('email', async (invalid) => {
        if (!invalid) {
          this.isSearching = true
          const apiMember = new ApiMember()
          const result = await apiMember.list({
            email: this.form.email
          }, true)
          this.isEmailExist = !!result.data.length
          this.inviteGuest(!result.data.length)
          this.isSearching = false
        }
      })
    },
    debouncedCheckEmail: _.debounce(function () {
      this.emailMapping()
    }, 600)
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
