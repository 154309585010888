<script>
import { mapActions, mapGetters } from 'vuex'

import PaginationPure from '@/components/PaginationPure'
import TicketTable from './components/TicketTable'
import DialogCreateTicket from './components/DialogCreateTicket'
import normalizeTicket from './utils/normalizeTicket'

export default {
  name: 'Ticket',
  components: {
    TicketTable,
    DialogCreateTicket,
    PaginationPure
  },

  data () {
    return {
      isShowDialogCreate: false,
      item: null
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'showInstructionLink']),
    ...mapGetters('Ticket', ['getTickets', 'ticketsPagination']),

    normalizeTickets () {
      return this.getTickets.map(normalizeTicket)
    }
  },

  created () {
    this.fetchTickets({ storeId: this.storeName })
  },

  methods: {
    ...mapActions('Ticket', [
      'fetchTickets',
      'createOrUpdateTicket',
      'deleteTicket'
    ]),

    showCreateTicket () {
      this.item = null
      this.isShowDialogCreate = true
    },

    handleCreateTicket ({ form, isEdit, id }) {
      this.createOrUpdateTicket({
        id,
        storeId: this.storeName,
        form,
        isEdit
      }).then(() => {
        this.isShowDialogCreate = false
        this.fetchTickets({ storeId: this.storeName })
      })
    },

    handlePage (page) {
      // @todo wrap the fetchTicket as one function and dont add `storeId` every time
      this.fetchTickets({
        storeId: this.storeName,
        params: {
          page
        }
      })
    },

    handleEdit (item) {
      this.item = item
      this.isShowDialogCreate = true
    },

    handleDelete (item) {
      if (item.lock > 0) {
        this.$msgbox({
          title: '',
          center: true,
          shoewClose: true,
          message: `
        ${this.$t('Ticket.message.delete_1')}<br>
        ${this.$t('Ticket.message.delete_2')}
        `,
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t('Ticket.message.understand')
        })
        return
      }

      const content = `
      <h5>${this.$t('Ticket.message.confirm.delete')}</h5>
      <p>${this.$t('Ticket.message.confirm.delete_2')}<br>
        ${this.$t('Ticket.message.confirm.delete_3')}</p>
      `

      this.$confirm(content, '', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: this.$t('button.confirm'),
        showCancelButton: true,
        center: true,
        cancelButtonClass: 'w-40',
        confirmButtonClass: 'w-40'
      }).then(() => {
        this.deleteTicket({
          storeId: this.storeName,
          productId: item.id
        })
      }).catch(() => {

      })
    }

  }
}
</script>

<template lang="pug" src="./Ticket.pug"></template>
