<script>
import { mapGetters, mapActions } from 'vuex'

import * as PAYMENT_STATUS from '@/constants/paymentStatus'
import * as PAYMENT_TYPES from '@/constants/paymentTypes'

import PaginationPure from '@/components/PaginationPure'

export default {
  name: 'PaymentList',
  components: { PaginationPure },

  data () {
    return {
      PAYMENT_STATUS,
      PAYMENT_TYPES
    }
  },

  computed: {
    ...mapGetters('Me', ['storeName', 'currencySymbol']),
    ...mapGetters('AccountManagement', {
      list: 'billListData',
      meta: 'billListMeta',
      search: 'billListSearch'
    }),

    searchDate: {
      get () {
        return [this.search.from, this.search.to]
      },
      set (val) {
        const [from = '', to = ''] = val || []
        this.updateBillListSearch({ from, to })
      }
    }

  },

  created () {
    this.fetchData()
  },

  methods: {
    ...mapActions('AccountManagement', ['setAtmInfo']),
    ...mapActions('AccountManagement', ['fetchBillList', 'updateBillListSearch']),

    fetchData () {
      this.fetchBillList(this.storeName)
    },

    getPaymentTypeName (item) {
      /**
       * 後端會回傳下列
       *  'newebpay',        // 虛擬帳號付款
          'newebpay_credit', // 信用卡付款
          'newebpay_auto',   // 信用卡付款 (企業版信用卡自動約定付款)
          'newebpay_atm',    // 虛擬帳號付款
          'manual',          // 手動註銷,
       */
      switch (item.payment_type) {
        case PAYMENT_TYPES.NEWEBPAY_CREDIT_AUTO:
        case PAYMENT_TYPES.NEWEBPAY_CREDIT:
          return this.$t(`payment.type.newebpay_credit`)
        case PAYMENT_TYPES.NEWEBPAY:
        case PAYMENT_TYPES.NEWEBPAY_ATM:
          return this.$t(`payment.type.newebpay_atm`)
        case PAYMENT_TYPES.MANUAL:
          return this.$t('payment.type.manual')
        default:
          return item.payment_type
      }
    },

    isAtmPayment (item) {
      const account = _.get(item, 'payment.account', null)

      return !!account
    },

    showAtmInfo (item) {
      const atmInfo = {
        amount: item.amount,
        ...item.payment
      }

      this.setAtmInfo(atmInfo)
    },

    handleUpdateRows (page) {
      this.updateBillListSearch({
        page
      })

      this.fetchData()
    }

  }
}
</script>

<template lang="pug" src="./PaymentList.pug"></template>
<style lang="scss" src="./PaymentList.scss" scoped></style>
