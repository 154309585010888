<script>
import { mapGetters } from 'vuex'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import FamilyBarcode from '@/components/FamilyBarcode'
import KerryThBarcode from '@/components/KerryThBarcode'
import addressNormalizer from '@/utils/addressNormalizer'

export default {
  name: 'Print',
  components: { FamilyBarcode, KerryThBarcode },
  props: {
    orderInput: {
      type: Object,
      required: true
    },
    printMode: {
      type: String,
      default: 'all'
    }
  },
  data () {
    return {
      SHIPPING_TYPES,
      pages: [],
      order: {},
      printTime: new Date(),
      quantityTotal: 0
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore']),
    displaySenderAddress () {
      const address = this.order.sender_address
      return addressNormalizer(address, this.currentStore.region)
    },
    displayReceiverAddress () {
      const address = this.order.receiver_address
      return addressNormalizer(address, this.currentStore.region)
    },
    // 是否為自取
    isSelfPickup () {
      const types = [SHIPPING_TYPES.SELF_PICK_UP, SHIPPING_TYPES.CUSTOM_SELF_PICK_UP]
      return types.includes(this.order.shipping_type)
    }
  },
  created () {
    this.displayOrder()
  },
  methods: {
    getImgPath (order) {
      const path = order.shipping_print_image
      return path.includes('googleusercontent') ? path + '=s1600' : path
    },
    iframeSrc (path) {
      return 'data:text/html;charset=utf-8,' + encodeURIComponent(path)
    },
    displayOrder () {
      if (this.printMode === 'shipping') {
        return
      }
      let { items, ...order } = this.orderInput
      let itemCount = 0
      items = items.map(item => {
        itemCount++
        return Object.assign(item, { idx: itemCount })
      })
      this.order = order
      this.pages = [items]
      this.quantityTotal = items.reduce((prev, item) => {
        return prev + item.quantity
      }, 0)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
