import * as loadingState from '@/constants/loadingState'

import { getSubscription } from '../apis'

export const types = {
  FETCH_SUBSCRIPTION: 'FETCH_SUBSCRIPTION',
  FETCH_SUBSCRIPTION_SUCCESS: 'FETCH_SUBSCRIPTION_SUCCESS',
  FETCH_SUBSCRIPTION_FAILED: 'FETCH_SUBSCRIPTION_FAILED'
}

const INIT_DATA = {
  subscriptionList: [],
  subscriptionDescription: '',

  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  subscriptionList: state => state.subscriptionList,
  subscriptionDescription: state => state.subscriptionDescription,

  getSubscriptionTicketLoadingState: state => state.loadingState
}

const mutations = {
  [types.FETCH_SUBSCRIPTION] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.FETCH_SUBSCRIPTION_SUCCESS] (state, { data, description }) {
    state.subscriptionList = data
    state.subscriptionDescription = description

    state.loadingState = loadingState.READY
  },

  [types.FETCH_SUBSCRIPTION_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  }
}

const actions = {
  fetchSubscription ({ commit }, storeSlug) {
    commit(types.FETCH_SUBSCRIPTION)

    return getSubscription(storeSlug)
      .then(response => commit(types.FETCH_SUBSCRIPTION_SUCCESS, response.data))
      .catch(err => {
        commit(types.FETCH_SUBSCRIPTION_FAILED, err)
        throw err
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
