<script>
import { mapGetters } from 'vuex'
import ApiOrder from '@/api/order'

export default {
  name: 'SplitList',

  data () {
    return {
      orders: []
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'currencySymbol'])
  },
  created () {
    this.fetchOrders()
  },
  methods: {
    async fetchOrders () {
      try {
        const apiOrder = new ApiOrder()
        const ids = JSON.parse(this.$route.query.orders)
        const { data: { data: orders } } = await apiOrder.fetchOrdersById(this.storeName, ids)
        this.orders = orders
      } catch (e) {
        console.error(e)
        this.toOrderList()
      }
    },
    toOrderDetail (id) {
      const path = `/${this.storeName}/order/${id}`
      this.$router.push({ path })
    },
    toOrderList () {
      const path = `/${this.storeName}/order`
      this.$router.push({ path })
    }
  }
}
</script>

<template lang="pug" src="./SplitList.pug"></template>
<style lang="scss" src="./SplitList.scss" scoped></style>
