export const HOME_DELIVERY = 'home_delivery' // 貨運宅配
export const FAMILY = 'family'
export const SEVEN = 'seven'
export const HSINCHU = 'hsinchu'
export const CUSTOM = 'custom' // 自訂-海外用
export const KERRY_TH = 'kerry_th'
export const SELF_PICK_UP = 'self_pick_up' // 門市純取貨
export const CUSTOM_SELF_PICK_UP = 'custom_self_pick_up' // 門市取貨付款
export const OFFLINE_CUSTOM_TCAT_COD = 'offline_custom_tcat_cod' // 宅配取貨付款
export const GHN = 'ghn'
export const VIRTUAL = 'virtual' // 虛擬 e.g. ticket
