import {
  updatePost,
  setSellingProduct as apiSetSellingProduct
} from '../../apis/post'

import { updateProduct } from '../../apis/product'

const getters = {
  products: (_, getters) => {
    if (!getters.post) return []

    return getters.post.products
      .filter(product => product.status !== 'deleted')
      .map(product => {
      // 關鍵字
        product.keyword = product.keywords.length > 0 ? product.keywords[0].keyword : null
        return product
      })
  }
}

const actions = {
  async pickProduct ({ getters, dispatch }, selectedProducts) {
    // 新增挑選商品
    const selectedProductIds = selectedProducts.map(product => product.id)

    // 可售數量 & 限購數量
    const quantities = selectedProducts.reduce((prev, product) => {
      const skusQuantity = product.skus.map(sku => {
        return {
          id: sku.id,
          type: 'sku',
          salable_quantity: sku.available_quantity,
          salable_limit: sku.available_quantity
        }
      })

      return [...prev, ...skusQuantity]
    }, [])

    // 更新 貼文商品資料
    updatePost(
      getters.postId,
      { product_ids: selectedProductIds, quantities }
    )
      .then(() => {
        dispatch('fetchAll')
      })
      .catch(err => {
        console.error(err)
      })
  },

  async updateOriginalProduct ({ dispatch, rootGetters }, product) {
    const data = {
      name: product.name,
      skus: product.skus,
      image_ids: product.image_ids,
      description: product.description,
      sale_started_at: product.sale_started_at,
      sale_ended_at: product.sale_ended_at,
      adult_warning: product.adult_warning
    }

    // 更新 原始商品資料
    return updateProduct({
      storeSlug: rootGetters['Me/storeName'],
      productId: product.id,
      data
    })
  },

  async updatePostProduct ({ getters, dispatch, rootGetters }, item) {
    const product = getters.products.find(product => product.id === item.id)

    let data = { product_ids: [product.id] }

    // Note:
    // 全站商品的關鍵字欄位是 default_keyword，
    // 而直播貼文的商品關鍵字欄位為 keyword，
    // 所以這裡是更新貼文的關鍵字，故更新 keyword
    if (product.keyword !== item.default_keyword) {
      data.keywords = [{
        id: item.id,
        type: 'product',
        keyword: item.default_keyword
      }]
    }

    // 可售數量 & 限購數量
    data.quantities = item.skus.map(sku => ({
      id: sku.id,
      type: 'sku',
      salable_limit: sku.salable_limit
    }))

    // 更新 貼文商品資料
    return updatePost(
      getters.postId,
      data
    )
  },

  setSellingProduct ({ getters, dispatch }, product) {
    const postData = { product_id: product.id }

    apiSetSellingProduct(getters.postId, postData)
      .then(() => {
        dispatch('fetchPost')
      })
      .catch(err => {
        console.error(err)
      })
  }
}

export default {
  getters,
  actions
}
