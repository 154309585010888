<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import * as PAYMENT_STATUS from '@/constants/paymentStatus'
import { normalizeImage } from '@/utils/normalizeImage'
import ApiPaymentSetting from '@/api/paymentSetting'
import ApiShippingSetting from '@/api/shippingSetting'

import FormHomeDelivery from '../DialogEditShipping/components/FormHomeDelivery'
import FormHsinchu from '../DialogEditShipping/components/FormHsinchu'
import FormFamily from '../DialogEditShipping/components/FormFamily'
import FormSeven from '../DialogEditShipping/components/FormSeven'
import FormSelfPickUp from '../DialogEditShipping/components/FormSelfPickUp'

const SHIPPING_PAYLOAD = {
  [SHIPPING_TYPES.HOME_DELIVERY]: ['receiver_name', 'receiver_phone', 'receiver_address'],
  [SHIPPING_TYPES.HSINCHU]: ['receiver_name', 'receiver_phone', 'receiver_address', 'info'],
  [SHIPPING_TYPES.FAMILY]: ['receiver_name', 'receiver_phone', 'receiver_address', 'info'],
  [SHIPPING_TYPES.SEVEN]: ['receiver_name', 'receiver_phone', 'receiver_address', 'info'],
  [SHIPPING_TYPES.SELF_PICK_UP]: ['receiver_name', 'receiver_phone', 'receiver_address']
}

export default {
  name: 'DialogSplitOrder',
  components: {
    FormHomeDelivery,
    FormHsinchu,
    FormFamily,
    FormSeven,
    FormSelfPickUp
  },
  props: {
    region: VueTypes.string.isRequired,
    orderData: VueTypes.object.def({}),
    shipping: VueTypes.object.def({})
  },
  data () {
    return {
      step: 1,
      shippingSettings: [],
      paymentSettings: [],
      newPayment: '',
      form: {},
      shippingFee: 0,
      splitItems: {},
      allSplitError: false
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'currencySymbol']),
    orderSum () {
      return this.orderData.items.reduce((acc, val) => {
        const splitQuantity = this.splitItems[val.id] || 0
        acc += Number(val.price) * (val.quantity - splitQuantity)
        return acc
      }, 0)
    },
    splitSum () {
      return Object.entries(this.splitItems).reduce((acc, [id, quantity]) => {
        const item = this.orderData.items.find(item => item.id + '' === id + '')
        acc += item ? Number(item.price) * quantity : 0
        return acc
      }, 0)
    },
    isChangeShipping () {
      return this.shipping.type !== this.form.shipping_type
    },
    originalPaymentLabel () {
      return this.$t('Order.DialogSplitOrder.original_payment',
        { payment: this.$t(`payment.type.${this.orderData.payment_type}`) })
    },
    originalShippingLabel () {
      return this.$t('Order.DialogSplitOrder.original_shipping',
        { shipping: this.$t(`shipping.type.${this.orderData.shipping_type}`) })
    },
    displayForm () {
      const formMapping = {
        [SHIPPING_TYPES.HOME_DELIVERY]: 'FormHomeDelivery',
        [SHIPPING_TYPES.HSINCHU]: 'FormHsinchu',
        [SHIPPING_TYPES.FAMILY]: 'FormFamily',
        [SHIPPING_TYPES.SEVEN]: 'FormSeven',
        [SHIPPING_TYPES.SELF_PICK_UP]: 'FormSelfPickUp'
      }

      return formMapping[this.form.shipping_type]
    },
    originalQuantity () {
      return this.orderData.items.reduce((acc, val) => {
        acc += val.quantity
        return acc
      }, 0)
    },
    splitQuantity () {
      return Object.entries(this.splitItems).reduce((acc, [id, quantity]) => {
        acc += quantity
        return acc
      }, 0)
    },
    filteredShipping () {
      return this.shippingSettings
        .filter(shipping => {
          return shipping.enabled &&
            !shipping.type.includes('_cod') &&
            shipping.type !== this.orderData.shipping_type
        })
    },
    filteredPayment () {
      return this.paymentSettings
        .filter(payment => {
          return payment.enabled &&
            !payment.type.includes('_cod') &&
            payment.type !== this.orderData.payment_type
        })
    },
    splitDisabled () {
      // 銀行轉帳待審核、商品數量 1 時，不可拆單
      return !!this.splitWarning
    },
    splitWarning () {
      if (this.orderData.payment_status === PAYMENT_STATUS.APPROVING) {
        return this.$t('Order.DialogSplitOrder.approving_warning')
      } else if (this.originalQuantity <= 1) {
        return this.$t('Order.DialogSplitOrder.one_item_warning')
      } else {
        return null
      }
    },
    // 若挑選的商品皆是已付款且運費為 0 ，就把修改付款方式的選單 disable 掉。
    // 因為新訂單已經不需要再付款了，所以也就不用改付款方式。
    paymentLocked () {
      if (this.shippingFee > 0) return false

      return Object.entries(this.splitItems)
        .filter(([id, quantity]) => {
          return quantity > 0
        })
        .every(([id, quantity]) => {
          const item = this.orderData.items.find(item => item.id + '' === id + '')
          const productPaymentStatus = _.get(item, 'payment.status', null)
          return productPaymentStatus === PAYMENT_STATUS.PAID
        })
    }
  },
  async created () {
    this.fetchShipping()
    this.fetchPayment()
    this.initShipping()

    // Return from 7-11/family store
    const tempStore = localStorage.getItem('splitOrder')
    if (tempStore && location.search) {
      this.initStoreReturn()
    } else {
      this.initItems()
    }
  },
  beforeDestroy () {
    localStorage.removeItem('splitOrder')
    localStorage.removeItem('orderEditShipping')
  },
  methods: {
    step1Validate () {
      if (this.splitQuantity >= this.originalQuantity) {
        this.allSplitError = true
        return
      }

      this.step = 2
    },
    initItems () {
      for (const item of this.orderData.items) {
        this.$set(this.splitItems, item.id, 0)
      }
    },
    initStoreReturn () {
      const tempStore = localStorage.getItem('splitOrder')
      Object.assign(this, JSON.parse(tempStore))
      this.step = 2
    },
    initShipping () {
      let formData = {
        shipping_type: ''
      }

      SHIPPING_PAYLOAD[this.shipping.type].forEach(key => {
        formData[key] = this.shipping[key]
      })

      // 從 7-11 或 全家 選擇門市導回後，更新 出貨方式
      const orderEditShipping = localStorage.getItem('orderEditShipping')
      if (orderEditShipping && location.search) {
        const {
          type,
          'receiver_name': name,
          'receiver_phone': phone
        } = JSON.parse(orderEditShipping)

        formData.shipping_type = type
        formData.receiver_name = name
        formData.receiver_phone = phone
      }

      this.$set(this, 'form', formData)
    },
    updateStorage () {
      const tempStore = {
        splitItems: this.splitItems,
        newPayment: this.newPayment,
        form: this.form,
        shippingFee: this.shippingFee
      }
      localStorage.setItem('splitOrder', JSON.stringify(tempStore))
    },
    updateShippingFee () {
      if (this.shippingFee === 0) {
        this.newPayment = ''
      }
      this.updateStorage()
    },
    close () {
      this.$emit('update:dialogVisible', false)
    },
    getImgUrl (data, size) {
      if (!data.image || !data.image.url) return
      return normalizeImage(data.image.url, size)
    },
    async fetchShipping () {
      const apiShippingSetting = new ApiShippingSetting()
      const shippings = await apiShippingSetting.list({}, 'all')
      this.shippingSettings = shippings
    },
    async fetchPayment () {
      const apiPaymentSetting = new ApiPaymentSetting()
      const payments = await apiPaymentSetting.list({}, 'all')
      this.paymentSettings = payments
    },
    async handleSubmit () {
      const orderItems = Object.entries(this.splitItems).reduce((acc, [id, quantity]) => {
        if (quantity > 0) {
          acc.push({ id, quantity })
        }
        return acc
      }, [])
      const payload = {
        order_items: orderItems
      }
      if (this.newPayment) {
        payload.payment_type = this.newPayment
      }
      if (this.$refs.form) {
        const isValidateForm = await this.$refs.form.validateForm()
        if (!isValidateForm) return
      }
      payload.shipping = {
        ...this.form,
        shipping_fee: this.shippingFee,
        shipping_type: this.form.shipping_type
          ? this.form.shipping_type
          : this.orderData.shipping_type
      }

      this.$emit('submit', payload)
    },
    updateForm (data) {
      const formData = Object.assign({ shipping_type: this.form.shipping_type }, data)
      this.$set(this, 'form', formData)
    }
  }
}
</script>

<template lang="pug" src="./DialogSplitOrder.pug"></template>
<style lang="scss" src="./DialogSplitOrder.scss" scoped></style>
