<script>

import { mapGetters, mapActions } from 'vuex'

import * as loadingState from '@/constants/loadingState'
import * as REGIONS from '@/constants/regions'

import ApiImage from '@/api/image'
import ApiProduct from '@/api/product'
import { normalizeImage } from '@/utils/normalizeImage'
import isKeyInNumber from '@/utils/isKeyInNumber'

const STEP = {
  UPLOAD: 'upload',
  PRODUCT: 'product',
  SKU: 'sku'
}

const QUANTITY_LIMIT_MAX = 999999

export default {
  name: 'DialogAddProduct',

  props: {
    dialogAddProductVisible: {
      type: Boolean,
      default: true
    },
    editProduct: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      loadingState,
      STEP,
      QUANTITY_LIMIT_MAX,
      activeStep: STEP.UPLOAD,
      fileLimit: 9,
      fileList: [],
      uploadedFiles: [],
      isBtnLoading: false,
      // @todo move to `Product` vuex modules
      ruleForm: {
        name: '',
        description: '',
        defaultKeyword: '',
        skus: [{
          name: this.$t('Product.DialogAddProduct.default_sku_name'),
          price: null,
          available_quantity: null
        }]
      },
      rules: {
        name: [{
          required: true,
          message: this.$t('Product.DialogAddProduct.placeholder_product'),
          trigger: 'blur'
        }],
        defaultKeyword: [
          {
            required: true,
            message: `${this.$t('Components.DialogPickProduct.enter_default_keyword')}`,
            trigger: 'blur'
          },
          {
            validator: this.checkHasBlank,
            message: `${this.$t('Product.DialogAddProduct.keyword_has_blank')}`,
            trigger: 'blur'
          },
          {
            validator: this.checkUniqueKeyword,
            message: `${this.$t('Components.DialogPickProduct.keyword_repeat2')}`,
            trigger: 'blur'
          }
        ]
      },

      skusErrorList: [],
      duplicateNames: [],

      saved: false,
      oldDefaultKeyword: ''
    }
  },
  computed: {
    ...mapGetters('Me', ['token', 'region', 'maxPrice', 'maxSkuName', 'maxProductName', 'shippingRateImg', 'keywordTip', 'imageUploadUrl']),
    ...mapGetters('ProductList', {
      defaultKeyword: 'defaultKeyword',
      defaultKeywordLoadingState: 'getDefaultKeywordLoadingState'
    }),

    uploadHeaders () {
      return { Authorization: `Bearer ${this.token}` }
    },
    isFileUpperLimit () {
      return this.fileList.length >= this.fileLimit
    }
  },
  async created () {
    // 是否為編輯商品
    if (this.editProduct) {
      const {
        name,
        description,
        default_keyword: defaultKeyword
      } = this.editProduct

      const skus = this.editProduct.skus.length && this.editProduct.skus.map(item => {
        return {
          ...item,
          price: Math.floor(item.price),
          original_quantity: item.available_quantity
        }
      })

      this.ruleForm = {
        name,
        description,
        skus,
        defaultKeyword
      }
      this.oldDefaultKeyword = defaultKeyword

      this.fileList = this.editProduct.images.map(image => {
        return {
          ...image,
          url: normalizeImage(image.url, 88)
        }
      })
    }
  },
  methods: {
    isKeyInNumber,
    ...mapActions('ProductList', ['getDefaultKeyword']),

    handleBeforeUpload (file) {
      const fileMB = file.size / (1024 ** 2)

      if (fileMB > 4) {
        this.$message.error(this.$t('Product.DialogAddProduct.error_upload_file'))
        return false
      }
    },
    async handleFileRemove (file, fileList) {
      const imageId = file.id || (file.response && file.response.id)

      if (!imageId) return

      const apiImage = new ApiImage()
      const success = await apiImage.delete(imageId)

      if (success) this.fileList = fileList
    },
    handleFileExceed (files, fileList) {
      this.$message.error(this.$t('Product.DialogAddProduct.image_limit_4', { fileLimit: this.fileLimit }))
    },
    handleFileSuccess (response, file, fileList) {
      // console.log('file upload success:', response, file, fileList)
      this.fileList = fileList
    },
    handleFileError (err) {
      this.$message.error(err)
    },
    validateUpload () {
      // 驗證有無上傳圖片
      this.activeStep = STEP.PRODUCT
    },

    checkHasBlank (rules, value, callback) {
      // 確認有無空白字元

      // 泰國不擋有空白字元
      if (this.region === REGIONS.TH) {
        callback()
        return
      }

      const regex = /\s/
      const hasSpace = regex.test(value)

      if (hasSpace) {
        callback(new Error())
      } else {
        callback()
      }
    },
    async checkUniqueKeyword (rules, value, callback) {
      // Keyword如果沒改動不用打API
      if (this.oldDefaultKeyword === this.ruleForm.defaultKeyword) {
        callback()
        return
      }
      const apiProduct = new ApiProduct()
      const isSuccess = await apiProduct.checkUniqueKeyword(this.ruleForm.defaultKeyword)
      if (isSuccess) {
        callback()
      } else {
        callback(new Error())
      }
    },
    validateProduct () {
      return new Promise((resolve, reject) => {
        // 驗證表單是否填寫完畢
        this.$refs['ruleForm'].validate(valid => {
          if (valid) {
            resolve()
            return
          }
          reject(new Error('form validation error'))
        })
      })
    },

    handleNextStep () {
      if (this.activeStep === STEP.UPLOAD) {
        this.activeStep = STEP.PRODUCT

        if (!this.ruleForm.defaultKeyword) {
          this.getDefaultKeyword()
            .then(() => {
              this.ruleForm.defaultKeyword = this.defaultKeyword
            })
        }
        return
      }

      if (this.activeStep === STEP.PRODUCT) {
        this.validateProduct()
          .then((res) => {
            this.activeStep = STEP.SKU
          })
      }
    },

    // ===============第三步===============
    addSkusRow () {
      const copySkus = _.pick(
        this.ruleForm.skus[this.ruleForm.skus.length - 1],
        ['price', 'available_quantity', 'name']
      )

      this.ruleForm.skus.push(copySkus)
    },
    removeSkus (index) {
      this.ruleForm.skus.splice(index, 1)
    },
    checkValidate (index, name) {
      return _.get(this.skusErrorList, `[${index}].${name}`, false)
    },
    async validateSkus () {
      let hasError = false
      const regex = /\s/

      const skusErrorList = this.ruleForm.skus.reduce((acc, current) => {
        const { name, price, available_quantity: count } = current

        let errorStatus = {
          isNullName: false,
          isBlankName: false,

          isNullPrice: false,
          isLimitOverPrice: false,

          isNullCount: false,
          isLimitOverCount: false,

          isOverStock: false
        }

        // 商品規格名稱
        if (!name) errorStatus.isNullName = true
        if (this.region !== REGIONS.TH && regex.test(name)) errorStatus.isBlankName = true

        // 價錢
        if (!price) errorStatus.isNullPrice = true
        if (price > this.maxPrice) errorStatus.isLimitOverPrice = true

        // 庫存
        if (_.isNil(count)) errorStatus.isNullCount = true
        if (count > QUANTITY_LIMIT_MAX) errorStatus.isLimitOverCount = true

        // 庫存 不可低於 可售數量
        if (current.available_quantity < current.lock_quantity) errorStatus.isOverStock = true

        if (Object.values(errorStatus).includes(true)) {
          hasError = true
        }

        acc.push(errorStatus)
        return acc
      }, [])

      this.$set(this, 'skusErrorList', skusErrorList)

      // 判斷商品規格名稱 有無重複
      const nameCount = this.ruleForm.skus.reduce((acc, current) => {
        if (acc[current.name]) {
          acc[current.name] = acc[current.name] + 1
        } else {
          acc[current.name] = 1
        }

        return acc
      }, {})

      const duplicateNames = Object.keys(nameCount).filter(key => nameCount[key] > 1)
      this.duplicateNames = duplicateNames

      if (duplicateNames.length) {
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          message: this.$t(
            'Product.DialogAddProduct.error_specification_duplicated'
            , { duplicate: duplicateNames.join('<br>') }
          ),
          showClose: true
        })

        hasError = true
      }

      if (hasError) return

      this.isBtnLoading = true

      // 送出 新增/更新
      const success = await this.submitForm()
      this.isBtnLoading = false

      if (success) {
        const showMessage = this.editProduct
          ? this.$t('Product.DialogAddProduct.message_product_update_completed')
          : this.$t('Product.DialogAddProduct.message_product_added')

        this.$message({
          message: showMessage,
          type: 'success',
          duration: 1000,
          showClose: true
        })

        this.saved = true
        this.handleCloseDialog()
        this.$emit('refreshTableData', !!this.editProduct)
      } else {
        const showMessage = this.editProduct
          ? this.$t('Product.DialogAddProduct.message_product_update_failed')
          : this.$t('Product.DialogAddProduct.message_product_addition_failed')

        this.$message({
          message: showMessage,
          type: 'error',
          duration: 1000,
          showClose: true
        })
      }
    },
    async submitForm () {
      // Set Sku Modify Quantity
      for (let sku of this.ruleForm.skus) {
        // 因為新增規格會有沒有 original_quantity 的情況所以要判斷
        if (sku['original_quantity']) {
          sku['modify_quantity'] = sku['available_quantity'] - sku['original_quantity']
        } else {
          sku['modify_quantity'] = sku['available_quantity']
        }
      }

      const apiProduct = new ApiProduct()
      const { name, description, defaultKeyword, skus } = this.ruleForm

      const submitForm = {
        name,
        description,
        default_keyword: defaultKeyword,
        skus
      }

      if (this.editProduct) {
        // 更新

        const success = await apiProduct.update(this.editProduct.id, {
          ...submitForm,
          image_ids: this.fileList.map(file => file.id || file.response.id)
        })

        return success
      } else {
        // 新增
        const data = await apiProduct.create({
          ...submitForm,
          image_ids: this.fileList.map(file => file.response.id)
        })

        if (data) {
          this.createdProduct = data.product
          return true
        } else {
          return false
        }
      }
    },
    // 關閉
    handleCloseDialog () {
      if (this.saved) {
        this.$emit('update:dialogAddProductVisible', false)
      } else {
        const create = this.$createElement
        this.$msgbox({
          title: '',
          center: true,
          showClose: true,
          customClass: 'remind-saving',
          message: create('h5', null, [
            create('span', null, this.$t('Product.DialogAddProduct.message_leave_webpage')),
            create('br', null, ''),
            create('span', null, this.$t('Product.DialogAddProduct.message_confirm_leave'))
          ]),
          showCancelButton: true,
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel')
        })
          .then(() => {
            this.$emit('update:dialogAddProductVisible', false)
          })
          .catch(action => {
          })
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
