<script>
export default {
  name: 'AppIntroduce',
  components: {},
  props: {
    closable: {
      type: Boolean,
      default: true
    },
    theme: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
    }
  },
  computed: {
    wrapClass () {
      const map = {
        'primary': ['bg-primary-vr-light', 'border-primary'],
        'danger': ['bg-danger-vr-light-opacity', 'border-danger'],
        'warning': ['bg-warning-vr-light', 'border-warning']
      }
      return map[this.theme]
    },
    themeIcon () {
      const map = {
        'primary': ['fa-info-circle', 'text-primary'],
        'danger': ['fa-exclamation-circle', 'text-danger'],
        'warning': ['fa-exclamation-circle', 'text-warning']
      }
      return map[this.theme]
    }
  },
  methods: {
    handleClose () {
      this.$emit('update:visible', false)
      this.$emit('close')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
