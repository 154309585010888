<script>
import VueTypes from 'vue-types'

export default {
  name: 'GmoApplyStatus',
  props: {
    status: VueTypes.string.isRequired
  },
  methods: {
    openGMOform () {
      this.$emit('openGMOform')
    }
  }
}
</script>

<template lang="pug" src="./GmoApplyStatus.pug"></template>
<style lang="scss" src="./GmoApplyStatus.scss" scoped></style>
