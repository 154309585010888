<script>
import { mapGetters, mapActions } from 'vuex'
import '@/utils/copyText'
import * as REGIONS from '@/constants/regions'
import * as ONBOARDING_KEYS from '@/constants/onboarding'
import { BASE_IMAGE_PATH } from '@/constants/path'
import AppDownloadDialog from './components/AppDownloadDialog'
import BaseImage from '@/components/BaseImage'

const LINKS = {
  PRODUCT: 'https://youtu.be/gpiR-7unc8o',
  POST: 'https://youtu.be/VC9H9pclajo',
  MARKET: 'https://youtu.be/gpiR-7unc8o',
  ORDER: 'https://youtu.be/-ltYztf0pHc',
  TICKET: 'https://youtu.be/c4xgBAGYD8g'
}

const ROUTES = {
  SETTING_CHANNEL: { name: 'Setting' },
  SETTING_PAYMENT: { name: 'Setting', query: { activeMenu: 'SettingPayment' } },
  SETTING_STORE: { name: 'Setting', query: { activeMenu: 'SettingStore' } },
  PRODUCT: { name: 'ProductDetail' },
  MARKET: { name: 'Market' },
  TICKET: { name: 'Ticket' },
  POST_CREATE: { name: 'PostCreate' }
}

export default {
  name: 'Onboarding',
  components: { AppDownloadDialog, BaseImage },
  data () {
    return {
      ...ONBOARDING_KEYS,
      ...REGIONS,
      BASE_IMAGE_PATH,
      LINKS,
      ROUTES,
      showCompleted: false,
      appDownloadDialogVisible: false
    }
  },
  computed: {
    ...mapGetters('Me', ['enabledMerchandise', 'enabledTicket', 'onboarding', 'onboardingLeft', 'storeName', 'region']),
    steps () {
      const all = Object.keys(this.onboarding).length

      return `${all - this.onboardingLeft}/${all}`
    },
    checkClasses () {
      return Object.entries(this.onboarding).reduce((acc, [key, value]) => {
        switch (key) {
          case ONBOARDING_KEYS.CHANNEL_SETTING:
            acc[key] = value.channels.length > 0 ? 'text-success' : 'text-gray-600'
            break
          default:
            acc[key] = value ? 'text-success' : 'text-gray-600'
            break
        }
        return acc
      }, {})
    },
    buyerMarketLink () {
      return `${process.env.VUE_APP_BUYER_DOMAIN_NAME}/${this.storeName}/market`
    },
    buyerLiveLink () {
      return `${process.env.VUE_APP_BUYER_DOMAIN_NAME}/${this.storeName}/live`
    }
  },
  watch: {
    onboardingLeft (value) {
      this.showCompleted = value === 0
    }
  },
  async mounted () {
    await this.fetchCurrentStore()
  },
  methods: {
    ...mapActions('Me', ['fetchCurrentStore']),
    ...mapActions('Me/StoreChannels', ['fetchChannels']),
    copyLiveLink () {
      this.copyText({
        url: this.buyerLiveLink
      })
    },
    copyMarketLink () {
      this.copyText({
        url: this.buyerMarketLink
      })
    }
  }
}
</script>

<template lang="pug" src="./Onboarding.pug"></template>
<style lang="scss" src="./Onboarding.scss" scoped></style>
