<script>
import { mapState, mapGetters } from 'vuex'
import { FEE_TYPE } from '@/constants/feeType'

export default {
  name: 'PricingSheet',
  computed: {
    ...mapState('OrderManual/ShippingAddress', ['shippingType', 'availableShippings']),
    ...mapState('OrderManual/ShoppingDetails', ['selectedProducts']),
    ...mapGetters('Me', ['currencySymbol']),
    count () {
      return this.selectedProducts.length
    },
    subtotal () {
      return this.selectedProducts.reduce((prev, curr) => prev + curr.price * curr.quantity, 0)
    },
    shippingFee () {
      const currentShipping = this.availableShippings.find(shipping => shipping.type === this.shippingType)
      if (!currentShipping) {
        return 0
      }
      switch (currentShipping.feeType) {
        case FEE_TYPE.free:
          return 0
        case FEE_TYPE.condition:
          return this.subtotal >= currentShipping.freeShippingThreshold ? 0 : currentShipping.fee
        case FEE_TYPE.fixed:
          return currentShipping.fee
        default:
          return 0
      }
    },
    grandTotal () {
      return this.subtotal + this.shippingFee || 0
    }

  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
