<script>
import BadgeImage from '@/components/BadgeImage'

export default {
  name: 'List',
  components: { BadgeImage },
  props: {
    orders: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getBuyerTypeTag (order) {
      const mappingType = {
        'handsup': 'line',
        'fb_page': 'fb'
      }

      return mappingType[order.buyer_channel_type] || ''
    },
    fetchOrderDetail (id) {
      this.$emit('fetchOrderDetail', id)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
