<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PointsDetail',
  components: {},
  props: {
    log: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName']),
    hasOrders () {
      return !!this.log.note
    },
    service () {
      return [{
        name: this.log.loggable.name,
        point: this.log.quantity
      }]
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    goOrder (id) {
      const path = `/${this.storeName}/order/${id}`
      window.open(path, '_blank')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
