import { STATUS } from '@/components/AppSteps'

export const STEP_ONE = 0
export const STEP_TWO = 1
export const STEP_THREE = 2

export const STEPS_INIT_STATE = [
  {
    status: STATUS.ACTIVE,
    title: '填寫基本資料'
  },
  {
    status: STATUS.WAIT,
    title: '選擇擅長領域'
  },
  {
    status: STATUS.WAIT,
    title: '綁定粉絲專頁'
  }
]
