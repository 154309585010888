import axios from '@/plugins/http.js'

export const fetchCartDetail = id => {
  const api = `/api/v1/carts/${id}`

  return axios.get(api)
}

export const updateCartDetail = (id, putData) => {
  const api = `/api/v1/carts/${id}`

  return axios.put(api, putData)
}
