<script>
import DialogValidatePhone from '@/components/DialogValidatePhone'
import AddressSelect from '@/components/AddressSelect'
import { mapGetters } from 'vuex'
import ApiStore from '@/api/store'
import ApiMobile from '@/api/mobile'

export default {
  name: 'JpCommercial',
  components: { DialogValidatePhone, AddressSelect },
  data () {
    return {
      isEditingMode: false,
      showNote: true,
      showDialogValidatePhone: false,
      apiStore: new ApiStore(),
      form: {
        identify: '',
        commercial_company_name: '',
        commercial_name_last: '',
        commercial_name_first: '',
        commercial_name_kana_first: '',
        commercial_name_kana_last: '',
        commercial_address: {},
        commercial_url: '',
        commercial_email: '',
        commercial_phone: ['', '', ''],
        opening_time: '',
        selling_price: '',
        payment_info: '',
        delivery_info: '',
        return_info: '',
        extra_fees: '',
        defective_info: ''
      },
      rules: {
        identify: [ { required: true, message: 'を入力してください' } ],
        commercial_company_name: [ { required: true, message: '法人名を入力してください' } ],
        commercial_name_last: [ { required: true, message: '代表者または運営統括責任者姓を入力してください' } ],
        commercial_name_first: [ { required: true, message: '代表者または運営統括責任者名を入力してください' } ],
        commercial_name_kana_last: [ { required: true, message: '代表者または運営統括責任者姓（ﾌﾘｶﾞﾅ）を入力してください' } ],
        commercial_name_kana_first: [ { required: true, message: '代表者または運営統括責任者名（ﾌﾘｶﾞﾅ）を入力してください' } ],
        commercial_url: [ { required: true, message: 'ホームページを入力してください' } ],
        commercial_email: [
          { required: true, message: '電子メールを入力してください' },
          { type: 'email', message: '正しいメールアドレスを入力してください' }
        ],
        commercial_phone: [
          { required: true, message: '事業者の連絡先(電話番号)を入力してください' },
          { pattern: /^[0-9]+$/, message: '番号を入力してください' }
        ],
        opening_time: [ { required: true, message: 'その他(営業時間・定休日等)を入力してください' } ],
        selling_price: [ { required: true, message: '販売価格についてを入力してください' } ],
        payment_info: [ { required: true, message: '代金(対価)の支払方法と時期を入力してください' } ],
        delivery_info: [ { required: true, message: '役務または商品の引渡時期を入力してください' } ],
        return_info: [ { required: true, message: '返品についての特約に関する事項を入力してください' } ],
        extra_fees: [ { required: true, message: '商品代金以外に必要な費用を入力してください' } ],
        defective_info: [ { required: true, message: '不良品の取扱条件を入力してください' } ]
      },
      identifyOptions: [
        { text: '個人', value: 'individual' },
        { text: '法人', value: 'company' }
      ],
      verifyCode: {
        requestToken: '',
        code: ''
      },
      isSmsSent: false,
      smsRemainedSeconds: 0
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore']),
    displayIdentify () {
      let map = {
        individual: '個人',
        company: '法人'
      }
      return map[this.form.identify] || ''
    },
    displayPhone () {
      return this.form['commercial_phone'] ? this.form['commercial_phone'].join('') : ''
    },
    validateObject () {
      return {
        identify: this.form.identify,
        commercial_company_name: this.form.commercial_company_name,
        commercial_name_last: this.form.commercial_name_last,
        commercial_name_first: this.form.commercial_name_first,
        commercial_name_kana_last: this.form.commercial_name_kana_last,
        commercial_name_kana_first: this.form.commercial_name_kana_first,
        commercial_url: this.form.commercial_url,
        commercial_email: this.form.commercial_email,
        commercial_phone: this.displayPhone,
        opening_time: this.form.opening_time,
        selling_price: this.form.selling_price,
        payment_info: this.form.payment_info,
        delivery_info: this.form.delivery_info,
        return_info: this.form.return_info,
        extra_fees: this.form.extra_fees,
        defective_info: this.form.defective_info
      }
    }
  },
  watch: {
    isSmsSent (value) {
      if (value) {
        this.setSmsTimer()
      }
    }
  },
  mounted () {
    if (localStorage.getItem('closeComercialNote')) {
      this.showNote = false
    }
    this.fetchFormContent()
  },
  methods: {
    closeNote () {
      localStorage.setItem('closeComercialNote', true)
      this.showNote = false
    },
    editForm () {
      this.isEditingMode = true
    },
    cancelEdit () {
      this.isEditingMode = false
      this.fetchFormContent()
    },
    async fetchFormContent () {
      const data = await this.apiStore.getJpCommercial()
      if (!data) return
      data['commercial_phone'] = (data['commercial_phone'] !== null)
        ? data['commercial_phone'].split('-') : ['', '', '']
      this.form = Object.assign(this.form, data)
    },
    async submitForm () {
      // 表單驗證
      try {
        await this.$refs['addressForm'].validateForm()
        await this.$refs['form'].validate()
      } catch (error) {
        this.$message.error(this.$t('message.form_incomplete'))
        return false
      }

      this.showDialogValidatePhone = true
      this.requestSMSCode()
    },
    async requestSMSCode () {
      if (this.smsRemainedSeconds > 0) return

      const request = {
        mobile: this.displayPhone
      }

      const apiMobile = new ApiMobile()
      const data = await apiMobile.personalVerify(request)

      if (data) {
        this.verifyCode.requestToken = data.request_token
        this.smsRemainedSeconds = 300
        this.isSmsSent = true
      }
    },
    setSmsTimer () {
      setInterval(() => {
        if (this.isSmsSent && this.smsRemainedSeconds > 0) {
          this.smsRemainedSeconds -= 1
        }
      }, 1000)
    },
    async submitPhoneValidation (phoneCode) {
      this.verifyCode.code = phoneCode
      const stringPhone = this.form['commercial_phone'].join('-')
      const data = {
        ...this.form,
        commercial_phone: stringPhone,
        request_token: this.verifyCode.requestToken,
        verification_code: this.verifyCode.code
      }
      await this.apiStore.updateJpCommercial(data)
      this.showDialogValidatePhone = false
      this.isEditingMode = false
    },
    updateAddress (obj) {
      this.form.commercial_address = obj
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
