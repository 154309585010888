
export const INIT = 'init'
export const DELIVERING = 'delivering'
export const DELIVERED = 'delivered'
export const RECEIVED = 'received'
export const RETURNING = 'returning'
export const RETURNED = 'returned'
export const RECEIVED_RETURN = 'received_return'
export const WAITING = 'waiting'
export const MISSING = 'missing'
export const DAMAGED = 'damaged'
export const CANCELED = 'canceled'
