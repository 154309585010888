/**
 * Concat 2 list, with data dedupped (duplicated data get removed).
 *
 * Basically it concats 2 list, but if any data in list2 having the same `id` as
 * one in list1, the one on list1 will be replaced by the one in list2.
 *
 * For example:
 *  list1 = [ A, B, C ]
 *  list2 = [ C', D, E]    // C' is the modified version of C
 *  concat(list1, list2) --> [A, B, C', D, E]
 *
 * An important note that it's a *concat* rather than *merge*, the order in list1 remains the same
 * For example:
 *  list1 = [ B, C, D ]
 *  list2 = [ A, B, C', D, E ]
 *  concat(list1, list2) --> [B, C', D, A, E]
 *
 * Items from list1 [ B, C, D ] remains the same, but with C updated
 * Non-duplicated items [A, E] from list2 are appended
 *
 * Options:
 * @param {function} idSelector - can be passed to work on items not using `id` as their key.
 * @param {boolean} useFirstList - when true, to take data from 1st list when conflict.
 *        false by default.
 *
 */
export const dedupeConcat = (list1, list2, {
  idSelector,
  useFirstList = false
} = {}) => {
  if (!list1 || list1.length === 0) {
    if (typeof list2 === 'undefined') {
      return []
    }

    return [...list2]
  }

  if (!list2 || list2.length === 0) {
    return [...list1]
  }

  if (!idSelector || typeof idSelector !== 'function') {
    // eslint-disable-next-line no-param-reassign
    idSelector = item => item.id
  }

  // A simple dedup algorithm:
  // 1. Loop over list1, pushing every item to the result, keep itemId in a map
  // 2. Loop over list2, check if an itemId exists in the map (meaning it exists in list1)
  //    A. If itemId already existed in list1, conflict happens. If useFirstList==true, the
  //       one in list1 will be kept. Otherwise, the one in list2 will replace it.
  //    B. If itemId is not found in the map, just append it to the end of the result.
  const resultList = []
  const itemIdToPos = {}

  list1.forEach((item, index) => {
    const itemId = idSelector(item)
    itemIdToPos[itemId] = index
    resultList.push(item)
  })

  list2.forEach((item, _) => {
    const itemId = idSelector(item)
    if (typeof itemIdToPos[itemId] === 'undefined') {
      // a new item from list2 is simply appended to result
      resultList.push(item)
    } else {
      // the same item found in both list1 and list2
      if (!useFirstList) {
        resultList[itemIdToPos[itemId]] = item
      }
    }
  })

  return resultList
}

/**
 * A shortcut to concat array with primitive values, for exmaple, string array.
 */
export const primitiveConcat = (list1, list2, {
  useFirstList
} = {}) => (
  dedupeConcat(list1, list2, {
    idSelector: v => v,
    useFirstList
  })
)
