<script>

import { mapGetters, mapActions } from 'vuex'
import VueApexCharts from 'vue-apexcharts'

import { normalizeImage } from '@/utils/normalizeImage'

import * as CHANNEL_TYPE from '@/constants/channelType'

import ApiPost from '@/api/post'

import HandsupIcon from '@/assets/icon-handsup.png'
import FacebookIcon from '@/assets/icon-fb.png'
import M17Icon from '@/assets/icon-channel-m17.png'

const primary = '#00ccff'

export default {
  name: 'ShowAnalysis',
  components: { VueApexCharts },
  props: {
    postId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      pieData: [0, 0],
      pieOptions: {
        labels: [this.$t('Post.Show.showAnalysis.new_visitor'), this.$t('Post.Show.showAnalysis.old_visitor')],
        legend: {
          show: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -10
            }
          }
        },
        dataLabels: {
          formatter: (val, opt) => {
            return opt.w.globals.labels[opt.seriesIndex]
          },
          style: {
            colors: ['#515151']
          },
          dropShadow: {
            enabled: false
          }
        },
        stroke: {
          colors: [primary],
          width: 1
        },
        colors: ['rgba(0, 204, 255, 0.3)', 'rgba(0, 204, 255, 0.1)']
      },
      apiPost: null,
      noBuckets: false,
      prevOrder: 0,
      totalOrder: 0,
      newPaidUsers: 0,
      oldPaidUsers: 0,
      richestBuyers: [],
      topSellers: [],
      paidUsersPie: null,
      impressions: 0,
      views: 0,
      shares: 0,
      likes: 0,
      maleTotal: 0,
      femaleTotal: 0,
      mainViewer: { count: 0 },
      updateTime: Date.now(),
      confirmedOrderCount: 0,
      confirmedSkuCount: 0,
      confirmedGmv: 0,
      estimatedOrderCount: 0,
      estimatedSkuCount: 0,
      estimatedGmv: 0,
      viewersCountHu: 0,
      viewersCountFb: 0,
      loginViewersCountHu: 0,
      usersWithOrdersCartsCountHu: 0,
      usersWithOrdersCartsCountFb: 0,
      newBuyersCountHu: 0,
      newBuyersCountFb: 0,
      oldBuyersCountHu: 0,
      oldBuyersCountFb: 0,
      usersWithOrdersCountHu: 0,
      usersWithOrdersCountFb: 0,
      usersWithPaidOrdersCountHu: 0,
      usersWithPaidOrdersCountFb: 0,
      replayViewerCountHu: 0
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol']),
    newPaidUsersRatio () {
      const res = Math.floor(this.newPaidUsers / (this.newPaidUsers + this.oldPaidUsers) * 100)
      return isFinite(res) ? res : 0
    },
    oldPaidUsersRatio () {
      const res = Math.floor(this.oldPaidUsers / (this.newPaidUsers + this.oldPaidUsers) * 100)
      return isFinite(res) ? res : 0
    }
  },
  mounted () {
    this.apiPost = new ApiPost()
    this.fetchAndRender()
    window.addEventListener('resize', this.resizeCharts)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeCharts)
  },
  methods: {
    ...mapActions(['setLoading']),

    resizeCharts () {
      this.$nextTick(() => {
        if (this.paidUsersPie) {
          this.paidUsersPie.resize()
        }
      })
    },
    productImgUrl (data) {
      if (!data.image || !data.image.url) return
      return normalizeImage(data.image.url, 40)
    },
    getChannelImage (data) {
      const iconMappings = {
        [CHANNEL_TYPE.FB_PAGE]: FacebookIcon,
        [CHANNEL_TYPE.HANDSUP]: HandsupIcon,
        [CHANNEL_TYPE.M17_LIVE]: M17Icon
      }
      return iconMappings[data.type]
    },
    async fetchAndRender () {
      if (!this.postId) return
      this.setLoading(true)
      const apiData = await this.apiPost.getStatisticsV2(this.postId)
      this.setLoading(false)
      if (!apiData) return
      this.updateTime = Date.now()
      this.renderOwnCharts(apiData)
      if (apiData['insights'].length === 0) return
      this.renderFbCharts(apiData['insights'])
    },

    renderFbCharts (insights) {
      if (!insights.length) {
        return
      }
      // FB 觀看人數 = FB 登入觀看人數
      // 多個 fb_pages 的 insights 要加總
      this.viewersCountFb = insights.reduce(
        (accumulator, insight) => {
          let fbViewsArr = insight.find(i => i.name === 'post_video_views_organic_unique')
          return accumulator + _.get(fbViewsArr, 'values[0].value', 0)
        },
        0
      )
    },
    renderOwnCharts (apiData) {
      this.confirmedOrderCount = apiData['data']['confirmed_order_count']
      this.confirmedSkuCount = apiData['data']['confirmed_sku_count']
      this.confirmedGmv = apiData['data']['confirmed_gmv']
      this.estimatedOrderCount = apiData['data']['estimated_order_count']
      this.estimatedSkuCount = apiData['data']['estimated_sku_count']
      this.estimatedGmv = apiData['data']['estimated_gmv']
      this.viewersCountHu = apiData['data']['viewers_count_handsup']
      this.loginViewersCountHu = apiData['data']['login_viewers_count_handsup']
      this.replayViewerCountHu = apiData['data']['replay_viewer_count_handsup']

      this.usersWithOrdersCartsCountHu = apiData['data']['users_with_orders_carts_count_handsup']
      this.usersWithOrdersCartsCountFb = apiData['data']['users_with_orders_carts_count_fb']
      this.usersWithOrdersCountHu = apiData['data']['users_with_orders_count_handsup']
      this.usersWithOrdersCountFb = apiData['data']['users_with_orders_count_fb']
      this.usersWithPaidOrdersCountHu = apiData['data']['users_with_paid_orders_count_handsup']
      this.usersWithPaidOrdersCountFb = apiData['data']['users_with_paid_orders_count_fb']

      this.newBuyersCountHu = apiData['data']['new_buyers_count_handsup']
      this.newBuyersCountFb = apiData['data']['new_buyers_count_fb']

      this.oldBuyersCountHu = apiData['data']['old_buyers_count_handsup']
      this.oldBuyersCountFb = apiData['data']['old_buyers_count_fb']

      this.newPaidUsers = this.newBuyersCountHu + this.newBuyersCountFb
      this.oldPaidUsers = this.oldBuyersCountHu + this.oldBuyersCountFb

      this.richestBuyers = apiData['richest_buyers']
      this.topSellers = apiData['top_sellers']

      this.pieData = [this.newPaidUsersRatio, this.oldPaidUsersRatio]
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
