<script>
import { mapGetters } from 'vuex'
import * as PAYMENT_TYPES from '@/constants/paymentTypes'
import * as REGIONS from '@/constants/regions'

import PaypalLogo from '@/assets/logo-paypal.png'
import NewebpayLogo from '@/assets/logo-newebpay.png'

import ApiPaymentSetting from '@/api/paymentSetting'
import ApiPayment from '@/api/payment'
import { getPaymentGateway, updatePaymentGateway } from '../../services/apis'

import DialogNewebpay from './components/DialogNewebpay'
import DialogCheckoutSetting from './components/DialogCheckoutSetting'
import DialogBankSetting from './components/DialogBankSetting'
import DialogPaypalSetting from './components/DialogPaypalSetting'

const NEWEBPAY_NTR_PAYMENT_DAY = 7 // 藍新金流結帳時間，預設 7 天
const PAYMENT_GATEWAYS = {
  NEWEBPAY: 'newebpay',
  PAYPAL: 'paypal',
  GMO_CREDIT: 'gmo_credit',
  GMO_CVS: 'gmo_cvs'
}

export default {
  name: 'PaymentContainer',
  components: {
    DialogNewebpay,
    DialogCheckoutSetting,
    DialogBankSetting,
    DialogPaypalSetting
  },
  data () {
    return {
      ...PAYMENT_TYPES,
      apiPaymentSetting: new ApiPaymentSetting(),
      availablePaymentTypes: [],
      paymentSettings: [],
      paymentGetwayNewebpay: {
        merchant_id: '',
        ntr_payment_day: NEWEBPAY_NTR_PAYMENT_DAY
      },
      paymentGatewayPaypal: {
        client: ''
      },
      gmoCreditShopId: '',
      gmoCvsShopId: '',
      dialogNewebpayVisible: false,
      dialogCheckoutSettingVisible: false,

      dialogBankVisible: false,
      dialogPaypalVisible: false,
      additionalPaymentSetting: { info: {} },
      PaypalLogo,
      NewebpayLogo,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('Me', [
      'isCurrentStoreOwner',
      'isChildStore',
      'isChildStoreParent',
      'currentStore',
      'storeName',
      'currencySymbol',
      'region'
    ]),
    enabledTypes () {
      return this.paymentSettings.reduce((acc, val) => {
        acc[val.type] = val.enabled
        return acc
      }, {})
    },
    availableTypes () {
      return this.availablePaymentTypes.reduce((acc, val) => {
        acc[val] = true
        return acc
      }, {})
    },
    showPayments () {
      const hiddenTypes = [PAYMENT_TYPES.FREE, PAYMENT_TYPES.COD]
      return this.availablePaymentTypes
        .filter(type => !hiddenTypes.includes(type))
        .length > 0
    }
  },
  async created () {
    this.isLoading = true
    try {
      await Promise.all([
        this.fetchPaymentTypes(),
        this.fetchPaymentSettings(),
        this.fetchPaymentGateways()
      ])
    } catch (e) {
      console.error(e)
    }
    this.isLoading = false
  },
  methods: {
    async fetchPaymentTypes () {
      const apiPayment = new ApiPayment()
      const paymentTypes = await apiPayment.getTypes(this.storeName)

      this.availablePaymentTypes = paymentTypes || []
    },
    async fetchPaymentSettings () {
      const paymentSettings = await this.apiPaymentSetting.list({}, 'all')

      this.paymentSettings = paymentSettings || []
    },
    async fetchPaymentGateways () {
      if (this.region === REGIONS.TW) {
        const [newebpay, paypal] = await Promise.all([
          getPaymentGateway(this.storeName, PAYMENT_GATEWAYS.NEWEBPAY),
          getPaymentGateway(this.storeName, PAYMENT_GATEWAYS.PAYPAL)
        ])

        this.paymentGetwayNewebpay = newebpay.data
        this.paymentGatewayPaypal = paypal.data
      } else if (this.region === REGIONS.JP) {
        const [gmoCreditResponse, gmoCvsResponse] = await Promise.all([
          getPaymentGateway(this.storeName, PAYMENT_GATEWAYS.GMO_CREDIT),
          getPaymentGateway(this.storeName, PAYMENT_GATEWAYS.GMO_CVS)
        ])

        this.gmoCreditShopId = gmoCreditResponse?.data?.['shop_id'] || null
        this.gmoCvsShopId = gmoCvsResponse?.data?.['shop_id'] || null
      }
    },

    getPaymentSetting (type) {
      return this.paymentSettings.find(setting => setting.type === type)
    },
    async updatePaymentEnabled (type) {
      const data = {
        type: type,
        enabled: !this.enabledTypes[type]
      }

      await this.apiPaymentSetting.update('', data)
      await this.fetchPaymentSettings()
      this.$emit('checkSetting')
    },

    // 藍新金流
    updateNewebpay (type) {
      if (!this.enabledTypes[type]) {
        this.showNewebpayDialog(type)
        return
      }

      this.updatePaymentEnabled(type)
    },
    // 藍新金流-帳號設定
    showNewebpayDialog (type) {
      this.additionalPaymentSetting = { type, info: this.paymentGetwayNewebpay }
      this.dialogNewebpayVisible = true
    },
    // 藍新金流-結帳時間設定
    showCheckoutSettingDialog () {
      this.additionalPaymentSetting = { info: this.paymentGetwayNewebpay }
      this.dialogCheckoutSettingVisible = true
    },
    updateNewebpayInfo (data) {
      updatePaymentGateway(this.storeName, 'newebpay', data.info)
        .then(() => {
          if (data.type) this.updatePaymentEnabled(data.type)
          this.dialogNewebpayVisible = false
          this.dialogCheckoutSettingVisible = false
          this.fetchPaymentSettings()
          this.fetchPaymentGateways()
        })
        .catch(err => {
          console.error(err)
        })
    },

    // 線下版 ATM
    updateOfflineAtm () {
      if (!this.enabledTypes[PAYMENT_TYPES.OFFLINE_ATM]) {
        this.showBankDialog()
        return
      }
      this.updatePaymentEnabled(PAYMENT_TYPES.OFFLINE_ATM)
    },
    showBankDialog () {
      const type = PAYMENT_TYPES.OFFLINE_ATM
      const setting = this.paymentSettings.find(setting => setting.type === type)
      this.additionalPaymentSetting = { ...setting, type }
      this.dialogBankVisible = true
    },
    async updateBankInfo (data) {
      await this.apiPaymentSetting.update('', data)
      this.dialogBankVisible = false
      this.fetchPaymentSettings()
    },
    updatePaypal () {
      if (!this.enabledTypes[PAYMENT_TYPES.PAYPAL]) {
        this.showPaypalDialog()
        return
      }
      this.updatePaymentEnabled(PAYMENT_TYPES.PAYPAL)
    },
    showPaypalDialog () {
      const type = PAYMENT_TYPES.PAYPAL
      this.additionalPaymentSetting = { type, info: this.paymentGatewayPaypal }
      this.dialogPaypalVisible = true
    },
    async updatePaypalInfo (data) {
      updatePaymentGateway(this.storeName, 'paypal', data.info)
        .then(() => {
          if (data.type) this.updatePaymentEnabled(data.type)
          this.dialogPaypalVisible = false
          this.fetchPaymentSettings()
          this.fetchPaymentGateways()
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
}
</script>

<template lang="pug" src="./PaymentContainer.pug"></template>
<style lang="scss" src="./PaymentContainer.scss" scoped></style>
