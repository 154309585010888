<script>
import VueTypes from 'vue-types'
import { mapActions, mapGetters } from 'vuex'
import { SESSION_KEYS } from '@/constants/common'
import { FB_PAGE } from '@/constants/channelType'
import { getAvailableFBFanPage, bindFBFanPage } from '@/api/FBBuyBinding'

export default {
  name: 'ChannelBind',
  props: {
    dialogVisible: VueTypes.bool.def(false),
    myChannels: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      activeStep: 'step-1',
      sources: [
        {
          id: 'facebook-page',
          name: this.$t('channel.type.fb_page'),
          imgUrl: require('@/assets/icons/fb-fans.png')
        }
      ],
      channels: [],
      selectedSource: {},
      selectedChannel: {},
      isInValidChannel: false,
      isChannelLoading: false
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'currentStore', 'isEnterprise', 'isCurrentStoreOwner', 'isPhoneVerfied', 'apiUrl', 'isFBBuyStore']),
    activeTitle () {
      const titles = {
        'step-1': this.$t('Setting.ChannelBind.title1'),
        'step-2': this.$t('Setting.ChannelBind.title2', { source: this.selectedSource.name }),
        'step-2-fbNoChannel': this.$t('Setting.ChannelBind.title1'),
        'step-3': this.$t('Setting.ChannelBind.title1')
      }

      return titles[this.activeStep]
    },

    fbAuthUrl () {
      return `${this.apiUrl}/auth/facebook?redirect=${location.href}`
    }
  },
  created () {
    // 因為目前只會有FB粉專，不會有其他管道。
    this.$set(this, 'selectedSource', {
      id: 'facebook-page',
      name: this.$t('channel.type.fb_page'),
      imgUrl: require('@/assets/icons/fb-fans.png')
    })

    this.activeStep = 'step-2'
    this.validateSelectedSource()
  },
  beforeDestroy () {
    try {
      sessionStorage.removeItem(SESSION_KEYS.isPopupOpen)
    } catch (error) {}
  },
  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('Me/StoreChannels', ['addFBChannel']),
    async validateSelectedSource () {
      if (!this.selectedSource.id) {
        this.$message.error(this.$t('Setting.ChannelBind.message.channel_not_selected'))
        return
      }
      this.isChannelLoading = true
      try {
        if (this.isFBBuyStore) {
          this.channels = await getAvailableFBFanPage(this.currentStore.id)
          this.activeStep = 'step-2'
        } else {
          const api = `/api/v1/channels/source/${this.selectedSource.id}`
          const { data: response } = await this.axios.get(api)
          this.channels = response.data
          // 判斷有無Channel
          this.channels.length ? this.activeStep = 'step-2' : this.activeStep = 'step-2-fbNoChannel'
        }
      } catch (e) {
        this.$message.error(this.$t('Setting.ChannelBind.message.channel_not_supported'))
        this.selectedSource = {}
      }
      this.isChannelLoading = false
    },
    isBinding (item) {
      return this.myChannels
        .map(channel => channel.endpoint)
        .some(id => id === item.id)
    },
    handleSelectChannel (item) {
      if (this.isBinding(item)) return
      if (this.selectedChannel.id === item.id) {
        this.selectedChannel = {}
      } else {
        this.selectedChannel = item
      }
    },
    async validateSelectedChannel () {
      if (!this.selectedChannel.id) {
        this.isInValidChannel = true
        return
      }

      if (this.isFBBuyStore) {
        try {
          await this.bindFBBuyFanPage()
          this.handleCloseDialog()
        } catch (error) {}
      } else {
        const success = await this.addFBChannel({
          id: this.selectedChannel.id,
          type: this.selectedSource.id
        })

        if (!success) return

        if (this.isEnterprise || this.isPhoneVerfied) {
          this.handleCloseDialog()
        } else {
          this.activeStep = 'step-3'
          this.selectedSource = {}
        }
        // FBbuy 店家 為了讓成功綁定訊息能夠非同步執行 在外面fetch channel
        this.$emit('reFetchChannel')
      }
    },
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    // FBBUY 商家綁定粉絲頁
    async bindFBBuyFanPage () {
      try {
        this.setLoading(true)
        await bindFBFanPage(this.currentStore.id, {
          id: this.selectedChannel.id,
          type: 'facebook-page'
        })
        this.setLoading(false)
        this.$emit('fbbuyBindSuccess', { type: FB_PAGE })
      } catch (error) {
        this.setLoading(false)
      }
    },
    goFBAuthURL () {
      try {
        sessionStorage.setItem(SESSION_KEYS.isPopupOpen, true)
      } catch (error) {}
      location.href = this.fbAuthUrl
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
