import axios from '@/plugins/http.js'

export const fetchOrderDetail = id => {
  const api = `/api/v1/orders/${id}`

  return axios.get(api)
}

export const updateOrderDetail = (id, putData) => {
  const api = `/api/v1/orders/${id}/items`

  return axios.put(api, putData)
}

// 完成訂單
export const completeOrderDetail = (id, postData) => {
  const api = `/api/v1/orders/${id}/complete`

  return axios.post(api, postData)
}

// 線下版 - OfflineAtm
// 更新 匯款資訊
export const putBankReceipts = (id, payload) => {
  const api = `/api/v1/orders/${id}/bank-receipts`

  return axios.put(api, payload)
}

// 線下版 - OfflineAtm
// 更新 付款狀態
export const putOfflineAtmReview = (storeName, payload) => {
  const api = `/api/v1/stores/${storeName}/orders/offline-atm-review`

  return axios.put(api, payload)
}

// 更新運送方式
export const updateOrderShipping = (id, putData) => {
  const api = `/api/v1/orders/${id}/shipping-method`

  return axios.put(api, putData)
}
