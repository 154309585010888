import BaseApi from './baseApi'
import axios from '@/plugins/http.js'
import storeId from './_storeId'

export default class StoreWalletBook extends BaseApi {
  uri = `/api/v1/stores/${storeId()}/books/store-wallet`

  export = async params => {
    try {
      const response = await axios({
        url: `/api/v1/stores/${storeId()}/books/store-wallet/export`,
        method: 'get',
        responseType: 'blob',
        params
      })

      const contentDisposition = response.headers['content-disposition']
      let fileName = 'store_wallets.xlsx'
      let matches = contentDisposition.match(/filename=(.+)/)
      if (matches != null) {
        fileName = matches[1]
      }
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link).click()

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }
}
