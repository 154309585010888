<script>
import Lottie from '@/components/Lottie'
import * as animationData from './lottery.json'

export default {
  name: 'DrawAnimation',

  components: {
    'lottie': Lottie
  },

  data () {
    return {
      defaultOptions: { animationData: animationData.default },
      animationSpeed: 1
    }
  }

}
</script>

<template lang="pug" src="./DrawAnimation.pug"></template>
<style lang="scss" src="./DrawAnimation.scss" scoped></style>
