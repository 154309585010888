import axios from '@/plugins/http.js'

export function updateSetting (storeId, setting) {
  const api = `/api/v1/stores/${storeId}/store-setting`
  return axios.put(api, setting)
}

export function getFestivals (storeId) {
  const api = `/api/v1/stores/${storeId}/festivals`
  return axios.get(api)
}
