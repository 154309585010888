import BaseApi from './baseApi'
import axios from '@/plugins/http.js'
import storeId from './_storeId'

export default class Order extends BaseApi {
  uri = `/api/v1/orders`
  uriList = `/api/v1/stores/${storeId()}/orders`
  // 訂單狀態 ('init', 'paid', 'canceled', 'delivering', 'delivered', 'completed', 'returning', 'returned')

  // 取消訂單
  cancel = async (id, postData) => {
    const api = `/api/v1/orders/${id}/cancel`

    try {
      await axios.post(api, postData)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 訂單運送中
  delivering = async (id, form) => {
    const api = `/api/v1/orders/${id}/delivering`
    const params = { ...form }

    try {
      await axios.post(api, params)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 完成訂單
  complete = async id => {
    const api = `/api/v1/orders/${id}/complete`

    try {
      await axios.post(api)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 完成退貨
  returned = async (id, postData) => {
    const api = `/api/v1/orders/${id}/returned`

    try {
      await axios.post(api, postData)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  writeRefundMemo = async (id, postData) => {
    const api = `/api/v1/orders/${id}/write-refund-memo`

    try {
      await axios.post(api, postData)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 發送催繳通知 (Order)
  sendOrderNotification = orderId => {
    const api = `/api/v1/orders/${orderId}/notification/payment`
    return axios.post(api)
  }

  // 發送催繳通知 (Cart)
  sendCartNotification = cartId => {
    const api = `/api/v1/carts/${cartId}/notify`
    return axios.post(api)
  }

  downloadPurchasingOrder = async () => {
    axios({
      url: `/api/v1/stores/${storeId()}/products/purchasing-orders`,
      method: 'GET',
      responseType: 'blob'
    }).then(response => {
      const contentDisposition = response.headers['content-disposition']
      let fileName = 'purchasing-order.xlsx'
      let matches = contentDisposition.match(/filename=(.+)/)
      if (matches != null) {
        fileName = matches[1]
      }
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    })
  }

  downloadUndeliveredOrder = async orderSlugs => {
    try {
      const response = await axios({
        url: `/api/v1/stores/${storeId()}/orders/export`,
        method: 'POST',
        responseType: 'blob',
        data: { order_slugs: orderSlugs }
      })
      const contentDisposition = response.headers['content-disposition']
      let fileName = 'undelivered-order.xlsx'
      let matches = contentDisposition.match(/filename=(.+)/)
      if (matches != null) {
        fileName = matches[1]
      }
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  downloadAllOrder = async params => {
    try {
      const response = await axios({
        url: `/api/v1/stores/${storeId()}/orders/export-all-order`,
        method: 'get',
        responseType: 'blob',
        params
      })

      const contentDisposition = response.headers['content-disposition']
      let fileName = 'all-order.xlsx'
      let matches = contentDisposition.match(/filename=(.+)/)
      if (matches != null) {
        fileName = matches[1]
      }
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 訂單已收到款項 (payment custom)
  pay = async (id) => {
    const api = `/api/v1/orders/${id}/pay`

    try {
      await axios.post(api)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 重產超商代碼
  regenerateCvs = (id) => {
    const api = `/api/v1/orders/${id}/payment-account/re-generate`
    return axios.post(api)
  }

  putBankReceipts = (id, payload) => {
    const api = `/api/v1/orders/${id}/bank-receipts`
    return axios.put(api, payload)
  }

  putOfflineAtmReview = (storeName, payload) => {
    const api = `/api/v1/stores/${storeName}/orders/offline-atm-review`
    return axios.put(api, payload)
  }

  getOfflineAtmReview = (storeName, params) => {
    const api = `/api/v1/stores/${storeName}/orders/offline-atm-review`
    return axios.get(api, { params })
  }

  split = (id, payload) => {
    const api = `/api/v1/orders/${id}/split`
    return axios.post(api, payload)
  }

  fetchOrdersById = (storeName, ids) => {
    const idParam = ids.reduce((acc, val) => acc + `id[]=${val}&`, '')
    const api = `/api/v1/stores/${storeName}/orders?all&${idParam}`
    return axios.get(api)
  }

  // 手動成立訂單
  manuallyCreate = (params) => {
    const api = `/api/v1/stores/${storeId()}/orders/manual`
    return axios.post(api, params)
  }
}
