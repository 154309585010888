var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticketTable"},[_c('AppTable',{attrs:{"columns":_vm.columns,"data":_vm.data},scopedSlots:_vm._u([{key:"td-name",fn:function(ref){
var ref_item = ref.item;
var name = ref_item.name;
var images = ref_item.images;
return [_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"product-image"},[_c('base-image',{attrs:{"src":images[0] && images[0].url,"type":"product","size":"40"}})],1),_c('div',{staticClass:"product-name break-all ml-3 mb-0"},[_vm._v(_vm._s(name))])])]}},{key:"td-price",fn:function(ref){
var price = ref.item.price;
return [_c('span',[_vm._v(_vm._s(_vm.currencySymbol)+" "+_vm._s(_vm._f("currency")(price)))])]}},{key:"td-saleTime",fn:function(ref){
var ref_item = ref.item;
var saleStartedAt = ref_item.saleStartedAt;
var saleEndedAt = ref_item.saleEndedAt;
return [(saleStartedAt && saleEndedAt)?_c('div',{staticClass:"flex flex-col"},[_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(saleStartedAt,'YYYY/MM/DD HH:mm')))]),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(saleEndedAt,'YYYY/MM/DD HH:mm')))])]):(saleStartedAt && !saleEndedAt)?_c('div',{staticClass:"flex flex-col"},[_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(saleStartedAt,'YYYY/MM/DD HH:mm')))]),_c('span',[_vm._v(_vm._s(_vm.$t('Components.ProductFormOther.permanent_sale')))])]):_c('div',{staticClass:"flex flex-col"},[_c('span',[_vm._v("-")])])]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center bg-white mt-2"},[_c('div',{staticClass:"empty text-center"},[_c('img',{staticClass:"mb-4",attrs:{"src":_vm.imgEmpty,"srcset":(_vm.imgEmpty + " 2x")}}),_c('p',{staticClass:"text-gray-800 mb-4"},[_vm._v(_vm._s(_vm.$t('Ticket.TicketTable.empty')))]),_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":_vm.handleCreateTicket}},[_vm._v(_vm._s(_vm.$t('Ticket.TicketTable.btn.create_first')))])],1)])]},proxy:true},{key:"btns",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ml-auto"},[_c('BaseButton',{staticClass:"mr-3",attrs:{"pattern":"outline"},on:{"click":function($event){return _vm.handleDelete(item)}}},[_vm._v(_vm._s(_vm.$t('button.delete')))]),_c('BaseButton',{attrs:{"pattern":"outline"},on:{"click":function($event){return _vm.handleEdit(item)}}},[_vm._v(_vm._s(_vm.$t('button.edit')))])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }