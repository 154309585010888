<script>
import VueTypes from 'vue-types'
import { secondsToTime, timeToSeconds } from '@/utils/timeOperation'
import { timeDurationPattern } from '@/constants/regexPattern'

export const actions = {
  USE_VIDEO_TIME: 'import_video_time',
  USE_ANCHOR_TIME: 'use_anchor_time'
}

export default {
  name: 'AnchorTimePicker',
  props: {
    originalAnchors: VueTypes.array.def([]),
    productId: VueTypes.number.def(NaN),
    getCurrentVideoTime: VueTypes.func.def(() => {}),
    lastAnchor: VueTypes.number.def(0),
    lastAnchorId: VueTypes.number.def(null),
    videoDuration: VueTypes.number.def(0)
  },
  data: function () {
    return {
      actions,
      currentAnchorTime: this.lastAnchor,
      currentEditAnchorId: this.lastAnchorId,
      formattedCurrentAnchorTime: secondsToTime(this.lastAnchor),
      secondsToTime
    }
  },
  computed: {
    exceedError () {
      return this.currentAnchorTime > this.videoDuration ? this.$t('Post.Replay.exceed_duration_error') : ''
    }
  },
  watch: {
    lastAnchor (newVal) { // watch clear all anchor action
      this.currentAnchorTime = newVal
      this.formattedCurrentAnchorTime = secondsToTime(newVal)
    }
  },
  methods: {
    handleCommand (command) {
      const currentVideoTime = this.getCurrentVideoTime()
      const secondsToBeUpdated = command.action === this.actions.USE_VIDEO_TIME ? currentVideoTime : command.seconds
      // 使用影片時間 -> 維持原本的 anchorid, 選擇曝光時間 -> id 要換成該曝光時間的錨點 id
      const anchorId = command.action === this.actions.USE_VIDEO_TIME ? this.lastAnchorId : command.anchorId
      this.currentEditAnchorId = anchorId
      this.formattedCurrentAnchorTime = secondsToTime(secondsToBeUpdated)

      this.currentAnchorTime = secondsToBeUpdated
      this.$emit('updateAnchorTime', {
        productId: this.productId,
        anchorId,
        anchor: secondsToBeUpdated
      })
    },
    handleAnchorInputChange (value) {
      const seconds = timeToSeconds(value, '：')
      this.formattedCurrentAnchorTime = seconds <= this.videoDuration ? this.checkAnchorInput(value) : '00：00：00'
      this.currentAnchorTime = seconds
      this.$emit('updateAnchorTime', {
        anchor: seconds,
        productId: this.productId,
        anchorId: this.currentEditAnchorId
      })
    },
    checkAnchorInput (value) {
      return timeDurationPattern.test(value) ? value : '：：'
    },
    formatAnchorInput (value) {
      const groups = value.match(timeDurationPattern)
      if (!groups) return '00：00：00'

      const autoFillZero = groups.slice(1, 4).map((anchor) => {
        if (!anchor) return '00'
        if (parseInt(anchor) < 10) return `0${parseInt(anchor)}`
        return anchor
      }).join('：')
      this.formattedCurrentAnchorTime = autoFillZero
    }
  }
}
</script>
<template lang="pug" src="./template.pug"></template>
