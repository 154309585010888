import store from '@/store'

export default async (to, from, next) => {
  // 有無登入
  if (!store.getters['Me/isLogin']) {
    // 紀錄嘗試進入的路徑，以便登陸後轉導
    sessionStorage.setItem('loginFailPath', to.fullPath)
    return next({ name: 'UserLogin' })
  } else {
    sessionStorage.removeItem('loginFailPath')
  }

  // 有無註冊
  if (!store.getters['Me/hasStores']) {
    return next({ path: `/register` })
  }

  // 確認是否為 FB 註冊商城
  if (store.getters['Me/isFBBuyStore'] && to.name !== 'Setting') {
    return next({ path: `/${store.getters['Me/currentStore'].slug}/setting` })
  }

  // 判斷登入的商城，有無在 stores 清單裡
  const ownedStore = store.getters['Me/ownedStore']
  if (!ownedStore) {
    return next({ name: '403' })
  }

  return next()
}
