<script>
import VueTypes from 'vue-types'

export default {
  name: 'DialogSendNotification',
  components: {},
  props: {
    dialogVisible: VueTypes.bool.def(false),
    storeName: VueTypes.string.isRequired,
    pageName: VueTypes.string.isRequired,
    order: VueTypes.object.isRequired
  },
  data () {
    return {
      showCheckbox: false,
      isBtnLoading: false
    }
  },
  methods: {
    handleCancel () {
      this.$emit('update:dialogVisible', false)
    },
    handleSubmit () {
      this.isBtnLoading = true
      if (this.showCheckbox) {
        localStorage.setItem('not_show_notification', true)
      }
      this.$emit('sendNotification')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
