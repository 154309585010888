<script>
import BadgeImage from '@/components/BadgeImage'
import getSocialIcon from '@/utils/getSocialIcon'
import VueTypes from 'vue-types'

export default ({
  name: 'MemberInfo',
  components: { BadgeImage },
  props: {
    memberName: VueTypes.string.def(''),
    memberAvatar: VueTypes.string.def(''),
    provider: VueTypes.object.def({}),
    isBlackList: VueTypes.bool.def(false)
  },
  methods: {
    getSocialIcon
  }
})
</script>
<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
