<script>
import _uniqBy from 'lodash/uniqBy'
export default {
  name: 'ImportFail',
  props: {
    errors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    title () {
      const failed = _uniqBy(this.filteredErrors, 'slug').length
      if (failed > 0) {
        return this.$t('Order.ImportFail.title1', { count: failed })
      } else {
        return this.$t('Order.ImportFail.title2')
      }
    },
    filteredErrors () {
      return this.errors.filter(x => x.slug)
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
