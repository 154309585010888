import ProductDetail from './ProductDetail'
import CreateProduct from './CreateProduct'
import UpdateProduct from './UpdateProduct'

export default {
  namespaced: true,
  modules: {
    ProductDetail,
    CreateProduct,
    UpdateProduct
  }
}
