import CheckToken from './CheckToken'
import DeleteToken from './DeleteToken'

export default {
  namespaced: true,
  modules: {
    CheckToken,
    DeleteToken
  }
}
