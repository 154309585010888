import { render, staticRenderFns } from "./DialogMarquee.pug?vue&type=template&id=29edcaa6&scoped=true&lang=pug&"
import script from "./DialogMarquee.vue?vue&type=script&lang=js&"
export * from "./DialogMarquee.vue?vue&type=script&lang=js&"
import style0 from "./DialogMarquee.scss?vue&type=style&index=0&id=29edcaa6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29edcaa6",
  null
  
)

export default component.exports