<script>
import PurchaseDetail from './components/PurchaseDetail'
import PricingSheet from './components/PricingSheet'
import DialogUpdateOrder from './components/DialogUpdateOrder'
import { mapActions } from 'vuex'
import { PRODUCT_ACTION_ADD } from '@/constants/orderManual'

export default {
  name: 'ShoppingDetails',
  components: { PricingSheet, DialogUpdateOrder, PurchaseDetail },
  data () {
    return {
      dialogUpdateOrderVisible: false,
      buttonAction: PRODUCT_ACTION_ADD
    }
  },
  methods: {
    ...mapActions('OrderManual/ShoppingDetails', ['updateProducts']),
    toggleDialogUpdateOrder (status, action) {
      this.dialogUpdateOrderVisible = status
      this.buttonAction = action
    },
    handleUpdateOrder (items) {
      /**
       * price: 200
        product_name: "testproduct5"
        quantity: 3
        sku_id: 22
        sku_name: "單一規格2"
       */
      this.updateProducts(items)
      this.toggleDialogUpdateOrder(false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
