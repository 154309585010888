<script>
import VueTypes from 'vue-types'

export default {
  name: 'ApproveDialog',
  props: {
    count: VueTypes.number.def(0)
  },
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    submit () {
      this.$emit('submit')
    }
  }
}
</script>

<template lang="pug" src="./ApproveDialog.pug"></template>
<style lang="scss" src="./ApproveDialog.scss" scoped></style>
