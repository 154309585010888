<script>
import '@/utils/copyText'
import ApiPost from '@/api/post'

export default {
  name: 'DialogLiveAuthorization',
  props: {
    token: {
      type: String,
      required: true
    },
    expiredAt: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      newToken: '',
      newExpiredAt: ''
    }
  },
  computed: {
    inviteUrl () {
      return `${location.origin}/celebrity/${this.newToken || this.token}`
    },
    isExpired () {
      const date = this.newExpiredAt || this.expiredAt
      return new Date(date) < new Date()
    }
  },
  methods: {
    copyLink () {
      this.copyText({
        url: this.inviteUrl
      })
    },
    async handleFetchInviteToken () {
      const apiPost = new ApiPost()
      const token = this.newToken || this.token

      const { token: newToken, expired_at: newExpiredAt } = await apiPost.renewPostAgentToken({ token })

      this.newToken = newToken
      this.newExpiredAt = newExpiredAt
      this.$emit('reFetch')
    },
    handleCloseDialog () {
      this.$emit('update:visible', false)
      this.$emit('handleClose')
    }

  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
