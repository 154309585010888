<script>

import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

import LineImg from '@/assets/line-add-friend-zh.png'

export default {
  name: 'DialogNewebpay',

  props: {
    setting: VueTypes.object.def({}),
    editable: VueTypes.bool.def(false)
  },

  data () {
    return {
      LineImg,
      isLoading: false,
      ruleForm: {
        merchant_id: '',
        hash_key: '',
        hash_iv: ''
      },
      rules: {
        merchant_id: [
          {
            required: true,
            message: this.$t('Setting.Seller.DialogNewebpay.validate_merchant_id')
          }
        ],
        hash_key: [
          {
            required: true,
            message: this.$t('Setting.Seller.DialogNewebpay.validate_hash_key')
          }
        ],
        hash_iv: [
          {
            required: true,
            message: this.$t('Setting.Seller.DialogNewebpay.validate_hash_iv')
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters('Me', ['lineLink'])
  },

  created () {
    this.initForm()
  },

  methods: {
    initForm () {
      const initData = {
        merchant_id: _.get(this.setting, 'info.merchant_id', ''),
        hash_key: '',
        hash_iv: ''
      }

      this.ruleForm = initData
    },
    async handleSubmit () {
      // 驗證欄位
      try {
        await this.$refs.ruleForm.validate()
      } catch (error) {
        return
      }

      const info = {
        merchant_id: this.ruleForm.merchant_id,
        secret: {
          hash_key: this.ruleForm.hash_key,
          hash_iv: this.ruleForm.hash_iv
        }
      }

      const data = {
        type: this.setting.type,
        enabled: true,
        info
      }

      this.isLoading = true
      this.$emit('submit', data)
    },
    handleClose () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogNewebpay.pug"></template>
<style lang="scss" scoped>
  img.lineImg {
    height: 20px;
  }
</style>
