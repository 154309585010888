<script>
// https://github.com/dwightjack/vue-types#documentation
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

const STATUS_TYPE = {
  'coming': 'coming',
  'ongoing': 'ongoing',
  'expired': 'expired'
}

const STATUS_I18N_MAP = {
  [STATUS_TYPE.coming]: 'Coupon.status_coming',
  [STATUS_TYPE.ongoing]: 'Coupon.status_ongoing',
  [STATUS_TYPE.expired]: 'Coupon.status_expired'
}

const STATUS_CLASS = {
  'coming': 'text-warning',
  'ongoing': 'text-success',
  'expired': 'text-gray-800'
}

export default {
  name: 'List',
  props: {
    couponList: VueTypes.array.def([])
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol'])
  },
  methods: {
    convertStatusI18n (key) {
      return this.$t(STATUS_I18N_MAP[key])
    },
    calStatusType (status) {
      return this.convertStatusI18n(STATUS_TYPE[status]) || status
    },
    calStatusClass (status) {
      return STATUS_CLASS[status] || status
    },
    handleShowHistory (coupon) {
      this.$emit('handleShowHistory', JSON.parse(JSON.stringify(coupon)))
    },
    handleEditCoupon (coupon) {
      this.$emit('handleEditCoupon', JSON.parse(JSON.stringify(coupon)))
    }
  }
}
</script>

<template lang="pug" src="./List.pug"></template>
<style lang="scss" src="./List.scss" scoped></style>
