<script>
import { mapGetters } from 'vuex'
import VueTypes from 'vue-types'
import AddressSelect from '@/components/AddressSelect'
import GmoFormMixin from '../../mixins/GmoFormMixin'

export default {
  name: 'Step1',
  components: { AddressSelect },
  mixins: [GmoFormMixin],
  props: {
    form: VueTypes.object.def({}),
    editDisabled: VueTypes.bool.def(false)
  },
  data () {
    return {
      typeOption: [
        { text: '個人', value: 'individual' },
        { text: '法人', value: 'corporation' }
      ],
      errorStep1: {
        type: [ { required: true, message: '事業形態を入力してください' } ],
        corp_name: [ { required: true, message: '会社名を入力してください' } ],
        corp_name_kana: [ { required: true, message: '会社名(ﾌﾘｶﾞﾅ)を入力してください' } ],
        corp_number: [
          { required: true, message: '法人番号を入力してください' },
          { pattern: /^[0-9]{13}$/, message: '13桁の法人番号をご入力お願いします' }
        ],
        corp_url: [ { required: true, message: '会社ホームページＵＲＬを入力してください' } ],
        corp_address_kana: [ { required: true, message: '会社所在地(ﾌﾘｶﾞﾅ)を入力してください' } ],
        corp_tel: [
          { required: true, message: '会社連絡先(電話番号)を入力してください' },
          { pattern: /^[0-9]+$/, message: '番号を入力してください' }
        ],
        corp_date_establishment: [ { required: true, message: '設立年月日を入力してください' } ],
        corp_capital: [
          { required: true, message: '資本金を入力してください' },
          { pattern: /^[0-9]+$/, message: '数字を入力してください。' }
        ],
        corp_num_employees: [
          { required: true, message: '従業員数を入力してください' },
          { pattern: /^[0-9]+$/, message: '数字を入力してください。' }
        ],
        corp_sales: [
          { required: true, message: '年商を入力してください' },
          { pattern: /^[0-9]+$/, message: '数字を入力してください。' }
        ],
        corp_business: [ { required: true, message: '業種・業態を入力してください' } ],
        corp_category: [ { required: true, message: '事業内容を入力してください' } ],
        corp_email: [
          { required: true, message: 'Eメールアドレスを入力してください' },
          { type: 'email', message: '正しいメールアドレスを入力してください' }
        ],
        rep_name_last: [ { required: true, message: '代表者姓を入力してください' } ],
        rep_name_first: [ { required: true, message: '代表者名を入力してください' } ],
        rep_name_kana_last: [ { required: true, message: '代表者姓(ﾌﾘｶﾞﾅ)を入力してください' } ],
        rep_name_kana_first: [ { required: true, message: '代表者名(ﾌﾘｶﾞﾅ)を入力してください' } ],
        rep_birthday: [ { required: true, message: '代表者生年月日を入力してください' } ],
        rep_address_kana: [ { required: true, message: '代表者住所(ﾌﾘｶﾞﾅ)を入力してください' } ],
        rep_tel: [
          { required: true, message: '代表者連絡先(電話番号)を入力してください' },
          { pattern: /^[0-9]+$/, message: '番号を入力してください' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['region']),
    validateStep1 () {
      return {
        type: this.form.type,
        corp_name: this.form.corp_name,
        corp_name_kana: this.form.corp_name_kana,
        corp_number: this.form.corp_number,
        corp_url: this.form.corp_url,
        corp_address_kana: this.form.corp_address_kana,
        corp_tel: this.form.corp_tel[0] && this.form.corp_tel[1] && this.form.corp_tel[2]
          ? this.form.corp_tel[0] + this.form.corp_tel[1] + this.form.corp_tel[2] : '',
        corp_date_establishment: this.form.corp_date_establishment,
        corp_capital: this.form.corp_capital,
        corp_num_employees: this.form.corp_num_employees,
        corp_sales: this.form.corp_sales,
        corp_business: this.form.corp_business,
        corp_category: this.form.corp_category,
        corp_email: this.form.corp_email,
        rep_name_last: this.form.rep_name_last,
        rep_name_first: this.form.rep_name_first,
        rep_name_kana_last: this.form.rep_name_kana_last,
        rep_name_kana_first: this.form.rep_name_kana_first,
        rep_birthday: this.form.rep_birthday,
        rep_address_kana: this.form.rep_address_kana,
        rep_tel:
          this.form.rep_tel[0] && this.form.rep_tel[1] && this.form.rep_tel[2]
            ? this.form.rep_tel[0] + this.form.rep_tel[1] + this.form.rep_tel[2] : ''
      }
    }
  },
  methods: {
    async validate () {
      try {
        await this.$refs['formStep1'].validate()
        await this.$refs['corp_address'].validateForm()
        if (this.$refs['rep_address']) {
          await this.$refs['rep_address'].validateForm()
        }
        return true
      } catch (error) {
        throw error
      }
    }
  }
}
</script>

<template lang="pug" src="./Step1.pug"></template>
