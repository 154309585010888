<script>
import { sendVerification } from '@/views/User/service/apis'
import LayoutCard from '../components/LayoutCard'

const TIME_LIMIT = 60
const TIME_INTERVAL = 1000

export default {
  name: 'EmailVerify',
  components: { LayoutCard },
  skipMiddleware: ['getAuth', 'checkAuth'],
  data () {
    return {
      timerInterval: null,
      timePassed: 0,
      email: this.$route.query.email,
      userId: this.$route.query.userId,
      loading: false
    }
  },
  computed: {
    timeLeft () {
      return Math.max(TIME_LIMIT - this.timePassed, 0)
    }
  },
  mounted () {
    this.startTimer()
    if (!this.email || !this.userId) {
      this.$router.push({ name: 'UserRegister' })
    }
  },
  beforeDestroy () {
    clearInterval(this.timerInterval)
  },
  methods: {
    startTimer () {
      this.timerInterval = setInterval(() => (this.timePassed += 1), TIME_INTERVAL)
    },
    async submit () {
      if (this.timeLeft > 0) return

      try {
        this.loading = true
        await sendVerification(this.userId)
        this.$message.success(this.$t('User.EmailVerify.send_success'))
        this.timePassed = 0
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    }
  }
}
</script>

<template lang="pug" src="./EmailVerify.pug"></template>
<style lang="scss" src="./EmailVerify.scss" scoped></style>
