<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderParentChildren',
  props: {
    parent: VueTypes.object.def(() => null),
    children: VueTypes.array.def([])
  },
  computed: {
    ...mapGetters('Me', ['storeName']),
    showSelf () {
      return this.parent || this.children.length > 0
    }
  }
}
</script>

<template lang="pug" src="./OrderParentChildren.pug"></template>
<style lang="scss" src="./OrderParentChildren.scss" scoped></style>
