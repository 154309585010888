<script>
import { mapGetters } from 'vuex'
import VueTypes from 'vue-types'
import AddressSelect from '@/components/AddressSelect'
import GmoFormMixin from '../../mixins/GmoFormMixin'

export default {
  name: 'Step4',
  components: { AddressSelect },
  mixins: [GmoFormMixin],
  props: {
    form: VueTypes.object.def({}),
    editDisabled: VueTypes.bool.def(false)
  },
  data () {
    return {
      errorStep4: {
        send_company: [ { required: true, message: '会社名を入力してください' } ],
        send_name_last: [ { required: true, message: 'ご担当者姓を入力してください' } ],
        send_name_first: [ { required: true, message: 'ご担当者名を入力してください' } ],
        send_department: [ { required: true, message: '所属部署名を入力してください' } ],
        send_tel: [
          { required: true, message: '電話番号を入力してください' },
          { pattern: /^[0-9]+$/, message: '番号を入力してください' }
        ],
        send_email: [
          { required: true, message: 'e-mailを入力してください' },
          { type: 'email', message: '正しいメールアドレスを入力してください' }
        ],
        bank_code: [
          { required: true, message: '銀行代碼を入力してください' },
          { pattern: /^[0-9]+$/, message: '番号を入力してください' }
        ],
        bank_shop_num: [
          { required: true, message: '支店代碼を入力してください' },
          { pattern: /^[0-9]+$/, message: '番号を入力してください' }
        ],
        bank_type: [ { required: true, message: '帳戶類型を入力してください' } ],
        bank_num: [
          { required: true, message: '口座番号を入力してください' },
          { pattern: /^[0-9]+$/, message: '番号を入力してください' }
        ],
        bank_name: [ { required: true, message: '口座名義を入力してください' } ],
        checkTerms: [ { required: true, message: '確認してください' } ]
      },
      bankTypeOption: [
        { text: '普通', value: '普通' },
        { text: '当座', value: '当座' },
        { text: '貯蓄', value: '貯蓄' }
      ]
    }
  },
  computed: {
    ...mapGetters('Me', ['region']),
    validateStep4 () {
      return {
        send_company: this.form.send_company,
        send_name_last: this.form.send_name_last,
        send_name_first: this.form.send_name_first,
        send_department: this.form.send_department,
        send_tel: this.form.send_tel[0] && this.form.send_tel[1] && this.form.send_tel[2]
          ? this.form.send_tel[0] + this.form.send_tel[1] + this.form.send_tel[2] : '',
        send_email: this.form.send_email,
        bank_code: this.form.bank_code,
        bank_shop_num: this.form.bank_shop_num,
        bank_type: this.form.bank_type,
        bank_num: this.form.bank_num,
        bank_name: this.form.bank_name,
        checkTerms: this.form.checkTerms1 && this.form.checkTerms2 && this.form.checkTerms3 ? true : ''
      }
    }
  },
  methods: {
    async validate () {
      try {
        await this.$refs['formStep4'].validate()
        await this.$refs['send_address'].validateForm()
        return true
      } catch (error) {
        throw error
      }
    }
  }
}
</script>

<template lang="pug" src="./Step4.pug"></template>
