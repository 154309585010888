<script>
import { mapActions, mapGetters } from 'vuex'

import * as COUPON_STATUS from '@/constants/couponStatus'

import PaginationPure from '@/components/PaginationPure'

import VoucherTable from './components/VoucherTable'
import DialogVoucherDetail from './components/DialogVoucherDetail'

export default {
  name: 'Voucher',

  components: { VoucherTable, PaginationPure, DialogVoucherDetail },

  data () {
    return {
      showVoucherDetail: false,
      editVoucher: null,
      showVoucherHistory: false,
      historyVoucher: null
    }
  },

  computed: {
    ...mapGetters('Voucher', [
      'vouchers',
      'paginator',
      'search'
    ]),

    statusOption () {
      return [
        { value: '', label: this.$t('Voucher.List.acquire_status') },
        { value: COUPON_STATUS.COMING, label: this.$t('Coupon.status_coming') },
        { value: COUPON_STATUS.ONGOING, label: this.$t('Coupon.status_ongoing') },
        { value: COUPON_STATUS.EXPIRED, label: this.$t('Coupon.status_expired') },
        { value: COUPON_STATUS.INTERRUPTED, label: this.$t('Coupon.status_interrupted') }
      ]
    }
  },

  created () {
    this.fetchVoucherList(true)
  },

  methods: {
    ...mapActions('Voucher', [
      'fetchVoucherList',
      'updateSearchStatus',
      'updateSearchName',
      'updateSearchPage'
    ]
    ),

    handleClearSearch () {
      this.fetchVoucherList(true)
    },
    handleCreateVoucher () {
      this.editVoucher = null
      this.showVoucherDetail = true
    },
    handleEditVoucher (voucher) {
      this.editVoucher = voucher
      this.showVoucherDetail = true
    },
    handleShowHistory (voucher) {
      this.historyVoucher = voucher
      this.showVoucherHistory = true
    }

  }
}
</script>

<template lang="pug" src="./Voucher.pug"></template>
