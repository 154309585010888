import { MessageBox } from 'element-ui'
import dateFormat from 'date-fns/format'
import store from '@/store'
import { TW, JP } from '@/constants/regions'
import router from '@/router'
import i18n from '@/plugins/i18n'

// empty means guard all routes
const GUARDED_ROUTES = {
  [TW]: [],
  [JP]: ['PostCreate', 'ProductCreate']
}

export default (to, from, next) => {
  const region = store.getters['Me/region']
  if (!region || (GUARDED_ROUTES[region].length > 0 && !GUARDED_ROUTES[region].includes(to.name))) {
    return next()
  }
  const checkFunction = {
    [TW]: twCheckPlanMiddleware,
    [JP]: jpCheckPlanMiddleware
  }
  checkFunction[region](to, from, next)
}

function twCheckPlanMiddleware (to, from, next) {
  // XXX-hutw: 這感覺應該要放在checkAuth, 或getStore 比較適當
  const currentStore = store.getters['Me/currentStore']
  if (!currentStore) {
    return next({ name: '403' })
  }

  /**
   * 沒過期提早離開, 繼續使用系統
   */
  const planExpired = currentStore.plan_is_expired
  if (!planExpired) {
    return next()
  }

  /**
   * 過期但登入的只是小幫手, 只顯示過期畫面, 快叫媽媽(owner)登入付錢阿
   */
  const isCurrentStoreOwner = store.getters['Me/isCurrentStoreOwner']
  if (!isCurrentStoreOwner) {
    // 小幫手
    return next({ path: `/${currentStore.slug}/plans/expired` })
  }

  /**
   * 過期且登入為owner, 導去付系統費畫面
   */
  const paymentComponents = ['AccountManagement', 'AccountManagementInstructions']
  const paymentPath = `/${currentStore.slug}/account-management/account`

  return paymentComponents.includes(to.name)
    ? next()
    : next({ path: paymentPath })
}

function jpCheckPlanMiddleware (to, from, next) {
  if (jpCheckPlan()) {
    return next()
  } else {
    return next({ name: 'Dashboard', params: { store: store.getters['Me/storeName'] } })
  }
}

// exported for use in components
export function jpCheckPlan () {
  const region = store.getters['Me/region']
  if (region !== JP) return true

  const currentStore = store.getters['Me/currentStore']
  if (!currentStore) return false

  const planExpired = currentStore.plan_is_expired
  if (!planExpired) return true

  const isCurrentStoreOwner = store.getters['Me/isCurrentStoreOwner']
  const isChildStore = store.getters['Me/isChildStore']
  const title = i18n.t('seller_subscription.plan_expire.title')
  const expireDate = dateFormat(store.getters['Me/currentStore'].plan_expired_at, 'YYYY/MM/DD')

  let message = i18n.t('seller_subscription.plan_expire.content', { date: expireDate })
  let confirmButtonText = i18n.t('seller_subscription.plan_expire.action')
  if (isChildStore) {
    message = i18n.t('seller_subscription.plan_expire.content_childstore', { date: expireDate })
    confirmButtonText = i18n.t('button.confirm')
  } else if (!isCurrentStoreOwner) {
    message = i18n.t('seller_subscription.plan_expire.content_helper', { date: expireDate })
    confirmButtonText = i18n.t('button.confirm')
  }

  MessageBox({
    title,
    center: true,
    closeOnClickModal: false,
    customClass: 'confirm-box',
    message,
    confirmButtonText
  })
    .then(() => {
      if (isCurrentStoreOwner && !isChildStore) {
        router.push({ name: 'Setting', query: { activeMenu: 'SettingPlan' } })
      }
      return false
    })
    .catch(() => {
      return false
    })
}
