export const ORDERER_TYPE = {
  Guest: 'Guest',
  SelectFromMembers: 'SelectFromMembers'
}

export const FORM_REFS = {
  FormHomeDelivery: 'FormHomeDelivery',
  FormSelfPickUp: 'FormSelfPickUp',
  FormFamily: 'FormFamily',
  FormSeven: 'FormSeven'
}

export const MANUAL_ORDER_CACHE = 'manual-order-cache'
export const PRODUCT_ACTION_EDIT = 'edit_product'
export const PRODUCT_ACTION_ADD = 'add_product'
