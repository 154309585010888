<script>
import { mapGetters } from 'vuex'

import ApiAuction from '@/api/auction'

export default {
  name: 'ShowActivityEnd',
  props: {
    post: {
      type: Object,
      default: null
    },
    currentActivity: {
      type: Object,
      default: null
    },
    preSelectedComments: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      winners: [],
      defaultAvator: `this.src='${require('@/assets/icon-default-user-md.png')}'`,
      isBtnLoading: false
    }
  },

  computed: {
    ...mapGetters('Me', ['currencySymbol']),
    normalizeActivity () {
      if (this.currentActivity) {
        return {
          productName: this.currentActivity.skus[0].product.name,
          productPrice: this.currentActivity.skus[0].price
        }
      } else {
        return null
      }
    },
    normalizeComments () {
      if (this.preSelectedComments.length !== 0) {
        return this.preSelectedComments.map(comment => {
          const commentId = comment.comment_id
          const fromId = comment.from.id
          const fromName = comment.from.name
          const message = comment.message
          const originalTime = comment.createdDate

          return { commentId, fromId, fromName, message, originalTime }
        })
      } else if (this.winners && this.winners.length) {
        return this.winners.map(item => {
          const commentId = item.comment_id
          const fromId = item.comment.from_id
          const fromName = item.comment.from_name
          const message = item.comment.message
          const originalTime = item.comment.original_time

          return { commentId, fromId, fromName, message, originalTime }
        })
      } else {
        return []
      }
    },
    isActivityEnded () {
      // 判斷活動結束標籤
      return this.currentActivity.status === 'done'
    }
  },

  created () {
    this.fetchAuctionWinners()
  },

  methods: {
    async fetchAuctionWinners () {
      const apiAuction = new ApiAuction()
      const res = await apiAuction.getAuctionWinners(this.currentActivity.id)
      if (res) {
        this.winners = res
      }
    },
    async handleAuctionBidders () {
      this.isBtnLoading = true

      const postData = {
        skus: this.currentActivity.skus,
        winning_bidders: this.preSelectedComments.map(comment => {
          return {
            type: comment.type,
            data: { ...comment }
          }
        })
      }

      const apiAuction = new ApiAuction()
      const res = await apiAuction.createAuctionBidders(this.currentActivity.id, postData)

      if (res) {
        this.$emit('handleUpdateAuction')
        this.$emit('close')
      } else {
        this.isBtnLoading = false
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
