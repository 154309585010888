<script>
export default {
  name: 'SimpleDialog',
  props: {
    canClose: {
      type: Boolean,
      default: true
    },
    beforeClose: {
      type: Function,
      default: (done) => { done() }
    }
  },
  data () {
    return {
      dialogVisible: false
    }
  },
  methods: {
    toggle (isOpen = null) {
      this.dialogVisible = isOpen === null ? !this.dialogVisible : isOpen
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
