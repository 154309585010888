<script>
import { mapGetters } from 'vuex'
import Sticky from 'vue-sticky-directive'
import * as PLANS from '@/constants/plans'
import * as GMO_STATUS from '@/constants/gmoStatus'
import AppAlert from '@/components/AppAlert'

export default {
  name: 'SelectJp',
  directives: { Sticky },
  components: { AppAlert },
  data () {
    return {
      PLANS,
      dialogVisible: false
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore', 'gmoStatus']),
    currentPlan () {
      return this.currentStore.plan
    },
    disabledBtnRenew () {
      // GMO送出後不可變更
      return this.gmoStatus !== GMO_STATUS.DRAFT
    }
  },
  async created () {
    // [Facebook Pixel] 追蹤觀看方案畫面
    this.$analytics.fbq.event('ViewContent', { Page: 'plan' })
    // [Google Analytics] 追蹤建立直播事件
    this.$ga.event({
      eventCategory: 'ViewContent',
      eventAction: 'View-Plan',
      eventLabel: 'Merchant-Platform'
    })
    if (this.$route.query.showPlanTip) {
      this.dialogVisible = true
    }
  },

  methods: {
    planIsActive (planName) {
      return this.currentStore.plan.name === planName
    },
    selectPlan (plan) {
      localStorage.setItem('jpPlan', plan)
      sessionStorage.setItem('gmoShowPlan', true)
      this.$router.push({ name: 'GmoForm' })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
