import axios from '@/plugins/http.js'

export default class Ghn {
  getServiceTypes = (from, to) => {
    const api = `/api/v1/ghn/services?from_district_id=${from}&to_district_id=${to}`
    return axios.get(api)
  }

  getShippingFee = (payload) => {
    const api = '/api/v1/ghn/shipping-fee'
    return axios.get(api, {
      params: payload
    })
  }
}
