import axios from '@/plugins/http.js'

/**
 * 取得 fbbuy 設定
 */
export async function getStoreSetting (storeId, settingKeys) {
  const api = `/api/v1/stores/${storeId}/settings`
  const resp = await axios.get(api, {
    params: {
      name: settingKeys
    }
  })
  return resp.data.data
}

/**
 *
 * 讓後端跟 FBbuy 溝通綁定情況
 */
export function sendInfoToFBbuy (storeId) {
  return axios.post(`/api/v1/stores/${storeId}/fbbuy/binding`)
}

/**
 * 綁定 FBBUY 商家粉絲頁
 */
export async function bindFBFanPage (storeId, payload) {
  const creatChannelApi = `/api/v1/stores/${storeId}/channels/fbbuy`

  const resp = await axios.post(creatChannelApi, payload)

  if (resp.data.success) {
    const bindResult = await sendInfoToFBbuy(storeId)
    return bindResult
  }

  return false
}

/**
 * 取得 FBBUY 商家粉絲頁列表
 */
export async function getAvailableFBFanPage (storeId) {
  const api = '/api/v1/channels/source/facebook-page/fbbuy'
  const resp = await axios.get(api, {
    params: {
      store: storeId
    }
  })
  return resp.data.data
}
