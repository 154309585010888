<script>
import { mapGetters } from 'vuex'
import VueTypes from 'vue-types'
import ApiImage from '@/api/image'
import GmoFormMixin from '../../mixins/GmoFormMixin'

export default {
  name: 'Step2',
  mixins: [GmoFormMixin],
  props: {
    form: VueTypes.object.def({}),
    editDisabled: VueTypes.bool.def(false)
  },
  data () {
    return {
      errorStep2: {
        contact_name_last: [ { required: true, message: 'ご担当者姓を入力してください' } ],
        contact_name_first: [ { required: true, message: 'ご担当者名を入力してください' } ],
        contact_name_kana_last: [ { required: true, message: 'ご担当者姓(ﾌﾘｶﾞﾅ)を入力してください' } ],
        contact_name_kana_first: [ { required: true, message: 'ご担当者名(ﾌﾘｶﾞﾅ)を入力してください' } ],
        contact_department: [ { required: true, message: 'ご担当者部署を入力してください' } ],
        contact_title: [ { required: true, message: 'ご担当者役職名を入力してください' } ],
        contact_tel: [
          { required: true, message: 'ご担当者連絡先(電話番号)を入力してください' },
          { pattern: /^[0-9]+$/, message: '番号を入力してください' }
        ],
        contact_email: [
          { required: true, message: 'ご担当者連絡先(e-mail)を入力してください' },
          { type: 'email', message: '正しいメールアドレスを入力してください' }
        ],
        shop_name: [ { required: true, message: 'ネットショップ名を入力してください' } ],
        shop_name_kana: [ { required: true, message: 'ネットショップ名(ﾌﾘｶﾞﾅ)を入力してください' } ],
        shop_name_alphabet: [ { required: true, message: 'ネットショップ名（アルファベット)を入力してください' } ],
        shop_url: [ { required: true, message: 'ネットショップＵＲＬを入力してください' } ],
        shop_image: [ { required: true, message: 'ファイルをアップロードしてください' } ],
        item_category: [ { required: true, message: '取扱商品/サービスカテゴリを入力してください' } ],
        item: [ { required: true, message: '取扱商品内容/サービス内容を入力してください' } ],
        license: [ { required: true, message: '免許取得を入力してください' } ],
        license_name: [ { required: true, message: '免許名称を入力してください' } ],
        license_number: [ { required: true, message: '免許番号を入力してください' } ],
        inquiry_start_time: [ { required: true, message: '問い合わせ開始時間を入力してください' } ],
        inquiry_fin_time: [ { required: true, message: '問い合わせ終了時間を入力してください' } ]
      },
      productCategoryOption: [
        { text: '家電', value: '家電' },
        { text: '電化製品（家電以外）', value: '電化製品（家電以外）' },
        { text: 'ホビー・趣味用品', value: 'ホビー・趣味用品' },
        { text: '食品・水', value: '食品・水' },
        { text: '酒類', value: '酒類' },
        { text: 'アパレル', value: 'アパレル' },
        { text: 'ジュエリー・貴金属・腕時計', value: 'ジュエリー・貴金属・腕時計' },
        { text: '家具・インテリア', value: '家具・インテリア' },
        { text: '美容・健康グッズ', value: '美容・健康グッズ' },
        { text: '医療・医薬品', value: '医療・医薬品' },
        { text: '日用品', value: '日用品' },
        { text: '車・バイク用品', value: '車・バイク用品' },
        { text: '百貨店・総合通販・ギフト', value: '百貨店・総合通販・ギフト' },
        { text: 'ペット用品', value: 'ペット用品' },
        { text: '物販（その他）', value: '物販（その他）' }
      ],
      licenseOption: [
        { text: '有', value: '有' },
        { text: '無', value: '無' }
      ]
    }
  },
  computed: {
    ...mapGetters('Me', ['token', 'imageUploadUrl']),
    validateStep2 () {
      return {
        contact_name_last: this.form.contact_name_last,
        contact_name_first: this.form.contact_name_first,
        contact_name_kana_last: this.form.contact_name_kana_last,
        contact_name_kana_first: this.form.contact_name_kana_first,
        contact_department: this.form.contact_department,
        contact_title: this.form.contact_title,
        contact_tel: this.form.contact_tel[0] && this.form.contact_tel[1] && this.form.contact_tel[2]
          ? this.form.contact_tel[0] + this.form.contact_tel[1] + this.form.contact_tel[2] : '',
        contact_email: this.form.contact_email,
        shop_name: this.form.shop_name,
        shop_name_kana: this.form.shop_name_kana,
        shop_name_alphabet: this.form.shop_name_alphabet,
        shop_url: this.form.shop_url,
        shop_image: this.form.shop_image,
        item_category: this.form.item_category,
        item: this.form.item,
        license: this.form.license,
        license_name: this.form.license_name,
        license_number: this.form.license_number,
        inquiry_start_time: this.form.inquiry_start_time,
        inquiry_fin_time: this.form.inquiry_fin_time
      }
    },
    uploadHeaders () {
      return { Authorization: `Bearer ${this.token}` }
    }
  },
  methods: {
    async validate () {
      try {
        await this.$refs['formStep2'].validate()
        return true
      } catch (error) {
        throw error
      }
    },
    handleBeforeUpload (file) {
      const fileMB = file.size / (1024 ** 2)

      if (fileMB > 2) {
        this.$message.error('アップロードファイルのサイズは2.0 MBを超えることはできません')
        return false
      }
    },
    handleFileSuccess (response, file, fileList) {
      // eslint-disable-next-line vue/no-mutating-props
      this.form.shop_image = fileList
    },
    async handleFileRemove (file, fileList) {
      const imageId = file.id || (file.response && file.response.id)

      if (!imageId) return

      const apiImage = new ApiImage()
      const success = await apiImage.delete(imageId)
      // eslint-disable-next-line vue/no-mutating-props
      if (success) this.form.shop_image = fileList
      this.updateGMOForm()
    },
    handleFileExceed (files, fileList) {
      this.$message.error('最大1枚の写真をアップロードします。各ファイルサイズは2.0 MBを超えてはなりません')
    },
    handleFileError (err) {
      this.$message.error(err)
    }
  }
}
</script>

<template lang="pug" src="./Step2.pug"></template>
