<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PaymentResult from './components/PaymentResult'
import ApiService from '@/api/additionalService'

export default {
  skipMiddleware: ['checkAuth'],

  name: 'Payment',
  components: { PaymentResult },
  data () {
    return {
      apiService: new ApiService(),
      checked: false,
      paymentRequestIsSent: false,
      paymentResultVisible: false,
      paymentResultStatus: null
    }
  },

  computed: {
    ...mapGetters('Me', ['currentStore', 'storeName', 'currencySymbol']),
    ...mapGetters('AdditionalService', ['point', 'amount']),
    ...mapState('AdditionalService', ['origin'])
  },

  mounted () {
    // TAPPAY SDK REMOVED
    this.setTappay()
  },

  methods: {
    ...mapActions('Me', ['fetchStores']),
    ...mapActions(['setLoading']),
    setTappay () {
      TPDirect.card.setup({
        fields: {
          number: {
            element: '.card-number',
            placeholder: '**** **** **** ****'
          },
          expirationDate: {
            element: '.card-expiration-date',
            placeholder: 'MM / YY'
          },
          ccv: {
            element: '.card-ccv',
            placeholder: 'ccv'
          }
        },
        styles: {
          'input': {
            'color': 'gray'
          },
          'input.card-number': {
            'font-size': '0.875rem'
          },
          'input.expiration-date': {
            'font-size': '0.875rem'
          },
          'input.cvc': {
            'font-size': '0.875rem'
          },
          '.valid': {
            'color': 'green'
          },
          '.invalid': {
            'color': 'red'
          }
        }
      })
    },

    update () {
      const tappayStatus = TPDirect.card.getTappayFieldsStatus()

      if (tappayStatus.canGetPrime === false) {
        this.$message.error(this.$t('Payment.validate_payment'))
        return
      }

      const that = this

      TPDirect.card.getPrime(async (result) => {
        that.paymentRequestIsSent = true

        try {
          let success = false

          if (result.status === 0) {
            success = await this.apiService.addValue('family_business', {
              amount: this.amount,
              prime: result.card.prime
            })
          }

          this.paymentResultStatus = !!success
          this.paymentResultVisible = true
          this.item = `${this.$t('Setting.Service.b2c_points')} ${this.point} ${this.$t('Setting.Service.points')}`
        } catch (e) {
          console.error(e)
        }

        that.paymentRequestIsSent = false
      })
    },
    toSettingSeller () {
      this.$router.push({ name: 'Setting', query: { activeMenu: 'SettingSeller', 'seller': true } },
        () => {
          this.emitMenuSettingSeller()
        },
        () => {
          this.emitMenuSettingSeller()
        })
    },
    emitMenuSettingSeller () {
      this.$router.push({ name: 'Setting', query: { activeMenu: 'SettingSeller' } })
    },
    handleConfirm () {
      this.goOrigin()
    },
    handleCancel () {
      this.goOrigin()
    },
    goOrigin () {
      if (!this.origin) {
        this.$router.push({ name: 'Setting', query: { activeMenu: 'SettingService' } })
      } else if (this.origin.path) {
        this.$router.push(this.origin.path)
      } else {
        this.$router.push(this.origin)
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
