/**
 * 影格截圖
 * @param { HTMLVideoElement } video
 * @returns base64 string
 */
export function getSnapshot (video) {
  if (!video) return ''
  const canvas = document.createElement('canvas')
  canvas.width = video.videoWidth
  canvas.height = video.videoHeight
  canvas.getContext('2d').drawImage(video, 0, 0)
  return canvas.toDataURL()
}
