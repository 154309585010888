import ApiOrder from '@/api/order'
import * as loadingState from '@/constants/loadingState'

export const actionTypes = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  UPDATE_APPROVING: 'UPDATE_APPROVING'
}

const state = {
  loadingState: loadingState.EMPTY,
  approving: {}
}

const getters = {
  approving: state => state.approving
}

const mutations = {
  [actionTypes.UPDATE_APPROVING] (state, payload) {
    state.approving = payload
  }
}

const actions = {
  updateSingleApprove ({ commit }, payload) {
    commit(actionTypes.UPDATE_APPROVING, payload)
  },

  async putBankReceipts ({ commit, state }, payload) {
    const apiOrder = new ApiOrder()
    commit(actionTypes.LOADING)
    try {
      await apiOrder.putBankReceipts(state.approving.id, payload)
      commit(actionTypes.SUCCESS)
    } catch (e) {
      commit(actionTypes.FAILED)
      throw e
    }
  },

  async putReceiptMistake ({ commit }, payload) {
    const apiOrder = new ApiOrder()
    commit(actionTypes.LOADING)
    try {
      await apiOrder.putBankReceipts(payload.id, { has_mistake: !payload.has_mistake })
      commit(actionTypes.SUCCESS)
    } catch (e) {
      commit(actionTypes.FAILED)
      throw e
    }
  },

  async putOfflineAtmReview ({ state, commit, rootGetters }, payload) {
    const apiOrder = new ApiOrder()
    commit(actionTypes.LOADING)
    try {
      const reviewPayload = {
        order_ids: [state.approving.id],
        payment_status: payload
      }
      await apiOrder.putOfflineAtmReview(rootGetters['Me/storeName'], reviewPayload)
      commit(actionTypes.SUCCESS)
    } catch (e) {
      commit(actionTypes.FAILED)
      throw e
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
