import Cookies from 'js-cookie'
/**
 * just for development
 */
export default function () {
  if (!process.env.VUE_APP_API_TOKEN) return

  Cookies.set('api_token', process.env.VUE_APP_API_TOKEN, {
    domain: location.hostname
  })
}
