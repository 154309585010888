import * as loadingState from '@/constants/loadingState'

import { updateCartDetail } from '../apis/cart'
import { updateOrderDetail } from '../apis/order'

export const types = {
  UPDATE_ORDER: 'UPDATE_ORDER',
  UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
  UPDATE_ORDER_FAILED: 'UPDATE_ORDER_FAILED',

  UPDATE_DIALOG_UPDATE_ORDER_VISIBLE: 'UPDATE_DIALOG_UPDATE_ORDER_VISIBLE'
}

const INIT_DATA = {
  dialogUpdateOrderVisible: false,

  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  dialogUpdateOrderVisible: state => state.dialogUpdateOrderVisible,
  orderUpdateLoadingState: state => state.loadingState
}

const mutations = {
  [types.UPDATE_ORDER] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.UPDATE_ORDER_SUCCESS] (state, payload) {
    state.loadingState = loadingState.READY
  },

  [types.UPDATE_ORDER_FAILED] (state, error) {
    state.loadingState = loadingState.ERROR
    state.error = error

    // Bugsnag 紀錄，若不需要的話，拿掉這段。
    console.error(error)
  },

  [types.UPDATE_DIALOG_UPDATE_ORDER_VISIBLE] (state, flag) {
    state.dialogUpdateOrderVisible = flag
  }
}

const actions = {
  updateOrder ({ commit }, { isCart, orderId, items }) {
    commit(types.UPDATE_ORDER)

    const updateFun = isCart
      ? updateCartDetail
      : updateOrderDetail

    const putData = isCart
      ? { cart_items: items }
      : { order_items: items }

    return updateFun(orderId, putData)
      .then(async (response) => {
        commit(types.UPDATE_ORDER_SUCCESS, response)
      })
      .catch(err => {
        commit(types.UPDATE_ORDER_FAILED, err)
        throw err
      })
  },

  toggleDialogUpdateOrder ({ commit }, flag) {
    commit('UPDATE_DIALOG_UPDATE_ORDER_VISIBLE', flag)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
