import Vue from 'vue'
import VueGtm from 'vue-gtm'
import router from '../router'

Vue.use(VueGtm, {
  // container id
  id: process.env.VUE_APP_GTM_CODE,
  // Pass the router instance to automatically sync with router (optional)
  vueRouter: router,
  // enable track or not (optional)
  enabled: true,
  // display console logs debugs or not (optional)
  debug: false
})
