
import axios from '@/plugins/http'

export const createProduct = (storeName, postData) => (
  axios.post(`/api/v1/stores/${storeName}/products`, postData)
)

export const updateProduct = (storeName, id, putData) => (
  axios.put(`/api/v1/stores/${storeName}/products/${id}`, putData)
)
