<script>
import VueTypes from 'vue-types'

export default {
  name: 'DialogCloseNotice',

  props: {
    title: VueTypes.string.def('')
  },

  methods: {
    handleCloseDialog () {
      this.$emit('close')
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogCloseNotice.pug"></template>
