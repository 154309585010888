<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EmailBubble',
  components: {},
  props: {
    guideOpen: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore']),
    isShown () {
      if (this.guideOpen || !this.currentStore || !this.currentStore.region) return false

      const shownRegions = ['jp', 'vn']
      return shownRegions.includes(this.currentStore.region.toLowerCase())
    },
    emailLink () {
      const defaultEmail = 'biz@17.live'
      const emails = {
        jp: 'jp_contact@handsup.shop',
        vn: 'vietnam_contact@handsup.shop',
        th: 'thailand_contact@handsup.shop'
      }
      const email = emails[this.currentStore.region.toLowerCase()] || defaultEmail
      const subject = 'HandsUP Support'

      return `mailto:${email}?subject=${encodeURIComponent(subject)}`
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
