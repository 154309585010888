<script>
import * as SHIPPING_TYPES from '@/constants/shippingTypes'

import mixin from '../../mixins'

export default {
  name: 'FormFamily',

  mixins: [mixin],

  data () {
    return {
      fields: ['shipping_type', 'receiver_name', 'receiver_phone', 'receiver_address', 'info'],

      form: {
        receiver_name: '',
        receiver_phone: '',
        receiver_address: '',
        info: {}
      },

      rules: {
        receiver_name: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ],
        receiver_phone: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    hasSelectStore () {
      const selectStoreId = _.get(this.form, 'info.store_id', '')
      return !!selectStoreId
    }
  },

  methods: {
    toStoreUrl () {
      // 更新全家門市
      const orderEditShipping = {
        type: SHIPPING_TYPES.FAMILY,
        receiver_name: this.form.receiver_name,
        receiver_phone: this.form.receiver_phone
      }
      localStorage.setItem('orderEditShipping', JSON.stringify(orderEditShipping))

      const cvsname = location.hostname
      const redirect = location.origin + location.pathname

      location.href = `https://mfme.map.com.tw/default.aspx?cvsname=${cvsname}&cvsid=1&cvstemp=${redirect}&exchange=true`
    }
  }

}
</script>

<template lang="pug" src="./FormFamily.pug"></template>
