<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DialogAppDownload',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore']),
    qrcodeUrl () {
      return require(`@/assets/qrcodes/${process.env.VUE_APP_ONE_QRCODE}`)
    },
    iosDownloadUrl () {
      return process.env.VUE_APP_IOS_DOWNLOAD_URL
    },
    androidDownloadUrl () {
      return process.env.VUE_APP_ANDROID_DOWNLOAD_URL
    },
    showCloseButton () {
      const regions = ['TW', 'TH', 'VN']

      return regions.includes(this.currentStore.region)
    }
  },
  methods: {
    handleCloseDialog () {
      this.$emit('showDialogLiveLater')
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
