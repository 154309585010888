<script>
import { mapGetters } from 'vuex'

import * as SHIPPING_TYPES from '@/constants/shippingTypes'

import mixin from '../../mixins'

export default {
  name: 'FormSeven',

  mixins: [mixin],

  data () {
    return {
      fields: ['receiver_name', 'receiver_phone', 'receiver_address', 'info'],

      form: {
        receiver_name: '',
        receiver_phone: '',
        receiver_address: '',
        info: {}
      },

      rules: {
        receiver_name: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ],
        receiver_phone: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['apiUrl']),
    hasSelectStore () {
      const selectStoreId = _.get(this.form, 'info.store_id', '')
      return !!selectStoreId
    },
    seven () {
      return {
        action: 'https://emap.presco.com.tw/c2cemap.ashx',
        callback: `${this.apiUrl || process.env.VUE_APP_DOMAIN_NAME}/callback/seven/map`,
        redirect: location.origin + location.pathname
      }
    }
  },

  methods: {
    toStoreUrl () {
      const orderEditShipping = {
        type: SHIPPING_TYPES.SEVEN,
        receiver_name: this.form.receiver_name,
        receiver_phone: this.form.receiver_phone
      }
      localStorage.setItem('orderEditShipping', JSON.stringify(orderEditShipping))

      this.$refs.sevenForm.submit()
    }
  }

}
</script>

<template lang="pug" src="./FormSeven.pug"></template>
