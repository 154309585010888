<script>
import ApiSettings from './components/ApiSettings'
import ApiPostback from './components/ApiPostback'

export default {
  name: 'Api',
  components: { ApiSettings, ApiPostback },
  data () {
    return {
      activeTab: 'settings'
    }
  }
}
</script>

<template lang="pug" src="./Api.pug"></template>
