<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PriceTax',
  computed: {
    ...mapGetters('Me', ['currentStore']),
    isShown () {
      if (!this.currentStore || !this.currentStore.region) return false

      const taxRegions = ['jp']
      return taxRegions.includes(this.currentStore.region.toLowerCase())
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
