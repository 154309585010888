<script>
import dateFormat from 'date-fns/format'
import { mapGetters } from 'vuex'
import ApiOrder from '@/api/order'

const CHUNK_COUNT = 25

export default {
  name: 'BatchApprovePrint',

  data () {
    return {
      printTime: new Date(),
      pages: []
    }
  },

  computed: {
    ...mapGetters('Me', ['storeName'])
  },

  created () {
    let params = {}
    if (localStorage.getItem('batchApproveParams')) {
      params = JSON.parse(localStorage.getItem('batchApproveParams'))
      delete params.per_page
    }
    params.all = true
    this.fetchOrders(params)
  },

  methods: {
    async fetchOrders (params) {
      const apiOrder = new ApiOrder()
      const { data: { data } } = await apiOrder.getOfflineAtmReview(this.storeName, params)
      const orders = data.map(order => {
        return {
          slug: order.slug,
          total_amount: Number(order.total_amount),
          paid_at: _.get(order, 'payment.bank_receipt.paid_at', ''),
          bank_account: _.get(order, 'payment.bank_receipt.bank_account', ''),
          bank_name: _.get(order, 'payment.bank_receipt.bank_name', '')
        }
      })
      this.pages = _.chunk(orders, CHUNK_COUNT)
      for (const page of this.pages) {
        this.setPageDates(page)
      }
    },
    getIndex (itemIndex, pageIndex) {
      return itemIndex + 1 + CHUNK_COUNT * pageIndex
    },
    setPageDates (page) {
      const firstOrder = _.minBy(page, order => order.paid_at)
      if (firstOrder && firstOrder.paid_at) {
        page.from = dateFormat(firstOrder.paid_at, 'YYYY-MM-DD')
      }
      const lastOrder = _.maxBy(page, order => order.paid_at)
      if (lastOrder && lastOrder.paid_at) {
        page.to = dateFormat(lastOrder.paid_at, 'YYYY-MM-DD')
      }
    }
  }
}
</script>

<template lang="pug" src="./BatchApprovePrint.pug"></template>
<style lang="scss" src="./BatchApprovePrint.scss" scoped></style>
