import axios from '@/plugins/http.js'
import storeId from './_storeId'

export default class Setting {
  // 簡易註冊(網紅)
  simpleSeller = async postData => {
    try {
      const api = `/api/v1/settings/simple-seller`
      await axios.post(api, postData)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }
  // GMO Form
  getGMOForm = async () => {
    try {
      const api = `/api/v1/stores/${storeId()}/gmo-form`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  updateGMOForm = async postData => {
    try {
      const api = `/api/v1/stores/${storeId()}/gmo-form`
      const { data } = await axios.put(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  submitGMOForm = async () => {
    try {
      const api = `/api/v1/stores/${storeId()}/gmo-form/submit`
      const { data } = await axios.post(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }
}
