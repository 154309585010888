<script>
import { mapGetters, mapActions } from 'vuex'

import UserIcon from '@/assets/icon-default-user-xl.png'

import * as USER_STATUS from '@/constants/userStatus'

import PreviewImage from './components/PreviewImage'
import DialogEditUserInfo from './components/DialogEditUserInfo'
import DialogConfirmEmail from './components/DialogConfirmEmail'
import DialogConfirmPassword from './components/DialogConfirmPassword'
import DialogEditPassword from './components/DialogEditPassword'

export default {
  name: 'ContainerInfo',

  components: {
    PreviewImage,
    DialogEditUserInfo,
    DialogConfirmEmail,
    DialogConfirmPassword,
    DialogEditPassword
  },

  data () {
    return {
      ...USER_STATUS,
      editUserInfoVisible: false,
      confirmEmailVisible: false,
      confirmPasswordVisible: false,
      editPasswordVisible: false
    }
  },

  computed: {
    ...mapGetters('Me', ['token', 'me', 'status']),

    uploadHeaders () {
      return { Authorization: `Bearer ${this.token}` }
    },
    avatarUrl () {
      // avatar_url: 第三方圖像
      // handsup_avatar_url: 自行上傳圖像

      if (!this.me) return UserIcon

      return this.me.handsup_avatar_url || this.me.avatar_url || UserIcon
    }
  },

  methods: {
    ...mapActions('Me', ['fetchMe']),

    handleConfirmMail () {
      if (this.me.has_password) {
        this.confirmEmailVisible = true
      } else {
        this.confirmPasswordVisible = true
      }
    }
  }
}
</script>

<template lang="pug" src="./ContainerInfo.pug"></template>
