<script>
import VueTypes from 'vue-types'

export default {
  name: 'DialogConfirmSubmit',
  props: {
    plan: VueTypes.object.def({})
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
