<script>
import { mapGetters, mapActions } from 'vuex'

import ApiService from '@/api/additionalService'
import ApiCarrier from '@/api/carrierSetting'

import * as PLANS from '@/constants/plans'
import * as PAYMENT_FLOW from '@/constants/paymentFlow'
import * as REGIONS from '@/constants/regions'

import BuyPoints from '@/components/DialogBuyPoints'

export default {
  name: 'PlanService',
  components: { BuyPoints },
  data () {
    return {
      REGIONS,
      apiService: new ApiService(),
      apiCarrier: new ApiCarrier(),
      buyPointsVisible: false,
      pointsLeft: 0,
      services: [],
      isFamilyB2C: false
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'currentStore', 'paymentFlow', 'canBuyPlan', 'region']),

    showPlan () {
      return this.region === REGIONS.JP || this.paymentFlow !== PAYMENT_FLOW.DIRECT
    },

    showService () {
      return this.region === REGIONS.TW && this.paymentFlow === PAYMENT_FLOW.AGENCY
    },

    disableBuyPoints () {
      if (this.currentStore.plan.name === PLANS.NOVICE) {
        return this.$t('Setting.Service.PlanService.novice_disable')
      } else if (this.currentStore.plan.name === PLANS.FREE_TRIAL) {
        return this.$t('Setting.Service.PlanService.trial_disable')
      } else if (!this.isFamilyB2C) {
        return this.$t('Setting.Service.PlanService.no_b2c')
      } else {
        return null
      }
    }
  },
  created () {
    this.fetchServices()
    this.fetchPoints()
    this.fetchCarrier()
  },
  methods: {
    ...mapActions('AdditionalService', ['updateOrigin']),
    async fetchServices () {
      this.services = await this.apiService.list()
    },
    async fetchPoints () {
      const { data } = await this.apiService.get('family_business')
      this.pointsLeft = data.summary.quantity
    },
    async fetchCarrier () {
      const data = await this.apiCarrier.get('family-business')
      this.isFamilyB2C = data && data.is_verified
    },
    handleUpgrade () {
      const path = process.env.VUE_APP_BUYER_DOMAIN_NAME + '/plans_ja.html'
      window.open(path, '_blank')
    },
    handleBuyPoints () {
      this.updateOrigin({ name: 'Setting', query: { activeMenu: 'SettingService' } })
      this.buyPointsVisible = true
    },
    handleGmo () {
      this.$router.push({ name: 'GmoForm' })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
