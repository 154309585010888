<script>
import cloneDeep from 'lodash/cloneDeep'
import { normalizeImage } from '@/utils/normalizeImage'

export default {
  name: 'DialogUpdateCart',
  props: {
    allProducts: {
      type: Array,
      default: () => []
    },
    cartData: {
      type: Object
    },
    cart: {
      type: Object
    }
  },
  data () {
    return {
      products: [],
      newProducts: [],
      filteredProducts: [],
      lastUpdatedTime: null,
      lastUpdateUser: null
    }
  },
  mounted () {
    this.filteredProducts = this.allProducts
    this.lastUpdatedTime = this.cartData.meta.last_updated_at
    this.lastUpdateUser = this.cartData.meta.last_update_user_name

    for (const item of this.cartData.data) {
      const skuId = item.sku_id
      const product = cloneDeep(this.allProducts.find(product => {
        return product.skus.some(sku => sku.id === skuId)
      }))
      item.updatedAt = item.updated_at

      // If product exists, hydrate it with cart item info.
      // If it or the sku no longer exists, lock the sku
      if (product) {
        product.comment = item.comment
        product.updatedAt = item.updatedAt
        product.price = parseInt(item.price)
        product.sku = product.skus.find(sku => sku.id === skuId)
        if (!product.sku) {
          product.sku = {
            name: item.sku_name
          }
          product.skus = [product.sku]
          product.skuDisable = true
        }
        product.quantity = item.quantity
        product.id = item.id
        product.sortKey = item.id
        this.products.push(product)
      } else {
        item.sku = {
          name: item.sku_name
        }
        item.skus = [item.sku]
        item.skuDisable = true
        item.sortKey = item.id
        this.products.push(item)
      }
    }
  },
  methods: {
    close () {
      this.$emit('submit')
    },
    filterProducts (query) {
      // search keyword
      this.filteredProducts = this.allProducts.filter(product => {
        const keywords = [product.name]
        keywords.push(...product.keywords.map(keyword => keyword.keyword))
        const content = keywords.join('!$').toLowerCase()
        return content.includes(query.toLowerCase())
      })
    },
    getImgUrl (data) {
      if (!data.images || data.images.length === 0) return
      return normalizeImage(data.images[0].url, 30)
    },
    handleSelect (item) {
      // Set defaults for new item, and delete id to mark as new item
      const newItem = cloneDeep(item)
      newItem.sku = newItem.skus[0]
      newItem.price = parseInt(newItem.skus[0].price)
      newItem.quantity = 1
      newItem.sortKey = Math.random().toString()
      newItem.comment = this.$t('Post.Show.DialogUpdateCart.new')
      delete newItem['id']
      this.products.unshift(newItem)
    },
    changeSku (product) {
      product.price = product.sku.price
    },
    deleteProduct (item) {
      this.products = this.products.filter(x => {
        return x.sortKey !== item.sortKey
      })
    },
    submit () {
      const finalProducts = this.products

      for (const p of finalProducts) {
        const sameSku = finalProducts.filter(x => x.sku.id === p.sku.id)
        if (sameSku.length > 1) {
          this.$message.error(this.$t('Post.Show.DialogUpdateCart.error.repeat_sku', { name: p.name, sku: p.sku.name }))
          return
        }
      }

      const cartItems = finalProducts.map(product => {
        const item = {
          sku_name: product.sku.name,
          sku_id: product.sku.id,
          product_name: product.name || product.product_name,
          quantity: product.quantity,
          price: parseInt(product.price)
        }
        if (product.id) {
          item.id = product.id
        }
        if (product.images && product.images.length > 0) {
          item.image_id = product.images[0].id
        } else if (product.image) {
          item.image_id = product.image_id
        }
        return item
      })
      this.$emit('submit', { cart_items: cartItems })
    }
  }
}
</script>

<template lang='pug' src='./template.pug'></template>
<style lang='scss' src='./style.scss' scoped></style>
