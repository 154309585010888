<script>
import VueTypes from 'vue-types'

export default {
  name: 'DialogBankSetting',
  components: {},
  props: {
    visible: VueTypes.bool.isRequired,
    isOnlyBank: VueTypes.bool.def(false),
    formData: VueTypes.object.isRequired,
    bankData: VueTypes.object.isRequired
  },
  data () {
    return {
      isBtnLoading: false,
      form: {
        bank_name: null,
        bank_branch_name: null,
        bank_registrant_name: null,
        bank_registrant_account: null
      },
      rules: {
        bank_name: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t('Setting.Seller.bank_name')}`,
            trigger: 'blur'
          }
        ],
        bank_branch_name: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t('Setting.Seller.bank_branch_name')}`,
            trigger: 'blur'
          }
        ],
        bank_registrant_name: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t('Setting.Seller.bank_registrant_name')}`,
            trigger: 'blur'
          }
        ],
        bank_registrant_account: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t('Setting.Seller.bank_registrant_account')}`,
            trigger: 'blur'
          },
          {
            pattern: /^[0-9]{11,}$/,
            message: this.$t('Setting.Seller.DialogBankSetting.account_validate')
          }
        ]
      },
      errors: {
        bank_name: null,
        bank_branch_name: null,
        bank_registrant_name: null,
        bank_registrant_account: null
      }
    }
  },
  created () {
    this.form = JSON.parse(JSON.stringify(this.bankData))
    if (!this.isOnlyBank) {
      this.form.bank_registrant_name = this.formData
        ? this.formData.registrant_name
        : this.bankData.bank_registrant_name
    }
  },
  methods: {
    async submit () {
      this.isBtnLoading = true

      try {
        await this.$refs['formBankSetting'].validate()
        this.$emit('submit', this.form)
      } catch (e) {
        this.$message.error(this.$t('message.form_incomplete'))
      }

      this.isBtnLoading = false
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
