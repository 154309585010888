<script>
import { mapGetters } from 'vuex'
import { differenceInCalendarDays } from 'date-fns'
import { getAnnouncements } from '../../services/apis/saleStats'

// Show new for 3 days
const NEW_DAYS = 3

export default {
  name: 'Announcements',
  data () {
    return {
      items: []
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName'])
  },
  mounted () {
    this.fetchAnnouncements()
  },
  methods: {
    async fetchAnnouncements () {
      try {
        const { data: { data } } = await getAnnouncements(this.storeName)
        this.items = data
      } catch (e) {
        console.error(e)
      }
    },
    isNew (date) {
      return differenceInCalendarDays(new Date(), date) <= NEW_DAYS
    }
  }
}
</script>

<template lang="pug" src="./Announcements.pug"></template>
<style lang="scss" src="./Announcements.scss" scoped></style>
