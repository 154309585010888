import BaseApi from './baseApi'
import storeId from './_storeId'
import axios from '@/plugins/http.js'

export default class Channel extends BaseApi {
  uri = `/api/v1/stores/${storeId()}/channels`
  uriGet = `/api/v1/channels`

  getChannels = storeId => {
    const api = `/api/v1/stores/${storeId}/channels?all`
    return axios.get(api)
  }

  getLives = async id => {
    try {
      const api = `/api/v1/channels/${id}/lives`
      const {
        data: { data }
      } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }

  create = async data => {
    try {
      await axios.post(this.uriCreate || this.uri, data)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  unbindChannel = async channelId => {
    try {
      const api = `/api/v1/stores/${storeId()}/channels/${channelId}/disable`
      await axios.post(api)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  get17LiveChannelInfo = async () => {
    const api = `/api/v1/stores/${storeId()}/channels/17live_v2`
    const { data } = await axios.get(api)
    return data
  }

  createOrUpdate17LiveChannel = async (payload) => {
    try {
      const api = `/api/v1/stores/${storeId()}/channels/17live_v2`
      await axios.post(api, payload)
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }
}
