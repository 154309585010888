import _ from 'lodash'

export const normalizeImage = (url, size) => {
  if (!url) return
  if (!url.includes('googleusercontent')) return url
  /** 擷取 google 已加參數的圖 */
  if (url.includes('googleusercontent') && url.includes('=')) {
    url = url.slice(0, url.lastIndexOf('='))
  }
  if (!_.isNumber(size)) return url
  return `${url}=s${size}`
}
