<script>
import VueTypes from 'vue-types'
import { mapGetters, mapActions } from 'vuex'

import ApiProduct from '@/api/product'

import * as loadingState from '@/constants/loadingState'

export default {
  name: 'ProductFormName',

  props: {
    isPostEditProduct: VueTypes.bool.def(false),
    alreadyKeywords: VueTypes.array.def([])
  },

  data () {
    return {
      loadingState,

      ruleForm: {
        name: '',
        defaultKeyword: ''
      },
      rules: {
        name: [{
          required: true,
          message: this.$t('Product.DialogAddProduct.placeholder_product'),
          trigger: 'blur'
        }],
        defaultKeyword: [
          {
            required: true,
            message: `${this.$t('Components.DialogPickProduct.enter_default_keyword')}`,
            trigger: 'blur'
          },
          {
            validator: this.checkHasBlank,
            message: `${this.$t('Product.DialogAddProduct.keyword_has_blank')}`,
            trigger: 'blur'
          },
          {
            validator: this.checkUniqueKeyword,
            message: `${this.$t('Components.DialogPickProduct.keyword_repeat2')}`,
            trigger: 'blur'
          }
        ]
      },

      oldDefaultKeyword: ''

    }
  },

  computed: {
    ...mapGetters('Me', ['maxProductName', 'keywordTip']),
    ...mapGetters('ProductList', {
      fetchDefaultKeyword: 'defaultKeyword',
      defaultKeywordLoadingState: 'getDefaultKeywordLoadingState'
    }),
    ...mapGetters('ProductDetail', ['name', 'defaultKeyword'])
  },

  watch: {
    'ruleForm.name': function (newValue, oldValue) {
      this.updateName(newValue)
    },
    'ruleForm.defaultKeyword': function (newValue, oldValue) {
      this.updateDefaultKeyword(newValue)
    }
  },

  created () {
    this.ruleForm.name = this.name
    this.ruleForm.defaultKeyword = this.defaultKeyword

    this.oldDefaultKeyword = this.defaultKeyword

    // 若無關鍵字，帶入 預設關鍵字
    if (!this.defaultKeyword) {
      this.getDefaultKeyword()
        .then(() => {
          this.ruleForm.defaultKeyword = this.fetchDefaultKeyword
        })
    }
  },
  methods: {
    ...mapActions('ProductList', ['getDefaultKeyword']),
    ...mapActions('ProductDetail', ['updateName', 'updateDefaultKeyword']),

    checkHasBlank (rules, value, callback) {
      // 確認有無空白字元
      const regex = /\s/
      const hasSpace = regex.test(value)

      if (hasSpace) {
        callback(new Error())
      } else {
        callback()
      }
    },

    async checkUniqueKeyword (rules, value, callback) {
      // Keyword如果沒改動不用打API
      if (this.oldDefaultKeyword === this.ruleForm.defaultKeyword) {
        callback()
        return
      }

      // Note:
      // 若是編輯直播貼文的商品關鍵字，就不需確認與全站商品關鍵字是否有重覆，
      // 僅需檢查是否有和 alreadyKeywords 重覆關鍵字即可。
      const isHaveKeyword = this.alreadyKeywords.includes(this.ruleForm.defaultKeyword)

      let isSuccess = false
      if (this.isPostEditProduct) {
        isSuccess = true
      } else {
        // 與全站商品是否有重覆關鍵字
        const apiProduct = new ApiProduct()
        isSuccess = await apiProduct.checkUniqueKeyword(this.ruleForm.defaultKeyword)
      }

      if (isSuccess && !isHaveKeyword) {
        callback()
      } else {
        callback(new Error())
      }
    },

    validateForm () {
      return this.$refs['ruleForm'].validate()
    }
  }
}
</script>

<template lang="pug" src="./ProductFormName.pug"></template>
