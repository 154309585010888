import axios from '@/plugins/http.js'

export function fetchEnterpriseSetting (slug) {
  const api = `/api/v1/stores/${slug}/enterprise-setting`
  return axios.get(api)
}

export function postEnterpriseSetting (slug, payload) {
  const api = `/api/v1/stores/${slug}/enterprise-setting`
  return axios.put(api, payload)
}
