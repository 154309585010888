<script>

import { mapGetters, mapActions } from 'vuex'
import dateDifferenceInSeconds from 'date-fns/difference_in_seconds'

import '@/utils/copyText'
import BadgeImage from '@/components/BadgeImage'
import * as loadingState from '@/constants/loadingState'

import { FB_PAGE, HANDSUP, M17_LIVE } from '@/constants/channelType'
import AllIcon from '@/assets/icon-channel-all.png'
import HandsupIcon from '@/assets/icon-handsup.png'
import FacebookIcon from '@/assets/icon-fb.png'
import M17Icon from '@/assets/icon-channel-m17.png'

import MessageBox from '../MessageBox'
import * as commentTypes from '../../constants/commentTypes'
import * as analyzeStatus from '../../constants/analyzeStatus'
import { BUY_SELF } from '../../constants/failedReasonCode'

// Bounce back 1000 pixels when comment list is scrolled to the top
const SCROLL_TO_TOP_OFFSET = 1000

export default {
  name: 'Comments',

  components: {
    BadgeImage,
    MessageBox
  },

  data () {
    return {
      showVideoPopup: false,
      message: '',

      LOADING_STATE: loadingState,
      COMMENT_TYPES: commentTypes,

      categoryOptions: [
        {
          name: this.$t('Post.Create.Comments.category.all'),
          callback: () => this.loadAllComments()
        },
        {
          name: this.$t('Post.Create.Comments.category.success'),
          callback: () => this.loadParsedComments(commentTypes.SUCCESSFUL)
        },
        {
          name: this.$t('Post.Create.Comments.category.fail'),
          callback: () => this.loadParsedComments(commentTypes.FAILED)
        },
        {
          name: this.$t('Post.Create.Comments.category.invalid'),
          callback: () => this.loadInvalidComments()
        }
      ],
      searchRule: {
        offset: null,
        category: 0,
        keyWord: null
      },

      // 最新留言
      showNewest: false,
      newestMessage: '',

      sendChannel: {
        type: commentTypes.ALL,
        name: this.$t('Post.Show.Published.all_channel'),
        image_url: AllIcon
      },
      showChannels: false,
      commentType: commentTypes.ALL,
      FB_PAGE,
      HANDSUP,
      M17_LIVE,
      firstTimeLoadingHistory: true
    }
  },

  computed: {
    ...mapGetters('Post', ['celebrityToken', 'postId', 'post', 'liveStatus', 'isCelebrityStoreOwner']),
    ...mapGetters('Post', ['blacklist']),
    ...mapGetters('Me/StoreChannels', ['channels']),

    ...mapGetters('ChatMessage/Messages', {
      currentMessages: 'getCurrentMessages',
      historicalMessages: 'getHistoricalMessages',
      fetchHistoryLoadingStatus: 'getLoadingState'
    }),

    ...mapGetters('ChatMessage/ChannelNames', {
      groupName: 'getGroupName'
    }),

    ...mapGetters('ChatMessage/Channels', {
      cartChannels: 'getCartChannels',
      commentChannels: 'getCommentChannels',
      failedChannels: 'getFailedChannels'
    }),

    ...mapGetters('ChatMessage/SendMessage', {
      sendMessageLoadingStatus: 'loadingState'
    }),

    agent () {
      return _.get(this.post, 'agent', null)
    },

    sendChannels () {
      return [
        {
          type: commentTypes.ALL,
          name: this.$t('Post.Show.Published.all_channel'),
          image_url: AllIcon
        },
        ...this.post.channels.map(channel => ({
          ...channel,
          image_url: this.getChannelImage(channel)
        }))
      ]
    },

    chunkNewestMessage (name) {
      return this.newestMessage.length > 35
        ? `${this.newestMessage.substring(0, 35)}...`
        : this.newestMessage
    },

    filterHistoricalComments () {
      const {
        commentType,
        historicalMessages,
        getFilterHandler
      } = this

      return historicalMessages.filter(getFilterHandler(commentType))
    },

    filterCurrentComments () {
      const {
        commentType,
        currentMessages,
        getFilterHandler
      } = this

      return currentMessages.filter(getFilterHandler(commentType))
    },

    channelPopoverWidth () {
      // (padding + margin) * 2 + (imgsize + margin * 2) * (channels + all)
      return 9 * 2 + 40 * (this.post.channels.length + 1)
    },

    messagePlaceholder () {
      return (this.agent && !this.celebrityToken) || this.isCelebrityStoreOwner
        ? this.$t('Post.Show.Published.cant_leave_message')
        : this.$t('Post.Show.Published.leave_message')
    }
  },

  watch: {
    currentMessages: function (next, prev) {
      if (prev.length !== next.length) {
        if (this.showNewest) {
          const [lastComment] = next.slice(-1)

          if (lastComment) {
            this.newestMessage = `${lastComment.from.name}：${lastComment.message}`
          }
        } else {
          this.scrollToBottom()
        }
      }
    },

    historicalMessages: function (next, prev) {
      if (next.length !== prev.length && !this.firstTimeLoadingHistory) {
        this.scrollToOffset(SCROLL_TO_TOP_OFFSET)
      }
    }

  },

  updated: function () {
    this.$nextTick(function () {
      if (this.firstTimeLoadingHistory) {
        this.scrollToBottom()
        this.firstTimeLoadingHistory = false
      }
    })
  },

  mounted () {
    // 監聽Table Scroll
    this.$nextTick().then(() => {
      this.$refs.commentsList.addEventListener('scroll', this.onScroll)
    })
  },

  beforeDestroy () {
    this.$refs.commentsList.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    ...mapActions('ChatMessage/Messages', [ 'fetchHistoricalMessage' ]),
    ...mapActions('ChatMessage/SendMessage', ['sendMessage']),

    getFilterHandler (commentType) {
      const filterBySku = x => x.analyzeStatus === analyzeStatus.SUCCESS
      const filterByFailedAnalysis = x => x.analyzeStatus === analyzeStatus.FAILED && x.reasonCode !== BUY_SELF
      const filterByOther = x => x.analyzeStatus === analyzeStatus.EMPTY

      const filterMap = {
        [commentTypes.ALL]: () => true,
        [commentTypes.SUCCESSFUL]: filterBySku,
        [commentTypes.FAILED]: filterByFailedAnalysis,
        [commentTypes.OTHER]: filterByOther
      }

      return filterMap[commentType] || filterMap[commentTypes.ALL]
    },

    onScroll: _.debounce(async function (e) {
      const { scrollTop, clientHeight, scrollHeight } = e.target
      const {
        fetchHistoricalMessage,
        fetchHistoryLoadingStatus,
        cartChannels,
        commentChannels,
        failedChannels
      } = this

      if (fetchHistoryLoadingStatus === loadingState.LOADING) return

      // 載入舊的留言
      if (scrollTop < 100) {
        fetchHistoricalMessage({
          cartChans: cartChannels,
          commentChans: commentChannels,
          failedChans: failedChannels
        })
      }

      // 判斷是否最新訊息
      if (scrollTop + clientHeight < scrollHeight) {
        this.showNewest = true
      } else {
        this.showNewest = false
        this.newestMessage = ''
      }
    }, 500),

    handleAddOrder (comment) {
      const cantAddOrder = createdTime => dateDifferenceInSeconds(new Date(), new Date(createdTime)) <= 20

      if (cantAddOrder(comment.created_time)) {
        this.$message.error(this.$t('Post.Create.Comments.message.order_error'))

        return
      }

      this.$emit('showDialogAddOrder', comment)
    },

    handleLiveMessage (event) {
      const {
        sendChannel,
        sendMessage,
        post
      } = this

      let {
        message,
        channels
      } = this

      if (event.shiftKey) return
      event.preventDefault() // 阻止只按Enter換行
      message = message.trim()
      if (!message || this.sendMessageLoadingStatus === loadingState.LOADING) return

      this.message = ''

      channels = sendChannel.type === commentTypes.ALL
        ? post.channels.map(x => x.id)
        : sendChannel.id

      if (Array.isArray(channels) && channels.length === 1) {
        channels = channels[0]
      }

      sendMessage({
        postID: this.post.id,
        channels,
        message
      })
    },

    scrollToBottom () {
      this.scrollToOffset(this.$refs.commentsList.scrollHeight)
    },

    scrollToOffset (offset) {
      if (this.$refs.commentsList && this.$refs.commentsList.scrollHeight) {
        this.$refs.commentsList.scrollTop = offset
      }
    },

    selectCart (item) {
      this.$emit('selectCart', item)
    },

    getChannelImage (channel) {
      const iconMappings = {
        [FB_PAGE]: FacebookIcon,
        [HANDSUP]: HandsupIcon,
        [M17_LIVE]: M17Icon
      }

      if (channel.image_url) return channel.image_url

      return iconMappings[channel.type]
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
 <style>
.comments-channels-popover {
  min-width: 0;
  padding: 12px 8px;
}

</style>
