import * as loadingState from '@/constants/loadingState'

import { fetchListOfChannels } from '../../apis/pubnub'
import * as MESSAGE_TYPES from '../../../constants/messageTypes'

export const types = {
  FETCH_CHANNELS: 'FETCH_CHANNELS',
  FETCH_CHANNELS_SUCCESS: 'FETCH_CHANNELS_SUCCESS',
  FETCH_CHANNELS_FAILED: 'FETCH_HISTORICAL_MESSAGES_FAILED'
}

// @TODO add descriptions for different channels
const state = {
  cartChannels: [],
  commentChannels: [],
  failedChannels: [],
  errMessage: '',
  loadingState: loadingState.EMPTY
}

const getters = {
  getCartChannels: state => state.cartChannels,
  getCommentChannels: state => state.commentChannels,
  getFailedChannels: state => state.failedChannels
}

const mutations = {
  [types.FETCH_CHANNELS] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.FETCH_CHANNELS_SUCCESS] (state, { cartChans, commentChans, failedChans }) {
    state.loadingState = loadingState.READY
    state.cartChannels = cartChans
    state.commentChannels = commentChans
    state.failedChannels = failedChans
  },

  [types.FETCH_CHANNELS_FAILED] (state) {
    state.loadingState = loadingState.ERROR
    state.channels = []
  }
}

const actions = {
  // Fetch list of channels with the given group name.
  // The fetched channels will be sorted into following
  // categories:
  //   - cart channels
  //   - comment channels
  //   - failed channels
  // Each of these channels have its own history messages
  fetchChannels ({ commit }, { groupName }) {
    commit(types.FETCH_CHANNELS)

    return fetchListOfChannels(groupName)
      .then(({ channels }) => {
        const sortedChans = channels.reduce((sortedChans, currentChan) => {
          const [ group ] = currentChan.split('|')
          if (group === MESSAGE_TYPES.CART) {
            sortedChans.cartChans.push(currentChan)
          }

          if (group === MESSAGE_TYPES.COMMENT) {
            sortedChans.commentChans.push(currentChan)
          }

          if (group === MESSAGE_TYPES.COMMENT_FAILED) {
            sortedChans.failedChans.push(currentChan)
          }

          return sortedChans
        }, {
          cartChans: [],
          commentChans: [],
          failedChans: []
        })

        commit(types.FETCH_CHANNELS_SUCCESS, sortedChans)

        return sortedChans
      })
      .catch(err => commit(types.FETCH_CHANNELS_FAILED, {
        errMessage: err.message
      }))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
