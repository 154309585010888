import GetCouponList from './GetCouponList'
import CreateCoupon from './CreateCoupon'
import UpdateCoupon from './UpdateCoupon'

export default {
  namespaced: true,
  modules: {
    GetCouponList,
    CreateCoupon,
    UpdateCoupon
  }
}
