<script>
import { mapState } from 'vuex'
import SubForm from '../SubForm'

export default {
  name: 'FormSelfPickUp',
  components: {
    SubForm
  },
  computed: {
    ...mapState('OrderManual/ShippingAddress', ['selfPickStoreName', 'selfPickAddress'])
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
