export const types = {
  UPDATE_IS_CHANGED: 'UPDATE_IS_CHANGED',
  UPDATE_SAVE_CONFIRM: 'UPDATE_SAVE_CONFIRM',
  UPDATE_LEAVE_CONFIRM: 'UPDATE_LEAVE_CONFIRM'
}

const INIT_DATA = {
  isChanged: false,
  showSaveConfirm: false,
  showLeaveConfirm: false
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  isChanged: state => state.isChanged,
  showSaveConfirm: state => state.showSaveConfirm,
  showLeaveConfirm: state => state.showLeaveConfirm
}

const mutations = {
  [types.UPDATE_IS_CHANGED] (state, isChanged) {
    state.isChanged = isChanged
  },

  [types.UPDATE_SAVE_CONFIRM] (state, bool) {
    state.showSaveConfirm = bool
  },

  [types.UPDATE_LEAVE_CONFIRM] (state, bool) {
    state.showLeaveConfirm = bool
  }
}

const actions = {
  updateIsChanged ({ commit }, isChanged) {
    commit(types.UPDATE_IS_CHANGED, isChanged)
  },

  updateShowSaveConfirm ({ commit }, bool) {
    commit(types.UPDATE_SAVE_CONFIRM, bool)
  },

  updateShowLeaveConfirm ({ commit }, bool) {
    commit(types.UPDATE_LEAVE_CONFIRM, bool)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
