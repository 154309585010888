<script>
import VueTypes from 'vue-types'

export default {
  name: 'DialogCancelOrder',
  props: {
    dialogVisible: VueTypes.bool.def(false),
    order: VueTypes.object
  },
  data () {
    return {

      form: {
        reason: '',
        hasCompensation: false
      },

      rules: {
        reason: [
          {
            required: true,
            message: this.$t('Order.DialogCancelOrder.reason_placeholder'),
            trigger: 'blur'
          }
        ]
      },

      isBtnLoading: false
    }
  },
  computed: {
    computedPaymentClass () {
      let thisStatus = this.order.paymentStatus
      if (!thisStatus) return ''
      if (thisStatus === 'init') return 'text-danger'
      if (thisStatus === 'paid') return ''
      if (thisStatus === 'refunded') return ''
      return 'text-primary'
    }
  },
  methods: {
    async handleSubmit () {
      try {
        await this.$refs.form.validate()
      } catch (e) {
        return
      }

      this.isBtnLoading = true

      this.$emit('cancelOrder', { reason: this.form.reason, hasCompensation: this.form.hasCompensation })
    },
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
