import Vue from 'vue'
import trans from '@/utils/trans'

Vue.mixin({
  methods: {
    copyText ({ url, success = trans('message.copy_success'), error = trans('message.copy_failed') }) {
      // Default message
      if (!success) {
        success = 'Copy Success'
      }
      if (!error) {
        error = 'Copy Failed'
      }
      this.$copyText(url).then(
        e => {
          this.$message({
            message: success,
            type: 'success',
            customClass: 'copy-message'
          })
        },
        e => {
          this.$message({
            message: error,
            type: 'error',
            customClass: 'copy-message'
          })
        }
      )
    }
  }
})
