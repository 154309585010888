<script>
import { mapGetters, mapActions } from 'vuex'
import Pagination from '@/components/Pagination'
import BadgeImage from '@/components/BadgeImage'
import ApiMember from '@/api/member'

import getSocialIcon from '@/utils/getSocialIcon'

export default {
  name: 'Member',
  components: { Pagination, BadgeImage },
  data () {
    return {
      members: [],
      paginator: null,
      apiMember: new ApiMember(),
      filterBlacklist: false,
      searchRule: {
        keyword: '',
        sort: null,
        desc: null
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'currencySymbol']),
    filterSearchRule () {
      let param = Object.keys(this.searchRule).reduce((prev, key) => {
        if (this.searchRule[key]) prev[key] = this.searchRule[key]
        return {
          ...prev,
          ...(this.filterBlacklist ? { enabled: false } : null)
        }
      }, { page: this.currentPage })

      return param
    }
  },
  async created () {
    await this.fetchMembers()
  },
  methods: {
    ...mapActions(['setLoading']),
    getSocialIcon,

    avgAmount (data) {
      const avg = data.orders_total_amount / data.orders_count
      return isFinite(avg) ? parseInt(avg) : 0
    },
    updateRows (rows) {
      this.members = rows
    },
    handleSortChange (obj) {
      this.searchRule.sort = obj.prop
      this.searchRule.desc = obj.order === 'descending' ? '1' : null

      this.currentPage = 1
      this.fetchMembers()
    },
    async fetchMembers () {
      this.setLoading(true)
      const params = Object.assign(this.filterSearchRule)
      this.members = await this.apiMember.list(params)
      this.paginator = this.apiMember.paginator
      this.setLoading(false)
    },
    async updateBlacklist (member) {
      const res = await this.apiMember.updateMember(member.slug, { enabled: !member.pivot.enabled })
      if (res) this.fetchMembers()
    },
    goToShow (slug) {
      const path = `/${this.storeName}/member/${slug}`

      this.$router.push({ path })
    },
    handleFilterBlacklist () {
      this.fetchMembers()
    },
    renderLastHeader (h, { column, $index }, index) {
      return h('span', { 'style': { 'display': 'flex' } }, [
        this.$t('Member.List.blacklist'),
        h('el-tooltip', { 'placement': 'right' }, [
          h('div', {
            'slot': 'content'
          }, [
            h('span', {}, this.$t('Member.List.blacklist_tip1')),
            h('br'),
            h('span', {}, this.$t('Member.List.blacklist_tip2')),
            h('br'),
            h('span', {}, this.$t('Member.List.blacklist_tip3'))
          ]
          ),
          h('span', {}, [
            h('i', { 'class': 'icon-static fas fa-info-circle ml-1' })
          ])
        ])
      ])
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
