<script>
export default {
  name: 'AppAlert',

  props: {
    color: {
      type: String,
      default: 'warning'
    }
  }

}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
