import CreateTicket from './CreateTicket'
import FetchTickets from './FetchTickets'

export default {
  namespaced: true,
  modules: {
    CreateTicket,
    FetchTickets
  }
}
