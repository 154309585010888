<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DialogB2C',
  components: {},
  props: {
    step: {
      type: String,
      default: 'submitForm'
    },
    shipping: {
      type: Object,
      default: null
    },
    carrier: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      carrierForm: {
        type: 'family-business',
        return_cycle: '0',
        return_day: '1',
        return_mode: '1',
        return_mode_other: null,
        contact_name: null,
        contact_vendor_name: null,
        contact_email: null,
        contact_number: null,
        contact_address: null
      },
      senderForm: {
        enabled: false,
        fee: 60,
        fee_type: 'fixed',
        sender_address: null,
        sender_name: null,
        sender_phone: null
      },
      senderRules: {
        sender_address: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_sender_address') }
        ],
        sender_name: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_sender_name') }
        ],
        sender_phone: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_sender_phone') }
        ]
      },
      carrierRules: {
        contact_name: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_contact_name') }
        ],
        contact_vendor_name: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_contact_vendor_name') }
        ],
        contact_email: [
          { trigger: 'blur', required: true, type: 'email', message: this.$t('Setting.Payment.DialogB2C.validate_contact_email') }
        ],
        contact_number: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_contact_number') }
        ],
        contact_address: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_contact_address') }
        ],
        return_cycle: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_return_cycle') }
        ],
        return_day: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_return_day') }
        ],
        return_mode: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_return_mode') }
        ],
        return_mode_other: [
          { required: true, message: this.$t('Setting.Payment.DialogB2C.validate_return_mode_other') }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName']),
    returnIsSender () {
      return this.carrierForm.contact_name === this.senderForm.sender_name &&
        this.carrierForm.contact_number === this.senderForm.sender_phone &&
        this.carrierForm.contact_address === this.senderForm.sender_address
    }
  },
  created () {
    if (this.shipping) {
      this.senderForm = { ...this.shipping }
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    sameAsSender () {
      this.carrierForm.contact_name = this.senderForm.sender_name
      this.carrierForm.contact_number = this.senderForm.sender_phone
      this.carrierForm.contact_address = this.senderForm.sender_address
    },
    async handleSubmit () {
      const carrierValid = await this.$refs.carrierForm.validate()
      const senderValid = await this.$refs.senderForm.validate()
      if (!carrierValid || !senderValid) return

      this.carrierForm.info = {
        return_cycle: this.carrierForm.return_cycle,
        return_day: this.carrierForm.return_day,
        return_mode: this.carrierForm.return_mode,
        return_mode_other: this.carrierForm.return_mode_other
      }
      const data = {
        carrierForm: this.carrierForm,
        senderForm: this.senderForm
      }

      this.$emit('submit', data)
      this.active = 'applying'
    },
    checkLabel () {
      const path = `/${this.storeName}/check-label/family-business`
      window.open(path, '_blank')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
