<script>
export default {
  skipMiddleware: ['getAuth', 'checkAuth'],

  name: 'AppDownload',
  components: {},
  data () {
    return {
    }
  },
  computed: {
    isDev () {
      return this.$route.query.hasOwnProperty('dev')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
