import FileSaver from 'file-saver'

// getResponse from axios get request
export async function downloadFile (getResponse, defaultFilename) {
  const { data, headers } = getResponse
  const filename = headers['content-disposition']
    ? headers['content-disposition'].split('filename=')[1].split(';')[0].replace(/['"]+/g, '')
    : defaultFilename
  FileSaver.saveAs(data, filename)
}
