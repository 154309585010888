<script>

const JASRAC_APPLY_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdAC8TB1hFrtzjR7wFnNUNwQd-D_voP80e2bD-pnM3BblOHdQ/viewform'

export default {
  name: 'JasracApply',

  data () {
    return {
      JASRAC_APPLY_URL
    }
  }
}
</script>

<template lang="pug" src="./JasracApply.pug"></template>
