<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'
import { JP } from '@/constants/regions'

export default {
  name: 'DialogBankSetting',
  components: {},
  props: {
    visible: VueTypes.bool.isRequired,
    isOnlyBank: VueTypes.bool.def(false),
    formData: VueTypes.object.isRequired,
    bankData: VueTypes.object.isRequired
  },
  data () {
    return {
      isBtnLoading: false,
      form: {
        bank_name: null,
        bank_branch_name: null,
        bank_registrant_name: null,
        bank_registrant_account: null,
        bank_account_type: null
      },
      errors: {
        bank_name: null,
        bank_branch_name: null,
        bank_registrant_name: null,
        bank_registrant_account: null
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore', 'region']),
    rules () {
      return {
        bank_name: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t('Setting.Seller.bank_name')}`,
            trigger: 'blur'
          }
        ],
        bank_branch_name: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t('Setting.Seller.bank_branch_name')}`,
            trigger: 'blur'
          }
        ],
        bank_registrant_name: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t('Setting.Seller.bank_registrant_name')}`,
            trigger: 'blur'
          }
        ],
        bank_registrant_account: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t('Setting.Seller.bank_registrant_account')}`,
            trigger: 'blur'
          },
          {
            pattern: /^[0-9]{7,}$/,
            message: this.$t('Setting.Seller.DialogBankSetting.account_length', { length: 7 }),
            trigger: 'blur'
          }
        ]
      }
    },
    showAccountType () {
      const showRegions = [JP]
      return showRegions.includes(this.region)
    }
  },
  created () {
    this.form = JSON.parse(JSON.stringify(this.bankData))
    if (!this.isOnlyBank) {
      this.form.bank_registrant_name = this.formData
        ? this.formData.registrant_name
        : this.bankData.bank_registrant_name
    }
    if (this.showAccountType && !this.form.bank_account_type) {
      this.$set(this.form, 'bank_account_type', 'cash')
    }
  },
  methods: {
    async submit () {
      this.isBtnLoading = true

      try {
        await this.$refs['formBankSetting'].validate()
        this.$emit('submit', this.form)
      } catch (e) {
        this.$message.error(this.$t('message.form_incomplete'))
      }

      this.isBtnLoading = false
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
