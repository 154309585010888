import * as loadingState from '@/constants/loadingState'

import { fetchBills } from '../apis/bill'

export const types = {
  FETCH_BILL_LIST: 'FETCH_BILL_LIST',
  FETCH_BILL_LIST_SUCCESS: 'FETCH_BILL_LIST_SUCCESS',
  FETCH_BILL_LIST_FAILED: 'FETCH_BILL_LIST_FAILED',

  UPDATE_BILLS_SEARCH: 'UPDATE_BILLS_SEARCH'
}

const BILL_STATUS = {
  INIT: 'init',
  OPENED: 'opened',
  CLOSED: 'closed'
}

const INIT_DATA = {
  data: [],
  meta: null,
  links: null,

  search: {
    from: '',
    to: '',
    page: 1,
    status_not: BILL_STATUS.INIT // 濾掉剛開帳的 bill
  },

  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  billListData: state => state.data,
  billListMeta: state => state.meta,
  billListLinks: state => state.links,

  billListSearch: state => state.search,

  billsLoadingState: state => state.loadingState
}

const mutations = {
  [types.FETCH_BILL_LIST] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.FETCH_BILL_LIST_SUCCESS] (state, response) {
    state.loadingState = loadingState.READY

    const { data, links, meta } = response.data

    state.data = data
    state.meta = meta
    state.links = links
  },

  [types.FETCH_BILL_LIST_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  },

  [types.UPDATE_BILLS_SEARCH] (state, payload) {
    Object.assign(state.search, payload)
  }
}

const actions = {
  fetchBillList ({ state, commit }, storeId) {
    commit(types.FETCH_BILL_LIST)

    const params = state.search

    return fetchBills(storeId, params)
      .then(response => commit(types.FETCH_BILL_LIST_SUCCESS, response))
      .catch(err => {
        commit(types.FETCH_BILL_LIST_FAILED, err)
        throw err
      })
  },

  updateBillListSearch ({ state, commit }, payload) {
    const page = payload.page || 1

    const search = {
      ...state.search,
      ...payload,
      page
    }

    commit(types.UPDATE_BILLS_SEARCH, search)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
