<script>
import VueTypes from 'vue-types'

export default {
  name: 'DialogApproveApply',

  props: {
    dialogVisible: VueTypes.bool.def(false),
    order: VueTypes.object
  },
  data () {
    return {
      reason: '',
      hasCompensation: false,
      isBtnLoading: false
    }
  },
  methods: {
    handleSubmit () {
      this.isBtnLoading = true
      this.$emit('returnOrder', { reason: this.reason, hasCompensation: this.hasCompensation })
    },
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
