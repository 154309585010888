<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

import * as PAYMENT_TYPES from '@/constants/paymentTypes'
import * as PAYMENT_STATUS from '@/constants/paymentStatus'

export default {
  name: 'DialogPaymentDetail',
  props: {
    dialogVisible: VueTypes.bool.def(false),
    payments: VueTypes.array.def([]),
    amountPayable: VueTypes.string.def('0'),
    totalAmount: VueTypes.string.def('0'),
    overpayment: VueTypes.string.def('0')
  },

  data () {
    return {
      PAYMENT_TYPES
    }
  },

  computed: {
    ...mapGetters('Me', ['currencySymbol']),

    reversePayments () {
      return [...this.payments].reverse()
    }

  },

  methods: {
    isNewestPayment (payment) {
      // For 線下版 - 銀行轉帳
      // 最新的一筆，才能有編輯的權限

      const paymentLength = this.payments.length

      if (paymentLength) {
        return payment.id === this.payments[paymentLength - 1].id
      } else {
        return false
      }
    },

    getPaymentStatusClass (status) {
      const classMapping = {
        [PAYMENT_STATUS.INIT]: 'text-danger',
        [PAYMENT_STATUS.PENDING]: 'text-danger',
        [PAYMENT_STATUS.PAID]: 'text-success'
      }

      return classMapping[status] || ''
    },

    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
