const matchString = location.origin
const multiverse = /https?:\/\/([a-z0-9]+)?(-)?admin-master[.]handsup[.]dev/.exec(matchString)
const universe = multiverse && multiverse[1]

export let VUE_APP_DOMAIN_NAME = process.env.VUE_APP_DOMAIN_NAME
export let VUE_APP_BUYER_DOMAIN_NAME = process.env.VUE_APP_BUYER_DOMAIN_NAME

if (universe) {
  VUE_APP_DOMAIN_NAME = VUE_APP_DOMAIN_NAME
    .replace('https://', `https://${universe}-`)
  VUE_APP_BUYER_DOMAIN_NAME = VUE_APP_BUYER_DOMAIN_NAME
    .replace('https://', `https://${universe}-`)
}
