// TODO：
// $FETCH_API$  <== (必改)將此檔所有 $FETCH_API$, 更名為 適合的名稱，
// 如：CREATE_POST、FETCH_POST、UPDATE_POST、DELETE_POST

import { getStoreShippings, getStoreShippingSetting } from '../apis/store'

import * as loadingState from '@/constants/loadingState'

export const types = {
  FETCH_STORE_SHIPPING: 'FETCH_STORE_SHIPPING',
  FETCH_STORE_SHIPPING_SUCCESS: 'FETCH_STORE_SHIPPING_SUCCESS',
  FETCH_STORE_SHIPPING_FAILED: 'FETCH_STORE_SHIPPING_FAILED'
}

const INIT_DATA = {
  orderShipping: null,
  orderShippingSetting: [],

  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  orderShipping: state => state.orderShipping,
  orderShippingSetting: state => state.orderShippingSetting,
  enabledShippings: state => {
    return state.orderShippingSetting.reduce((prev, curr) => {
      if (curr.enabled) {
        prev.push(curr.type)
      }

      return prev
    }, [])
  },

  storeShippingLoadingState: state => state.loadingState
}

const mutations = {
  [types.FETCH_STORE_SHIPPING] (state) {
    state.loadingState = loadingState.LOADING

    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))
  },

  [types.FETCH_STORE_SHIPPING_SUCCESS] (state, payload) {
    state.loadingState = loadingState.READY

    const [shipping, shippingSetting] = payload

    state.orderShipping = shipping.data
    state.orderShippingSetting = shippingSetting.data
  },

  [types.FETCH_STORE_SHIPPING_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error

    // Bugsnag 紀錄，若不需要的話，拿掉這段。
    console.error(error)
  }
}

const actions = {
  fetchShippingInfo ({ commit, dispatch }, storeId) {
    commit(types.FETCH_STORE_SHIPPING)

    dispatch('setLoading', true, { root: true })

    return Promise.all([
      getStoreShippings(storeId),
      getStoreShippingSetting(storeId)
    ])
      .then(response => commit(types.FETCH_STORE_SHIPPING_SUCCESS, response))
      .catch(err => {
        commit(types.FETCH_STORE_SHIPPING_FAILED, err)
        throw err
      })
      .finally(() => {
        dispatch('setLoading', false, { root: true })
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
