<script>
// https://github.com/dwightjack/vue-types#documentation
import VueTypes from 'vue-types'
import { mapGetters, mapActions } from 'vuex'

import AppIntroduce from '@/components/AppIntroduce'

import isKeyInNumber from '@/utils/isKeyInNumber'

// 折扣代碼，是否顯示於 Buyer 前台
const COUPON_TYPES = {
  PUBLIC: 'public',
  PRIVATE: 'private'
}

function isNumber (obj) {
  const number = Number(obj)
  return typeof number === 'number' && !isNaN(number)
}

export default {
  name: 'DialogCouponDetail',
  components: { AppIntroduce },
  props: {
    editCoupon: VueTypes.oneOfType([Object, null])
  },
  data () {
    const validateCode = (rule, value, callback) => {
      if (value.match(/[^a-z^A-Z^0-9]/g)) {
        callback(new Error(this.$t('Coupon.DialogCouponDetail.coupon_code_error')))
      } else {
        callback()
      }
    }

    const validateAmount = (rule, value, callback) => {
      if (!isNumber(value)) {
        callback(new Error(this.$t('Coupon.DialogCouponDetail.coupon_amount_error')))
      } else {
        callback()
      }
    }

    const validateMinimum = (rule, value, callback) => {
      if (this.ruleForm.isSetMinimum) {
        if (!value) {
          callback(new Error(this.$t('Coupon.DialogCouponDetail.coupon_minimum_required')))
        } else if (!isNumber(value)) {
          callback(new Error(this.$t('Coupon.DialogCouponDetail.coupon_minimum_error')))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    const validateQuantity = (rule, value, callback) => {
      if (this.ruleForm.isSetQuantity) {
        if (!value) {
          callback(new Error(this.$t('Coupon.DialogCouponDetail.coupon_quantity_required')))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    const validateExpireAt = (rule, value, callback) => {
      if (this.ruleForm.isSetExpireAt) {
        if (!value) {
          callback(new Error(this.$t('Coupon.DialogCouponDetail.end_date_required')))
        } else if (new Date(this.ruleForm.start_at) > new Date(this.ruleForm.expire_at)) {
          callback(new Error(this.$t('Coupon.DialogCouponDetail.end_date_error')))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }

    return {
      COUPON_TYPES,
      ruleForm: {
        name: '',
        code: '',
        amount: null,
        isSetMinimum: false,
        minimum: 0,
        isSetQuantity: false,
        quantity: null,
        start_at: new Date(),
        isSetExpireAt: false,
        expire_at: null,
        type: COUPON_TYPES.PUBLIC
      },
      rules: {
        name: [
          { required: true, message: this.$t('Coupon.DialogCouponDetail.coupon_name_error'), trigger: 'blur' }
        ],
        code: [
          { required: true, message: this.$t('Coupon.DialogCouponDetail.coupon_code_required'), trigger: 'blur' },
          { min: 1, max: 20, message: this.$t('Coupon.DialogCouponDetail.coupon_code_limited'), trigger: 'blur' },
          { validator: validateCode, trigger: 'blur' }

        ],
        amount: [
          { required: true, message: this.$t('Coupon.DialogCouponDetail.coupon_amount_required'), trigger: 'blur' },
          { validator: validateAmount, trigger: 'blur' }

        ],
        minimum: [
          { validator: validateMinimum, trigger: 'blur' }
        ],
        quantity: [
          { validator: validateQuantity, trigger: 'blur' }
        ],
        start_at: [
          {
            required: true,
            message: this.$t('Coupon.DialogCouponDetail.start_date_required'),
            trigger: 'change'
          }
        ],
        expire_at: [
          { validator: validateExpireAt, trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol']),
    isDisabledEdit () {
      if (!this.editCoupon) return false

      const disabledList = ['ongoing', 'expired']
      return !!(disabledList.includes(this.editCoupon.status) || this.editCoupon.used_apply_quantity)
    }

  },
  created () {
    if (this.editCoupon) {
      const normalizeCoupon = {
        code: this.editCoupon.apply_code,
        quantity: this.editCoupon.apply_quantity,
        isSetMinimum: !!Number(this.editCoupon.apply_amount_threshold),
        isSetQuantity: !!Number(this.editCoupon.quantity),
        isSetExpireAt: !!this.editCoupon.apply_end_at,
        minimum: Number(this.editCoupon.apply_amount_threshold) || null,
        amount: Number(this.editCoupon.amount),
        type: this.editCoupon.visibility
      }

      this.ruleForm = Object.assign(this.ruleForm, this.editCoupon, normalizeCoupon)
      this.ruleForm['start_at'] = new Date(this.ruleForm.apply_start_at)
      this.ruleForm['expire_at'] = this.ruleForm.isSetExpireAt
        ? new Date(this.ruleForm.apply_end_at)
        : null
    }
  },
  methods: {
    isKeyInNumber,
    ...mapActions('Coupon', ['createCoupon', 'updateCoupon', 'fetchCouponList']),

    async validateForm () {
      try {
        await this.$refs['ruleForm'].validate()
        return true
      } catch (error) {
        return false
      }
    },
    calUpdateDate (form) {
      return {
        name: form.name,
        apply_code: form.code,
        amount: form.amount,
        apply_amount_threshold: form.isSetMinimum ? form.minimum : null,
        apply_quantity: form.isSetQuantity ? form.quantity : null,
        apply_start_at: new Date(form.start_at).toISOString(),
        apply_end_at: form.isSetExpireAt
          ? new Date(form.expire_at).toISOString()
          : null,
        visibility: form.type
      }
    },
    async handleCreateCoupon () {
      let result = false

      const postData = this.calUpdateDate(this.ruleForm)

      result = await this.createCoupon(postData)

      if (result) {
        this.$message({
          showClose: true,
          duration: 1000,
          message: this.$t('Coupon.DialogCouponDetail.message_create_success'),
          type: 'success'
        })
      }

      return result
    },
    async handleUpdateCoupon () {
      let result = false

      result = await this.updateCoupon({
        id: this.editCoupon.id,
        updateData: this.calUpdateDate(this.ruleForm)
      })

      if (result) {
        this.$message({
          showClose: true,
          duration: 1000,
          message: this.$t('Coupon.DialogCouponDetail.message_update_success'),
          type: 'success'
        })
      }

      return result
    },
    async handleSubmit () {
      if (!(await this.validateForm())) return

      let result = false

      if (this.editCoupon) {
        result = await this.handleUpdateCoupon()
      } else {
        result = await this.handleCreateCoupon()
      }

      if (!result) return

      // 新增：回第一頁；更新：留在當頁
      this.fetchCouponList(!this.editCoupon)

      this.handleClose()
    },
    handleClose () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogCouponDetail.pug"></template>
<style lang="scss" src="./DialogCouponDetail.scss" scoped></style>
