import BaseApi from './baseApi'
import storeId from './_storeId'
import axios from '@/plugins/http.js'

export default class Store extends BaseApi {
  uri = `/api/v1/stores/${storeId()}/users`

  detachAssistant = async userId => {
    try {
      const api = `/api/v1/stores/${storeId()}/users/${userId}`
      await axios.put(api)

      return true
    } catch (e) {
      console.error(e)
      return null
    }
  }

  getManagers = async userId => {
    try {
      const api = `/api/v1/stores/${storeId()}/users?type=manager&all`
      const { data: { data } } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }
}
