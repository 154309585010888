<script>
import VueTypes from 'vue-types'

export const PAYMENT_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
  LAST_PAY_FAILED: 'lastPayFailed'
}

export default {
  name: 'DialogPaymentNotice',
  props: {
    status: VueTypes.string.def(PAYMENT_STATUS.SUCCESS),
    message: VueTypes.string.def('')
  },
  data () {
    return {
      PAYMENT_STATUS
    }
  },
  methods: {
    close () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogPaymentNotice.pug"></template>
<style lang="scss" scoped>
  .far {
    font-size: 5rem;
  }
</style>
