<script>
import { mapGetters } from 'vuex'
import '@/utils/copyText'
import { getParentToken } from '../../services/apis/ChildStore'

export default {
  name: 'ChildInvite',
  data () {
    return {
      token: ''
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName']),
    inviteUrl () {
      return this.token
        ? `${location.origin}/invite-parent/${this.token}`
        : this.$t('Setting.AccountInvite.getting')
    }
  },
  created () {
    this.fetchInviteToken()
  },
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    async fetchInviteToken () {
      try {
        const { data: { token } } = await getParentToken(this.storeName)
        this.token = token
      } catch (e) {
        console.error(e)
      }
    },
    copyLink () {
      this.copyText({
        url: this.inviteUrl
      })
    }
  }
}
</script>

<template lang="pug" src="./ChildInvite.pug"></template>
<style lang="scss" src="./ChildInvite.scss" scoped></style>
