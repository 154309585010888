<script>
import { mapGetters, mapActions } from 'vuex'
import { format } from 'date-fns'

import {
  BILL_DEADLINE_START,
  BILL_DEADLINE_END
} from '@/constants/BillPaymentDeadline'
import * as PAYMENT_STATUS from '@/constants/paymentStatus'
import { NEWEBPAY_CREDIT, NEWEBPAY_ATM } from '@/constants/paymentTypes'

// 後端開帳關帳狀態, 與是否付款無關
const BILL_STATUS = {
  INIT: 'init',
  OPENED: 'opened',
  CLOSED: 'closed'
}

export default {
  name: 'PaymentInformation',
  data () {
    return {
      showPaymentChangeDialog: false,
      paymentType: NEWEBPAY_CREDIT, // only NEWEBPAY_CREDIT or NEWEBPAY_ATM
      NEWEBPAY_CREDIT,
      NEWEBPAY_ATM
    }
  },
  computed: {
    ...mapGetters('Me', [
      'storeName',
      'currencySymbol',
      'apiUrl',
      'isEnterprise'
    ]),
    ...mapGetters('AccountManagement', ['paymentBill']),

    billsTotalAmountPayable () {
      return this.paymentBill.reduce((acc, current) => {
        return acc + Number(current.amount_payable)
      }, 0)
    },

    newestPaymentBill () {
      return this.paymentBill[this.paymentBill.length - 1]
    },

    isFirstBill () {
      // 此情況為 剛註冊第一個月時，會取得當月的 bill 且 statue 為 init
      // 次月後，取得的 bill 的 status 都會是 opened 或 closed

      const billStatus = _.get(
        this.newestPaymentBill,
        'status',
        BILL_STATUS.INIT
      )
      return billStatus === BILL_STATUS.INIT
    },

    billDate () {
      return this.newestPaymentBill ? this.newestPaymentBill.date : null
    },
    deadlineTitle () {
      return this.isAutoPay ? this.$t('Component.PaymentInformation.payment.autopay_deadline_title') : this.$t('Order.OrderInfo.payment.deadline')
    },
    deadline () {
      if (!this.newestPaymentBill || this.billsTotalAmountPayable === 0) { return '-' }

      if (this.isAutoPay && this.newestPaymentBill.auto_agreed_pay_date) {
        return format(this.newestPaymentBill.auto_agreed_pay_date, 'YYYY-MM-DD')
      }
      const dt = new Date(this.newestPaymentBill.date)
      dt.setMonth(dt.getMonth() + 1)

      const yearMonth =
        dt.getFullYear() + '-' + `${dt.getMonth() + 1}`.padStart(2, '00')
      const deadlineStart = `${BILL_DEADLINE_START}`.padStart(2, '00')
      const deadlineEnd = `${BILL_DEADLINE_END}`.padStart(2, '00')

      return `${yearMonth}-${deadlineStart} ~ ${yearMonth}-${deadlineEnd}`
    },

    commissionRate () {
      return this.newestPaymentBill
        ? this.newestPaymentBill.commission_rate
        : null
    },

    commissionLimit () {
      return Number(_.get(this.newestPaymentBill, 'commission_limit', '0'))
    },

    commissionLimitReached () {
      const billAmount = Number(_.get(this.newestPaymentBill, 'amount', '0'))

      return billAmount !== 0 && this.commissionLimit !== 0
        ? billAmount >= this.commissionLimit
        : null
    },

    isPaid () {
      if (!this.newestPaymentBill) return false
      return this.newestPaymentBill.payment_status === PAYMENT_STATUS.PAID
    },

    isPaymentPending () {
      if (!this.newestPaymentBill) return true
      return this.newestPaymentBill.payment_status === PAYMENT_STATUS.PENDING
    },

    isAutoPay () {
      return this.newestPaymentBill.is_auto_agreed_pay
    }
  },

  methods: {
    ...mapActions('AccountManagement', ['setPaymentDetail']),

    showPaymentDetail () {
      this.setPaymentDetail(this.paymentBill)
    },

    toPayBill () {
      // 由 後端 導轉頁面至 藍新付款
      const url = `${this.apiUrl}/api/v1/stores/${this.storeName}/bills/payment`
      window.location.href = url
    },

    toCreditCardBill () {
      // 由 後端 導轉頁面至 藍新付款
      const url = `${this.apiUrl}/api/v1/stores/${
        this.storeName
      }/bills/agreed-payment`
      window.location.href = url
    },

    openPaymentChangeDialog () {
      this.showPaymentChangeDialog = true
    },
    checkPayLink () {
      switch (this.paymentType) {
        case NEWEBPAY_CREDIT:
          this.toCreditCardBill()
          break
        case NEWEBPAY_ATM:
          this.toPayBill()
          break
        default:
          break
      }
    }
  }
}
</script>

<template lang="pug" src="./PaymentInformation.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
