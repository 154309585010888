<script>
import VueTypes from 'vue-types'
import { TW, JP } from '@/constants/regions'

const TW_LINE = 'https://line.me/R/ti/p/%40frf5773e'
const JP_EMAIL = 'mailto:jp_contact@handsup.shop'

export default {
  name: 'DialogBindFailed',
  props: {
    region: VueTypes.string.def(JP)
  },
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    contact () {
      const url = {
        [TW]: TW_LINE,
        [JP]: JP_EMAIL
      }

      window.open(url[this.region], '_blank')
    }
  }
}
</script>

<template lang="pug" src="./DialogBindFailed.pug"></template>
<style lang="scss" src="./DialogBindFailed.scss" scoped></style>
