import axios from 'axios'
import Cookies from 'js-cookie'

import logCollection from '@/plugins/logCollection'

import * as USER_STATUS from '@/constants/userStatus'

const state = {
  token: Cookies.get('api_token'),
  me: null,
  isNewRegister: false
}

const getters = {
  token: state => state.token,
  me: state => state.me,
  status: state => _.get(state.me, 'status', ''),
  providers: state => _.get(state.me, 'providers', {}),
  isNewRegister: state => state.isNewRegister,

  isLogin: (state, getters) => {
    // 目前 後台登入，只允許 自有會員 、Facebook 身份登入
    // 原因：避免 前台用 Line 登入，不小心進後台，導致重新註冊而誤會。

    const isVerified = getters.status === USER_STATUS.VERIFIED
    const hasFacebook = !!getters.providers.facebook

    return state.me !== null && (isVerified || hasFacebook)
  },

  name: state => state.me && ({
    first: state.me.first_name,
    last: state.me.last_name
  }),

  // 是否有賣家身份
  hasStore: state => state.me && state.me.has_store,
  // 是否有網紅身份
  hasCelebrity: state => state.me && state.me.has_celebrity
}

const mutations = {
  UPDATE_ME (state, data) {
    state.me = data
  },
  UPDATE_ISNEWREGISTER (state, data) {
    state.isNewRegister = data
  }
}

const actions = {
  async fetchMe ({ commit, dispatch }) {
    try {
      const { data } = await axios.get('/api/v1/me')
      const { id, name, email } = data
      commit('UPDATE_ME', data)

      logCollection.user = {
        id,
        name,
        email
      }
      return true
    } catch (e) {
      // TODO：清 cookie 方式，待研究
      const domain = process.env.VUE_APP_DOMAIN
      Cookies.remove('api_token')
      document.cookie = 'api_token=; path=/; domain=' + domain + '; expires=' + new Date(0).toUTCString()

      return false
    }
  },

  updateIsNewRegister ({ commit, dispatch }, flag) {
    commit('UPDATE_ISNEWREGISTER', flag)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
