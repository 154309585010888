import Vue from 'vue'

const requireContext = require.context('./', true, /.*\.vue$/)

requireContext.keys().map(file => {
  const strArr = file.split('/')
  const componentConfig = requireContext(file)

  Vue.component(`${strArr[1]}`, componentConfig.default || componentConfig)
})
