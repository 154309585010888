import * as loadingState from '@/constants/loadingState'
import { updateCelebrityTags } from '../apis'

export const actionTypes = {
  UPDATE_TAGS: 'UPDATE_TAGS',
  UPDATE_TAGS_SUCCESS: 'UPDATE_TAGS_SUCCESS',
  UPDATE_TAGS_FAILED: 'UPDATE_TAGS_FAILED'
}

const state = {
  loadingState: loadingState.EMPTY,
  error: null
}

const getters = {
  updateTagsLoadingState: state => state.loadingState
}

const mutations = {
  [actionTypes.UPDATE_TAGS] (state) {
    state.loadingState = loadingState.LOADING
    state.error = null
  },

  [actionTypes.UPDATE_TAGS_SUCCESS] (state) {
    state.loadingState = loadingState.READY
  },

  [actionTypes.UPDATE_TAGS_FAILED] (state, { err }) {
    state.loadingState = loadingState.ERROR
    state.error = err
  }
}

const actions = {
  updateCelebrityTags ({ commit }, payload) {
    commit(actionTypes.UPDATE_TAGS)

    return updateCelebrityTags(payload)
      .then(() => commit(actionTypes.UPDATE_TAGS_SUCCESS))
      .catch(err => commit(actionTypes.UPDATE_TAGS_FAILED, { err }))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
