<script>
import { mapGetters } from 'vuex'
import dateDifferenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import VueTypes from 'vue-types'
import { JP } from '@/constants/regions'
import { GMO_DEFERRED } from '@/constants/paymentTypes'
import { validateInvoice } from '../../utils/validateInvoice'

const JP_SHIPPING = [
  { company_code: '11', company: '佐川急便' },
  { company_code: '12', company: 'ヤマト運輸・クロネコメール便' },
  { company_code: '14', company: '西濃運輸' },
  { company_code: '15', company: '郵便書留・特定記録郵便' },
  { company_code: '16', company: 'ゆうパック・エクスパック・ポスパケット' },
  { company_code: '18', company: '福山通運' },
  { company_code: '27', company: 'エコ配' },
  { company_code: '28', company: '翌朝 10 時便・レターパック・新特急郵便' }
]

export default {
  name: 'DialogShipmentFreight',
  props: {
    dialogVisible: VueTypes.bool.def(false),
    paymentType: VueTypes.string.def('')
  },
  data () {
    return {
      JP,
      JP_SHIPPING,
      deliverForm: { delivery_at: new Date(), source_id: null, info: { company: null }, invoice: null },
      deliverRules: {
        delivery_at: {
          type: 'date',
          required: true,
          message: this.$t('Order.DialogShipmentFreight.form.delivery_at_placeholder'),
          trigger: 'change'
        },
        source_id: {
          message: this.$t('Order.DialogShipmentFreight.form.source_id_placeholder'),
          trigger: 'blur',
          required: false
        },
        info: {
          company: {
            message: this.$t('Order.DialogShipmentFreight.form.company_placeholder'),
            trigger: 'blur',
            required: false
          }
        },
        invoice: [
          {
            validator: validateInvoice,
            trigger: 'blur',
            required: false
          }
        ]
      },
      isBtnLoading: false
    }
  },
  computed: {
    ...mapGetters('Me', ['region'])
  },
  created () {
    if (this.paymentType === GMO_DEFERRED) {
      this.deliverRules.source_id.required = true
      this.deliverRules.info.company.required = true
    }
  },
  methods: {
    changeDate (date) {
      if (Math.abs(dateDifferenceInCalendarDays(new Date(), date)) > 2) {
        this.deliverForm.delivery_at = null
        this.$message.error(this.$t('Order.DialogShipmentFreight.message_delivery_error'))
      }
    },
    async validateDeliverForm () {
      try {
        await this.$refs['deliverForm'].validate()
        this.isBtnLoading = true
        this.$emit('deliverOrder', this.deliverForm)
      } catch (e) {
      }
    },
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    validateInvoice (rule, value, callback) {
      // element ui form validation, 發票資訊非必填, 因此有值才做檢查
      const invoicePattern = /^[a-zA-Z]{2}-?[0-9]{8}$/g
      if (value && !invoicePattern.test(value)) {
        const errorMessage = this.$t('Order.DialogShipmentFreight.form.invoice_number_invalid')
        callback(new Error(errorMessage))
      } else {
        callback()
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
