<script>
import PayOfflineImg from './images/pay-offline-img.png'

export default {
  metaInfo () {
    return {
      meta: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0'
      }]
    }
  },
  name: 'AccountManagementInstructions',

  data () {
    return {
      PayOfflineImg,
      isEnterprise: !!this.$route.query.enterprise
    }
  }
}
</script>

<template lang="pug" src="./AccountManagementInstructions.pug"></template>
<style lang="scss" src="./AccountManagementInstructions.scss" scoped></style>
