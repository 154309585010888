import axios from '@/plugins/http.js'
import storeId from './_storeId'

export default class ClipVideo {
  fetchClipVideo = async (postId) => {
    const api = `/api/v1/stores/${storeId()}/posts/${postId}/clips`
    const result = await axios.get(api)
    return result.data.data
  }

  updateClipVideoSort = async (postId, payload) => {
    const api = `/api/v1/stores/${storeId()}/posts/${postId}/clips/sort`
    const result = await axios.put(api, payload)
    return result.data.data
  }

  createClipVideo = async (postId, payload) => {
    const api = `/api/v1/stores/${storeId()}/posts/${postId}/clips`
    const result = await axios.post(api, payload)
    return result.data.data
  }

  deleteClipVideo = async (postId, clipId) => {
    const api = `/api/v1/stores/${storeId()}/posts/${postId}/clips/${clipId}`
    const result = await axios.delete(api)
    return result.data.data
  }

  updateReplayType = async (postId, payload) => {
    const api = `/api/v1/posts/${postId}`
    const result = await axios.put(api, payload)
    return result.data.data
  }

  updateClipInfo = async (postId, clipId, payload) => {
    const api = `/api/v1/stores/${storeId()}/posts/${postId}/clips/${clipId}`
    const result = await axios.put(api, payload)
    return result.data.data
  }
}
