<script>
import VueTypes from 'vue-types'
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days'
import endOfDay from 'date-fns/end_of_day'
import { validateInvoice } from '../../utils/validateInvoice'

export default {
  name: 'DialogShipmentStorePickup',
  components: {},
  props: {
    dialogVisible: VueTypes.bool.def(false),
    order: VueTypes.object
  },
  data () {
    return {
      isBtnLoading: false,
      form: {
        pickDate: new Date(),
        invoice: null
      },
      rules: {
        pickDate: [
          {
            required: true,
            message: this.$t('Order.DialogShipmentStorePickup.delivery_at_placeholder'),
            trigger: 'change'
          }
        ],
        invoice: [
          {
            validator: validateInvoice,
            trigger: 'blur',
            required: false
          }
        ]
      },
      pickerOptions: {
        disabledDate: this.calDisabledDate
      }
    }
  },
  methods: {
    calDisabledDate (time) {
      const createTime = this.order.createTime
      return differenceInCalendarDays(time.getTime(), createTime) < 0
    },
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    async submit () {
      try {
        await this.$refs['form'].validate()
        const pickDate = await endOfDay(this.form.pickDate).toISOString()
        const params = {
          delivery_at: pickDate,
          // received_at: pickDate
          invoice: this.form.invoice
        }
        this.isBtnLoading = true
        this.$emit('deliverPickupOrder', params)
      } catch (e) {
      }
    }
  }
}
</script>

<template lang="pug" src="./DialogShipmentStorePickup.pug"></template>
<style lang="scss" src="./DialogShipmentStorePickup.scss" scoped></style>
