<script>
import VueTypes from 'vue-types'

export default {
  name: 'AppSelectListItem',
  components: {},
  props: {
    active: VueTypes.bool.def(false),
    disabled: VueTypes.bool.def(false)
  },
  computed: {
    computedClass () {
      return {
        'active': this.active,
        'disabled': this.disabled
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
