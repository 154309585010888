<script>

import PubNub from 'pubnub'
import ApiPost from '@/api/post'
import ShowActivityNew from '../ShowActivityNew'
import ShowActivityProcess from '../ShowActivityProcess'
import ShowActivityEnd from '../ShowActivityEnd'

export default {
  name: 'ShowActivity',

  components: {
    ShowActivityNew,
    ShowActivityProcess,
    ShowActivityEnd
  },

  props: {
    post: {
      type: Object,
      default: null
    },
    currentActivity: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      activeSteps: '',
      selectedComments: [],
      steps: [
        {
          name: 'new',
          component: 'ShowActivityNew',
          title: this.$t('Post.Show.ShowActivity.title.new')
        },
        {
          name: 'edit',
          component: 'ShowActivityNew',
          title: this.$t('Post.Show.ShowActivity.title.edit')
        },
        {
          name: 'process',
          component: 'ShowActivityProcess',
          title: this.$t('Post.Show.ShowActivity.title.message')
        },
        {
          name: 'end',
          component: 'ShowActivityEnd',
          title: this.$t('Post.Show.ShowActivity.title.list')
        }
      ],
      pubnub: null,
      channelGroup: null,
      searchInput: '',
      rawComments: [],
      analysedComments: []
    }
  },
  computed: {
    currentStep () {
      return this.steps.find(step => step.name === this.activeSteps)
    },
    title () {
      return this.currentStep.title.replace('<product>', this.$t('Post.Show.ShowActivity.auction'))
    }
  },
  watch: {
    rawComments: function () {
      this.handleAnalysedComments()
    }
  },
  created () {
    this.connectPubNub()

    if (!this.currentActivity) {
      this.activeSteps = 'new'
      return
    }

    const auctionStatusMapping = {
      'init': 'new',
      'started': 'process',
      'done': 'end'
    }

    this.activeSteps = auctionStatusMapping[this.currentActivity.status]
  },

  beforeDestroy () {
    try {
      this.pubnub.unsubscribeAll()
    } catch (e) {
      console.error(e)
    }
  },

  methods: {
    async connectPubNub () {
      // 即時取得直播留言
      try {
        const apiPost = new ApiPost()
        const { group_name: channelGroup } = await apiPost.getChannelNames(this.post.id)

        this.channelGroup = channelGroup

        this.pubnub = new PubNub({
          subscribeKey: process.env.VUE_APP_PUBNUB_SUBSCRIBE_KEY
        })

        this.pubnub.subscribe({
          channelGroups: [channelGroup]
        })

        this.pubnub.addListener({
          message: m => this.dispatch(m.channel, m.message)
        })

        // 抓取最新一頁的直播留言
        await this.loadComments()
      } catch (e) {
        console.error(e)
        this.$message.error(this.$t('Post.Show.ShowActivityProcess.message.load_pubnub'))
      }
    },
    async loadComments () {
      const channelGroup = this.channelGroup

      try {
        this.pubnub.channelGroups.listChannels({ channelGroup }, (status, { channels }) => {
          channels.forEach(async channel => {
            // channel format: comment|fb_page|760975837620233_586427001833977
            if (channel.startsWith('comment')) {
              await this.loadHistory(channel)
            }
          })
        })
      } catch (e) {
        console.error(e)
        this.$message.error(this.$t('Post.Show.ShowActivityProcess.message.load_commit'))
        return []
      }
    },
    async loadHistory (channelName) {
      // 若已拉到最後一個分頁的資料，則不再向後拉資料
      let hasReachedTopComment = false
      let pubnubCommentsOffset = 0
      let historyMessages = []

      while (!hasReachedTopComment) {
        let params = {
          channel: channelName,
          count: 100,
          start: pubnubCommentsOffset || 0
        }

        let response = await this.pubnub.history(params)
        let messages = response.messages

        if (messages && messages.length > 0) {
          // 將 offset 更新為最小的 time token
          pubnubCommentsOffset = Math.min(response.startTimeToken, response.endTimeToken)
          // 收集留言
          historyMessages.push(...messages)
          // 若取得的資料筆數少於分頁基準資料數量，則表示已經拉到最後一個分頁了
          if (messages.length < params.count) {
            hasReachedTopComment = true
          }
        } else {
          hasReachedTopComment = true
        }
      }

      historyMessages.reverse().forEach(payload => {
        const { type, data } = payload && payload.entry
        if (!channelName.startsWith('notification') || type === 'url.sent') {
          this.dispatch(channelName, { type, data }, true)
        }
      })
    },
    dispatch (channel, payload, prepend = false) {
      try {
        switch (true) {
          case channel.startsWith('comment'):
            this.pushComment(payload, prepend)
            break
        }
      } catch (error) {
        console.error(error)
      }
    },
    pushComment ({ data: comment, type }, prepend = false) {
      // 暫解：先排除掉 分析失敗的 comment
      if (comment && !comment.reason) {
        comment.type = type

        // filter message by store owner
        if (!comment.from.id) return

        if (prepend) {
          this.rawComments.unshift(comment)
        } else {
          this.rawComments.push(comment)
        }
      }
    },
    handleAnalysedComments: _.throttle(function () {
      let activityStart = this.currentActivity && this.currentActivity.started_at
        ? new Date(this.currentActivity.started_at)
        : null

      const activityEnded = this.currentActivity && this.currentActivity.ended_at
        ? new Date(this.currentActivity.ended_at)
        : null

      // 分析關鍵字
      let comments = this.rawComments

      if (this.searchInput) {
        comments = comments
          .filter(rawComment => {
            return rawComment.message && rawComment.message.includes(this.searchInput)
          })
      }

      // 過濾非活動期間留言
      comments = comments.map(raw => ({ ...raw, createdDate: new Date(raw.created_time * 1000) }))

      if (activityStart || activityStart) {
        comments = comments.filter(rawComment => {
          if (activityStart && rawComment.createdDate < activityStart) return false
          if (activityEnded && rawComment.createdDate >= activityEnded) return false

          return true
        })
      }

      this.analysedComments = _.sortBy(_.uniqBy(comments, 'comment_id'), comment => comment.createdDate.getTime())
    }, 7000),

    close () {
      this.$emit('close')
    }

  }

}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
