import CreateVoucher from './CreateVoucher'
import GetVoucherList from './GetVoucherList'
import UpdateVoucher from './UpdateVoucher'
import InterruptVoucher from './InterruptVoucher'

export default {
  namespaced: true,
  modules: {
    CreateVoucher,
    GetVoucherList,
    UpdateVoucher,
    InterruptVoucher
  }
}
