<template>
  <div id="app">
    <!-- 主要內容 -->
    <component :is="layout">
      <router-view :key="routerKey" />
    </component>

    <!-- loading畫面 -->
    <div
      v-if="isLoading"
      id="loading"
      v-loading="isLoading"
      element-loading-background="rgba(0, 0, 0, 0.5)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

const defaultLayout = 'LayoutDefault'

export default {
  metaInfo () {
    return { title: 'HandsUP' }
  },

  computed: {
    ...mapState(['isLoading', 'routerKey']),
    layout () {
      return (this.$route.meta.layout || defaultLayout)
    }
  }
}
</script>
<style lang="scss">
#app {
  flex: 1 1 100%;
}

#loading {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background .2s;
  background: transparent;

  & svg {
    width: 60px;
    height: 60px;
    circle {
      stroke-width: 4px;
    }
  }
}

</style>
<style lang="scss" src="./sass/all.scss"></style>
