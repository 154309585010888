<script>
import * as PAYMENT_FLOW from '@/constants/paymentFlow'
import * as REGIONS from '@/constants/regions'
import * as GMO_STATUS from '@/constants/gmoStatus'

import CarrierSetting from '@/api/carrierSetting'
import SettingTransaction from '@/views/Setting/Transaction'
import SettingChannel from '@/views/Setting/Channel'
import SettingStore from '@/views/Setting/Store'
import SettingSeller from '@/views/Setting/Seller'
import SettingCommercial from '@/views/Setting/JPCommercial'
import SettingPayment from '@/views/Setting/Payment'
import SettingAccount from '@/views/Setting/Account'
import SettingPlan from '@/views/Setting/Plan'
import SettingChildStore from '@/views/Setting/ChildStore'
import SettingApi from './Api'
import { mapGetters } from 'vuex'

export default {
  name: 'Setting',
  components: {
    SettingTransaction,
    SettingChannel,
    SettingStore,
    SettingSeller,
    SettingCommercial,
    SettingPayment,
    SettingAccount,
    SettingPlan,
    SettingChildStore,
    SettingApi
  },
  data () {
    return {
      menu: [
        {
          name: 'menu_transaction',
          component: 'SettingTransaction',
          key: 'Setting.menu_transaction'
        },
        {
          name: 'menu_channel',
          component: 'SettingChannel',
          key: 'Setting.menu_channel'
        },
        {
          name: 'menu_store',
          component: 'SettingStore',
          key: 'Setting.menu_store'
        },
        {
          name: 'menu_plan',
          component: 'SettingPlan',
          key: 'seller_subscription.plan_management'
        },
        {
          name: 'menu_seller',
          component: 'SettingSeller',
          key: 'Setting.menu_seller'
        },
        {
          name: 'menu_commercial',
          component: 'SettingCommercial',
          key: 'Setting.menu_commercial'
        },
        {
          name: 'menu_payment',
          component: 'SettingPayment',
          key: 'Setting.menu_payment'
        },
        {
          name: 'menu_account',
          component: 'SettingAccount',
          key: 'Setting.menu_account'
        },
        {
          name: 'menu_child_store',
          component: 'SettingChildStore',
          key: 'Setting.menu_child_store'
        },
        {
          name: 'menu_api',
          component: 'SettingApi',
          key: 'Setting.menu_api'
        }
      ],
      activeMenu: {},
      carrierSetting: {
        'family-business': {}
      }
    }
  },

  computed: {
    ...mapGetters('Me', [
      'isEnterprise',
      'isCurrentStoreOwner',
      'isChildStore',
      'currentStore',
      'paymentFlow',
      'gmoStatus',
      'region',
      'enabledParentStore',
      'isFBBuyStore'
    ]),
    normalizeMenu () {
      let menu = Array.from(this.menu)

      if (this.isFBBuyStore) {
        return menu.filter(item => item.name === 'menu_channel')
      }

      // 企業版
      if (this.isEnterprise) {
        const enterpriseItems = ['menu_channel', 'menu_store', 'menu_account', 'menu_api']
        if (this.isCurrentStoreOwner) enterpriseItems.push('menu_seller')
        return menu.filter(item => enterpriseItems.includes(item.name))

      // 非企業版
      } else {
        menu = menu.filter(item => item.name !== 'menu_api')

        // 我的帳戶
        if (this.region !== REGIONS.TW || this.paymentFlow === PAYMENT_FLOW.DIRECT) {
          menu = menu.filter(item => item.name !== 'menu_transaction')
        }

        // 電子商務法則(gmo form 審核通過後才開電商法)
        if (this.region !== REGIONS.JP || this.gmoStatus !== GMO_STATUS.SUCCESS || this.isChildStore) {
          menu = menu.filter(item => item.name !== 'menu_commercial')
        }

        // 賣家設定
        if (!this.isCurrentStoreOwner) {
          menu = menu.filter(item => item.name !== 'menu_seller')
        }

        // 方案管理
        if (this.region !== REGIONS.JP || !this.isCurrentStoreOwner || this.isChildStore) {
          menu = menu.filter(item => item.name !== 'menu_plan')
        }

        // 子母店
        if (!this.enabledParentStore) {
          menu = menu.filter(item => item.name !== 'menu_child_store')
        }

        return menu
      }
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.query.activeMenu) {
        const menu = this.normalizeMenu.find(item => item.component === to.query.activeMenu)
        this.updateActiveMenu(menu)
      }
    }
  },
  mounted () {
    let isSettingChannel = localStorage.getItem('isSettingChannel')

    if (isSettingChannel) {
      this.activeMenu = this.normalizeMenu[1]
      localStorage.removeItem('isSettingChannel')
    } else {
      const activeItem = this.normalizeMenu.find(item => item.component === this.$route.query.activeMenu)
      this.activeMenu = activeItem || this.normalizeMenu[0]
    }
    this.fetchCarrierSetting()
  },

  methods: {
    calcLiClass (item) {
      return {
        disabled: this.isFBBuyStore && this.activeMenu.name !== item.name,
        active: this.activeMenu.name === item.name,
        'b2c-applying': item.name === 'menu_payment' && this.carrierSetting['family-business']['is_verified'] === false }
    },
    updateActiveMenu (item) {
      if (!this.isCurrentStoreOwner && item.component === 'SettingSeller') {
        return
      }
      // [Facebook Pixel] 追蹤點擊左方Tab事件
      this.$analytics.fbq.event('ClickReg', {
        Page: 'Merchant-Platform',
        Content: 'Setting',
        Target: item.name
      })
      this.$ga.event({
        eventCategory: 'Bottom-Click',
        eventAction: item.name + '-Click',
        eventLabel: 'Merchant-Platform'
      })
      this.activeMenu = item
      this.$router.replace({ name: 'Setting', query: { activeMenu: undefined } })
    },

    async fetchCarrierSetting () {
      // TW only carrier settings
      if (this.region !== REGIONS.TW) return

      const apiCarrier = new CarrierSetting()
      const data = await apiCarrier.get('family-business')
      this.carrierSetting['family-business'] = data
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
