<script>
import { mapGetters, mapActions } from 'vuex'
import ApiStore from '@/api/store'

export default {
  skipMiddleware: ['checkAuth'],

  name: 'Invite',
  data () {
    return {
      token: null
    }
  },
  computed: {
    ...mapGetters('Me', ['isLogin']),
    ...mapGetters('Lang', ['locale']),
    message () {
      return this.isLogin
        ? this.$t('Invite.logging_in')
        : this.$t('Invite.not_logged_in')
    }
  },
  created () {
    this.token = this.$route.params.token
    if (this.isLogin) {
      this.joinPermission()
    } else {
      this.handleLogin()
    }
  },
  methods: {
    ...mapActions('Me', ['fetchStores', 'fetchCurrentStore']),
    async joinPermission () {
      const apiStore = new ApiStore()
      const data = await apiStore.bind({ token: this.token })

      if (data) {
        const { slug } = data
        // 更新商城資料
        await this.fetchStores()
        await this.fetchCurrentStore()
        // 前往商城
        this.$router.push({ path: `/${slug}` })
      } else {
        this.$message.error(this.$t('Invite.auth_fail'))
        this.$router.push({ name: 'UserLogin', query: { autoLogin: false } })
      }

      // 清除授權Token
      localStorage.removeItem('localInvite')
    },
    handleLogin () {
      // 紀錄前往的路徑
      localStorage.setItem('localInvite', this.$route.params.token)
      localStorage.removeItem('localParentInvite')
      this.$router.push({ name: 'UserLogin', query: { autoLogin: false } })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
