<script>
import { emailRule } from '@/utils/formRules'
import LayoutCard from '../components/LayoutCard'
import resetPassword from './resetPassword.js'

export default {
  name: 'ForgotPassword',
  components: { LayoutCard },
  skipMiddleware: ['getAuth', 'checkAuth'],
  data () {
    return {
      loading: false,
      form: {
        email: ''
      }
    }
  },
  computed: {
    rules () {
      return {
        email: emailRule()
      }
    }
  },
  methods: {
    async submit () {
      try {
        await this.$refs.form.validate()
      } catch {
        return
      }

      this.loading = true
      try {
        await resetPassword(this.form.email)
        this.$router.push({ name: 'UserForgotPasswordSent', query: { email: this.form.email } })
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    goRegister () {
      this.$router.push({ name: 'UserRegister' })
    }
  }
}
</script>

<template lang="pug" src="./ForgotPassword.pug"></template>
<style lang="scss" src="./ForgotPassword.scss" scoped></style>
