<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'BiddingBeforeEndDialog',
  data () {
    return {
      bidForm: {
        price: null
      },
      bidRules: {
        price: [
          { type: 'number', message: '', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Post', ['postId']),
    ...mapGetters('Post', ['currentBidding'])
  },

  mounted () {
    this.initData()
  },

  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('Post', ['updateBidding', 'endBidding', 'getBiddingWinners']),

    initData () {
      const price = _.get(this.currentBidding, 'skus[0].price', 0)
      if (price) this.bidForm.price = price * 1
    },
    handleCloseDialog () {
      this.$emit('toggleBiddingBeforeEndDialog', false)
    },
    async submit () {
      this.setLoading(true)
      try {
        const biddingId = this.currentBidding.id
        const postData = { price: this.bidForm.price }

        await this.endBidding({
          postId: this.postId,
          biddingId,
          postData
        })

        await this.getBiddingWinners(biddingId)

        this.handleCloseDialog()
        this.$emit('submit')
        this.setLoading(false)
      } catch (error) {
        console.error(error)
        this.setLoading(false)
      }
    }

  }
}
</script>

<template lang="pug" src="./BiddingBeforeEndDialog.pug"></template>
<style lang="scss" src="./BiddingBeforeEndDialog.scss" scoped></style>
