<script>
import VueTypes from 'vue-types'
import { mapGetters, mapActions, mapMutations } from 'vuex'

import { getRtmpInfo, startWithRtmp, startStream } from '@/api/post'
import storeId from '@/api/_storeId'

import * as LIVE_STATUS from '@/constants/liveStatus'
import * as CHANNEL_TYPE from '@/constants/channelType'

const calcRtmpInfo = (streamUrl) => {
  let rtmpUrl = ''
  let rtmpKey = ''

  if (streamUrl.includes('wansu')) {
    // 如果是網宿的URL
    const [ url, key ] = streamUrl.split('/vod/')
    rtmpUrl = `${url}/vod/`
    rtmpKey = key
  } else {
    // 如果是HU SRS的URL
    const [ url, key ] = streamUrl.split('/app/')
    rtmpUrl = `${url}/app/`
    rtmpKey = key
  }

  return {
    url: rtmpUrl,
    key: rtmpKey
  }
}

export default {
  name: 'DialogSetRtmp',
  props: {
    liveStatus: VueTypes.string.required,
    perPostItem: VueTypes.object,
    isPrivate: VueTypes.bool.def(true)
  },
  data () {
    return {
      ...LIVE_STATUS,
      CHANNEL_TYPE,
      rtmpUrl: '',
      rtmpKey: '',
      isLoading: true
    }
  },
  computed: {
    ...mapGetters('Me', ['token']),
    ...mapGetters('Me/StoreChannels', ['channelsWithoutHandsUP']),
    ...mapGetters('Post', ['selectedStreamChannels', 'post']),
    hasRtmpInfo () {
      return !!this.rtmpKey
    },
    isAfterBroadcast () {
      // 開播後的狀態
      const BROADCAST = [
        LIVE_STATUS.LIVE,
        LIVE_STATUS.LIVE_STOPPED,
        LIVE_STATUS.LIVE_REPLAY
      ]

      return BROADCAST.includes(this.liveStatus)
    },
    selectChannels: {
      get () {
        return this.selectedStreamChannels
      },
      set (value) {
        this.updateSelectedStreamChannels(value)
      }
    }
  },
  created () {
    this.initData()
  },
  methods: {
    ...mapActions('Me/StoreChannels', ['fetchChannels']),
    ...mapMutations('Post', ['updateSelectedStreamChannels']),
    async initData () {
      await this.fetchRtmpInfo()

      if (this.hasRtmpInfo) return

      if (this.isPrivate) {
        this.handleGenerateRtmp()
      } else {
        this.fetchChannels(storeId())
      }
    },

    async fetchRtmpInfo () {
      this.isLoading = true

      try {
        const { data: streamMeta } = await getRtmpInfo(this.perPostItem.id)
        const { url, key } = calcRtmpInfo(streamMeta.stream_url)

        this.rtmpUrl = url
        this.rtmpKey = key
      } catch (err) {
        console.error(err)
      }

      this.isLoading = false
    },
    async handleGenerateRtmp () {
      if (this.isLoading) return
      this.isLoading = true
      try {
        const { data: streamMeta } = await startWithRtmp(this.perPostItem.id, {
          channels: this.selectChannels
        })
        const { url, key } = calcRtmpInfo(streamMeta.stream_url)

        this.rtmpUrl = url
        this.rtmpKey = key
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    },
    async handleStartStream () {
      if (this.isLoading) return

      this.isLoading = true

      try {
        await startStream(this.perPostItem.id, {
          channels: this.selectChannels
        })
        this.handleClose()
      } catch (err) {
        console.error(err)
      }

      this.isLoading = false
    },
    handleClose () {
      this.$emit('close')
      this.$emit('update:dialogVisible', false)
    },

    copy (data) {
      this.copyText({
        url: data
      })
    }
  }
}
</script>

<template lang="pug" src="./DialogSetRtmp.pug"></template>
