<script>
export default {
  name: 'DialogConfirmPassword',

  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    handleSubmit () {
      this.$emit('submit')
      this.handleCloseDialog()
    }
  }
}
</script>

<template lang="pug" src="./DialogConfirmPassword.pug"></template>
