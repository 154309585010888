<script>
import { mapGetters } from 'vuex'
import { TW, JP } from '@/constants/regions'
import * as SETTINGS from '@/constants/storeSettings'
import HighlightElement from '../HighlightElement'

export default {
  name: 'Notifications',
  components: { HighlightElement },
  data () {
    return {
      SETTINGS
    }
  },
  computed: {
    ...mapGetters('Me', ['region']),
    ...mapGetters('LiveSetting', ['newSettings', 'hoverElements']),
    topPosition () {
      let count = 0
      if (this.newSettings[SETTINGS.JOIN_ROOM_SHOW]) count++
      if (this.newSettings[SETTINGS.ADD_CART_NOTICE]) count++

      const topOffset = 200
      const elementHeight = 33
      return topOffset - ((count - 1) * elementHeight) + 'px'
    },
    addCartName () {
      const names = {
        [TW]: 'R***',
        [JP]: 'K'
      }

      return names[this.region] || names[JP]
    },

    welcomeName () {
      const names = {
        [TW]: 'N****',
        [JP]: 'ヤマキ'
      }

      return names[this.region] || names[JP]
    }
  }
}
</script>

<template lang="pug" src="./Notifications.pug"></template>
<style lang="scss" src="./Notifications.scss" scoped></style>
