<script>
import AddressSelect from '@/components/AddressSelect'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import { FORM_REFS } from '@/constants/orderManual'
import { mapActions, mapState } from 'vuex'
import { getShippingLabel } from '@/utils/getShippingLabel'
import FormSeven from './components/FormSeven'
import FormFamily from './components/FormFamily'
import FormHomeDelivery from './components/FormHomeDelivery'
import FormSelfPickUp from './components/FormSelfPickUp'

export default {
  name: 'ShippingAddress',
  components: { AddressSelect, FormSeven, FormFamily, FormHomeDelivery, FormSelfPickUp },
  data () {
    return {
      FORM_REFS,
      SHIPPING_TYPES
    }
  },
  computed: {
    ...mapState('OrderManual/ShippingAddress', ['availableShippings', 'shippingType', 'storeId', 'selfPickAddress']),
    currentShipping: {
      get () {
        return this.shippingType
      },
      set (value) {
        this.updateShippingType(value)
      }
    },
    shippingOptions () {
      return this.availableShippings.map(info => ({
        value: info.type,
        label: getShippingLabel(info.type)
      }))
    }
  },
  async mounted () {
    await this.getAvailableShippingMethod()
    this.restoreCachedOrder()
    this.initData()
  },
  methods: {
    ...mapActions('OrderManual/ShippingAddress', ['getAvailableShippingMethod', 'updateShippingType', 'updateConvenientStore', 'restoreCachedOrder']),
    getRef () {
      switch (this.shippingType) {
        case SHIPPING_TYPES.HOME_DELIVERY:
        case SHIPPING_TYPES.HSINCHU:
          return FORM_REFS.FormHomeDelivery
        case SHIPPING_TYPES.FAMILY:
          return FORM_REFS.FormFamily
        case SHIPPING_TYPES.SEVEN:
          return FORM_REFS.FormSeven
        case SHIPPING_TYPES.SELF_PICK_UP:
          return FORM_REFS.FormSelfPickUp
        default:
          break
      }
    },
    async validateForm () {
      try {
        const refName = this.getRef()
        await this.$refs[refName].$refs.nameAndFields.$refs.form.validate()
        return true
      } catch (error) {
        return false
      }
    },
    async validateAddress () {
      try {
        const refName = this.getRef()
        if (this.shippingType === SHIPPING_TYPES.HOME_DELIVERY || this.shippingType === SHIPPING_TYPES.HSINCHU) {
          await this.$refs[refName].$refs.addressForm.validateForm()
          return true
        }
        if (this.shippingType === SHIPPING_TYPES.FAMILY || this.shippingType === SHIPPING_TYPES.SEVEN) {
          return !!this.storeId
        }
        if (this.shippingType === SHIPPING_TYPES.SELF_PICK_UP && this.selfPickAddress) {
          return true
        }
        return false
      } catch (error) {
        return false
      }
    },
    initData () {
      // 從 全家 或 7-11 選擇門市導回來
      if (location.search) {
        const decodedSearch = decodeURIComponent(location.search)
        const searchStr = decodedSearch.substring(1).split('&')

        const params = searchStr.reduce((prev, str) => {
          const name = str.split('=')[0]
          const value = decodeURIComponent(str.split('=')[1])
          prev[name] = value

          return prev
        }, {})

        if (params.store_id) {
          this.updateConvenientStore({
            ...params,
            storeType: this.shippingType
          })
        }
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
