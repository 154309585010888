<script>
import { mapGetters, mapActions } from 'vuex'

import AccountDescription from '../AccountDescription'
import PaymentInformation from '../PaymentInformation'
import AccountList from '../AccountList'

export default {
  name: 'ContainerAccountDetail',
  components: {
    AccountDescription,
    PaymentInformation,
    AccountList
  },

  computed: {
    ...mapGetters('Me', ['storeName', 'isEnterprise'])
  },

  created () {
    this.initData()
  },

  methods: {
    ...mapActions('AccountManagement', ['fetchPaymentBill']),
    ...mapActions('AccountManagement', ['fetchPaymentList']),

    initData () {
      this.fetchPaymentBill(this.storeName)
      this.fetchPaymentList(this.storeName)
    }

  }
}
</script>

<template lang="pug" src="./ContainerAccountDetail.pug"></template>
