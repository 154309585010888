import _ from 'lodash'
import Activity from './Activity'
import ActivityAuction from './ActivityAuction'
import ActivityBidding from './ActivityBidding'
import ActivityDraw from './ActivityDraw'
import Analysis from './Analysis'
import Blacklist from './Blacklist'
import DialogVisible from './DialogVisible'
import Post from './Post'
import Product from './Product'
import StreamInfo from './StreamInfo'
import ViewersCount from './ViewersCount'
import Export from './Export'
import Notification from './Notification'
import ConnectSocketIO from './ConnectSocketIO'

const actions = {
  fetchAll: _.throttle(async function ({ getters, dispatch }) {
    await Promise.all([
      dispatch('fetchPost'),
      dispatch('fetchAnalysis', getters.postId),
      dispatch('fetchActivities', getters.postId)
    ])
  }, 2500)
}

export default {
  namespaced: true,
  actions,
  modules: {
    Activity,
    ActivityAuction,
    ActivityBidding,
    ActivityDraw,
    Analysis,
    Blacklist,
    DialogVisible,
    Post,
    Product,
    StreamInfo,
    ViewersCount,
    Export,
    Notification,
    ConnectSocketIO
  }
}
