<script>
import { mapGetters, mapActions } from 'vuex'
import PaginationPure from '@/components/PaginationPure'
import { downloadReceipt } from '../../services/api'

export default {
  name: 'Receipt',
  components: { PaginationPure },
  computed: {
    ...mapGetters('Me', ['currentStore']),
    ...mapGetters('Receipt', ['receipts', 'meta', 'isLoading'])
  },
  created () {
    this.fetchReceipts()
  },
  methods: {
    ...mapActions('Receipt', ['fetchReceipts', 'updateRows']),
    async downloadReceipt (receiptId) {
      try {
        await downloadReceipt(this.currentStore.id, receiptId)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<template lang="pug" src="./Receipt.pug"></template>
