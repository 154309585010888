import Vue from 'vue'
import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'

const PRODUCTION = 'production'

const TEST_KEY = '1234'
let apiKey = process.env.VUE_APP_BUGSNAG_ID
if (process.env.NODE_ENV === 'test') {
  apiKey = TEST_KEY
}
const bugsnagClient = bugsnag({
  apiKey: apiKey,
  beforeSend: report => {
    report.app.releaseStage = process.env.VUE_APP_STAGE
    report.app.version = process.env.version
    if (process.env.VUE_APP_STAGE !== PRODUCTION) {
      report.ignore()
    }
  }
}).use(bugsnagVue, Vue)

if (process.env.VUE_APP_STAGE === PRODUCTION) {
  let errorHandler = window.console.error
  const errorConvert = err => {
    let errorStack = err
    if (err && typeof err === 'object' && !(err instanceof Error)) {
      try {
        errorStack = JSON.stringify(err)
      } catch (e) {
        errorStack = err.toString()
      }
      return new Error(errorStack)
    }
    return errorStack
  }

  window.console.error = e => {
    bugsnagClient.notify(errorConvert(e))
    errorHandler(e)
  }

  Vue.config.errorHandler = (e, vm, info) => {
    bugsnagClient.notify(errorConvert(e))
    errorHandler(e)
  }
}

if (window && window.screen) {
  bugsnagClient.metaData = {
    resolution: {
      width: window.screen.width,
      height: window.screen.height
    }
  }
}

export default bugsnagClient
