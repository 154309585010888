import BaseApi from './baseApi'
import axios from '@/plugins/http.js'

export default class Shipping extends BaseApi {
  uri = '/api/v1/shippings'

  // 產生貨運條碼
  create = async (id, data) => {
    // return promise only for custom error handling
    return axios.post(this.uri + `/${id}/add-order`, data)
  }

  // 重新產生物流單
  reAddOrder = async id => {
    // return promise only for custom error handling
    return axios.post(this.uri + `/${id}/re-add-order`)
  }

  getTypes = async (id) => {
    try {
      const api = `/api/v1/stores/${id}/shippings`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  batchAddOrder = async (storeId, postData) => {
    const api = `/api/v1/stores/${storeId}/shippings/batch-add-order`

    try {
      const { data } = await axios.post(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  batchShipOrder = async (storeId, postData) => {
    const api = `/api/v1/stores/${storeId}/shippings/batch-ship-order`

    try {
      const { data } = await axios.post(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  importShipping = async (storeId, file) => {
    const uploadPath = '/api/v1/shippings/import'
    const allowedTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ]

    if (!allowedTypes.includes(file.type)) {
      return false
    }

    let formData = new FormData()
    formData.append('file', file)

    try {
      const { data } = await axios.post(uploadPath, formData)

      return data
    } catch (e) {
      console.error(e)
      return false
    }
  }
}
