import store from '@/store'
import _ from 'lodash'

function trans (key) {
  let currentLocale = store.getters['Lang/locale']
  let langFile = require(`../lang/${currentLocale}.json`)
  return _.get(langFile, key)
}

export default trans
