
import * as ORDER_STATUS from '@/constants/orderStatus'
import * as ORDER_TYPES from '@/constants/orderTypes'
import * as PAYMENT_STATUS from '@/constants/paymentStatus'
import * as PAYMENT_TYPES from '@/constants/paymentTypes'
import * as SHIPPING_STATUS from '@/constants/shippingStatus'

// 訂單完成的類型
const ORDER_COMPLETE_TYPE = {
  AUTOMATIC: 'automatic',
  MANUAL: 'manual'
}

// 訂單的 Step 狀態
const STEP_TYPES = {
  CONFIRM: 'confirm',
  PAYMENT: 'payment',
  DELIVERY: 'delivery',
  SHIPPMENT: 'shippment',
  CASH_ON_DELIVERY: 'cash_on_delivery',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  RETURN: 'return',
  RECEIVE: 'receive'
}

const STEP_STATUS = {
  ACTIVE: 'active',
  COMPLETED: 'completed'
}

const totalSteps = {
  // 買家確認訂單時間
  [STEP_TYPES.CONFIRM]: {
    type: STEP_TYPES.CONFIRM,
    no: { icon: 'fa-user' },
    yes: { icon: 'fa-user-check' }
  },
  // 買家付款時間
  [STEP_TYPES.PAYMENT]: {
    type: STEP_TYPES.PAYMENT,
    no: { icon: 'fa-money-bill-wave' },
    yes: { icon: 'fa-money-bill-wave' }
  },
  // 出貨時間
  [STEP_TYPES.DELIVERY]: {
    type: STEP_TYPES.DELIVERY,
    no: { icon: 'fa-hand-holding-box' },
    yes: { icon: 'fa-hand-holding-box' }
  },
  // 到貨時間
  [STEP_TYPES.SHIPPMENT]: {
    type: STEP_TYPES.SHIPPMENT,
    no: { icon: 'fa-shipping-fast' },
    yes: { icon: 'fa-shipping-fast' }
  },
  // 到貨時間
  [STEP_TYPES.CASH_ON_DELIVERY]: {
    type: STEP_TYPES.CASH_ON_DELIVERY,
    no: { icon: 'fa-money-bill-wave' },
    yes: { icon: 'fa-money-bill-wave' }
  },
  // 訂單完成時間
  [STEP_TYPES.COMPLETED]: {
    type: STEP_TYPES.COMPLETED,
    no: { icon: 'fa-file-check' },
    yes: { icon: 'fa-file-check' }
  },
  // 取消訂單時間
  [STEP_TYPES.CANCELED]: {
    type: STEP_TYPES.CANCELED,
    no: { icon: 'fa-file-times' },
    yes: { icon: 'fa-file-times' }
  },
  // 退貨申請時間
  [STEP_TYPES.RETURN]: {
    type: STEP_TYPES.RETURN,
    no: { icon: 'fa-exchange' },
    yes: { icon: 'fa-exchange' }
  },
  // 買家取貨時間
  [STEP_TYPES.RECEIVE]: {
    type: STEP_TYPES.RECEIVE,
    no: { icon: 'fa-person-carry' },
    yes: { icon: 'fa-person-carry' }
  }
}

function pickupRequiredSteps (status, order) {
  let stepsList = []
  let hasShipping = order.shipping_status === SHIPPING_STATUS.DELIVERED
  let hasPayment = order.payment_status === PAYMENT_STATUS.PAID
  let isCashOnDelivery = order.payment_type === PAYMENT_TYPES.COD
  let isSubscription = order.type === ORDER_TYPES.SUBSCRIPTION

  // 正常流程
  stepsList = [
    STEP_TYPES.CONFIRM,
    STEP_TYPES.PAYMENT,
    STEP_TYPES.DELIVERY,
    STEP_TYPES.SHIPPMENT,
    STEP_TYPES.COMPLETED
  ]

  // 訂閱制流程
  if (isSubscription) {
    stepsList = [
      STEP_TYPES.CONFIRM,
      STEP_TYPES.PAYMENT,
      STEP_TYPES.COMPLETED
    ]
  }

  // 超商取貨付款
  if (isCashOnDelivery) {
    stepsList = [
      STEP_TYPES.CONFIRM,
      STEP_TYPES.DELIVERY,
      STEP_TYPES.SHIPPMENT,
      STEP_TYPES.COMPLETED
    ]
  }

  // 退貨申請中
  if (status === ORDER_STATUS.RETURNING) {
    stepsList = [
      STEP_TYPES.CONFIRM,
      STEP_TYPES.DELIVERY,
      STEP_TYPES.SHIPPMENT,
      STEP_TYPES.RETURN,
      STEP_TYPES.RECEIVE
    ]
  }

  // 取消訂單
  if (status === ORDER_STATUS.CANCELED) {
    if (hasShipping) {
      stepsList = [
        STEP_TYPES.CONFIRM,
        STEP_TYPES.DELIVERY,
        STEP_TYPES.SHIPPMENT,
        STEP_TYPES.RETURN,
        STEP_TYPES.RECEIVE
      ]
    } else {
      if (hasPayment) {
        stepsList = [
          STEP_TYPES.CONFIRM,
          STEP_TYPES.PAYMENT,
          STEP_TYPES.CANCELED
        ]
      } else {
        stepsList = [
          STEP_TYPES.CONFIRM,
          STEP_TYPES.CANCELED
        ]
      }
    }
  }

  // 手動完成訂單
  const isCompleteOrder = order.complete_type === ORDER_COMPLETE_TYPE.MANUAL
  if (isCompleteOrder) {
    if (hasShipping) {
      stepsList = [
        STEP_TYPES.CONFIRM,
        STEP_TYPES.DELIVERY,
        STEP_TYPES.SHIPPMENT,
        STEP_TYPES.COMPLETED
      ]
    } else {
      if (hasPayment) {
        stepsList = [
          STEP_TYPES.CONFIRM,
          STEP_TYPES.PAYMENT,
          STEP_TYPES.COMPLETED
        ]
      } else {
        stepsList = [
          STEP_TYPES.CONFIRM,
          STEP_TYPES.COMPLETED
        ]
      }
    }
  }

  return stepsList
}

function getCompleteAtIndex (stepsList, status) {
  let done = ''

  switch (status) {
    case 'cart':
      done = ''
      break
    case ORDER_STATUS.UNPAID:
      done = STEP_TYPES.CONFIRM
      break
    case ORDER_STATUS.UNDELIVERED:
      done = STEP_TYPES.PAYMENT
      break
    case ORDER_STATUS.DELIVERING:
      done = STEP_TYPES.DELIVERY
      break
    case ORDER_STATUS.COMPLETED:
      done = STEP_TYPES.COMPLETED
      break
    case ORDER_STATUS.RETURNING:
      done = STEP_TYPES.SHIPPMENT
      break
    case ORDER_STATUS.CANCELED:
      done = STEP_TYPES.RECEIVE
      break
    default:
      done = ''
      break
  }

  if (status === ORDER_STATUS.CANCELED) {
    return 5
  }

  return stepsList.indexOf(done)
}

function formatStepData (stepsList, completeIndex) {
  return stepsList.map((stepName, index) => {
    let stepData = totalSteps[stepName]
    let isComplete = index <= completeIndex
    let isActive = index === completeIndex + 1

    let status = ''
    if (isActive) {
      status = STEP_STATUS.ACTIVE
    } else if (isComplete) {
      status = STEP_STATUS.COMPLETED
    }

    return {
      type: stepData.type,
      isComplete: isComplete ? 'yes' : 'no',
      status,
      icon: isComplete ? stepData.yes.icon : stepData.no.icon
    }
  })
}

function appendMemoToStepData (stepData, order) {
  if (order.status === ORDER_STATUS.CANCELED && order.reason) {
    stepData[stepData.length - 1].memo = order.reason
  }

  return stepData
}

export default function (status, order) {
  let stepsList = pickupRequiredSteps(status, order)

  let completeIndex = getCompleteAtIndex(stepsList, status)

  let formattedStepData = formatStepData(stepsList, completeIndex)

  return appendMemoToStepData(formattedStepData, order)
}
