<script>
import PubNub from 'pubnub'
import { mapGetters, mapActions } from 'vuex'
import queryString from 'query-string'

import ApiOrder from '@/api/order'
import ApiShipping from '@/api/shipping'
import ApiOrderMessage from '@/api/orderMessage'
import ApiPrint from '@/api/print'
import ApiService from '@/api/additionalService'

import * as ORDER_STATUS from '@/constants/orderStatus'
import * as PAYMENT_FLOW from '@/constants/paymentFlow'
import * as PAYMENT_STATUS from '@/constants/paymentStatus'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'

import AppAlert from '@/components/AppAlert'
import StepsBar from '@/components/StepsBar'
import DialogBuyPoints from '@/components/DialogBuyPoints'
import DialogOfflineAtm from '@/components/DialogOfflineAtm'
import DialogShippingPoints from '../components/DialogShippingPoints'
import OrderInfo from './components/OrderInfo'
import PurchaseDetail from './components/PurchaseDetail'
import PurchasePrice from './components/PurchasePrice'
import PaymentDetail from './components/PaymentDetail'
import OrderMessage from './components/OrderMessage'
import OrderMenu from './components/OrderMenu'
import OrderParentChildren from './components/OrderParentChildren'
import Memo from './components/Memo'
import DialogPaymentDetail from './components/DialogPaymentDetail'
import DialogShipmentFreight from './components/DialogShipmentFreight'
import DialogShipmentStore from './components/DialogShipmentStore'
import DialogShipmentStatus from './components/DialogShipmentStatus'
import DialogShipmentGhn from './components/DialogShipmentGhn'
import DialogCancelOrder from './components/DialogCancelOrder'
import DialogApproveApply from './components/DialogApproveApply'
import DialogRejectApply from './components/DialogRejectApply'
import DialogUpdateOrder from './components/DialogUpdateOrder'
import DialogEditAddress from './components/DialogEditAddress'
import DialogKerryThShipment from './components/DialogKerryThShipment'
import DialogShipmentStorePickup from './components/DialogShipmentStorePickup'
import DialogCompleteOrderManually from './components/DialogCompleteOrderManually'
import DialogChangePaymentNotice from './components/DialogChangePaymentNotice'
import DialogSplitOrder from './components/DialogSplitOrder'
import DialogShipmentStoreInvoice from './components/DialogShipmentStoreInvoice'

export default {
  name: 'Show',
  components: {
    AppAlert,
    StepsBar,
    OrderMessage,
    OrderInfo,
    PurchaseDetail,
    PurchasePrice,
    PaymentDetail,
    OrderMenu,
    OrderParentChildren,
    Memo,
    DialogPaymentDetail,
    DialogShipmentFreight,
    DialogShipmentStore,
    DialogShipmentStatus,
    DialogShipmentGhn,
    DialogCancelOrder,
    DialogApproveApply,
    DialogRejectApply,
    DialogUpdateOrder,
    DialogBuyPoints,
    DialogShippingPoints,
    DialogEditAddress,
    DialogKerryThShipment,
    DialogShipmentStorePickup,
    DialogOfflineAtm,
    DialogCompleteOrderManually,
    DialogChangePaymentNotice,
    DialogSplitOrder,
    DialogShipmentStoreInvoice
  },
  
  async beforeRouteUpdate (to, from, next) {
    this.orderId = to.params.order
    this.resetData()
    await this.initData()
    next()
  },

  data () {
    return {
      dialogPaymentDetailVisible: false,
      dialogShipmentFreightVisible: false,
      dialogShipmentStoreVisible: false,
      dialogShipmentStatusVisible: false,
      dialogShipmentGhnVisible: false,
      dialogCancelOrderVisible: false,
      dialogApproveApplyVisible: false,
      dialogRejectApplyVisible: false,
      dialogBuyPointsVisible: false,
      dialogShippingPointsVisible: false,
      dialogEditAddressVisible: false,
      dialogKerryThShipmentVisible: false,
      dialogShipmentStorePickupVisible: false,
      dialogOfflineAtmVisible: false,
      dialogCompleteOrderManuallyVisible: false,
      dialogChangePaymentNoticeVisible: false,
      dialogSplitOrderVisible: false,

      orderId: this.$route.params.order,

      btnLoading: [],
      messages: [],
      sendingMessages: false,
      pendingCount: 0,
      success: 0,
      fail: 0,
      printMode: null,
      printSlugs: [],
      pointsLeft: 0,
      handleShippingPoints: null,

      ignoreOverpayment: JSON.parse(localStorage.getItem('ignoreOverpayment')) || [],
      dialogShipmentStoreInvoiceVisible: false
    }
  },
  computed: {
    ...mapGetters('Me', ['me', 'storeName', 'currentStore', 'region', 'isFamilyB2C', 'paymentFlow']),
    ...mapGetters('OrderDetail', ['isCart', 'isSubscriptionType', 'orderData', 'order', 'items', 'payments', 'payment', 'steps', 'shipping']),
    ...mapGetters('OrderDetail', ['dialogUpdateOrderVisible']),

    fetchOrderData () {
      return {
        orderId: this.orderId,
        region: this.region
      }
    },

    company () {
      return this.order.shippingInfo && this.order.shippingInfo.company
    },

    ghnInfo () {
      return _.get(this.order, 'shippingInfo.third_party', {})
    },

    showOverpaymentTip () {
      // 當溢付金額大於 0 ，代表溢付
      return !this.ignoreOverpayment.includes(this.orderData.id) &&
        Number(this.orderData.overpayment) > 0
    }

  },

  async mounted () {
    await this.initData()
  },

  beforeDestroy () {
    this.resetData()
  },

  methods: {
    ...mapActions('AdditionalService', ['updateOrigin']),
    ...mapActions('OrderDetail', ['updateIsCart', 'fetchOrderDetail', 'resetOrderData']),
    ...mapActions('OrderDetail', ['updateOrder', 'toggleDialogUpdateOrder']),
    ...mapActions('OrderDetail', ['updateBankReceipt']),
    ...mapActions('OrderDetail', ['setOrderComplete']),
    ...mapActions('OrderDetail', ['openDialogEditShipping']),
    ...mapActions(['setLoading']),

    async initData () {
      this.setLoading(true)

      // Pubnub for printing
      this.pubnub = new PubNub({
        subscribeKey: process.env.VUE_APP_PUBNUB_SUBSCRIBE_KEY
      })
      const channel = `notification|shipping|${this.storeName}|${this.me.slug}`

      this.pubnub.subscribe({
        channels: [channel]
      })

      this.pubnub.addListener({
        message: m => this.dispatch(m.channel, m.message)
      })

      const isCart = !!this.$route.query.cart
      this.updateIsCart(isCart)

      // 取得 Order 資料
      await this.fetchOrderDetail(this.fetchOrderData)
        .then(() => {
          if (!this.isCart) {
            this.fetchOrderMessages()
          }
        })
        .catch(() => {
          this.$message.error(this.$t('Order.Show.error_fetch_order'))
          let path = `/${this.storeName}/order`
          this.$router.push({ path })
        })

      // 更新門市
      if (location.search) {
        if (this.paymentFlow === PAYMENT_FLOW.DIRECT) {
          // 非代收代付
          if (localStorage.getItem('splitOrder')) {
            this.dialogSplitOrderVisible = true
          } else if (localStorage.getItem('orderEditShipping')) {
            await this.openDialogEditShipping(this.currentStore.id)
          }
        } else {
          // 代收代付
          await this.updateShippingStore()
        }
      }

      this.setLoading(false)
    },

    resetData () {
      // 清空資料
      this.resetOrderData()

      // PubNub Unsubscribe：
      // https://www.pubnub.com/docs/web-javascript/api-reference-publish-and-subscribe#unsubscribe_all

      try {
        this.pubnub.unsubscribeAll()
      } catch (e) {
        console.error(e)
      }
    },

    dispatch (channel, payload) {
      this.pendingCount--
      if (payload.type.includes('success')) {
        this.success++
      } else if (payload.type.includes('fail')) {
        this.fail++
        if (payload.type.includes('shipping.printed')) {
          this.printSlugs = this.printSlugs.filter(x => x !== payload.data.order_slug)
        }
      }

      if (this.pendingCount !== 0) return

      if (payload.type.includes('shipping.printed')) {
        this.printComplete()
      }
    },
    async updateShippingStore () {
      const decodedSearch = decodeURIComponent(location.search)
      const searchStr = decodedSearch.substring(1).split('&')
      const params = searchStr.reduce((prev, str) => {
        const name = str.split('=')[0]
        const value = decodeURIComponent(str.split('=')[1])
        prev[name] = value

        return prev
      }, {})

      if (params.store_id) {
        const newAddress = params.address
        const apiShipping = new ApiShipping()
        await apiShipping.update(this.order.shippingId, { info: params, receiver_address: newAddress })

        // 重撈訂單資料
        this.fetchOrderDetail(this.fetchOrderData)
      }
    },
    async fetchOrderMessages () {
      const apiOrderMessage = new ApiOrderMessage()
      this.messages = await apiOrderMessage.list(this.orderId, 'all')
    },
    async createOrderMessage (message) {
      if (this.sendingMessages) return

      this.sendingMessages = true
      const apiOrderMessage = new ApiOrderMessage()
      const success = await apiOrderMessage.create(this.orderId, message)

      if (success) {
        this.fetchOrderMessages()
      }

      this.sendingMessages = false
    },
    async cancelOrder ({ reason, hasCompensation }) {
      this.toggleBtnLoading('cancelOrder')
      const apiOrder = new ApiOrder()
      const success = await apiOrder.cancel(this.orderId, {
        reason,
        has_compensation: hasCompensation
      })

      if (success) {
        this.dialogCancelOrderVisible = false
        this.fetchOrderDetail(this.fetchOrderData)
      } else {
        this.$message.error(this.$t('Order.Show.error_update_order'))
      }

      this.toggleBtnLoading('cancelOrder')
    },
    async handleShipmentCreate () {
      if (this.order.shippingType === SHIPPING_TYPES.GHN) {
        this.dialogShipmentGhnVisible = true
        return
      }

      if (this.order.shippingType === SHIPPING_TYPES.KERRY_TH) {
        this.dialogKerryThShipmentVisible = true
        return
      }
      if (!(this.order.shippingType === SHIPPING_TYPES.FAMILY && this.isFamilyB2C)) {
        this.shipmentCreate()
        return
      }

      // Family B2C
      const apiService = new ApiService()
      const { data } = await apiService.get('family_business')
      if (!data) return
      this.pointsLeft = data.summary.quantity
      this.dialogShippingPointsVisible = true
      this.handleShippingPoints = this.handleB2CShipmentCreate
    },
    handleBuyPoints () {
      this.updateOrigin({ name: 'Order', path: `/${this.storeName}/order/${this.order.id}` })
      this.dialogShippingPointsVisible = false
      this.dialogBuyPointsVisible = true
    },
    handleB2CShipmentCreate () {
      this.dialogShippingPointsVisible = false
      this.shipmentCreate()
    },
    async handleGhnSubmit (ghnForm) {
      const data = {
        info: {
          third_party: { ...ghnForm }
        }
      }
      this.setLoading(true)
      await this.shipmentCreate(data)
      this.setLoading(false)
      this.dialogShipmentGhnVisible = false
    },
    handleKerryThShipmentCreate (method) {
      this.dialogKerryThShipmentVisible = false
      this.shipmentCreate({ method })
    },
    async shipmentCreate (data = null) {
      this.toggleBtnLoading('shipmentCreate')

      try {
        const apiShipping = new ApiShipping()
        const { data: response } = await apiShipping.create(this.order.shippingId, data)
        await this.fetchOrderDetail(this.fetchOrderData)

        if (response.success) {
          this.dialogShipmentStoreVisible = true
        } else {
          this.$message.error(response.message)
        }
      } catch (e) {
        await this.fetchOrderDetail(this.fetchOrderData)

        const title = `<h5>${this.$t('Order.Batchshipping.generate_fail')}</h5>`
        const message = `<div class="text-gray-800">${this.$t('message.problem_contact')}</div>`
        let errorMessage = ''
        if (e.data.error && e.data.error.code) {
          errorMessage = `${this.$t('shipping.error_code')} ${e.data.error.code}`
        } else {
          errorMessage = `${e.data.message}`
        }
        const error = `<div class="text-gray-700 text-xs">${errorMessage}</div>`
        this.$msgbox({
          title: '',
          center: true,
          showClose: true,
          customClass: 'orderShow-msgbox',
          message: title + message + error,
          dangerouslyUseHTMLString: true,
          showCancelButton: false,
          closeOnClickModal: false,
          confirmButtonText: this.$t('button.confirm')
        }).catch(() => { })
      }

      this.toggleBtnLoading('shipmentCreate')
    },
    async handleReShipmentStore () {
      if (this.order.shippingSubType === 'c2c' && this.isFamilyB2C) {
        // Family C2C to B2C 過渡期
        const message = `<h5>${this.$t('Order.Batchshipping.family_b2c_activated')}</h5>`
        const pointsMessage = `<div>${this.$t('Order.Batchshipping.readd_b2c')}</div>`

        this.$msgbox({
          title: '',
          center: true,
          showClose: true,
          customClass: 'orderShow-msgbox',
          message: message + pointsMessage,
          dangerouslyUseHTMLString: true,
          showCancelButton: false,
          closeOnClickModal: false,
          confirmButtonText: this.$t('button.confirm')
        })
          .then(() => this.B2CReAdd())
          .catch(() => this.B2CReAdd())
      } else if (this.order.shippingSubType === 'b2c') {
        // B2C
        this.B2CReAdd()
      } else {
        // Not B2C
        this.reShipmentStore()
      }
    },
    async B2CReAdd (shippingSlug) {
      this.setLoading(true)
      const apiService = new ApiService()
      const { data } = await apiService.get('family_business')
      this.setLoading(false)
      if (!data) return
      const pointsLeft = data.summary.quantity

      if (pointsLeft > 0) {
        const message = `<h5>${this.$t('Order.Batchshipping.generate_b2c')}
          <span class="text-danger"> 1 </span>${this.$t('Order.Batchshipping.points')}？</h5>`
        const pointsMessage = `<div>${this.$t('Order.Batchshipping.remaining_points')}
          ${pointsLeft}${this.$t('Order.Batchshipping.points')}</div>`

        this.$msgbox({
          title: '',
          center: true,
          showClose: true,
          customClass: 'orderShow-msgbox',
          message: message + pointsMessage,
          dangerouslyUseHTMLString: true,
          showCancelButton: true,
          closeOnClickModal: false,
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel')
        })
          .then(async () => {
            await this.reShipmentStore()
          })
          .catch(action => {
          })
      } else {
        this.$msgbox({
          title: '',
          center: true,
          showClose: true,
          customClass: 'orderShow-msgbox',
          message: `<h5>${this.$t('Order.Batchshipping.no_points')}</h5>`,
          dangerouslyUseHTMLString: true,
          showCancelButton: true,
          closeOnClickModal: false,
          confirmButtonText: this.$t('Order.Batchshipping.buy_points')
        })
          .then(async () => {
            this.handleBuyPoints()
          })
          .catch(action => {
          })
      }
    },
    async handleB2CReShipmentStore () {
      this.dialogShippingPointsVisible = false
      this.reShipmentStore()
    },
    async reShipmentStore () {
      this.setLoading(true)

      try {
        const apiShipping = new ApiShipping()
        const { data: response } = await apiShipping.reAddOrder(this.order.shippingId)
        await this.fetchOrderDetail(this.fetchOrderData)

        if (response.success) {
          this.$message.success(this.$t('Order.Show.success_update_code'))
        } else {
          this.$message.error(response.message)
        }
      } catch (e) {
        await this.fetchOrderDetail(this.fetchOrderData)

        const title = `<h5>${this.$t('Order.Batchshipping.generate_fail')}</h5>`
        const message = `<div class="text-gray-800">${this.$t('message.problem_contact')}</div>`
        let errorMessage = ''
        if (e.data.error && e.data.error.code) {
          errorMessage = `${this.$t('shipping.error_code')} ${e.data.error.code}`
        } else {
          errorMessage = `${e.data.message}`
        }
        const error = `<div class="text-gray-700 text-xs">${errorMessage}</div>`
        this.$msgbox({
          title: '',
          center: true,
          showClose: true,
          customClass: 'orderShow-msgbox',
          message: title + message + error,
          dangerouslyUseHTMLString: true,
          showCancelButton: false,
          closeOnClickModal: false,
          confirmButtonText: this.$t('button.confirm')
        }).catch(() => { })
      }

      this.setLoading(false)
    },
    async deliverOrder (deliverForm) {
      // 訂單出貨 - 貨運
      this.toggleBtnLoading('shipmentCreate')
      const apiShipping = new ApiShipping()
      const { data: response } = await apiShipping.create(this.order.shippingId, deliverForm)
      if (response.success) {
        this.dialogShipmentFreightVisible = false
        await this.fetchOrderDetail(this.fetchOrderData)
      } else {
        this.$message.error(response.message)
      }

      this.toggleBtnLoading('shipmentCreate')
    },
    // 自取出貨 (訂單完成)
    async deliverPickupOrder (pickupData) {
      this.toggleBtnLoading('shipmentCreate')
      const apiShipping = new ApiShipping()
      const { data: response } = await apiShipping.create(this.order.shippingId, pickupData)
      if (response.success) {
        this.dialogShipmentStorePickupVisible = false
        this.fetchOrderDetail(this.fetchOrderData)
      } else {
        this.$message.error(response.message)
      }
      this.toggleBtnLoading('shipmentCreate')
    },
    async deliverStoreOrder (storeData) {
      this.dialogShipmentStoreInvoiceVisible = false
      this.shipmentCreate(storeData)
    },
    async printOrder (mode) {
      if (this.shipping.type === 'seven' && mode === 'shipping') {
        const path = queryString.stringifyUrl({
          url: `/web/stores/${this.storeName}/print/seven`,
          query: {
            order_slugs: [this.order.slug]
          }
        }, {
          arrayFormat: 'bracket'
        })
        window.open(path, '_blank')
        return
      }
      this.setLoading(true)
      const printData = { order_slugs: [this.order.slug], print_mode: mode }
      this.printMode = mode
      const apiPrint = new ApiPrint()
      const data = await apiPrint.batchOrder(printData)
      if (!data) {
        this.setLoading(false)
        return
      }
      this.pendingCount = data.pending_count
      this.printSlugs = [this.order.slug]
      this.success = 0
      this.fail = 0
      if (this.pendingCount === 0) {
        this.printComplete()
      }
    },
    async printComplete () {
      this.setLoading(false)
      if (this.printSlugs.length === 0) {
        this.$message.error(this.$t('Order.BatchShipping.message_print_failed'))
        return
      }
      const orders = JSON.stringify([this.order.slug])
      localStorage.setItem('ordersToPrint', orders)

      const path = `/${this.storeName}/order/print?mode=${this.printMode}`
      window.open(path, '_blank')
    },
    async completeOrder () {
      const apiOrder = new ApiOrder()
      const success = await apiOrder.complete(this.orderId)

      if (success) {
        this.dialogRejectApplyVisible = false
        this.fetchOrderDetail(this.fetchOrderData)
      } else {
        this.$message.error(this.$t('Order.Show.error_update_order'))
      }
    },
    async returnOrder ({ reason, hasCompensation }) {
      const apiOrder = new ApiOrder()
      const success = await apiOrder.returned(this.orderId, {
        reason,
        has_compensation: hasCompensation
      })

      if (success) {
        this.dialogApproveApplyVisible = false
        this.fetchOrderDetail(this.fetchOrderData)
      } else {
        this.$message.error(this.$t('Order.Show.error_update_order'))
      }
    },
    toggleBtnLoading (name) {
      const idx = this.btnLoading.findIndex(btn => btn === name)

      if (idx !== -1) {
        this.btnLoading.splice(idx, 1)
      } else {
        this.btnLoading.push(name)
      }
    },

    handleUpdateOrder (items) {
      this.toggleDialogUpdateOrder(false)

      const lastPaymentStatus = _.get(this.orderData, 'payment_status', '')

      this.updateOrder({
        isCart: this.isCart,
        orderId: this.orderId,
        items
      })
        .then(async (response) => {
          // 若是更新數量為 0 ，代表清空購物車，所以就不留車了。
          if (items.length > 0) {
            await this.fetchOrderDetail(this.fetchOrderData)

            this.$message({ message: this.$t('Order.Show.message_update'), type: 'success' })

            // 當原本最後付款紀錄是 PAID，可能應編輯商品後，訂單狀態變 UNPAID，需提醒
            const currentOrderStatus = _.get(this.orderData, 'status', '')
            if (lastPaymentStatus === PAYMENT_STATUS.PAID && currentOrderStatus === ORDER_STATUS.UNPAID) {
              this.dialogChangePaymentNoticeVisible = true
            }
          } else {
            const path = `/${this.storeName}/order`
            this.$router.push({ path })
            this.$message({ message: this.$t('Order.Show.message_delete'), type: 'error' })
          }
        })
    },
    toggleDialog (target) {
      this[target] = !this[target]
    },
    handlePaymentReceived () {
      this.$msgbox({
        title: this.$t('Order.message.payment_received_title'),
        center: true,
        showClose: true,
        customClass: 'orderShow-msgbox',
        message: this.$t('Order.message.payment_received_content'),
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel')
      })
        .then(() => this.paymentReceived())
        .catch(() => { })
    },
    async paymentReceived () {
      this.setLoading(true)
      const apiOrder = new ApiOrder()
      await apiOrder.pay(this.order.id)
      await this.fetchOrderDetail(this.fetchOrderData)

      this.setLoading(false)
    },
    handleOrderComplete () {
      this.$msgbox({
        title: this.$t('Order.message.order_complete_title'),
        center: true,
        showClose: true,
        customClass: 'orderShow-msgbox',
        message: `<div>${this.$t('Order.message.order_complete_content1')}</div>
        <div>${this.$t('Order.message.order_complete_content2')}</div>`,
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel')
      })
        .then(() => this.orderComplete())
        .catch(() => { })
    },
    async orderComplete () {
      this.setLoading(true)
      const apiOrder = new ApiOrder()
      await apiOrder.complete(this.order.id)
      await this.fetchOrderDetail(this.fetchOrderData)

      this.setLoading(false)
    },
    handleEditAddress () {
      this.dialogEditAddressVisible = true
    },
    async handleEditAddressSubmit (addressForm) {
      this.setLoading(true)
      const apiShipping = new ApiShipping()
      const res = await apiShipping.update(this.order.shippingId, addressForm)
      this.setLoading(false)
      if (!res) return

      await this.fetchOrderDetail(this.fetchOrderData)

      this.$message.success(this.$t('message.save_success'))
      this.dialogEditAddressVisible = false
    },
    async handleFetchOrder () {
      this.setLoading(true)
      await this.fetchOrderDetail(this.fetchOrderData)

      this.setLoading(false)
    },
    async handleBankReceipt (payload) {
      this.setLoading(true)

      this.updateBankReceipt({
        storeName: this.storeName,
        orderId: this.order.id,
        payload
      })
        .then(() => {
          this.fetchOrderDetail(this.fetchOrderData)
        })
        .catch(() => {
          this.$message.error(this.$t('message.save_fail'))
        })
        .finally(() => {
          this.setLoading(false)
          this.dialogOfflineAtmVisible = false
        })
    },

    handleCompleteManually (payload) {
      // 手動完成訂單

      this.setOrderComplete({
        orderId: this.order.id,
        postData: payload
      })
        .then(() => {
          this.fetchOrderDetail(this.fetchOrderData)
        })
        .finally(() => {
          this.dialogCompleteOrderManuallyVisible = false
        })
    },

    handleIgnoreOverpaymentTip () {
      let ignoreList = this.ignoreOverpayment

      ignoreList.push(this.orderData.id)
      ignoreList = [...new Set(ignoreList)]

      this.ignoreOverpayment = ignoreList
      localStorage.setItem('ignoreOverpayment', JSON.stringify(ignoreList))
    },

    async handleSplitOrder (data) {
      const lastPaymentStatus = _.get(this.orderData, 'payment_status', '')
      const apiOrder = new ApiOrder()
      try {
        const { data: { id: newId } } = await apiOrder.split(this.orderId, data)
        const orderIds = [this.orderId, newId.toString()]
        const path = `/${this.storeName}/order/split?orders=${JSON.stringify(orderIds)}`
        this.$router.push({ path })
        this.dialogSplitOrderVisible = false
        this.fetchOrderDetail(this.fetchOrderData)
        this.$message.success(this.$t('Order.DialogSplitOrder.split_success'))
        const currentOrderStatus = _.get(this.orderData, 'status', '')
        if (lastPaymentStatus === PAYMENT_STATUS.PAID && currentOrderStatus === ORDER_STATUS.UNPAID) {
          this.dialogChangePaymentNoticeVisible = true
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
<style lang="scss">
.orderShow-msgbox {
  padding-bottom: 30px;
  h5 {
    margin-bottom: 20px;
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 500;
  }
  .el-message-box__content {
    padding-top: 0;
    padding-bottom: 15px;
  }
  .el-message-box__header {
    padding-bottom: 0;
  }
  .el-button--small {
    width: 165px;
    padding: 10px 0;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
  }
}

</style>
