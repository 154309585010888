<script>
import VueTypes from 'vue-types'
import { mapGetters, mapActions } from 'vuex'

import ApiPost from '@/api/post'
const apiPost = new ApiPost()

export default {
  name: 'DialogPrivateSetting',
  props: {
    post: VueTypes.object
  },

  data () {
    return {
      isSubmitting: false,
      form: {
        isPrivate: false,
        ticket_id: null
      }
    }
  },

  computed: {
    ...mapGetters('Me', ['storeName']),
    ...mapGetters('PostCreate', ['ticketList', 'isTicketListLoading'])
  },

  created () {
    if (this.post) {
      this.form = {
        isPrivate: this.post.is_private,
        ticket_id: this.post.ticket_id
      }
    }

    this.getTicketList({ storeId: this.storeName })
  },

  methods: {
    ...mapActions('PostCreate', ['getTicketList']),

    handleClose () {
      this.$emit('close')
      this.$emit('update:dialogVisible', false)
    },

    async handleSubmit () {
      this.isSubmitting = true

      try {
        const success = await apiPost.updatePost(this.post.id, {
          ticket_id: this.form.ticket_id
        })

        if (success) {
          this.$emit('reFetch')
          this.handleClose()
        }
      } catch (error) {
        // console.error(error)
      }
      this.isSubmitting = false
    }
  }
}
</script>

<template lang="pug" src="./DialogPrivateSetting.pug"></template>
