<script>
import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import { mapActions, mapState } from 'vuex'
import SubForm from '../SubForm'

export default {
  name: 'FormFamily',
  components: {
    SubForm
  },
  data () {
    return {
      SHIPPING_TYPES
    }
  },
  computed: {
    ...mapState('OrderManual/ShippingAddress', ['storeAddress', 'storeName', 'storeId', 'storeType'])
  },
  methods: {
    ...mapActions('OrderManual/ShippingAddress', ['cacheOrderData']),
    toStoreUrl () {
      // 更新全家門市
      this.cacheOrderData()

      const cvsname = location.hostname
      const redirect = location.origin + location.pathname

      location.href = `https://mfme.map.com.tw/default.aspx?cvsname=${cvsname}&cvsid=1&cvstemp=${redirect}&exchange=true`
    }
  }

}
</script>

<template lang="pug" src="./template.pug"></template>
