import dateFormat from 'date-fns/format'
import store from '@/store'
// import differenceInMinutes from 'date-fns/difference_in_minutes'
// import differenceInHours from 'date-fns/difference_in_hours'
// import differenceInDays from 'date-fns/difference_in_days'

/**
 * 10000 => "10,000"
 * @param {number} num
 */

export function currency (num) {
  const number = Number(num)
  if (isNaN(number)) return ''
  const n = +parseFloat(number.toFixed(2))
  const currentStore = store.getters['Me/currentStore']

  if (currentStore && currentStore.region !== 'TW') {
    return Intl.NumberFormat(currentStore.locale.split('-')[0]).format(n)
  }

  return `${n.toString().replace(/\d+/, function (n) {
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
      return $1 + ','
    })
  })}`
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter (num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * 過濾特殊符號
 * @param {string} str
 */
export function filterSymbolStr (str) {
  const pattern = new RegExp(
    "[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？%+_]"
  )

  let specialStr = ''
  for (let i = 0; i < str.length; i++) {
    specialStr += str.substr(i, 1).replace(pattern, '')
  }
  return specialStr
}

/**
 * 過濾空格
 * @param {string} str
 */
export function filterSpaceStr (str) {
  return str.replace(/\s/g, '')
}

export function formatDate (date, fmt = 'YYYY/MM/DD HH:mm:ss') {
  try {
    return dateFormat(date, fmt)
  } catch (e) {
    return date
  }
}

// export function relativeTime (date) {
//   const now = new Date()

//   if (differenceInMinutes(now, date) < 60) {
//     return `${differenceInMinutes(now, date)}分鐘前`
//   } else if (differenceInHours(now, date) < 24) {
//     return `${differenceInHours(now, date)}小時前`
//   } else if (differenceInDays(now, date) < 3) {
//     return `${differenceInDays(now, date)}天前`
//   } else {
//     return dateFormat(date, 'M/D日')
//   }
// }
