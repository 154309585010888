<script>
import Cookies from 'js-cookie'
import dateFormat from 'date-fns/format'
import ApiBook from '@/api/paymentToStoreBook'
import Pagination from '@/components/Pagination'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TransactionPendingTable',
  components: { Pagination },
  props: {
    showTooltip: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiBook: new ApiBook(),
      dateRange: null,
      searchRule: {
        from: null,
        to: null,
        keyword: null
      },
      totalPendingAmount: null,
      paginator: null,
      rows: null,
      isAppIntroduceVisible: false
    }
  },

  computed: {
    ...mapGetters('Me', ['currencySymbol', 'currentStore']),
    infoTip4 () {
      const tips = {
        TW: this.$t('Setting.TransactionPendingTable.info_tip4'),
        TH: this.$t('Setting.TransactionPendingTable.info_tip4_th')
      }

      return tips[this.currentStore.region]
    }
  },
  async created () {
    await this.fetchBook()
    this.showTip()
  },
  methods: {
    ...mapActions(['setLoading']),
    showTip () {
      const currentToken = Cookies.get('api_token')
      const lastToken = localStorage.getItem('lastTokenPending')

      if (currentToken === lastToken) {
        this.isAppIntroduceVisible = false
      } else {
        this.isAppIntroduceVisible = true
      }
    },
    closeTip () {
      this.isAppIntroduceVisible = false
      const currentToken = Cookies.get('api_token')
      localStorage.setItem('lastTokenPending', currentToken)
    },
    async handleDateChange (dateRange) {
      this.searchRule.from = dateRange ? dateFormat(dateRange[0], 'YYYY/MM/DD') : null
      this.searchRule.to = dateRange ? dateFormat(dateRange[1], 'YYYY/MM/DD') : null
      await this.fetchBook()
    },
    async fetchBook () {
      const params = Object.assign({}, this.searchRule)
      const data = await this.apiBook.list(params)

      this.rows = data
      this.paginator = this.apiBook.paginator
      this.totalPendingAmount = this.apiBook.paginator.data().meta.total_amount || 0
    },
    updateData (data) {
      this.rows = data
      // 跟上面重複 待優化
      this.totalPendingAmount = this.apiBook.paginator.data().meta.total_amount || 0
    },
    goOrder (id) {
      const path = `/${this.$route.params.store}/order/${id}`
      this.$router.push({ path })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
