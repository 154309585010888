<template>
  <div>
    <p class="mt-2 mb-2">
      {{ title || $t('Post.PushNotification.post_cover') }}
    </p>
    <div
      v-loading="isImageUploading"
      class="flex items-end"
    >
      <el-upload
        name="image"
        class="upload"
        :accept="acceptedImageType"
        :action="uploadPath"
        :headers="uploadHeaders"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :on-success="handleImageUploadResult"
        :on-error="handleImageUploadResult"
      >
        <i
          v-if="!imageUrl"
          class="uploader-icon far fa-plus"
        />
        <template v-else>
          <i
            class="el-icon-circle-close delete-video-button"
            @click.stop="handleDeleteImage"
          />
          <img
            class="h-full w-full object-cover align-baseline"
            :src="imageUrl"
          >
        </template>
      </el-upload>
      <div class="flex-1 ml-2 leading-normal text-gray-800 text-xs">
        {{ $t('Post.PushNotification.post_cover_rule1') }}
        <br>
        {{ $t('Post.PushNotification.post_cover_rule2') }}
        <br>
        {{ $t('Post.PushNotification.post_cover_rule3') }}
      </div>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import ApiImage from '@/api/image'
import {
  getUploadHeaders,
  getImageUploadPath
} from '@/utils/getVideoUploadInfo'

export default {
  name: 'ImageUploader',
  props: {
    title: VueTypes.string.def(''),
    acceptedImageType: VueTypes.string.def('image/*'),
    sizeLimit: VueTypes.number.def(2),
    defaultImage: VueTypes.string.def(''),
    defaultImageId: VueTypes.number.def(null),
    isImageUploading: VueTypes.bool.def(false)
  },
  data () {
    return {
      imageUrl: this.defaultImage,
      imageId: this.defaultImageId,
      uploadHeaders: getUploadHeaders(),
      uploadPath: getImageUploadPath()
    }
  },
  methods: {
    beforeUpload (file) {
      if (!this.uploadPath) {
        return false
      }
      const isSizeValid = file.size / 1024 / 1024 <= this.sizeLimit
      if (!isSizeValid) {
        this.$message.error(
          this.$t('Components.ImageUploader.error.size', { size: this.sizeLimit })
        )
        return false
      }
      this.$emit('update:isImageUploading', true)
      return true
    },
    async handleDeleteImage () {
      this.$emit('update:isImageUploading', true)
      const apiImage = new ApiImage()
      await apiImage.delete(this.imageId)
      this.$emit('updateImage', {
        url: null,
        id: null
      })
      this.imageUrl = null
      this.imageId = null
      this.$emit('update:isImageUploading', false)
    },
    handleImageUploadResult (response) {
      if (response && response.id) {
        this.imageId = response.id
        this.imageUrl = response.url
        this.$emit('updateImage', response)
      } else {
        this.imageUrl = null
        this.imageId = null
      }
      this.$emit('update:isImageUploading', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.upload {
  border: 1px dashed $border-dusty-gray;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 110px;
  background: $gray-100;

  ::v-deep .el-upload--text {
    width: 100%;
    height: 100%;
  }
}
.uploader-icon {
  font-size: 30px;
  display: inline-block;
  text-align: center;
  width: 100%;
  color: $primary;
  margin: auto;
}
.delete-video-button {
  position: absolute;
  font-size: 20px;
  top: 5px;
  right: 5px;
  z-index: 2;
  color: $primary;
  cursor: pointer;
}
.preview-video {
  width: 100%;
  height: 100%;
}
</style>
