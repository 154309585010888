<script>
import VueTypes from 'vue-types'

export default {
  name: 'DialogConfirmDelete',

  props: {
    products: VueTypes.array.def([])
  },

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    selectQuantity () {
      return this.products.length
    },
    lockedProducts () {
      return this.products.filter(product => product.lock_quantity > 0)
    },
    lockedProductNames () {
      return this.lockedProducts.map(product => product.name)
    }
  },

  methods: {
    handleDelete () {
      this.isLoading = true
      this.$emit('handleDeleteSelected')
    }
  }

}
</script>

<template lang="pug" src="./DialogConfirmDelete.pug"></template>
<style lang="scss" src="./DialogConfirmDelete.scss" scoped></style>
