<script>
import { mapGetters } from 'vuex'
import Step1ImgTw from '@/assets/img-intro-app-1-tw.svg'
import Step2ImgTw from '@/assets/img-intro-app-2-tw.svg'
import Step3ImgTw from '@/assets/img-intro-app-3-tw.svg'
import Step1ImgJp from '@/assets/img-intro-app-1-jp.svg'
import Step2ImgJp from '@/assets/img-intro-app-2-jp.svg'
import Step3ImgJp from '@/assets/img-intro-app-3-jp.svg'
import * as REGIONS from '@/constants/regions'

export default {
  name: 'AppDownloadDialog',
  computed: {
    ...mapGetters('Me', ['region']),
    iosDownloadUrl () {
      return process.env.VUE_APP_IOS_DOWNLOAD_URL
    },
    androidDownloadUrl () {
      return process.env.VUE_APP_ANDROID_DOWNLOAD_URL
    },
    appQRCode () {
      return require(`@/assets/qrcodes/${process.env.VUE_APP_ONE_QRCODE}`)
    },
    images () {
      return {
        [REGIONS.TW]: {
          1: Step1ImgTw,
          2: Step2ImgTw,
          3: Step3ImgTw
        },
        [REGIONS.JP]: {
          1: Step1ImgJp,
          2: Step2ImgJp,
          3: Step3ImgJp
        }
      }
    }
  },
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./AppDownloadDialog.pug"></template>
<style lang="scss" src="./AppDownloadDialog.scss" scoped></style>
