export default [{
  label: '北海道地方',
  children: [{
    value: 'JP-01',
    label: '北海道'
  }]
}, {
  label: '東北地方',
  children: [{
    value: 'JP-02',
    label: '青森県'
  }, {
    value: 'JP-03',
    label: '岩手県'
  }, {
    value: 'JP-04',
    label: '宮城県'
  }, {
    value: 'JP-05',
    label: '秋田県'
  }, {
    value: 'JP-06',
    label: '山形県'
  }, {
    value: 'JP-07',
    label: '福島県'
  }]
}, {
  label: '関東地方',
  children: [{
    value: 'JP-08',
    label: '茨城県'
  }, {
    value: 'JP-09',
    label: '栃木県'
  }, {
    value: 'JP-10',
    label: '群馬県'
  }, {
    value: 'JP-11',
    label: '埼玉県'
  }, {
    value: 'JP-12',
    label: '千葉県'
  }, {
    value: 'JP-13',
    label: '東京都'
  }, {
    value: 'JP-14',
    label: '神奈川県'
  }]
}, {
  label: '中部地方',
  children: [{
    value: 'JP-15',
    label: '新潟県'
  }, {
    value: 'JP-16',
    label: '富山県'
  }, {
    value: 'JP-17',
    label: '石川県'
  }, {
    value: 'JP-18',
    label: '福井県'
  }, {
    value: 'JP-19',
    label: '山梨県'
  }, {
    value: 'JP-20',
    label: '長野県'
  }, {
    value: 'JP-21',
    label: '岐阜県'
  }, {
    value: 'JP-22',
    label: '静岡県'
  }, {
    value: 'JP-23',
    label: '愛知県'
  }]
}, {
  label: '近畿地方',
  children: [{
    value: 'JP-24',
    label: '三重県'
  }, {
    value: 'JP-25',
    label: '滋賀県'
  }, {
    value: 'JP-26',
    label: '京都府'
  }, {
    value: 'JP-27',
    label: '大阪府'
  }, {
    value: 'JP-28',
    label: '兵庫県'
  }, {
    value: 'JP-29',
    label: '奈良県'
  }, {
    value: 'JP-30',
    label: '和歌山県'
  }]
}, {
  label: '中国地方',
  children: [{
    value: 'JP-31',
    label: '鳥取県'
  }, {
    value: 'JP-32',
    label: '島根県'
  }, {
    value: 'JP-33',
    label: '岡山県'
  }, {
    value: 'JP-34',
    label: '広島県'
  }, {
    value: 'JP-35',
    label: '山口県'
  }]
}, {
  label: '四国地方',
  children: [{
    value: 'JP-36',
    label: '徳島県'
  }, {
    value: 'JP-37',
    label: '香川県'
  }, {
    value: 'JP-38',
    label: '愛媛県'
  }, {
    value: 'JP-39',
    label: '高知県'
  }]
}, {
  label: '九州・沖縄地方',
  children: [{
    value: 'JP-40',
    label: '福岡県'
  }, {
    value: 'JP-41',
    label: '佐賀県'
  }, {
    value: 'JP-42',
    label: '長崎県'
  }, {
    value: 'JP-43',
    label: '熊本県'
  }, {
    value: 'JP-44',
    label: '大分県'
  }, {
    value: 'JP-45',
    label: '宮崎県'
  }, {
    value: 'JP-46',
    label: '鹿児島県'
  }, {
    value: 'JP-47',
    label: '沖縄県'
  }]
}]
