<script>
import ApiPrint from '@/api/print'

export default {
  name: 'HsinchuPrint',
  components: {},
  data () {
    return {
      apiPrint: new ApiPrint(),
      orders: [],
      customerCode: null,
      printAt: null
    }
  },
  async created () {
    const slugs = JSON.parse(this.$route.query.slugs)
    const postData = {
      order_slugs: slugs
    }
    const apiData = await this.apiPrint.printHsinchu(postData)
    this.orders = apiData.data || []
    this.customerCode = apiData.customer_code
    this.printAt = apiData.print_at
    this.$nextTick(() => {
      window.print()
    })
  }
}
</script>

<template lang='pug' src='./template.pug'></template>
<style lang='scss' src='./style.scss' scoped></style>
