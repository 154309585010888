<script>
import { mapGetters } from 'vuex'
import store from '@/store'
import Metrics from './components/Metrics'
import StoreInfo from './components/StoreInfo'
import UpcomingPosts from './components/UpcomingPosts'
import Announcements from './components/Announcements'
import SalesChart from './components/SalesChart'
import SalesTable from './components/SalesTable'

export default {
  name: 'Dashboard',
  components: {
    Metrics,
    StoreInfo,
    UpcomingPosts,
    Announcements,
    SalesChart,
    SalesTable
  },

  middleware (to, from, next) {
    // 只有店長或是母店店長可以看
    if (!store.getters['Me/isCurrentStoreOwner'] && !store.getters['Me/isChildStoreParent']) {
      next({ name: 'Home', params: { store: store.getters['Me/currentStore'].slug } })
    } else {
      next()
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore', 'isChildStore'])
  }
}
</script>

<template lang="pug" src="./Dashboard.pug"></template>
<style lang="scss" scoped>
.right-col {
  flex-basis: 330px;
}
</style>
