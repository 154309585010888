<script>
import VueTypes from 'vue-types'
import { mapGetters, mapActions } from 'vuex'

import ApiOrder from '@/api/order'
import addressNormalizer from '@/utils/addressNormalizer'
import getSocialIcon from '@/utils/getSocialIcon'

import * as REGIONS from '@/constants/regions'
import * as ORDER_STATUS from '@/constants/orderStatus'
import { INIT } from '@/constants/shippingStatus'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import * as PAYMENT_STATUS from '@/constants/paymentStatus'
import * as PAYMENT_TYPES from '@/constants/paymentTypes'
import * as PAYMENT_FLOW from '@/constants/paymentFlow'

import GmoDeferredStatus from '@/views/Order/components/GmoDeferredStatus'

import DialogEditShipping from '../../components/DialogEditShipping'

import CvsStatus from './components/CvsStatus'
import GmoCvsStatus from './components/GmoCvsStatus'

const ORDER_COMPLETE_TYPE = {
  AUTOMATIC: 'automatic',
  MANUAL: 'manual'
}

export default {
  name: 'OrderInfo',

  components: {
    CvsStatus,
    GmoCvsStatus,
    DialogEditShipping,
    GmoDeferredStatus
  },

  props: {
    company: VueTypes.string.def(''),
    order: VueTypes.object.isRequired,
    orderData: VueTypes.object.isRequired,
    handleReShipmentStore: VueTypes.func.isRequired
  },

  data () {
    return {
      INIT,
      ...SHIPPING_TYPES,
      ...PAYMENT_TYPES,
      PAYMENT_STATUS,

      refundMemo: this.order.refundMemo || 'unrefunded',
      refundMemoOptions: [
        { value: 'unrefunded', label: 'payment.refund_pending' },
        { value: 'refunded', label: 'payment.refund_complete' }
      ]

    }
  },

  computed: {
    ...mapGetters('Me', ['currencySymbol', 'currentStore', 'storeName', 'region', 'paymentFlow', 'apiUrl']),
    ...mapGetters('OrderDetail', ['isSubscriptionType']),
    ...mapGetters('OrderDetail', ['dialogEditShippingVisible']),
    ...mapGetters('OrderDetail', ['enabledShippings']),
    seven () {
      return {
        action: 'https://emap.presco.com.tw/c2cemap.ashx',
        callback: `${this.apiUrl || process.env.VUE_APP_DOMAIN_NAME}/callback/seven/map`,
        redirect: location.origin + location.pathname
      }
    },
    isCvsExpired () {
      if (!this.order) return false
      let today = new Date()
      let expiredDay = new Date(this.order.paymentExpireAt)
      return today > expiredDay
    },

    paymentUnpaid () {
      const paidStatus = [PAYMENT_STATUS.PAID, PAYMENT_STATUS.REFUNDED]
      // 未付款時的狀態可能會是 init、unpaid、pending
      return !(paidStatus.includes(this.order.paymentStatus))
    },
    showRefundMemo () {
      return this.order.status === ORDER_STATUS.CANCELED && this.order.paymentStatus === PAYMENT_STATUS.PAID
    },
    showEditAddress () {
      // 代收代付 - 編輯運送方式
      // 備註：日本只有 Custom，所以延用原先 代收代付 的 UI 介面

      if (this.paymentFlow === PAYMENT_FLOW.DIRECT && this.region === REGIONS.TW) {
        // 銀行轉帳待審核時，不可改運送方式，但可以改地址
        return this.order.shippingStatus === INIT &&
          this.order.paymentStatus === PAYMENT_STATUS.APPROVING
      } else {
        return this.order.shippingStatus === INIT && !this.order.shippingSourceId
      }
    },
    showEditShipping () {
      // 非代收代付 - 編輯運送方式
      if (this.paymentFlow === PAYMENT_FLOW.DIRECT && this.region === REGIONS.TW) {
        return this.order.shippingStatus === INIT
      } else {
        return false
      }
    },
    isSelfPickup () {
      const types = [
        SHIPPING_TYPES.SELF_PICK_UP,
        SHIPPING_TYPES.CUSTOM_SELF_PICK_UP
      ]
      return types.includes(this.order.shippingType)
    },
    isCompleteOrderManual () {
      // 是否為 手動完成訂單
      const orderCompleteType = _.get(this.orderData, 'complete_type', '')

      return orderCompleteType === ORDER_COMPLETE_TYPE.MANUAL
    }
  },

  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('OrderDetail', ['updateOrderShipping', 'openDialogEditShipping', 'updateDialogEditShipping']),
    ...mapActions('OrderDetail', ['fetchShippingInfo']),
    getSocialIcon,
    toMemberPage () {
      const userSlug = _.get(this.orderData, 'buyer.slug', null)

      if (userSlug) {
        this.$router.push({ path: `/${this.storeName}/member/${userSlug}` })
      }
    },

    calAddress (address) {
      return addressNormalizer(address, this.currentStore.region)
    },
    toStoreUrl () {
      // 更新全家門市
      const cvsname = location.hostname
      const redirect = location.origin + location.pathname

      location.href = `https://mfme.map.com.tw/default.aspx?cvsname=${cvsname}&cvsid=1&cvstemp=${redirect}&exchange=true`
    },
    handleRefundMemochange () {
      const apiOrder = new ApiOrder()
      apiOrder.writeRefundMemo(this.order.id, { refund_memo: this.refundMemo })
    },
    editAddress () {
      this.$emit('editAddress')
    },
    async regenerateCvsCode () {
      this.setLoading(true)
      try {
        const apiOrder = new ApiOrder()
        await apiOrder.regenerateCvs(this.order.id)
        this.$emit('fetchOrder')
        this.$message.success(this.$t('Order.Show.success_generate_code'))
      } catch (error) {
        console.error(error)
        this.setLoading(false)
      }
    },

    async handleUpdateShipping (payload) {
      this.updateOrderShipping({
        id: this.orderData.id,
        putData: payload
      })
        .then(() => {
          this.$emit('fetchOrder')
          this.updateDialogEditShipping(false)
        })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
