import AtmInfo from './AtmInfo'
import BillList from './BillList'
import PaymentDetail from './PaymentDetail'
import PaymentList from './PaymentList'
import PaymentBill from './PaymentBill'

export default {
  namespaced: true,
  modules: {
    AtmInfo,
    BillList,
    PaymentDetail,
    PaymentList,
    PaymentBill
  }
}
