<script>
export default {
  name: 'DialogOrderDetail',
  props: {
    order: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  computed: {
    total () {
      return this.order.reduce((prev, item) => {
        return prev + (item.price * item.quantity)
      }, 0)
    }
  },
  methods: {
    getChannelTypeImg (item) {
      return item.channel_type === 'handsup'
        ? require('@/assets/icon-handsup.png')
        : require('@/assets/icon-fb.png')
    },
    handleClose () {
      this.$emit('close')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
