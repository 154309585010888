<script>
import VueTypes from 'vue-types'

export default {
  name: 'DialogAtmInfo',

  props: {
    currencySymbol: VueTypes.string.def('$'),
    atmInfo: VueTypes.object.isRequired
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<template lang="pug" src="./DialogAtmInfo.pug"></template>
