<script>
import Cookies from 'js-cookie'
import dateFormat from 'date-fns/format'
import { mapGetters, mapActions } from 'vuex'

import * as PAYMENT_FLOW from '@/constants/paymentFlow'
import * as REGIONS from '@/constants/regions'
import * as PLANS from '@/constants/plans'
import * as GMO_STATUS from '@/constants/gmoStatus'
import * as ROLES from '@/constants/roles'

import Guide from '@/components/Guide'
import LineChat from '@/components/LineChat'
import EmailBubble from '@/components/EmailBubble'
import DialogCheckList from '@/components/DialogCheckList'

export default {
  metaInfo () {
    return {
      meta: [{
        name: 'viewport',
        content: 'width=1200'
      }]
    }
  },

  name: 'LayoutAdmin',
  components: { Guide, LineChat, DialogCheckList, EmailBubble },
  data () {
    return {
      ...REGIONS,
      dialogCheckListVisible: false,

      notificationCount: 99,
      activeNavItem: 'Home',
      navHome: { id: 'Home', name: this.$t('LayoutAdmin.navbar.home'), link: 'posts' },
      navMarket: { id: 'Market', name: this.$t('LayoutAdmin.navbar.market.short'), link: 'market' },
      navProduct: { id: 'Product', name: this.$t('LayoutAdmin.navbar.product.short'), link: 'product' },
      navTicket: { id: 'Ticket', name: this.$t('LayoutAdmin.navbar.ticket.short'), link: 'ticket' },
      navSubscription: { id: 'Subscription', name: this.$t('LayoutAdmin.navbar.subscription'), link: 'subscription' },
      navCoupon: { id: 'Coupon', name: this.$t('LayoutAdmin.navbar.coupon'), link: 'coupon' },
      navVoucher: { id: 'Voucher', name: this.$t('LayoutAdmin.navbar.voucher'), link: 'voucher' },
      source: null,
      planInfo: [],
      guideOpen: false,
      showTerms: false
    }
  },
  computed: {
    ...mapGetters('Lang', ['locale']),
    ...mapGetters('Me', [
      'me',
      'stores',
      'region',
      'gmoStatus',
      'storeName',
      'currentStore',
      'paymentFlow',
      'isEnterprise',
      'isEnterpriseGeneric',
      'isCurrentStoreOwner',
      'isChildStore',
      'isChildStoreParent',
      'canBuyPlan',
      'enabledCoupon',
      'enabledTicket',
      'enabledSubscription',
      'enabledMerchandise',
      'onboardingLeft',
      'isFBBuyStore'
    ]),
    ...mapGetters('SettingsChecked', ['isAllChecked', 'sellerChecked']),

    getOtherStore () {
      return this.stores.filter(item => item.id !== this.currentStore.id)
    },
    getPlanTime () {
      return dateFormat(this.currentStore.plan_expired_at, 'YYYY/MM/DD')
    },
    getPlanName () {
      // 非代收代付
      if (this.paymentFlow === PAYMENT_FLOW.DIRECT && this.region === REGIONS.TW) return ''

      const planName = {
        [PLANS.NOVICE]: this.$t('LayoutAdmin.plans.novice'),
        [PLANS.MASTER]: this.$t('LayoutAdmin.plans.master'),
        [PLANS.EXPERT]: this.$t('LayoutAdmin.plans.expert'),
        [PLANS.FREE_TRIAL]: this.$t('LayoutAdmin.plans.free_trial'),
        [PLANS.MERCHANDISE]: '物販のみ',
        [PLANS.TICKET]: '有料配信のみ',
        [PLANS.FULL]: '物販＋有料配信'
      }

      let returnPlanName = planName[this.currentStore.plan.name] || ''

      return returnPlanName
    },
    isPlanExpired () {
      return this.currentStore.plan_is_expired
    },

    calcNavItems () {
      if (this.isEnterpriseGeneric) {
        // 企業通用版
        return [
          { id: 'Home', name: this.$t('LayoutAdmin.navbar.home'), link: 'posts' },
          { id: 'Product', name: this.$t('LayoutAdmin.navbar.product.short'), link: 'product' },
          { id: 'Setting', name: this.$t('LayoutAdmin.navbar.setting'), link: 'setting' }
        ]
      }

      const items = [
        { id: 'OrderList', name: this.$t('LayoutAdmin.navbar.order_list'), link: 'order' },
        { id: 'MemberList', name: this.$t('LayoutAdmin.navbar.member_list'), link: 'member' },
        { id: 'Setting', name: this.$t('LayoutAdmin.navbar.setting'), link: 'setting' }
      ]

      return items
    },
    showGMOButton () {
      if (this.region !== REGIONS.JP || this.isChildStore) return false

      return this.gmoStatus && this.gmoStatus !== GMO_STATUS.SUCCESS
    },
    showInstructions () {
      const shownRegions = [REGIONS.TW, REGIONS.JP]

      return shownRegions.includes(this.currentStore.region)
    },
    showAccountManagement () {
      return this.paymentFlow === PAYMENT_FLOW.DIRECT && this.isCurrentStoreOwner && this.region === REGIONS.TW
    },
    disableNav () {
      // 台灣方案過期鎖定連結
      if (this.region === REGIONS.TW) {
        return this.isPlanExpired
      }

      return false
    },
    showPlan () {
      return !this.isEnterprise && this.getPlanName !== '' && !this.isChildStore
    }
  },
  created () {
    this.source = this.$route.query.source

    this.activeNavItem = this.$route.meta.navItem || this.$route.name

    // 子商店綁定成功
    if (sessionStorage.getItem('parentStoreBind')) {
      sessionStorage.removeItem('parentStoreBind')
      this.handleParentStoreBind()
    }
  },
  async mounted () {
    this.checkTermsVersion()

    await this.checkPaymentShipping()
    const guideRegions = ['tw', 'vn', 'th']
    if (!this.isEnterprise && !this.isAllChecked &&
      localStorage.getItem('hasShowGuide') === 'false' &&
      guideRegions.includes(this.currentStore.region.toLowerCase())) {
      this.toggleGuide(true)
    }
  },
  updated () {
    this.activeNavItem = this.$route.meta.navItem || this.$route.name
  },
  methods: {
    ...mapActions('SettingsChecked', ['checkPaymentShipping']),
    ...mapActions('Me', ['updateCurrentStore']),

    handleRouter (item) {
      if (this.isFBBuyStore) return
      // [Facebook Pixel] 追蹤點擊上方Tab事件
      this.$analytics.fbq.event('ClickReg', {
        Page: 'Merchant-Platform',
        Content: 'Nav',
        Target: item.name
      })
      this.$ga.event({
        eventCategory: 'Nav-Click',
        eventAction: item.name + '-Click',
        eventLabel: 'Merchant-Platform'
      })

      this.activeNavItem = item.id
      this.$router.push({ path: `/${this.storeName}/${item.link}` })
    },
    async changePath (path) {
      await this.updateCurrentStore(path)
      window.open('/' + path, '_blank')
    },
    getRoleName (pivot) {
      const role = pivot ? pivot.type : ROLES.CHILD_STORE

      if (role === ROLES.OWNER && this.isChildStoreParent) {
        return this.$t('child_store.parent')
      }
      const roleName = {
        [ROLES.OWNER]: this.$t('LayoutAdmin.role.owner'),
        [ROLES.ASSISTANT]: this.$t('LayoutAdmin.role.assistant'),
        [ROLES.CHILD_STORE]: this.$t('child_store.child')
      }

      return roleName[role] || this.$t('LayoutAdmin.role.owner')
    },
    handleLogout () {
      const domain = process.env.VUE_APP_DOMAIN
      // TODO：清 cookie 方式，待研究
      Cookies.remove('api_token')
      document.cookie = 'api_token=; path=/; domain=' + domain + '; expires=' + new Date(0).toUTCString()

      if (this.isEnterprise) {
        location.replace(`/e/${this.currentStore.enterprise.slug}`)
      } else {
        // 導向到買家前台
        location.href = this.$VUE_APP_BUYER_DOMAIN_NAME
      }
    },
    toggleGuide (show) {
      this.guideOpen = show
    },
    openSystemOperation () {
      this.$analytics.fbq.event('ClickReg', {
        Page: 'Merchant-Platform',
        Target: 'Instructions'
      })
      this.$ga.event({
        eventCategory: 'Bottom-Click',
        eventAction: 'Instructions-Click',
        eventLabel: 'Merchant-Platform'
      })
      const links = {
        [REGIONS.TW]: '/pdf/HandsUP_system _operation_instructions.pdf',
        [REGIONS.JP]: 'https://neo-c.gitbook.io/handsup/'
      }
      window.open(links[this.region], '_blank')
    },
    openGMOform () {
      this.$router.push({ name: 'GmoForm' })
    },
    goTerms () {
      this.$router.push(`/contract_seller`)
    },
    // 條款更新跳窗判斷
    checkTermsVersion () {
      const storageTerms = localStorage.getItem('terms_version')
      const region = _.get(this.currentStore, 'region', '')

      if (region !== 'TW') return

      const LAST_TERMS_VERSION = '20191205'

      if (!storageTerms || storageTerms < LAST_TERMS_VERSION) {
        this.showTerms = true
      }
    },
    handleHideTerms () {
      localStorage.setItem('terms_version', '20191205')
      this.showTerms = false
    },
    toAccountManagement () {
      window.open(`/${this.storeName}/account-management`, '_blank')
    },
    toUserPage () {
      this.$router.push({ path: `/${this.storeName}/user` })
    },
    handleParentStoreBind () {
      this.$msgbox({
        title: '',
        center: true,
        customClass: 'inviteParent-msgbox',
        message: `<h5>${this.$t('child_store.confirm_success1')}</h5>
          <h5>${this.$t('child_store.confirm_success2')}</h5>`,
        dangerouslyUseHTMLString: true,
        confirmButtonText: this.$t('button.confirm')
      })
        .then(() => {})
        .catch(() => {})
    },
    goOnboarding () {
      if (this.isFBBuyStore) return
      this.$router.push({ name: 'Onboarding' })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>

<style lang="scss">
.el-popover-home {
  .card-home {
    display: flex;
    flex-direction: column;

    .el-button {
      color: $dark;
    }

    .el-button:hover {
      color: $primary;
    }

    .el-button + .el-button {
      margin-left: 0px;
    }
  }
}

.el-popover-admin {
  width: 330px !important;
  padding: 0;

  .card-admin {
    .title {
      color: $vr-dark;
    }
    .info {
      color: $light;
    }
    .date-info {
      color: #03aaf4;
      font-size: 12px;
    }

    &-header {
      @include flex(flex-start, center);

      padding: 20px 20px;
      border-bottom: 1px solid $line-dark;

      .img-box {
        @include rwdImg(60px);

        margin-right: 10px;
        border: 1px solid $line-dark;
        border-radius: 50%;
        background: $line-vr-light;
      }
      p {
        margin-bottom: 0;
      }
    }
    &-body {
      max-height: 16rem;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      li {
        @include flex(flex-start, center);

        padding: 10px 20px;
        cursor: pointer;
        background-color: #fafafa;

        .img-box {
          @include rwdImg(40px);

          margin-right: 10px;
          border: 1px solid $line-dark;
          border-radius: 50%;
          background: $line-vr-light;
        }

        p {
          margin-bottom: 0;
        }

        &:hover {
          background-color: $bg-light;
        }

        + li {
          border-top: 1px solid $line-dark;
        }
      }
    }
    &-footer {
      @include flex(flex-end, center);

      padding: 10px 20px;
      border-top: 1px solid $line-dark;
    }
  }
}

</style>
