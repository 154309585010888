<script>
import { mapGetters } from 'vuex'

import AppAlert from '@/components/AppAlert'
import LineImg from '@/assets/line-add-friend-zh.png'
import VueTypes from 'vue-types'

export default {
  name: 'AccountDescription',
  components: { AppAlert },
  props: {
    isEnterprise: VueTypes.bool.def(false)
  },
  data () {
    return {
      LineImg
    }
  },
  computed: {
    ...mapGetters('Me', ['lineLink']),

    instructionsLink () {
      const { store = '' } = this.$route.params
      return `/${store}/account-management-instructions${this.isEnterprise ? '?enterprise=true' : ''}`
    }
  }
}
</script>

<template lang="pug" src="./AccountDescription.pug"></template>

<style lang="scss" scoped>
  .lineImg {
    height: 20px;
  }

</style>
