<script>
import { mapGetters, mapActions } from 'vuex'
import compose from 'lodash/fp/compose'
import curry from 'lodash/fp/curry'
import { stdev } from 'stats-lite'

import LatencyGraph from './components/LatencyGraph'
import {
  TICK_FREQUENCY_MS,
  SPECIMENT_LOADING_TIMEOUT_MS,
  NUM_OF_SPECIMENTS,
  ACCEPTED_STANDARD_DEVIATION
} from '../../constants'

const LOADING_STATES = {
  EMPTY: 'empty',
  LOADING: 'loading',
  ERROR: 'error',
  READY: 'ready'
}

const NETWORK_STATES = {
  NOT_TESTED: 'unchecked',
  UNSTABLE: 'unstable',
  STABLE: 'stable'
}

const NETWORK_STATUS_TEXT = {
  [NETWORK_STATES.NOT_TESTED]: '',
  [NETWORK_STATES.UNSTABLE]: 'Unstable Network',
  [NETWORK_STATES.STABLE]: 'Stable Network'
}

function measureLatency (url) {
  const startTime = Date.now()
  return new Promise((resolve, reject) => {
    let timer = null
    const img = document.createElement('img')
    img.onload = function () {
      clearTimeout(timer)
      const endTime = Date.now()
      resolve({
        latency: endTime - startTime
      })
    }

    img.onerror = img.onabort = function (err) {
      clearTimeout(timer)
      reject(new Error(`error loading speciment image ${err.message}`))
    }

    timer = setTimeout(() => {
      img.src = '//!!!/invalid.jpg'
      resolve({
        latency: Infinity
      })
    }, SPECIMENT_LOADING_TIMEOUT_MS)
    img.src = url
  })
}

export default {
  name: 'DetecorPanel',

  components: {
    LatencyGraph
  },

  data () {
    return {
      // Collection of speciments collected
      speciments: [],

      // Number of speciments to collect
      numOfSpeciments: Array(NUM_OF_SPECIMENTS).fill(0).map((_, i) => i + 1),

      // Indicates the loading status
      loading: LOADING_STATES.EMPTY,

      // Network status indicator
      networkStatus: NETWORK_STATES.NOT_TESTED,

      // Text of net work status to display
      networkStatusText: NETWORK_STATUS_TEXT[NETWORK_STATES.NOT_TESTED],

      // Error message for loading speciment image, if any.
      errorMsg: '',

      // Interval ID
      interval: null
    }
  },

  computed: {
    ...mapGetters('SettingsChecked', [
      'hasPaymentVerified',
      'hasShipmentVerified',
      'sellerChecked'
    ])
  },

  created () {
    this.checkPaymentShipping()
  },

  mounted () {
    const { VUE_APP_NETWORK_SPECIMENT_URL } = process.env

    const getRandomString = () => (+new Date()).toString(10).slice(-5)
    const tickCriteria = curry((domain, str) => `${domain}?${str}`)(VUE_APP_NETWORK_SPECIMENT_URL)
    const getTickCriteria = compose(tickCriteria, getRandomString)

    function startAssessing () {
      return new Promise((resolve, reject) => {
        this.interval = setInterval(
          function tickLatency () {
            if (this.speciments.length >= NUM_OF_SPECIMENTS) {
              resolve(this.speciments)
              clearInterval(this.interval)
            } else {
              const url = getTickCriteria()

              measureLatency(url)
                .then(res => this.speciments.push(res.latency))
                .catch(err => {
                  reject(err)
                  clearInterval(this.interval)
                })
            }
          }.bind(this), TICK_FREQUENCY_MS)
      })
    }

    this.toggleLoadingState(LOADING_STATES.LOADING)

    // start assessing network latency
    startAssessing.call(this)
      .then(() => {
        const sd = stdev(this.speciments)

        const networkStatus = sd > ACCEPTED_STANDARD_DEVIATION
          ? NETWORK_STATES.UNSTABLE
          : NETWORK_STATES.STABLE

        this.toggleNetworkStatus(networkStatus)
        this.toggleLoadingState(LOADING_STATES.READY)
      })
      .catch(err => {
        this.errorMsg = err.message
        this.toggleNetworkStatus(NETWORK_STATES.NOT_TESTED)
        this.toggleLoadingState(LOADING_STATES.ERROR)
      })
  },

  methods: {
    ...mapActions('SettingsChecked', [
      'checkPaymentShipping'
    ]),

    toggleNetworkStatus (status) {
      this.networkStatus = status
      this.networkStatusText = NETWORK_STATUS_TEXT[status]
    },

    toggleLoadingState (loadingState) {
      this.loading = loadingState
    }
  }
}
</script>

<template lang="pug" src="./template.pug" />
<style lang="scss" src="./style.scss" />
