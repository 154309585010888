<script>
import VueTypes from 'vue-types'

export default {
  name: 'ActionButton',

  props: {
    action: VueTypes.object,
    disabled: VueTypes.bool.def(false)
  }
}
</script>

<template lang="pug" src="./ActionButton.pug"></template>
<style lang="scss" src="./ActionButton.scss" scoped></style>
