<script>
import VueTypes from 'vue-types'

export default {
  name: 'InstructionLink',
  props: {
    linkUrl: VueTypes.string.isRequired
  }
}
</script>

<template lang="pug" src="./InstructionLink.pug"></template>
