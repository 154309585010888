<script>
export default {
  name: 'List',
  components: {},
  props: {
    paginator: {
      type: Object
    }
  },
  computed: {
    meta () {
      return this.paginator ? this.paginator.data().meta : {
        total: 1,
        current_page: 1,
        per_page: 15
      }
    }
  },
  methods: {
    async changePage (toPage) {
      const data = await this.paginator.to(toPage)
      this.$emit('change', data)
      this.$emit('updatePaginationPage', toPage)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
