<script>
import mixin from '../../mixins'

export default {
  name: 'FormSelfPickUp',

  mixins: [mixin],

  data () {
    return {
      fields: ['shipping_type', 'receiver_name', 'receiver_phone', 'receiver_address'],

      form: {
        receiver_name: '',
        receiver_phone: '',
        receiver_address: {}
      },

      rules: {
        receiver_name: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ],
        receiver_phone: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ]
      }
    }
  },

  created () {
    // 選 門市純取貨，後端會自動去抓 門市純取貨 的設定檔
    // 前端塞個意思，避掉 require
    this.form.receiver_address = this.senderAddress
  }

}
</script>

<template lang="pug" src="./FormSelfPickUp.pug"></template>
