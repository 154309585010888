import BaseApi from './baseApi'
import axios from '@/plugins/http.js'
import storeId from './_storeId'

export default class Post extends BaseApi {
  uri = `/api/v1/posts`
  uriList = `/api/v1/stores/${storeId()}/posts`

  // 取得 PubNub 訂閱頻道名稱
  getChannelNames = async id => {
    try {
      const api = `/api/v1/posts/${id}/channel-info`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // 取得意圖分析留言
  getComments = async (id, params) => {
    try {
      const api = `/api/v1/posts/${id}/comments`
      const { data } = await axios.get(api, params)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  postComment = async (id, postData) => {
    try {
      const api = `/api/v1/posts/${id}/comment`
      const { data } = await axios.post(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // 取得 全部留言分析狀態(成功/失敗)清單
  getCommentStatus = async id => {
    try {
      const api = `/api/v1/posts/${id}/comment-status`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  getCommentAnalysis = async id => {
    const api = `/api/v1/posts/${id}/comment-analysis`
    return axios.get(api)
  }

  stop = async id => {
    try {
      const api = `/api/v1/posts/${id}/stop`
      const { data } = await axios.post(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  getStatistics = async id => {
    try {
      const api = `/api/v1/posts/${id}/statistics`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  getStatisticsV2 = async id => {
    try {
      const api = `/api/v1/posts/${id}/statistics-v2`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // Get carts of post
  searchPostCart = async (postId, keyword) => {
    try {
      const api = `/api/v1/posts/${postId}/search-post-cart?keyword=${keyword}`
      const {
        data: { data }
      } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }

  getCart = async (postId, cartId) => {
    try {
      const api = `/api/v1/posts/${postId}/carts/${cartId}`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  putCart = async (postId, cartId, postData) => {
    try {
      const api = `/api/v1/posts/${postId}/carts/${cartId}`
      const { data } = await axios.put(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // 建立 網紅代理開播 貼文
  createPostAgent = async postData => {
    try {
      const api = `/api/v1/stores/${storeId()}/post-agents`
      const { data } = await axios.post(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // 更新 網紅代理開播 貼文
  updatePostAgent = async (id, updateData) => {
    try {
      const api = `/api/v1/stores/${storeId()}/post-agents/${id}`
      const { data } = await axios.put(api, updateData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // 重新取得網紅授權 Token
  renewPostAgentToken = async postData => {
    try {
      const api = `/api/v1/stores/${storeId()}/post-agents/renew`
      const { data } = await axios.post(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  // 取得 貼文
  getPost = id => {
    const api = `/api/v1/posts/${id}`
    return axios.get(api)
  }

  // 取得 代理開播 貼文
  getPostAgent = params => {
    const api = `/api/v1/celebrity`
    return axios.get(api, { params })
  }

  // 綁定網紅以及貼文
  bindPostAgentCelebrity = async postData => {
    try {
      const api = `/api/v1/celebrity/bind`
      await axios.post(api, postData)
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 綁定貼文的頻道
  bindPostAgentChannel = async postData => {
    try {
      const api = `/api/v1/celebrity/bind-channel`
      await axios.post(api, postData)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 更新直播貼文相關設定
  updatePost = async (postId, data) => {
    try {
      const api = `/api/v1/posts/${postId}`
      const updateData = await axios.put(api, data)
      return updateData
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 設定跑馬燈
  setMarquee = (postId, payload) => {
    const api = `/api/v1/posts/${postId}/set-marquee`
    return axios.post(api, payload)
  }
}

// 取得 RTMP 資訊
export const getRtmpInfo = (postId) => {
  const api = `/api/v1/posts/${postId}/stream-meta`
  return axios.get(api)
}

// 透過 RTMP 開播
export const startWithRtmp = (postId, postData) => {
  const api = `/api/v1/posts/${postId}/start-with-rtmp`
  return axios.post(api, postData)
}

// RTMP 開播
export const startStream = (postId, postData) => {
  const api = `/api/v1/posts/${postId}/start-stream`
  return axios.post(api, postData)
}
