
import _ from 'lodash'

const getters = {
  currencySymbol: (state, getters) => {
    const currency = _.get(getters.currentStore, 'currency')
    const map = {
      TWD: 'NT$',
      JPY: '¥',
      VND: '₫',
      THB: '฿'
    }
    return map[currency]
  },
  keywordTip: (state, getters) => {
    const map = {
      TW: 'product.keyword.tooltip',
      JP: 'product.keyword.tooltip',
      VN: 'product.keyword.tooltip',
      TH: 'product.keyword.tooltip_cf'
    }
    return map[getters.region]
  },
  maxPrice: (state, getters) => {
    const map = {
      TW: 999999,
      JP: 9999999,
      VN: 99999999,
      TH: 999999
    }
    return map[getters.region]
  },
  maxSkuName: (state, getters) => {
    const map = {
      TW: 30,
      JP: 30,
      VN: 40,
      TH: 40
    }
    return map[getters.region]
  },
  maxProductName: (state, getters) => {
    const map = {
      TW: 99,
      JP: 99,
      VN: 99,
      TH: 99
    }
    return map[getters.region]
  },
  // 運費參考圖片
  shippingRateImg: (state, getters) => {
    const map = {
      TW: '',
      JP: '',
      VN: '',
      TH: 'KerryShippingRate'
    }
    return map[getters.region]
  },

  apiUrl: (_, getters) => {
    return process.env.VUE_APP_API_URL
  },

  imageUploadUrl: (_, getters) => {
    return `${getters.apiUrl}/api/v1/images`
  },

  videoUploadUrl: (_, getters) => {
    return `${getters.apiUrl}/api/v1/preview-videos`
  }
}

export default {
  getters
}
