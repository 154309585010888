<script>
import VueTypes from 'vue-types'

const GUIDE_URL = 'https://blog.handsup.shop/set-fbpixel-1/'
export default {
  name: 'DialogFbPixelSetting',
  props: {
    pixelId: VueTypes.string.def('')
  },
  data () {
    return {
      dataPixel: this.pixelId,
      guideUrl: GUIDE_URL
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      const payload = this.dataPixel ? this.dataPixel.trim() : ''
      this.$emit('submit', payload)
    }
  }
}
</script>

<template lang="pug" src="./DialogFbPixelSetting.pug"></template>
<style lang="scss" src="./DialogFbPixelSetting.scss" scoped></style>
