import * as loadingState from '@/constants/loadingState'

import { checkToken } from '@/api/registerToken'

export const actionTypes = {
  CHECK_TOKEN: 'CHECK_TOKEN',
  CHECK_TOKEN_SUCCESS: 'CHECK_TOKEN_SUCCESS',
  CHECK_TOKEN_FAILED: 'CHECK_TOKEN_FAILED'
}

const state = {
  valid: null,
  loadingState: loadingState.EMPTY,
  error: null
}

const getters = {
  checkTokenLoadingState: state => state.loadingState,
  isTokenValid: state => state.valid
}

const mutations = {
  [actionTypes.CHECK_TOKEN] (state) {
    state.loadingState = loadingState.LOADING
  },

  [actionTypes.CHECK_TOKEN_SUCCESS] (state, valid) {
    state.loadingState = loadingState.READY
    state.valid = valid
  },

  [actionTypes.CHECK_TOKEN_FAILED] (state, error) {
    state.loadingState = loadingState.ERROR
    state.error = error

    console.error(error)
  }
}

const actions = {
  checkToken ({ commit }, token) {
    commit(actionTypes.CHECK_TOKEN)

    return checkToken(token)
      .then(res => {
        const { valid } = res.data

        commit(actionTypes.CHECK_TOKEN_SUCCESS, valid)
      })
      .catch(err => commit(actionTypes.CHECK_TOKEN_FAILED, err))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
