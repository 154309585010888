<script>
export default {
  name: 'DialogRegistered',
  components: {},
  data () {
    return {
      dialogVisible: false
    }
  },
  methods: {
    toggle (isOpen = null) {
      this.dialogVisible = isOpen === null ? !this.dialogVisible : isOpen
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
