import './plugins/chunkLoadFailedListener.js' // 監聽 chunk Load Failed

// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Layout Component
import './layouts'

// Global Components
import BaseImage from './components/BaseImage'
import BaseButton from './components/BaseButton'
import DropDownLocale from './components/DropDownLocale'
import PriceTax from './components/PriceTax'
import InstructionLink from '@/components/InstructionLink'

// App Component
import AppConfirm from '@/components/AppConfirm'
import AppDialog from '@/components/AppDialog'
import AppIntroduce from '@/components/AppIntroduce'
import AppListLayout from '@/components/AppListLayout'
import AppAlert from '@/components/AppAlert'

// Utils
import { VUE_APP_DOMAIN_NAME, VUE_APP_BUYER_DOMAIN_NAME } from '@/utils/globals'
import autoLogin from '@/utils/autoLogin'

// 第三方套件
import './plugins/clearServiceWorker.js' // clear service worker and cache
import './plugins/setupVueGtm.js' // GTM
import './plugins/fb.js' // fb
import './plugins/fbPixelBase.js' // Fb Pixel Base Code
import './plugins/setupVueFbPixel.js' // Fb Pixel Tracking
import './plugins/logCollection.js' // log service
import './plugins/cheet.js' // cheet js
import VueAnalytics from 'vue-analytics'
import VueClipboard from 'vue-clipboard2'
import i18n from './plugins/i18n'
import axios from './plugins/http.js'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui'
import VueScrollTo from 'vue-scrollto'

import * as filters from './filters'

if (process.env.NODE_ENV === 'development') {
  autoLogin()
}

Vue.use(VueAxios, axios)
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.use(VueClipboard)
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GA_ID,
  checkDuplicatedScript: true
})
Vue.use(VueScrollTo)

Vue.component('BaseImage', BaseImage)
Vue.component('BaseButton', BaseButton)
Vue.component('DropDownLocale', DropDownLocale)
Vue.component('PriceTax', PriceTax)
Vue.component('InstructionLink', InstructionLink)

Vue.component('AppConfirm', AppConfirm)
Vue.component('AppDialog', AppDialog)
Vue.component('AppIntroduce', AppIntroduce)
Vue.component('AppListLayout', AppListLayout)
Vue.component('AppAlert', AppAlert)

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// register global variables
Vue.prototype.$VUE_APP_DOMAIN_NAME = VUE_APP_DOMAIN_NAME
Vue.prototype.$VUE_APP_BUYER_DOMAIN_NAME = VUE_APP_BUYER_DOMAIN_NAME

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
