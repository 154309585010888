export const CREDIT = 'credit'
export const VIRTUAL_ATM = 'virtual_atm'
export const COD = 'cash_on_delivery'
export const CVS = 'cvs'
export const GMO_CVS = 'gmo_cvs'
export const FREE = 'free'
export const GMO_CREDIT = 'gmo_credit'
export const GMO_DEFERRED = 'gmo_deferred'
export const CCPP_CREDIT = 'ccpp_credit'
export const PROMPT_PAY = 'prompt_pay'
export const TH_ATM = 'th_atm'
export const TH_CCPP_GATEWAY = 'th_ccpp_gateway'
export const CUSTOM = 'custom'
export const OFFLINE_SELF_PICK_UP = 'offline_self_pick_up'
export const OFFLINE_CUSTOM_TCAT_COD = 'offline_custom_tcat_cod'
export const OFFLINE_ATM = 'offline_atm' // 銀行轉帳
export const NEWEBPAY_CREDIT = 'newebpay_credit' // 藍新金流-信用卡
export const NEWEBPAY_ATM = 'newebpay_atm'// 藍新金流-虛擬帳號匯款
export const PAYPAL = 'paypal'
export const MANUAL = 'manual'
export const NEWEBPAY_CREDIT_AUTO = 'newebpay_auto' // 藍新金流-信用卡自動付款
export const NEWEBPAY = 'newebpay' // 虛擬帳號匯款
