<script>

import Sticky from 'vue-sticky-directive'
import { mapGetters, mapActions } from 'vuex'

import ApiPlan from '@/api/plan'
import ApiChannel from '@/api/channel'
import ApiStoreUser from '@/api/storeUser'

import * as PAYMENT_FLOW from '@/constants/paymentFlow'

import plans from '@/utils/plans201910.js'

import AppAlert from '@/components/AppAlert'
import BaseButton from '@/components/BaseButton'

import DialogChoosePlans from '@/views/Plan/DialogChoosePlans'

const config = {
  trialPlan: 'master'
}

export default {
  name: 'SelectTw',
  directives: { Sticky },
  components: { DialogChoosePlans, AppAlert, BaseButton },
  data () {
    return {
      apiPlan: new ApiPlan(),
      apiChannel: new ApiChannel(),
      apiStoreUser: new ApiStoreUser(),
      plans,
      tabs: [],
      availablePlans: [],
      activeTab: null,
      loading: false,
      isDegrade: false,
      isDialogVisible: false,
      chosenPlan: null
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'currentStore', 'paymentFlow']),
    isTrial () {
      return this.currentStore && this.currentStore.plan_frequency === 'trial'
    },
    isAssistant () {
      // 是否為 小幫手
      return this.currentStore && this.currentStore.pivot.type === 'assistant'
    },
    isPaymentDirect () {
      // 非代收代付
      return this.paymentFlow === PAYMENT_FLOW.DIRECT
    },
    currentPlan () {
      if (this.isTrial) {
        return _.find(this.plans, { name: config.trialPlan })
      }
      return this.currentStore.plan
    },
    displayPlans () {
      // Novice no annual pay
      return this.plans.map(plan => {
        const matchPlan = _.find(this.availablePlans, { name: plan.name })

        return {
          ...plan,
          id: matchPlan && matchPlan.id,
          isEnabled: !!_.find(this.availablePlans, { name: plan.name }),
          isActive: this.currentPlan.name === plan.name
        }
      })
    }

  },
  async created () {
    // [Facebook Pixel] 追蹤觀看方案畫面
    this.$analytics.fbq.event('ViewContent', { Page: 'plan' })
    // [Google Analytics] 追蹤建立直播事件
    this.$ga.event({
      eventCategory: 'ViewContent',
      eventAction: 'View-Plan',
      eventLabel: 'Merchant-Platform'
    })

    await this.fetchPlans()

    this.setTabs()
  },

  mounted () {

  },

  methods: {
    ...mapActions('Me', ['fetchStores']),
    ...mapActions('Plan', ['updateBuyingPlan']),
    setTabs () {
      this.activeTab = 'monthly'
      this.tabs = [{ value: 'monthly', text: '月繳' }, { value: 'annual', text: '年繳' }]

      if (this.currentStore.plan_is_renew) {
        this.activeTab = this.currentStore && this.currentStore.plan_frequency
        this.tabs = _.filter(this.tabs, { value: this.activeTab })
      }
    },
    async fetchPlans () {
      this.availablePlans = await this.apiPlan.list()
    },
    async checkPlanValid (planId) {
      // 判斷 channels 和 小幫手數量是否低於原本 plan 數量
      let valid = true

      if (this.chosenPlan.channel_number < this.currentStore.channel_count) {
        valid = false
      }
      if (this.chosenPlan.manager_number &&
      (this.chosenPlan.manager_number < this.currentStore.manager_count)) {
        valid = false
      }
      return valid
    },

    async choosePlan (planId) {
      this.chosenPlan = _.find(this.displayPlans, { id: planId })
      const valid = await this.checkPlanValid(planId)

      if (!valid) {
        this.channels = await this.apiChannel.list()
        this.storeUsers = await this.apiStoreUser.list()
        this.isDialogVisible = true
        return
      }

      this.goPlanPayment(planId)
    },

    disabledBtnRenew (plan) {
      // 可否點擊 購買方案 按鈕
      return !plan.isEnabled || (this.currentStore.plan_is_expired && this.isAssistant) || this.isPaymentDirect
    },

    async updatePlan (planId) {
      this.loading = true

      const success = await this.apiPlan.update(planId, {
        frequency: this.activeTab
      })

      if (success) {
        await this.$store.dispatch('Me/fetchStores')
        this.$message.success('購買方案成功')
        await this.fetchStores()
        this.$router.push('/')
      } else {
        this.$message.error('購買方案失敗')
      }

      this.loading = false
    },

    async unbind ({ channels, users }) {
      try {
        await Promise.all([
          ...channels.map(async channelId => {
            const data = await this.apiChannel.unbindChannel(channelId)
            if (!data) return Promise.reject(new Error('Unbind channels fail'))
            return data
          }),
          ...users.map(async userlId => {
            const data = await this.apiStoreUser.detachAssistant(userlId)
            if (!data) return Promise.reject(new Error('Unbind users fail'))
            return data
          })
        ])

        this.updatePlan(this.chosenPlan.id)
      } catch (e) {
        console.error('catch', e)
      }
    },

    goPlanPayment (planId) {
      this.updateBuyingPlan({
        id: planId,
        frequency: this.activeTab
      })
      this.$router.push({ name: 'PlanPayment' })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
