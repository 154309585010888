import ApiProduct from '@/api/product'
import { sortImage } from '@/utils/sortImage'

const INIT_STATE = {
  products: [],
  meta: null,
  currentPage: 1,
  search: {
    from: '',
    to: '',
    keyword: '',
    sort: null,
    desc: null,
    postId: null
  }
}

const state = {
  ...INIT_STATE
}

const getters = {
  products: state => state.products,
  meta: state => state.meta,
  currentPage: state => state.currentPage,
  search: state => state.search,
  filterSearchRule: state => {
    return {
      page: state.currentPage,
      with_ticket: false,
      self_from: state.search.from,
      self_to: state.search.to,
      keyword: state.search.keyword,
      sort: state.search.sort,
      desc: state.search.desc,
      post_id: state.search.postId
    }
  }
}

const mutations = {
  UPDATE_PRODUCTS (state, data) {
    state.products = data
  },
  UPDATE_META (state, meta) {
    state.meta = meta
  },
  UPDATE_SEARCH (state, search) {
    state.search = {
      ...state.search,
      ...search
    }
  },
  SET_CURRENT_PAGE (state, currentPage) {
    state.currentPage = currentPage
  },
  RESET_PRODUCT_DATA (state) {
    state = Object.assign(state, { ...INIT_STATE })
  }
}

const actions = {
  async fetchProducts ({ getters, commit }) {
    const apiProduct = new ApiProduct()
    const result = await apiProduct.getProductList(getters.filterSearchRule)
      .then(response => {
        const { data, meta } = response.data
        // sort images by sort key
        data.forEach(product => {
          product.images = product.images.sort(sortImage)
        })
        commit('UPDATE_PRODUCTS', data)
        commit('UPDATE_META', meta)
      })
      .catch(error => {
        console.error(error)
      })
    return result
  },
  updateSearch ({ getters, commit }, searchObj) {
    commit('UPDATE_SEARCH', {
      ...getters.search,
      ...searchObj
    })
  },
  setCurrentPage ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page)
  },
  async updateRows ({ dispatch, state }, page) {
    await dispatch('setCurrentPage', page)
    await dispatch('fetchProducts', state.search)
  },
  resetProductData ({ commit }) {
    commit('RESET_PRODUCT_DATA')
  },
  batchDelete ({ dispatch }, ids) {
    const apiProduct = new ApiProduct()
    const request = Object.assign({}, { data: { product_ids: ids } })
    return apiProduct.batchDeleteProduct(request)
      .then(async () => {
        await dispatch('updateRows', 1)
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
