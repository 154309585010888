<script>
import PubNub from 'pubnub'
import { mapGetters, mapActions } from 'vuex'
import ApiPrint from '@/api/print'
import Print from '../Print'
import FamilyBarcode from '@/components/FamilyBarcode'
import KerryThBarcode from '@/components/KerryThBarcode'

export default {
  name: 'BatchPrint',
  components: { Print, FamilyBarcode, KerryThBarcode },
  data () {
    return {
      pendingCount: 0,
      success: 0,
      fail: 0,
      orders: [],
      printMode: null,
      sort: null
    }
  },
  computed: {
    ...mapGetters('Me', ['me', 'storeName', 'token'])
  },
  async created () {
    this.pubnub = new PubNub({
      subscribeKey: process.env.VUE_APP_PUBNUB_SUBSCRIBE_KEY
    })
    const channel = `notification|shipping|${this.storeName}|${this.me.slug}`

    this.pubnub.subscribe({
      channels: [channel]
    })

    this.pubnub.addListener({
      message: m => this.dispatch(m.channel, m.message)
    })

    await this.fetchOrder()
  },
  beforeDestroy () {
    // PubNub Unsubscribe：
    // https://www.pubnub.com/docs/web-javascript/api-reference-publish-and-subscribe#unsubscribe_all

    try {
      this.pubnub.unsubscribeAll()
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    getImgPath (order) {
      const path = order.shipping_print_image
      return path.includes('googleusercontent') ? path + '=s1600' : path
    },
    iframeSrc (path) {
      return 'data:text/html;charset=utf-8,' + encodeURIComponent(path)
    },
    dispatch (channel, payload) {
      this.pendingCount--
      if (payload.type.includes('success')) this.success++
      else if (payload.type.includes('fail')) this.fail++

      if (this.pendingCount === 0) {
        this.setLoading(false)
      }
    },
    async fetchOrder () {
      this.setLoading(true)

      const orders = localStorage.getItem('ordersToPrint')
      localStorage.removeItem('ordersToPrint')

      const slugs = JSON.parse(orders)
      if (!slugs || slugs.length <= 0) {
        window.close()
      }

      this.printMode = this.$route.query.mode
      const apiPrint = new ApiPrint()
      const postData = {
        order_slugs: slugs,
        print_mode: this.printMode,
        sort: this.$route.query.sort
      }
      const orderData = await apiPrint.batchOrder(postData)
      this.pendingCount = orderData.pending_count
      if (this.pendingCount === 0) this.setLoading(false)
      this.success = 0
      this.fail = 0
      this.orders = orderData.data
    }
  }
}
</script>

<template lang='pug' src='./template.pug'></template>
<style lang='scss' src='./style.scss' scoped></style>
