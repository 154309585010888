import i18n from '@/plugins/i18n'
import * as PAYMENT_TYPES from '@/constants/paymentTypes'

export const getPaymentLabel = paymentType => {
  switch (paymentType) {
    case PAYMENT_TYPES.OFFLINE_ATM:
      return i18n.t('OrderManual.payment.type.offline_atm')
    case PAYMENT_TYPES.NEWEBPAY_CREDIT:
      return i18n.t('payment.type.newebpay_credit')
    case PAYMENT_TYPES.NEWEBPAY_ATM:
      return i18n.t('payment.type.newebpay_atm')
    default:
      break
  }
}
