<script>
import VueTypes from 'vue-types'

import iconNews from '@/assets/icon-news.png'

import { BILL_DEADLINE_END } from '@/constants/BillPaymentDeadline'

export default {
  name: 'DialogBillingReminder',

  props: {
    paymentBill: VueTypes.array.isRequired
  },

  data () {
    return {
      iconNews
    }
  },

  computed: {
    newestBill () {
      return this.paymentBill[this.paymentBill.length - 1]
    },

    deadline () {
      const dt = new Date(this.newestBill.date)
      dt.setMonth(dt.getMonth() + 1)

      const yearMonth = dt.getFullYear() + '-' + `${dt.getMonth() + 1}`.padStart(2, '00')
      const deadlineEnd = `${BILL_DEADLINE_END}`.padStart(2, '00')

      return `${yearMonth}-${deadlineEnd}`
    }
  },

  methods: {
    toAccountManagement () {
      this.$emit('toAccountManagement')
      this.close()
    },
    close () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogBillingReminder.pug"></template>
<style lang="scss" scoped>
  .img {
    object-fit: contain;
  }

</style>
