import { getViewersCount } from '../../apis/liveweb'

const state = {
  pubnubViewersCount: 0, // 觀看人數
  facebookViewersCount: 0 // 觀看人數
}

const getters = {
  watchingCount: state => state.pubnubViewersCount + state.facebookViewersCount
}

const mutations = {
  UPDATE_PUBNUB_VIEWERS_COUNT (state, count) {
    state.pubnubViewersCount = count
  },
  UPDATE_FACEBOOK_VIEWERS_COUNT (state, count) {
    state.facebookViewersCount = count
  },

  RESET_VIEWERS_COUNT (state) {
    state.pubnubViewersCount = 0
    state.facebookViewersCount = 0
  }
}

const actions = {
  fetchViewersCount ({ commit }, postId) {
    getViewersCount(postId)
      .then(response => {
        const { data } = response
        const viewersCount = _.get(data, 'fb_page', 0)

        commit('UPDATE_FACEBOOK_VIEWERS_COUNT', viewersCount)
      })
      .catch(err => {
        console.error(err)
        throw err
      })
  },

  updatePubnubViewersCount ({ commit }, count) {
    commit('UPDATE_PUBNUB_VIEWERS_COUNT', count)
  },

  resetViewersCount ({ commit }) {
    commit('RESET_VIEWERS_COUNT')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
