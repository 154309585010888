import * as loadingState from '@/constants/loadingState'
import ApiPost from '@/api/post'

export const types = {
  SENDING_MESSAGE: 'SENDING_MESSAGE',
  SEND_MESSAGE_SUCCESS: 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILED: 'SEND_MESSAGE_FAILED'
}

const state = {
  loading: loadingState.EMPTY,
  errMessage: null
}

const getters = {
  loadingState: state => state.loading
}

const mutations = {
  [types.SENDING_MESSAGE] (state) {
    state.loading = loadingState.LOADING
    state.errMessage = null
  },

  [types.SEND_MESSAGE_SUCCESS] (state) {
    state.loading = loadingState.READY
    state.errMessage = null
  },

  [types.SEND_MESSAGE_FAILED] (state, { errMessage }) {
    state.loading = loadingState.ERROR
    state.errMessage = errMessage
  }
}

const actions = {
  sendMessage ({ commit, dispatch }, { postID, channels, message }) {
    commit(types.SENDING_MESSAGE)
    const apiPost = new ApiPost()

    apiPost.postComment(postID, {
      channel_id: channels,
      message
    })
      .then(message => {
        // @todo we should create a an event bus to handle 'SEND_MESSAGE_SUCCESS'
        // event and dispatch 'appendMessage' action accordingly.

        // toggle loading state to READY
        commit(types.SEND_MESSAGE_SUCCESS)

        // dispatch to push new message to current message stack
        // dispatch('ChatMessage/Messages/appendMessage', { message }, { root: true })
      })
      .catch(err => commit(types.SEND_MESSAGE_FAILED, { errMessage: err.message }))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
