<script>
import VueTypes from 'vue-types'
import { mapState, mapGetters } from 'vuex'
import { MARKET, SELLER, SUBSCRIPTION } from '@/constants/cartAddedFrom'
import { HANDSUP, FB_PAGE, M17_LIVE } from '@/constants/channelType'
import { PRODUCT_ACTION_ADD, PRODUCT_ACTION_EDIT } from '@/constants/orderManual'
import { normalizeImage } from '@/utils/normalizeImage'
import getSubscriptionImage from '@/utils/getSubscriptionImage'

import handsupIcon from '@/assets/icon-handsup.png'
import facebookIcon from '@/assets/icon-fb-lg.png'
import m17Icon from '@/assets/icon-17-lg.png'

const imageMappings = {
  [HANDSUP]: handsupIcon,
  [FB_PAGE]: facebookIcon,
  [M17_LIVE]: m17Icon
}

export default {
  name: 'PurchaseDetail',

  props: {
    toggleDialogUpdateOrder: VueTypes.func.isRequired
  },

  data () {
    return {
      SELLER,
      MARKET,
      SUBSCRIPTION,
      handsupIcon,
      PRODUCT_ACTION_ADD,
      PRODUCT_ACTION_EDIT
    }
  },

  computed: {
    ...mapState('OrderManual/ShoppingDetails', ['selectedProducts']),
    ...mapGetters('Me', ['currencySymbol'])
  },

  methods: {
    normalizeImage,
    getSubscriptionImage,
    getChannelImage (item) {
      const channelType = item.channel.type

      return imageMappings[channelType]
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
