<script>
export default {
  name: 'DialogValidatePhone',
  props: {
    remainedSeconds: {
      type: Number
    },
    phoneNumber: {
      type: String
    },
    visible: {
      type: Boolean
    }
  },
  data () {
    return {
      code: null,
      rules: {
        code: [
          {
            required: true,
            message: this.$t('Components.DialogValidatePhone.enter_validate'),
            trigger: 'blur' }
        ]
      },
      errors: {
        code: null
      }
    }
  },
  computed: {
    form () {
      return { code: this.code }
    },
    maskPhone () {
      return this.phoneNumber.slice(0, 4) + '***' + this.phoneNumber.slice(7)
    }
  },
  watch: {
    code (newValue) {
      this.updateCode(this, newValue)
    }
  },
  methods: {
    submit () {
      this.$refs['DialogValidatePhone'].validate((valid) => {
        if (valid) {
          this.$emit('submit', this.code)
        }
      })
    },
    updateCode: _.debounce((that, newValue) => {
      that.$emit('updateCode', newValue)
    }),
    requestSMSCode () {
      this.$emit('requestSMSCode')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
