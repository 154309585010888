import { render, staticRenderFns } from "./DialogSetCover.pug?vue&type=template&id=526f178f&scoped=true&lang=pug&"
import script from "./DialogSetCover.vue?vue&type=script&lang=js&"
export * from "./DialogSetCover.vue?vue&type=script&lang=js&"
import style0 from "./DialogSetCover.scss?vue&type=style&index=0&id=526f178f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "526f178f",
  null
  
)

export default component.exports