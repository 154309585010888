<script>
import { BASE_IMAGE_PATH } from '@/constants/path'
import { mapGetters } from 'vuex'
import { REPLY_TYPE, REPLY_BUTTON_TEMPLATE_TYPE } from '../../constants'

export default {
  name: 'DialogReplyType',
  props: {
    replyType: {
      type: String,
      default: REPLY_TYPE.DIRECT
    },
    replyButtonTemplateType: {
      type: String,
      default: REPLY_BUTTON_TEMPLATE_TYPE.checkout
    }
  },
  data () {
    return {
      REPLY_TYPE,
      REPLY_BUTTON_TEMPLATE_TYPE,
      BASE_IMAGE_PATH,
      dataReplyType: null,
      dataReplyButtonTemplateType: null
    }
  },
  computed: {
    ...mapGetters('Me', ['isEnterprise'])
  },
  created () {
    this.dataReplyType = this.replyType
    this.dataReplyButtonTemplateType = this.replyButtonTemplateType
  },
  methods: {
    close () {
      this.$emit('close')
    },
    submit () {
      this.$emit('submit', {
        replyType: this.dataReplyType,
        replyButtonTemplateType: this.dataReplyButtonTemplateType
      })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
