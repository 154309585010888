import Cookies from 'js-cookie'
import axios from 'axios'
import store from '@/store'
import responseHandler from '@/utils/responseHandler'

// axios 配置
// axios.defaults.timeout = 5000
// axios.defaults.baseURL = 'https://handsup.test/'
// axios.defaults.withCredentials = true

// http request 攔截器
axios.interceptors.request.use(
  config => {
    const token = Cookies.get('api_token')
    const locale = store.getters['Lang/locale']
    const baseURL = store.getters['Me/apiUrl']
    if (token) config.headers.common['Authorization'] = `Bearer ${token}`
    if (locale) config.headers.common['Accept-Language'] = locale
    if (!config.baseURL && baseURL) config.baseURL = baseURL

    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// http response 攔截器
axios.interceptors.response.use(
  res => {
    return res
  },
  async error => {
    const { status } = error.response

    /* 隱藏訊息 */
    const ignoreTypes = [
      'HandsUp\\Stream\\Exceptions\\LiveStreamException' // 直播結束/錯誤
    ]

    const isIgnore = ignoreTypes.findIndex(
      ignoreType => ignoreType === error.response.data.type
    )

    if (isIgnore !== -1) return Promise.reject(error.response)

    /* 處理催繳通知錯誤訊息隱藏 */
    if (
      error.response.data.type ===
        'Facebook\\Exceptions\\FacebookResponseException' &&
      error.response.data.message.includes('551')
    ) {
      return Promise.reject(error.response)
    }

    // PayPal Auth Error 隱藏
    if (
      error.response.data.error === 'invalid_client'
    ) {
      return Promise.reject(error.response)
    }

    switch (status) {
      // 表單欄位錯誤
      case 422:
        responseHandler.handleValidateError(error.response)
        break
      // 401 權限過期
      case 401:
        responseHandler.handleExpiredError(error.response)
        break
      // 404 無此頁面
      case 404:
        responseHandler.handlePageNotFoundError(error.response)
        break
      default:
        responseHandler.handleError(error.response)
        break
    }

    return Promise.reject(error.response)
  }
)

export default axios
