<script>
import VueTypes from 'vue-types'

export default {

  name: 'KerryThBarcode',
  props: {
    data: VueTypes.object.isRequired,
    subType: VueTypes.string.def('')
  }
}
</script>

<template lang="pug" src="./KerryThBarcode.pug"></template>
<style lang="scss" src="./KerryThBarcode.scss" scoped></style>
