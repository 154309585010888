<script>
import { mapGetters } from 'vuex'
import * as SETTINGS from '@/constants/storeSettings'
import PhoneImg from '../../images/img-iphonex-mockup.svg'
import TopShadow from './components/TopShadow'
import SellingProduct from './components/SellingProduct'
import Notifications from './components/Notifications'
import ChatInput from './components/ChatInput'
import ChatMessageList from './components/ChatMessageList'
import HotkeyList from './components/HotkeyList'
import UserControls from './components/UserControls'
import ProductList from './components/ProductList'
import InteractionAnimation from './components/InteractionAnimation'

export default {
  name: 'PhoneDisplay',
  components: {
    TopShadow,
    SellingProduct,
    Notifications,
    ChatInput,
    ChatMessageList,
    HotkeyList,
    UserControls,
    ProductList,
    InteractionAnimation
  },
  data () {
    return {
      PhoneImg
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore']),
    ...mapGetters('LiveSetting', ['newSettings']),
    containerHeight () {
      return this.newSettings[SETTINGS.PRODUCT_LIST_SHOW]
        ? '740px'
        : '496px'
    }
  },
  methods: {
    triggerAnimation (type, yPosition) {
      this.$refs.interactionAnimation.triggerSeasonal(type, yPosition)
    }
  }
}
</script>

<template lang="pug" src="./PhoneDisplay.pug"></template>
<style lang="scss" src="./PhoneDisplay.scss" scoped></style>
