import axios from '@/plugins/http'

export const updatePost = (id, data) => (
  axios.put(`/api/v1/posts/${id}`, data)
)

export const stopPost = (id) => (
  axios.post(`/api/v1/posts/${id}/stop`)
)

export const setSellingProduct = (id, data) => (
  axios.put(`/api/v1/posts/${id}/product`, data)
)

// 下載下單紀錄
export const exportPostBuyerList = id => (
  axios({
    url: `/api/v1/posts/${id}/export-post-buyer-list`,
    method: 'GET',
    responseType: 'blob'
  })
)

export const hideComment = (postId, payload) => (
  axios.post(`/api/v1/posts/${postId}/hidden-comment`, payload)
)

export const showComment = (postId, commentId) => {
  const data = { comment_id: commentId }
  return axios.delete(`/api/v1/posts/${postId}/hidden-comment`, { data })
}
