<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { BASE_IMAGE_PATH } from '@/constants/path'
import AnchorTimePicker from './components/AnchorTimePicker'
import PublishedHeader from '../Published/components/PublishedHeader'
import VideoPlayer from '@/components/VideoPlayer'
import { secondsToTime } from '@/utils/timeOperation'
import { getSnapshot } from '@/utils/getSnapshot'
import VideoClipToolBar from './components/VideoClipToolBar'
import ClipVideoCard from './components/ClipVideoCard'
import DialogCoverSetting from './components/DialogCoverSetting'
import DialogPreviewVideo from './components/DialogPreviewVideo'

export default {
  name: 'ReplayEdit',
  components: {
    AnchorTimePicker,
    PublishedHeader,
    VideoPlayer,
    VideoClipToolBar,
    ClipVideoCard,
    DialogCoverSetting,
    DialogPreviewVideo
  },
  data () {
    return {
      videoDuration: 0,
      isLoading: false,
      editAnchorMode: false, // 商品錨點編輯
      clipVideoMode: false, // 建立剪輯片段
      clipSortMode: false, // 挑選剪輯片段
      secondsToTime,
      showCoverDialog: false,
      showPreviewDialog: false,
      BASE_IMAGE_PATH
    }
  },
  computed: {
    ...mapGetters('Post', ['products', 'replayUrl', 'watchingCount', 'postId', 'isClipAsReplay']),
    ...mapGetters('Me', ['storeName', 'token', 'imageUploadUrl']),
    ...mapGetters('ReplayEdit', ['anchorList']),
    ...mapGetters('ReplayEdit/ClipVideo', ['sortedClipVideoList', 'currentEditedClip', 'isDuplicatedTitle']),
    ...mapState('Uploader', ['isFileUploading']),
    ...mapState('ReplayEdit', ['editProductAnchor']),
    ...mapState('ReplayEdit/ClipVideo', ['clipVideoCoverTitle', 'newClip', 'isClipLoading', 'editClipVideo', 'currentEditClipId']),
    imageUploadHeader () {
      return { Authorization: `Bearer ${this.token}` }
    }
  },
  watch: {
    clipVideoMode (state) {
      if (state) {
        this.updateCurrentEditVideoId(null)
        this.switchAnchorEditMode(false)
        this.switchClipSortMode(false)
        this.updateNewClip({
          start: 0,
          end: this.videoDuration
        })
      } else {
        this.$refs.videoPlayerRef.resetClipData()
        this.resetNewClipVideo()
      }
    },
    editAnchorMode (state) {
      if (!state) {
        this.initEditProductAnchor()
      }
    },
    clipSortMode (state) {
      if (state) {
        this.updateCurrentEditVideoId(null)
        this.switchClipVideoMode(false)
      }
    }
  },
  created () {
    this.initializeProductAnchor()
    this.initializeClipVideoList()
  },
  destroyed () {
    this.resetProductAnchor()
    this.resetClipVideo()
  },
  methods: {
    ...mapActions('Uploader', ['handleImageUpload']),
    ...mapActions('Post', ['resetPostData', 'updateActiveTab']),
    ...mapActions('ReplayEdit', [
      'initializeProductAnchor',
      'updateProductAnchor',
      'clearAllProductAnchors',
      'resetProductAnchor',
      'updateProductAnchorList',
      'initEditProductAnchor'
    ]),
    ...mapActions('ReplayEdit/ClipVideo', [
      'initializeClipVideoList',
      'createNewClip',
      'deleteClip',
      'sortClipVideo',
      'updateClipSort',
      'useFullVideoAsReplay',
      'useClipAsReplay',
      'saveClipInfo',
      'resetClipSort',
      'resetClipInfo'
    ]),
    ...mapMutations('ReplayEdit/ClipVideo', ['updateNewClip', 'updateCurrentEditVideoId', 'updateClipInfo', 'resetNewClipVideo', 'resetClipVideo']),
    /**
     * 更新商品錨點曝光時間
     * @param {Object} anchorTime
     * @param {number} anchorTime.anchor
     * @param {number} anchorTime.productId
     * @param {number | NULL} anchorTime.anchorId
     */
    updateAnchorTime (anchorTime) {
      this.updateProductAnchor(anchorTime)
    },
    getCurrentVideoTime () {
      return this.$refs.videoPlayerRef.getCurrentTime()
    },
    videoDurationUpdated (duration) {
      this.videoDuration = duration
    },
    getVideoDuration () {
      return this.$refs.videoPlayerRef.getDuration()
    },
    async updateProductAnchors () {
      this.isLoading = true
      const result = await this.updateProductAnchorList()
      if (result) {
        this.$message.success(this.$t('Post.Replay.update_anchor_success'))
        this.switchAnchorEditMode(false)
      }
      this.isLoading = false
    },
    copyLink () {
      const copyUrl = `${this.$VUE_APP_BUYER_DOMAIN_NAME}/${this.storeName}/live/${this.postId}?openExternalBrowser=1`
      this.copyText({
        url: copyUrl,
        success: this.$t('Post.Show.message.copy_link'),
        error: this.$t('Post.Show.message.copy_fail')
      })
    },
    changeTab (tab) {
      this.updateActiveTab(tab)
      this.$router.push(`/${this.storeName}/posts/${this.postId}`)
    },
    switchAnchorEditMode (status) {
      this.editAnchorMode = status
    },
    switchClipVideoMode (status) {
      this.clipVideoMode = status
    },
    switchClipSortMode (status) {
      this.clipSortMode = status
      this.resetClipSort()
    },
    duplicatedTitleConfirm () {
      if (this.isDuplicatedTitle) {
        return this.$confirm(this.$t('ReplayEdit.text.duplicated_title'), '', {
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel'),
          type: 'warning'
        })
      }
    },
    async handleCoverSetting (title) {
      this.updateNewClip({ title })
      try {
        await this.duplicatedTitleConfirm()
        if (!this.newClip.imageId) {
          const firstFrameImage = await this.handleImageUpload(this.firstFrame)
          this.updateNewClip({
            imageId: firstFrameImage.id,
            screenshot: 0
          })
        }
        await this.createNewClip()
        this.switchClipVideoMode(false)
      } catch (error) {}
    },
    goEditMode (clipId) {
      this.updateCurrentEditVideoId(clipId)
      this.switchClipVideoMode(false)
    },
    leaveClipMode () {
      this.updateCurrentEditVideoId(null)
      this.switchClipVideoMode(false)
    },
    openCoverSettingDialog (clipId) {
      this.showCoverDialog = true
    },
    closeCoverSettingDialog () {
      this.showCoverDialog = false
    },
    async updateCoverSetting ({ imageId, clipId, imageUrl, screenshot }) {
      const payload = {
        imageId,
        imageUrl,
        screenshot
      }
      if (!imageId) {
        const firstFrameImage = await this.handleImageUpload(this.firstFrame)
        payload.imageUrl = firstFrameImage.url
        payload.imageId = firstFrameImage.id
        payload.screenshot = 0
      }
      if (!clipId) {
        this.updateNewClip(payload)
      } else {
        this.updateClipInfo(payload)
      }
      this.closeCoverSettingDialog()
    },
    handleDeleteClip (clipId) {
      this.$confirm(this.$t('ReplayEdit.text.confirm_delete_video'), {
        confirmButtonText: this.$t('ReplayEdit.button.confirm'),
        cancelButtonText: this.$t('ReplayEdit.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.deleteClip(clipId)
      }).catch(() => {})
    },
    setFullVideoAsReplay () {
      this.$confirm('', this.$t('ReplayEdit.text.confirm_use_full_video'), {
        confirmButtonText: this.$t('ReplayEdit.button.confirm'),
        cancelButtonText: this.$t('ReplayEdit.button.cancel'),
        type: 'warning'
      }).then(() => {
        this.switchClipVideoMode(false)
        this.useFullVideoAsReplay()
      }).catch(() => {})
    },
    openPreviewDialog (clipId) {
      this.updateCurrentEditVideoId(clipId)
      this.showPreviewDialog = true
    },
    getFirstFrame () {
      const video = this.$refs.videoPlayerRef.videoElement
      this.firstFrame = getSnapshot(video)
    },
    async updateClipSortAndSetClipAsReplay () {
      if (!this.sortedClipVideoList.length) {
        this.$message({
          message: this.$t('ReplayEdit.text.empty_clip'),
          type: 'warning'
        })
        return
      }
      await this.updateClipSort()
      await this.useClipAsReplay()
      this.switchClipSortMode(false)
    },
    async updateAndSaveClipVideo (payload) {
      this.updateClipInfo(payload)
      try {
        await this.duplicatedTitleConfirm()
        await this.saveClipInfo()
        this.updateCurrentEditVideoId(null)
      } catch (error) {}
    },
    closePreviewDialog () {
      this.updateCurrentEditVideoId(null)
      this.showPreviewDialog = false
    }
  }
}
</script>
<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
