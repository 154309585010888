<script>
import VueTypes from 'vue-types'

export default {
  name: 'DialogPaymentDetail',

  props: {
    currencySymbol: VueTypes.string.def('$'),
    paymentDetail: VueTypes.object.isRequired
  },

  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<template lang="pug" src="./DialogPaymentDetail.pug"></template>
