import axios from '@/plugins/http'

export function listChildStore (params) {
  const api = `/api/v1/stores/child-stores`
  return axios.get(api, { params })
}

export function getParentToken (store, params) {
  const api = `/api/v1/stores/${store}/parent-token`
  return axios.get(api)
}

export function unbindChildStore (childStore) {
  const api = `/api/v1/stores/${childStore}/parent-unbind`
  return axios.post(api)
}
