<script>
export default {
  name: 'DialogShippingPoints',
  components: {},
  props: {
    pointsLeft: {
      type: Number,
      default: 0
    },
    orders: {
      type: Number,
      default: 1
    },
    fail: {
      type: Number,
      default: 0
    },
    success: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      title: 'Vue - Project'
    }
  },
  computed: {
    pointsPrice () {
      return this.orders
    }
  },
  methods: {
    handleClose () {
      this.$emit('update:dialogVisible', false)
    },
    buyPoints () {
      this.handleClose()
      this.$emit('buyPoints')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
