<script>
import addMonths from 'date-fns/add_months'
import addDays from 'date-fns/add_days'

import { mapGetters } from 'vuex'
import Pagination from '@/components/Pagination'
import BadgeImage from '@/components/BadgeImage'
import MemberInfo from './components/MemberInfo'
import ApiMember from '@/api/member'
import ApiMemberOrder from '@/api/memberOrder'

import trans from '@/utils/trans'
import getSocialIcon from '@/utils/getSocialIcon'
import getSubscriptionImage from '@/utils/getSubscriptionImage'

import * as ORDER_STATUS from '@/constants/orderStatus'
import * as ORDER_TYPES from '@/constants/orderTypes'

import { formatDate } from '@/filters/index.js'

import emailIcon from '@/assets/icon-social-email@2x.png'

export default {
  name: 'Show',
  components: { Pagination, BadgeImage, MemberInfo },
  data () {
    return {
      emailIcon,

      apiMember: new ApiMember(),
      apiMemberOrder: null,
      member: { pivot: {} },
      orders: [],
      paginator: null,
      editingMemo: false,
      newMemo: null
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'currencySymbol', 'currentStore']),
    normalizedOrders () {
      return this.orders.map(order => {
        return {
          ...order,
          statusName: trans(`order.status.${order.status || 'undefined'}`),
          redStatus: order.status === ORDER_STATUS.UNPAID,
          isSubscription: order.type === ORDER_TYPES.SUBSCRIPTION
        }
      })
    },
    isBlack () {
      return !this.member.pivot.enabled
    },

    hasOwnAccount () {
      return _.get(this.member, 'has_password', false)
    },

    subscription () {
      return this.member && this.member.subscription
    },

    getSubscriptionIcon () {
      if (!this.subscription) return

      return getSubscriptionImage(this.subscription.subscription_frequency)
    },

    subscriptionRange () {
      if (!this.subscription) return

      const subscriptionFrequency = this.subscription.subscription_frequency
      const startAt = this.subscription.plan_started_at
      const endAt = addDays(addMonths(startAt, subscriptionFrequency), -1)

      return `${formatDate(startAt, 'YYYY-MM-DD')} ~ ${formatDate(endAt, 'YYYY-MM-DD')}`
    }
  },

  async created () {
    const id = this.$route.params.member
    this.apiMemberOrder = new ApiMemberOrder(id)
    await this.fetchMember()
    await this.fetchOrders()
  },

  methods: {
    getSocialIcon,

    updateRows (rows) {
      this.orders = rows
    },
    async fetchMember () {
      const id = this.$route.params.member
      this.member = await this.apiMember.get(id)
    },
    async fetchOrders () {
      this.orders = await this.apiMemberOrder.list()
      this.paginator = this.apiMemberOrder.paginator
    },
    startEditMemo () {
      this.newMemo = this.member.pivot.memo
      this.editingMemo = true
    },
    cancelEditMemo () {
      this.editingMemo = false
    },
    async updateMemo () {
      await this.apiMember.updateMember(this.member.slug, {
        memo: this.newMemo
      })
      this.fetchMember()
      this.editingMemo = false
    },

    goToOrder (id) {
      const path = `/${this.storeName}/order/${id}`

      this.$router.push({ path })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
