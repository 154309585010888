/**
 * 處理上傳影片, 圖片
 */
import ApiImage from '@/api/image'

const initialState = () => ({
  isFileUploading: false
})
const state = initialState()

const mutations = {
  setFileUploading (state, payload) {
    state.isFileUploading = payload
  }
}

const actions = {
  async handleImageUpload ({ commit }, image) {
    let imageObj = null
    commit('setFileUploading', true)
    try {
      const apiImage = new ApiImage()
      imageObj = await apiImage.uploadImage({ image })
    } catch (error) {}
    commit('setFileUploading', false)
    return imageObj
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
