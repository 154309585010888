import dateDifferenceInSeconds from 'date-fns/difference_in_seconds'

const getMinutes = ({ day = 0, hour = 0, minute = 0 }) => {
  return day * 1440 + hour * 60 + minute
}

const splitMinutes = (tmpMinute = 0) => {
  let day = 0
  let hour = 0
  let minute = 0

  if (tmpMinute) {
    day = Math.floor(tmpMinute / 1440)
    hour = Math.floor((tmpMinute - day * 1440) / 60)
    minute = tmpMinute - day * 1440 - hour * 60
  }

  return { day, hour, minute }
}

const elapsedTime = (startTime, compareTime) => {
  let hours = 0
  let minutes = 0
  let seconds = 0

  const diffTime = dateDifferenceInSeconds(compareTime, startTime)

  hours = Math.floor(diffTime / (60 * 60)) || 0
  minutes = Math.floor((diffTime - (60 * 60 * hours)) / 60) || 0
  seconds = diffTime % 60 || 0

  const timeStr = [hours, minutes, seconds].reduce((acc, current, index) => {
    if (index === 0 && current === 0) return acc
    return [...acc, (current + '').padStart(2, '0')]
  }, []).join(':')

  return timeStr
}

function secondsToTime (originalSeconds) {
  if (originalSeconds === null) return null

  const time = Number(originalSeconds)

  if (!time) return '00：00：00'

  let hours = Math.floor(time / 3600)
  const leftMinutes = time % 3600
  let minutes = Math.floor(leftMinutes / 60)
  let seconds = leftMinutes % 60

  if (minutes < 10) minutes = `0${minutes}`
  if (hours < 10) hours = `0${hours}`
  if (seconds < 10) seconds = `0${seconds}`

  return `${hours}：${minutes}：${seconds}`
}

function timeToSeconds (timeString, separator) {
  if (!timeString || !separator) return 0

  let step = 3600
  const finalSeconds = timeString.split(separator).map(time => {
    return Number(time) ? Number(time) : 0
  }).reduce((accumulator, current) => {
    const ans = current * step
    step = step / 60
    return ans + accumulator
  }, 0)

  return finalSeconds
}

export { getMinutes, splitMinutes, elapsedTime, secondsToTime, timeToSeconds }
