import * as loadingState from '@/constants/loadingState'
import { fetchFbChannels, fetchBoundChannels } from '../../services/apis'

export const actionTypes = {
  FETCH_CHANNELS: 'FETCH_CHANNELS',
  FETCH_CHANNELS_SUCCESS: 'FETCH_CHANNELS_SUCCESS',
  FETCH_CHANNELS_FAILED: 'FETCH_CHANNELS_FAILED',
  FETCH_BINDED_SUCCESS: 'FETCH_BINDED_SUCCESS',
  FETCH_BINDED_FAILED: 'FETCH_BINDED_FAILED'
}

const state = {
  channels: [],
  bound: [],
  loadingState: loadingState.EMPTY,
  error: null
}

const getters = {
  getChannels: state => {
    const bound = state.bound.map(channel => channel.endpoint)
    return state.channels.map(channel => ({
      bound: bound.includes(channel.id),
      ...channel
    }))
  },
  hasBoundChannel: state => state.bound.length > 1,
  getChannelsLoadingState: state => state.loadingState
}

const mutations = {
  [actionTypes.FETCH_CHANNELS] (state) {
    state.loadingState = loadingState.LOADING
    state.error = null
  },

  [actionTypes.FETCH_CHANNELS_SUCCESS] (state, channels) {
    state.channels = channels
  },

  [actionTypes.FETCH_CHANNELS_FAILED] (state, { err }) {
    state.loadingState = loadingState.ERROR
    state.error = err
    state.channels = []
  },

  [actionTypes.FETCH_BINDED_SUCCESS] (state, channels) {
    state.loadingState = loadingState.READY
    state.bound = channels
  },

  [actionTypes.FETCH_BINDED_FAILED] (state, { err }) {
    state.loadingState = loadingState.ERROR
    state.error = err
    state.bound = []
  }
}

const actions = {
  fetchFbChannels ({ commit, dispatch }, storeName) {
    commit(actionTypes.FETCH_CHANNELS)

    return fetchFbChannels()
      .then(data => {
        const { data: { data: channels } } = data
        commit(actionTypes.FETCH_CHANNELS_SUCCESS, channels)
        dispatch('fetchBoundChannels', storeName)
      })
      .catch(err => commit(actionTypes.FETCH_CHANNELS_FAILED, { err }))
  },

  fetchBoundChannels ({ commit }, storeName) {
    return fetchBoundChannels(storeName)
      .then(res => {
        const { data: { data } } = res
        commit(actionTypes.FETCH_BINDED_SUCCESS, data)
      })
      .catch(err => commit(actionTypes.FETCH_BINDED_FAILED, { err }))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
