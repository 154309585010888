<script>
import { mapGetters, mapActions } from 'vuex'

import AppAlert from '@/components/AppAlert'

import ContainerAccountDetail from './components/ContainerAccountDetail'
import ContainerPaymentRecord from './components/ContainerPaymentRecord'
import DialogPaymentDetail from './components/DialogPaymentDetail'
import DialogCreditCardNotice from './components/DialogCreditCardNotice'
import DialogAtmInfo from './components/DialogAtmInfo'

const TABS = {
  ACCOUNT_DETAIL: 'account',
  PAYMENT_RECORD: 'record'
}

export default {
  name: 'AccountManagement',
  components: {
    AppAlert,
    ContainerAccountDetail,
    ContainerPaymentRecord,
    DialogPaymentDetail,
    DialogCreditCardNotice,
    DialogAtmInfo
  },

  beforeRouteEnter (to, from, next) {
    const { store, tab } = to.params

    if (tab) {
      next()
    } else {
      next({ path: `/${store}/account-management/${TABS.ACCOUNT_DETAIL}` })
    }
  },
  
  data () {
    return {
      TABS,
      activeTab: this.$route.params.tab,
      tabs: [
        {
          label: 'AccountManagement.tabs.account_detail',
          component: 'ContainerAccountDetail',
          name: TABS.ACCOUNT_DETAIL
        },
        {
          label: 'Setting.Service.payment_record',
          component: 'ContainerPaymentRecord',
          name: TABS.PAYMENT_RECORD
        }
      ],

      dialogCreditCardNoticeVisible: false,
      paymentInfo: {
        status: null,
        message: ''
      }
    }
  },

  computed: {
    ...mapGetters('Me', ['currentStore', 'storeName', 'currencySymbol']),
    ...mapGetters('AccountManagement', [
      'paymentDetail',
      'dialogPaymentDetailVisible'
    ]),
    ...mapGetters('AccountManagement', [
      'atmInfo',
      'dialogAtmInfoVisible'
    ]),

    getContainer () {
      const tab = this.tabs.find(tab => tab.name === this.activeTab)
      return tab && tab.component
    }
  },

  mounted () {
    this.checkCreditCardNotice()
  },

  methods: {
    ...mapActions('AccountManagement', ['clearPaymentDetail']),
    ...mapActions('AccountManagement', ['clearAtmInfo']),

    checkCreditCardNotice () {
      const { status = null, message = null } = this.$route.query

      if (!status) return

      this.paymentInfo = { status, message }
      this.dialogCreditCardNoticeVisible = true
    },

    handleChangeTab () {
      this.$router.push({ path: `/${this.storeName}/account-management/${this.activeTab}` })
    }
  }
}
</script>

<template lang="pug" src="./AccountManagement.pug"></template>
