<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FooterCopyright',
  computed: {
    ...mapGetters('Lang', ['locale'])
  }
}
</script>

<template lang="pug">
.footerCopyright
  DropDownLocale.absolute.top-0.right-0(:locale="locale")
  .copyright
    span.mr-1
      | {{ $t('contract.rights', { year: new Date().getFullYear() }) }}
    a.text-gray-700.cursor-pointer(href="/contract_seller" class="hover:text-gray-800") {{ $t("contract.service") }}
</template>

<style lang="scss" scoped>
.footerCopyright {
  position: relative;
  width: 100%;
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
}

.copyright{
  text-align: center;
  color: $vr-light;
  font-size: 14px;
  font-weight: normal;
  @include rwd_xs {
    font-size: h(6);
  }
}
</style>
