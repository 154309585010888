<script>
import store from '@/store'
import { mapGetters, mapActions } from 'vuex'
import FooterCopyright from '@/components/FooterCopyright'
import TogglePanel from './components/TogglePanel'
import SettingTitle from './components/SettingTitle'
import LeaveConfirm from './components/LeaveConfirm'
import SaveConfirm from './components/SaveConfirm'
import PhoneDisplay from './components/PhoneDisplay'

export default {
  name: 'LiveSetting',
  components: { TogglePanel, SettingTitle, LeaveConfirm, SaveConfirm, PhoneDisplay, FooterCopyright },

  beforeRouteLeave (to, from, next) {
    if (this.isChanged) {
      this.updateShowLeaveConfirm(true)
      next(false)
    } else {
      next()
    }
  },
  beforeRouteEnter (to, from, next) {
    if (store.getters['Me/enabledLiveSetting']) {
      next()
    } else {
      next({ name: 'Home' })
    }
  },
  computed: {
    ...mapGetters('LiveSetting', ['isChanged', 'showLeaveConfirm', 'showSaveConfirm'])
  },
  methods: {
    ...mapActions('Me', ['fetchCurrentStore']),
    ...mapActions('LiveSetting', ['updateIsChanged', 'updateShowLeaveConfirm', 'updateShowSaveConfirm', 'saveNewSettings']),
    toPosts () {
      this.updateShowLeaveConfirm(false)
      this.updateIsChanged(false)
      this.$router.push({ name: 'Home' })
    },
    async saveSettings () {
      this.updateShowSaveConfirm(false)
      this.updateIsChanged(false)
      try {
        await this.saveNewSettings()
        this.$message.success({ message: this.$t('message.save_success'), customClass: 'copy-message' })
        this.fetchCurrentStore()
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<template lang="pug" src="./LiveSetting.pug"></template>
