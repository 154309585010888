<script>
import { mapGetters } from 'vuex'

import imageWelcome from './images/img-welcome@2x.png'

export default {
  name: 'Welcome',
  data () {
    return {
      imageWelcome,
      termCheckbox: true
    }
  },
  computed: {
    ...mapGetters('Me', ['apiUrl'])
  },

  methods: {
    handleLogin () {
      const getFacebookAuth = (apiRoute, redirect) => `${apiRoute}/auth/facebook?redirect=${redirect}`
      location.href = getFacebookAuth(this.apiUrl, location.href)
    }
  }
}
</script>

<template lang="pug" src="./Welcome.pug"></template>
<style lang="scss" src="./Welcome.scss" scoped></style>
