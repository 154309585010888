import i18n from '@/plugins/i18n'

const requiredRule = (key) => ([
  {
    required: true,
    message: i18n.t('form.error.required', { msg: key }),
    trigger: 'blur'
  }
])

const phonePatterns = {
  TW: /^[0-9]+$/,
  JP: /^[0-9]{11}$/,
  TH: /^[0-9]+$/,
  VN: /^[0-9]+$/
}

const phonePattern = region => phonePatterns[region] || phonePatterns.TW
const phoneRule = ({ region }) => ([
  { required: true, message: i18n.t('Register.enter_phone'), trigger: 'blur' },
  { pattern: phonePattern(region), message: i18n.t('Register.enter_phone') }
])

function emailRule () {
  return [
    {
      required: true,
      message: i18n.t('form.error.required', { msg: i18n.t('field.email') }),
      trigger: 'blur'
    },
    {
      type: 'email',
      message: i18n.t('Register.email_error'),
      trigger: 'blur'
    }
  ]
}

function passwordLengthValidator (rule, value, cb) {
  const eightLetterNumber = /^([a-zA-Z0-9]{8,})$/

  eightLetterNumber.test(value) ? cb() : cb(new Error())
}

function passwordFormatValidator (rule, value, cb) {
  const hasLetter = /[A-Z]/i
  const hasNumber = /\d/

  hasLetter.test(value) && hasNumber.test(value) ? cb() : cb(new Error())
}

function passwordRule () {
  return [
    {
      required: true,
      message: i18n.t('form.error.required', { msg: i18n.t('field.password') })
    },
    {
      validator: passwordLengthValidator,
      message: i18n.t('field.password.length_error')
    },
    {
      validator: passwordFormatValidator,
      message: i18n.t('field.password.format_error')
    }
  ]
}

export const creditNumberRegex = /[0-9]{16}/
export const monthRegex = /[0-1][0-9]/
export const shortYearRegex = /[0-9]{2}/
export const cvcRegex = /[0-9]{3,4}/

// https://gist.github.com/ShirtlessKirk/2134376
// slightly modified for eslint
export const luhnChk = (function (arr) {
  return function (ccNum) {
    let len = ccNum.length
    let bit = 1
    let sum = 0
    let val

    while (len) {
      val = parseInt(ccNum.charAt(--len), 10)
      bit ^= 1
      sum += bit ? arr[val] : val
    }

    return sum && sum % 10 === 0
  }
}([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]))

export {
  requiredRule,
  phoneRule,
  emailRule,
  passwordRule
}
