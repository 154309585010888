<template>
  <!-- 片段標題 -->
  <el-form
    ref="form"
    :model="form"
    class="flex background-black pt-4 pb-4 pr-3 pl-3"
    :rules="rules"
    @submit.native.prevent="handleSave"
  >
    <el-form-item
      class="form-item"
      prop="clipVideoCoverTitle"
    >
      <template #label>
        <span class="mr-2">{{ $t('ReplayEdit.button.edit_clip_video_cover_title') }}</span>
      </template>
      <el-input
        v-model="form.clipVideoCoverTitle"
        size="25"
        :placeholder="$t('ReplayEdit.text.please_set_title')"
      />
    </el-form-item>
    <div class="form-item justify-items-end">
      <!-- 按鈕 -->
      <el-button @click="$emit('openCoverDialog')">
        {{ $t('ReplayEdit.button.set_clip_video_cover') }}
      </el-button>
      <el-button @click="$emit('cancel')">
        {{ $t('ReplayEdit.button.cancel') }}
      </el-button>
      <el-button
        type="primary"
        :disabled="!form.clipVideoCoverTitle"
        @click="handleSave"
      >
        {{ $t('ReplayEdit.button.save') }}
      </el-button>
    </div>
  </el-form>
</template>
<script>
import { noSpecialCharacter } from '@/constants/regexPattern'

export default {
  name: 'VideoClipToolBar',
  data () {
    return {
      form: {
        clipVideoCoverTitle: ''
      },
      rules: {
        clipVideoCoverTitle: [
          {
            pattern: noSpecialCharacter,
            message: this.$t('ReplayEdit.text.max_title_length'),
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    handleSave () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit('submit', this.form.clipVideoCoverTitle)
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.background-black {
  background: rgba(100, 100, 100,0.5);
}
.form-item {
  margin-bottom: 0;
  display: flex;
  flex: 1;
  align-items: center;

  .el-form-item__content {
    flex: 1;
  }
}
.justify-items-end {
  justify-content: flex-end;
}
.el-form {
  ::v-deep .el-form-item__label{
    color: $white;
    vertical-align: center;
  }
}

</style>
