import axios from '@/plugins/http.js'

export function getCreditCards (params) {
  const api = `/api/v1/credit-cards`

  return axios.get(api, { params })
}

export function postCreditCard (payload) {
  const api = '/api/v1/credit-cards'

  return axios.post(api, payload)
}

export function deleteCreditCard (id) {
  const api = `/api/v1/credit-cards/${id}`

  return axios.delete(api)
}

export function putCreditCard (id, payload) {
  const api = `/api/v1/credit-cards/${id}`

  return axios.put(api, payload)
}
