<script>
import PaginationPure from '@/components/PaginationPure'
import ChildInvite from './components/ChildInvite'
import { listChildStore, unbindChildStore } from './services/apis/ChildStore'

export default {
  name: 'ChildStore',
  components: { ChildInvite, PaginationPure },
  data () {
    return {
      childInviteVisible: false,
      data: [],
      meta: {},
      query: {
        per_page: 6,
        page: 1
      }
    }
  },
  created () {
    this.fetchChildStore()
  },
  methods: {
    async fetchChildStore () {
      try {
        const { data: { data, meta } } = await listChildStore(this.query)
        this.data = data
        this.meta = meta
      } catch (e) {
        console.error(e)
      }
    },
    handleUpdateRows (page) {
      this.query.page = page
      this.fetchChildStore()
    },
    deleteConfirm (childSlug) {
      this.$msgbox({
        title: '',
        center: true,
        customClass: 'childStore-msgbox',
        message: `<h5>${this.$t('child_store.delete_confirm')}</h5>`,
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        confirmButtonText: this.$t('child_store.delete_button')
      })
        .then(() => {
          this.unbindChild(childSlug)
        })
        .catch(() => {})
    },
    async unbindChild (childSlug) {
      try {
        await unbindChildStore(childSlug)
        this.fetchChildStore()
      } catch (e) {
        console.error(e)
      }
    },
    goStore (slug) {
      window.open(`/${slug}`, '_blank')
    }
  }
}
</script>

<template lang='pug' src='./ChildStore.pug'></template>
<style lang='scss' src='./ChildStore.scss' scoped></style>
<style lang="scss">
.childStore-msgbox {
  h5 {
    margin-bottom: 20px;
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 500;
  }
  .el-button--small {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    min-width: 88px;
  }
}

</style>
