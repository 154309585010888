<script>
import { mapGetters } from 'vuex'
import { FXH_KEYS } from '@/constants/common'
import { requiredRule, passwordRule, emailRule } from '@/utils/formRules'
import { FACEBOOK } from '@/constants/provider'
import LayoutCard from '../components/LayoutCard'
import { getParentStore, getInviteStore, register, checkEmail } from '../service/apis'

export default {
  name: 'Register',
  components: { LayoutCard },
  skipMiddleware: ['getAuth', 'checkAuth'],
  data () {
    return {
      loading: false,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password2: ''
      },
      title: 'User.Register.title',
      store: '',
      existedFBAccountMessage: ''
    }
  },
  computed: {
    ...mapGetters('Me', ['apiUrl']),
    ...mapGetters('Lang', ['locale']),
    rules () {
      return {
        first_name: requiredRule(this.$t('field.first_name')),
        last_name: requiredRule(this.$t('field.last_name')),
        email: [
          ...emailRule(),
          { validator: this.emailValidator, trigger: 'blur' }
        ],
        password: passwordRule(),
        password2: [
          { required: true, message: this.$t('field.password2.diff_error') },
          { validator: this.passwordSameValidator, message: this.$t('field.password2.diff_error') }
        ]
      }
    },
    isFromFBBuy () {
      return !!this.$route.query[FXH_KEYS.fxhPayload]
    }
  },
  created () {
    // 如果 fbbuy 商家帳號已在 HU 註冊過
    if (this.isFromFBBuy && this.$route.query[FXH_KEYS.fxhHasStore] === '1') {
      this.existedFBAccountMessage = this.$t('Register.text.fb_account.existed')
    }
    // 記住日本官網方案選擇
    if (this.$route.query.jpPlan) {
      localStorage.setItem('jpPlan', this.$route.query.jpPlan)
      sessionStorage.setItem('gmoShowPlan', true)
    }
    this.handleInvite()
  },
  methods: {
    async submit () {
      try {
        await this.$refs.form.validate()
      } catch {
        return
      }
      this.loading = true
      try {
        const payload = {
          first_name: this.form.first_name,
          last_name: this.form.last_name,
          email: this.form.email,
          password: this.form.password,
          locale: this.locale.replace('-', '_'),
          register_from: 'web_seller'
        }
        const { data: { user_id: userId } } = await register(payload)
        const verifyRoute = {
          name: 'UserEmailVerify',
          query: {
            userId,
            email: this.form.email
          }
        }
        this.$router.push(verifyRoute)
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    fbLogin () {
      let redirectPath = `${this.apiUrl}/auth/facebook?redirect=${location.origin}`
      const payload = this.$route.query[FXH_KEYS.fxhPayload]
      redirectPath = this.isFromFBBuy ? `${redirectPath}&${FXH_KEYS.fxhPayload}=${payload}` : redirectPath
      location.href = redirectPath
    },
    goContract () {
      const url = this.$router.resolve({ name: 'ContractSeller' })
      window.open(url.href + '?lang=' + this.locale, '_blank')
    },
    goLogin () {
      this.$router.push({ name: 'UserLogin' })
    },
    passwordSameValidator (rule, value, cb) {
      value === this.form.password ? cb() : cb(new Error())
    },
    async emailValidator (rule, value, callback) {
      try {
        const { data: { data } } = await checkEmail(value)
        let error

        if (data.email_exists) {
          error = this.$t('field.email.dupe_error')
        }

        const fb = data.sns_email_exists.find(sns => sns === FACEBOOK)
        if (fb) {
          error = this.$t('field.email.sns_exist', { sns: fb })
        } else if (!fb && data.sns_email_exists.length > 0) {
          error = this.$t('field.email.third_party')
        }

        if (error) {
          callback(new Error(error))
        } else {
          callback()
        }
      } catch (e) {
        console.error(e)
        callback(new Error())
      }
    },
    async handleInvite () {
      if (localStorage.getItem('localInvite')) {
        this.handleInviteStore(localStorage.getItem('localInvite'))
      } else if (localStorage.getItem('localParentInvite')) {
        this.handleParentStore(localStorage.getItem('localParentInvite'))
      }
    },
    async handleInviteStore (token) {
      try {
        const { data: { name } } = await getInviteStore(token)
        this.title = 'User.Register.title_assistant'
        this.store = name
      } catch (e) {
        console.error(e)
      }
    },
    async handleParentStore (token) {
      try {
        const { data: { name } } = await getParentStore(token)
        this.title = 'User.Register.title_parent'
        this.store = name
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<template lang="pug" src="./Register.pug"></template>
<style lang="scss" src="./Register.scss" scoped></style>
