import * as loadingState from '@/constants/loadingState'

import { createTicket, updateTicket } from '../apis'

export const actionTypes = {
  CREATE_TICKET: 'CREATE_TICKET',
  CREATE_TICKET_SUCCESS: 'CREATE_TICKET_SUCCESS',
  CREATE_TICKET_FAILED: 'CREATE_TICKET_FAILED'
}

const state = {
  loadingState: loadingState.EMPTY,
  error: null
}

const getters = {
  createTicketLoadingState: state => state.loadingState
}

const mutations = {
  [actionTypes.CREATE_TICKET] (state) {
    state.loadingState = loadingState.LOADING
  },

  [actionTypes.CREATE_TICKET_SUCCESS] (state, payload) {
    state.loadingState = loadingState.READY
  },

  [actionTypes.CREATE_TICKET_FAILED] (state, error) {
    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  }
}

const actions = {
  createOrUpdateTicket ({ commit }, { id, storeId, form, isEdit }) {
    commit(actionTypes.CREATE_TICKET)

    const action = isEdit ? updateTicket : createTicket

    return action({ storeId, form, id })
      .then(response => commit(actionTypes.CREATE_TICKET_SUCCESS, response))
      .catch(err => {
        commit(actionTypes.CREATE_TICKET_FAILED, err)
        throw new Error()
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
