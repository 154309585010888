<script>
import { mapGetters, mapActions } from 'vuex'
import { format, startOfMonth, subMonths } from 'date-fns'
import { saleStats } from '../../services/apis/saleStats'

export default {
  name: 'Metrics',
  data () {
    return {
      orderAmount: 0,
      orderQuantity: 0,
      paidAmount: 0,
      orderAmountForMonth: 0,
      undelivered: 0,
      orderMom: '-'
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol', 'storeName', 'isEnterprise', 'childStores'])
  },
  mounted () {
    this.fetchStats()
  },
  methods: {
    ...mapActions('OrderList', ['updateOrderPageTab']),
    goShipping () {
      this.updateOrderPageTab('undelivered')
      this.$router.push({ name: 'OrderList' })
    },

    async fetchStats () {
      await Promise.all([
        this.fetchCurrent(),
        this.fetchUndelivered()
      ])
      // Last month need current month data
      await this.fetchLastMonth()
    },

    async fetchCurrent () {
      // Fetch today and this month
      const today = new Date()
      try {
        const params = {
          from: format(startOfMonth(today), 'YYYY-MM-DD'),
          to: format(today, 'YYYY-MM-DD'),
          filter: ['order_amounts', 'order_quantities', 'paid_amounts'].join(','),
          with_children: this.childStores.length > 0
        }
        const { data: { data } } = await saleStats(this.storeName, params)
        this.orderAmount = data.order_amounts[params.to]
        this.orderQuantity = data.order_quantities[params.to]
        this.paidAmount = data.paid_amounts[params.to]
        this.orderAmountForMonth = data.order_amounts_sum
      } catch (e) {
        console.error(e)
      }
    },

    async fetchLastMonth () {
      // Fetch last month and calculate MoM
      // 顯示環比 (MOM)：(本期 - 上期) /上期 × 100%
      const today = new Date()
      try {
        const lastMonth = {
          from: format(subMonths(startOfMonth(today), 1), 'YYYY-MM-DD'),
          to: format(subMonths(today, 1), 'YYYY-MM-DD'),
          filter: ['order_amounts'].join(','),
          sum_only: true,
          with_children: this.childStores.length > 0
        }
        const { data: { data: lastMonthData } } = await saleStats(this.storeName, lastMonth)

        if (lastMonthData.order_amounts_sum > 0) {
          const mom = (this.orderAmountForMonth - lastMonthData.order_amounts_sum) / lastMonthData.order_amounts_sum * 100
          this.orderMom = Number.isFinite(mom) ? mom.toFixed(1) : '-'
        }
      } catch (e) {
        console.error(e)
      }
    },

    async fetchUndelivered () {
      // 待出貨訂單
      try {
        const undelivered = {
          sum_only: true,
          filter: 'order_undelivered_quantities',
          all: true,
          with_children: this.childStores.length > 0
        }
        const { data: undeliveredData } = await saleStats(this.storeName, undelivered)
        this.undelivered = undeliveredData?.data?.['order_undelivered_quantities_sum']
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<template lang="pug" src="./Metrics.pug"></template>
<style lang="scss" src="./Metrics.scss" scoped></style>
