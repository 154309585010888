<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'
import * as CONST_CHANNEL_TYPE from '@/constants/channelType'
import { BASE_IMAGE_PATH } from '@/constants/path'

import allImage from '@/assets/icon-default-shop-all.png'
import facebookImage from '@/assets/icon-channel-facebook.png'
import authorizeImage from '@/assets/icon-channel-authorize.png'
import handsupImage from '@/assets/icon-channel-handsup.png'
import celebrityImage from '@/assets/icon-channel-celebrity.png'
import m17Image from '@/assets/icon-channel-m17.png'

/**
 * 這邊不抽出去全域的 channel type 是因為這邊只是首頁 filter 的銷售管道分類
 * 不是真正的直播可以打出去的管道
 */
const CHANNEL_TYPE = {
  ...CONST_CHANNEL_TYPE,
  'ALL': 'all',
  'HANDSUP': 'handsup',
  'FB_PAGE': 'fb_page',
  'CELEBRITY': 'celebrity',
  'POST_AGENT': 'postAgent',
  'M17_LIVE': '17live'
}

const channelImageMappings = {
  [CHANNEL_TYPE.ALL]: allImage,
  [CHANNEL_TYPE.HANDSUP]: handsupImage,
  [CHANNEL_TYPE.FB_PAGE]: facebookImage,
  [CHANNEL_TYPE.CELEBRITY]: celebrityImage,
  [CHANNEL_TYPE.POST_AGENT]: authorizeImage,
  [CHANNEL_TYPE.M17_LIVE]: m17Image,
  [CHANNEL_TYPE.M17_LIVE_V_2]: `${BASE_IMAGE_PATH}/icons/20211004_17LIVE_icon.png`
}

export default {
  name: 'ChannelList',
  props: {
    channels: VueTypes.array.isRequired,
    searchForm: VueTypes.shape({
      channel: VueTypes.oneOfType([null, Number])
    }).loose
  },

  computed: {
    ...mapGetters('Me', ['enabledCelebrity', 'currentStore']),

    channelOptions () {
      // TODO: celebrity 和 agent 可以抽出去優化
      return [
        {
          value: '',
          title: this.$t('Home.ChannelList.all_sales_pipeline'),
          subtitle: null,
          image_url: allImage
        },
        ...this.normalizeChannel(this.channels),
        ...this.getFeaturesChannels()
      ]
    },

    selectedChannel () {
      return this.channelOptions.find(channel => {
        return channel.value === this.searchForm.channel
      })
    }
  },
  methods: {
    normalizeChannel (channels) {
      return channels.map(channel => ({
        ...channel,
        title: this.getChannelTitle(channel),
        subtitle: this.$t(`channel.type.${channel.type}`),
        value: channel.id,
        image_url: channelImageMappings[channel.type]
      })
      )
    },

    getFeaturesChannels () {
      // 針對網紅授權需要檢查開關是否打開
      const featuresChannels = {
        [CHANNEL_TYPE.POST_AGENT]: this.enabledCelebrity
      }

      return Object.keys(featuresChannels)
        // 把開關沒開的 filter 掉
        .filter(key => featuresChannels[key])
        .map(channelType =>
          ({
            value: channelType,
            title: this.$t(`Home.ChannelList.${channelType}`),
            subtitle: this.$t(`channel.type.${channelType}`),
            image_url: channelImageMappings[channelType]
          })
        )
    },

    getChannelTitle ({ type, name }) {
      if (type === CHANNEL_TYPE.HANDSUP) {
        return this.currentStore.name
      }
      if (type === CHANNEL_TYPE.M17_LIVE) {
        return this.$t('Home.ChannelList.17live')
      }
      return name
    }
  }
}
</script>

<template lang="pug" src="./ChannelList.pug"></template>
<style lang="scss" src="./ChannelList.scss" scoped></style>
