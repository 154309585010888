<script>
import VueTypes from 'vue-types'
import ApiPost from '@/api/post'
import VideoUpload from '@/components/VideoUpload'

export default {
  name: 'DialogSetLiveTime',
  components: {
    VideoUpload
  },
  props: {
    post: VueTypes.object
  },

  data () {
    const validateUpcoming = (rule, value, callback) => {
      if (this.newVideoId && !value) {
        return callback(new Error(this.$t('Post.PushNotification.error.set_upcoming')))
      }
      callback()
    }

    return {
      isBtnLoading: false,

      pickerOptions: {
        disabledDate: (time) => {
          const now = new Date()
          const yesterday = now.setDate(now.getDate() - 1)

          return time.getTime() < yesterday
        }
      },

      liveForm: {
        upcoming_at: null
      },

      rules: {
        upcoming_at: [
          { validator: validateUpcoming, trigger: 'blur' }
        ]
      },
      newVideoId: null,
      isVideoUploading: false
    }
  },
  
  created () {
    this.initData()
  },

  methods: {
    initData () {
      this.liveForm.upcoming_at = this.post.upcoming_at
        ? new Date(this.post.upcoming_at)
        : null
      this.newVideoId = this.post.preview_video?.id
    },

    async updatePost () {
      const apiPost = new ApiPost()

      const params = {
        upcoming_at: this.liveForm.upcoming_at
          ? new Date(this.liveForm.upcoming_at).toISOString()
          : null,
        preview_video_id: this.newVideoId !== null ? this.newVideoId : undefined
      }

      const result = await apiPost.update(this.post.id, params)

      return result
    },

    handleCloseDialog () {
      this.$emit('close')
      this.$emit('update:dialogVisible', false)
    },

    async handleSubmit () {
      if (this.isBtnLoading || this.isVideoUploading) return
      try {
        await this.$refs.liveForm.validate()
      } catch (e) {
        this.isBtnLoading = false
        return
      }

      this.isBtnLoading = true
      if (await this.updatePost()) {
        this.$emit('reFetch')
      }
      this.isBtnLoading = false

      this.handleCloseDialog()
    },
    updateVideoId (videoId) {
      this.newVideoId = videoId
    }
  }
}
</script>

<template lang="pug" src="./DialogSetLiveTime.pug"></template>
