<script>
import VueTypes from 'vue-types'

import imgTicket from './icon-ticket-sm@2x.png'

export default {
  name: 'TicketBadge',

  props: {
    name: VueTypes.string.def(null)
  },

  data: () => ({
    imgTicket
  })
}
</script>

<template lang="pug" src="./TicketBadge.pug"></template>
<style lang="scss" src="./TicketBadge.scss" scoped></style>
