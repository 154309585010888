import ApiPaymentSetting from '@/api/paymentSetting'
import { OFFLINE_ATM, NEWEBPAY_CREDIT } from '@/constants/paymentTypes'

export const actionTypes = {
  UPDATE_PAYMENT_METHOD: 'UPDATE_PAYMENT_METHOD',
  UPDATE_PAYMENT_SETTING: 'UPDATE_PAYMENT_SETTING',
  RESET: 'RESET',
  RESTORE_CACHE: 'RESTORE_CACHE'
}

const INIT_DATA = {
  selectedPayment: '',
  sortedPaymentSettings: []
}

const state = _.cloneDeep(INIT_DATA)

const mutations = {
  [actionTypes.UPDATE_PAYMENT_METHOD] (state, payload) {
    state.selectedPayment = payload
  },
  [actionTypes.UPDATE_PAYMENT_SETTING] (state, payload) {
    state.sortedPaymentSettings = [...payload]
  },
  [actionTypes.RESET] (state) {
    Object.assign(state, INIT_DATA)
  },
  [actionTypes.RESTORE_CACHE] (state, payload = {}) {
    state.selectedPayment = payload.selectedPayment
  }
}

const actions = {
  updatePaymentMethod ({ commit }, payload) {
    commit(actionTypes.UPDATE_PAYMENT_METHOD, payload)
  },
  async getAvailablePaymentMethods ({ commit }) {
    const apiPaymentSetting = new ApiPaymentSetting()
    const settings = await apiPaymentSetting.list({}, true)

    // 順序須為 信用卡付款, ATM虛擬帳號匯款, 銀行匯款
    const sortedSetting = settings.filter(payment => payment.enabled).sort((a, b) => {
      if (a.type === NEWEBPAY_CREDIT || b.type === OFFLINE_ATM) {
        return -1
      }
      if (b.type === NEWEBPAY_CREDIT || a.type === OFFLINE_ATM) {
        return 1
      }
      return 0
    })
    commit(actionTypes.UPDATE_PAYMENT_SETTING, sortedSetting)
    if (!state.selectedPayment && sortedSetting.length) {
      commit(actionTypes.UPDATE_PAYMENT_METHOD, sortedSetting[0].type)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
