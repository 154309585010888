<script>
import { mapGetters, mapActions } from 'vuex'
import ApiImage from '@/api/image'
import draggable from 'vuedraggable'

const FILE_LIMIT = 9

export default {
  name: 'ProductFormImageSortable',
  components: {
    draggable
  },
  data () {
    return {
      FILE_LIMIT,
      imageProgressList: [],
      isDeleting: false
    }
  },
  computed: {
    ...mapGetters('Me', ['token', 'imageUploadUrl']),
    ...mapGetters('ProductDetail', { imageFileList: 'images' }),

    uploadHeaders () {
      return { Authorization: `Bearer ${this.token}` }
    },
    isFileUpperLimit () {
      return this.images.length >= FILE_LIMIT
    },
    images: {
      get () {
        return this.imageFileList
      },
      set (val) {
        this.addNewImage(val)
      }
    },
    sortedImageList: {
      get () {
        return this.imageFileList
      },
      set (sortedImageList) {
        this.updateImages(sortedImageList)
      }
    }

  },

  methods: {
    ...mapActions('ProductDetail', ['updateImages', 'addNewImage', 'removeImage']),

    handleBeforeUpload (file) {
      const fileMB = file.size / (1024 ** 2)
      if (fileMB > 4) {
        this.$message.error(this.$t('Product.DialogAddProduct.error_upload_file'))
        return false
      } else {
        this.imageProgressList.push('loading')
      }
    },
    async handleFileRemove (file) {
      this.isDeleting = true
      const imageId = file.id

      if (!imageId) return

      const apiImage = new ApiImage()
      const success = await apiImage.delete(imageId)

      if (success) this.removeImage(imageId)
      this.isDeleting = false
    },

    handleFileExceed (files, images) {
      this.$message.error(this.$t('Product.DialogAddProduct.image_limit_4', { fileLimit: FILE_LIMIT }))
    },

    handleFileSuccess (response) {
      this.images = response
      this.imageProgressList.pop()
    },

    handleFileError (err) {
      this.$message.error(err)
      this.imageProgressList.pop()
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang='scss' src='./style.scss' scoped></style>
