import * as loadingState from '@/constants/loadingState'

import { fetchBillItems } from '../apis/bill'

export const types = {
  FETCH_PAYMENT_LIST: 'FETCH_PAYMENT_LIST',
  FETCH_PAYMENT_LIST_SUCCESS: 'FETCH_PAYMENT_LIST_SUCCESS',
  FETCH_PAYMENT_LIST_FAILED: 'FETCH_PAYMENT_LIST_FAILED',

  UPDATE_PAYMENT_LIST_SEARCH: 'UPDATE_PAYMENT_LIST_SEARCH'
}

const INIT_DATA = {
  data: [],
  meta: null,
  links: null,

  search: {
    date: null,
    page: 1,
    per_page: 15
  },

  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  paymentListData: state => state.data,
  paymentListMeta: state => state.meta,
  paymentListLinks: state => state.links,

  paymentListSearch: state => state.search,

  paymentListLoadingState: state => state.loadingState
}

const mutations = {
  [types.FETCH_PAYMENT_LIST] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.FETCH_PAYMENT_LIST_SUCCESS] (state, response) {
    state.loadingState = loadingState.READY

    const { data, links, meta } = response.data

    state.data = data
    state.meta = meta
    state.links = links
  },

  [types.FETCH_PAYMENT_LIST_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  },

  [types.UPDATE_PAYMENT_LIST_SEARCH] (state, payload) {
    Object.assign(state.search, payload)
  }

}

const actions = {
  fetchPaymentList ({ state, commit }, storeId) {
    commit(types.FETCH_PAYMENT_LIST)

    const params = state.search

    return fetchBillItems(storeId, params)
      .then(response => commit(types.FETCH_PAYMENT_LIST_SUCCESS, response))
      .catch(err => {
        commit(types.FETCH_PAYMENT_LIST_FAILED, err)
        throw err
      })
  },

  updatePaymentListSearch ({ state, commit }, payload) {
    const page = payload.page || 1

    const search = {
      ...state.search,
      ...payload,
      page
    }

    commit(types.UPDATE_PAYMENT_LIST_SEARCH, search)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
