<script>
import imgQrcode from '../images/qr-code@2x.png'

export default {
  render: (h) => (
    <div>
      <h5 class="mb-4 font-medium" style={{ color: '#4a4a4a' }}>
        掃QR Code下載安裝APP
      </h5>
      <img src={ imgQrcode } style={{ width: '204px' }}>
      </img>
    </div>
  )
}
</script>
