<script>
import { mapGetters, mapActions } from 'vuex'

import LayoutOneBlock from '@/layouts/LayoutOneBlock'
import { LOADING, READY } from '@/constants/loadingState'
import AppSteps from '@/components/AppSteps'
import Oops from '@/components/Oops'
import handsupLogo from '@/assets/handsup-logo.svg'

import { STEPS_INIT_STATE, STEP_ONE, STEP_TWO, STEP_THREE } from './constants/steps'
import Welcome from './components/Welcome'
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3 from './components/Step3'
import Done from './components/Done'

const STEP_MAPS = {
  [STEP_ONE]: Step1,
  [STEP_TWO]: Step2,
  [STEP_THREE]: Step3
}

export default {
  name: 'RegisterInfluencer',

  skipMiddleware: ['getAuth', 'checkAuth'],

  metaInfo () {
    return {
      meta: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0'
      }]
    }
  },

  components: {
    LayoutOneBlock,
    AppSteps,
    Welcome,
    Step1,
    Step2,
    Step3,
    Done,
    Oops
  },

  data: () => ({
    loading: false,
    handsupLogo,
    LOADING,
    READY,
    steps: [
      ...STEPS_INIT_STATE
    ],
    activeStep: STEP_ONE,
    isMobile: screen.width < 768,
    // 是否已經有網紅身份
    isDone: false
  }),

  computed: {
    ...mapGetters('Me', [
      'isLogin',
      'name',
      'hasStore',
      'hasCelebrity'
    ]),
    ...mapGetters('Register', [
      'checkTokenLoadingState',
      'isTokenValid'
    ]),

    stepComponent () {
      return STEP_MAPS[this.activeStep]
    }
  },

  created () {
    this.checkToken(this.$route.query.token)

    // 透過 auth api redirect 回來的網址會帶上 `#_=_`
    // 用這個判斷已登入
    if (location.hash === '#_=_') {
      this.checkDone()
    }
  },

  methods: {
    ...mapActions('Me', [
      'fetchMe'
    ]),
    ...mapActions('Register', ['checkToken']),

    handleNextStep (step) {
      this.activeStep = step
    },

    async checkDone () {
      try {
        this.loading = true
        // @todo 之後我一定會來改這裡 要直接改 me 的 module
        // 並加上 loadingstate
        await this.fetchMe()
      } catch {}
      this.loading = false
      // 是否有網紅身份來判斷是否註冊完成
      this.isDone = this.hasCelebrity
    }
  }
}
</script>

<template lang="pug" src="./RegisterInfluencer.pug"></template>
<style lang="scss" src="./RegisterInfluencer.scss" scoped></style>
