<script>
import { mapGetters } from 'vuex'
import ApiStore from '@/api/store'
import '@/utils/copyText'

export default {
  name: 'AccountInvite',
  data () {
    return {
      token: null
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName']),
    inviteUrl () {
      return this.token
        ? `${location.origin}/invite/${this.token}`
        : this.$t('Setting.AccountInvite.getting')
    }
  },
  created () {
    this.fetchInviteToken()
  },
  methods: {
    async fetchInviteToken () {
      const apiStore = new ApiStore()
      const data = await apiStore.inviteToken()

      if (!data) {
        this.token = null
        return
      }

      const { token } = data

      this.token = token
    },
    copyLink () {
      this.copyText({
        url: this.inviteUrl
      })
    },
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
