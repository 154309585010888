import ApiProduct from '@/api/product'
import * as loadingState from '@/constants/loadingState'

const apiProduct = new ApiProduct()

export const actionTypes = {
  GET_DEFAULT_KEYWORD: 'GET_DEFAULT_KEYWORD',
  GET_DEFAULT_KEYWORD_SUCCESS: 'GET_DEFAULT_KEYWORD_SUCCESS',
  GET_DEFAULT_KEYWORD_FAILED: 'GET_DEFAULT_KEYWORD_FAILED'
}

const state = {
  loadingState: loadingState.EMPTY,
  keyword: '',
  error: null
}

const getters = {
  getDefaultKeywordLoadingState: state => state.loadingState,
  defaultKeyword: state => state.keyword
}

const mutations = {
  [actionTypes.GET_DEFAULT_KEYWORD] (state) {
    state.loadingState = loadingState.LOADING
  },
  [actionTypes.GET_DEFAULT_KEYWORD_SUCCESS] (state, keyword) {
    state.loadingState = loadingState.READY
    state.keyword = keyword
  },
  [actionTypes.GET_DEFAULT_KEYWORD_FAILED] (state, error) {
    state.loadingState = loadingState.ERROR
    state.error = error
  }
}

const actions = {
  async getDefaultKeyword ({ commit, rootGetters }) {
    commit(actionTypes.GET_DEFAULT_KEYWORD)
    try {
      const { data: { default_keyword: keyword } } = await apiProduct.getDefaultKeyword(rootGetters['Me/storeName'])
      commit(actionTypes.GET_DEFAULT_KEYWORD_SUCCESS, keyword)
    } catch (e) {
      commit(actionTypes.GET_DEFAULT_KEYWORD_FAILED)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
