import axios from '@/plugins/http.js'
import BaseApi from './baseApi'
import storeId from './_storeId'

export default class ShippingSetting extends BaseApi {
  uri = `/api/v1/stores/${storeId()}/carrier-setting`

  checkLabel = async type => {
    try {
      const api = `/api/v1/stores/${storeId()}/carrier-setting/${type}/check-label`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }
}
