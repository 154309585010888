import Vue from 'vue'
import Vuex from 'vuex'

import AccountManagement from '@/views/AccountManagement/services/store'
import OrderDetail from '@/views/Order/Show/services/store'
import OrderManual from '@/views/OrderManual/services/store'
import BatchApprove from '@/views/Order/BatchApprove/services/store'
import ChatMessage from '@/views/Post/services/store/ChatMessage'
import Post from '@/views/Post/services/store/Post'
import PostCreate from '@/views/Post/views/Create/services/store'
import RegisterInfluencer from '@/views/RegisterInfluencer/services/store'
import Ticket from '@/views/Ticket/services/store'
import Coupon from '@/views/Coupon/services/store'
import Voucher from '@/views/Voucher/services/store'
import Subscription from '@/views/Subscription/services/store'
import ProductList from '@/views/ProductList/services/store'
import ProductDetail from '@/views/ProductDetail/services/store'
import LiveSetting from '@/views/LiveSetting/services/store'
import ApiSetting from '@/views/Setting/Api/services/store'
import Receipt from '@/views/Setting/Plan/services/Receipt'
import ReplayEdit from '@/views/Post/views/ReplayEdit/services/store'

import AdditionalService from './modules/AdditionalService'
import Lang from './modules/Lang'
import Me from './modules/Me'
import OrderList from './modules/OrderList'
import Plan from './modules/Plan'
import Pubnub from './modules/Pubnub'
import SettingsChecked from './modules/SettingsChecked'
import Register from './modules/Register'
import Uploader from './modules/Uploader'

Vue.use(Vuex)

const state = {
  isLoading: false,
  routerKey: +new Date()
}

const getters = {}
const mutations = {
  SET_LOADING (state, value) {
    state.isLoading = value
  },
  SET_ROUTER_KEY (state, value) {
    state.routerKey = value
  }
}

const actions = {
  setLoading ({ commit }, value) {
    commit('SET_LOADING', value)
  },
  updateRouterKey ({ commit }) {
    commit('SET_ROUTER_KEY', +new Date())
  }
}

const store = new Vuex.Store({
  strict: true, // 嚴格模式
  state,
  getters,
  actions,
  mutations,
  modules: {
    AccountManagement,
    Coupon,
    Me,
    Post,
    ProductList,
    ProductDetail,
    AdditionalService,
    Lang,
    OrderList,
    OrderDetail,
    OrderManual,
    Plan,
    SettingsChecked,
    Pubnub,
    ChatMessage,
    Register,
    BatchApprove,
    RegisterInfluencer,
    Ticket,
    PostCreate,
    Voucher,
    Subscription,
    LiveSetting,
    ApiSetting,
    Receipt,
    ReplayEdit,
    Uploader
  }
})

export default store
