import Vue from 'vue'
import store from '@/store'
import Meta from 'vue-meta'
import routes from './routes'
import Router from 'vue-router'
import { sync } from 'vuex-router-sync'

Vue.use(Meta)
Vue.use(Router)

// The middleware for every page of the application.
// 每頁必執行的 middleware
const globalMiddleware = ['getAuth', 'getStore', 'checkAuth', 'checkPlan', 'locale']

// Load middleware modules dynamically.
// 取出 middleware 資料夾裡所有的 js 檔
const routeMiddleware = resolveMiddleware(
  require.context('@/middleware', false, /.*\.js$/)
)

const router = createRouter()

sync(store, router)

export default router

/**
 * Create a new router instance.
 *
 * @return {Router}
 */
function createRouter () {
  const router = new Router({
    scrollBehavior,
    mode: 'history',
    routes
  })

  router.beforeEach(beforeEach)
  router.afterEach(afterEach)

  return router
}

/**
 * Global router guard.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function beforeEach (to, from, next) {
  // Get the matched components and resolve them.

  // 取得前往路由之組件
  const components = await resolveComponents(
    router.getMatchedComponents({ ...to })
  )

  if (components.length === 0) {
    return next()
  }

  // Get the middleware for all the matched components.
  const middleware = getMiddleware(components)

  // Call each middleware.
  callMiddleware(middleware, to, from, next)
}

/**
 * Global after hook.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function afterEach (to, from, next) {
  await router.app.$nextTick()
  // store.dispatch('setLoading', false)

  // Google Tag Manager
  pageUserTrack()
}

/**
 * Call each middleware.
 *
 * @param {Array} middleware
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
function callMiddleware (middleware, to, from, next) {
  const stack = middleware.reverse()

  const _next = (...args) => {
    // Stop if "_next" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      if (args.length > 0) {
        store.dispatch('setLoading', false)
      }

      // 參數 可接受形態為
      // next() → 無參數，進行管道中的下一個hook
      // next(false) → 中斷當前的導航，重置到from路由對應的路徑。
      // next({ path: '/' }) → 當前的導航被中斷, 跳轉到新路徑，然後重新對新路徑進行 middleware。
      return next(...args)
    }

    const middleware = stack.pop()

    if (typeof middleware === 'function') {
      middleware(to, from, _next)
    } else if (routeMiddleware[middleware]) {
      routeMiddleware[middleware](to, from, _next)
    } else {
      throw Error(`Undefined middleware [${middleware}]`)
    }
  }

  _next()
}

/**
 * Resolve async components.
 *
 * @param  {Array} components
 * @return {Array}
 */
async function resolveComponents (components) {
  const results = await Promise.all(
    components.map(async component => {
      const result =
        typeof component === 'function' ? await component() : component

      return result
    })
  )

  return results
}

/**
 * Merge the the global middleware with the components middleware.
 *
 * @param  {Array} components
 * @return {Array}
 */
function getMiddleware (components) {
  const middleware = [...globalMiddleware]
  const skipMiddleware = []

  // 要驗證的 middleware
  components
    .filter(c => c.middleware)
    .forEach(component => {
      if (Array.isArray(component.middleware)) {
        middleware.push(...component.middleware)
      } else {
        middleware.push(component.middleware)
      }
    })

  // 排除驗證的 middleware
  components
    .filter(c => c.skipMiddleware)
    .forEach(component => {
      if (Array.isArray(component.skipMiddleware)) {
        skipMiddleware.push(...component.skipMiddleware)
      } else {
        skipMiddleware.push(component.skipMiddleware)
      }
    })

  // 過濾不驗證的 middleware
  const newMiddleware = middleware.filter(
    item => !skipMiddleware.includes(item)
  )

  return newMiddleware
}

/**
 * Scroll Behavior
 *
 * @link https://router.vuejs.org/en/advanced/scroll-behavior.html
 *
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
function scrollBehavior (to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition
  }

  if (to.hash) {
    return { selector: to.hash }
  }

  const [component] = router.getMatchedComponents({ ...to }).slice(-1)

  if (component && component.scrollToTop === false) {
    return {}
  }

  return { x: 0, y: 0 }
}

/**
 * @param  {Object} requireContext
 * @return {Object}
 */
function resolveMiddleware (requireContext) {
  return requireContext
    .keys()
    .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
    .reduce(
      (guards, [name, guard]) => ({ ...guards, [name]: guard.default }),
      {}
    )
}

/**
 * @description 紀錄使用者軌跡
 * @param {String} trackedTitle 頁面標題
 */
const pageUserTrack = async ({ trackedTitle = '' } = {}) => {
  // 從 vue router 實體中取的目前路由 meta 資訊
  const { meta } = router.currentRoute

  // 以傳入此方法的 trackedTitle 為主，若無則使用定義在 router 上的 meta 資訊
  const pageTitle = trackedTitle || meta.trackedTitle
  // console.log('pageTitle', pageTitle)

  // 若無傳入參數或當下路由無 mate 資訊時會略過軌跡紀錄
  if (Vue.gtm && pageTitle) {
    // 發送 content-view 事件給 GTM
    Vue.gtm.trackEvent({
      // 自行定義的事件名稱
      event: 'content-view',
      // 頁面功能/分頁標題文字
      pageTitle: pageTitle
    })
  }
}
