<script>
export default {
  name: 'StepsBar',
  props: {
    steps: {
      type: Array
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
