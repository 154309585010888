<script>
import { mapGetters, mapActions } from 'vuex'

import Ready from '../Ready'
import Published from '../Published'

export default {
  name: 'Show',
  components: { Ready, Published },

  computed: {
    ...mapGetters('Me', ['stores', 'storeName', 'isEnterpriseGeneric']),
    ...mapGetters('Post', ['postId', 'postAgent', 'post', 'liveStatus'])
  },

  async created () {
    this.initData()
  },

  methods: {
    ...mapActions('Post', [
      'updateCelebrityToken',
      'updatePostId',
      'fetchPostAgent',
      'fetchPost',
      'checkCelebrityData'
    ]),

    async getRealPostId () {
      let postId = this.$route.params.post

      // 網紅授權直播
      if (this.$route.params.celebritytoken) {
        const celebrityToken = this.$route.params.celebritytoken

        this.updateCelebrityToken(celebrityToken)

        try {
          postId = await this.fetchPostAgent()
          this.checkCelebrityData(this.stores)
        } catch (error) {
          console.error(error)
          this.$router.push('/403')
          return null
        }
      }

      return postId
    },

    async initData () {
      // 進直播貼文有二種路由
      // 賣家：/:store/posts/:post
      // 網紅授權：/postagent/:celebritytoken
      // 若網紅進來的話，先取得真正貼文的 postId
      const postId = await this.getRealPostId()

      if (!postId) return

      // 更新 PostId
      this.updatePostId(postId)
      // 取得貼文資料
      this.fetchPost().catch(() => {
        this.$router.push(`/${this.storeName}/`)
      })
    },

    copyLink () {
      // 企業通用版 特別新增 openExternalBrowser，
      // 主要是 FBBuy 登入也是用 SDK 會造成白畫面
      const copyUrl = this.isEnterpriseGeneric
        ? `${this.$VUE_APP_BUYER_DOMAIN_NAME}/${this.storeName}/live/${this.postId}?openExternalBrowser=1`
        : `${this.$VUE_APP_BUYER_DOMAIN_NAME}/${this.storeName}/live/${this.postId}`

      this.copyText({
        url: copyUrl,
        success: this.$t('Post.Show.message.copy_link'),
        error: this.$t('Post.Show.message.copy_fail')
      })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
