<script>
import PubNub from 'pubnub'
import { mapActions, mapGetters } from 'vuex'

import ApiPost from '@/api/post'
import ApiChannel from '@/api/channel'
import { getMinutes, splitMinutes } from '@/utils/timeOperation'
import { LOADING } from '@/constants/loadingState'
import SimpleDialog from '@/components/SimpleDialog'
import VideoUpload from '@/components/VideoUpload'
import ImageUploader from '@/components/ImageUploader'
import DialogAppDownload from '../DialogAppDownload'
import DialogLiveLater from '../DialogLiveLater'

import iconFacebook from '@/assets/icon-channel-facebook.png'
import iconHandsup from '@/assets/icon-channel-handsup.png'
import iconAuthorize from '@/assets/icon-channel-authorize.png'

import imgLiveNotice from './images/img-live-notice.png'

const LIVEMODE = {
  FB: 'fb',
  APP: 'app',
  OBS: 'obs',
  POST_AGENT: 'postAgent'
}

export default {
  name: 'PushNotification',
  components: { SimpleDialog, DialogAppDownload, DialogLiveLater, VideoUpload,ImageUploader },
  props: {
    post: {
      type: Object,
      default: null
    },
    products: {
      type: Array,
      required: true
    }
  },
  data () {
    const validateUpcoming = (rule, value, callback) => {
      if (!value && this.newVideoId) {
        return callback(new Error(this.$t('Post.PushNotification.error.set_upcoming')))
      }
      callback()
    }

    const validatelimitForm = (rule, value, callback) => {
      // 無限制
      if (!this.limitForm.hasLimit) {
        callback()
        return
      }

      if (this.limitForm.day || this.limitForm.hour || this.limitForm.minute) {
        callback()
      } else {
        callback(new Error(this.$t('Post.PushNotification.checkout_rule')))
      }
    }

    return {
      LOADING,
      iconFacebook,
      iconHandsup,
      iconAuthorize,
      imgLiveNotice,
      ...LIVEMODE,
      pickerOptions: {
        disabledDate: (time) => {
          const now = new Date()
          const yesterday = now.setDate(now.getDate() - 1)

          return time.getTime() < yesterday
        }
      },
      channelGroup: null,
      apiPost: new ApiPost(),
      apiChannel: new ApiChannel(),
      myChannels: [],
      coverImage: null,
      isObs: false,
      liveForm: {
        title: null,
        mode: null,
        channel_ids: null,
        target: null,
        content: null,
        showSetLivePreview: false,
        upcoming_at: null,
        cart_item_life: null
      },

      ticketForm: {
        isPrivate: false,
        ticket_id: null
      },

      limitForm: {
        hasLimit: false,
        day: 3,
        hour: 0,
        minute: 0
      },

      rules: {
        title: [
          { required: true, message: this.$t('Post.PushNotification.enter_title'), trigger: 'blur' }
        ],
        mode: [
          { required: true, message: this.$t('Post.PushNotification.enter_mode'), trigger: 'change' }
        ],
        channel_ids: [
          { required: true, message: this.$t('Post.PushNotification.enter_channel_id'), trigger: 'change' }
        ],
        upcoming_at: [
          { validator: validateUpcoming, trigger: 'change' }
        ],
        limitForm: [
          { validator: validatelimitForm, trigger: 'change' }
        ]
      },
      selectLivePosts: [],
      isLoading: false,
      dialogAppDownloadVisible: false,
      dialogLiveLaterVisible: false,
      isVideoUploading: false,
      newVideoId: null,
      isImageUploading: false,
    }
  },
  computed: {
    ...mapGetters('Me', ['token', 'isEnterprise', 'enabledCelebrity', 'enabledTicket', 'imageUploadUrl']),
    ...mapGetters('PostCreate', ['ticketList', 'isTicketListLoading']),

    uploadHeaders () {
      return { Authorization: `Bearer ${this.token}` }
    },
    filterFBChannels () {
      return this.myChannels.filter(channel => channel.type === 'fb_page')
    },
    selectedFbLivePost () {
      if (!this.liveForm.channel_ids) {
        return {}
      }
      return this.filterFBChannels.find(item => item.id === this.liveForm.channel_ids)
    },
    isDayUpperLimit () {
      const upperLimitNumber = 30

      return this.limitForm.day === upperLimitNumber
    },
    showUpcomingVideoSetting () {
      return this.liveForm.mode === 'app' || this.liveForm.mode === 'obs'
    },
  },
  created () {
    if (this.post) {
      this.liveForm.title = this.post.title
      this.liveForm.content = this.post.content

      this.$set(
        this.liveForm,
        'limitForm',
        {
          hasLimit: !!this.post.cart_item_life,
          ...splitMinutes(this.post.cart_item_life)
        }
      )
    }
    this.fetchMyChannels()
  },
  beforeDestroy () {
    // PubNub Unsubscribe：
    // https://www.pubnub.com/docs/web-javascript/api-reference-publish-and-subscribe#unsubscribe_all

    try {
      if (this.pubnub) this.pubnub.unsubscribeAll()
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    async fetchMyChannels () {
      try {
        const { data: channels } = await this.apiChannel.list({}, 'all')
        this.myChannels = channels
      } catch (e) {
        console.error(e)
      }
    },
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.liveForm.validate(valid => (
          valid ? resolve() : reject(new Error('validate error'))
        ))
      })
    },

    async submit () {
      try {
        if (this.isVideoUploading || this.isImageUploading) return
        await this.validate()

        let mode = this.liveForm.mode

        // OBS 只是前端顯示需要，送給後端時還是ㄧ樣帶 app
        if (this.liveForm.mode === 'obs') {
          mode = 'app'
        }

        const fun = {
          fb: () => this.handleFB(),
          app: () => this.handleAPP(),
          postAgent: () => this.handlePostAgent()
        }

        mode ? fun[mode]() : this.createPost()
      } catch (e) {

      }
    },

    updateCheckoutDay () {
      if (this.isDayUpperLimit) {
        this.limitForm.hour = 0
        this.limitForm.minute = 0
      }
    },
    handleFB () {
      this.fetchLiveData()
      this.$refs.publishFacebook.toggle(true)
    },
    handleAPP () {
      // 取得賣場 ID
      const handsupStore = this.myChannels.find(channel => channel.type === 'handsup')

      if (!handsupStore) {
        this.$message.error(this.$t('Post.PushNotification.no_store'))
        return false
      }

      // 建立直播
      this.liveForm.channel_ids = handsupStore.id
      this.createPost()
    },
    handlePostAgent () {
      this.createPost()
    },
    handleChangeMode (mode) {
      // 判斷是否選擇 obs
      this.isObs = mode === LIVEMODE.OBS

      // 判斷可否編輯 直播預告
      const canEditLivePreview = [LIVEMODE.APP, LIVEMODE.OBS].includes(mode)
      if (canEditLivePreview) {
        this.liveForm.showSetLivePreview = true
      } else {
        this.liveForm.showSetLivePreview = false
        this.liveForm.upcoming_at = null
      }
    },
    handleChangePrivate (isPrivate) {
      // FB cannot use private ticket
      if (isPrivate && this.liveForm.mode === LIVEMODE.FB) {
        this.liveForm.mode = LIVEMODE.APP
      }
    },
    handleCompleted (id, callback) {
      let mode = this.liveForm.mode

      if (this.liveForm.mode === LIVEMODE.OBS) {
        mode = LIVEMODE.APP
      }

      const fun = {
        fb: () => this.handleFBCompleted(id, callback),
        app: () => this.handleAPPCompleted(id, callback)
      }

      if (mode) fun[mode]()
    },
    handleFBCompleted (id, callback) {
      callback()
    },
    async handleAPPCompleted (id, callback) {
      this.dialogAppDownloadVisible = true

      const { group_name: channelGroup } = await this.apiPost.getChannelNames(id)

      this.channelGroup = channelGroup

      this.pubnub = new PubNub({
        subscribeKey: process.env.VUE_APP_PUBNUB_SUBSCRIBE_KEY
      })

      this.pubnub.subscribe({
        channelGroups: [channelGroup]
      })

      this.pubnub.addListener({
        message: m => this.dispatch(m.channel, m.message, callback)
      })
    },
    dispatch (channel, payload, callback) {
      const { type } = payload

      switch (true) {
        case channel.startsWith('notification'):
          if (type === 'live.started') {
            // 關閉 APP Dialog
            this.dialogAppDownloadVisible = false
            this.dialogLiveLaterVisible = false

            callback()
          }
          break
      }
    },
    async fetchLiveData () {
      // 取得在 FB 直播中的清單
      this.liveForm.target = null
      this.isLoading = true

      try {
        const data = await this.apiChannel.getLives(this.liveForm.channel_ids)

        this.selectLivePosts = data
      } catch (e) {
        console.error(e)
      }

      this.isLoading = false
    },
    checkoutFBPost () {
      this.liveForm.target
        ? this.createPost()
        : this.$message.error(this.$t('Post.PushNotification.no_post'))
    },
    createPost () {
      const form = {
        ...this.liveForm,
        upcoming_at: this.liveForm.upcoming_at
          ? new Date(this.liveForm.upcoming_at).toISOString()
          : null,
        cart_item_life: this.limitForm.hasLimit
          ? getMinutes(this.limitForm)
          : null,
        // 設為 isPrivate 時才帶 ticket_id
        is_private: this.ticketForm.isPrivate,
        ticket_id: this.ticketForm.isPrivate ? this.ticketForm.ticket_id : null,
        preview_video_id: this.newVideoId !== null ? this.newVideoId : undefined,
        image_id: this.coverImage?.id,
      }

      this.$emit('handleVideoCreate', {
        form,
        isObs: this.isObs
      })
    },
    updateVideoId (videoId) {
      this.newVideoId = videoId
    },
    updateImage (imageObj) {
      this.coverImage = {
        url: imageObj.url,
        id: imageObj.id
      }
    },
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
