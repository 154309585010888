<script>

import dateFormat from 'date-fns/format'
import { mapGetters, mapActions } from 'vuex'

import ApiStore from '@/api/store'
import ApiBank from '@/api/bank'
import ApiMobile from '@/api/mobile'

import * as PAYMENT_FLOW from '@/constants/paymentFlow'
import * as REGIONS from '@/constants/regions'

import addressNormalizer from '@/utils/addressNormalizer'
import FormField from '@/utils/FormField'

import DialogValidatePhone from '@/components/DialogValidatePhone'
import DialogAccountSetting from '@/views/Setting/Seller/components/DialogAccountSetting'
import DialogAccountSettingOversea from '@/views/Setting/Seller/components/DialogAccountSettingOversea'
import DialogBankSetting from '@/views/Setting/Seller/components/DialogBankSetting'
import DialogBankSettingOversea from '@/views/Setting/Seller/components/DialogBankSettingOversea'
import GmoApplyStatus from '@/views/Setting/Seller/components/GmoApplyStatus'

export default {
  name: 'Seller',
  components: {
    DialogAccountSetting,
    DialogBankSetting,
    DialogValidatePhone,
    DialogBankSettingOversea,
    DialogAccountSettingOversea,
    GmoApplyStatus
  },
  data () {
    return {
      ...REGIONS,
      apiStore: new ApiStore(),
      apiBank: new ApiBank(),
      apiMobile: new ApiMobile(),
      isAccountSettingVisible: false,
      isBankSettingVisible: false,
      isValidatePhoneVisible: false,
      isOnlyBank: false,
      bankData: {
        bank_name: null,
        bank_branch_name: null,
        bank_registrant_name: null,
        bank_registrant_account: null
      },
      formData: null,
      verifyCode: {
        requestToken: '',
        code: ''
      },
      isSmsSent: false,
      smsRemainedSeconds: 0,
      selectInvoice: [
        { text: this.$t('Setting.Seller.invoice_ecpay'), value: 'personal' },
        { text: this.$t('Setting.Seller.invoice_donate'), value: 'donation' },
        { text: this.$t('Setting.Seller.invoice_company'), value: 'company' }
      ],
      selectDonation: [
        { text: this.$t('Setting.Seller.donate_genesis'), value: '919' },
        { text: this.$t('Setting.Seller.donate_ccf'), value: '8585' },
        { text: this.$t('Setting.Seller.donate_hsapf'), value: '8957282' }
      ]
    }
  },
  computed: {
    ...mapGetters('Me', [
      'storeName',
      'currentStore',
      'region',
      'currentStore',
      'isPhoneVerfied',
      'gmoStatus',
      'paymentFlow'
    ]),

    isCompany () {
      return this.sellerData.type === 'company'
    },
    invoiceTypeText () {
      return this.sellerData.invoice_type &&
        _.find(this.selectInvoice, { value: this.sellerData.invoice_type }).text
    },
    donationText () {
      return this.sellerData.invoice_donated_code &&
        _.find((this.selectDonation), { value: this.sellerData.invoice_donated_code }).text
    },

    phoneNumber () {
      if (this.isPhoneVerfied) {
        return this.sellerData.registrant_mobile
      } else {
        return this.formData && this.formData.registrant_mobile
      }
    },
    sellerData () {
      const storeRegistrant = _.pick(this.currentStore.store_registrant, [
        'type',
        'registrant_company_phone',
        'registrant_company_representative',
        'registrant_date',
        'registrant_identity_number',
        'registrant_mobile',
        'registrant_name',
        'registrant_tax_identity'
      ])
      if (storeRegistrant.registrant_date) {
        storeRegistrant.registrant_date = dateFormat(storeRegistrant.registrant_date, 'YYYY/M/DD')
      }
      const address = _.pick(this.currentStore.store_registrant.address, [
        'level_one',
        'level_two',
        'level_three',
        'level_four',
        'postcode'
      ])

      const invoiceSetting = this.currentStore.invoice_setting

      return {
        ...storeRegistrant,
        address,
        invoice_type: invoiceSetting ? invoiceSetting.type : null,
        invoice_donated_code: invoiceSetting ? invoiceSetting.donated_code : null,
        registrant_email: invoiceSetting ? invoiceSetting.registrant_email : null
      }
    },

    hiddenFields () {
      if (this.region === REGIONS.JP) {
        return ['registrant_identity_number', 'registrant_date', 'invoice_type', 'invoice_donated_code']
      } else if (this.region === REGIONS.VN) {
        return ['registrant_date', 'invoice_type', 'invoice_donated_code']
      } else if (this.region === REGIONS.TH) {
        return ['invoice_type', 'invoice_donated_code']
      } else {
        return []
      }
    },

    additionalFields () {
      const regionFields = {
        [REGIONS.VN]: ['registrant_tax_identity']
      }

      return regionFields[this.region] || []
    },

    showBank () {
      // 台灣 或 泰國 且不是 非代收代付，才須填寫 銀行資訊

      const showRegions = [REGIONS.TW, REGIONS.TH]
      return showRegions.includes(this.region) && this.paymentFlow !== PAYMENT_FLOW.DIRECT
    },

    showAccountType () {
      const showRegions = [REGIONS.JP]
      return showRegions.includes(this.region)
    },
    displayAddress () {
      const address = this.sellerData.address
      return addressNormalizer(address, this.region)
    },
    isOversea () {
      return this.region !== REGIONS.TW
    },
    dialogBankSetting () {
      return this.isOversea ? 'DialogBankSettingOversea' : 'DialogBankSetting'
    },
    dialogAccountSetting () {
      return this.isOversea ? 'DialogAccountSettingOversea' : 'DialogAccountSetting'
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.query.seller || from.query.seller) {
        this.editingSeller()
        this.$router.replace({ 'query': { seller: null } })
      }
    },

    isSmsSent (value) {
      if (value) {
        this.setSmsTimer()
      }
    }
  },
  async created () {
    this.isSmsSent = false
    this.setLoading(true)
    this.fetchGMOStatus()
    await this.setBankData()
    this.initFormData()
    this.setLoading(false)
  },
  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('Me', ['fetchStores', 'fetchGMOStatus', 'fetchCurrentStore']),

    formField (fieldName) {
      return FormField[this.sellerData.type][fieldName]
    },
    async setBankData () {
      const { data } = await this.apiBank.getBankingAccount()
      if (data) {
        this.bankData = _.pick(data, [
          'bank_name',
          'bank_branch_name',
          'bank_registrant_name',
          'bank_registrant_account',
          'bank_account_type'
        ])
      }
    },
    initFormData () {
      if (this.showBank) {
        this.formData = {
          ...this.sellerData,
          ...this.bankData
        }
      } else {
        this.formData = {
          ...this.sellerData
        }
      }
    },
    editingSeller () {
      this.isOnlyBank = false
      this.isAccountSettingVisible = !this.isAccountSettingVisible
    },
    editingBank () {
      this.isOnlyBank = true
      this.isBankSettingVisible = !this.isBankSettingVisible
    },

    goLastStep () {
      this.isAccountSettingVisible = !this.isAccountSettingVisible
      this.isBankSettingVisible = !this.isBankSettingVisible
    },

    submitSellerData (formSellerData) {
      this.formData = {
        ...formSellerData
      }
      this.isAccountSettingVisible = !this.isAccountSettingVisible
      if (this.showBank) {
        this.isBankSettingVisible = !this.isBankSettingVisible
      } else {
        this.isValidatePhoneVisible = !this.isValidatePhoneVisible
        this.requestSMSCode()
      }
    },
    submitBankData (formBankData) {
      this.formData = {
        ...this.formData,
        ...formBankData
      }

      this.isBankSettingVisible = !this.isBankSettingVisible
      this.isValidatePhoneVisible = !this.isValidatePhoneVisible
      this.requestSMSCode()
    },

    submitPhoneValidation (phoneCode) {
      this.verifyCode.code = phoneCode
      this.formData = {
        ...this.formData,
        request_token: this.verifyCode.requestToken,
        verification_code: this.verifyCode.code
      }
      this.updateSellerSetting()
    },

    async requestSMSCode () {
      if (this.smsRemainedSeconds > 0) return

      const request = {
        mobile: this.formData.registrant_mobile
      }

      const data = await this.apiMobile.storesVerify(request)

      if (data) {
        this.verifyCode.requestToken = data.request_token
        this.smsRemainedSeconds = 300
        this.isSmsSent = true
      }
    },

    setSmsTimer () {
      setInterval(() => {
        if (this.isSmsSent && this.smsRemainedSeconds > 0) {
          this.smsRemainedSeconds -= 1
        }
      }, 1000)
    },

    handleFormData () {
      // 刪除不需傳送的資料
      if (this.formData.type === 'personal') {
        this.formData.registrant_company_phone = null
        this.formData.registrant_company_representative = null
      } else {
        this.formData.invoice_type = null
        this.formData.invoice_donated_code = null
      }
    },

    async updateSellerSetting () {
      const submitData = { ...this.formData }
      // Delete hidden fields
      for (const key of Object.keys(submitData)) {
        if (this.hiddenFields.includes(key)) {
          delete submitData[key]
        }
      }
      let res
      if (this.isOnlyBank) {
        res = await this.apiBank.updateBankingAccount(submitData)
      } else {
        res = await this.apiStore.updateRegistrant(submitData)
      }

      if (res) {
        this.isValidatePhoneVisible = !this.isValidatePhoneVisible
        this.setLoading(true)
        await this.fetchStores()
        await this.fetchCurrentStore()
        await this.setBankData()
        this.setLoading(false)
        this.$message.success(this.$t('message.validate_success'))
      }
    },
    openGMOform () {
      this.$router.push({ name: 'GmoForm' })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
