<script>
import ApiService from '@/api/additionalService'
import Pagination from '@/components/Pagination'
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentRecord',
  components: { Pagination },
  data () {
    return {
      data: [],
      paginator: null
    }
  },

  computed: {
    ...mapGetters('Me', ['currencySymbol'])
  },
  async created () {
    const apiService = new ApiService()
    const { data } = await apiService.get('payment-list')
    this.paginator = apiService.paymentPaginator
    this.data = data
  },
  methods: {
    getType (record) {
      return record.plan ? 'plan' : 'service'
    },
    update (rows) {
      this.data = rows.data
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
