<script>
import Cookies from 'js-cookie'
import { mapGetters } from 'vuex'

const lineHref = 'https://line.me/R/ti/p/%40frf5773e'
export default {
  name: 'LineChat',
  props: {
    isLineSticky: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isVisibleLineBubble: false,
      isVisibleLinePopUp: false
    }
  },

  computed: {
    ...mapGetters('Me', ['currentStore', 'lineLink']),
    isShown () {
      if (!this.currentStore || !this.currentStore.region) return false

      const shownRegions = ['tw', 'th']
      return shownRegions.includes(this.currentStore.region.toLowerCase())
    },
    lineQrcode () {
      const images = {
        TW: require('@/assets/line-qrcode@2x.jpg'),
        TH: require('@/assets/line-qrcode-th.jpg')
      }

      return images[this.currentStore.region]
    }
  },

  mounted () {
    this.calcIsSameToken()
  },
  methods: {
    calcIsSameToken () {
      const currentToken = Cookies.get('api_token')
      const lastToken = localStorage.getItem('lastToken')

      if (currentToken === lastToken) {
        this.isVisibleLineBubble = false
      } else {
        this.isVisibleLineBubble = true
      }
    },
    closeBubble () {
      this.isVisibleLineBubble = false
      const currentToken = Cookies.get('api_token')
      localStorage.setItem('lastToken', currentToken)
    },
    openLineDialog (show) {
      if (show) this.btnClickHandler()
    },
    btnClickHandler () {
      var userAgent = navigator.userAgent
      var isMobile
      if (/Android/i.test(userAgent)) {
        // 是否為Android
        isMobile = true
      } else if (/iPhone|iPad/i.test(userAgent)) {
        // 是否為iPhone或iPad
        isMobile = true
      } else if (/Windows/i.test(userAgent)) {
        // 使否是用電腦觀看
        isMobile = false
      }
      if (isMobile) {
        window.open(lineHref)
      } else {
        this.isVisibleLinePopUp = true
      }
    }

  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
