<script>
import AccountInvite from '../AccountInvite'
import SimpleDialog from '@/components/SimpleDialog'
import ApiStoreUser from '@/api/storeUser'
import { mapGetters } from 'vuex'

export default {
  name: 'Account',
  components: { AccountInvite, SimpleDialog },
  data () {
    return {
      members: [],
      deleteMan: null,
      accountInviteVisible: false
    }
  },
  computed: {
    ...mapGetters('Me', ['isCurrentStoreOwner', 'isEnterprise', 'currentStore', 'canBuyPlan']),
    isShowAddAccount () {
      const plan = this.currentStore.plan
      const number = plan ? (plan.assistant_number || 0) : -1

      if (this.isEnterprise) {
        return true
      } else {
        return number === 0 || this.members.length < plan.assistant_number
      }
    }
  },
  created () {
    this.fetchMembers()
  },
  methods: {
    async fetchMembers () {
      const apiStoreUser = new ApiStoreUser()
      const { data } = await apiStoreUser.list({ type: 'manager' }, true)

      if (data.length) {
        this.members = [...data]
      } else {
        this.$message.error(this.$t('Setting.Account.get_fail'))
      }
    },
    async updateEnabled (member) {
      // TODO：目前需求改為刪除，無開關功能
      try {
        const api = `/api/v1/stores/${this.$route.params.store}/users/${member.id}`
        await this.axios.put(api)
        await this.fetchMembers()
      } catch (e) {
        console.error(e)
        this.$message.error(this.$t('Setting.Account.delete_fail'))
      }

      this.$refs.DeleteConfirm.toggle(false)
    },
    memberIsCreator (member) {
      return member.pivot.type === 'owner'
    },
    showMemberPopover (member) {
      if (!this.isCurrentStoreOwner) return false

      return !this.memberIsCreator(member)
    },
    removeUser (user) {
      this.deleteMan = user
      this.$refs.DeleteConfirm.toggle(true)
    },
    deleteUser () {
      this.updateEnabled(this.deleteMan)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
