<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DialogImportData',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    excelType: {
      type: String,
      required: true
    },
    uploadPath: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      fileList: [],
      fileName: '',
      validate: null,
      success: false,
      isBtnLoading: false
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'token', 'currentStore']),
    uploadHeaders () {
      return { Authorization: `Bearer ${this.token}` }
    },
    productSamplePath () {
      const region = this.currentStore.region.toLowerCase()
      return `/excel/product-${region}.xlsx`
    }
  },
  methods: {
    handleCloseDialog () {
      this.$emit('refresh')
      this.$emit('update:visible', false)
    },
    handleBeforeUpload (file) {
      // 交給後端驗證，前端有許多瀏覽器吃不到 mime 很容易上傳不了
      this.validate = true
      this.fileName = file.name
    },
    importData () {
      const excel = this.$refs.uploader.uploadFiles[0].raw

      let data = new FormData()
      data.append('file', excel)
      this.isBtnLoading = true

      this.axios.post(this.uploadPath, data)
        .then((res) => {
          this.isBtnLoading = false
          this.$message({
            showClose: true,
            duration: 1000,
            message: this.$t('Components.DialogImportData.import_complete'),
            type: 'success'
          })
        })
        .catch((e) => {
          console.error(e)
        })
        .then(() => {
          this.success = true
          this.handleCloseDialog()
        })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
