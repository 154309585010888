<script>
import VueTypes from 'vue-types'

export default {
  name: 'AppSelectList',
  props: {
    list: VueTypes.array
  },
  methods: {
    handleClick (item, index) {

    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
