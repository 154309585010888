<script>
import VueTypes from 'vue-types'

import { updateMe } from '@/views/User/service/apis'

export default {
  name: 'DialogEditUserInfo',

  props: {
    lastName: VueTypes.string.def(''),
    firstName: VueTypes.string.def('')
  },

  data () {
    return {
      ruleForm: {
        lastName: '',
        firstName: ''
      },
      rules: {
        lastName: [
          { required: true, message: this.$t('Register.enter_last_name'), trigger: 'blur' }
        ],
        firstName: [
          { required: true, message: this.$t('Register.enter_first_name'), trigger: 'blur' }
        ]
      }
    }
  },

  created () {
    this.ruleForm.lastName = this.lastName
    this.ruleForm.firstName = this.firstName
  },

  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    async handleSubmit () {
      try {
        await this.$refs.ruleForm.validate()

        // 更新 姓名
        await updateMe({
          'last_name': this.ruleForm.lastName,
          'first_name': this.ruleForm.firstName
        })

        this.$emit('refreshMe')
        this.$message.success(this.$t('Post.Show.ShowActivityProcess.save_success'))
        this.handleCloseDialog()
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<template lang="pug" src="./DialogEditUserInfo.pug"></template>
