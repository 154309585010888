import { render, staticRenderFns } from "./ContainerSetting.pug?vue&type=template&id=2d6908fc&lang=pug&"
import script from "./ContainerSetting.vue?vue&type=script&lang=js&"
export * from "./ContainerSetting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports