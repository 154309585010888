import LiveSetting from './LiveSetting'
import ChangeConfirm from './ChangeConfirm'
import Festivals from './Festivals'
import HoverElements from './HoverElements'

export default {
  namespaced: true,
  modules: {
    LiveSetting,
    ChangeConfirm,
    Festivals,
    HoverElements
  }
}
