import ApiDraw from '@/api/draw'

import * as ACTIVITY_STATUS from '@/constants/activityStatus'

const apiDraw = new ApiDraw()

const INIT_DATA = {
  activityDraws: [],
  selectDraw: null,
  selectDrawWinners: [],

  drawFormVisible: false,
  showDrawAnimation: false,
  showRibbonAnimation: false,

  isDrawEnd: false

}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  activityDraws: state => state.activityDraws,
  selectDraw: state => state.selectDraw,
  selectDrawWinners: state => state.selectDrawWinners,
  drawFormVisible: state => state.drawFormVisible,
  showDrawAnimation: state => state.showDrawAnimation,
  showRibbonAnimation: state => state.showRibbonAnimation,
  isDrawEnd: state => state.isDrawEnd,

  currentDraw: state => {
    // 目前 正在進行中的活動 或 已結束，但尚未發佈
    return state.activityDraws.find(activity =>
      activity.status === ACTIVITY_STATUS.STARTED || (activity.status === ACTIVITY_STATUS.DONE && !activity.published))
  },

  hasCurrentDraw: (_, getters) => {
    // 正在進行中：有 開始時間 且 無結束時間
    return !!getters.currentDraw
  },

  showDrawResult: state => state.selectDraw && state.selectDraw.status === ACTIVITY_STATUS.DONE

}

const mutations = {
  UPDATE_ACTIVITY_DRAWS (state, payload) {
    state.activityDraws = payload
  },

  UPDATE_SELECT_DRAW (state, { draw, winners }) {
    state.selectDraw = draw
    state.selectDrawWinners = winners
  },

  UPDATE_IS_DRAW_END (state, bol) {
    state.isDrawEnd = bol
  },
  UPDATE_DRAW_FORM_VISIBLE (state, bol) {
    state.drawFormVisible = bol
  },
  UPDATE_DRAW_ANIMATION (state, bol) {
    state.showDrawAnimation = bol
  },
  UPDATE_RIBBON_ANIMATION (state, bol) {
    state.showRibbonAnimation = bol
  },

  RESET_DRAW_DATA (state) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))
  }
}

const actions = {

  async fetchActivityDraws ({ commit }, postId) {
    if (!postId) return

    return apiDraw.getDraws(postId)
      .then(response => {
        const { data } = response.data
        commit('UPDATE_ACTIVITY_DRAWS', data)
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  },

  checkDrawActivity ({ getters, dispatch }) {
    // 判斷是否有 抽獎活動 正在進行中

    if (getters.hasCurrentDraw) {
      dispatch('setSelectDraw', getters.currentDraw.id)

      // 若還沒 開抽 的話，顯示 DrawForm
      if (getters.currentDraw.status !== ACTIVITY_STATUS.DONE) {
        dispatch('toggleDrawForm', true)
      }
    }
  },

  async createDraw ({ dispatch }, { postId, postData }) {
    return apiDraw.create(postId, postData)
      .then(async (response) => {
        return response
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  },

  async updateDraw ({ dispatch }, { postId, drawId, updateData }) {
    return apiDraw.update(drawId, updateData)
      .then(async (response) => {
        return response
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  },

  async endDraw ({ dispatch }, { postId, drawId }) {
    return apiDraw.end(drawId)
      .then(async () => {
        // 刷新資料的時機點，由 Pubnub 的 draw.ended 事件後，才觸發。
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  },

  async deleteDraw ({ dispatch }, drawId) {
    return apiDraw.delete(drawId)
  },

  updateIsDrawEnd ({ commit }, bol) {
    commit('UPDATE_IS_DRAW_END', bol)
  },

  toggleDrawForm ({ getters, commit }, bol = null) {
    const flag = bol !== null
      ? bol
      : !getters.drawFormVisible

    commit('UPDATE_DRAW_FORM_VISIBLE', flag)
  },
  updateDrawAnimation ({ commit }, bol) {
    commit('UPDATE_DRAW_ANIMATION', bol)
  },
  triggerRibbonAnimation ({ commit }) {
    commit('UPDATE_RIBBON_ANIMATION', true)

    setTimeout(() => {
      commit('UPDATE_RIBBON_ANIMATION', false)
    }, 5000)
  },

  async setSelectDraw ({ dispatch, commit, getters }, drawId) {
    const draw = getters.activityDraws.find(item => item.id === drawId)
    if (!draw) return

    if (draw.status === ACTIVITY_STATUS.DONE) {
      // 已開抽(有得獎名單)
      return apiDraw.getWinners(drawId)
        .then(response => {
          const { data } = response.data
          commit('UPDATE_SELECT_DRAW', {
            draw,
            winners: data
          })

          // 活動結束 且 有 Winner 時，才顯示 抽獎動畫(彩花)
          if (getters.isDrawEnd && data.length) dispatch('triggerRibbonAnimation')
          commit('UPDATE_IS_DRAW_END', false)
        })
        .catch(error => {
          console.error(error)
          throw error
        })
    } else {
      // 未開抽
      commit('UPDATE_SELECT_DRAW', {
        draw,
        winners: []
      })
    }
  },

  async publishDraw ({ dispatch }, { postId, drawId }) {
    return apiDraw.publishWinners(drawId)
      .then(async () => {
        // await dispatch('fetchActivityDraws', postId)
      })
      .catch(error => {
        console.error(error)
        throw error
      })
  },

  clearSelectDraw ({ commit }) {
    commit('UPDATE_SELECT_DRAW', {
      draw: null,
      winners: []
    })
  },

  resetDrawData ({ commit }) {
    commit('RESET_DRAW_DATA')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
