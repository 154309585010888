<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'
import { normalizeImage } from '@/utils/normalizeImage'

// Flags to bring to the backend for product sorting.
const PRODUCT_SORTING_FLAGS = {
  CATEGORY: 'category',
  PRICE_RANGE: 'priceRange',
  SALABLE_QUANTITY: 'salableQuantity',
  LOCK_QUANTITY: 'lockQuantity',
  AVAILABLE_QUANTITY: 'availableQuantity'
}

export default {
  name: 'ProductTable',

  props: {
    products: VueTypes.array.def([]),
    visiblePickProduct: VueTypes.bool.def(false),
    sort: VueTypes.string,
    desc: VueTypes.string,
    selectProducts: VueTypes.array.def([]),
    canPickProduct: VueTypes.bool.def(false)
  },
  data () {
    return {
      expandedRow: [],

      PRODUCT_SORTING_FLAGS
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol', 'keywordTip']),

    selectAll: {
      get () {
        return this.products.length !== 0 &&
        this.selectProducts.length !== 0 &&
        this.products.length === this.selectProducts.length
      },

      set () {
        this.$emit('handleToggleAll')
      }
    }
  },

  methods: {
    getImgUrl (data) {
      if (data.images.length === 0) return
      return normalizeImage(data.images[0].url, 40)
    },

    isExpandRow (id) {
      return this.expandedRow.some(item => item === id)
    },
    handleExpandRow (id) {
      const idx = this.expandedRow.findIndex(item => item === id)

      if (idx === -1) {
        this.expandedRow.push(id)
      } else {
        this.expandedRow.splice(idx, 1)
      }
    },
    isChecked (product) {
      return this.selectProducts.some(item => item.id === product.id)
    },

    getKeyword (defaultKeyword, skus, index) {
      if (skus.length === 1) {
        return defaultKeyword
      } else {
        return defaultKeyword ? `${defaultKeyword}${skus[index].name}` : ''
      }
    },

    handleSort (name) {
      this.$emit('handleSortChange', name)
    },

    handleToggleSelect (product) {
      this.$emit('handleToggleSelect', product)
    },
    handleUpdate (index, data) {
      this.$emit('handleUpdate', index, data)
    },
    handleCreate () {
      this.$emit('handleCreate')
    }
  }
}
</script>

<template lang="pug" src="./ProductTable.pug"></template>
<style lang="scss" src="./ProductTable.scss" scoped></style>
