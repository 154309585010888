<script>
// https://github.com/dwightjack/vue-types#documentation
import VueTypes from 'vue-types'
import ServiceJp from './components/ServiceJp'
import PrivacyJp from './components/PrivacyJp'
import ContractBuyerJp from './components/ContractBuyerJp'

export default {
  name: 'Jp',
  components: { ServiceJp, PrivacyJp, ContractBuyerJp },
  props: {
    isSeller: VueTypes.bool
  },
  data () {
    return {
      terms: [
        {
          id: 1,
          component: 'ServiceJp',
          title: 'HandsUP 加盟店規約',
          version: '2020年9月3日バージョン'
        },
        {
          id: 2,
          component: 'PrivacyJp',
          title: 'HandsUPプライバシーポリシー',
          version: '2020年02月24日バージョン'
        },
        {
          id: 3,
          component: 'ContractBuyerJp',
          title: 'HandsUPユーザー利用規約',
          version: '2020年9月3日バージョン'
        }
      ]
    }
  },
  computed: {
    // 判斷分別要出現哪些條款
    sections () {
      const terms = this.terms
      // 賣家
      const sellerSections = terms.slice(0, 2)
      // 買家
      const buyerSections = [terms[2], terms[1]]
      return this.isSeller ? sellerSections : buyerSections
    }
  }
}
</script>

<template lang="pug" src="./Jp.pug"></template>
<style lang="scss" src="./Jp.scss" scoped></style>
