import _ from 'lodash'

export default function addressNormalizer (address = {}, region = 'tw') {
  // If address is string return it, else assemble from object
  if (_.isString(address)) return address

  region = region.toLowerCase()
  const regions = {
    tw: ['postcode', 'level_one', 'level_two', 'level_three', 'level_four'],
    vn: ['level_four', 'level_three', 'level_two', 'level_one', 'postcode'],
    jp: ['postcode', 'level_one', 'level_two', 'level_three', 'level_four'],
    th: ['level_four', 'level_three', 'level_two', 'level_one', 'postcode']
  }
  const order = regions[region] || regions['tw']

  return order.reduce((acc, val) => {
    return address[val] ? acc + address[val] + ' ' : acc
  }, '')
}
