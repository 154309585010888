<script>
import { mapState, mapActions } from 'vuex'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import SubForm from '../SubForm'

export default {
  name: 'FormSeven',
  components: {
    SubForm
  },
  data () {
    return {
      SHIPPING_TYPES
    }
  },
  computed: {
    ...mapState('OrderManual/ShippingAddress', ['storeAddress', 'storeName', 'storeId', 'storeType']),
    seven () {
      return {
        action: 'https://emap.presco.com.tw/c2cemap.ashx',
        callback: `${process.env.VUE_APP_DOMAIN_NAME}/callback/seven/map`,
        redirect: location.origin + location.pathname
      }
    }
  },

  methods: {
    ...mapActions('OrderManual/ShippingAddress', ['cacheOrderData']),

    toStoreUrl () {
      this.cacheOrderData()
      this.$refs.sevenForm.submit()
    }
  }

}
</script>

<template lang="pug" src="./template.pug"></template>
