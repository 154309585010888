import * as loadingState from '@/constants/loadingState'

import { deleteToken } from '@/api/registerToken'

export const actionTypes = {
  DELETE_TOKEN: 'DELETE_TOKEN',
  DELETE_TOKEN_SUCCESS: 'DELETE_TOKEN_SUCCESS',
  DELETE_TOKEN_FAILED: 'DELETE_TOKEN_FAILED'
}

const state = {
  loadingState: loadingState.EMPTY,
  error: null
}

const getters = {
  deleteTokenLoadingState: state => state.loadingState
}

const mutations = {
  [actionTypes.DELETE_TOKEN] (state) {
    state.loadingState = loadingState.LOADING
  },

  [actionTypes.DELETE_TOKEN_SUCCESS] (state, payload) {
    state.loadingState = loadingState.READY
  },

  [actionTypes.DELETE_TOKEN_FAILED] (state, error) {
    state.loadingState = loadingState.ERROR
    state.error = error

    console.error(error)
  }
}

const actions = {
  deleteToken ({ commit }, token) {
    commit(actionTypes.DELETE_TOKEN)

    return deleteToken(token)
      .then(response => commit(actionTypes.DELETE_TOKEN_SUCCESS, response))
      .catch(err => commit(actionTypes.DELETE_TOKEN_FAILED, err))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
