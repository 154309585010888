<script>
export default {

  name: 'DialogLogistic',
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    setting: {
      type: Object
    }
  },
  data () {
    return {
      ruleForm: {
        company: '',
        password: '',
        station: '',
        customer_code: '',
        print_label: ''
      },
      rules: {
        company: [
          { required: true, message: this.$t('Setting.Payment.DialogLogistic.validate_company'), trigger: 'blur' }
        ],
        password: [
          { required: true, message: this.$t('Setting.Payment.DialogLogistic.validate_password'), trigger: 'blur' }
        ],
        station: [
          {
            len: 4,
            required: true,
            pattern: /^\d{4}$/,
            trigger: 'blur',
            message: this.$t('Setting.Payment.DialogLogistic.validate_station')
          }
        ],
        customer_code: [
          {
            len: 11,
            required: true,
            pattern: /^\d{11}$/,
            trigger: 'blur',
            message: this.$t('Setting.Payment.DialogLogistic.validate_customer_code')
          }
        ],
        print_label: [
          { required: true, message: this.$t('Setting.Payment.DialogLogistic.validate_print_label'), trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    this.ruleForm = { ...this.setting, ...this.setting.info }
  },
  methods: {
    async handleSubmit () {
      // 驗證欄位
      try {
        await this.$refs.ruleForm.validate()
      } catch (error) {
        return
      }
      const data = {
        type: this.setting.type,
        company: this.ruleForm.company,
        password: this.ruleForm.password,
        info: {
          station: this.ruleForm.station,
          customer_code: this.ruleForm.customer_code,
          print_label: this.ruleForm.print_label
        }
      }
      this.$emit('updateLogistic', data)
    },
    handleClose () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
