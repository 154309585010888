<script>
import AddressSelect from '@/components/AddressSelect'
import { mapActions } from 'vuex'
import { twCellPhonePattern } from '@/constants/regexPattern'
import SubForm from '../SubForm'

export default {
  name: 'FormHomeDelivery',

  components: { AddressSelect, SubForm },

  data () {
    return {
      form: {
        receiverName: '',
        receiverPhone: '',
        receiverAddress: {}
      },

      rules: {
        receiverName: [
          {
            required: true,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ],
        receiverPhone: [
          {
            required: true,
            pattern: twCellPhonePattern,
            message: this.$t('Setting.Payment.DialogStorePickup.validate'),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('OrderManual/ShippingAddress', ['updateReceiverName', 'updateReceiverPhone', 'updateReceiverHomeAddress']),
    updateName (value) {
      this.updateReceiverName(value)
      this.form.receiverName = value
    },
    updatePhone (value) {
      const removeSpaceStr = value.replace(/\D/g, '')
      this.updateReceiverPhone(removeSpaceStr)
      this.form.receiverPhone = removeSpaceStr
    },
    updateAddress (value) {
      /**
       * value example:
       *  level_four: "aaabbbccc"
          level_one: "苗栗縣"
          level_two: "獅潭鄉"
          postcode: 354
       */
      this.updateReceiverHomeAddress({
        city: value.level_one,
        region: value.level_two,
        address: value.level_four,
        postcode: value.postcode
      })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
