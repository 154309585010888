import * as loadingState from '@/constants/loadingState'

import { updateVoucher as apiUpdateVoucher } from '../apis'

export const types = {
  UPDATE_VOUCHER: 'UPDATE_VOUCHER',
  UPDATE_VOUCHER_SUCCESS: 'UPDATE_VOUCHER_SUCCESS',
  UPDATE_VOUCHER_FAILED: 'UPDATE_VOUCHER_FAILED'
}

const INIT_DATA = {
  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  updateVoucherLoadingState: state => state.loadingState
}

const mutations = {
  [types.UPDATE_VOUCHER] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.UPDATE_VOUCHER_SUCCESS] (state) {
    state.loadingState = loadingState.READY
  },

  [types.UPDATE_VOUCHER_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  }
}

const actions = {
  updateVoucher ({ commit }, { id, updateData }) {
    commit(types.UPDATE_VOUCHER)

    return apiUpdateVoucher(id, updateData)
      .then(() => commit(types.UPDATE_VOUCHER_SUCCESS))
      .catch(err => {
        commit(types.UPDATE_VOUCHER_FAILED, err)
        throw err
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
