import parse from 'url-parse'
import Cookies from 'js-cookie'

const RELOAD_LIMIT = 7 // 重載頁面次數上限

const formatRedirectURL = (url, query = {}) => {
  const parsed = parse(url, true)
  parsed.query = Object.assign(parsed.query, query)
  return parsed.toString()
}

function catchError (event) {
  const { message } = event
  const pattern1 = /Loading chunk .+ failed/gm
  const pattern2 = /SyntaxError: Unexpected token/gm

  const isChunkLoadFailed = message.match(pattern1) || message.match(pattern2)

  if (isChunkLoadFailed) {
    let reloadCount = Cookies.get('reloadCount') || 0

    // 判斷是否重載過多次
    if (reloadCount > RELOAD_LIMIT) {
      alert('Loaded too many times ...')
      console.error('已重載頁面次數：' + reloadCount)
      return
    }

    // 紀錄重載次數
    reloadCount++
    const oneHour = 0.04 // 約一小時
    Cookies.set('reloadCount', reloadCount, { expires: oneHour })

    // 重新載入頁面
    let redirectURL = formatRedirectURL(location.href, { 'hashTime': Date.now() })
    console.error(`Window Error redirectURL ： ` + redirectURL)
    window.location.href = redirectURL
  }
}

/**
 * @description 遇到 Loading chunk failed 的話，原路由加 hash-time 重新載入。
 */
window.addEventListener('error', catchError)
