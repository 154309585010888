<script>
import VueTypes from 'vue-types'
import { mapActions } from 'vuex'

import ProductFormName from '@/views/ProductDetail/components/ProductFormName'
import ProductFormSku from '@/views/ProductDetail/components/ProductFormSku'
import ProductFormOther from '@/views/ProductDetail/components/ProductFormOther'
import ProductFormImageSortable from '@/views/ProductDetail/components/ProductFormImageSortable'

export default {
  name: 'DialogAddProduct',

  components: {
    ProductFormName,
    ProductFormImageSortable,
    ProductFormSku,
    ProductFormOther
  },

  props: {
    selectedProducts: VueTypes.array.def([])
  },

  data () {
    return {
      showFormImage: false,
      showFormOther: false,
      isLoading: false
    }
  },

  computed: {
    selectedProductsKeyword () {
      return this.selectedProducts.map(product => product.keyword)
    }
  },

  beforeDestroy () {
    this.resetEditProduct()
  },

  methods: {
    ...mapActions('ProductDetail', [
      'createProduct',
      'resetEditProduct'
    ]),

    handleCloseDialog () {
      this.$emit('close')
      this.$emit('update:dialogVisible', false)
    },

    async validateFormName () {
      try {
        await this.$refs.ProductFormName.validateForm()
        return true
      } catch (error) {
        return false
      }
    },

    validateFormSku () {
      try {
        return this.$refs.ProductFormSku.validateForm()
      } catch (error) {
        return false
      }
    },

    validateFormOther () {
      try {
        return this.$refs.ProductFormOther.validateForm()
      } catch (error) {
        return false
      }
    },

    async handleSubmit () {
      const [validateName, validateSku, validateOther] = await Promise.all([
        this.validateFormName(),
        this.validateFormSku(),
        this.validateFormOther()
      ])

      if (!validateName || !validateSku || !validateOther) return

      this.isLoading = true

      try {
        const { data } = await this.createProduct()
        this.$emit('submit', [data])

        this.$message({
          message: this.$t('Components.DialogAddProductSingle.add_complete'),
          type: 'success',
          duration: 1000,
          showClose: true
        })
      } catch (err) {
        console.error(err)
      }

      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogAddProduct.pug"></template>
