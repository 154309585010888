<script>
import VueTypes from 'vue-types'
import { mapActions } from 'vuex'

import ApiSku from '@/api/sku'
import ApiProduct from '@/api/product'

import * as PAYMENT_STATUS from '@/constants/paymentStatus'
import * as ORDER_STATUS from '@/constants/orderStatus'

import AppAlert from '@/components/AppAlert'

import { normalizeImage } from '@/utils/normalizeImage'

export default {
  name: 'DialogUpdateOrder',

  components: { AppAlert },

  props: {
    isCart: VueTypes.bool.def(true),
    orderData: VueTypes.object.def({}),
    cartData: VueTypes.array.def([]),
    payment: VueTypes.object.def({})
  },

  data () {
    return {
      allProducts: [],
      products: [],

      showDeleteNotice: false
    }
  },

  computed: {
    isOrderApproving () {
      // 付款狀態為「待審核」的訂單無法編輯購買明細，請先審核過訂單，再進行編輯
      return this.payment.status === PAYMENT_STATUS.APPROVING
    },

    showChangeProductNotice () {
      if (this.isCart) return false

      const orderStatus = _.get(this.orderData, 'status', '')

      return orderStatus !== ORDER_STATUS.UNPAID
    },

    getPriceLimitMin () {
      const hasPaid = this.products.some(product => product.isPaid)
      return hasPaid ? 0 : -Infinity
    }
  },

  async created () {
    this.fetchProductInfo()
  },

  methods: {
    normalizeImage,
    ...mapActions(['setLoading']),

    async fetchOriginProducts () {
      const apiSku = new ApiSku()

      // 由 sku 反查 原本的 Product
      const originProducts = await Promise.all(
        this.cartData.map(item => {
          return item.sku_id
            ? apiSku.get(item.sku_id)
            : null
        })
      )

      return originProducts
    },
    normalizeHasProduct (product, item) {
      // Product 還存在
      const skuId = item.sku_id

      for (const sku of product.skus) {
        sku.price = parseInt(sku.price)
      }

      // 判斷目前的 sku 是否還存在？
      // 存在：引用現有的 product 的 skus
      // 不存在：自建一個 skus，且不給改 規格
      product.sku = product.skus.find(sku => sku.id === skuId)

      if (!product.sku) {
        product.sku = {
          name: item.sku_name
        }
        product.skus = [product.sku]
        product.skuDisable = true
      } else {
        // 將 已買的數量 加上 可再買要數量
        product.sku.salable_quantity = product.sku.salable_quantity + item.quantity
        product.skuDisable = false
      }

      product.quantity = item.quantity
      product.price = parseInt(item.price)
      product.id = item.id
      product.sortKey = item.id
      product.payment = item.payment
      product.hasPriceLimitError = false
      product.hasQuantityLimitError = false

      const orderPaymentStatus = _.get(this.payment, 'status', null)
      const productPaymentStatus = _.get(item.payment, 'status', null)

      if (orderPaymentStatus === PAYMENT_STATUS.APPROVING) {
        // 訂單狀態為 待審核 時，不可編輯
        product.editDisable = true
      } else if (productPaymentStatus === PAYMENT_STATUS.PAID) {
        // sku 狀態，若已付款，不可編輯
        product.editDisable = true
        product.isPaid = true
      } else {
        product.editDisable = false
      }

      return product
    },
    normalizeNoProduct (item) {
      // Product 已不存在

      const product = { ...item }

      product.sku = {
        name: product.sku_name
      }
      product.skus = [product.sku]
      product.skuDisable = true
      product.price = parseInt(item.price)
      product.sortKey = item.id
      product.payment = item.payment
      product.hasPriceLimitError = false
      product.hasQuantityLimitError = false

      const orderPaymentStatus = _.get(this.payment, 'status', null)
      const productPaymentStatus = _.get(item.payment, 'status', null)

      if (orderPaymentStatus === PAYMENT_STATUS.APPROVING) {
        // 訂單狀態為 待審核 時，不可編輯
        product.editDisable = true
      } else if (productPaymentStatus === PAYMENT_STATUS.PAID) {
        // sku 狀態，若已付款，不可編輯
        product.editDisable = true
        product.isPaid = true
      } else {
        product.editDisable = false
      }

      return product
    },

    async fetchProductInfo () {
      this.setLoading(true)

      let products = []

      // 取得原始的 Product 資料
      const originProduct = await this.fetchOriginProducts()

      this.cartData.forEach((item, index) => {
        const product = originProduct[index]

        if (product) {
          // Product 還存在
          products.push(this.normalizeHasProduct(product, item))
        } else {
          // Product 不存在
          products.push(this.normalizeNoProduct(item))
        }
      })

      this.products = products

      this.setLoading(false)
    },

    async remoteMethod (query) {
      if (!query) {
        this.allProducts = []
        return
      }

      const apiProduct = new ApiProduct()
      const params = query ? { keyword: query } : null

      const { data: allProducts } = await apiProduct.list(params, true)
      this.allProducts = allProducts
    },
    getImageUrl (data) {
      if (!data.images || data.images.length === 0) return
      return normalizeImage(data.images[0].url, 30)
    },
    handleSelect (item) {
      const newItem = _.cloneDeep(item)
      const skuLeft = newItem.skus
      for (const sku of skuLeft) {
        sku.price = parseInt(sku.price)
      }
      newItem.sku = skuLeft[0]
      newItem.quantity = 1
      newItem.price = parseInt(skuLeft[0].price)
      newItem.sortKey = Math.random().toString()
      delete newItem['id']
      this.products.push(newItem)
      this.allProducts = []

      this.products.forEach(p => this.validateQuantityLimit(p))
    },

    validatePriceLimit (product) {
      if (product.price < this.getPriceLimitMin) {
        product.hasPriceLimitError = true
      } else {
        product.hasPriceLimitError = false
      }
    },
    validateQuantityLimit (product) {
      const salableQuantity = _.get(product, 'sku.salable_quantity', Infinity)
      if (salableQuantity === 0) {
        product.hasQuantityLimitError = true
        return
      }

      const limitMin = 1
      const limitMax = salableQuantity

      if (limitMin <= product.quantity && product.quantity <= limitMax) {
        product.hasQuantityLimitError = false
      } else {
        product.hasQuantityLimitError = true
      }
    },
    canDelete (product) {
      // 當商品價錢為 0 元 且 目前的 paymentId 與 商品的 paymentId 一樣，則提供可刪除
      const productPaymentId = _.get(product, 'payment.id', '')
      if (Number(product.price) === 0 && this.payment.id === productPaymentId) {
        return true
      } else {
        return !product.editDisable
      }
    },
    handleDelete (item) {
      this.products = this.products.filter(x => {
        return x.sortKey !== item.sortKey
      })
    },
    changeSku (product) {
      product.price = product.sku.price
      product.quantity = 1
    },

    handleSubmit () {
      if (this.products.length === 0) {
        if (this.isCart) {
          this.confirmDelete()
        } else {
          this.toggleDeleteNotice()
        }
      } else {
        this.submit()
      }
    },
    toggleDeleteNotice () {
      this.showDeleteNotice = !this.showDeleteNotice
    },
    confirmDelete () {
      this.$confirm(
        this.$t('Order.DialogUpdateOrder.confirm.delete.description'),
        this.$t('Order.DialogUpdateOrder.confirm.delete.title'),
        {
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel'),
          type: 'warning'
        })
        .then(() => {
          this.submit()
        }).catch(() => {
          // Cancelling
        })
    },

    valiadateProduct () {
      let hasError = false
      let duplicateItem = []

      for (const p of this.products) {
        // 驗證商品的價錢與數量(排除已付的商品)
        const isUnpaid = !(p.payment && p.payment.status === PAYMENT_STATUS.PAID)

        if (isUnpaid) {
          this.validatePriceLimit(p)
          this.validateQuantityLimit(p)

          if (p.hasPriceLimitError || p.hasQuantityLimitError) {
            hasError = true
          }
        }

        // 排除已付款的商品 且 同商品規格與金額不可重複
        const sameSku = this.products
          .filter(x => !(x.payment && x.payment.status === PAYMENT_STATUS.PAID))
          .filter(x => x.sku.id === p.sku.id)
          .filter(x => x.price === p.price)

        if (sameSku.length > 1) {
          duplicateItem.push(p.name + ' ' + p.sku.name)
          hasError = true
        }
      }

      // 商品重複 Error Message
      if (duplicateItem.length) {
        const duplicateItemMsg = '<br><br>' + [...new Set(duplicateItem)].join('<br>')
        const message = this.$t('Order.DialogUpdateOrder.error_sku_duplication', { item: duplicateItemMsg })

        this.$message({
          dangerouslyUseHTMLString: true,
          showClose: true,
          message,
          type: 'error'
        })
      }

      return !hasError
    },
    submit () {
      if (!this.valiadateProduct()) return

      const items = this.products.map(product => {
        const item = {
          sku_name: product.sku.name,
          sku_id: product.sku.id,
          product_name: product.name || product.product_name,
          quantity: product.quantity,
          price: product.price
        }
        if (product.id) {
          item.id = product.id
        }
        if (product.images && product.images.length > 0) {
          item.image_id = product.images[0].id
        } else if (product.image) {
          item.image_id = product.image_id
        }
        return item
      })

      this.$emit('submit', items)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
