<script>
import { mapGetters } from 'vuex'
import * as PAYMENT_FLOW from '@/constants/paymentFlow'
import * as REGIONS from '@/constants/regions'
import * as PLANS from '@/constants/plans'
import '@/utils/copyText'

export default {
  name: 'StoreInfo',
  computed: {
    ...mapGetters('Me', ['currentStore', 'storeName', 'region', 'paymentFlow', 'isChildStore']),
    planName () {
      // 非代收代付
      if (this.paymentFlow === PAYMENT_FLOW.DIRECT && this.region === REGIONS.TW) return ''

      const planName = {
        [PLANS.NOVICE]: this.$t('LayoutAdmin.plans.novice'),
        [PLANS.MASTER]: this.$t('LayoutAdmin.plans.master'),
        [PLANS.EXPERT]: this.$t('LayoutAdmin.plans.expert'),
        [PLANS.FREE_TRIAL]: this.$t('LayoutAdmin.plans.free_trial'),
        [PLANS.MERCHANDISE]: this.$t('plan.merchandise'),
        [PLANS.TICKET]: this.$t('plan.ticket'),
        [PLANS.FULL]: this.$t('plan.full')
      }

      let returnPlanName = planName[this.currentStore.plan.name] || ''

      return returnPlanName
    },
    liveLink () {
      return `${this.$VUE_APP_BUYER_DOMAIN_NAME}/${this.storeName}/live`
    },
    marketLink () {
      return `${this.$VUE_APP_BUYER_DOMAIN_NAME}/${this.storeName}/market`
    }
  },
  methods: {
    copy (url) {
      this.copyText({ url })
    },
    displayLink (link) {
      const url = new URL(link)
      return url.host + url.pathname
    }
  }
}
</script>

<template lang="pug" src="./StoreInfo.pug"></template>
<style lang="scss" src="./StoreInfo.scss" scoped></style>
