<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'

import isKeyInNumber from '@/utils/isKeyInNumber'

const isNumber = obj => {
  const number = Number(obj)
  return typeof number === 'number' && !isNaN(number)
}

export default {
  name: 'SubscriptionForm',

  props: {
    formData: VueTypes.object.isRequired
  },

  data () {
    const validatePrice = (rule, value, callback) => {
      if (!isNumber(value)) {
        callback(new Error(this.$t('Coupon.DialogCouponDetail.coupon_amount_error')))
      } else if (Number(value) < 1) {
        callback(new Error(this.$t('Components.DialogVoucherDetail.validate_lowest_limit', { currency: this.currencySymbol })))
      } else {
        callback()
      }
    }

    return {
      pickerOptions: {
        disabledDate: (time) => {
          const now = new Date()
          const yesterday = now.setDate(now.getDate() - 1)

          return time.getTime() < yesterday
        }
      },
      ruleForm: {
        id: '',
        name: '',
        price: null,
        subscription_started_at: ''
      },
      rules: {
        name: [
          { required: true, message: this.$t('Subscription.DialogSubscriptionSetting.ValidateForm.validate_name'), trigger: 'blur' }
        ],
        price: [
          { required: true, message: this.$t('Subscription.DialogSubscriptionSetting.ValidateForm.validate_price'), trigger: 'blur' },
          { validator: validatePrice, trigger: 'blur' }
        ],
        subscription_started_at: [
          { required: true, message: this.$t('Subscription.DialogSubscriptionSetting.ValidateForm.validate_subscription_started_at'), trigger: 'change' }
        ]
      }
    }
  },

  computed: {
    ...mapGetters('Me', ['currencySymbol'])
  },

  watch: {
    ruleForm: {
      async handler (val) {
        this.$emit('updateForm', val)
      },
      deep: true
    }
  },

  created () {
    this.initData()
  },

  methods: {
    isKeyInNumber,
    initData () {
      const { id, name, price } = this.formData

      this.ruleForm = {
        id,
        name,
        price,
        'subscription_started_at': this.formData.subscription_started_at
          ? new Date(this.formData.subscription_started_at)
          : null
      }
    },

    validateForm () {
      return this.$refs.ruleForm.validate()
    }
  }
}
</script>

<template lang="pug" src="./SubscriptionForm.pug"></template>
