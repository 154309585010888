<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DialogImportDataGeneric',

  props: {
    uploadPath: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      fileList: [],
      fileName: '',
      validate: null,
      success: false,
      isBtnLoading: false
    }
  },

  computed: {
    ...mapGetters('Me', ['storeName', 'token', 'currentStore']),
    uploadHeaders () {
      return { Authorization: `Bearer ${this.token}` }
    },
    productSamplePath () {
      const region = this.currentStore.region.toLowerCase()
      return `/excel/product-${region}.xlsx`
    }
  },

  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },
    handleBeforeUpload (file) {
      // 交給後端驗證，前端有許多瀏覽器吃不到 mime 很容易上傳不了
      this.validate = true
      this.fileName = file.name
    },
    normalizeProducts (products) {
      const result = products.map(item => {
        // 商品分類
        let category
        if (item.categories.length) {
          category = item.categories[0].name
        } else {
          category = ''
        }

        // Keyword
        const keyword = item.default_keyword || '待串Keyword'

        // 售價
        const maxPrice = Math.floor(item.max_price)
        const minPrice = Math.floor(item.min_price)
        let priceRange
        if (maxPrice !== minPrice) {
          priceRange = `${this.currencySymbol} ${minPrice} ~ ${maxPrice}`
        } else {
          priceRange = `${this.currencySymbol} ${maxPrice}`
        }

        return Object.assign(item, { priceRange, category, keyword })
      })

      return result
    },
    importData () {
      const excel = this.$refs.uploader.uploadFiles[0].raw

      let data = new FormData()
      data.append('file', excel)
      this.isBtnLoading = true

      this.axios.post(this.uploadPath, data)
        .then((response) => {
          this.isBtnLoading = false
          const { data } = response.data

          this.$emit('handleImportProduct', this.normalizeProducts(data))
          this.$emit('refresh')
          this.$message({
            showClose: true,
            duration: 1000,
            message: this.$t('Components.DialogImportData.import_complete'),
            type: 'success'
          })
        })
        .catch((e) => {
          console.error(e)
        })
        .then(() => {
          this.success = true
          this.handleCloseDialog()
        })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
