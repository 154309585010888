import Vue from 'vue'

export const types = {
  UPDATE_HOVER_ELEMENTS: 'UPDATE_HOVER_ELEMENTS'
}

const INIT_DATA = {
  hoverElements: {}
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  hoverElements: state => state.hoverElements
}

const mutations = {
  [types.UPDATE_HOVER_ELEMENTS] (state, { key, value }) {
    Vue.set(state.hoverElements, key, value)
  }
}

const actions = {
  updateHoverElement ({ commit, getters }, { key, value }) {
    commit(types.UPDATE_HOVER_ELEMENTS, { key, value })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
