<template>
  <el-card
    class="card-border"
    :class="{'is-select': isSelected}"
  >
    <div class="cover-title">
      <template v-if="isEditing">
        <el-input
          :value="editCoverTitle"
          @input="handleTitleChange"
        />
        <span class="text-red">{{ titleError }}</span>
      </template>
      <el-tooltip
        v-else
        placement="top"
        :content="editCoverTitle"
      >
        <div class="line-clamp-2">
          {{ editCoverTitle }}
        </div>
      </el-tooltip>
    </div>
    <div class="relative text-center">
      <BaseImage
        class="cover-img"
        :src="clipVideo.image && clipVideo.image.url"
      />
      <div
        v-if="isEditing"
        class="photo-icon hover-cursor"
        @click="openCoverSettingDialog"
      >
        <i class="el-icon-camera" />
      </div>
      <div
        v-else
        class="play-icon"
        @click="$emit('openPreviewDialog')"
      >
        <i class="el-icon-video-play hover-cursor" />
      </div>
    </div>
    <div
      v-if="clipSortMode"
      class="circle-wrapper justify-center"
    >
      <div
        class="check-circle"
        :class="{'is-checked': isSelected }"
        @click="updateClipSort"
      >
        {{ clipVideo.sort }}
      </div>
    </div>
    <div
      v-else-if="!isEditing"
      class="button-wrapper justify-around items-center"
    >
      <div
        v-show="showClipSortLabel"
        class="sort-icon"
      >
        {{ `${ $t('ReplayEdit.text.clip_name')} ${clipVideo.sort}` }}
      </div>
      <div>
        <el-button
          type="default"
          size="small"
          @click="goEditMode"
        >
          {{ $t('button.edit') }}
        </el-button>
        <el-button
          type="default"
          size="small"
          @click="deleteClipVideo"
        >
          {{ $t('button.delete') }}
        </el-button>
      </div>
    </div>
    <div
      v-else
      class="button-wrapper justify-end"
    >
      <el-button
        type="default"
        size="small"
        @click="leaveEditMode"
      >
        {{ $t('ReplayEdit.button.cancel') }}
      </el-button>
      <el-button
        type="primary"
        size="small"
        :disabled="disableSaveButton"
        @click="updateClipVideo"
      >
        {{ $t('ReplayEdit.button.save') }}
      </el-button>
    </div>
  </el-card>
</template>
<script>
import VueTypes from 'vue-types'
import { noSpecialCharacter } from '@/constants/regexPattern'

export default {
  name: 'ClipVideoCard',
  props: {
    clipVideo: VueTypes.object.def({}),
    clipSortMode: VueTypes.bool.def(false),
    currentEditClipId: VueTypes.number.def(null),
    isInPreview: VueTypes.bool.def(false),
    isClipAsReplay: VueTypes.bool.def(false)
  },
  data () {
    return {
      editCoverTitle: this.clipVideo.title,
      titleError: ''
    }
  },
  computed: {
    isSelected () {
      return this.clipVideo.sort >= 1 && this.clipVideo.sort <= 4 && this.clipSortMode
    },
    disableSaveButton () {
      return this.titleError !== ''
    },
    isEditing () {
      if (this.isInPreview) return false
      return this.clipVideo.id === this.currentEditClipId
    },
    showClipSortLabel () {
      return this.clipVideo.sort && this.isClipAsReplay
    }
  },
  methods: {
    goEditMode () {
      this.$emit('goEditMode', this.clipVideo.id)
    },
    leaveEditMode () {
      this.editCoverTitle = this.clipVideo.title
      this.titleError = ''
      this.$emit('resetClipInfo')
      this.$emit('updateCurrentEditClipId', null)
    },
    deleteClipVideo () {
      this.$emit('deleteClipVideo', this.clipVideo.id)
    },
    updateClipVideo () {
      if (!this.disableSaveButton) {
        this.$emit('updateAndSaveClipVideo', {
          title: this.editCoverTitle
        })
      }
    },
    openCoverSettingDialog () {
      this.$emit('openCoverDialog')
    },
    updateClipSort () {
      this.$emit('changeSort', this.clipVideo.id)
    },
    handleTitleChange (value) {
      this.editCoverTitle = value
      if (!noSpecialCharacter.test(value)) {
        this.titleError = this.$t('ReplayEdit.text.max_title_length')
      } else {
        this.titleError = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cover-img {
  width:  153px;
  height: 272px;
}
.cover-title {
  height: 4rem;
  line-height: 1.3;
}

.photo-icon {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: $white;
  background: rgba(0,0,0,0.4);
  text-align: center;
  line-height: 40px;
  font-size: 1.2rem;
}
.play-icon {
  border-radius: 50%;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 3rem;
  color: $white;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}
.hover-cursor {
  cursor: pointer;
}
.circle-wrapper {
  display: flex;
  margin-top: 10px;
  justify-content: center;

}
.check-circle {
  width: 30px;
  height: 30px;
  border: 1px solid $gray-500;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  line-height: 29px;
  color: $white;
  text-align: center;
}
.sort-icon {
  background: $primary;
  border-radius: 1rem;
  padding: 5px 8px;
  font-size: 0.5rem;
  color: $white;
}
.button-wrapper {
  display: flex;
  margin-top: 20px;
}
.card-border {
  border: 2px solid transparent
}
.is-select {
  border-color: $primary;
}
.is-checked {
  border: 1px solid $primary;
  background: $primary;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-red {
  color: $danger;
  font-size: 0.7rem;
}
</style>
