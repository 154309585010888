<script>
import VueTypes from 'vue-types'

export default {
  name: 'FooterButton',
  props: {
    activeStep: VueTypes.number.def(0),
    editDisabled: VueTypes.bool.def(false)
  }
}
</script>

<template lang="pug" src="./FooterButton.pug"></template>
<style lang="scss" src="./FooterButton.scss" scoped></style>
