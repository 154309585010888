<script>
import VueTypes from 'vue-types'
import { mapGetters, mapActions } from 'vuex'
import HoverElement from '../HoverElement'

export default {
  name: 'SettingToggle',
  components: { HoverElement },
  props: {
    setting: VueTypes.string.isRequired,
    label: VueTypes.string.def(''),
    tip: VueTypes.string.def(''),
    disabled: VueTypes.bool.def(false)
  },
  computed: {
    ...mapGetters('LiveSetting', ['newSettings'])
  },
  methods: {
    ...mapActions('LiveSetting', ['updateNewSetting']),
    updateSettings (setting, value) {
      this.updateNewSetting({ setting, value })
      this.$emit('change', value)
    }
  }
}
</script>

<template lang="pug" src="./SettingToggle.pug"></template>
