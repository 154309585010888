<script>
import { mapGetters } from 'vuex'
import { fetchEnterpriseSetting, postEnterpriseSetting } from '../../services/apis/settings'

export default {
  name: 'ApiSettings',
  data () {
    return {
      apiInformation: {},
      apiSettings: {},
      isLoading: false,
      rules: {
        cart_endpoint: { required: true, type: 'url', trigger: 'blur' },
        domain: { type: 'url', trigger: 'blur' }
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName'])
  },
  created () {
    this.fetch()
  },
  methods: {
    copyEndPoint () {
      this.copyText({
        url: this?.apiInformation?.['api_endpoint']
      })
    },
    copyStoreToken () {
      this.copyText({
        url: this?.apiInformation?.['store_token']
      })
    },
    async validate () {
      try {
        await this.$refs.settingForm.validate()
        return true
      } catch (e) {
        return false
      }
    },
    async fetch () {
      this.isLoading = true
      try {
        const { data } = await fetchEnterpriseSetting(this.storeName)
        this.apiInformation = data?.['api_information']
        this.apiSettings = data?.['api_settings']
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    },
    async save () {
      if (!await this.validate()) return

      this.isLoading = true
      try {
        const { data: { success } } = await postEnterpriseSetting(this.storeName, this.apiSettings)
        if (success) this.fetch()
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    }
  }
}
</script>

<template lang="pug" src="./ApiSettings.pug"></template>
