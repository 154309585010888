function loadView (view) {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/views/${view}/index.vue`
    ).then(m => m.default || m)
}

export default [
  {
    path: '/develop-example',
    name: 'DevelopExample',
    meta: { trackedTitle: 'HandsUP API 範例文件', layout: 'LayoutAdmin' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/DevelopExample/DevelopExample.vue'
      ).then(m => m.default || m)
  },
  {
    path: '/contract_seller',
    name: 'ContractSeller',
    meta: { trackedTitle: 'HandsUP 商店服務條款' },
    component: loadView('Contract')
  },
  {
    path: '/contract_buyer',
    name: 'ContractBuyer',
    meta: { trackedTitle: 'HandsUP 商店服務條款' },
    component: loadView('Contract')
  },
  {
    path: '/user/register',
    name: 'UserRegister',
    meta: { trackedTitle: '賣家註冊', layout: 'LayoutUser' },
    component: () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/views/User/Register/Register.vue`
    ).then(m => m.default || m)
  },
  {
    path: '/user/login',
    name: 'UserLogin',
    meta: { trackedTitle: '賣家登入', layout: 'LayoutUser' },
    component: () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/views/User/Login/Login.vue`
    ).then(m => m.default || m)
  },
  {
    path: '/user/email-verify',
    name: 'UserEmailVerify',
    meta: { trackedTitle: '賣家Email驗證', layout: 'LayoutUser' },
    component: () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/views/User/EmailVerify/EmailVerify.vue`
    ).then(m => m.default || m)
  },
  {
    path: '/user/forgot-password',
    name: 'UserForgotPassword',
    meta: { trackedTitle: '會員忘記密碼', layout: 'LayoutUser' },
    component: () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/views/User/ForgotPassword/ForgotPassword.vue`
    ).then(m => m.default || m)
  },
  {
    path: '/user/forgot-password-sent',
    name: 'UserForgotPasswordSent',
    meta: { trackedTitle: '會員忘記密碼', layout: 'LayoutUser' },
    component: () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/views/User/ForgotPasswordSent/ForgotPasswordSent.vue`
    ).then(m => m.default || m)
  },
  {
    path: '/user/change-password',
    name: 'UserChangePassword',
    meta: { trackedTitle: '會員更改密碼', layout: 'LayoutUser' },
    component: () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/views/User/ChangePassword/ChangePassword.vue`
    ).then(m => m.default || m)
  },
  {
    path: '/register',
    name: 'Register',
    meta: { trackedTitle: '註冊' },
    component: loadView('Register')
  },

  {
    path: '/invite/:token',
    name: 'Invite',
    meta: { trackedTitle: '加入管理員' },
    component: loadView('Invite')
  },
  {
    path: '/invite-parent/:token',
    name: 'InviteParent',
    meta: { trackedTitle: '加入母商店' },
    component: () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/views/InviteParent/InviteParent.vue`
    ).then(m => m.default || m)
  },
  {
    path: '/celebrity/:token',
    name: 'celebrity',
    meta: { trackedTitle: '網紅授權' },
    component: loadView('Celebrity')
  },
  {
    path: '/postagent/:celebritytoken',
    name: 'PostAgent',
    meta: { trackedTitle: '網紅銷售' },
    component: loadView('Post/views/Show')
  },
  {
    path: '/app/download',
    name: 'AppDownload',
    meta: { trackedTitle: 'APP下載' },
    component: loadView('AppDownload')
  },
  {
    path: '/',
    name: 'Login',
    meta: { trackedTitle: '登入' },
    component: loadView('Login')
  },
  {
    path: '/store_register',
    name: 'StoreRegister',
    meta: { trackedTitle: '登入' },
    component: loadView('Login')
  },
  {
    path: '/influencer_register',
    name: 'RegisterInfluencer',
    meta: {
      trackedTitle: 'InfluencerRegister'
    },
    component: () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/views/RegisterInfluencer`
    ).then(m => m.default || m)
  },
  /**
   * For 台灣業務，在用戶登入之前，
   * 確認是否已有店？
   * 有店：進後台
   * 無店：導去 Google Form
   */
  {
    path: '/login_store',
    name: 'LoginConfirm',
    redirect: { name: 'UserLogin' }
  },
  {
    path: '/e/:enterPriseType',
    name: 'Enterprise',
    meta: { trackedTitle: '登入' },
    component: loadView('Login')
  },
  {
    path: '/error/permission',
    name: 'Permission',
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/ErrorPages/Permission'
      ).then(m => m.default || m)
  },
  {
    path: '/detector',
    name: 'BroadcastDetector',
    component: loadView('BroadcastDetector')
  },
  {
    path: '/403',
    name: '403',
    component: loadView('ErrorPages/403')
  },
  {
    path: '/404',
    name: '404',
    component: loadView('ErrorPages/404')
  },
  {
    path: '/:store',
    name: 'Dashboard',
    meta: { trackedTitle: 'Dashboard', layout: 'LayoutAdmin' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/Dashboard'
      ).then(m => m.default || m)
  },
  {
    path: '/:store/posts',
    name: 'Home',
    meta: { trackedTitle: '銷售', layout: 'LayoutAdmin' },
    component: loadView('Home')
  },
  {
    path: '/:store/live-setting',
    name: 'LiveSetting',
    meta: { trackedTitle: '直播設定' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/LiveSetting'
      ).then(m => m.default || m)
  },
  {
    path: '/:store/posts/create',
    name: 'PostCreate',
    meta: { trackedTitle: '貼文', layout: 'LayoutAdmin' },
    component: loadView('Post/views/Create')
  },
  {
    path: '/:store/posts/:post',
    name: 'PostShow',
    meta: { trackedTitle: '貼文', layout: 'LayoutDefault' },
    component: loadView('Post/views/Show')
  },
  {
    path: '/:store/posts/:post/replay-edit',
    name: 'PostReplayEdit',
    meta: { trackedTitle: '回放編輯', layout: 'LayoutDefault' },
    component: loadView('Post/views/ReplayEdit'),
    beforeEnter: (to, from, next) => {
      if (from.name !== 'PostShow') next({ name: 'PostShow', params: { ...to.params } })
      else next()
    }
  },
  {
    path: '/:store/market',
    name: 'Market',
    meta: { trackedTitle: '賣場', layout: 'LayoutAdmin' },
    component: loadView('Market')
  },
  {
    path: '/:store/product',
    name: 'ProductList',
    meta: { trackedTitle: '商品列表', layout: 'LayoutAdmin' },
    component: loadView('ProductList')
  },
  {
    path: '/:store/product-detail',
    name: 'ProductDetail',
    meta: { trackedTitle: '商品明細', layout: 'LayoutAdmin' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/ProductDetail'
      ).then(m => m.default || m)
  },
  {
    path: '/:store/product/create',
    name: 'ProductCreate',
    meta: { trackedTitle: '商品新增', layout: 'LayoutAdmin' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/ProductDetail'
      ).then(m => m.default || m)
  },
  {
    path: '/:store/ticket',
    name: 'Ticket',
    meta: { trackedTitle: '票卷管理', layout: 'LayoutAdmin' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/Ticket'
      ).then(m => m.default || m)
  },
  {
    path: '/:store/coupon',
    name: 'Coupon',
    meta: { trackedTitle: '折扣代碼', layout: 'LayoutAdmin' },
    component: loadView('Coupon')
  },
  {
    path: '/:store/voucher',
    name: 'Voucher',
    meta: { trackedTitle: '折扣券', layout: 'LayoutAdmin' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/Voucher'
      ).then(m => m.default || m)
  },
  {
    path: '/:store/subscription',
    name: 'Subscription',
    meta: { trackedTitle: '訂閱制票券', layout: 'LayoutAdmin' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/Subscription'
      ).then(m => m.default || m)
  },
  {
    path: '/:store/e/order',
    name: 'OrderListEnterPrise',
    meta: { trackedTitle: '訂單', layout: 'LayoutAdmin' },
    component: loadView('OrderEnterPrise')
  },
  {
    path: '/:store/order',
    name: 'OrderList',
    meta: { trackedTitle: '訂單', layout: 'LayoutAdmin' },
    component: loadView('Order/List')
  },
  {
    path: '/:store/order/manual',
    name: 'OrderManual',
    meta: { trackedTitle: '新增訂單', layout: 'LayoutAdmin' },
    component: loadView('OrderManual')
  },
  {
    path: '/:store/order/split',
    name: 'SplitList',
    meta: { trackedTitle: '拆單', layout: 'LayoutAdmin' },
    component: () =>
    import(
      /* webpackChunkName: "view-[request]" */ '@/views/Order/SplitList'
    ).then(m => m.default || m)
  },
  {
    path: '/:store/batch-shipping',
    name: 'BatchShipping',
    meta: { trackedTitle: '批次出貨', layout: 'LayoutAdmin' },
    component: loadView('Order/BatchShipping')
  },
  {
    path: '/:store/batch-approve',
    name: 'BatchApprove',
    meta: { trackedTitle: '批次審核', layout: 'LayoutAdmin' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/Order/BatchApprove'
      ).then(m => m.default || m)
  },
  {
    path: '/:store/batch-approve-print',
    name: 'BatchApprovePrint',
    meta: { trackedTitle: '列印批次審核', layout: 'LayoutDefault' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/Order/BatchApprovePrint'
      ).then(m => m.default || m)
  },
  {
    path: '/:store/order/print',
    name: 'Print',
    meta: { trackedTitle: '批次列印', layout: 'LayoutDefault' },
    component: loadView('Order/BatchPrint')
  },
  {
    path: '/:store/order/:order',
    name: 'OrderShow',
    meta: { trackedTitle: '訂單明細', layout: 'LayoutAdmin' },
    component: loadView('Order/Show')
  },
  {
    path: '/:store/hsinchu-print',
    name: 'HsinchuPrint',
    meta: { trackedTitle: '出貨總表', layout: 'LayoutDefault' },
    component: loadView('Order/HsinchuPrint')
  },
  {
    path: '/:store/user',
    name: 'User',
    meta: { trackedTitle: '個人資料', layout: 'LayoutAdmin' },
    component: () =>
    import(
      /* webpackChunkName: "view-[request]" */ `@/views/User/Information/Information.vue`
    ).then(m => m.default || m)
  },
  {
    path: '/:store/member',
    name: 'MemberList',
    meta: { trackedTitle: '會員', layout: 'LayoutAdmin' },
    component: loadView('Member/List')
  },
  {
    path: '/:store/member/:member',
    name: 'MemberShow',
    meta: { trackedTitle: '會員', layout: 'LayoutAdmin' },
    component: loadView('Member/Show')
  },
  {
    path: '/:store/setting',
    name: 'Setting',
    meta: { trackedTitle: '設定', layout: 'LayoutAdmin' },
    component: loadView('Setting')
  },
  {
    path: '/:store/gmo',
    name: 'GmoForm',
    meta: { trackedTitle: 'GmoForm', layout: 'LayoutAdmin' },
    component: loadView('GmoForm')
  },
  {
    path: '/:store/plans/expired',
    name: 'PlanExpired',
    meta: { trackedTitle: '方案過期', layout: 'LayoutAdmin' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/PlanExpired'
      ).then(m => m.default || m)
  },
  {
    path: '/:store/plans/select',
    name: 'PlanSelect',
    meta: { trackedTitle: '方案', layout: 'LayoutAdmin' },
    component: loadView('Plan/SelectContainer')
  },

  {
    path: '/:store/plans/payment',
    name: 'PlanPayment',
    meta: { trackedTitle: '續約', layout: 'LayoutAdmin' },
    component: loadView('Plan/Payment')
  },
  {
    path: '/:store/payment',
    name: 'Payment',
    meta: { trackedTitle: '付款', layout: 'LayoutAdmin' },
    component: loadView('Payment')
  },

  {
    path: '/:store/account-management-instructions',
    name: 'AccountManagementInstructions',
    meta: { trackedTitle: '帳務管理說明', layout: 'LayoutAdmin' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/AccountManagementInstructions'
      ).then(m => m.default || m)
  },

  {
    path: '/:store/account-management/:tab?',
    name: 'AccountManagement',
    meta: { trackedTitle: '帳務管理', layout: 'LayoutAdmin' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/AccountManagement'
      ).then(m => m.default || m)
  },
  {
    path: '/:store/onboarding',
    name: 'Onboarding',
    meta: { trackedTitle: '指南', layout: 'LayoutAdmin' },
    component: () =>
      import(
        /* webpackChunkName: "view-[request]" */ '@/views/Onboarding'
      ).then(m => m.default || m)
  },

  {
    path: '*',
    redirect: { name: '404' }
  }
]
