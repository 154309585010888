import Messages from './Messages'
import ChannelNames from './ChannelNames'
import SendMessage from './SendMessage'
import Channels from './Channels'

export default {
  namespaced: true,
  modules: {
    Messages,
    ChannelNames,
    SendMessage,
    Channels
  }
}
