<script>
import VueTypes from 'vue-types'
import { IMG_MAP } from './imgMap'

const CANVAS_SIZE = {
  width: 100,
  height: 480
}
const LIMIT_MAX = 7 // 動畫最大量
const IMAGE_SIZE = 36 // 動畫愛心大小
const IMAGE_OPACITY = 0.8 // 預設透明度

export default {
  name: 'InteractionAnimation',
  props: {
    height: VueTypes.number.def(480)
  },
  data () {
    return {
      CANVAS_SIZE,
      queue: [],
      isProcessing: false,
      ctx: null,
      myReq: null,
      seasonalTriggerCount: 0
    }
  },

  watch: {
    queue: {
      immediate: true,
      handler (value) {
        if (!this.isProcessing && this.queue.length) {
          this.myReq = requestAnimationFrame(this.render)
          this.isProcessing = true
        } else if (this.queue.length === 0 && this.myReq) {
          cancelAnimationFrame(this.myReq)
          this.isProcessing = false
        }
      }
    }
  },

  mounted () {
    this.ctx = this.$refs.animationCanvas.getContext('2d')
  },

  beforeDestroy () {
    if (this.myReq) {
      cancelAnimationFrame(this.myReq)
    }
  },

  methods: {
    calcImageInfo (imageObj) {
      // 刷新重繪
      imageObj.context.clearRect(0, 0, IMAGE_SIZE, IMAGE_SIZE)
      imageObj.context.globalAlpha = imageObj.context.globalAlpha - 0.005 // 逐漸降低動畫愛心的透明度
      imageObj.context.drawImage(imageObj.imageRaw, 0, 0)

      // 偏移方向
      const displacementCoordinates = imageObj.isForward
        ? imageObj.velocity.x + imageObj.acceleration.x // 偏左
        : imageObj.velocity.x - imageObj.acceleration.x // 偏右

      // 偏移座標
      imageObj.position.x -= displacementCoordinates
      imageObj.position.y -= (imageObj.velocity.y + imageObj.acceleration.y)

      // 是否超出邊界
      switch (true) {
        case imageObj.position.x < 0 :
        case imageObj.position.x > CANVAS_SIZE.width :
        case imageObj.position.y < 0 :
        case imageObj.position.y > CANVAS_SIZE.height :
          imageObj.active = false
          break
        default:
          imageObj.active = true
      }
    },

    motion () {
      this.queue.forEach((imageObj, index) => {
        // 重新計算 Heart 資料
        this.calcImageInfo(imageObj)

        // 判斷是否超出邊界
        if (imageObj.active) {
          this.draw(imageObj)
        } else {
          delete this.queue.splice(index, 1)
        }
      })
    },

    draw (imageObj) {
      this.ctx.drawImage(imageObj.canvas, imageObj.position.x, imageObj.position.y)
    },

    render () {
      this.$refs.animationCanvas.width = this.$refs.animationCanvas.width
      this.motion()
      this.myReq = requestAnimationFrame(this.render)
    },

    createImage (imgType, yPosition) {
      const imageRaw = new Image()
      const imgTypeMap = IMG_MAP[imgType]
      imageRaw.src = imgTypeMap[Math.floor(Math.random() * Object.keys(imgTypeMap).length) + 1]

      const dynamicCanvas = document.createElement('canvas')
      const dynamicContext = dynamicCanvas.getContext('2d')
      dynamicCanvas.height = IMAGE_SIZE
      dynamicCanvas.width = IMAGE_SIZE

      dynamicContext.globalAlpha = IMAGE_OPACITY
      dynamicContext.drawImage(imageRaw, 0, 0)

      let imageObj = {
        imageRaw: imageRaw,
        canvas: dynamicCanvas,
        context: dynamicContext,
        active: true,
        position: {
          x: 60,
          y: yPosition
        },
        isForward: !!Math.round(Math.random()), // 偏左或偏右
        velocity: { // 等速
          x: 0,
          y: 2
        },
        acceleration: { // 加速度
          x: Math.random() * 0.3,
          y: Math.random() * 1.4
        }
      }

      return imageObj
    },

    triggerSeasonal (imgType, yPosition) {
      // User 端手動觸發
      this.updateSeasonalTriggerCount(this.seasonalTriggerCount + 1)

      if (this.queue.length > LIMIT_MAX) return

      this.queue.push(this.createImage(imgType, yPosition))
    },

    updateSeasonalTriggerCount (count) {
      this.seasonalTriggerCount = count
    }
  }
}
</script>

<template lang="pug">
.interactionAnimation.pointer-events-none
  canvas(ref='animationCanvas', :width='CANVAS_SIZE.width', :height='CANVAS_SIZE.height')
</template>

<style scoped>
.interactionAnimation {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
</style>
