<script>

import { mapActions, mapGetters } from 'vuex'
import { LOADING } from '@/constants/loadingState'
import PaginationPure from '@/components/PaginationPure'

export default {
  name: 'ApiPostback',
  components: { PaginationPure },
  data () {
    return {
      LOADING,
      expandedRows: []
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol']),
    ...mapGetters('ApiSetting', ['query', 'results', 'meta', 'postbackLoadingState']),
    dates: {
      get () {
        return [this.query.from, this.query.to]
      },
      set (val) {
        const [from = '', to = ''] = val || []
        this.updateQuery({ from, to })
      }
    },
    status: {
      get () {
        return this.query.status
      },
      set (status) {
        this.updateQuery({ status })
      }
    },
    param: {
      get () {
        return this.query.param
      },
      set (param) {
        this.updateQuery({ param })
      }
    }
  },
  created () {
    this.fetchResults()
  },
  methods: {
    ...mapActions('ApiSetting', ['fetchResults', 'updateQuery']),
    toggleRow (id) {
      if (this.expandedRows.includes(id)) {
        this.expandedRows = this.expandedRows.filter(rowId => rowId !== id)
      } else {
        this.expandedRows.push(id)
      }
    },
    handleUpdateRows (page) {
      this.updateQuery({ page })
      this.fetchResults()
    },
    updateData: _.debounce(function () {
      this.fetchResults()
    }, 300),
    getTotalPrice (items) {
      return items.reduce((acc, val) => {
        acc += val.price * val.quantity
        return acc
      }, 0)
    }
  }
}
</script>

<template lang="pug" src="./ApiPostback.pug"></template>
<style lang="scss" src="./ApiPostback.scss" scoped></style>
