import { render, staticRenderFns } from "./DialogNewFeature.pug?vue&type=template&id=3189dfdc&scoped=true&lang=pug&"
import script from "./DialogNewFeature.vue?vue&type=script&lang=js&"
export * from "./DialogNewFeature.vue?vue&type=script&lang=js&"
import style0 from "./DialogNewFeature.scss?vue&type=style&index=0&id=3189dfdc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3189dfdc",
  null
  
)

export default component.exports