// This module is in charge of pubnub logic. The pubnub subscribing
// logic is handled in 'init' action.
import Pubnub from 'pubnub'

import { HANDSUP } from '@/constants/channelType'

const types = {
  SUBSCRIBE_CHANNEL: 'SUBSCRIBE_CHANNEL',
  UNSUBSCRIBE_CHANNEL: 'UNSUBSCRIBE_CHANNEL'
}

const state = {}

const mutations = {
  [types.SUBSCRIBE_CHANNEL] (state) {
    state = { ...state }
  },

  [types.UNSUBSCRIBE_CHANNEL] (state) {
    state = { ...state }
  }
}

const pubnub = new Pubnub({
  subscribeKey: process.env.VUE_APP_PUBNUB_SUBSCRIBE_KEY
})

const blockerRegistry = {}

// Contains pubnub message listener object reference.
// This listener object will be used to remove punub
// event listener.
// @see https://www.pubnub.com/docs/web-javascript/pubnub-javascript-sdk
let listenerRefs = {}

const actions = {
  subscribe ({ dispatch }, { channelGroup, publications }) {
    // PubNub Unsubscribe：
    // https://www.pubnub.com/docs/web-javascript/api-reference-publish-and-subscribe#unsubscribe_all
    // subscribe pubnub channel
    pubnub.subscribe({
      channelGroups: [channelGroup],
      withPresence: true
    })

    // 僅針對 Handsup APP 開播，才取得 觀看直播人數
    const handsupChannel = publications.find(publication => publication.channel.type === HANDSUP)

    if (handsupChannel) {
      const slug = handsupChannel.target

      // 觀看直播人數 只需要監聽 handsup channel
      pubnub.hereNow(
        {
          channels: [`comment|handsup|${slug}`]
        },
        (status, response) => {
          dispatch('Post/updatePubnubViewersCount', response.totalOccupancy, { root: true })
        }
      )
    }

    listenerRefs = {
      message: m => dispatch('ChatMessage/Messages/appendMessage', { message: m }, { root: true }),
      presence: presenceEvent => {
        const { channel, occupancy } = presenceEvent

        if (_.startsWith(channel, 'comment|handsup|')) {
          dispatch('Post/updatePubnubViewersCount', occupancy, { root: true })
        }
      }
    }

    // pubnub add a listener to handle incoming messages
    // dispatch action from another module
    pubnub.addListener(listenerRefs)

    function * chan () {
      yield ''
    }

    blockerRegistry[channelGroup] = chan()
  },

  unsubscribe (_, { channelGroup }) {
    pubnub.removeListener(listenerRefs)
    pubnub.unsubscribeAll()
    const chan = blockerRegistry[channelGroup]
    chan.next()
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
