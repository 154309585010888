import { ORDERER_TYPE } from '@/constants/orderManual'

export const actionTypes = {
  UPDATE_ORDERER_TYPE: 'UPDATE_ORDERER_TYPE',
  UPDATE_CONTACT_EMAIL: 'UPDATE_CONTACT_EMAIL',
  UPDATE_ORDERER_NAME: 'UPDATE_ORDERER_NAME',
  IS_GUEST_INVITED: 'IS_GUEST_INVITED',
  UPDATE_MEMBER_INFO: 'UPDATE_MEMBER_INFO',
  RESET: 'RESET',
  RESTORE_CACHE: 'RESTORE_CACHE'
}

const INIT_DATA = {
  ordererType: ORDERER_TYPE.SelectFromMembers,
  ordererName: '',
  contactEmail: '',
  memberSlug: '',
  isGuestInvited: false,
  memberInfo: {}
}

const state = _.cloneDeep(INIT_DATA)

const mutations = {
  [actionTypes.UPDATE_ORDERER_TYPE] (state, payload) {
    state.ordererType = payload
  },
  [actionTypes.UPDATE_CONTACT_EMAIL] (state, payload) {
    state.contactEmail = payload
  },
  [actionTypes.UPDATE_ORDERER_NAME] (state, payload) {
    state.ordererName = payload
  },
  [actionTypes.IS_GUEST_INVITED] (state, payload) {
    state.isGuestInvited = payload
  },
  [actionTypes.UPDATE_MEMBER_INFO] (state, payload) {
    state.memberSlug = payload.slug
    state.memberInfo = _.cloneDeep(payload)
  },
  [actionTypes.RESET] (state) {
    Object.assign(state, INIT_DATA)
  },
  [actionTypes.RESTORE_CACHE] (state, payload = {}) {
    state.ordererType = payload.ordererType
    state.ordererName = payload.ordererName
    state.contactEmail = payload.contactEmail
    state.memberSlug = payload.memberSlug
    state.memberInfo = payload.memberInfo
  }
}

const actions = {
  updateOrdererType ({ commit }, payload) {
    commit(actionTypes.UPDATE_ORDERER_TYPE, payload)
  },
  updateOrdererName ({ commit }, payload) {
    commit(actionTypes.UPDATE_ORDERER_NAME, payload)
  },
  updateMemberInfo ({ commit }, payload) {
    commit(actionTypes.UPDATE_MEMBER_INFO, payload)
  },
  updateContactEmail ({ commit }, payload) {
    commit(actionTypes.UPDATE_CONTACT_EMAIL, payload)
  },
  inviteGuest ({ commit }, payload) {
    commit(actionTypes.IS_GUEST_INVITED, payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
