<script>
import { mapGetters } from 'vuex'
import * as SETTINGS from '@/constants/storeSettings'
import logo from '../../../../images/logo.svg'
import HighlightElement from '../HighlightElement'

export default {
  name: 'SellingProduct',
  components: { HighlightElement },
  data () {
    return {
      logo,
      SETTINGS
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol']),
    ...mapGetters('LiveSetting', ['newSettings', 'hoverElements']),
    enabledLivewebSalesProduct () {
      return this.newSettings[SETTINGS.SALES_PRODUCT_SHOW]
    }
  }
}
</script>

<template lang="pug" src="./SellingProduct.pug"></template>
<style lang="scss" src="./SellingProduct.scss" scoped></style>
