<script>

import ApiPost from '@/api/post'
import ApiProduct from '@/api/product'
import { mapGetters, mapActions } from 'vuex'

import { getPublication } from '@/utils/getPublication'
import DialogPickProduct from '@/components/DialogPickProduct'
import DialogPickProductGeneric from '@/components/DialogPickProductGeneric'

import ProductTable from '../../components/ProductTable'
import PostPushNotification from '../../components/PushNotification'

export default {
  name: 'Ready',
  components: {
    DialogPickProduct,
    DialogPickProductGeneric,
    ProductTable,
    PostPushNotification
  },
  data () {
    return {
      apiPost: new ApiPost(),
      apiProduct: new ApiProduct(),
      post: null,
      dialogPickProductGenericVisible: false,
      dialogSetCheckoutTimeVisible: false
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'currentStore', 'isEnterprise', 'isEnterpriseGeneric', 'enterpriseSlug', 'enabledMerchandise']),
    postId () {
      return this.$route.params.post
    },

    isPublished () {
      return !!getPublication(this.post.publications)
    },

    publication () {
      let data = { embed_html: null, live_status: null }

      const publication = getPublication(this.post.publications)

      if (publication) {
        data = {
          embed_html: publication.info ? publication.info.embed_html : null,
          live_status: publication.live_status
        }
      }

      return data
    },
    products () {
      if (!this.post) return []

      return this.post.products
        .filter(product => product.status !== 'deleted')
        .map(product => {
          // 關鍵字
          product.keyword = product.keywords.length > 0 ? product.keywords[0].keyword : null
          return product
        })
    }
  },
  async mounted () {
    await this.fetchAll()
  },
  methods: {
    ...mapActions(['setLoading']),
    async fetchAll () {
      this.setLoading(true)
      await this.fetchPost()
      this.setLoading(false)
    },
    async fetchPost () {
      let data = await this.apiPost.get(this.postId)

      if (!data) {
        this.$message.error(this.$t('Post.Show.message.get_post_fail'))
        this.$router.push(`/${this.storeName}/`)
      }

      this.post = data
    },
    async handlePickProduct (data) {
      try {
        // 新增挑選商品
        const selectedProducts = data
        const selectedProductIds = selectedProducts.map(product => product.id)

        // 可售數量 & 限購數量
        const quantities = selectedProducts.reduce((prev, product) => {
          const skusQuantity = product.skus.map(sku => {
            return {
              id: sku.id,
              type: 'sku',
              salable_quantity: sku.available_quantity,
              salable_limit: sku.available_quantity
            }
          })

          return [...prev, ...skusQuantity]
        }, [])

        const api = `/api/v1/posts/${this.postId}`

        await this.axios.put(api, { product_ids: selectedProductIds, quantities })

        this.fetchAll()
      } catch (e) {
        this.$message.error(this.$t('Post.Show.message.live_end'))
        console.error(e)
      }
    },

    async handleDeleteProduct (item) {
      const success = await this.apiProduct.deletePostProduct(this.postId, item.id)

      if (success) {
        this.$message({
          type: 'success',
          message: this.$t('Post.Show.message.delete_success'),
          duration: 2000,
          showClose: true
        })

        this.fetchPost()
      }
    },
    async handleUpdateProduct (item) {
      this.setLoading(true)

      try {
        await Promise.all([
          this.updateProduct(item),
          this.updatePostProduct(item)
        ])
      } catch (e) {
        this.$message.error(e.message)
        this.setLoading(false)
        return
      }

      await this.fetchAll()

      this.setLoading(false)
    },
    async updateProduct (item) {
      const apiProduct = new ApiProduct()
      const payload = {
        name: item.name,
        skus: item.skus,
        image_ids: item.image_ids,
        description: item.description,
        adult_warning: item.adult_warning,
        keyword: item.default_keyword
      }
      const success = await apiProduct.update(item.id, payload)
      if (!success) {
        throw new Error(this.$t('message.save_fail'))
      }
    },
    async updatePostProduct (item) {
      const product = this.products.find(product => product.id === item.id)

      let params = { product_ids: [product.id] }

      // 關鍵字
      if (product.keyword !== item.default_keyword) {
        params.keywords = [{
          id: item.id,
          type: 'product',
          keyword: item.default_keyword
        }]
      }

      // 可售數量 & 限購數量
      params.quantities = item.skus.map(sku => {
        return {
          id: sku.id,
          type: 'sku',
          salable_limit: sku.salable_limit
        }
      })

      const success = await this.apiPost.update(this.postId, params)
      if (!success) {
        throw new Error(this.$t('Post.Show.message.keyword_repeat'))
      }
    },
    async handleVideoCreate (liveFormData) {
      const channelIds = _.get(liveFormData, 'form.channel_ids', null)
      const postData = Object.assign(liveFormData, {
        channel_ids: channelIds ? [channelIds] : [],
        live: true
      })

      this.setLoading(true)

      try {
        const success = liveFormData.mode === 'postAgent'
          ? await this.apiPost.updatePostAgent(this.postId, postData)
          : await this.apiPost.update(this.postId, postData)

        if (success) {
          if (postData.channel_ids.length) {
            this.$refs.PostPushNotification.handleCompleted(this.postId, () => {
              // this.fetchAll()
              this.$emit('readyCreate')
            })
          } else {
            this.$router.push({ name: 'Home' })
          }
        } else {
          this.$message.error(this.$t('Post.Show.message.create_post_fail'))
        }
      } catch (e) {
        console.error(e)
      }

      this.setLoading(false)
    },
    async handleUpdateCartItemLife (cartItemLife) {
      let params = { cart_item_life: cartItemLife }

      const success = await this.apiPost.update(this.postId, params)
      this.dialogSetCheckoutTimeVisible = false

      if (success) {
        this.fetchAll()
      }
    },
    copyLink () {
      const copyUrl = `${this.$VUE_APP_BUYER_DOMAIN_NAME}/${this.storeName}/live/${this.postId}?openExternalBrowser=1`
      this.copyText({
        url: copyUrl,
        success: this.$t('Post.Show.message.copy_link'),
        error: this.$t('Post.Show.message.copy_fail')
      })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
