<script>
import Cookies from 'js-cookie'
import ApiPost from '@/api/post'
import ApiChannel from '@/api/channel'
import ApiSetting from '@/api/setting'

import { mapGetters, mapActions } from 'vuex'
import { loadMessages } from '@/plugins/i18n'
import AppSteps from '@/components/AppSteps'
import BaseImage from '@/components/BaseImage'
import ProductTable from './components/ProductTable'

export default {
  skipMiddleware: ['checkAuth'],
  name: 'Celebrity',
  components: { AppSteps, BaseImage, ProductTable },
  data () {
    return {
      token: '',
      activeStep: 0,
      postAgent: null,
      hasCreatedStore: false,
      form: {
        first_name: '',
        last_name: '',
        email: '',
        locale: 'zh_TW',
        currency: 'TWD',
        timezone: 'Asia/Taipei'
      },
      rules: {
        email: [
          { type: 'email', required: true, message: this.$t('Celebrity.validate_email'), trigger: 'blur' }
        ]
      },
      fbChannels: [],
      boundChannels: [],
      selectChannelId: null,
      firstLiveData: null,
      target: null,
      selectLivePosts: [],
      isLoading: false,
      isBtnLoading: false,
      isSmallDevice: false
    }
  },
  computed: {
    ...mapGetters('Lang', ['locale']),
    ...mapGetters('Me', ['me', 'stores', 'isLogin', 'currentStore', 'apiUrl']),
    steps () {
      return [
        { title: this.$t('Celebrity.step_1') },
        { title: this.$t('Celebrity.step_2') },
        { title: this.$t('Celebrity.step_3') }
      ]
    },
    getProducts () {
      return this.postAgent ? this.postAgent.post.products : []
    },
    filterFBChannels () {
      return this.fbChannels.filter(channel => channel.type === 'fb_page')
    },
    getSelectChannel () {
      if (this.fbChannels.length === 0 || !this.selectChannelId) return ''

      return this.fbChannels.find(channel => channel.id === this.selectChannelId)
    },
    canNextStep () {
      if (this.activeStep === 0 && !this.selectChannelId) return false

      if (this.activeStep === 2 && !this.target) return false

      return true
    },
    isPostActive () {
      // 貼文是否已發佈直播過
      return this.postAgent === null || !['unbound', 'bound'].includes(this.postAgent.status)
    },
    isBoundFBChannel () {
      return this.boundChannels.some(channel => channel.endpoint === this.selectChannelId)
    }
  },
  async mounted () {
    this.setLoading(true)

    // 授權 Token
    this.token = localStorage.getItem('localCelebrity') || this.$route.params.token
    localStorage.removeItem('localCelebrity')

    // 建議裝置提醒
    this.deviceReminder()

    // 貼文資料
    await this.fetchPostAgent()

    // 確認貼文目前的狀態
    this.checkPostStatus()

    // 確認是否已註冊
    if (this.isLogin) this.checkRegistered()

    this.setLoading(false)
  },
  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('Me', ['fetchMe', 'fetchStores']),
    ...mapActions('Lang', ['setLocale']),
    deviceReminder () {
      if (window.screen.width < 768) {
        this.$confirm(`
        <i class="far fa-info-circle text-5xl text-warning mb-3"></i>
        <h5 class="mb-0">${this.$t('Celebrity.message.size_notice1')}</h5>
        <h5 class="mb-0">${this.$t('Celebrity.message.size_notice2')}</h5>
        <h5>${this.$t('Celebrity.message.size_notice3')}</h5>
        `, '', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t('button.confirm'),
          showCancelButton: false,
          center: true,
          confirmButtonClass: 'w-40'
        }).then(() => {
        }).catch(() => {
        })
      }
    },
    async setRegionLocale (region) {
      const map = {
        TW: 'zh-TW',
        JP: 'ja',
        VN: 'vi',
        TH: 'th'
      }
      const locale = map[region] || 'zh-TW'
      await this.setLocale({ locale: locale })
      await loadMessages(locale)
    },
    async fetchPostAgent () {
      const apiPost = new ApiPost()
      const params = { token: this.token }

      try {
        const { data } = await apiPost.getPostAgent(params)
        this.postAgent = data
        this.setRegionLocale(this.postAgent.store.region)
      } catch (e) {
        const { code, message } = e.data

        // 此網紅連結不存在，或已經失效
        if (code === 404) return

        // 此直播貼文已被綁定
        this.$confirm(`<h5>${message}</h5>`, '', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t('button.confirm'),
          showCancelButton: false,
          center: true,
          confirmButtonClass: 'w-40'
        }).then(() => {
          location.href = this.$VUE_APP_BUYER_DOMAIN_NAME
        }).catch(() => {
          location.href = this.$VUE_APP_BUYER_DOMAIN_NAME
        })
      }
    },
    checkPostStatus () {
      // unbound 未綁定
      // bound 已綁定
      // live 直播中
      // ended 已結束
      // expired 連結過期失效

      if (this.postAgent === null) {
        // 無此連結
        this.$router.push({ name: '403' })
        return
      }

      const status = this.postAgent.status

      if (['unbound', 'bound'].includes(status)) return

      const isStoreOwner = this.stores.some(store => {
        if (store.pivot && store.pivot.type !== 'owner') return false

        const postAgentSlug = this.postAgent.store && this.postAgent.store.slug

        if (store.slug === postAgentSlug) return true
      })

      // 您是本場直播的管理員 確認進入授權直播畫面？
      if (isStoreOwner) {
        this.$confirm(`
        <h5 class="mb-0">${this.$t('Celebrity.post_owner_description1')}</h5>
        <h5>${this.$t('Celebrity.post_owner_description2')}</h5>
        `, '', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t('Celebrity.post_owner_submit'),
          showCancelButton: false,
          center: true,
          confirmButtonClass: 'w-40'
        }).then(() => {
          this.$router.push({ path: `/postagent/${this.token}` })
        }).catch(() => {
          this.$router.push({ path: `/postagent/${this.token}` })
        })
        return
      }

      if (status === 'live') {
        // 直播中
        this.$router.push({ path: `/postagent/${this.token}` })
        return
      }

      const statusDescription = {
        ended: `<h5>${this.$t('Celebrity.post_ended')}</h5>`,
        expired: `<h5>${this.$t('Celebrity.post_expired')}</h5>`
      }

      const msgTitle = statusDescription[status] || `${this.$t('Celebrity.current_status')}：${status}`

      this.$confirm(msgTitle, '', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: this.$t('button.confirm'),
        showCancelButton: false,
        center: true,
        confirmButtonClass: 'w-40'
      }).then(() => {
        location.href = this.$VUE_APP_BUYER_DOMAIN_NAME
      }).catch(() => {
        location.href = this.$VUE_APP_BUYER_DOMAIN_NAME
      })
    },
    async checkRegistered () {
      // 待確認已註冊過(type: "owner")
      this.hasCreatedStore = this.stores
        .map(store => store.pivot.type)
        .find(type => type === 'owner') !== undefined

      if (this.hasCreatedStore) {
        await this.fetchBoundChannels() // HandsUP 已綁定粉專
        await this.fetchFBChannels() // 取得 FB 粉專
      } else {
        // 註冊之預設值
        const { first_name: firstName = '', last_name: lastName = '', email = '' } = this.me

        this.form = Object.assign(this.form, {
          first_name: firstName,
          last_name: lastName,
          email
        })
      }
    },
    handleLogin () {
      // 紀錄前往的路徑
      localStorage.setItem('localCelebrity', this.$route.params.token)

      if (process.env.NODE_ENV === 'development') {
        // 開發環境
        location.href = `${this.apiUrl}/auth/facebook?redirect=${location.href}`
      } else {
        location.href = `${this.apiUrl}/auth/facebook`
      }
    },
    handleLogout () {
      const domain = process.env.VUE_APP_DOMAIN
      Cookies.remove('api_token')
      document.cookie = 'api_token=; path=/; domain=' + domain + '; expires=' + new Date(0).toUTCString()

      location.reload()
    },
    async handleValidateForm () {
      try {
        await this.$refs.form.validate()
        this.sipmleReister()
      } catch (e) {
        this.$message.error(this.$t('Celebrity.message_email'))
      }
    },
    async sipmleReister () {
      // 網紅 簡易註冊
      this.isBtnLoading = true

      const apiSetting = new ApiSetting()
      const isSuccess = await apiSetting.simpleSeller(this.form)

      if (isSuccess) {
        await this.fetchMe()
        await this.fetchStores()
        await this.checkRegistered()
      }

      this.isBtnLoading = false
    },
    async fetchBoundChannels () {
      // 取得已綁定清單

      const apiChannel = new ApiChannel()
      this.boundChannels = await apiChannel.list()
    },
    async fetchFBChannels () {
      // 取得 FB 粉絲團清單
      try {
        const api = `/api/v1/channels/source/facebook-page`
        const { data: response } = await this.axios.get(api)

        this.fbChannels = response.data
      } catch (e) {
        console.error(e)
      }
    },
    async bindFbChannel () {
      // 綁定粉絲團

      const apiChannel = new ApiChannel()
      const isSuccess = await apiChannel.create({
        id: this.selectChannelId,
        type: 'facebook-page'
      })

      return isSuccess
    },
    async fetchLiveData () {
      // 取得在 FB 直播中的清單
      this.isBtnLoading = true

      const channel = this.boundChannels.find(channel => channel.endpoint === this.selectChannelId)

      const apiChannel = new ApiChannel()
      const data = await apiChannel.getLives(channel.id)

      // 用來紀錄第一次進來，是否有取得到 FB Live 貼文
      if (!this.firstLiveData) this.firstLiveData = data

      this.selectLivePosts = data

      this.isBtnLoading = false
    },
    handlePrevStep () {
      // 上一步
      if (this.activeStep <= 0) return

      this.activeStep -= 1
    },
    async handleNextStep2 () {
      this.activeStep += 1
    },
    async handleNextStep3 () {
      this.isBtnLoading = true

      if (!this.isBoundFBChannel) {
        await this.bindFbChannel()
        await this.fetchBoundChannels()
      }

      await this.fetchLiveData()

      this.activeStep += 1
      this.isBtnLoading = false
    },
    async handleStartLive () {
      // 綁定網紅以及貼文

      this.setLoading(true)

      const apiPost = new ApiPost()
      const isSuccess = await apiPost.bindPostAgentCelebrity({ token: this.token })

      if (isSuccess) {
        this.bindPostAgentChannel()
      } else {
        this.setLoading(false)
      }
    },
    async bindPostAgentChannel () {
      // 綁定貼文的頻道

      this.setLoading(true)

      const bindChannel = this.boundChannels.find(channel => channel.endpoint === this.selectChannelId)

      const apiPost = new ApiPost()
      const isSuccess = await apiPost.bindPostAgentChannel({
        token: this.token,
        channel_ids: [bindChannel.id],
        target: this.target
      })

      if (isSuccess) {
        localStorage.removeItem('localCelebrity')
        this.$router.push({ path: `/postagent/${this.token}` })
      }

      this.setLoading(false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
