<script>
import VueTypes from 'vue-types'

import * as PAYMENT_FLOW from '@/constants/paymentFlow'

import TermsOfServiceForMerchant from './components/TermsOfServiceForMerchant'
import TermsOfServiceForMerchantAgency from './components/TermsOfServiceForMerchantAgency'
import RulesOfUserConduct from './components/RulesOfUserConduct'
import PrivacyPolicy from './components/PrivacyPolicy'
import IntellectualPropertyPolicy from './components/IntellectualPropertyPolicy'
import TermsOfServicesForPickupAtConvenientStore from './components/TermsOfServicesForPickupAtConvenientStore'
import RulesOfStreamers from './components/RulesOfStreamers'
import TermsOfServiceForConsumers from './components/TermsOfServiceForConsumers'
import TermsOfServiceForInfluencer from './components/TermsOfServiceForInfluencer'

export default {
  name: 'Tw',
  components: {
    TermsOfServiceForMerchant,
    TermsOfServiceForMerchantAgency,
    RulesOfUserConduct,
    PrivacyPolicy,
    IntellectualPropertyPolicy,
    TermsOfServicesForPickupAtConvenientStore,
    RulesOfStreamers,
    TermsOfServiceForInfluencer,
    TermsOfServiceForConsumers
  },
  props: {
    isAgency: VueTypes.bool,
    isSeller: VueTypes.bool,
    paymentFlow: VueTypes.string.def(PAYMENT_FLOW.DIRECT)
  },
  data () {
    return {
      terms: {
        TermsOfServiceForMerchant: {
          id: 'TermsOfServiceForMerchant',
          component: 'TermsOfServiceForMerchant',
          title: 'HandsUP服務條款 (賣家)',
          version: '版本 2021年12月17日'
        },
        TermsOfServiceForMerchantAgency: {
          id: 'TermsOfServiceForMerchantAgency',
          component: 'TermsOfServiceForMerchantAgency',
          title: 'HandsUP服務條款 (賣家)',
          version: '版本 2021年12月17日'
        },

        RulesOfUserConduct: {
          id: 'RulesOfUserConduct',
          component: 'RulesOfUserConduct',
          title: '使用者行為規範',
          version: '版本 2021年12月17日'
        },
        PrivacyPolicy: {
          id: 'PrivacyPolicy',
          component: 'PrivacyPolicy',
          title: 'HandsUP隱私權政策',
          version: '版本 2021年12月17日'
        },
        IntellectualPropertyPolicy: {
          id: 'IntellectualPropertyPolicy',
          component: 'IntellectualPropertyPolicy',
          title: 'HandsUP智慧財產權政策',
          version: '版本 2021年12月17日'
        },
        TermsOfServicesForPickupAtConvenientStore: {
          id: 'TermsOfServicesForPickupAtConvenientStore',
          component: 'TermsOfServicesForPickupAtConvenientStore',
          title: 'HandsUP超商取貨服務條款',
          version: '版本 2021年12月17日'
        },
        RulesOfStreamers: {
          id: 'RulesOfStreamers',
          component: 'RulesOfStreamers',
          title: 'HandsUP直播主規範',
          version: '版本 2021年12月17日'
        },
        TermsOfServiceForConsumers: {
          id: 'TermsOfServiceForConsumers',
          component: 'TermsOfServiceForConsumers',
          title: 'HandsUP服務條款(買家)',
          version: '版本 2021年12月17日'
        },
        TermsOfServiceForInfluencer: {
          id: 'TermsOfServiceForInfluencer',
          component: 'TermsOfServiceForInfluencer',
          title: '網紅媒合平台服務規範',
          version: '版本 2021年12月17日'
        }
      }

    }
  },
  computed: {
    // 判斷要出現哪些條款
    sections () {
      const terms = this.terms
      // 賣家 - 後收模式
      const sellerSectionsDirect = [
        terms.TermsOfServiceForMerchant,
        terms.RulesOfUserConduct,
        terms.PrivacyPolicy,
        terms.IntellectualPropertyPolicy,
        terms.RulesOfStreamers
      ]

      // 賣家 - 代收代付
      const sellerSectionsAgency = [
        terms.TermsOfServiceForMerchantAgency,
        terms.RulesOfUserConduct,
        terms.PrivacyPolicy,
        terms.IntellectualPropertyPolicy,
        terms.TermsOfServicesForPickupAtConvenientStore,
        terms.RulesOfStreamers,
        terms.TermsOfServiceForInfluencer
      ]

      // 買家
      const buyerSections = [
        terms.TermsOfServiceForConsumers,
        terms.RulesOfUserConduct,
        terms.PrivacyPolicy,
        terms.IntellectualPropertyPolicy
      ]

      if (this.isSeller && this.isAgency) {
        return sellerSectionsAgency
      } else if (this.isSeller) {
        return this.paymentFlow === PAYMENT_FLOW.DIRECT
          ? sellerSectionsDirect
          : sellerSectionsAgency
      } else {
        return buyerSections
      }
    }
  }
}
</script>

<template lang="pug" src="./Tw.pug"></template>
<style lang="scss" src="./Tw.scss" scoped></style>
