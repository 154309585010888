<script>
import VueTypes from 'vue-types'
import { mapGetters, mapActions } from 'vuex'

import { LOADING } from '@/constants/loadingState'
import ApiImage from '@/api/image'

const apiImage = new ApiImage()

const IMAGES_LIMITS = 9 // 限制圖片張數
const QUANTITY_MIN = 0
const QUANTITY_MAX = 999999

const INIT_STATE_FORM = {
  no_keyword: true,
  is_ticket: true,
  name: '',
  description: '',
  skus: [
    {
      id: '',
      name: '',
      price: 0,
      availabe_quantity: 0,
      modify_quantity: 0
    }
  ]
}

export default {
  name: 'DialogCreateTicket',

  props: {
    item: VueTypes.object
  },

  data () {
    const validateSaleStartTime = (rule, value, callback) => {
      const saleStart = this.form.saleStartedAt
      const saleEnd = this.form.saleEndedAt

      if (!this.isSetSaleTime) {
        callback()
        return
      }

      if (!saleStart) {
        callback(new Error(this.$t('Setting.Payment.DialogStorePickup.validate')))
        return
      }

      if (saleEnd) {
        this.$refs.form.validateField('saleEndedAt')
      }

      callback()
    }

    const validateSaleEndTime = (rule, value, callback) => {
      const saleStart = this.form.saleStartedAt
      const saleEnd = this.form.saleEndedAt

      if (!this.isSetSaleTime) {
        callback()
        return
      }

      if (this.form.isPermanentSale) {
        callback()
        return
      }

      if (!saleEnd) {
        callback(new Error(this.$t('Setting.Payment.DialogStorePickup.validate')))
        return
      }

      if (saleStart) {
        if (new Date(saleStart) > new Date(saleEnd)) {
          callback(new Error(this.$t('Components.ProductFormOther.validate_start_after_end')))
          return
        }
      }

      callback()
    }

    return {
      LOADING,
      QUANTITY_MIN,
      QUANTITY_MAX,

      fileLimit: IMAGES_LIMITS,
      fileList: [],
      isSetSaleTime: false,

      form: {
        name: '',
        description: '',
        price: 0,
        quantity: 0,
        images: [],
        originalQuantity: 0,
        modify_quantity: 0, // 庫存變動量
        saleStartedAt: null,
        saleEndedAt: null,
        isPermanentSale: false
      },
      rules: {
        name: {
          required: true,
          message: this.$t('form.error.required', { msg: this.$t('Ticket.DialogCreate.form.name') })
        },
        price: {
          type: 'number',
          required: true,
          transform: (val) => Number(val),
          min: 1,
          message: this.$t('form.error.number_minimal', { min: 0, field: this.$t('Ticket.DialogCreate.form.price') })
        },
        quantity: {
          type: 'number',
          required: true,
          min: 0,
          message: this.$t('form.error.required', { msg: this.$t('Ticket.DialogCreate.form.quantity') })
        },
        saleStartedAt: [
          { validator: validateSaleStartTime, trigger: 'blur' }
        ],
        saleEndedAt: [
          { validator: validateSaleEndTime, trigger: 'blur' }
        ]
      }
    }
  },

  computed: {
    ...mapGetters('Me', ['token', 'maxProductName', 'maxPrice', 'storeName', 'currencySymbol', 'imageUploadUrl']),
    ...mapGetters('Ticket', ['createTicketLoadingState']),

    isEdit () {
      return !!this.item
    },
    uploadHeaders () {
      return { Authorization: `Bearer ${this.token}` }
    },
    isFileUpperLimit () {
      return this.fileList.length >= this.fileLimit
    }
  },

  created () {
    if (this.item) {
      this.initForm()
    }
  },

  methods: {
    ...mapActions('Ticket', ['createTicket']),

    initForm () {
      const {
        name,
        price,
        available,
        images,
        description,
        skuId,
        saleStartedAt,
        saleEndedAt
      } = this.item

      this.isSetSaleTime = !!saleStartedAt || !!saleEndedAt
      this.form = {
        skuId,
        name,
        description,
        price,
        quantity: available,
        // 存一份原始的 available
        originalQuantity: available,
        saleStartedAt: saleStartedAt ? new Date(saleStartedAt) : null,
        saleEndedAt: saleEndedAt ? new Date(saleEndedAt) : null,
        isPermanentSale: saleStartedAt && !saleEndedAt
      }

      this.fileList = images.map(image => ({
        ...image,
        url: image.url
      }))
    },

    handleFileSuccess (response, file, fileList) {
      this.fileList = fileList
    },

    handleFileError (err) {
      this.$message.error(err)
    },
    async handleFileRemove (file, fileList) {
      const imageId = file.id || (file.response && file.response.id)

      if (!imageId) return

      const success = await apiImage.delete(imageId)

      if (success) this.fileList = fileList
    },

    handleFileExceed (files, fileList) {
      this.$message.error(this.$t('Product.DialogAddProduct.image_limit_4', { fileLimit: this.fileLimit }))
    },

    handleBeforeUpload (file) {
      const fileMB = file.size / (1024 ** 2)

      if (fileMB > 4) {
        this.$message.error(this.$t('Components.DialogAddProductSingle.file_size'))
        return false
      }
    },

    handlePermanentSale (bol) {
      if (bol) {
        this.form.saleEndedAt = ''
        this.$refs.form.validateField('saleEndedAt')
      }
    },

    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },

    async handleSubmit () {
      try {
        await this.$refs.form.validate()
      } catch {
        return
      }
      const { name, description, price, quantity, originalQuantity, skuId, saleStartedAt, saleEndedAt } = this.form

      const form = {
        ...INIT_STATE_FORM,
        name,
        description,
        skus: [
          {
            id: skuId,
            name: this.$t('ticket'),
            price,
            modify_quantity: quantity - originalQuantity // 送「庫存變動量」給後端
          }
        ],
        sale_started_at: this.isSetSaleTime && saleStartedAt ? saleStartedAt : null,
        sale_ended_at: this.isSetSaleTime && saleEndedAt ? saleEndedAt : null
      }

      form.image_ids = this.fileList.map(file => file.id || file.response.id)

      if (this.isEdit) {
        const { id } = this.item
        this.$emit('createTicket', { form, isEdit: this.isEdit, id })
      } else {
        this.$emit('createTicket', { form, isEdit: this.isEdit })
      }
    },

    handleCancel () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogCreateTicket.pug"></template>
<style lang="scss" src="./DialogCreateTicket.scss" scoped></style>
