import axios from '@/plugins/http.js'

export const getStoreShippings = id => {
  const api = `/api/v1/stores/${id}/shippings `

  return axios.get(api)
}

export const getStoreShippingSetting = id => {
  const api = `/api/v1/stores/${id}/shipping-setting?all=`

  return axios.get(api)
}
