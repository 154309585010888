import store from '@/store'
import queryString from 'query-string'
import { FACEBOOK_LOGIN_ERROR_HASH, FXH_KEYS } from '@/constants/common'

export default async (to, from, next) => {
  // check fbbuy login error first
  if (from.fullPath.includes(FACEBOOK_LOGIN_ERROR_HASH) && from.fullPath.includes(FXH_KEYS.fxhPayload) && to.name !== 'Setting') {
    const params = queryString.parse(from.hash.replace(FACEBOOK_LOGIN_ERROR_HASH, ''))
    next({
      name: 'UserRegister',
      query: {
        [FXH_KEYS.fxhPayload]: params[FXH_KEYS.fxhPayload],
        [FXH_KEYS.fxhHasStore]: params[FXH_KEYS.fxhHasStore]
      }
    })
  } else {
    // 紀錄前往的路徑
    localStorage.setItem('localToFullPath', to.fullPath)
    // 紀錄登入的商城
    localStorage.setItem('localStoreName', to.params.store || '')

    // 未登入 & 有Token => 重新取得 賣家資料
    // 情境：有可能按F5重整頁面
    if (!store.getters['Me/isLogin'] && store.getters['Me/token']) {
      store.dispatch('setLoading', true)

      await store.dispatch('Me/fetchMe')
      store.dispatch('setLoading', false)
    }
    next()
  }
}
