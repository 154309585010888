import GetCelebrityTags from './GetCelebrityTags'
import UpdateCelebrityTags from './UpdateCelebrityTags'
import FetchChannels from './FetchChannels'
import BindChannel from './BindChannel'
import CreateAccount from './CreateAccount'

export default {
  namespaced: true,
  modules: {
    GetCelebrityTags,
    UpdateCelebrityTags,
    FetchChannels,
    BindChannel,
    CreateAccount
  }
}
