<script>
import imgOops from './images/img-404@2x.png'

export default {
  name: 'Oops',

  data: () => ({
    imgOops
  }),

  methods: {
    handleBack () {
      location.href = process.env.VUE_APP_BUYER_DOMAIN_NAME
    }
  }
}
</script>

<template lang="pug" src="./Oops.pug"></template>
<style lang="scss" src="./Oops.scss" scoped></style>
