export const types = {
  UPDATE_PAYMENT_DETAIL: 'UPDATE_PAYMENT_DETAIL'
}

const INIT_DATA = {
  paymentDetail: []
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  paymentDetail: state => {
    let billsTotalAmountPayable = 0

    const list = state.paymentDetail.map(item => {
      const {
        id,
        date,
        overpayment,
        commission_rate: rate,
        total_amount: totalAmount,
        amount_payable: amountPayable
      } = item

      billsTotalAmountPayable = billsTotalAmountPayable + Number(amountPayable)

      return {
        id,
        date,
        rate,
        overpayment,
        amountPayable,
        totalAmount
      }
    })

    return {
      list,
      billsTotalAmountPayable
    }
  },
  dialogPaymentDetailVisible: state => state.paymentDetail.length
}

const mutations = {
  [types.UPDATE_PAYMENT_DETAIL] (state, payload) {
    state.paymentDetail = payload
  }
}

const actions = {
  setPaymentDetail ({ commit }, payload) {
    commit(types.UPDATE_PAYMENT_DETAIL, payload)
  },

  clearPaymentDetail ({ commit }) {
    commit(types.UPDATE_PAYMENT_DETAIL, [])
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
