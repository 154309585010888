<script>
export default {
  name: 'ConfirmAddProduct',

  methods: {
    handleCloseDialog () {
      this.$emit('close')
      this.$emit('update:dialogVisible', false)
    },
    submit () {
      this.$emit('submit')
    }
  }
}
</script>

<template lang="pug" src="./ConfirmAddProduct.pug"></template>
