import axios from '@/plugins/http'

const createSeller = form => (
  axios.post('/api/v1/settings/seller', {
    ...form
  })
)

const createCelebrityInfo = form => (
  axios.post('/api/v1/celebrity/info', {
    ...form
  })
)

const getCelebrityTags = () => axios.get('/api/v1/celebrity/tags')

const updateCelebrityTags = payload => axios.put('/api/v1/celebrity/tags', payload)

const fetchFbChannels = () => axios.get(`/api/v1/channels/source/facebook-page`)

const bindFbChannel = (storeId, payload) => (
  axios.post(`/api/v1/stores/${storeId}/channels`, payload)
)

const fetchBoundChannels = storeId => axios.get(`/api/v1/stores/${storeId}/channels`)

export {
  createSeller,
  createCelebrityInfo,
  getCelebrityTags,
  updateCelebrityTags,
  fetchFbChannels,
  bindFbChannel,
  fetchBoundChannels
}
