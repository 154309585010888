export default [
  {
    name: 'novice',
    title: '小資計畫',
    brief: '小資免月費，開店零負擔',
    channel_number: 1,
    manager_number: 1,
    monthly_fee: '800.00',
    annual_fee: '9600.00',
    rate_transaction: '1.50',
    rate_credit: 3,
    rate_cvs: null,
    rate_cvs_family: null,
    rate_cvs_711: null,
    fee_atm: 10,
    fee_cvs_code: 30,
    fee_cvs: '運費$60 + $5',
    fee_cvs_family: null,
    fee_cvs_711: null,
    fee_homeDelivery: '無手續費',
    fee_seller: '無手續費',
    fee_b2c_pickup: null,
    fee_b2c_cod: null,
    rate_product: 7,
    description:
      "<p>適用於每個月</p><p class='bold'>30萬以下<span>的賣家</span></p>",
    influencer: false
  },
  {
    name: 'master',
    title: '銷售達人',
    brief: '品牌電商，最top的銷售利器',
    channel_number: 3,
    manager_number: 5,
    monthly_fee: '2880.00',
    annual_fee: '34560.00',
    rate_transaction: '1.00',
    rate_credit: 2.4,
    rate_cvs: '運費$60 + 1%(最低5元)',
    rate_cvs_family: '運費$50 + 1%(最低5元)',
    rate_cvs_711: 1.0,
    fee_atm: 5,
    fee_cvs_code: 30,
    fee_cvs: '運費$60 + $5',
    fee_cvs_family: '運費$50 + $5',
    fee_cvs_711: 55,
    fee_homeDelivery: '無手續費',
    fee_seller: '無手續費',
    fee_b2c_pickup: 55,
    fee_b2c_cod: [55, 1],
    rate_product: 5,
    description:
      "<p>適用於每個月</p><p class='bold'>30萬以上<span>的賣家</span></p>",
    influencer: true
  },
  {
    name: 'expert',
    title: '購物專家',
    brief: '多元銷售管道，業績always UP',
    channel_number: 6,
    manager_number: null,
    monthly_fee: '8000.00',
    annual_fee: '96000.00',
    rate_transaction: '0.50',
    rate_credit: 2,
    rate_cvs: '運費$60 + 1%(最低5元)',
    rate_cvs_family: '運費$50 + 1%(最低5元)',
    rate_cvs_711: 1.0,
    fee_atm: 3,
    fee_cvs_code: 30,
    fee_cvs: '運費$60 + $5',
    fee_cvs_family: '運費$50 + $5',
    fee_cvs_711: 55,
    fee_homeDelivery: '無手續費',
    fee_seller: '無手續費',
    fee_b2c_pickup: 55,
    fee_b2c_cod: [55, 1],
    rate_product: 3,
    description:
      "<p>適用於每個月</p><p class='bold'>100萬以上<span>的賣家</span></p>",
    influencer: true
  }
]
