<script>
import { mapGetters } from 'vuex'
import { TW, JP } from '@/constants/regions'

export default {
  name: 'ChatMessageList',
  computed: {
    ...mapGetters('Me', ['region']),

    currentMessages () {
      const messages = {
        [TW]: [
          { username: 'Ally', content: '超期待!!' },
          { username: 'Emma', content: '竟然差別這麼大' },
          { username: 'Nancy', content: '這個必須要買啊～' },
          { username: 'Rumi', content: '超想試試' },
          { username: 'Fong', content: '太喜歡限定了！想要' },
          { username: 'Lesley', content: '下次會賣什麼呢？' }
        ],
        [JP]: [
          { username: '純', content: 'これは買わなくちゃ' },
          { username: 'みるく', content: '楽しみにしてます' },
          { username: '夏', content: '次回は何かな〜' },
          { username: 'ヤマキ', content: '最高！買ってくる' },
          { username: 'Rumi', content: 'つかってみたーい' },
          { username: 'きらきら', content: '限定に弱い！' }
        ]
      }
      return messages[this.region] || messages[JP]
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
