import _ from 'lodash'
import * as loadingState from '@/constants/loadingState'
import ApiCoupon from '@/api/coupon'

export const actionTypes = {
  FETCH_COUPON: 'FETCH_COUPON',
  FETCH_COUPON_SUCCESS: 'FETCH_COUPON_SUCCESS',
  FETCH_COUPON_FAILED: 'FETCH_COUPON_FAILED',

  UPDATE_COUPONS: 'UPDATE_COUPONS',

  UPDATE_SEARCH_STATUS: 'UPDATE_SEARCH_STATUS',
  UPDATE_SEARCH_CODE: 'UPDATE_SEARCH_CODE',
  UPDATE_SEARCH_PAGE: 'UPDATE_SEARCH_PAGE'
}

const INIT_SEARCH = {
  acquire_status: '',
  apply_code: '',
  page: 1
}

const state = {
  coupons: [],
  paginator: null,
  search: JSON.parse(JSON.stringify(INIT_SEARCH)),
  loadingState: loadingState.EMPTY,
  error: null
}

const getters = {
  getCoupons: state => state.coupons,
  getPaginator: state => state.paginator,
  getSearch: state => state.search
}

const mutations = {
  [actionTypes.FETCH_COUPON] (state) {
    state.loadingState = loadingState.LOADING
    state.coupons = []
    state.paginator = null
    state.search = JSON.parse(JSON.stringify(INIT_SEARCH))
  },

  [actionTypes.FETCH_COUPON_SUCCESS] (state, { data, paginator }) {
    state.loadingState = loadingState.READY
    state.coupons = data
    state.paginator = paginator
  },

  [actionTypes.FETCH_COUPON_FAILED] (state) {
    state.loadingState = loadingState.ERROR
    // state.error = error
    state.coupons = []
    state.paginator = null
  },

  [actionTypes.UPDATE_SEARCH_STATUS] (state, { status }) {
    state.loadingState = loadingState.LOADING
    state.search.acquire_status = status
    state.search.page = 1
  },
  [actionTypes.UPDATE_SEARCH_CODE] (state, { code }) {
    state.loadingState = loadingState.LOADING
    state.search.apply_code = code
    state.search.page = 1
  },
  [actionTypes.UPDATE_SEARCH_PAGE] (state, { page }) {
    state.search.page = page
  },
  [actionTypes.UPDATE_COUPONS] (state, { data }) {
    state.coupons = data
  }
}

const actions = {
  fetchCouponList: _.debounce(async function (
    { state, commit },
    isFirst = false
  ) {
    commit('SET_LOADING', true, { root: true })

    if (isFirst) commit(actionTypes.FETCH_COUPON)

    const apiCoupon = new ApiCoupon()

    const data = await apiCoupon.list(state.search)
    const paginator = apiCoupon.paginator

    if (data) {
      commit(actionTypes.FETCH_COUPON_SUCCESS, { data, paginator })
    } else {
      commit(actionTypes.FETCH_COUPON_FAILED)
    }

    commit('SET_LOADING', false, { root: true })
  },
  500),
  updateSearchStatus ({ commit, dispatch }, status) {
    commit(actionTypes.UPDATE_SEARCH_STATUS, { status })
    dispatch('fetchCouponList')
  },
  updateSearchCode ({ commit, dispatch }, code) {
    commit(actionTypes.UPDATE_SEARCH_CODE, { code })
    dispatch('fetchCouponList')
  },
  updateSearchPage ({ commit, dispatch }, page) {
    commit(actionTypes.UPDATE_SEARCH_PAGE, { page })
  },
  updateCoupons ({ commit, dispatch }, { data }) {
    commit(actionTypes.UPDATE_COUPONS, { data })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
