<script>
export default {
  name: 'AccountInfo',

  methods: {
    handleClose () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
