import * as PROVIDER from '@/constants/provider'

import FBIcon from '@/assets/icon-fb-lg.png'
import LineIcon from '@/assets/icon-line-lg.png'
import IGIcon from '@/assets/icon-ig-lg.png'
import M17Icon from '@/assets/icon-17-lg.png'
import EmailIcon from '@/assets/icon-social-email@2x.png'
import UserIcon from '@/assets/icon-default-user-md.png'

export default function (provider) {
  const socialIcons = {
    [PROVIDER.FACEBOOK]: FBIcon,
    [PROVIDER.M17_LIVE]: M17Icon,
    [PROVIDER.LINE]: LineIcon,
    [PROVIDER.INSTAGRAM]: IGIcon,
    [PROVIDER.HANDSUP]: EmailIcon,
    [PROVIDER.GUEST]: UserIcon
  }

  return socialIcons[provider] || null
}
