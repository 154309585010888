import ICON_PLAN_1 from '@/assets/subscription/img-1-m-pass-plan@2x.png'
import ICON_PLAN_3 from '@/assets/subscription/img-3-m-pass-plan@2x.png'
import ICON_PLAN_6 from '@/assets/subscription/img-6-m-pass-plan@2x.png'

export default function (month) {
  const iconMap = {
    '1': ICON_PLAN_1,
    '3': ICON_PLAN_3,
    '6': ICON_PLAN_6
  }

  return iconMap[month] || null
}
