import BaseApi from './baseApi'
import storeId from './_storeId'

export default class Member extends BaseApi {
  uri = `/api/v1/stores/${storeId()}/members`
  uriList = `/api/v1/stores/${storeId()}/members`

  constructor (id) {
    super()
    this.uriList = `/api/v1/stores/${storeId()}/members/${id}/orders`
  }
}
