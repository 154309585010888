import * as loadingState from '@/constants/loadingState'

import { fetchTickets, deleteTicket } from '../apis'

export const actionTypes = {
  FETCH_TICKETS: 'FETCH_TICKETS',
  FETCH_TICKETS_SUCCESS: 'FETCH_TICKETS_SUCCESS',
  FETCH_TICKETS_FAILED: 'FETCH_TICKETS_FAILED'
}

const state = {
  tickets: [],
  pagination: null,
  loadingState: loadingState.EMPTY,
  error: null
}

const getters = {
  getTickets: state => state.tickets,
  ticketsPagination: state => state.pagination,
  getTicketsLoadingState: state => state.loadingState
}

const mutations = {
  [actionTypes.FETCH_TICKETS] (state) {
    state.loadingState = loadingState.LOADING
  },

  [actionTypes.FETCH_TICKETS_SUCCESS] (state, { tickets, pagination }) {
    state.loadingState = loadingState.READY
    state.tickets = tickets
    state.pagination = pagination
  },

  [actionTypes.FETCH_TICKETS_FAILED] (state, error) {
    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  }
}

const actions = {
  fetchTickets ({ commit }, { storeId, params }) {
    commit(actionTypes.FETCH_TICKETS)

    fetchTickets({ storeId, params })
      .then(response => {
        const { data } = response
        const { meta: pagination, data: tickets } = data
        commit(actionTypes.FETCH_TICKETS_SUCCESS, { pagination, tickets })
      })
      .catch(err => commit(actionTypes.FETCH_TICKETS_FAILED, err))
  },

  deleteTicket ({ dispatch }, { storeId, productId }) {
    return deleteTicket({ storeId, productId })
      .then(() => dispatch('fetchTickets', { storeId }))
      .catch(error => {
        console.error(error)
        throw error
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
