export const SHOP_ICON_SHOW = 'liveweb_shop_icon_show'
export const MEMBER_COUNT_SHOW = 'liveweb_member_count_show'
export const HEART_ANIMATION = 'liveweb_heart_animation'
export const FESTIVAL_ANIMATION = 'liveweb_festival_animation'
export const JOIN_ROOM_SHOW = 'liveweb_join_room_show'
export const SALES_PRODUCT_SHOW = 'liveweb_sales_product_show'
export const ADD_CART_NOTICE = 'liveweb_add_cart_notice'
export const PRODUCT_PURCHASE_WORD = 'liveweb_product_purchase_word'
export const PRODUCT_LIST_SHOW = 'liveweb_product_list_show'
export const CART_SHOW = 'liveweb_cart_show'
export const COMMENT_ANALYSIS = 'comment_analysis'
export const FBBUY = 'fbbuy'
