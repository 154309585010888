import BaseApi from './baseApi'
import axios from '@/plugins/http.js'

export default class Image extends BaseApi {
  uri = `/api/v1/images`

  async uploadImage (payload) {
    const result = await axios.post(this.uri, payload)
    return result.data
  }
}
