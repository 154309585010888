const initOrderPageState = {
  tabName: 'all',
  page: 1,
  filter: {
    keyword: null,
    selected: {
      from: null,
      to: null,
      paymentType: null,
      shippingType: null,
      channel: null
    },
    perPage: 15
  }
}

const state = {
  orderPageState: initOrderPageState
}

const getters = {
  orderPageState: state => {
    return state.orderPageState
  }
}

const mutations = {
  UPDATE_ORDER_PAGE_STATE (state, pageState) {
    state.orderPageState = pageState
  },
  RESET_ORDER_PAGE_STATE (state) {
    state.orderPageState = initOrderPageState
  },
  UPDATE_ORDER_PAGE_TAB (state, tab) {
    state.orderPageState.tabName = tab
  }
}

const actions = {
  updateOrderPageState ({ commit }, pageState) {
    commit('UPDATE_ORDER_PAGE_STATE', pageState)
  },
  resetOrderPageState ({ commit }) {
    commit('RESET_ORDER_PAGE_STATE')
  },
  updateOrderPageTab ({ commit }, tab) {
    commit('UPDATE_ORDER_PAGE_TAB', tab)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
