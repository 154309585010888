<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Guide',
  props: {
    guideOpen: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore']),
    ...mapGetters('SettingsChecked', ['checkedList']),
    activeStepCount () {
      return (_.filter(this.checkedList).length || 0) + 1
    },
    activeStep () {
      let now = null
      const list = ['seller', 'paymentShipping']
      list.some(item => {
        if (!this.checkedList[item]) {
          now = item
          return now
        }
      })
      return now
    },
    showMore () {
      const showRegions = ['tw']

      return showRegions.includes(this.currentStore.region.toLowerCase())
    }
  },
  methods: {
    goSetting () {
      if (this.activeStep === 'seller') {
        this.$router.push({ name: 'Setting', query: { activeMenu: 'SettingSeller', seller: 'true' } })
      } else {
        this.$router.push({ name: 'Setting', query: { activeMenu: 'SettingPayment' } })
      }
    },
    showGuide () {
      this.$emit('toggleGuide', true)
    },
    hideGuide () {
      this.$emit('toggleGuide', false)
      localStorage.setItem('hasShowGuide', true)
    },
    showLineDialog (e) {
      this.$parent.$refs.lineChat.openLineDialog(true)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
