<script>
import VueTypes from 'vue-types'

export default {
  name: 'DialogShipmentStatus',
  props: {
    dialogVisible: VueTypes.bool.def(false),
    createTime: VueTypes.string,
    shippingSourceId: VueTypes.string,
    shippingLogs: VueTypes.array.def([]),
    orderStatus: VueTypes.string
  },
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
