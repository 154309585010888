<script>

import Cookies from 'js-cookie'
import { mapGetters, mapActions } from 'vuex'
import { JP, TW } from '@/constants/regions'
import FbMessengerCheckbox from '@/components/FbMessengerCheckbox'

export default {
  skipMiddleware: ['checkAuth'],

  metaInfo () {
    return {
      meta: [{
        name: 'viewport',
        content: 'width=device-width,initial-scale=1.0'
      }]
    }
  },

  name: 'Register',
  components: { FbMessengerCheckbox },
  data () {
    return {
      isEnterprise: !!Cookies.get('enterprise'), // 註冊前，只能由 Cookies 判斷
      enterprise: Cookies.get('enterprise'),
      form: {
        first_name: '',
        last_name: '',
        email: '',
        domain: '',
        app_id: '',
        name: '', // 商店名稱
        region: TW
      },
      errors: {
        email: '',
        name: ''
      },
      submitting: false,
      mobileCountryCode: '+886'
    }
  },
  computed: {
    ...mapGetters('Lang', ['locale']),
    ...mapGetters('Me', ['me', 'stores', 'storeName', 'isLogin']),
    ...mapGetters('Register', ['registerToken']),

    env () { return process.env },
    userRef () {
      return (new Date()).getTime() + Math.random().toString(36).slice(0, 10) + this.me.id
    },
    rules () {
      const phonePatterns = {
        TW: /^[0-9]+$/
      }
      const phonePattern = phonePatterns.TW
      return {
        email: [
          { required: true, message: this.$t('Register.enter_email'), trigger: 'blur' },
          { type: 'email', message: this.$t('Register.email_error'), trigger: 'blur' }
        ],
        domain: [{
          required: !!Cookies.get('enterprise'),
          message: this.$t('Register.enter_domain', { 'domain': Cookies.get('enterprise') }),
          trigger: 'blur'
        }],
        name: [
          { required: true, message: this.$t('Register.enter_store_name'), trigger: 'blur' }
        ],
        phone_number: [
          { required: true, message: this.$t('Register.enter_phone'), trigger: 'blur' },
          { pattern: phonePattern, message: this.$t('Register.enter_phone') }
        ]
      }
    },
    serviceLink () {
      const locale = this.calcLocale(this.form.region)
      return locale ? `/contract_seller?lang=${locale}` : '/contract_seller'
    }
  },
  created () {
    const hasCreatedStore = this.stores
      .map((store) => store.pivot.type)
      .find((type) => type === 'owner') !== undefined

    if (hasCreatedStore || !this.isLogin) {
      this.$router.push({ path: `/` })
    }
    if (!this.me) return

    let { first_name: firstName = '', last_name: lastName = '', email = '' } = this.me

    this.form = Object.assign(this.form, {
      first_name: firstName,
      last_name: lastName,
      email
    })
    this.defaultStoreName()

    // 企業版給預設值
    if (this.isEnterprise) {
      this.$set(this.form, 'currency', 'TWD')
      this.$set(this.form, 'timezone', 'Asia/Taipei')
    }
  },
  mounted () {
    // [Facebook Pixel] 追蹤授權完成 Redirect 到系統第一個畫面
    this.$analytics.fbq.event('M-Register-Authorization', {
      Step: 'Authorization',
      Page: 'Merchant-Platform'
    })

    // [Google Analytics] 追蹤授權完成 Redirect 到系統第一個畫面
    this.$ga.event({
      eventCategory: 'M-Register-Authorization',
      eventAction: 'Authorization',
      eventLabel: 'Merchant-Platform'
    })
  },
  methods: {
    ...mapActions('Me', ['fetchMe', 'fetchStores', 'fetchCurrentStore', 'updateIsNewRegister', 'fetchGMOStatus']),
    ...mapActions('Lang', ['setLocale']),
    ...mapActions('Register', ['deleteToken']),
    async submitForm () {
      if (this.submitting) return

      this.submitting = true

      if (await this.validateForm()) await this.handleRegister()

      this.submitting = false
    },
    async validateForm () {
      try {
        await this.$refs.form.validate()
        return true
      } catch (e) {
        this.$message.error(this.$t('message.form_incomplete'))
        return false
      }
    },
    async handleRegister () {
      this.confirmFbCheckbox()

      const api = `/api/v1/settings/seller`
      const form = this.normalizeForm()

      try {
        await this.axios.post(api, form)

        const token = this.$route.query.token
        if (token) {
          this.deleteToken(token)
        }

        const parentToken = this.$route.query.parentToken
        if (parentToken) {
          const api = '/api/v1/stores/parent-bind'
          await this.axios.post(api, { token: parentToken })
        }

        await this.fetchMe()
        await this.fetchStores()
        await this.fetchCurrentStore()
        await this.fetchGMOStatus()

        this.updateIsNewRegister(true)

        if (this.isEnterprise) {
          this.$router.push({ path: `/register/applying/${Cookies.get('enterprise')}` })
        } else if (this.form.region === JP && !parentToken) {
          this.$router.push({ path: `/${this.storeName}/gmo` })
        } else {
          this.$router.push({ path: `/${this.storeName}/setting` })
        }

        localStorage.setItem('isSettingChannel', true)
        localStorage.setItem('hasShowGuide', false)
        // [Facebook Pixel] 追蹤填寫完商店資料並成功建立商店，完成註冊事件
        this.$analytics.fbq.event('M-Register-Complete', {
          Step: 'Complete-Form',
          Page: 'Merchant-Platform'
        })

        // [Google Analytics] 追蹤填寫完商店資料並成功建立商店，完成註冊事件
        this.$ga.event({
          eventCategory: 'M-Register-Complete',
          eventAction: 'Complete-Form',
          eventLabel: 'Merchant-Platform'
        })
      } catch (e) {
        this.submitting = false

        if (e.status === 422) {
          this.showErrorMessage(e.data.message)
        }

        if (e.status === 400 && /DuplicateStoreException/.test(e.data.type)) {
          await this.fetchMe()
          await this.fetchStores()
          await this.fetchCurrentStore()
          this.updateIsNewRegister(true)
          this.$router.push({ path: `/${this.storeName}/setting` })
          localStorage.setItem('isSettingChannel', true)
        }
        console.error(e)
      }
    },
    confirmFbCheckbox () {
      try {
        FB.AppEvents.logEvent('MessengerCheckboxUserConfirmation', null, {
          'app_id': process.env.VUE_APP_FACEBOOK_APP_ID,
          'page_id': process.env.VUE_APP_FACEBOOK_PAGE_ID,
          'ref': JSON.stringify({ userId: this.me.id }),
          'user_ref': this.userRef
        })
      } catch (e) {
        console.error(e)
      }
    },
    normalizeForm () {
      let newForm = Object.assign({}, this.form)
      if (this.isEnterprise) {
        newForm = Object.assign(newForm, { enterprise: Cookies.get('enterprise') })
      } else {
        delete newForm.domain
      }

      newForm.locale = this.locale.replace('-', '_')

      return newForm
    },
    showErrorMessage (message) {
      _.forEach(message, (value, key) => {
        this.errors[key] = value[0]
      })
    },
    defaultStoreName () {
      if (this.locale === 'th') {
        // 語系：泰
        this.form.name = this.form.first_name + this.form.last_name
      } else if (this.locale === 'vi') {
        // 語系：越
        this.form.name = this.$t('Register.shop') + this.form.first_name + this.form.last_name
      } else {
        // 語系：台、日、英
        this.form.name = this.form.first_name + this.form.last_name + this.$t('Register.shop')
      }
    },
    calcLocale (region) {
      const locales = {
        TW: 'zh-TW',
        VN: 'vi',
        JP: 'ja',
        TH: 'th'
      }
      return locales[region]
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
