import { LIVE_STATUS } from './constants'

import { getPublication } from '@/utils/getPublication'

import liveEmpty from '@/assets/live-empty.png'

export function normalizePost (post) {
  const {
    title,
    agent,
    celebrity,
    created_at: createdTime,
    upcoming_at: upcomingTime,
    publications,
    image
  } = post

  const publication = getPublication(publications) || {}

  const {
    live_status: liveStatus = LIVE_STATUS.READY,
    info,
    channel,
    started_at: liveStartedTime
  } = publication
  const channelName = channel && (channel.type === 'handsup' ? 'HandsUp' : channel.name)

  let cardImage
  let hasCardImage
  if (image.length > 0) {
    cardImage = image[0].url
    hasCardImage = true
  } else {
    cardImage = info ? info.picture : liveEmpty
    hasCardImage = !!info
  }

  return {
    title,
    createdTime,
    upcomingTime,
    liveStatus,
    liveStartedTime,
    cardImage,
    channelName,
    hasCardImage,
    ...(celebrity && { celebrityName: celebrity.name }),
    ...(agent && { agentToken: agent.token }),
    ...(agent && { agentStoreId: agent.agent_store_id }),
    ...(agent && { agentAvatarUrl: agent.avatar_url }),
    ...(channel && { channelName: channel.name }),
    ...(channel && { channelType: channel.type })
  }
}
