<script>
import { legalPerson, naturalPerson } from '@/utils/identityNumberValidator.js'
import AddressSelect from '@/components/AddressSelect'
import { mapGetters } from 'vuex'
import FormField from '@/utils/FormField'
import validate from '@/utils/validate'
import VueTypes from 'vue-types'

export default {
  name: 'DialogAccountSetting',
  components: { AddressSelect },
  props: {
    visible: VueTypes.bool.isRequired,
    sellerData: VueTypes.object.isRequired,
    isPhoneVerfied: VueTypes.bool.isRequired,
    formData: VueTypes.object.isRequired
  },
  data () {
    return {
      form: null,
      selectInvoiceType: [
        { text: this.$t('Setting.Seller.invoice_ecpay'), value: 'personal' },
        { text: this.$t('Setting.Seller.invoice_donate'), value: 'donation' }
      ],
      selectDonation: [
        { text: this.$t('Setting.Seller.donate_genesis'), value: '919' },
        { text: this.$t('Setting.Seller.donate_ccf'), value: '8585' },
        { text: this.$t('Setting.Seller.donate_hsapf'), value: '8957282' }
      ]
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore']),
    isCompany () {
      return this.form.type === 'company'
    },
    accountRules () {
      return {
        registrant_name: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t(this.formField('registrant_name'))}`,
            trigger: 'blur'
          }
        ],
        registrant_company_phone: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t(this.formField('registrant_company_phone'))}`,
            trigger: 'blur'
          }
        ],
        registrant_identity_number: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t(this.formField('registrant_identity_number'))}`,
            trigger: 'blur'
          },
          {
            validator: this.isCompany ? legalPerson : this.handleNaturalPersonRule,
            trigger: 'blur'
          }
        ],
        registrant_company_representative: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t(this.formField('registrant_company_representative'))}`,
            trigger: 'blur'
          }
        ],
        registrant_date: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t(this.formField('registrant_date'))}`,
            trigger: 'blur'
          }
        ],
        registrant_mobile: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t(this.formField('registrant_mobile'))}`,
            trigger: 'blur'
          }
        ],
        registrant_email: [
          {
            type: 'email',
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t('Setting.Seller.receipt_email')}`,
            trigger: 'blur'
          }
        ],
        invoice_type: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t('Setting.Seller.receipt_type')}`,
            trigger: 'blur'
          }
        ],
        invoice_donated_code: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')} ${this.$t('Setting.Seller.donate_org')}`,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    this.form = {
      ...this.sellerData,
      ...this.formData
    }
  },
  methods: {
    formField (fieldName) {
      return FormField[this.form.type][fieldName]
    },
    async submit () {
      try {
        await this.$refs['addressForm'].validateForm()
        await this.$refs['accountForm'].validate()
        this.$emit('submit', this.form)
      } catch (error) {
        this.$message.error(this.$t('message.form_incomplete'))
      }
    },
    handleNaturalPersonRule (rules, value, callback) {
      // 身分證字號隱碼則略過不進行驗證
      if (validate.hasStar(String(value))) {
        callback()
      } else {
        return naturalPerson(rules, value, callback)
      }
    },
    handleChangeSellerType (value) {
      this.$refs['accountForm'].clearValidate()

      // 切換成公司時，發票類型強制改為公司
      this.form.invoice_type = this.form.type === 'company' ? 'company' : ''
    },
    updateAddress (obj) {
      this.form.address = obj
    },
    updateInvoiceType () {
      if (this.form.invoice_type !== 'donation') {
        this.form.invoice_donated_code = null
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
