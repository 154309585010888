<script>
import { mapGetters, mapActions } from 'vuex'
import store from '@/store'
import AppXBox from '@/components/AppXBox'
import PaginationPure from '@/components/PaginationPure'

export default {
  name: 'ApproveTable',
  components: { AppXBox, PaginationPure },
  data () {
    return {
      subscribeHandler: store.subscribeAction({
        after: (action, state) => {
          if (action.type === 'BatchApprove/batchApprove') {
            this.$refs.table.clearSelection()
          }
        }
      })
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol', 'storeName']),
    ...mapGetters('BatchApprove', [
      'isLoading',
      'meta',
      'orders',
      'normalizedOrders'
    ])
  },
  mounted () {
    this.fetchApproves()
  },
  beforeDestroy () {
    this.subscribeHandler()
  },
  methods: {
    ...mapActions('BatchApprove', [
      'fetchApproves',
      'updateSingleApprove',
      'updateBatchSelected',
      'putReceiptMistake',
      'updateSort',
      'updateCurrentPage'
    ]),
    toOrder (order) {
      const path = `/${this.storeName}/order/${order.id}`
      window.open(path, '_blank')
    },
    singleApprove (orderId) {
      const order = this.orders.find(order => order.id === orderId)
      if (order) {
        const approve = { ...order, bankReceipt: _.get(order, 'payment.bank_receipt', {}) }
        this.updateSingleApprove(approve)
      } else {
        this.fetchApproves()
      }
    },
    getStatusClass (status) {
      const classes = {
        init: 'text-danger',
        pending: 'text-warning'
      }

      return classes[status]
    },
    async handleWrongInfo (order) {
      try {
        await this.putReceiptMistake(order)
        this.$refs.table.toggleRowSelection(order, false)
        this.fetchApproves()
      } catch (e) {
        this.$message.error(this.$t('message.save_fail'))
      }
    },
    handleUpdateRows (page) {
      this.updateBatchSelected([])
      this.updateCurrentPage(page)
      this.fetchApproves()
    },
    handleSelectionChange (val) {
      this.updateBatchSelected(val)
    },
    handleSortChange (val) {
      const directionMap = {
        ascending: 'asc',
        descending: 'desc'
      }
      const sort = {
        order_by: val.prop,
        direction: directionMap[val.order]
      }
      this.updateSort(sort)
      this.fetchApproves()
    },
    orderSelectable (order) {
      return !order.has_mistake
    }
  }
}
</script>

<template lang="pug" src="./ApproveTable.pug"></template>
<style lang="scss" src="./ApproveTable.scss" scoped></style>
