<script>
import VueTypes from 'vue-types'

export default {
  name: 'DialogInterruptVoucher',

  props: {
    isActivating: VueTypes.bool.def(false)
  },

  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },

    handleSubmit () {
      this.$emit('submit')
    }
  }
}
</script>

<template lang="pug" src="./DialogInterruptVoucher.pug"></template>
