<script>
import { mapGetters, mapActions } from 'vuex'

import ApiStore from '@/api/store'

import {
  REPLY_TYPE,
  REPLY_BUTTON_TEMPLATE_TYPE,
  REPLY_BUTTON_TYPE
} from '../../constants'
import DialogReplyType from '../DialogReplyType'
import DialogCartSetting from '../DialogCartSetting'
import DialogFbPixelSetting from '../DialogFbPixelSetting'

const apiStore = new ApiStore()

export default {
  name: 'StoreSetting',
  components: {
    DialogReplyType,
    DialogCartSetting,
    DialogFbPixelSetting
  },

  data () {
    return {
      REPLY_TYPE,
      DialogCartSettingVisible: false,
      DialogReplyTypeVisible: false,
      DialogFbPixelSettingVisible: false
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore', 'storeName', 'enabledDeleteShoppingCart', 'enabledPixelId', 'isEnterprise']),

    canEditCart () {
      // 購物車商品設定
      return _.get(this.currentStore, 'buyer_editing_cart')
    },

    replyType () {
      // 訂購成功訊息通知
      return _.get(this.currentStore, 'reply_type')
    },
    replyButtonTemplateType () {
      // 訂購成功訊息 按鈕架構
      const replyButtonTypes = _.get(this.currentStore, 'checkout_message_type') || []
      switch (true) {
        case replyButtonTypes.includes(REPLY_BUTTON_TYPE.checkout) && replyButtonTypes.includes(REPLY_BUTTON_TYPE.goMarket):
          return REPLY_BUTTON_TEMPLATE_TYPE.checkoutAndGoMarket
        case replyButtonTypes.includes(REPLY_BUTTON_TYPE.checkout):
          return REPLY_BUTTON_TEMPLATE_TYPE.checkout
        default:
          return undefined
      }
    },

    pixelId () {
      return _.get(this.currentStore, 'facebook_pixel_id', '')
    }
  },

  created () {
    if (!this.currentStore.reply_type) {
      this.changeReplyType('direct')
    }
  },

  async mounted () {
    let isPixelVisible = localStorage.getItem('pixelVisible')

    if (isPixelVisible) {
      this.$scrollTo('#pixel', 2000)
      this.DialogFbPixelSettingVisible = true
      localStorage.removeItem('pixelVisible')
    }
  },
  methods: {
    ...mapActions('Me', ['fetchCurrentStore']),

    async changeCartSetting (value) {
      // 購物車商品設定
      this.DialogCartSettingVisible = false
      const success = await apiStore.update(
        this.storeName, {
          buyer_editing_cart: value
        }
      )
      if (success) {
        this.fetchCurrentStore()
      }
    },

    async changeReplyType (payload) {
      // 訂購成功訊息通知
      this.DialogReplyTypeVisible = false

      const postData = {
        reply_type: payload.replyType
      }
      // 企業版不需要加上Messenger 按鈕的設定
      if (!this.isEnterprise) {
        switch (payload.replyButtonTemplateType) {
          case REPLY_BUTTON_TEMPLATE_TYPE.checkout:
            postData.checkout_message_type = [REPLY_BUTTON_TYPE.checkout]
            break
          case REPLY_BUTTON_TEMPLATE_TYPE.checkoutAndGoMarket:
            postData.checkout_message_type = [REPLY_BUTTON_TYPE.checkout, REPLY_BUTTON_TYPE.goMarket]
            break
          default:
            break
        }
      }
      await apiStore.putReplyType(postData)

      // 刷新 Vuex Store 資料
      this.fetchCurrentStore()
    },

    async changePixelSetting (pixelId) {
      // 訂購成功訊息通知
      this.DialogFbPixelSettingVisible = false

      const postData = {
        facebook_pixel_id: pixelId
      }
      await apiStore.putFbPixel(postData)

      // 刷新 Vuex Store 資料
      await this.fetchCurrentStore()
    },

    handleOpenDialogCartSetting () {
      this.DialogCartSettingVisible = true
    }
  }
}
</script>

<template lang="pug" src="./StoreSetting.pug"></template>
<style lang="scss" src="./StoreSetting.scss" scoped></style>
