<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProductTable',
  props: {
    products: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      expandedRow: []
    }
  },
  computed: {
    ...mapGetters('Me', ['currencySymbol', 'keywordTip']),
    normalizeProducts () {
      return this.products
        .filter(product => product.status !== 'deleted')
        .map(product => {
          // 關鍵字
          product.keyword = product.keywords.length > 0 ? product.keywords[0].keyword : null

          return product
        })
    }
  },
  methods: {
    isExpandRow (id) {
      return this.expandedRow.some(item => item === id)
    },
    handleExpandRow (id) {
      const idx = this.expandedRow.findIndex(item => item === id)

      if (idx === -1) {
        this.expandedRow.push(id)
      } else {
        this.expandedRow.splice(idx, 1)
      }
    },
    getImgUrl (data) {
      const imgUrl = data.images && data.images.length && data.images[0].url

      return imgUrl || require('@/assets/icon-default-product.png')
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
