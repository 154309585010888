<script>
import { mapGetters, mapActions } from 'vuex'
import VueTypes from 'vue-types'

import { LOADING, READY } from '@/constants/loadingState'
import * as REGIONS from '@/constants/regions'
import { requiredRule, phoneRule, emailRule } from '@/utils/formRules'

import { STEP_TWO } from '../../constants/steps'

export default {
  name: 'Step1',

  props: {
    isMobile: VueTypes.bool
  },

  data () {
    return {
      LOADING,
      form: {
        first_name: '',
        last_name: '',
        region: REGIONS.TW,
        phone: '',
        email: '',
        gender: '',
        introduction: ''
      },

      selectRegion: [
        { text: '台灣', value: REGIONS.TW },
        { text: 'ประเทศไทย', value: REGIONS.TH },
        { text: '日本', value: REGIONS.JP }
      ],

      genderOptions: [
        {
          text: '男',
          value: 'male'
        },
        {
          text: '女',
          value: 'female'
        }
      ]

    }
  },

  computed: {
    ...mapGetters('Me', ['me', 'hasStore']),
    ...mapGetters('RegisterInfluencer', ['createAccountLoadingState']),
    rules () {
      return {
        first_name: requiredRule(this.$t('field.first_name')),
        last_name: requiredRule(this.$t('field.last_name')),
        phone_number: phoneRule({ region: REGIONS.TW }),
        email: emailRule(),
        gender: requiredRule(this.$t('field.gender'))
      }
    },
    mobileCountryCode () {
      // @todo move to constants
      const codes = {
        TW: '+886',
        VN: '+84',
        TH: '+66',
        JP: '+81'
      }
      return codes[this.form.region] || ''
    }
  },

  created () {
    this.setDefaultForm()
  },
  mounted () {
    // [Facebook Pixel] 追蹤授權完成 Redirect 到系統第一個畫面
    this.$analytics.fbq.event('I-Register-Authorization', {
      Step: 'Authorization',
      Page: 'Influencer-Platform'
    })

    // [Google Analytics] 追蹤授權完成 Redirect 到系統第一個畫面
    this.$ga.event({
      eventCategory: 'I-Register-Authorization',
      eventAction: 'Authorization',
      eventLabel: 'Influencer-Platform'
    })
  },
  methods: {
    ...mapActions('RegisterInfluencer', ['createAccount']),
    ...mapActions('Register', ['deleteToken']),

    setDefaultForm () {
      // 如果有賣家資料這裡就是塞之前填的賣家資料
      // 如果沒賣家資料就是 FB 資料
      const {
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phoneNumber
      } = this.me

      this.form = {
        ...this.form,
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: phoneNumber
      }
    },

    validateForm () {
      return new Promise((resolve, reject) => {
        this.$refs.form.validate(valid => (
          valid ? resolve() : reject(new Error())
        ))
      })
    },

    handleNextStep () {
      this.validateForm()
        .then(() => (
          this.createAccount({
            hasStore: this.hasStore,
            form: this.form
          })
            .then(() => {
              if (this.createAccountLoadingState === READY) {
                this.$emit('next-step', STEP_TWO)
                this.deleteToken(this.$route.query.token)
              }
            })
        ))
    }
  }
}
</script>

<template lang="pug" src="./Step1.pug"></template>
<style lang="scss" src="./Step1.scss" scoped></style>
