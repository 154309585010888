import { HANDSUP } from '@/constants/channelType'

/**
 *
 * 如果有用 HANDSUP 開播則優先取 HANDSUP channel
 * 如果沒有 HANDSUP 就抓第一個
 */
export const getPublication = publications => {
  if (!publications.length) {
    return null
  }

  const handsupChannel = publications.find(publication =>
    publication.channel.type === HANDSUP
  )

  return handsupChannel || publications[0]
}
