
export const types = {
  UPDATE_ATM_INFO: 'UPDATE_ATM_INFO'
}

const INIT_DATA = {
  atmInfo: null

}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  atmInfo: state => state.atmInfo,
  dialogAtmInfoVisible: state => !!state.atmInfo
}

const mutations = {
  [types.UPDATE_ATM_INFO] (state, payload) {
    state.atmInfo = payload
  }
}

const actions = {
  setAtmInfo ({ commit }, atmInfo) {
    commit(types.UPDATE_ATM_INFO, atmInfo)
  },

  clearAtmInfo ({ commit }) {
    commit(types.UPDATE_ATM_INFO, null)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
