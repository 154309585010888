import _ from 'lodash'

import * as PAYMENT_FLOW from '@/constants/paymentFlow'
import { TW, JP } from '@/constants/regions'
import * as PLANS from '@/constants/plans'

const DRAW_SETTING = 'draw'
const AUCTION_SETTING = 'auction'
const BIDDING_SETTING = 'bidding'
const CELEBRITY_SETTING = 'celebrity'
const COUPON_SETTING = 'coupon'
const POST_ANALYSIS_SETTING = 'post_analysis'
const REPLAY_SETTING = 'replay'
const PIXEL_CODE_SETTING = 'pixel_code'
const INFLUENCER_SETTING = 'influencer'
const CUSTOM_SELF_PICK_UP = 'custom_self_pick_up'
const TICKET = 'ticket'
const PARENT_STORE = 'parent_store'
const SUBSCRIPTION = 'subscription'
const LIVEWEB_SIMPLE_REDIRECT_PURCHASE = 'liveweb_simple_redirect_purchase'
const USE_CONTAINER = 'use_container'

const getters = {
  settings: (state, getters) => {
    const mapping = _.get(getters.currentStore, 'settings', [])
      .reduce((prev, current) => {
        prev[current.name] = current.enable
        return prev
      }, {})

    return mapping
  },
  enabledCelebrity: (_, getters) => {
    // 網紅授權預設
    return getters.settings[CELEBRITY_SETTING]
  },

  enabledDraw: (_, getters) => {
    // 抽獎預設
    return getters.settings[DRAW_SETTING]
  },

  enabledAuction: (_, getters) => {
    // 拍賣預設
    return getters.settings[AUCTION_SETTING]
  },

  enabledBidding: (_, getters) => {
    // 喊單模組預設
    return getters.settings[BIDDING_SETTING]
  },

  enabledPostActivities: (_, getters) => {
    return getters.enabledDraw || getters.enabledAuction || getters.enabledBidding
  },

  enabledCoupon: (_, getters) => {
    // 折價卷預設
    return getters.settings[COUPON_SETTING]
  },

  enabledPostAnalysis: (_, getters) => {
    // 直播貼文分析
    return getters.settings[POST_ANALYSIS_SETTING]
  },

  enabledReplay: (_, getters) => {
    // 回放影片時間
    return getters.settings[REPLAY_SETTING]
  },

  enabledInfluencer: (_, getters) => {
    // 網紅媒合預設
    return getters.settings[INFLUENCER_SETTING]
  },

  enabledDialogSetPostTime: (_, getters) => {
    // 回放影片時間 或 買家結帳期限，至少其一有顯示，才能開啟此功能
    return getters.enabledReplay || getters.enabledCheckoutTime
  },

  enabledDeleteShoppingCart: (_, getters) => {
    // 商店設定 - 購物車商品設定
    const regions = [TW, JP]
    return regions.includes(getters.region) && !getters.isEnterprise
  },

  enabledPixelId: (_, getters) => {
    // 商店設定 - FB Pixel ID設定
    return getters.settings[PIXEL_CODE_SETTING]
  },

  enabledExportAccountBalance: (_, getters) => {
    // 設定>我的帳戶 - 帳戶餘額 - 匯出(CVS)
    const regions = [TW]
    return regions.includes(getters.region)
  },

  canBuyPlan: (_, getters) => {
    if (!getters.currentStore) return false

    // 非代收代付
    if (getters.paymentFlow === PAYMENT_FLOW.DIRECT) return false

    const region = getters.region
    const plan = getters.currentStore.plan

    if (region === TW) {
      return plan && plan.name !== 'expert'
    } else {
      // 等日本第二個方案出來以後，再改寫判斷
      return true
    }
  },

  enabledCustomSelfPickUp: (_, getters) => {
    // 是否顯示這兩種物流設定：門市取貨付款/宅配取貨付款
    // 顯示：丟丟妹only
    // 不顯示：其他所有店家
    return getters.settings[CUSTOM_SELF_PICK_UP]
  },

  enabledTicket: (_, getters) => {
    // 是否允許不公開直播 ＆ 票券功能
    return getters.settings[TICKET]
  },

  enabledMerchandise: (_, getters) => {
    // 是否允許賣商品
    // 目前只有日本的ticket方案不行
    return getters.currentStore.plan.name !== PLANS.TICKET
  },
  enabledParentStore: (_, getters) => {
    // 子母商店
    return getters.settings[PARENT_STORE]
  },

  showInstructionLink: (_, getters) => {
    // 目前只有日本有做 操作說明，所以暫時只判斷日本
    const regions = [JP]
    return regions.includes(getters.region)
  },

  enabledSubscription: (_, getters) => {
    // 訂閱制票券
    return getters.settings[SUBSCRIPTION]
  },

  enabledLivewebSimpleRedirectPurchase: (_, { settings }) => {
    // 直播間的商品點擊時，是否轉變為 導購連結
    return settings[LIVEWEB_SIMPLE_REDIRECT_PURCHASE]
  },

  enabledCheckoutTime: (_, getters) => {
    // 買家結帳期限
    return !getters.isEnterprise && !getters.enabledLivewebSimpleRedirectPurchase
  },

  enabledUseContainer: (_, getters) => {
    // 容器化API
    return getters.settings[USE_CONTAINER]
  },

  enabledLiveSetting: (_, getters) => {
    // 再抄啊
    return getters.region !== TW
  }
}

export default {
  getters
}
