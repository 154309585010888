<script>
import { mapGetters } from 'vuex'
import dateFormat from 'date-fns/format'
import { GMO_CREDIT } from '@/constants/paymentTypes'
import { SELLER_SUBSCRIPTION } from '@/constants/creditCardApplication'
import { getCreditCards } from '@/api/creditCard'
import { planRenew as apiPlanRenew, planRepay as apiPlanRepay } from '@/api/plan'
import { getLastPayment } from '../../services/api'
import DialogPaymentNotice, { PAYMENT_STATUS } from '../DialogPaymentNotice'
import GmoCreditDialog from '../GmoCreditDialog'

export default {
  name: 'PaymentInfo',
  components: { GmoCreditDialog, DialogPaymentNotice },
  data () {
    return {
      gmoCreditDialogVisible: false,
      currentCard: {},
      isLoading: false,
      nextPaymentDate: '-',
      lastPayFailed: false,
      paymentNoticeVisible: false,
      paymentStatus: PAYMENT_STATUS.SUCCESS,
      paymentMessage: ''
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore']),
    displayCardNum () {
      return this?.currentCard?.['masked_card_no']
        ? this.currentCard.masked_card_no.slice(-5)
        : ''
    }
  },
  async created () {
    this.isLoading = true
    try {
      await Promise.all([
        this.fetchCards(),
        this.getLastPayment()
      ])
    } catch (e) {
      console.error(e)
    }
    this.isLoading = false
  },
  methods: {
    async getLastPayment () {
      const { data: { data } } = await getLastPayment(this.currentStore.id)
      const lastPayment = data?.[0]
      if (!lastPayment) return

      this.lastPayFailed = lastPayment.last_pay_failed
      this.nextPaymentDate = lastPayment.deferred_at ? dateFormat(lastPayment.deferred_at, 'YYYY/MM/DD') : '-'
    },
    togglePaymentDialog () {
      // GMO Credit Only for now
      this.gmoCreditDialogVisible = !this.gmoCreditDialogVisible
    },
    handleDialogChangeCard () {
      this.gmoCreditDialogVisible = true
      this.paymentNoticeVisible = false
    },
    async handeSaveCard () {
      this.isLoading = true
      try {
        await this.fetchCards()
        await this.planRenew()
        this.paymentStatus = PAYMENT_STATUS.SUCCESS
      } catch (e) {
        this.paymentStatus = PAYMENT_STATUS.FAILED
        this.paymentMessage = e?.data?.message
        console.error(e)
      }
      this.getLastPayment()
      this.paymentNoticeVisible = true
      this.isLoading = false
    },
    async handleUpdateCard () {
      this.isLoading = true
      try {
        await this.fetchCards()
        this.$message.success(this.$t('message.save_success'))
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false

      if (this.lastPayFailed) {
        this.paymentStatus = PAYMENT_STATUS.LAST_PAY_FAILED
        this.paymentNoticeVisible = true
      }
    },
    async handleRepay () {
      this.isLoading = true
      try {
        await this.planRepay()
        this.paymentStatus = PAYMENT_STATUS.SUCCESS
      } catch (e) {
        this.paymentStatus = PAYMENT_STATUS.FAILED
        this.paymentMessage = e?.data?.message
        console.error(e)
      }
      this.getLastPayment()
      this.paymentNoticeVisible = true
      this.isLoading = false
    },
    async fetchCards () {
      const params = {
        type: GMO_CREDIT,
        application: SELLER_SUBSCRIPTION
      }
      const { data } = await getCreditCards(params)
      this.currentCard = data?.data?.[0] || {}
    },
    async planRenew () {
      const storeId = this.currentStore.id
      const planId = this.currentStore.plan.id
      const cardId = this.currentCard.id
      await apiPlanRenew(storeId, planId, cardId)
    },
    async planRepay () {
      const storeId = this.currentStore.id
      const cardId = this.currentCard.id
      await apiPlanRepay(storeId, cardId)
    }
  }
}
</script>

<template lang="pug" src="./PaymentInfo.pug"></template>
