import * as loadingState from '@/constants/loadingState'
import { getReceipts } from './api'

const INIT_DATA = {
  loadingState: loadingState.EMPTY,
  receipts: [],
  meta: {},
  currentPage: 1
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  loadingState: state => state.loadingState,
  receipts: state => state.receipts,
  meta: state => state.meta,
  currentPage: state => state.currentPage,
  isLoading: state => state.loadingState === loadingState.LOADING
}

const mutations = {
  UPDATE_LOADING_STATE (state, loadingState) {
    state.loadingState = loadingState
  },
  UPDATE_RECEIPTS (state, data) {
    state.receipts = data
  },
  UPDATE_META (state, meta) {
    state.meta = meta
  },
  SET_CURRENT_PAGE (state, currentPage) {
    state.currentPage = currentPage
  }
}

const actions = {
  async fetchReceipts ({ rootGetters, getters, commit }) {
    commit('UPDATE_LOADING_STATE', loadingState.LOADING)

    try {
      const response = await getReceipts(rootGetters['Me/currentStore'].id, getters.currentPage)
      const { data, meta } = response.data
      commit('UPDATE_LOADING_STATE', loadingState.READY)
      commit('UPDATE_RECEIPTS', data)
      commit('UPDATE_META', meta)
    } catch (e) {
      commit('UPDATE_LOADING_STATE', loadingState.ERROR)
      console.error(e)
    }
  },
  setCurrentPage ({ commit }, page) {
    commit('SET_CURRENT_PAGE', page)
  },
  async updateRows ({ dispatch, state }, page) {
    dispatch('setCurrentPage', page)
    await dispatch('fetchReceipts')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
