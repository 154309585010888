<script>
import ApiCart from '@/api/cart'
import { mapGetters } from 'vuex'
import { normalizeImage } from '@/utils/normalizeImage'

export default {
  name: 'DialogAddOrder',
  props: {
    dialogAddOrderVisible: {
      type: Boolean,
      default: true
    },
    products: {
      type: Array,
      required: true
    },
    activities: {
      type: Array,
      default: () => []
    },
    comment: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      apiCart: new ApiCart(),
      inputSearchProduct: '',
      searchData: [],
      selectedItem: [],
      isBtnLoading: false
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName'])
  },
  methods: {
    getImgUrl (data, size) {
      if (!data.images || data.images.length === 0) return
      return normalizeImage(data.images[0].url, size)
    },

    async querySearchAsync (query, cb) {
      let searchData = this.products
        .filter(product => !this.activities.some(item =>
          item.type === 'draw' && (item.skus[0].product.id === product.id)))

      if (query !== '') {
        searchData = searchData.filter(product => {
          const keywords = [product.name]
          // product keyword
          keywords.push(...product.keywords.map(keyword => keyword.keyword))
          // skus keyword
          keywords.push(...product.skus.map(sku => sku.name))

          const content = keywords.join('!$').toLowerCase()

          return content.includes(query.toLowerCase())
        })
      }

      cb(searchData)
    },

    selectItem (item) {
      const selected = _.cloneDeep(this.products.find(product => product.id === item.id))
      this.selectedItem.push({
        ...selected,
        // 如果只有單一規格則自動帶入
        selectedSkus: selected.skus.length === 1 ? selected.skus[0].id : null,
        purchaseQuantity: 1
      })
      this.searchData = []
    },
    removeItem (id) {
      this.selectedItem = this.selectedItem.filter(select => select.id !== id)
    },

    validateOrder () {
      if (this.selectedItem.length === 0) {
        this.$message.error(this.$t('Components.DialogAddOrder.no_new'))
        return
      }

      const isPass = this.selectedItem.every(item => !!item.selectedSkus)

      if (!isPass) {
        this.$message.error(this.$t('Components.DialogAddOrder.no_sku'))
        return
      }

      // 新增訂單
      this.handleAddOrder()
    },

    async handleAddOrder () {
      let skus = _.groupBy(this.selectedItem, item => item.selectedSkus)

      skus = Object.keys(skus).map((skuId) => {
        return {
          id: skuId,
          quantity: skus[skuId].reduce((prev, sku) => {
            return prev + sku.purchaseQuantity
          }, 0)
        }
      })

      const data = {
        source_id: this.comment.from.id,
        source_name: this.comment.from.name,
        skus,
        comment: {
          data: { ...this.comment },
          type: this.comment.type
        }
      }
      this.isBtnLoading = true
      const success = await this.apiCart.create(data)
      this.isBtnLoading = false

      if (success) {
        this.$message.success(this.$t('Components.DialogAddOrder.order_created'))
        this.handleCloseDialog()
      }
    },
    // 關閉
    handleCloseDialog () {
      this.$emit('update:dialogAddOrderVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
