<script>

export const STATUS = {
  WAIT: 'wait',
  ACTIVE: 'active',
  COMPLETED: 'completed'
}

const INIT_STATE = [
  {
    status: STATUS.ACTIVE,
    icon: '',
    title: ''
  },
  {
    status: STATUS.WAIT,
    icon: '',
    title: ''
  },
  {
    status: STATUS.WAIT,
    icon: '',
    title: ''
  }
]

export default {
  name: 'AppSteps',
  STATUS,
  props: {
    steps: {
      type: Array,
      default: INIT_STATE
    },
    active: {
      type: Number,
      default: 0
    }
  },
  computed: {
    displaySteps () {
      return this.steps.map((step, index) => {
        let status
        if (index < this.active) {
          status = STATUS.COMPLETED
        } else if (index === this.active) {
          status = STATUS.ACTIVE
        } else {
          status = STATUS.WAIT
        }
        return {
          ...step,
          status
        }
      })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
