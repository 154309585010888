<script>
import { mapGetters } from 'vuex'
import ApiProduct from '@/api/product'
import ApiAuction from '@/api/auction'

export default {
  name: 'ShowActivityNew',

  props: {
    post: {
      type: Object,
      default: null
    },
    currentActivity: {
      type: Object,
      default: null
    },
    activeSteps: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isUploadImage: false,
      formAuction: {
        image: null,
        image_id: null,
        title: '',
        price: null,
        quality: null,
        limitQuality: 1,
        description: ''
      },

      isBtnLoading: false
    }
  },
  computed: {
    ...mapGetters('Me', ['token', 'imageUploadUrl']),

    rules () {
      return {
        image: [
          { required: false, message: this.$t('Post.Show.ShowActivityNew.rules.image'), trigger: 'blur' }
        ],
        title: [
          {
            required: true,
            message: this.$t('Post.Show.ShowActivityNew.rules.title', {
              product: this.$t('Post.Show.ShowActivityNew.product')
            }),
            trigger: 'blur' }
        ],
        price: [
          { required: true, message: this.$t('Post.Show.ShowActivityNew.rules.price'), trigger: 'blur' }
        ],
        quality: [
          { required: true,
            type: 'number',
            min: 1,
            message: this.$t('Post.Show.ShowActivityNew.rules.quality_min', {
              product: this.$t('Post.Show.ShowActivityNew.product')
            }),
            trigger: 'blur'
          }
        ],
        limitQuality: [
          {
            type: 'number',
            min: 1,
            message: this.$t('Post.Show.ShowActivityNew.rules.limitQuality', {
              product: this.$t('Post.Show.ShowActivityNew.product')
            }),
            trigger: 'blur' }
        ]
      }
    },
    uploadHeaders () {
      return { Authorization: `Bearer ${this.token}` }
    }
  },
  created () {
    if (this.currentActivity) {
      const sku = this.currentActivity.skus[0]
      const product = sku.product
      const image = sku.product.images[0]

      this.formAuction = {
        image_id: image ? image.id : null,
        image: image || null,
        title: product.name,
        price: sku.price,
        quality: sku.available_quantity,
        limitQuality: 1,
        description: product.description
      }
    }
  },
  methods: {
    /* ---- upload image ---- */
    handleUploadSuccess (response, file) {
      this.formAuction.image_id = response.id
      this.formAuction.image = response
      this.isUploadImage = false
    },
    handleFileError () {
      this.isUploadImage = false
      this.$message.error(this.$t('Post.Show.ShowActivityNew.message.upload_fail'))
    },
    handleBeforeUpload (file) {
      // console.log(file)
      const fileMB = file.size / (1024 ** 2)

      if (fileMB > 4) {
        this.$message.error(this.$t('Post.Show.ShowActivityNew.message.file_size'))
        return false
      }
      this.isUploadImage = true
    },
    handleDeleteImage () {
      this.formAuction.image_id = null
      this.formAuction.image = null
    },
    /* ---- end upload image ---- */
    async validateForm () {
      try {
        await this.$refs.form.validate()
        return true
      } catch (e) {
        return false
      }
    },
    async createProduct () {
      const apiProduct = new ApiProduct()
      const form = this.formAuction
      const postData = {
        name: form.title,
        description: form.description || null,
        image_ids: form.image_id ? [form.image_id] : [],
        image_urls: [],
        info: null,
        skus: [{
          name: this.$t('Post.Show.ShowActivityNew.one_sku'),
          price: form.price || 0,
          available_quantity: form.quality || null,
          is_giftable: true
        }],
        // 贈品、拍賣品，不需關鍵字
        no_keyword: true
      }

      const data = await apiProduct.create(postData)

      return data
    },
    async getActivitySkuId () {
      if (this.currentActivity) {
        return this.currentActivity.skus[0].id
      }

      const product = await this.createProduct()
      if (product) return product.skus[0].id
    },

    async createAuctionActivity (skuId) {
      const apiAuction = new ApiAuction()
      const form = this.formAuction

      const auctionData = {
        title: form.title,
        quantity: {
          id: skuId,
          salable_quantity: form.quality
        }
      }

      const response = await apiAuction.create(this.post.id, auctionData)

      return response
    },

    async handleSubmitAuction () {
      this.isBtnLoading = true

      // 驗證表單 && 取得 skuId
      const skuId = (await this.validateForm()) && (await this.getActivitySkuId())
      if (!skuId) {
        this.isBtnLoading = false
        return
      }

      // 建立拍賣
      const res = await this.createAuctionActivity(skuId)
      if (res) {
        this.$emit('handleCreateAuction')
      }

      this.isBtnLoading = false
    },
    async handleUpdateAuction () {
      this.isBtnLoading = true

      const apiAuction = new ApiAuction()
      const form = this.formAuction
      const sku = this.currentActivity.skus[0]

      const postData = {
        id: sku.id,
        price: form.price,
        modify_quantity: form.quality - sku.available_quantity
      }

      const res = await apiAuction.update(this.post.id, postData)

      if (res) {
        this.$emit('handleUpdateAuction')
        this.$emit('update:activeSteps', 'process')
      }

      this.isBtnLoading = false
    }

  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
