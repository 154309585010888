<script>
import { mapGetters, mapActions } from 'vuex'
import ApiSetting from '@/api/setting'
import ApiPlan from '@/api/plan'
import * as GMO_STATUS from '@/constants/gmoStatus'
import DialogConfirmSubmit from './components/DialogConfirmSubmit'
import DialogPlanFee from './components/DialogPlanFee'
import HeaderNote from './components/HeaderNote'
import FooterButton from './components/FooterButton'
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3 from './components/Step3'
import Step4 from './components/Step4'
import Step5 from './components/Step5'

const stepField = {
  contact_name_last: 1,
  commercial_name_last: 2,
  send_company: 3
}

const beforeUnloadListener = (event) => {
  event.preventDefault()
  event.returnValue = '変更を保存しますか？'
  return '変更を保存しますか？'
}

export default {
  name: 'GmoForm',
  components: {
    DialogConfirmSubmit,
    DialogPlanFee,
    HeaderNote,
    FooterButton,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5
  },

  beforeRouteLeave (to, from, next) {
    window.removeEventListener('beforeunload', beforeUnloadListener)
    if (this.gmoStatus === GMO_STATUS.DRAFT) {
      const create = this.$createElement
      this.$msgbox({
        title: '',
        center: true,
        showClose: true,
        customClass: 'remind-saving',
        message: create('h4', null, [
          create('span', null, '変更を保存しますか？')
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel')
      })
        .then(() => {
          this.updateGMOForm()
          next()
        })
        .catch(action => {
        })
    } else {
      next()
    }
  },

  data () {
    return {
      planUrl: process.env.VUE_APP_BUYER_DOMAIN_NAME + '/plans_ja.html',
      activeStep: 0,
      apiSetting: new ApiSetting(),
      showConfirmSubmit: false,
      dialogPlanFeeVisible: false,
      isReady: false,
      plans: [],
      selectedPlan: {},
      form: {
        // step1 info
        type: 'individual',
        corp_name: '',
        corp_name_kana: '',
        corp_number: '',
        corp_url: '',
        corp_address: {},
        corp_address_building: '',
        corp_address_kana: '',
        corp_tel: [],
        corp_date_establishment: '',
        corp_capital: '',
        corp_num_employees: '',
        corp_sales: '',
        corp_business: '',
        corp_category: '',
        corp_email: '',
        rep_name_last: '',
        rep_name_first: '',
        rep_name_kana_last: '',
        rep_name_kana_first: '',
        rep_birthday: '',
        rep_address: {},
        rep_address_building: '',
        rep_address_kana: '',
        rep_tel: [],
        // step2 info
        contact_name_last: '',
        contact_name_first: '',
        contact_name_kana_last: '',
        contact_name_kana_first: '',
        contact_department: '',
        contact_title: '',
        contact_tel: [],
        contact_email: '',
        shop_name: '',
        shop_name_kana: '',
        shop_name_alphabet: '',
        shop_url: '',
        shop_image: [],
        item_category: '',
        item: '',
        license: '',
        license_name: '',
        license_number: '',
        inquiry_start_time: '',
        inquiry_fin_time: '',
        // step3 info
        identify: 'individual',
        commercial_company_name: '',
        commercial_name_last: '',
        commercial_name_first: '',
        commercial_name_kana_first: '',
        commercial_name_kana_last: '',
        commercial_address: {},
        commercial_address_building: '',
        commercial_url: '',
        commercial_email: '',
        commercial_phone: [],
        opening_time: '',
        selling_price: '販売価格は表示された金額になります（表示された価格/消費税を含む）。',
        payment_info: '支払方法：クレジットカードで支払うことができます。支払い時間：注文確認後に支払いが確認されます',
        delivery_info: '配送リクエストを受け取ってから5日以内に発送します。',
        return_info: '製品に欠陥がない限り、基本的に返品は受け付けていません。',
        extra_fees: '注文の状況によって変わりますが納期は●日～●日が目安です。多少前後する場合がございます。',
        defective_info: '以下内容については、返品交換を承ります。 ◯商品が“著しく“破損していた(使用に支障がある商品) ◯注文したサイズや色(タイプ)ではないものが届いた。',
        is_jasrac: null,
        // step4 info
        send_company: '',
        send_name_last: '',
        send_name_first: '',
        send_department: '',
        send_tel: [],
        send_email: '',
        send_address: {},
        send_address_building: '',
        bank_code: '',
        bank_shop_num: '',
        bank_type: 'normal',
        bank_num: '',
        bank_name: '',
        checkTerms1: '',
        checkTerms2: '',
        checkTerms3: '',
        plan_id: ''
      },
      phoneField: ['corp_tel', 'rep_tel', 'contact_tel', 'commercial_phone', 'send_tel'],
      addressField: ['corp_address', 'rep_address', 'commercial_address', 'send_address']
    }
  },
  computed: {
    ...mapGetters('Me', ['gmoStatus', 'currentStore']),
    editDisabled () {
      const status = [GMO_STATUS.PENDING, GMO_STATUS.VERIFYING, GMO_STATUS.SUCCESS, GMO_STATUS.WAIT_FOR_APPROVE]
      return status.includes(this.gmoStatus)
    }
  },
  async created () {
    window.addEventListener('beforeunload', beforeUnloadListener)
    await Promise.all([
      this.fetchPlans(),
      this.fetchGMOStatus(),
      this.initialFormInfo()
    ])

    // Set plan from local storage else default full
    const plan = localStorage.getItem('jpPlan')
    if (plan) {
      this.setPlan(plan)
    } else {
      this.setPlan('full')
    }

    // Show plan dialog when coming from plan page
    if (sessionStorage.getItem('gmoShowPlan')) {
      sessionStorage.removeItem('gmoShowPlan')
      this.dialogPlanFeeVisible = true
    }

    // Remember last filled page
    for (const [field, step] of Object.entries(stepField)) {
      if (this.form[field]) {
        this.activeStep = step
      }
    }

    if (this.editDisabled) {
      this.activeStep = 4
    }
    this.isReady = true
  },

  methods: {
    ...mapActions('Me', ['fetchGMOStatus']),
    async fetchPlans () {
      const apiPlan = new ApiPlan()
      this.plans = await apiPlan.list()
    },
    setPlan (newPlan) {
      const plan = this.plans.find(plan => plan.name === newPlan)
      if (plan) {
        this.selectedPlan = plan
        this.form.plan_id = plan.id
      }
    },
    async validatePages () {
      try {
        const form = `formStep${this.activeStep + 1}`
        await this.$refs[form].validate()
        this.nextStep()
      } catch (error) {
        this.$message.error(this.$t('message.form_incomplete'))
      }
    },
    goStep (step) {
      // 送出不能編輯後隨便他跳
      if (this.editDisabled) {
        this.activeStep = step
        return
      }

      // 送出前填寫中只能點之前的
      if (step < this.activeStep) {
        this.updateGMOForm()
        this.activeStep = step
      }
    },
    nextStep () {
      // 送出不能編輯後隨便他下一步
      if (this.editDisabled) {
        parseInt(this.activeStep++)
        this.scrollToTop()
        return
      }

      // 送出前填寫中要存起來
      this.updateGMOForm()
      if (this.activeStep === 1 && !this.form.opening_time) {
        this.form.opening_time = `営業時間：${this.form.inquiry_start_time} - ${this.form.inquiry_fin_time} 定休日：`
      }

      if (this.activeStep < 3) {
        parseInt(this.activeStep++)
        this.scrollToTop()
      } else {
        this.showConfirmSubmit = true
      }
    },
    prevStep () {
      parseInt(this.activeStep--)
      this.scrollToTop()
    },
    async initialFormInfo () {
      if (this.form.shop_url === '') {
        this.form.shop_url = 'https://handsup.shop/' + this.currentStore.slug
      }

      const result = await this.apiSetting.getGMOForm()
      if (!result) return
      const { data } = result
      this.phoneField.forEach(field => {
        if (data[field]) {
          data[field] = data[field].split('-')
        }
      })
      this.addressField.forEach(field => {
        if (Array.isArray(data[field]) && data[field].length === 0) {
          data[field] = {}
        } else {
          this.$set(this.form, field, data[field])
        }
      })

      const formItems = Object.keys(this.form)
      for (const key of formItems) {
        if (data[key]) {
          this.form[key] = data[key]
        }
      }
    },
    async updateGMOForm () {
      let data = { ...this.form }
      this.phoneField.forEach(item => {
        data[item] = _.join(data[item], '-')
      })
      await this.apiSetting.updateGMOForm(data)
    },
    async submit () {
      try {
        await this.updateGMOForm()
        await this.apiSetting.submitGMOForm()
        await this.fetchGMOStatus()
      } catch (error) {
        console.error(error)
      }
      parseInt(this.activeStep++)
      this.showConfirmSubmit = false
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        left: 0
      })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
