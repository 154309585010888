import * as loadingState from '@/constants/loadingState'

import { getMemo, updateMemo } from '../apis/memo'

export const types = {
  LOADING: 'LOADING',
  GET_SUCCESS: 'GET_SUCCESS',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  FAILED: 'FAILED'
}

const INIT_DATA = {
  memoData: '',

  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  memoLoadingState: state => state.loadingState,
  memoData: state => state.memoData
}

const mutations = {
  [types.LOADING] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.GET_SUCCESS] (state, payload) {
    state.loadingState = loadingState.READY
    state.memoData = payload
  },

  [types.UPDATE_SUCCESS] (state, payload) {
    state.loadingState = loadingState.READY
  },

  [types.FAILED] (state, error) {
    state.loadingState = loadingState.ERROR
    state.error = error

    console.error(error)
  }
}

const actions = {
  getMemo ({ commit }, orderId) {
    commit(types.LOADING)

    return getMemo(orderId)
      .then(response => {
        const { data: { data: memo } } = response
        commit(types.GET_SUCCESS, memo)
      })
      .catch(err => {
        commit(types.FAILED, err)
        throw err
      })
  },
  updateMemo ({ commit }, { orderId, memo }) {
    commit(types.LOADING)

    return updateMemo(orderId, memo)
      .then(() => commit(types.UPDATE_SUCCESS))
      .catch(err => {
        commit(types.FAILED, err)
        throw err
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
