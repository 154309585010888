<script>
import Cookies from 'js-cookie'
import dateFormat from 'date-fns/format'
import ApiBook from '@/api/storeWalletBook'
import SimpleDialog from '@/components/SimpleDialog'
import StepsBar from '@/components/StepsBar'
import Pagination from '@/components/Pagination'
import axios from '@/plugins/http.js'
import storeId from '@/api/_storeId'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TransactionBalanceTable',
  components: { SimpleDialog, StepsBar, Pagination },
  props: {
    showTooltip: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dateRange: null,
      searchRule: {
        dateRange: null,
        amountType: null,
        keyword: null
      },
      isSubmitBtnLoading: false,
      categoryOptions: [
        { label: this.$t('Setting.TransactionBalanceTable.withdraw'), value: 'negative' },
        { label: this.$t('Setting.TransactionBalanceTable.enter'), value: 'positive' }
      ],
      totalBalanceAmount: null,
      paginator: null,
      rows: [],
      incomeDetail: {
        fees: [],
        totalAmount: null,
        amount: null
      },
      refundDetail: {
        fees: [],
        totalAmount: null,
        amount: null
      },
      withdrawalApply: null,
      steps: [
        { text: this.$t('Setting.TransactionBalanceTable.steps.apply'), time: '2019/01/12 10:00:30', status: 'completed', content: '', icon: 'fa-file-export' },
        { text: this.$t('Setting.TransactionBalanceTable.steps.ready'), time: '2019/01/30 10:00', status: 'active', content: '', icon: 'fa-money-check-alt' },
        { text: this.$t('Setting.TransactionBalanceTable.steps.success'), status: '', content: '', icon: 'fa-file-check' }
      ],
      withdrawalAmount: 0,
      isAppIntroduceVisible: false
    }
  },

  computed: {
    ...mapGetters('Me', ['currencySymbol', 'currentStore', 'isPhoneVerfied', 'enabledExportAccountBalance']),
    withdrawValid () {
      const numberRegex = new RegExp(/^[0-9]*$/)
      if (!this.withdrawalAmount) return false
      return this.withdrawalAmount.match(numberRegex)
    },
    incomeDetailFees () {
      let incomeDetailFees = JSON.parse(JSON.stringify(this.incomeDetail.fees)).reverse()

      if (incomeDetailFees[0] && incomeDetailFees[0]['info'] === 'square_celebrity_commission') {
        let lastFee = incomeDetailFees.shift()
        incomeDetailFees.push(lastFee)
      }

      return incomeDetailFees
    },
    canWithdraw () {
      const withdrawRegions = ['TW']

      return withdrawRegions.includes(this.currentStore.region) && this.isPhoneVerfied
    }
  },
  async created () {
    await this.fetchBook()
    this.showTip()
  },
  methods: {
    ...mapActions(['setLoading']),
    showTip () {
      const currentToken = Cookies.get('api_token')
      const lastToken = localStorage.getItem('lastTokenBalance')

      if (currentToken === lastToken) {
        this.isAppIntroduceVisible = false
      } else {
        this.isAppIntroduceVisible = true
      }
    },
    closeTip () {
      this.isAppIntroduceVisible = false
      const currentToken = Cookies.get('api_token')
      localStorage.setItem('lastTokenBalance', currentToken)
    },
    isNumber (evt) {
      evt = evt || window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async submitWithdraw () {
      this.isSubmitBtnLoading = true

      if (!this.withdrawValid) {
        this.$message.error(this.$t('Setting.TransactionBalanceTable.message.enter_withdraw_dollar'))
        this.isSubmitBtnLoading = false
        return
      }

      try {
        const res = await axios.post(`/api/v1/stores/${storeId()}/banking/withdrawal`, {
          withdrawal_amount: this.withdrawalAmount,
          currency: 'TWD'
        })

        if (res.data.id) {
          this.$message.success(this.$t('Setting.TransactionBalanceTable.message.withdraw_success'))
          this.showWithdrawDetail(res.data)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.$refs.withdraw.toggle()
        this.fetchBook()
        this.isSubmitBtnLoading = false
      }
    },
    handleDateChange (dateRange) {
      this.searchRule.from = dateRange ? dateFormat(dateRange[0], 'YYYY/MM/DD') : null
      this.searchRule.to = dateRange ? dateFormat(dateRange[1], 'YYYY/MM/DD') : null
      this.fetchBook()
    },
    async fetchBook () {
      const apiBook = new ApiBook()
      const params = Object.assign({}, this.searchRule)
      const data = await apiBook.list(params)

      data.forEach(row => {
        row.category = this.getAccountingCategory(row)
        row.description = this.getAccountingDescription(row)
      })

      this.rows = data
      this.paginator = apiBook.paginator
      this.totalBalanceAmount = apiBook.paginator.data().meta.total_amount || 0
    },
    getAccountingCategory (row) {
      if (row.amount < 0) {
        return this.$t('Setting.TransactionBalanceTable.withdraw')
      }

      return this.$t('Setting.TransactionBalanceTable.enter')
    },
    getAccountingDescription (row) {
      if (row.amount < 0) {
        return this.$t(
          'Setting.TransactionBalanceTable.account_deposited',
          { account: row.apply.bank_registrant_account }
        )
      }

      switch (row.accounting_type) {
        case 'release_by_platform':
          return this.$t('Setting.TransactionBalanceTable.order_entered')
        case 'logistics_compensation':
          return this.$t('Setting.TransactionBalanceTable.order_compensation')
        default:
          return this.$t('Setting.TransactionBalanceTable.order_refunded')
      }
    },
    updateData (data) {
      this.rows = data
      // 跟上面重複 待優化（未驗證）
      data.forEach(row => {
        row.category = this.getAccountingCategory(row)
        row.description = this.getAccountingDescription(row)
      })
      this.totalPendingAmount = this.apiBook.paginator.data().meta.total_amount || 0
    },
    showDetail (row) {
      switch (row.accounting_type) {
        case 'release_by_platform':
          this.showIncomeDetail(row)
          break
        case 'order_commission':
        case 'logistics_compensation':
          this.showRefundDetail(row)
          break
        case 'apply_withdraw_by_store':
          this.showWithdrawDetail(row.apply)
          break
      }
    },
    showIncomeDetail (row) {
      this.incomeDetail = {
        fees: row.fees,
        amount: row.amount,
        orderId: row.order ? row.order.id : '',
        description: row.description
      }

      const feeAmount = this.incomeDetail.fees.reduce((sum, fee) => sum + +fee.amount, 0)
      this.incomeDetail.totalAmount = +this.incomeDetail.amount + feeAmount

      this.$refs.incomeDetail.toggle()
    },
    showRefundDetail (row) {
      this.refundDetail = {
        fees: row.fees,
        amount: row.amount,
        orderId: row.order ? row.order.id : '',
        description: row.description
      }

      this.$refs.refundDetail.toggle()
    },
    showWithdrawDetail (apply) {
      this.prepareWithdrawDetailData(apply)

      this.$refs.withdrawDetail.toggle()
    },
    prepareWithdrawDetailData (apply) {
      this.withdrawalApply = apply

      this.steps = [
        {
          text: this.$t('Setting.TransactionBalanceTable.steps.apply'),
          time: apply.created_at,
          status: 'completed',
          content: '',
          icon: 'fa-file-export'
        },
        {
          text: this.$t('Setting.TransactionBalanceTable.steps.ready'),
          time: '',
          status: 'active',
          content: '',
          icon: 'fa-money-check-alt'
        },
        {
          text: this.$t('Setting.TransactionBalanceTable.steps.success'),
          status: '',
          content: '',
          icon: 'fa-file-check'
        }
      ]

      if (apply.status === 'canceled') {
        this.steps[1] = {
          text: this.$t('Setting.TransactionBalanceTable.steps.apply'),
          time: apply.updated_at,
          status: 'completed',
          content: '',
          icon: 'fa-money-check-alt'
        }
        this.steps[2] = {
          text: this.$t('Setting.TransactionBalanceTable.steps.fail'),
          time: apply.updated_at,
          status: 'failed',
          content: apply.transfer_canceled_reason,
          icon: 'fa-times-circle'
        }
      }

      if (apply.transfer_finished_date) {
        this.steps[1] = {
          text: this.$t('Setting.TransactionBalanceTable.steps.apply'),
          status: 'completed',
          time: '',
          content: '',
          icon: 'fa-money-check-alt'
        }
        this.steps[2] = {
          text: this.$t('Setting.TransactionBalanceTable.steps.success'),
          time: apply.transfer_finished_date,
          status: 'completed',
          content: '',
          icon: 'fa-file-check'
        }
      }
    },
    // 匯出餘額excel
    async handleExport () {
      const apiBook = new ApiBook()
      await apiBook.export(this.searchRule)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
