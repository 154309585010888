import BaseApi from './baseApi'
import storeId from './_storeId'
import axios from '@/plugins/http.js'

export default class Print extends BaseApi {
  uri = `/api/v1/stores/${storeId()}/print`
  undeliveredPagination = { links: {}, meta: {} }
  predeliveryPagination = { links: {}, meta: {} }

  printHsinchu = async postData => {
    const api = `/api/v1/stores/${storeId()}/print/hsinchu`

    try {
      const { data } = await axios.post(api, postData)

      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }

  undeliveredPaginator = {
    data: () => this.undeliveredPagination,

    to: async page => this.listUndelivered({ ...this.undeliveredPagination.params, page })
  }

  listUndelivered = async (params) => {
    const api = `/api/v1/stores/${storeId()}/print/undelivered-order`

    try {
      const { data } = await axios.get(api, { params })
      this.undeliveredPagination = { ...data, params }
      if (this.undeliveredPagination.meta) {
        this.undeliveredPagination.meta.per_page = parseInt(this.undeliveredPagination.meta.per_page)
      }

      return data
    } catch (e) {
      return []
    }
  }

  predeliveryPaginator = {
    data: () => this.predeliveryPagination,

    to: async page => this.listPredelivery({ ...this.predeliveryPagination.params, page })
  }

  listPredelivery = async (params) => {
    const api = `/api/v1/stores/${storeId()}/print/pre-delivery-order`

    try {
      const { data } = await axios.get(api, { params })
      this.predeliveryPagination = { ...data, params }
      if (this.predeliveryPagination.meta) {
        this.predeliveryPagination.meta.per_page = parseInt(this.predeliveryPagination.meta.per_page)
      }

      return data
    } catch (e) {
      return []
    }
  }

  // 批次列印條碼
  batchOrder = async (postData) => {
    const api = `/api/v1/stores/${storeId()}/print/batch-order`

    try {
      const { data } = await axios.post(api, postData)

      return data
    } catch (e) {
      return null
    }
  }
}
