<script>

export default {
  name: 'TermsOfServiceForMerchantAgency',

  computed: {
    isStream17 () {
      /**
       * 當 app 選擇推流到 17 時，那邊的連結會加上 stream=17 的 query
       * 若有 query 才顯示某一段合約
       */
      return this.$route.query.stream === '17'
    }
  }
}
</script>

<template lang="pug" src="./TermsOfServiceForMerchantAgency.pug"></template>
