export const types = {
  SET_EDIT_PRODUCT: 'SET_EDIT_PRODUCT',
  RESET_EDIT_PRODUCT: 'RESET_EDIT_PRODUCT',

  UPDATE_ID: 'UPDATE_ID',
  UPDATE_NAME: 'UPDATE_NAME',
  UPDATE_DEFAULT_KEYWORD: 'UPDATE_DEFAULT_KEYWORD',
  UPDATE_FILE_LIST: 'UPDATE_FILE_LIST',
  UPDATE_SKUS: 'UPDATE_SKUS',
  UPDATE_DESCRIPTION: 'UPDATE_DESCRIPTION',
  SALE_STARTED_AT: 'SALE_STARTED_AT',
  SALE_ENDED_AT: 'SALE_ENDED_AT',
  UPDATE_ADULT_WARNING: 'UPDATE_ADULT_WARNING',
  ADD_NEW_IMAGE: 'ADD_NEW_IMAGE',
  REMOVE_IMAGE: 'REMOVE_IMAGE'
}

const INIT_DATA = {
  editProduct: null,
  id: '',
  name: '',
  defaultKeyword: '',

  images: [],

  skus: [{
    name: '',
    price: null,
    available_quantity: null,
    number: '',
    info: {
      source_id: '',
      purchase_url: ''
    }
  }],

  description: '',
  saleStartedAt: null,
  saleEndedAt: null,
  adultWarning: false
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  editProduct: state => state.editProduct,

  id: state => state.id,
  name: state => state.name,
  defaultKeyword: state => state.defaultKeyword,
  images: state => state.images,
  skus: state => state.skus,
  description: state => state.description,
  saleStartedAt: state => state.saleStartedAt,
  saleEndedAt: state => state.saleEndedAt,
  adultWarning: state => state.adultWarning,

  calcProductData: state => {
    let data = {
      name: state.name,
      default_keyword: state.defaultKeyword,
      image_ids: state.images.map(file => file.id || file.response.id),
      skus: state.skus,
      description: state.description,
      sale_started_at: state.saleStartedAt
        ? new Date(state.saleStartedAt).toISOString()
        : null,
      sale_ended_at: state.saleEndedAt
        ? new Date(state.saleEndedAt).toISOString()
        : null,
      adult_warning: state.adultWarning
    }

    if (state.editProduct) {
      data.id = state.id
    }

    return data
  }
}

const mutations = {
  [types.SET_EDIT_PRODUCT] (state, data) {
    state.id = data.id
    state.name = data.name
    state.defaultKeyword = data.default_keyword
    state.images = data.images
    state.skus = data.skus.map(sku => {
      return {
        ...sku,
        price: Math.floor(sku.price),
        original_quantity: sku['available_quantity'],
        info: {
          source_id: sku?.info?.['source_id'] || null,
          purchase_url: sku?.info?.['purchase_url'] || null
        }
      }
    })

    state.description = data.description
    state.saleStartedAt = data.sale_started_at
      ? new Date(data.sale_started_at)
      : null
    state.saleEndedAt = data.sale_ended_at
      ? new Date(data.sale_ended_at)
      : null
    state.adultWarning = data.adult_warning

    const { id, name, defaultKeyword, images, description, saleStartedAt, saleEndedAt, adultWarning } = state

    state.editProduct = {
      id,
      name,
      defaultKeyword,
      images,
      description,
      saleStartedAt,
      saleEndedAt,
      adultWarning,
      skus: data.skus
    }
  },

  [types.RESET_EDIT_PRODUCT] (state, data) {
    state.editProduct = null

    state.id = ''
    state.name = ''
    state.defaultKeyword = ''
    state.images = []
    state.skus = [{
      name: '',
      price: null,
      available_quantity: null,
      number: '',
      info: {
        source_id: '',
        purchase_url: ''
      }
    }]
    state.description = ''
    state.saleStartedAt = ''
    state.saleEndedAt = ''
    state.adultWarning = false
  },

  [types.UPDATE_ID] (state, id) {
    state.id = id
  },
  [types.UPDATE_NAME] (state, name) {
    state.name = name
  },
  [types.UPDATE_DEFAULT_KEYWORD] (state, keyword) {
    state.defaultKeyword = keyword
  },
  [types.UPDATE_FILE_LIST] (state, image) {
    state.images = image
  },
  [types.ADD_NEW_IMAGE] (state, image) {
    state.images = [...state.images, image]
  },
  [types.REMOVE_IMAGE] (state, deletedImageId) {
    state.images = state.images.filter(image => image.id !== deletedImageId)
  },
  [types.UPDATE_SKUS] (state, skus) {
    state.skus = skus
  },
  [types.UPDATE_DESCRIPTION] (state, description) {
    state.description = description
  },
  [types.SALE_STARTED_AT] (state, time) {
    state.saleStartedAt = time
  },
  [types.SALE_ENDED_AT] (state, time) {
    state.saleEndedAt = time
  },
  [types.UPDATE_ADULT_WARNING] (state, bool) {
    state.adultWarning = bool
  }
}

const actions = {
  setEditProduct ({ commit }, data = null) {
    if (data) {
      commit(types.SET_EDIT_PRODUCT, data)
    }
  },

  resetEditProduct ({ commit }) {
    commit(types.RESET_EDIT_PRODUCT)
  },

  updateId ({ commit }, id) {
    commit(types.UPDATE_ID, id)
  },
  updateName ({ commit }, name) {
    commit(types.UPDATE_NAME, name)
  },
  updateDefaultKeyword ({ commit }, keyword) {
    commit(types.UPDATE_DEFAULT_KEYWORD, keyword)
  },
  addNewImage ({ commit }, image) {
    commit(types.ADD_NEW_IMAGE, image)
  },
  removeImage ({ commit }, deletedImageId) {
    commit(types.REMOVE_IMAGE, deletedImageId)
  },
  updateImages ({ commit }, images) {
    commit(types.UPDATE_FILE_LIST, images)
  },
  updateSkus ({ commit }, skus) {
    commit(types.UPDATE_SKUS, skus)
  },
  updateDescription ({ commit }, description) {
    commit(types.UPDATE_DESCRIPTION, description)
  },
  updateSaleStartedAt ({ commit }, time) {
    commit(types.SALE_STARTED_AT, time)
  },
  updateSaleEndedAt ({ commit }, time) {
    commit(types.SALE_ENDED_AT, time)
  },
  updateAdultWarning ({ commit }, bool) {
    commit(types.UPDATE_ADULT_WARNING, bool)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
