<script>
import VueTypes from 'vue-types'

export default {
  name: 'DialogPlanFee',
  props: {
    plan: VueTypes.object.def({})
  },
  methods: {
    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
</script>

<template lang="pug" src="./DialogPlanFee.pug"></template>
