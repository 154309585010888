<script>
import VueTypes from 'vue-types'

export default {
  name: 'PaginationPure',
  components: {},
  props: {
    paginator: VueTypes.object
  },
  methods: {
    async changePage (toPage) {
      this.$emit('change', toPage)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
