import * as loadingState from '@/constants/loadingState'
import * as REGIONS from '@/constants/regions'
import * as ORDER_TYPES from '@/constants/orderTypes'

import orderNormalizer from '@/utils/orderNormalizer'

import { fetchCartDetail } from '../apis/cart'
import { fetchOrderDetail } from '../apis/order'

import getSteps from '../../utils/steps'

export const types = {
  UPDATE_IS_CART: 'UPDATE_IS_CART',
  UPDATE_STEPS: 'UPDATE_STEPS',

  FETCH_ORDER: 'FETCH_ORDER',
  FETCH_ORDER_SUCCESS: 'FETCH_ORDER_SUCCESS',
  FETCH_ORDER_FAILED: 'FETCH_ORDER_FAILED',

  RESET_ORDER_DATA: 'RESET_ORDER_DATA'
}

// Note:
// 希望最終資料都從 orderData 取得，再進行轉換想要的資料
// 而原先的 order 是過渡性的資料，原因是先 normalizer 過後，導致某些資料不易再取得轉換

const INIT_DATA = {
  isCart: false,
  orderData: {}, // 原始資料
  order: {}, // normalizer 過的 order
  steps: [],

  loadingState: loadingState.EMPTY,
  error: null
}

const ORDER_NORMALIZE_TYPES = {
  CART: 'cart',
  ORDER: 'order'
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  isCart: state => state.isCart,
  orderData: state => state.orderData,
  order: state => state.order,
  isSubscriptionType: state => _.get(state.orderData, 'type', ORDER_TYPES.PRODUCT) === ORDER_TYPES.SUBSCRIPTION,
  steps: state => state.steps,
  items: state => _.get(state.orderData, 'items', []),
  buyer: state => _.get(state.orderData, 'buyer', []),

  orderLoadingState: state => state.loadingState
}

const mutations = {
  [types.UPDATE_IS_CART] (state, flag) {
    state.isCart = flag
  },

  [types.UPDATE_STEPS] (state, steps) {
    state.steps = steps
  },

  [types.FETCH_ORDER] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.FETCH_ORDER_SUCCESS] (state, { orderData, order, steps }) {
    state.loadingState = loadingState.READY

    state.orderData = orderData
    state.order = order // 這是有 Normalizer 過的
    state.steps = steps
  },

  [types.FETCH_ORDER_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  },

  [types.RESET_ORDER_DATA] (state) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))
  }
}

const actions = {
  updateIsCart ({ commit }, flag) {
    commit(types.UPDATE_IS_CART, flag)
  },
  async fetchOrderDetail ({ getters, commit, dispatch }, { orderId, region }) {
    // 判斷是 購物車 或是 訂單
    const fetchFun = getters.isCart ? fetchCartDetail : fetchOrderDetail
    const normalizeType = getters.isCart
      ? ORDER_NORMALIZE_TYPES.CART
      : ORDER_NORMALIZE_TYPES.ORDER

    return fetchFun(orderId)
      .then(response => {
        const { data } = response
        const order = orderNormalizer(normalizeType, data)

        commit(types.FETCH_ORDER_SUCCESS, { orderData: data, order })
        dispatch('calculateSteps', { order: data, region })
      })
      .catch(err => {
        commit(types.FETCH_ORDER_FAILED, err)
        throw err
      })
  },

  calculateSteps ({ commit }, { order, region }) {
    let steps = getSteps(order.status, order)
    // JP removed completed step
    if (region === REGIONS.JP) {
      steps = steps.filter(step => step.type !== 'completed')
    }

    commit(types.UPDATE_STEPS, steps)
  },

  resetOrderData ({ commit }) {
    commit(types.RESET_ORDER_DATA)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
