import _ from 'lodash'
import * as loadingState from '@/constants/loadingState'

import { getVoucherList } from '../apis'

export const types = {
  FETCH_VOUCHER: 'FETCH_VOUCHER',
  FETCH_VOUCHER_SUCCESS: 'FETCH_VOUCHER_SUCCESS',
  FETCH_VOUCHER_FAILED: 'FETCH_VOUCHER_FAILED',

  UPDATE_SEARCH_STATUS: 'UPDATE_SEARCH_STATUS',
  UPDATE_SEARCH_NAME: 'UPDATE_SEARCH_NAME',
  UPDATE_SEARCH_PAGE: 'UPDATE_SEARCH_PAGE'
}

const INIT_DATA = {
  vouchers: [],
  paginator: null,
  search: {
    acquire_status: '',
    name: '',
    page: 1
  },

  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  vouchers: state => state.vouchers,
  paginator: state => state.paginator,
  search: state => state.search,
  voucherListLoadingState: state => state.loadingState
}

const mutations = {
  [types.FETCH_VOUCHER] (state, isFirst) {
    state.loadingState = loadingState.LOADING

    if (isFirst) {
      // 初始化資料
      Object.assign(state, _.cloneDeep(INIT_DATA))
    }
  },

  [types.FETCH_VOUCHER_SUCCESS] (state, response) {
    state.loadingState = loadingState.READY

    const { data, meta } = response.data

    state.vouchers = data
    state.paginator = meta
  },

  [types.FETCH_VOUCHER_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  },

  [types.UPDATE_SEARCH_STATUS] (state, { status }) {
    state.loadingState = loadingState.LOADING
    state.search.acquire_status = status
    state.search.page = 1
  },
  [types.UPDATE_SEARCH_NAME] (state, { name }) {
    state.loadingState = loadingState.LOADING
    state.search.name = name
    state.search.page = 1
  },
  [types.UPDATE_SEARCH_PAGE] (state, { page }) {
    state.search.page = page
  }
}

const actions = {
  fetchVoucherList: _.debounce(async function (
    { state, commit, rootGetters },
    isFirst = false
  ) {
    commit('SET_LOADING', true, { root: true })

    commit(types.FETCH_VOUCHER, isFirst)

    return getVoucherList(rootGetters['Me/storeName'], state.search)
      .then(response => commit(types.FETCH_VOUCHER_SUCCESS, response))
      .catch(err => {
        commit(types.FETCH_VOUCHER_FAILED, err)
        throw err
      })
      .finally(() => {
        commit('SET_LOADING', false, { root: true })
      })
  }, 500),

  updateSearchStatus ({ commit, dispatch }, status) {
    commit(types.UPDATE_SEARCH_STATUS, { status })
    dispatch('fetchVoucherList')
  },
  updateSearchName ({ commit, dispatch }, name) {
    commit(types.UPDATE_SEARCH_NAME, { name })
    dispatch('fetchVoucherList')
  },
  updateSearchPage ({ commit, dispatch }, page) {
    commit(types.UPDATE_SEARCH_PAGE, { page })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
