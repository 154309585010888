<script>
import PaymentList from '../PaymentList'

export default {
  name: 'ContainerPaymentRecord',

  components: { PaymentList }
}
</script>

<template lang="pug" src="./ContainerPaymentRecord.pug"></template>
