import BaseApi from './baseApi'
import storeId from './_storeId'
import axios from '@/plugins/http.js'

export default class Coupon extends BaseApi {
  uri = `/api/v1/stores/${storeId()}/coupons`

  // 更新 coupon
  update = async (couponId, updateData) => {
    try {
      const api = `/api/v1/coupons/${couponId}`
      const { data } = await axios.put(api, updateData)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  getCouponHistory = couponId => (
    axios
      .get(`/api/v1/coupons/${couponId}/history?all`)
      .then(({ data: { data } }) => data)
      .catch(err => {
        console.error(err)

        return []
      })
  )
}
