import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import i18n from '@/plugins/i18n'

export const getShippingLabel = shippingType => {
  switch (shippingType) {
    case SHIPPING_TYPES.HOME_DELIVERY:
      return i18n.t('shipping.type.home_delivery')
    case SHIPPING_TYPES.FAMILY:
      return i18n.t('shipping.type.family')
    case SHIPPING_TYPES.SEVEN:
      return i18n.t('shipping.type.seven')
    case SHIPPING_TYPES.SELF_PICK_UP:
      return i18n.t('shipping.type.self_pick_up')
    case SHIPPING_TYPES.HSINCHU:
      return i18n.t('shipping.type.hsinchu')
    default:
      break
  }
}
