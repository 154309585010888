const actions = {
  async triggerNotification ({ getters, dispatch }, { type, data }) {
    switch (type) {
      case 'live.started':
        // 直播開始
        dispatch('fetchAll')
        dispatch('fetchStreamInfo', getters.postId)
        break
      case 'live.stopped':
        dispatch('checkStopPost')
        break
      case 'sku.sold':
        dispatch('fetchAll')
        break
      case 'live_product.set':
      case 'products.changed':
        dispatch('fetchPost')
        break
      case 'bidding.analysed':
        // 喊單模組 之 分析下單數量
        dispatch('updateBiddingAnalysed', data.analysed_count)
        break
      case 'bidding.started':
      case 'bidding.ended':
        dispatch('fetchAll')
        break
      case 'auction.started':
      case 'auction.ended':
      case 'auction.published':
      case 'draw.started':
      case 'draw.published':
        if (getters.celebrityToken) dispatch('fetchAll')
        break
      case 'draw.ended':
        // 抽獎活動結束
        await Promise.all([
          dispatch('fetchPost'),
          dispatch('fetchActivityDraws', getters.postId)
        ])

        dispatch('checkDrawActivity')

        // 動畫
        dispatch('updateDrawAnimation', false)
        dispatch('updateIsDrawEnd', true)
        break
    }
  }
}

export default {
  actions
}
