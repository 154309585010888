<script>
import VueTypes from 'vue-types'

export default {
  name: 'AppXBox',
  props: {
    active: VueTypes.bool.def(false)
  }
}
</script>

<template lang="pug" src="./AppXBox.pug"></template>
<style lang="scss" src="./AppXBox.scss" scoped></style>
