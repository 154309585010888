<script>
import ApiService from '@/api/additionalService'
import Pagination from '@/components/Pagination'
import PointsDetail from '../PointsDetail'

export default {
  name: 'Points',
  components: { PointsDetail, Pagination },
  data () {
    return {
      apiService: new ApiService(),
      detailVisible: false,
      logs: [],
      log: null,
      paginator: null
    }
  },
  created () {
    this.fetchLogs()
  },
  methods: {
    async fetchLogs () {
      const { data: logs } = await this.apiService.getLogs()
      this.paginator = this.apiService.logPaginator
      this.logs = logs
    },
    showDetail (log) {
      this.log = log
      this.detailVisible = true
    },
    update (rows) {
      this.logs = rows.data
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
