<script>
import { mapGetters } from 'vuex'

import * as REGIONS from '@/constants/regions'

import SelectTw from '../SelectTw'
import SelectJp from '../SelectJp'

export default {
  name: 'SelectContainer',

  components: { SelectTw, SelectJp },

  computed: {
    ...mapGetters('Me', ['region']),

    componentName () {
      const map = {
        [REGIONS.TW]: 'SelectTw',
        [REGIONS.JP]: 'SelectJp'
      }

      return map[this.region] || map[REGIONS.TW]
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
