<script>
import { mapGetters } from 'vuex'

import * as COUPON_STATUS from '@/constants/couponStatus'

const STATUS_I18N_MAP = {
  [COUPON_STATUS.COMING]: 'Coupon.status_coming',
  [COUPON_STATUS.ONGOING]: 'Coupon.status_ongoing',
  [COUPON_STATUS.EXPIRED]: 'Coupon.status_expired',
  [COUPON_STATUS.INTERRUPTED]: 'Coupon.status_interrupted'
}

const STATUS_CLASS = {
  [COUPON_STATUS.COMING]: 'text-warning',
  [COUPON_STATUS.ONGOING]: 'text-success',
  [COUPON_STATUS.EXPIRED]: 'text-gray-800',
  [COUPON_STATUS.INTERRUPTED]: 'text-gray-800'
}

export default {
  name: 'VoucherTable',

  computed: {
    ...mapGetters('Me', ['currencySymbol']),
    ...mapGetters('Voucher', {
      vouchers: 'vouchers'
    }),

    normalizeVouchers () {
      return this.vouchers.map(voucher => ({
        ...voucher,
        isActivityEnd: [COUPON_STATUS.EXPIRED, COUPON_STATUS.INTERRUPTED].includes(voucher.acquire_status),
        remainingQuantity: voucher.acquire_quantity ? voucher.acquire_quantity - voucher.used_acquire_quantity : null
      }))
    }
  },

  methods: {
    convertStatusI18n (key) {
      return this.$t(STATUS_I18N_MAP[key])
    },
    calStatusType (status) {
      return this.convertStatusI18n(COUPON_STATUS[status.toUpperCase()]) || status
    },
    calStatusClass (status) {
      return STATUS_CLASS[status] || status
    },

    handleEditVoucher (voucher) {
      this.$emit('handleEditVoucher', JSON.parse(JSON.stringify(voucher)))
    }
  }
}
</script>

<template lang="pug" src="./VoucherTable.pug"></template>
<style lang="scss" src="./VoucherTable.scss" scoped></style>
