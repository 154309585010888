export const SESSION_KEYS = {
  isPopupOpen: 'isPopupOpen'
}
// fbbuy 綁定 hu 帳號相關
export const FXH_KEYS = {
  fxhPayload: 'fxh_payload',
  fxhHasStore: 'fxh_has_store',
  fxhRedirectPath: 'fxh_redirect_path'
}

// FB 登入失敗帶在網頁後的 hash
export const FACEBOOK_LOGIN_ERROR_HASH = '#login-error'
