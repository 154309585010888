const validate = {
  // slug規則: 必須一個小寫英文，數字，-, _
  slug (text) {
    return (/^[a-z0-9\-_]*[a-z][a-z0-9\-_]*$/).test(text)
  },

  hasStar (text) {
    return (/\*+/).test(text)
  }
}

export default validate
