import { render, staticRenderFns } from "./BindFb.pug?vue&type=template&id=5d30d8bd&scoped=true&lang=pug&"
import script from "./BindFb.vue?vue&type=script&lang=js&"
export * from "./BindFb.vue?vue&type=script&lang=js&"
import style0 from "./BindFb.scss?vue&type=style&index=0&id=5d30d8bd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d30d8bd",
  null
  
)

export default component.exports