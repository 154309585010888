
export const actionTypes = {
  UPDATE_SELECTED_PRODUCTS: 'UPDATE_SELECTED_PRODUCTS',
  RESET: 'RESET'
}

const INIT_DATA = {
  selectedProducts: []
}

const state = _.cloneDeep(INIT_DATA)

const mutations = {
  [actionTypes.UPDATE_SELECTED_PRODUCTS] (state, payload) {
    state.selectedProducts = [...payload]
  },
  [actionTypes.RESET] (state, payload) {
    Object.assign(state, INIT_DATA)
  }
}

const actions = {
  updateProducts ({ commit }, payload) {
    commit(actionTypes.UPDATE_SELECTED_PRODUCTS, payload)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
