<script>

export default {
  name: 'OrderMessage',
  props: {
    isCart: {
      type: Boolean,
      default: true
    },
    messages: {
      type: Array,
      default: () => []
    },
    sendingMessages: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      message: null
    }
  },
  watch: {
    messages () {
      this.$nextTick(() => {
        this.$refs.messagesList.scrollTop = this.$refs.messagesList.scrollHeight
      })
    }
  },
  methods: {
    async createOrderMessage () {
      if (this.sendingMessages) return

      this.$emit('sendMessage', this.message)
      this.message = null
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
