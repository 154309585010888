import ClipVideo from './ClipVideo'
import ProductAnchor from '@/api/productAnchor'

export function sortByLastEditAnchor (anchors) {
  const tempMap = new Map()

  anchors.forEach((currentAnchor) => {
    const currentProductId = currentAnchor.anchorable_id
    const previousProduct = tempMap.get(currentProductId) || { anchor: null, id: currentAnchor.id, originalAnchors: [] }

    const isLast = currentAnchor.is_anchor_valid

    const data = {
      productId: currentProductId,
      anchor: isLast ? currentAnchor.anchor : previousProduct.anchor,
      id: isLast ? currentAnchor.id : previousProduct.id,
      updated_at: isLast ? currentAnchor.updated_at : previousProduct.updated_at,
      originalAnchors: currentAnchor.original_anchor ? [...previousProduct.originalAnchors, {
        anchorId: currentAnchor.id,
        seconds: currentAnchor.original_anchor
      }] : previousProduct.originalAnchors
    }

    tempMap.set(currentProductId, data)
  })

  const sortAsc = (productA, productB) => {
    if (productA.anchor === null) return 1
    if (productB.anchor === null) return -1
    return productA.anchor - productB.anchor
  }

  return Array.from(tempMap, ([key, value]) => ({ ...value })).sort(sortAsc)
}

export const actionTypes = {
  INITIALIZE_PRODUCT_ANCHOR: 'INITIALIZE_PRODUCT_ANCHOR',
  UPDATE_PRODUCT_ANCHOR: 'UPDATE_PRODUCT_ANCHOR',
  RESET_PRODUCT_ANCHOR: 'RESET_PRODUCT_ANCHOR',
  CLEAR_PRODUCT_ANCHOR: 'CLEAR_PRODUCT_ANCHOR',
  SET_FIRST_LOADING: 'SET_FIRST_LOADING',
  INIT_EDIT_PRODUCT_ANCHOR: 'INIT_EDIT_PRODUCT_ANCHOR'
}

const INIT_DATA = {
  editProductAnchor: {},
  anchors: [],
  isFirstLoading: true
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  computedAnchor (state) {
    return sortByLastEditAnchor(state.anchors)
  },
  productMapper (state, getters, rootState, rootGetters) {
    return rootGetters['Post/products'].reduce((accumulator, product) => (
      {
        ...accumulator,
        [product.id]: {
          ...product
        }
      }
    ), {})
  },
  anchorMapper (state, getters, rootState, rootGetters) {
    return getters.computedAnchor.reduce((accumulator, anchor) => {
      return anchor.anchor !== null ? {
        ...accumulator,
        [anchor.productId]: {
          ...anchor
        }
      } : accumulator
    }, {})
  },
  nullAnchorMapper (state, getters, rootState, rootGetters) {
    return getters.computedAnchor.reduce((accumulator, anchor) => {
      return anchor.anchor === null ? {
        ...accumulator,
        [anchor.productId]: {
          ...anchor
        }
      } : accumulator
    }, {})
  },
  productWithNoAnchor (state, getters, rootState, rootGetters) {
    // 無錨點資訊: 回傳 anchor 為 null 或沒有 anchor 資訊的
    // 因為要符合 "無錨點值（按商品ID排序)" 的規則
    return rootGetters['Post/products'].filter(product => !getters.anchorMapper[product.id] || getters.nullAnchorMapper[product.id])
  },
  productWithAnchor (state, getters, rootState, rootGetters) {
    // 有錨點資訊: 回傳 anchor 非 null 且有商品資訊的
    if (getters.computedAnchor.some(anchor => anchor.anchor !== null)) {
      return getters.computedAnchor.filter(anchor => {
        return getters.productMapper[anchor.productId] && !getters.nullAnchorMapper[anchor.productId]
      }).map(anchor => ({ ...getters.productMapper[anchor.productId] }))
    } else {
      return []
    }
  },
  anchorList (state, getters, rootState, rootGetters) {
    if (state.isFirstLoading) return []
    return getters.productWithAnchor.concat(getters.productWithNoAnchor).map(product => {
      const anchorData = getters.anchorMapper[product.id] || getters.nullAnchorMapper[product.id] || { anchor: null }
      return {
        productId: product.id,
        productImage: product.images[0] && product.images[0].url,
        productName: product.name,
        ...anchorData
      }
    })
  }
}

const mutations = {
  [actionTypes.INITIALIZE_PRODUCT_ANCHOR] (state, payload) {
    state.anchors = [...payload]
  },
  [actionTypes.UPDATE_PRODUCT_ANCHOR] (state, payload) {
    const item = state.editProductAnchor[payload.productId]
    state.editProductAnchor[payload.productId] = { ...item, ...payload, id: payload.anchorId || payload.id }
  },
  [actionTypes.INIT_EDIT_PRODUCT_ANCHOR] (state, payload) {
    state.editProductAnchor = { ...payload }
  },
  [actionTypes.RESET_PRODUCT_ANCHOR] (state) {
    Object.assign(state, INIT_DATA)
  },
  [actionTypes.CLEAR_PRODUCT_ANCHOR] (state) {
    state.editProductAnchor = Object.entries(state.editProductAnchor).reduce((accumulator, [productId, value]) => ({
      ...accumulator,
      [productId]: {
        ...value,
        anchor: null
      }
    }), {})
  },
  [actionTypes.SET_FIRST_LOADING] (state, payload) {
    state.isFirstLoading = payload
  }
}

const actions = {
  async initializeProductAnchor ({ commit, getters, dispatch, rootGetters }) {
    try {
      const productAnchor = new ProductAnchor()
      const anchorList = await productAnchor.getProductAnchorList(rootGetters['Post/postId'])

      commit(actionTypes.INITIALIZE_PRODUCT_ANCHOR, anchorList)
      commit(actionTypes.SET_FIRST_LOADING, false)
      dispatch('initEditProductAnchor')
    } catch (error) {
    }
  },
  initEditProductAnchor ({ commit, getters }) {
    const editMapper = getters.anchorList.reduce((accumulator, anchor) => ({
      ...accumulator,
      [anchor.productId]: {
        ...anchor
      }
    }), {})
    commit(actionTypes.INIT_EDIT_PRODUCT_ANCHOR, editMapper)
  },
  updateProductAnchor ({ commit }, payload) {
    commit(actionTypes.UPDATE_PRODUCT_ANCHOR, payload)
  },
  clearAllProductAnchors ({ commit }) {
    commit(actionTypes.CLEAR_PRODUCT_ANCHOR)
  },
  resetProductAnchor ({ commit }) {
    commit(actionTypes.RESET_PRODUCT_ANCHOR)
  },
  async updateProductAnchorList ({ state, commit, getters, dispatch, rootGetters }) {
    try {
      const productAnchor = new ProductAnchor()
      const payload = Object.entries(state.editProductAnchor).reduce((accumulator, [productId, value]) => {
        return accumulator.concat({
          id: value.id,
          anchorable_id: value.id ? undefined : value.productId,
          anchor: value.anchor
        })
      }, [])
      const updatedAnchors = await productAnchor.updateProductAnchors(rootGetters['Post/postId'], payload)

      // 更新成功後, 需要將新的錨點 id 更新回 state
      commit(actionTypes.INITIALIZE_PRODUCT_ANCHOR, updatedAnchors)
      dispatch('initEditProductAnchor')
      return true
    } catch (error) {
      return false
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: {
    ClipVideo
  }
}
