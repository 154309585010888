<script>
import PubNub from 'pubnub'
import { mapGetters, mapActions } from 'vuex'
import differenceInMinutes from 'date-fns/difference_in_minutes'
import differenceInHours from 'date-fns/difference_in_hours'
import differenceInDays from 'date-fns/difference_in_days'
import dateFormat from 'date-fns/format'
import queryString from 'query-string'

import ApiPrint from '@/api/print'
import ApiShipping from '@/api/shipping'
import ApiOrder from '@/api/order'
import ApiService from '@/api/additionalService'
import ApiCarrier from '@/api/carrierSetting'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'

import Pagination from '@/components/Pagination'
import ImportFail from './components/ImportFail'
import BuyPoints from '@/components/DialogBuyPoints'
import ShippingPoints from '../components/DialogShippingPoints'
import InfoContentDirect from './components/InfoContentDirect'
import InfoContentOther from './components/InfoContentOther'

export default {
  name: 'BatchShipping',
  components: {
    Pagination,
    ImportFail,
    BuyPoints,
    ShippingPoints,
    InfoContentDirect,
    InfoContentOther
  },
  data () {
    return {
      apiPrint: new ApiPrint(),
      apiShipping: new ApiShipping(),
      apiOrder: new ApiOrder(),
      apiService: new ApiService(),
      apiCarrier: new ApiCarrier(),
      undeliveredData: [],
      predeliveryData: [],
      undeliveredPaginator: null,
      predeliveryPaginator: null,
      activeName: 'undelivered',
      printStatus: 'all',
      printMode: 'all',
      predeliverySelection: [],
      undeliveredSelection: [],
      undeliveredTypes: [],
      predeliveryTypes: [],
      pendingCount: 0,
      success: 0,
      fail: 0,
      undeliveredFilter: {
        shipping_type: null,
        sort: 'payment_at',
        only_show_error_order: null,
        per_page: 15,
        keyword: ''
      },
      preFilter: {
        shipping_type: null,
        sort: 'payment_at',
        keyword: '',
        is_printed_order: null,
        per_page: 15
      },
      predeliveryCheck: {},
      undeliveredCheck: {},
      printSlugs: [],
      importErrors: null,
      buyPointsVisible: false,
      shippingPointsVisible: false,
      shippingPointsConfirm: null,
      isFamilyB2C: false,
      pointsLeft: 0,
      directShippingTypes: [
        SHIPPING_TYPES.HOME_DELIVERY,
        SHIPPING_TYPES.SELF_PICK_UP,
        SHIPPING_TYPES.CUSTOM_SELF_PICK_UP,
        SHIPPING_TYPES.OFFLINE_CUSTOM_TCAT_COD
      ],
      kerryMethod: 'drop_off'
    }
  },
  computed: {
    ...mapGetters('Me', ['me', 'storeName']),
    undeliveredNames () {
      return {
        seven: this.$t('Order.BatchShipping.undelivered.seven'),
        family: this.$t('Order.BatchShipping.undelivered.family'),
        hsinchu: this.$t('Order.BatchShipping.undelivered.hsinchu'),
        home_delivery: this.$t('Order.BatchShipping.undelivered.home_delivery'),
        custom: this.$t('shipping.type.custom'),
        kerry_th: this.$t('shipping.type.kerry_th'),
        self_pick_up: this.$t('shipping.type.self_pick_up'),
        custom_self_pick_up: this.$t('shipping.type.custom_self_pick_up'),
        offline_custom_tcat_cod: this.$t('shipping.type.offline_custom_tcat_cod')
      }
    },
    predeliveryNames () {
      return {
        seven: this.$t('Order.BatchShipping.predelivery.seven'),
        family: this.$t('Order.BatchShipping.predelivery.family'),
        family_business: this.$t('Order.BatchShipping.predelivery.family_business'),
        hsinchu: this.$t('Order.BatchShipping.predelivery.hsinchu'),
        home_delivery: this.$t('Order.BatchShipping.predelivery.home_delivery'),
        custom: this.$t('shipping.type.custom'),
        kerry_th: this.$t('shipping.type.kerry_th'),
        offline_custom_tcat_cod: this.$t('shipping.type.offline_custom_tcat_cod'),
        kerry_th_drop_off: this.$t('shipping.type.kerry_th') + ' ' + this.$t('shipping.kerry_th.drop_off'),
        kerry_th_pick_up: this.$t('shipping.type.kerry_th') + ' ' + this.$t('shipping.kerry_th.pick_up')
      }
    },
    undeliveredLabels () {
      if (this.undeliveredFilter.shipping_type === 'family' ||
        this.undeliveredFilter.shipping_type === 'seven') {
        return { address: this.$t('Order.BatchShipping.receiving_store') }
      } else {
        return { address: this.$t('Order.BatchShipping.receiving_address') }
      }
    },
    predeliveryLabels () {
      if (this.preFilter.shipping_type === 'family' ||
        this.preFilter.shipping_type === 'family_business' ||
        this.preFilter.shipping_type === 'seven') {
        return {
          address: this.$t('Order.BatchShipping.receiving_store'),
          barcode: this.$t('Order.BatchShipping.barcode_store')
        }
      } else {
        return {
          address: this.$t('Order.BatchShipping.receiving_address'),
          barcode: this.$t('Order.BatchShipping.barcode_address')
        }
      }
    },
    predeliveryTotal () {
      return Object.values(this.predeliveryCheck).filter(x => x).length
    },
    undeliveredTotal () {
      return Object.values(this.undeliveredCheck).filter(x => x).length
    },
    canPrintBarcodeOnly () {
      const cantTypes = ['kerry_th']
      return !cantTypes.includes(this.preFilter.shipping_type)
    }
  },
  async created () {
    if (['undelivered', 'predelivery'].includes(this.$route.query.activeName)) {
      this.activeName = this.$route.query.activeName
    }

    await this.fetchUndelivered()
    await this.fetchPredelivery()
    this.fetchFamilyCarrier()
    this.fetchServicePoints()
    this.undeliveredFilter.shipping_type = this.apiPrint.undeliveredPagination.meta.current_type
    this.preFilter.shipping_type = this.apiPrint.predeliveryPagination.meta.current_type

    this.pubnub = new PubNub({
      subscribeKey: process.env.VUE_APP_PUBNUB_SUBSCRIBE_KEY
    })
    const channel = `notification|shipping|${this.storeName}|${this.me.slug}`

    this.pubnub.subscribe({
      channels: [channel]
    })

    this.pubnub.addListener({
      message: m => this.dispatch(m.channel, m.message)
    })
  },
  beforeDestroy () {
    // PubNub Unsubscribe：
    // https://www.pubnub.com/docs/web-javascript/api-reference-publish-and-subscribe#unsubscribe_all

    try {
      this.pubnub.unsubscribeAll()
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('AdditionalService', ['updateOrigin']),
    dispatch (channel, payload) {
      this.pendingCount--
      if (payload.type.includes('success')) {
        this.success++
      } else if (payload.type.includes('fail')) {
        this.fail++
        if (payload.type.includes('shipping.printed')) {
          this.printSlugs = this.printSlugs.filter(x => x !== payload.data.order_slug)
        }
      }
      if (this.pendingCount !== 0) return

      if (payload.type.includes('shipping.added')) {
        this.undeliveredComplete()
      } else if (payload.type.includes('shipping.printed')) {
        this.printComplete()
      }
    },
    undeliveredSelectAll (selection) {
      const status = selection.length > 0
      for (const order of this.undeliveredData) {
        this.$set(this.undeliveredCheck, order.slug, status ? order.shipping_slug : null)
      }
    },
    predeliverySelectAll (selection) {
      const status = selection.length > 0
      for (const order of this.predeliveryData) {
        if (order.is_expired) continue
        this.$set(this.predeliveryCheck, order.slug, status ? order.shipping_slug : null)
      }
    },
    updateUndelivered (data) {
      this.undeliveredData = data.data
    },
    updatePredelivery (data) {
      this.predeliveryData = data.data
    },
    changeUndelivered () {
      this.checkClear()
      this.fetchUndelivered()
    },
    changePredelivery () {
      this.checkClear()
      this.fetchPredelivery()
    },
    changeTab (activeTab) {
      if (activeTab === 'undelivered') {
        return this.fetchUndelivered()
      } else if (activeTab === 'predelivery') {
        return this.fetchPredelivery()
      }
    },
    getOrderSlugs (checks) {
      return Object.entries(checks).reduce((acc, val) => {
        if (val[1]) acc.push(val[0])
        return acc
      }, [])
    },
    getShippingSlugs (checks) {
      return Object.entries(checks).reduce((acc, val) => {
        if (val[1]) acc.push(val[1])
        return acc
      }, [])
    },
    checkClear () {
      for (const key of Object.keys(this.undeliveredCheck)) {
        this.$set(this.undeliveredCheck, key, null)
      }
      for (const key of Object.keys(this.predeliveryCheck)) {
        this.$set(this.predeliveryCheck, key, null)
      }
    },
    async fetchUndelivered () {
      const params = { ...this.undeliveredFilter }
      if (!params.only_show_error_order || params.shipping_type === 'home_delivery') {
        delete params.only_show_error_order
      }
      if (!params.keyword) {
        delete params.keyword
      }
      params.shipping_sub_type = 'customer'
      if (params.shipping_type && params.shipping_type.includes('business')) {
        params.shipping_sub_type = 'business'
        params.shipping_type = params.shipping_type.split('_business')[0]
      }
      const data = await this.apiPrint.listUndelivered(params)
      this.undeliveredPaginator = this.apiPrint.undeliveredPaginator
      this.undeliveredTypes = data.meta.shipping_type_list
      this.undeliveredData = data.data
    },
    async fetchPredelivery () {
      const params = { ...this.preFilter }
      if (params.is_printed_order === null) {
        delete params.is_printed_order
      } else if (params.is_printed_order === 'only_show_expired_order') {
        delete params.is_printed_order
        params.only_show_expired_order = true
      }
      if (!params.keyword) {
        delete params.keyword
      }
      // For Family B2C
      params.shipping_sub_type = 'customer'
      if (params.shipping_type && params.shipping_type.includes('business')) {
        params.shipping_sub_type = 'business'
        params.shipping_type = params.shipping_type.split('_business')[0]
      }
      // For kerry th
      if (params.shipping_type && params.shipping_type.includes('kerry_th')) {
        params.shipping_sub_type = params.shipping_type.split('kerry_th_')[1]
        params.shipping_type = 'kerry_th'
      }
      const data = await this.apiPrint.listPredelivery(params)
      this.predeliveryPaginator = this.apiPrint.predeliveryPaginator
      const omitDeliveryTypes = [
        SHIPPING_TYPES.HOME_DELIVERY,
        SHIPPING_TYPES.SELF_PICK_UP,
        SHIPPING_TYPES.CUSTOM_SELF_PICK_UP,
        SHIPPING_TYPES.OFFLINE_CUSTOM_TCAT_COD
      ]
      this.predeliveryTypes = _.omit(data.meta.shipping_type_list, omitDeliveryTypes)
      this.predeliveryData = data.data
    },
    async handleReAddorder (shippingSlug) {
      if (this.preFilter.shipping_type === 'family' && this.isFamilyB2C) {
        // Family C2C to B2C 過渡期
        const message = `<h5>${this.$t('Order.Batchshipping.family_b2c_activated')}</h5>`
        const pointsMessage = `<div>${this.$t('Order.Batchshipping.readd_b2c')}</div>`

        this.$msgbox({
          title: '',
          center: true,
          showClose: true,
          customClass: 'batchShipping-msgbox',
          message: message + pointsMessage,
          dangerouslyUseHTMLString: true,
          showCancelButton: false,
          closeOnClickModal: false,
          confirmButtonText: this.$t('button.confirm')
        })
          .then(() => this.B2CReAdd(shippingSlug))
          .catch(() => { })
      } else if (this.preFilter.shipping_type.includes('_business')) {
        // B2C
        this.B2CReAdd(shippingSlug)
      } else {
        // Not B2C
        this.reAddOrder(shippingSlug)
      }
    },
    async B2CReAdd (shippingSlug) {
      this.setLoading(true)
      const { data } = await this.apiService.get('family_business')
      this.setLoading(false)
      const pointsLeft = data.summary.quantity

      if (pointsLeft > 0) {
        const message = `<h5>${this.$t('Order.Batchshipping.generate_b2c')}
          <span class="text-danger"> 1 </span>${this.$t('Order.Batchshipping.points')}？</h5>`
        const pointsMessage = `<div>${this.$t('Order.Batchshipping.remaining_points')}
          ${pointsLeft}${this.$t('Order.Batchshipping.points')}</div>`

        this.$msgbox({
          title: '',
          center: true,
          showClose: true,
          customClass: 'batchShipping-msgbox',
          message: message + pointsMessage,
          dangerouslyUseHTMLString: true,
          showCancelButton: true,
          closeOnClickModal: false,
          confirmButtonText: this.$t('button.confirm'),
          cancelButtonText: this.$t('button.cancel')
        })
          .then(async () => {
            await this.reAddOrder(shippingSlug)
          })
          .catch(action => {
          })
      } else {
        this.$msgbox({
          title: '',
          center: true,
          showClose: true,
          customClass: 'batchShipping-msgbox',
          message: `<h5>${this.$t('Order.Batchshipping.no_points')}</h5>`,
          dangerouslyUseHTMLString: true,
          showCancelButton: true,
          closeOnClickModal: false,
          confirmButtonText: this.$t('Order.Batchshipping.buy_points')
        })
          .then(async () => {
            this.handleBuyPoints()
          })
          .catch(action => {
          })
      }
    },
    async reAddOrder (shippingSlug) {
      this.setLoading(true)
      try {
        const { data: response } = await this.apiShipping.reAddOrder(shippingSlug)
        await this.fetchPredelivery()
        if (!response.success) {
          this.$message.error(response.message)
          return
        }
        if (!this.preFilter.shipping_type.includes('_business')) {
          this.$message.success(this.$t('Order.BatchShipping.message_update_code'))
        } else {
          this.$msgbox({
            title: '',
            center: true,
            showClose: true,
            customClass: 'batchShipping-msgbox',
            message: `
              <h5>${this.$t('Order.Batchshipping.generate_success')}</h5>
              <h5>${this.$t('Order.Batchshipping.used_points')}
              <span class="text-danger"> 1 </span>
              ${this.$t('Order.Batchshipping.points')}</h5>
              <div>${this.$t('Order.Batchshipping.family_b2c_print')}</div>`,
            dangerouslyUseHTMLString: true,
            showCancelButton: false,
            closeOnClickModal: false,
            confirmButtonText: this.$t('button.complete')
          })
        }
      } catch (e) {
        await this.fetchPredelivery()
        const title = `<h5>${this.$t('Order.Batchshipping.generate_fail')}</h5>`
        const message = `<div class="text-gray-800">${this.$t('message.problem_contact')}</div>`
        let errorMessage = ''
        if (e.data.error && e.data.error.code) {
          errorMessage = `${this.$t('shipping.error_code')} ${e.data.error.code}`
        } else {
          errorMessage = `${e.data.message}`
        }
        const error = `<div class="text-gray-700 text-xs">${errorMessage}</div>`
        this.$msgbox({
          title: '',
          center: true,
          showClose: true,
          customClass: 'batchShipping-msgbox',
          message: title + message + error,
          dangerouslyUseHTMLString: true,
          showCancelButton: false,
          closeOnClickModal: false,
          confirmButtonText: this.$t('button.confirm')
        }).catch(() => { })
      }
      this.setLoading(false)
    },
    async handleUndelivered () {
      if (!(this.undeliveredFilter.shipping_type === 'family' && this.isFamilyB2C)) {
        this.submitUndelivered()
        return
      }

      // Family B2C
      const { data } = await this.apiService.get('family_business')
      if (!data) {
        return
      }
      this.pointsLeft = data.summary.quantity
      this.shippingPointsVisible = true
      this.shippingPointsConfirm = () => {
        this.shippingPointsVisible = false
        this.submitUndelivered()
      }
    },
    async submitUndelivered () {
      const shippingSlugs = this.getShippingSlugs(this.undeliveredCheck)
      this.setLoading(true)
      const printData = { shipping_slugs: shippingSlugs }
      if (this.undeliveredFilter.shipping_type === 'kerry_th') {
        printData.method = this.kerryMethod
      }
      const data = await this.apiShipping.batchAddOrder(this.storeName, printData)
      if (!data) {
        this.setLoading(false)
        return
      }
      this.pendingCount = data.pending_count
      this.success = 0
      this.fail = 0
      if (this.pendingCount === 0) {
        this.undeliveredComplete()
      }
    },
    async undeliveredComplete () {
      await this.fetchUndelivered()
      await this.fetchPredelivery()
      await this.fetchServicePoints()
      this.setLoading(false)
      this.checkClear()
      this.undeliveredCompleteMessage()
        .then(() => {
          this.activeName = 'predelivery'
          let preShippingType = this.undeliveredFilter.shipping_type
          const isFamilyB2C = this.undeliveredFilter.shipping_type === 'family' && this.isFamilyB2C
          if (isFamilyB2C) {
            preShippingType = preShippingType + '_business'
          } else if (this.undeliveredFilter.shipping_type === 'kerry_th') {
            preShippingType = preShippingType + '_' + this.kerryMethod
          }
          this.preFilter.shipping_type = preShippingType
        })
        .catch(action => {
        })
    },
    undeliveredCompleteMessage () {
      let message = ''
      const isFamilyB2C = this.undeliveredFilter.shipping_type === 'family' && this.isFamilyB2C
      if (this.success > 0) message += this.$t('Order.BatchShipping.message_create_code', { count: this.success })
      if (this.success > 0 && this.fail > 0) message += '， '
      if (this.fail > 0) message += this.$t('Order.BatchShipping.message_create_failed', { count: this.fail })
      if (isFamilyB2C) {
        message += `<br>${this.$t('Order.Batchshipping.used_points')}
          <span class='text-primary mx-1'>${this.success}</span>${this.$t('Order.Batchshipping.points')}`
      }
      return this.$msgbox({
        title: '',
        center: true,
        showClose: true,
        customClass: 'batchShipping-msgbox',
        message: `
        <h5>${message}</h5>
        <div>${this.$t('Order.BatchShipping.confirm.undelivered.description1')}</div>
        <div>${this.$t('Order.BatchShipping.confirm.undelivered.description2')}</div>`,
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: this.$t('Order.BatchShipping.confirm.undelivered.btn_print'),
        cancelButtonText: this.$t('Order.BatchShipping.confirm.undelivered.btn_continue')
      })
    },
    async submitPrint () {
      const orderSlugs = this.getOrderSlugs(this.predeliveryCheck)
      if (this.printMode === 'hsinchu') {
        const jsonSlugs = JSON.stringify(orderSlugs)
        const path = `/${this.storeName}/hsinchu-print?slugs=${jsonSlugs}`
        window.open(path, '_blank')
        return
      } else if (this.printMode === 'shipping' && this.preFilter.shipping_type === 'seven') {
        const path = queryString.stringifyUrl({
          url: `/web/stores/${this.storeName}/print/seven`,
          query: {
            order_slugs: orderSlugs
          }
        }, {
          arrayFormat: 'bracket'
        })
        window.open(path, '_blank')
        return
      }
      this.setLoading(true)
      const printData = { order_slugs: orderSlugs, print_mode: this.printMode }
      const data = await this.apiPrint.batchOrder(printData)
      if (!data) {
        this.setLoading(false)
        return
      }
      this.pendingCount = data.pending_count
      this.printSlugs = data.data.map(x => x.slug)
      this.success = 0
      this.fail = 0
      if (this.pendingCount === 0) {
        this.printComplete()
      }
    },
    async printComplete () {
      await this.fetchUndelivered()
      await this.fetchPredelivery()
      this.setLoading(false)
      this.checkClear()
      if (this.printSlugs.length === 0) {
        this.$message.error(this.$t('Order.BatchShipping.message_print_failed'))
        return
      }
      const orders = JSON.stringify(this.printSlugs)
      localStorage.setItem('ordersToPrint', orders)

      const path = `/${this.storeName}/order/print?mode=${this.printMode}&sort=${this.preFilter.sort}`
      window.open(path, '_blank')
    },
    async exportOrder () {
      const orderSlugs = this.getOrderSlugs(this.undeliveredCheck)
      const res = await this.apiOrder.downloadUndeliveredOrder(orderSlugs)
      if (res) {
        this.$message({
          showClose: true,
          duration: 1000,
          message: this.$t('Order.BatchShipping.message_export_success'),
          type: 'success'
        })
      }
    },
    async importShipping (file) {
      const res = await this.apiShipping.importShipping(this.storeName, file.raw)
      if (res) {
        this.importComplete(res)
      }
    },
    importComplete (response) {
      if (!response.success) {
        this.importErrors = response.data
        return
      }

      const number = response.data.order_slugs.length
      this.$msgbox({
        title: '',
        center: true,
        showClose: true,
        customClass: 'batchShipping-msgbox',
        message: `
          <h5>${this.$t('Order.BatchShipping.confirm.import.description1', { number: number })}</h5>
          ${this.$t('Order.BatchShipping.confirm.import.description2')}`,
        dangerouslyUseHTMLString: true,
        closeOnClickModal: false,
        confirmButtonText: this.$t('Order.BatchShipping.confirm.import.btn_print')
      })
        .then(() => {
          this.directPrint(null, response.data.order_slugs)
        })
        .catch(action => {
        })
    },
    directPrint (event, orderSlugs) {
      if (!orderSlugs) {
        orderSlugs = this.getOrderSlugs(this.undeliveredCheck)
      }

      const printMode = 'order'
      const orders = JSON.stringify(orderSlugs)
      localStorage.setItem('ordersToPrint', orders)

      const path = `/${this.storeName}/order/print?mode=${printMode}&sort=${this.preFilter.sort}`
      window.open(path, '_blank')
    },
    directShip () {
      const number = this.undeliveredTotal
      let description = this.$t('Order.BatchShipping.confirm.direct.description2')
      const selfPickupTypes = [SHIPPING_TYPES.SELF_PICK_UP, SHIPPING_TYPES.CUSTOM_SELF_PICK_UP]
      if (selfPickupTypes.includes(this.undeliveredFilter.shipping_type)) {
        description = this.$t('Order.BatchShipping.confirm.direct.description3')
      }
      this.$msgbox({
        title: '',
        center: true,
        showClose: true,
        customClass: 'batchShipping-msgbox',
        message: `
        <h5>${this.$t('Order.BatchShipping.confirm.direct.description1', { member: number })}</h5>
        <div>${description}</div>`,
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: this.$t('Order.BatchShipping.confirm.import.btn_submit'),
        cancelButtonText: this.$t('Order.BatchShipping.confirm.import.btn_cancel')
      })
        .then(async () => {
          const shippingSlugs = this.getShippingSlugs(this.undeliveredCheck)
          const postData = { shipping_slugs: shippingSlugs }
          const res = await this.apiShipping.batchShipOrder(this.storeName, postData)
          if (res) {
            this.$message.success(this.$t('Order.BatchShipping.message_shipment_success'))
            this.fetchUndelivered()
            this.checkClear()
          } else {
            this.$message.error(this.$t('Order.BatchShipping.message_shipment_failed'))
          }
        })
        .catch(action => {
        })
    },
    handleBuyPoints () {
      this.updateOrigin({ name: 'BatchShipping', query: { activeName: this.activeName } })
      this.buyPointsVisible = true
    },
    handleB2CSubmit () {
      this.shippingPointsVisible = false
      this.submitUndelivered()
    },
    async fetchFamilyCarrier () {
      const data = await this.apiCarrier.get('family-business')
      this.isFamilyB2C = data && data.is_verified
    },
    async fetchServicePoints () {
      const { data } = await this.apiService.get('family_business')
      this.pointsLeft = data.summary.quantity
    },
    barcodeInvalid (order) {
      return order.is_expired || order.shipping_status === 'canceled'
    },
    relativeTimePrinted (date) {
      const now = new Date()

      if (differenceInMinutes(now, date) < 60) {
        return this.$t('Order.Batchshipping.minutes_ago', { minutes: differenceInMinutes(now, date) })
      } else if (differenceInHours(now, date) < 24) {
        return this.$t('Order.Batchshipping.hours_ago', { hours: differenceInHours(now, date) })
      } else if (differenceInDays(now, date) < 3) {
        return this.$t('Order.Batchshipping.days_ago', { days: differenceInDays(now, date) })
      } else {
        return dateFormat(date, 'M/D')
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
<style lang="scss">
.batchShipping-msgbox {
  padding-bottom: 30px;
  h5 {
    margin-bottom: 20px;
    color: #4a4a4a;
    font-size: 18px;
    font-weight: 500;
  }
  .el-message-box__content {
    padding-top: 0;
    padding-bottom: 15px;
  }
  .el-message-box__header {
    padding-bottom: 0;
  }
  .el-button--small {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
  }
}

</style>
