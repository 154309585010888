<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { FACEBOOK } from '@/constants/provider'
import * as CHANNEL_TYPE from '@/constants/channelType'
import { SESSION_KEYS } from '@/constants/common'
import { BASE_IMAGE_PATH } from '@/constants/path'
import DialogRegistered from '@/components/DialogRegistered'

import ChannelBind from './components/ChannelBind'
import BindFb from './components/BindFb'
import ChannelEditorFor17 from './components/ChannelEditorFor17'
import FBbuyBindSuccessDialog from './components/FBbuyBindSuccessDialog'

export default {
  name: 'Channel',
  components: {
    DialogRegistered,
    ChannelBind,
    BindFb,
    ChannelEditorFor17,
    FBbuyBindSuccessDialog
  },
  data () {
    return {
      CHANNEL_TYPE,
      ChannelBindVisible: false,
      bindFbVisible: false,
      isShowChannelEditorFor17: false,
    }
  },
  computed: {
    ...mapGetters('Me', [
      'me',
      'stores',
      'isNewRegister',
      'storeName',
      'isEnterprise',
      'currentStore',
      'canBuyPlan',
      'providers',
      'isFBBuyStore'
    ]),
    ...mapState('Me/StoreChannels', ['infoFor17Live']),
    ...mapGetters('Me/StoreChannels', [
      'channelsWithoutHandsUP',
      'has17LIVEChannel',
      'hasFBFanChannel',
    ]),
    normalizeChannels () {
      return this.channelsWithoutHandsUP.map((item) => {
        const types = {
          [CHANNEL_TYPE.FB_PAGE]: this.$t('channel.type.fb_page'),
          [CHANNEL_TYPE.M17_LIVE_V_2]: this.$t('channel.type.17live_v2')
        }
        const typeName = types[item.type] || this.$t('channel.type.default')
        let imageUrl = item.image_url
        if (item.type === CHANNEL_TYPE.M17_LIVE_V_2) {
          imageUrl = `${BASE_IMAGE_PATH}/icons/20211004_17LIVE_icon.png`
        }
        return Object.assign(item, { type_name: typeName, image_url: imageUrl })
      })
    },
    isShowAddChannel () {
      const plan = this.currentStore.plan
      return plan && this.normalizeChannels.length < plan.channel_number
    },
    isShowAddFanChannel () {
      // 如果不是FBbuy賣家，則都顯示，否則要看已經是否有綁定一個粉專
      return (
        !this.isFBBuyStore || !this.hasFBFanChannel
      )
    },
    buttonForFBFanTooltip () {
      return !this.isFBBuyStore ? this.$t('Setting.Channel.create_fb_fan_tip') : this.$t('Setting.Channel.create_one_fan_tip')
    },
    is17LiveButtonDisabled () {
      return this.isFBBuyStore && !this.hasFBFanChannel
    },
    buttonFor17LiveTooltip () {
      return this.is17LiveButtonDisabled
        ? this.$t('Setting.Channel.needBindFBTooltip')
        : this.$t('Setting.Channel.create_17LIVE_tip')
    }
  },
  async mounted () {
    if (window.innerWidth < 768) {
      this.$message({
        message: this.$t('message.small_screen'),
        type: 'warning',
        customClass: 'channel-message'
      })
    }

    // 判斷是否第一次註冊進來
    if (this.isNewRegister) {
      this.ChannelBindVisible = true
      // this.$refs.DialogRegistered.toggle()
      this.updateIsNewRegister(false)
    }

    if (sessionStorage.getItem(SESSION_KEYS.isPopupOpen) === 'true') {
      this.ChannelBindVisible = true
    }

    this.initialize()
  },
  methods: {
    ...mapActions('Me', ['updateIsNewRegister']),
    ...mapActions('Me/StoreChannels', [
      'fetchChannels',
      'get17LiveChannelInfo'
    ]),
    async initialize () {
      await this.fetchChannels(this.currentStore.slug)
      if (this.has17LIVEChannel) {
        await this.get17LiveChannelInfo()
      }
    },
    toSettingPayment () {
      // 前往 商店設定
      this.$emit('updateActiveMenu', {
        name: 'menu_payment',
        component: 'SettingPayment'
      })
    },
    toSettingSeller () {
      this.$router.push(
        { query: { seller: true } },
        () => {
          this.emitMenuSettingSeller()
        },
        () => {
          this.emitMenuSettingSeller()
        }
      )
    },
    emitMenuSettingSeller () {
      this.$emit('updateActiveMenu', {
        name: 'menu_seller',
        component: 'SettingSeller'
      })
    },
    showBindChannel () {
      if (!Object.keys(this.providers).includes(FACEBOOK)) {
        this.bindFbVisible = true
      } else {
        this.ChannelBindVisible = true
      }
    },
    show17LiveEditor () {
      if(this.is17LiveButtonDisabled) {
        return
      }
      this.isShowChannelEditorFor17 = true
    },
    submit17LiveChannelSuccess () {
      this.isShowChannelEditorFor17 = false

      // 綁定 17渠道成功後 如是FBbuy 店家需要跳出dialog訊息
      if(this.isFBBuyStore) {
        this.onFbbuyBindSuccess({ type: CHANNEL_TYPE.M17_LIVE_V_2 })
      }
    },
    close17LiveEditor () {
      this.isShowChannelEditorFor17 = false
    },
    async onFbbuyBindSuccess (payload) {
      await this.initialize()

      this.$refs.fBbuyBindSuccessDialog.openHandler(payload)
    }
  }
}
</script>

<template src="./template.pug" />
<style lang="scss" src="./style.scss" scoped></style>
