<script>
import compareAsc from 'date-fns/compare_asc'
import { mapGetters, mapActions } from 'vuex'

import * as LIVE_STATUS from '@/constants/liveStatus'
import { HANDSUP, FB_PAGE } from '@/constants/channelType'

import DialogMarquee from '@/views/Post/components/DialogMarquee'

import ActionButton from './components/ActionButton'

// Icon
import MARQUEE from '@/assets/post/action/btn-marquee.svg'
import MARQUEE_ACTIVE from '@/assets/post/action/btn-marquee-active.svg'
import MARQUEE_DISABLE from '@/assets/post/action/btn-marquee-disable.svg'
import TICKET from '@/assets/post/action/btn-ticket.svg'
import TICKET_ACTIVE from '@/assets/post/action/btn-ticket-active.svg'
import TICKET_DISABLE from '@/assets/post/action/btn-ticket-disable.svg'
import CART_DATE from '@/assets/post/action/btn-cart-date.svg'
import CART_DATE_ACTIVE from '@/assets/post/action/btn-cart-date-active.svg'
import CART_DATE_DISABLE from '@/assets/post/action/btn-cart-date-disable.svg'
import COVER from '@/assets/post/action/btn-cover.svg'
import COVER_ACTIVE from '@/assets/post/action/btn-cover-active.svg'
import COVER_DISABLE from '@/assets/post/action/btn-cover-disable.svg'
import ANNOUNCE from '@/assets/post/action/btn-announce.svg'
import ANNOUNCE_ACTIVE from '@/assets/post/action/btn-announce-active.svg'
import ANNOUNCE_DISABLE from '@/assets/post/action/btn-announce-disable.svg'
import KEY from '@/assets/post/action/btn-key.svg'
import KEY_ACTIVE from '@/assets/post/action/btn-key-active.svg'
import KEY_DISABLE from '@/assets/post/action/btn-key-disable.svg'
import REPLAYEDIT_ACTIVE from '@/assets/post/action/btn-replayedit-active.svg'
import REPLAYEDIT_ACTIVE_DISABLE from '@/assets/post/action/btn-replayedit-disable.svg'

export default {
  name: 'ActionItem',

  components: {
    ActionButton,
    DialogMarquee
  },

  data () {
    return {
      ...LIVE_STATUS,
      actions: {
        marquee: {
          text: this.$t('marquee'),
          icon: MARQUEE,
          iconHover: MARQUEE_ACTIVE,
          iconDisable: MARQUEE_DISABLE
        },
        ticket: {
          text: this.$t('Post.Show.Published.private_setting'),
          icon: TICKET,
          iconHover: TICKET_ACTIVE,
          iconDisable: TICKET_DISABLE
        },
        time: {
          text: this.$t('Post.Show.Published.check_time'),
          icon: CART_DATE,
          iconHover: CART_DATE_ACTIVE,
          iconDisable: CART_DATE_DISABLE
        },
        cover: {
          text: this.$t('Post.Show.Published.set_cover'),
          icon: COVER,
          iconHover: COVER_ACTIVE,
          iconDisable: COVER_DISABLE
        },
        announce: {
          text: this.$t('Post.Show.DialogSetLiveTime.title'),
          icon: ANNOUNCE,
          iconHover: ANNOUNCE_ACTIVE,
          iconDisable: ANNOUNCE_DISABLE
        },
        rtmp: {
          text: this.$t('Post.Show.Published.rtmp'),
          icon: KEY,
          iconHover: KEY_ACTIVE,
          iconDisable: KEY_DISABLE
        },
        replayEdit: {
          text: this.$t('Post.Show.Published.replayEdit'),
          icon: REPLAYEDIT_ACTIVE,
          iconHover: REPLAYEDIT_ACTIVE,
          iconDisable: REPLAYEDIT_ACTIVE_DISABLE
        }
      },

      showMore: false,
      dialogMarqueeVisible: false
    }
  },
  computed: {
    ...mapGetters('Me', ['enabledDialogSetPostTime', 'enabledTicket', 'storeName']),
    ...mapGetters('Post', ['celebrityToken', 'liveStatus', 'replayUrl', 'publications']),
    ...mapGetters('Post', ['post', 'postId']),

    hasHandsupChannel () {
      return this.post.channels.some(channel => channel.type === HANDSUP)
    },

    disabledMarquee () {
      return !!this.celebrityToken || this.liveStatus !== LIVE_STATUS.LIVE || !this.hasHandsupChannel
    },

    canEditTicket () {
      return this.enabledTicket &&
        this.liveStatus === LIVE_STATUS.UNPUBLISHED &&
        this.post.is_private
    },

    disabledReplayEdit () {
      return this.liveStatus !== LIVE_STATUS.LIVE_STOPPED || this.isOnlyFb()
    }
  },

  mounted () {
    // 若首次進直播間，先展開
    if (!localStorage.getItem('isOpenedAction')) {
      this.showMore = true

      localStorage.setItem('isOpenedAction', true)
    }
  },

  methods: {
    ...mapActions('Post', [
      'toggleDialogSetCover',
      'toggleDialogSetRtmp',
      'toggleDialogSetLiveTime',
      'toggleDialogSetPostTime',
      'toggleDialogPrivate'
    ]),

    toggleMarquee (flag = false) {
      this.dialogMarqueeVisible = flag
    },

    toggleReplyEdit () {
      if (compareAsc(this.post.replay_expired_at, new Date()) !== -1 && this.replayUrl) {
        this.goReplayEditView()
      } else {
        this.$alert(this.$t('Post.Action.Video_Conversion_Notice'), '', {
          confirmButtonText: this.$t('button.confirm')
        })
      }
    },

    goReplayEditView () {
      if (this.postId) {
        this.$emit('goReplayEditSignal')
        this.$router.push(`/${this.storeName}/posts/${this.postId}/replay-edit`)
      }
    },

    isOnlyFb () {
      return this.publications.every(channel => channel.channel.type === FB_PAGE)
    }
  }
}
</script>

<template lang="pug" src="./ActionItem.pug"></template>
