<script>
import { mapGetters } from 'vuex'
import VueTypes from 'vue-types'
import twzipcode from 'twzipcode-data'

import ApiJpZip from '@/api/jpZip'
import { TW, JP } from '@/constants/regions'
import { validateEmptyString } from '@/utils/validateEmptyString'

export default {
  name: 'AddressSelect',
  props: {
    value: VueTypes.object.def({}),
    disabled: VueTypes.bool.def(false),
    colSpan: VueTypes.object.def({
      city: 4,
      region: 6,
      address: 14
    }),
    wrapCols: VueTypes.bool.def(false)
  },
  data () {
    const jpZipValidator = (rule, value, cb) => {
      if (!value || this.value.level_one === '入力間違い、再度入力してください。') {
        cb(new Error())
      } else {
        cb()
      }
    }
    return {
      TW,
      JP,
      errors: {
        level_one: [
          {
            required: true,
            message: this.$t('Components.AddressSelect.choose_province'),
            trigger: 'blur'
          }
        ],
        level_two: [
          {
            required: true,
            message: this.$t('Components.AddressSelect.choose_district'),
            trigger: 'blur'
          }
        ],
        level_four: [
          {
            required: true,
            message: this.$t('Components.AddressSelect.input_street'),
            validator: validateEmptyString,
            trigger: 'blur'
          }
        ],
        postcode: [
          {
            required: true,
            message: this.$t('Components.AddressSelect.zipcode'),
            trigger: 'blur',
            validator: jpZipValidator
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['region']),
    taiwanOption () {
      return {
        counties: twzipcode().counties,
        cities: twzipcode().zipcodes
          .filter(obj => obj.county === this.value.level_one)
          .map(obj => {
            return {
              ...obj,
              text: `${obj.city} ${obj.id}`
            }
          })
      }
    }
  },
  methods: {
    validateForm () {
      return this.$refs.addressForm.validate()
    },
    updateValue (key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    updateTwLevelOne (value) {
      this.$emit('input', {
        ...this.value,
        level_one: value,
        postcode: '',
        level_two: ''
      })
    },
    updateTwLevelTwo (value) {
      this.$emit('input', {
        ...this.value,
        postcode: value.id,
        level_two: value.city
      })
    },
    updateJpPostcode (postcode) {
      this.updateValue('postcode', postcode)
      this.getJpAddress(postcode)
    },
    getJpAddress: _.debounce(async function (zipcode) {
      const apiJpZip = new ApiJpZip()
      const zipData = await apiJpZip.get(zipcode)
      const address = {
        level_one: zipData.level_one || '入力間違い、再度入力してください。',
        level_one_code: zipData.level_one_code || '',
        level_two: (zipData.level_two || '') + (zipData.level_three || '')
      }
      this.$emit('input', { ...this.value, ...address })
    }, 500)
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
