<script>
import VueTypes from 'vue-types'

import { mapGetters } from 'vuex'
import { MARKET, SELLER, SUBSCRIPTION } from '@/constants/cartAddedFrom'
import { HANDSUP, FB_PAGE, M17_LIVE } from '@/constants/channelType'
import * as PAYMENT_FLOW from '@/constants/paymentFlow'
import * as ORDER_STATUS from '@/constants/orderStatus'

import { normalizeImage } from '@/utils/normalizeImage'
import getSubscriptionImage from '@/utils/getSubscriptionImage'

import handsupIcon from '@/assets/icon-handsup.png'
import facebookIcon from '@/assets/icon-fb-lg.png'
import m17Icon from '@/assets/icon-17-lg.png'

const imageMappings = {
  [HANDSUP]: handsupIcon,
  [FB_PAGE]: facebookIcon,
  [M17_LIVE]: m17Icon
}

export default {
  name: 'PurchaseDetail',

  props: {
    updateOrderItems: VueTypes.func.isRequired
  },

  data () {
    return {
      SELLER,
      MARKET,
      SUBSCRIPTION,
      handsupIcon
    }
  },

  computed: {
    ...mapGetters('Me', ['currencySymbol', 'paymentFlow']),
    ...mapGetters('OrderDetail', ['isCart', 'isSubscriptionType', 'orderData', 'items']),

    showBtnEdit () {
      // 購物車階段
      if (this.isCart) return true

      // 此訂單 Subscription 類型，不提供修改
      if (this.isSubscriptionType) return false

      if (this.paymentFlow === PAYMENT_FLOW.DIRECT) {
        const orderStatus = _.get(this.orderData, 'status', '')
        const canEditStatus = [ORDER_STATUS.INIT, ORDER_STATUS.UNPAID, ORDER_STATUS.UNDELIVERED]

        return canEditStatus.includes(orderStatus)
      } else {
        return false
      }
    }
  },

  methods: {
    normalizeImage,
    getSubscriptionImage,
    getChannelImage (item) {
      const channelType = item.channel.type

      return imageMappings[channelType]
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
