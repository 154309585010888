import BaseApi from './baseApi'
import storeId from './_storeId'
import axios from '@/plugins/http.js'

export default class Member extends BaseApi {
  uri = `/api/v1/stores/${storeId()}/members`
  uriList = `/api/v1/stores/${storeId()}/members`

  putMemo = async (id, memo) => {
    const api = `/api/v1/stores/${storeId()}/members/${id}/memo`
    const params = { memo }

    try {
      await axios.put(api, params)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  // 取得黑名單列表
  getBlacklist = storeId => {
    const api = `/api/v1/stores/${storeId}/members/blacklist`
    return axios.get(api)
  }

  // 更新黑名單
  updateMember = async (id, params) => {
    const api = `/api/v1/stores/${storeId()}/members/${id}`

    try {
      await axios.put(api, params)

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }
}
