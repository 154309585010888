import axios from '@/plugins/http'

export const createTicket = ({ storeId, form }) => (
  axios.post(`/api/v1/stores/${storeId}/products`, form)
)

export const updateTicket = ({ storeId, form, id }) => (
  axios.put(`/api/v1/stores/${storeId}/products/${id}`, form)
)

export const fetchTickets = ({ storeId, params }) => (
  axios.get(`/api/v1/stores/${storeId}/tickets`, { params })
)

export const deleteTicket = ({ storeId, productId }) => {
  const api = `/api/v1/stores/${storeId}/products/${productId}`
  return axios.delete(api)
}
