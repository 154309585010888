import BaseApi from './baseApi'
import axios from '@/plugins/http.js'
import storeId from './_storeId'
// TODO: 把 bank 相關 api 搬進來
export default class Bank extends BaseApi {
  uri = `/api/v1/stores/${storeId()}/`

  // 取得Store銀行帳號資訊
  getBankingAccount = async () => {
    try {
      const api = `/api/v1/stores/${storeId()}/banking/account`
      const { data } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  updateBankingAccount = async postData => {
    try {
      const api = `/api/v1/stores/${storeId()}/banking/account`
      await axios.post(api, postData)

      return true
    } catch (e) {
      console.error(e)
      return null
    }
  }
}
