<script>
import VueTypes from 'vue-types'
import { mapGetters, mapActions } from 'vuex'

import * as COUPON_STATUS from '@/constants/couponStatus'
import * as loadingState from '@/constants/loadingState'

import AppIntroduce from '@/components/AppIntroduce'

import isKeyInNumber from '@/utils/isKeyInNumber'

import DialogInterruptVoucher from '../DialogInterruptVoucher'

const isNumber = obj => {
  const number = Number(obj)
  return typeof number === 'number' && !isNaN(number)
}

export default {
  name: 'DialogVoucherDetail',

  components: { AppIntroduce, DialogInterruptVoucher },

  props: {
    editVoucher: VueTypes.oneOfType([Object, null])
  },

  data () {
    const validateAcquireStartTime = (rule, value, callback) => {
      if (this.validateAcquireError) {
        callback(new Error(this.$t('Components.DialogVoucherDetail.activity_time_comment')))
        return
      }

      const acquireEnd = this.ruleForm.acquire_end_at
      const applyStart = this.ruleForm.apply_start_at

      if (acquireEnd) {
        this.$refs.ruleForm.validateField('acquire_end_at')
      }

      if (applyStart) {
        this.$refs.ruleForm.validateField('apply_start_at')
      }

      callback()
    }

    const validateAcquireEndTime = (rule, value, callback) => {
      if (this.validateAcquireError) {
        callback(new Error(this.validateAcquireError))
        return
      }

      const acquireStart = this.ruleForm.acquire_start_at
      const acquireEnd = this.ruleForm.acquire_end_at
      const applyEnd = this.ruleForm.apply_end_at

      if (acquireStart) {
        if (new Date(acquireStart) > new Date(acquireEnd)) {
          callback(new Error(this.$t('Components.DialogVoucherDetail.validate_start_after_end')))
          return
        }
      }

      if (applyEnd) {
        this.$refs.ruleForm.validateField('apply_end_at')
      }

      callback()
    }

    const validateApplyStartTime = (rule, value, callback) => {
      const acquireStart = this.ruleForm.acquire_start_at
      const applyStart = this.ruleForm.apply_start_at
      const applyEnd = this.ruleForm.apply_end_at

      if (acquireStart) {
        if (new Date(acquireStart) > new Date(applyStart)) {
          callback(new Error(this.$t('Components.DialogVoucherDetail.validate_apply_start')))
          return
        }
      }

      if (applyEnd) {
        this.$refs.ruleForm.validateField('apply_end_at')
      }

      callback()
    }

    const validateApplyEndTime = (rule, value, callback) => {
      const acquireEnd = this.ruleForm.acquire_end_at
      const applyStart = this.ruleForm.apply_start_at
      const applyEnd = this.ruleForm.apply_end_at

      if (acquireEnd) {
        if (new Date(acquireEnd) > new Date(applyEnd)) {
          callback(new Error(this.$t('Components.DialogVoucherDetail.validate_apply_end')))
          return
        }
      }

      if (applyStart) {
        if (new Date(applyStart) > new Date(applyEnd)) {
          callback(new Error(this.$t('Components.DialogVoucherDetail.validate_start_after_end')))
          return
        }
      }

      callback()
    }

    const validateAmount = (rule, value, callback) => {
      if (!isNumber(value)) {
        callback(new Error(this.$t('Coupon.DialogCouponDetail.coupon_amount_error')))
      } else if (Number(value) < 1) {
        callback(new Error(this.$t('Components.DialogVoucherDetail.validate_lowest_limit', { currency: this.currencySymbol })))
      } else {
        callback()
      }
    }
    const validateApplyAmountThreshold = (rule, value, callback) => {
      if (!this.ruleForm.isSetMinimum) {
        callback()
        return
      }

      if (!isNumber(value)) {
        callback(new Error(this.$t('Coupon.DialogCouponDetail.coupon_minimum_error')))
      } else if (Number(value) < 1) {
        callback(new Error(this.$t('Components.DialogVoucherDetail.validate_lowest_limit', { currency: this.currencySymbol })))
      } else {
        callback()
      }
    }

    const validateAcquireQuantity = (rule, value, callback) => {
      if (!this.ruleForm.isSetQuantity) {
        callback()
        return
      }

      if (!value) {
        callback(new Error(this.$t('Coupon.DialogCouponDetail.coupon_quantity_required')))
      } else {
        callback()
      }
    }

    return {
      pickerOptions: {
        disabledDate: (time) => {
          const now = new Date()
          const yesterday = now.setDate(now.getDate() - 1)

          return time.getTime() < yesterday
        }
      },
      ruleForm: {
        name: '',
        event_type: 'share_live',
        acquire_start_at: '',
        acquire_end_at: '',
        apply_start_at: '',
        apply_end_at: '',
        amount: null,
        isSetMinimum: false,
        apply_amount_threshold: null,
        isSetQuantity: false,
        acquire_quantity: null
      },
      rules: {
        name: [
          { required: true, message: this.$t('Coupon.DialogCouponDetail.coupon_name_error'), trigger: 'blur' }
        ],
        event_type: [
          { required: true, message: this.$t('Coupon.DialogCouponDetail.coupon_name_error'), trigger: 'blur' }
        ],
        acquire_start_at: [
          { required: true, message: this.$t('Coupon.DialogCouponDetail.start_date_required'), trigger: 'change' },
          { validator: validateAcquireStartTime, trigger: 'blur' }
        ],
        acquire_end_at: [
          { required: true, message: this.$t('Coupon.DialogCouponDetail.end_date_required'), trigger: 'change' },
          { validator: validateAcquireEndTime, trigger: 'blur' }
        ],
        apply_start_at: [
          { required: true, message: this.$t('Coupon.DialogCouponDetail.start_date_required'), trigger: 'change' },
          { validator: validateApplyStartTime, trigger: 'change' }
        ],
        apply_end_at: [
          { required: true, message: this.$t('Coupon.DialogCouponDetail.end_date_required'), trigger: 'change' },
          { validator: validateApplyEndTime, trigger: 'change' }
        ],
        amount: [
          { required: true, message: this.$t('Coupon.DialogCouponDetail.coupon_amount_required'), trigger: 'blur' },
          { validator: validateAmount, trigger: 'blur' }
        ],
        apply_amount_threshold: [
          { validator: validateApplyAmountThreshold, trigger: 'blur' }
        ],
        acquire_quantity: [
          { validator: validateAcquireQuantity, trigger: 'blur' }
        ]
      },

      validateAcquireError: '',

      interruptVoucherVisible: false
    }
  },

  computed: {
    ...mapGetters('Me', ['currencySymbol', 'storeName']),
    ...mapGetters('Voucher', ['createVoucherLoadingState', 'updateVoucherLoadingState']),

    acquireStatus () {
      return _.get(this.editVoucher, 'acquire_status', '')
    },
    isActivating () {
      return this.acquireStatus === COUPON_STATUS.ONGOING
    },
    isActivityEnd () {
      return [COUPON_STATUS.EXPIRED, COUPON_STATUS.INTERRUPTED].includes(this.acquireStatus)
    },

    isLoading () {
      return [this.createVoucherLoadingState, this.updateVoucherLoadingState].includes(loadingState.LOADING)
    },

    showInterruptBtn () {
      return !!this.editVoucher && !this.isActivityEnd
    }
  },

  created () {
    if (this.editVoucher) {
      const normalizeCoupon = {
        isSetMinimum: !!Number(this.editVoucher.apply_amount_threshold),
        isSetQuantity: !!Number(this.editVoucher.acquire_quantity),
        apply_amount_threshold: Number(this.editVoucher.apply_amount_threshold) || 0,
        amount: Number(this.editVoucher.amount)
      }

      this.ruleForm = Object.assign(this.ruleForm, this.editVoucher, normalizeCoupon)

      this.ruleForm['acquire_start_at'] = new Date(this.ruleForm.acquire_start_at)
      this.ruleForm['acquire_end_at'] = new Date(this.ruleForm.acquire_end_at)
      this.ruleForm['apply_start_at'] = new Date(this.ruleForm.apply_start_at)
      this.ruleForm['apply_end_at'] = new Date(this.ruleForm.apply_end_at)
    }
  },

  methods: {
    isKeyInNumber,
    ...mapActions('Voucher', ['createVoucher', 'updateVoucher', 'fetchVoucherList', 'interruptVoucher']),

    resetValidateAcquireError () {
      this.validateAcquireError = ''

      this.$refs.ruleForm.validateField('acquire_start_at')
      this.$refs.ruleForm.validateField('acquire_end_at')
    },

    async validateForm () {
      try {
        this.validateAcquireError = ''
        await this.$refs['ruleForm'].validate()
        return true
      } catch (error) {
        return false
      }
    },

    calUpdateDate (form) {
      return {
        name: form.name,
        event_type: form.event_type,
        acquire_start_at: new Date(form.acquire_start_at).toISOString(),
        acquire_end_at: new Date(form.acquire_end_at).toISOString(),
        apply_start_at: new Date(form.apply_start_at).toISOString(),
        apply_end_at: new Date(form.apply_end_at).toISOString(),
        amount: form.amount,
        apply_amount_threshold: form.isSetMinimum ? form.apply_amount_threshold : 0,
        acquire_quantity: form.isSetQuantity ? form.acquire_quantity : null
      }
    },

    handleEndEvent () {
      this.interruptVoucher(this.editVoucher.id)
        .then(() => {
          this.fetchVoucherList(false)
        })
        .finally(() => {
          this.handleCloseDialog()
        })
    },

    handleCloseDialog () {
      this.$emit('update:dialogVisible', false)
    },

    async handleCreateVoucher () {
      try {
        await this.createVoucher({
          storeName: this.storeName,
          postData: this.calUpdateDate(this.ruleForm)
        })

        this.$message({
          showClose: true,
          duration: 1000,
          message: this.$t('Components.DialogVoucherDetail.message_create_success'),
          type: 'success'
        })

        return true
      } catch (error) {
        if (error.status === 409) {
          this.validateAcquireError = error.data.message

          this.$refs.ruleForm.validateField('acquire_start_at')
          this.$refs.ruleForm.validateField('acquire_end_at')
        }
        return false
      }
    },

    async handleUpdateVoucher () {
      try {
        await this.updateVoucher({
          id: this.editVoucher.id,
          updateData: this.calUpdateDate(this.ruleForm)
        })

        this.$message({
          showClose: true,
          duration: 1000,
          message: this.$t('Components.DialogVoucherDetail.message_update_success'),
          type: 'success'
        })

        return true
      } catch (error) {
        return false
      }
    },

    async handleSubmit () {
      if (!(await this.validateForm())) return

      let result = false

      if (this.editVoucher) {
        result = await this.handleUpdateVoucher()
      } else {
        result = await this.handleCreateVoucher()
      }

      if (!result) return

      // 新增：回第一頁；更新：留在當頁
      this.fetchVoucherList(!this.editVoucher)

      this.handleCloseDialog()
    }
  }
}
</script>

<template lang="pug" src="./DialogVoucherDetail.pug"></template>
<style lang="scss" src="./DialogVoucherDetail.scss" scoped></style>
