<script>

import { mapGetters, mapActions } from 'vuex'

import ApiProduct from '@/api/product'

import * as REGIONS from '@/constants/regions'

import isKeyInNumber from '@/utils/isKeyInNumber'

const QUANTITY_LIMIT_MAX = 999999

export default {
  name: 'DialogAddProductSingle',

  props: {
    selectedProducts: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      QUANTITY_LIMIT_MAX,
      dialogVisible: false,
      uploadType: 'upload',
      isUploadImage: false,
      isLoading: false,
      preloadImageUrl: '',
      ruleForm: {
        image_id: null,
        name: '',
        skus: [
          {
            name: this.$t('product.default_sku'),
            price: null,
            available_quantity: null
          }
        ],
        default_keyword: ''
      },

      skusErrorList: [],
      duplicateNames: []
    }
  },

  computed: {
    ...mapGetters('Me', ['token', 'region', 'maxPrice', 'maxSkuName', 'maxProductName', 'shippingRateImg', 'keywordTip', 'imageUploadUrl']),
    ...mapGetters('ProductList', {
      defaultKeyword: 'defaultKeyword'
    }),
    uploadHeaders () {
      return { Authorization: `Bearer ${this.token}` }
    },
    rules () {
      return {
        name: [
          {
            required: true,
            message: this.$t('Components.DialogAddProductSingle.enter_name'),
            trigger: 'blur'
          }
        ],
        default_keyword: [
          {
            required: true,
            message: `${this.$t('Components.DialogPickProduct.enter_default_keyword')}`,
            trigger: 'blur'
          },
          {
            validator: this.checkHasBlank,
            message: `${this.$t('Product.DialogAddProduct.keyword_has_blank')}`,
            trigger: 'blur'
          },
          {
            validator: this.checkUniqueKeyword,
            message: `${this.$t('Components.DialogPickProduct.keyword_repeat2')}`,
            trigger: 'blur'
          }
        ]
      }
    },
    selectedProductsKeyowrd () {
      return this.selectedProducts.map(product => product.keyword)
    }
  },

  created () {
    this.getDefaultKeyword()
      .then(() => {
        this.ruleForm.default_keyword = this.defaultKeyword
      })
  },

  methods: {
    isKeyInNumber,
    ...mapActions('ProductList', ['getDefaultKeyword']),

    handleUploadSuccess (response, file) {
      this.ruleForm.image_id = response.id
      this.isUploadImage = false
      this.preloadImageUrl = URL.createObjectURL(file.raw)
    },
    handleDeleteImage () {
      this.ruleForm.image_id = null
      this.preloadImageUrl = null
    },
    handleFileError () {
      this.isUploadImage = false
      this.$message.error(this.$t('Components.DialogAddProductSingle.upload_fail'))
    },
    async handleFileRemove (file) {
      const imageId = file.id

      if (!imageId) return

      const success = await this.apiImage.delete(file.id)

      if (success) {
        this.ruleForm.image_id = null
      }
    },
    handleBeforeUpload (file) {
      const fileMB = file.size / (1024 ** 2)

      if (fileMB > 4) {
        this.$message.error(this.$t('Components.DialogAddProductSingle.file_size'))
        return false
      }

      this.isUploadImage = true
    },
    addSkusRow () {
      const copySkus = _.cloneDeep(this.ruleForm.skus[this.ruleForm.skus.length - 1])
      delete copySkus['id']
      this.ruleForm.skus.push(copySkus)
    },
    removeSkus (index) {
      this.ruleForm.skus.splice(index, 1)
    },
    checkValidate (index, name) {
      return _.get(this.skusErrorList, `[${index}].${name}`, false)
    },
    async validateSkus () {
      let validate = true

      // 驗證欄位
      try {
        await this.$refs['ruleForm'].validate()
      } catch {
        validate = false
      }

      const regex = /\s/

      const skusErrorList = this.ruleForm.skus.reduce((acc, current) => {
        const { name, price, available_quantity: count } = current

        let errorStatus = {
          isNullName: false,
          isBlankName: false,

          isNullPrice: false,
          isLimitOverPrice: false,

          isNullCount: false,
          isLimitOverCount: false,

          isOverStock: false
        }

        // 商品規格名稱
        if (!name) errorStatus.isNullName = true
        if (this.region !== REGIONS.TH && regex.test(name)) errorStatus.isBlankName = true

        // 價錢
        if (!price) errorStatus.isNullPrice = true
        if (price > this.maxPrice) errorStatus.isLimitOverPrice = true

        // 庫存
        if (_.isNil(count)) errorStatus.isNullCount = true
        if (count > QUANTITY_LIMIT_MAX) errorStatus.isLimitOverCount = true

        // 庫存 不可低於 可售數量
        if (current.available_quantity < current.lock_quantity) errorStatus.isOverStock = true

        if (Object.values(errorStatus).includes(true)) {
          validate = false
        }

        acc.push(errorStatus)
        return acc
      }, [])

      this.$set(this, 'skusErrorList', skusErrorList)

      // 判斷商品規格名稱 有無重複
      const nameCount = this.ruleForm.skus.reduce((acc, current) => {
        if (acc[current.name]) {
          acc[current.name] = acc[current.name] + 1
        } else {
          acc[current.name] = 1
        }

        return acc
      }, {})

      const duplicateNames = Object.keys(nameCount).filter(key => nameCount[key] > 1)
      this.duplicateNames = duplicateNames

      if (duplicateNames.length) {
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          message: this.$t(
            'Product.DialogAddProduct.error_specification_duplicated'
            , { duplicate: duplicateNames.join('<br>') }
          ),
          showClose: true
        })

        validate = false
      }

      return validate
    },
    async submitForm () {
      this.isLoading = true

      // 驗證規格
      const isValidateSkus = await this.validateSkus()
      if (!isValidateSkus) {
        this.isLoading = false
        return false
      }

      // 建立商品
      const apiProduct = new ApiProduct()
      const data = await apiProduct.create({
        ...this.ruleForm,
        image_ids: this.ruleForm.image_id ? [this.ruleForm.image_id] : []
      })

      // 新增至挑選商品
      this.$emit('handlePickProduct', [data])

      this.$message({
        message: this.$t('Components.DialogAddProductSingle.add_complete'),
        type: 'success',
        duration: 1000,
        showClose: true
      })

      this.$emit('update:dialogVisible', false)
      this.isLoading = false
    },

    handleCloseDialog () {
      const create = this.$createElement
      this.$msgbox({
        title: '',
        center: true,
        showClose: true,
        customClass: 'remind-saving',
        message: create('h4', null, [
          create('span', null, this.$t('message.leave_page')),
          create('br', null, ''),
          create('span', null, this.$t('message.confirm_leave'))
        ]),
        showCancelButton: true,
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel')
      })
        .then(() => {
          this.$emit('update:dialogVisible', false)
        })
        .catch(action => {
        })
    },

    checkHasBlank (rules, value, callback) {
      // 確認有無空白字元

      // 泰國不擋有空白字元
      if (this.region === REGIONS.TH) {
        callback()
        return
      }

      const regex = /\s/
      const hasSpace = regex.test(value)

      if (hasSpace) {
        callback(new Error())
      } else {
        callback()
      }
    },
    async checkUniqueKeyword (rules, value, callback) {
      const keyword = this.ruleForm.default_keyword
      const isLiveWebHaveKeyword = this.selectedProductsKeyowrd.includes(keyword)
      const apiProduct = new ApiProduct()
      const isSuccess = await apiProduct.checkUniqueKeyword(keyword)
      if (isSuccess && !isLiveWebHaveKeyword) {
        callback()
      } else {
        callback(new Error())
      }
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
