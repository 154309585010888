<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DialogStoreNew',
  data () {
    return {
      dialogVisible: false
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName'])
  },
  methods: {
    toProduct () {
      this.$router.push(`/${this.storeName}/product`)
    },
    toPost () {
      this.$router.push(`/${this.storeName}/posts`)
    },
    toggle (isOpen = null) {
      this.dialogVisible = isOpen === null ? !this.dialogVisible : isOpen
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
