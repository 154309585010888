<script>
import VueTypes from 'vue-types'

import { BASE_IMAGE_PATH } from '@/constants/path'
import { FB_PAGE, HANDSUP, M17_LIVE, M17_LIVE_V_2 } from '@/constants/channelType'
import { UNPUBLISHED, LIVE, LIVE_STOPPED, LIVE_REPLAY } from '@/constants/liveStatus'
import { normalizePost } from '@/views/Home/components/CardPost/normalizePost'
import TicketBadge from '@/components/TicketBadge'
import authorizeIcon from '@/assets/icon-channel-authorize.png'
import handsupIcon from '@/assets/icon-channel-handsup.png'
import facebookIcon from '@/assets/icon-channel-facebook.png'
import celebrityIcon from '@/assets/icon-channel-celebrity.png'
import privateIcon from '@/assets/icon-channel-private@2x.png'
import M17Icon from '@/assets/icon-channel-m17.png'

import TICKET from '@/assets/post/action/btn-ticket.svg'
import TICKET_ACTIVE from '@/assets/post/action/btn-ticket-active.svg'
import TICKET_DISABLE from '@/assets/post/action/btn-ticket-disable.svg'
import ANNOUNCE from '@/assets/post/action/btn-announce.svg'
import ANNOUNCE_ACTIVE from '@/assets/post/action/btn-announce-active.svg'
import ANNOUNCE_DISABLE from '@/assets/post/action/btn-announce-disable.svg'

export default {
  name: 'CardPost',
  components: {
    TicketBadge
  },
  props: {
    post: VueTypes.shape({
      title: String,
      created_at: String,
      publications: Array
    }).loose.def({}),
    storeName: VueTypes.string.def(''),
    isEnterpriseGeneric: VueTypes.bool.def(false),
    enabledTicket: VueTypes.bool.def(false)
  },

  data () {
    return {
      UNPUBLISHED,
      LIVE_STOPPED,
      authorizeIcon,
      handsupIcon,
      facebookIcon,
      celebrityIcon,
      privateIcon,
      TICKET,
      TICKET_ACTIVE,
      TICKET_DISABLE,
      ANNOUNCE,
      ANNOUNCE_ACTIVE,
      ANNOUNCE_DISABLE
    }
  },

  computed: {
    liveUrl () {
      // 企業通用版 特別新增 openExternalBrowser，
      // 主要是 FBBuy 登入也是用 SDK 會造成白畫面
      return this.isEnterpriseGeneric
        ? `${this.$VUE_APP_BUYER_DOMAIN_NAME}/${this.storeName}/live/${this.post.id}?openExternalBrowser=1`
        : `${this.$VUE_APP_BUYER_DOMAIN_NAME}/${this.storeName}/live/${this.post.id}`
    },

    isAgent () {
      return !!this.post.agent
    },

    isCelebrity () {
      return !!this.post.celebrity
    },

    normalizePost () {
      return normalizePost(this.post)
    },

    isUpcoming () {
      if (!this.normalizePost) return

      return this.normalizePost.liveStatus === UNPUBLISHED && !!this.normalizePost.upcomingTime
    },

    isReplaying () {
      const date = this.post.replay_expired_at
      if (!date) return false
      if (this.normalizePost.liveStatus === LIVE_STOPPED) {
        return new Date(date) > new Date()
      } else {
        return false
      }
    },

    disableLinkAgent () {
      // 直播中、結束直播、回放直播，皆不能複製授權直播連結
      return [LIVE, LIVE_STOPPED, LIVE_REPLAY].includes(this.normalizePost.liveStatus)
    },

    disableLiveLink () {
      // 網紅授權 和 FB 開播，都無 連結複製
      if (this.isAgent || this.normalizePost.channelType === FB_PAGE) return true

      // 不是回放影片 且 已直播結束
      if (!this.isReplaying && this.normalizePost.liveStatus === LIVE_STOPPED) return true

      return false
    },

    postIcons () {
      const iconMappings = {
        [FB_PAGE]: facebookIcon,
        [HANDSUP]: handsupIcon,
        [M17_LIVE]: M17Icon,
        [M17_LIVE_V_2]: `${BASE_IMAGE_PATH}/icons/20211004_17LIVE_icon.png`
      }

      if (this.isAgent) {
        return [authorizeIcon, facebookIcon]
      }
      if (this.isCelebrity) {
        return [celebrityIcon, facebookIcon]
      }

      // 取出不重複的 type，因為 facebook 有可能為會重複
      const channelSet = this.post.publications.reduce((prev, publication) =>
        prev.add(publication.channel.type), new Set())

      return [...channelSet].map(type => iconMappings[type])
    },

    avatarImage () {
      if (this.isAgent && this.normalizePost.agentStoreId) {
        return this.normalizePost.agentAvatarUrl
      }

      if (this.isCelebrity) {
        return this.post.celebrity.avatar_url
      }

      return null
    },

    ticket () {
      return this.post.ticket
    },

    showEditTicket () {
      return this.enabledTicket &&
        this.post.is_private
    }
  },
  methods: {
    copyLinkAgent () {
      const data = {
        token: this.normalizePost.agentToken,
        expiredAt: this.isAgent && this.post.agent.expired_at
      }
      this.$emit('copyLink', data)
    },

    copyLinkLive () {
      this.copyText({
        url: this.liveUrl,
        success: this.$t('Post.Show.message.copy_link'),
        error: this.$t('Post.Show.message.copy_fail')
      })
    },
    toLive () {
      this.$router.push(this.liveUrl)
    },

    openSetLiveTime () {
      this.$emit('setLiveTime', this.post)
    },

    openSetCover () {
      this.$emit('setCover', this.post)
    },

    openPrivate () {
      this.$emit('setPrivate', this.post)
    }
  }
}
</script>

<template lang="pug" src="./CardPost.pug"></template>
<style lang="scss" src="./CardPost.scss" scoped></style>
