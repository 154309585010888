import ICON_HEART_1 from '@/assets/heart/icon_heart_1.svg'
import ICON_HEART_2 from '@/assets/heart/icon_heart_2.svg'
import ICON_HEART_3 from '@/assets/heart/icon_heart_3.svg'
import ICON_HEART_4 from '@/assets/heart/icon_heart_4.svg'
import ICON_HEART_5 from '@/assets/heart/icon_heart_5.svg'
import ICON_HEART_6 from '@/assets/heart/icon_heart_6.svg'
import ICON_HEART_7 from '@/assets/heart/icon_heart_7.svg'
import ICON_HEART_8 from '@/assets/heart/icon_heart_8.svg'
import ICON_HEART_9 from '@/assets/heart/icon_heart_9.svg'
import ICON_HEART_10 from '@/assets/heart/icon_heart_10.svg'
import ICON_HEART_11 from '@/assets/heart/icon_heart_11.svg'
import ICON_HEART_12 from '@/assets/heart/icon_heart_12.svg'
import ICON_HEART_14 from '@/assets/heart/icon_heart_14.svg'
import ICON_HEART_21 from '@/assets/heart/icon_heart_21.svg'
import ICON_HEART_22 from '@/assets/heart/icon_heart_22.svg'

import IMAGE_HALLOWEEN_1 from '@/assets/halloween/icon_candy_blue.svg'
import IMAGE_HALLOWEEN_2 from '@/assets/halloween/icon_candy_green.svg'
import IMAGE_HALLOWEEN_3 from '@/assets/halloween/icon_candy_red.svg'
import IMAGE_HALLOWEEN_4 from '@/assets/halloween/icon_ghost.svg'
import IMAGE_HALLOWEEN_5 from '@/assets/halloween/icon_pumpkin.svg'

import IMAGE_XMAS_1 from '@/assets/xmas/icon-christmas-1.svg'
import IMAGE_XMAS_2 from '@/assets/xmas/icon-christmas-2.svg'
import IMAGE_XMAS_3 from '@/assets/xmas/icon-christmas-3.svg'
import IMAGE_XMAS_4 from '@/assets/xmas/icon-christmas-4.svg'
import IMAGE_XMAS_5 from '@/assets/xmas/icon-christmas-5.svg'
import IMAGE_XMAS_6 from '@/assets/xmas/icon-christmas-6.svg'
import IMAGE_XMAS_7 from '@/assets/xmas/icon-christmas-7.svg'
import IMAGE_XMAS_8 from '@/assets/xmas/icon-christmas-8.svg'
import IMAGE_XMAS_9 from '@/assets/xmas/icon-christmas-9.svg'
import IMAGE_XMAS_10 from '@/assets/xmas/icon-christmas-10.svg'

const halloweenMap = {
  1: IMAGE_HALLOWEEN_1,
  2: IMAGE_HALLOWEEN_2,
  3: IMAGE_HALLOWEEN_3,
  4: IMAGE_HALLOWEEN_4,
  5: IMAGE_HALLOWEEN_5
}

const xmasMap = {
  1: IMAGE_XMAS_1,
  2: IMAGE_XMAS_2,
  3: IMAGE_XMAS_3,
  4: IMAGE_XMAS_4,
  5: IMAGE_XMAS_5,
  6: IMAGE_XMAS_6,
  7: IMAGE_XMAS_7,
  8: IMAGE_XMAS_8,
  9: IMAGE_XMAS_9,
  10: IMAGE_XMAS_10
}

const likeMap = {
  1: ICON_HEART_1,
  2: ICON_HEART_2,
  3: ICON_HEART_3,
  4: ICON_HEART_4,
  5: ICON_HEART_5,
  6: ICON_HEART_6,
  7: ICON_HEART_7,
  8: ICON_HEART_8,
  9: ICON_HEART_9,
  10: ICON_HEART_10,
  11: ICON_HEART_11,
  12: ICON_HEART_12,
  13: ICON_HEART_14,
  14: ICON_HEART_21,
  15: ICON_HEART_22
}

export const IMG_MAP = {
  halloween: halloweenMap,
  xmas: xmasMap,
  like: likeMap
}
