<script>
import { mapGetters, mapActions } from 'vuex'

import BasicInfo from './components/BasicInfo'
import BasicInfoEnterpriseGeneric from './components/BasicInfoEnterpriseGeneric'
import StoreSetting from './components/StoreSetting'

export default {
  name: 'Store',
  components: {
    BasicInfo,
    BasicInfoEnterpriseGeneric,
    StoreSetting
  },
  computed: {
    ...mapGetters('Me', ['isEnterpriseGeneric']),

    calBasicInfoComponent () {
      if (this.isEnterpriseGeneric) {
        // 企業通用版
        return 'BasicInfoEnterpriseGeneric'
      } else {
        return 'BasicInfo'
      }
    }
  },

  methods: {
    ...mapActions('Me', ['fetchCurrentStore'])
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
