import store from '@/store/index'

export function getUploadHeaders () {
  if (!store) return
  const token = store.getters['Me/token']
  return { Authorization: `Bearer ${token}` }
}

export function getVideoUploadPath () {
  if (!store) return
  return store.getters['Me/videoUploadUrl']
}

export function getImageUploadPath () {
  if (!store) return
  return store.getters['Me/imageUploadUrl']
}