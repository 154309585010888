<script>
export default {
  name: 'DialogConfirmRemove',
  components: {},
  data () {
    return {
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
