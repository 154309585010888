<script>
import VueTypes from 'vue-types'
import { mapGetters, mapActions } from 'vuex'

import { normalizeImage } from '@/utils/normalizeImage'
import { getPublication } from '@/utils/getPublication'
import AppTable from '@/components/AppTable'

import DialogEditProduct from '@/views/Post/components/DialogEditProduct'

export default {
  name: 'ProductTable',

  components: {
    AppTable,
    DialogEditProduct
  },

  props: {
    products: VueTypes.array,
    post: VueTypes.object.def({})
  },

  data () {
    return {
      currentPage: 1,
      pageSize: 6,
      searchRule: {
        keyword: ''
      },
      editProduct: {},
      searchEnterPriseProduct: '',
      isBtnLoading: false,
      columns: [
        {
          id: 'product',
          name: this.$t('product.product'),
          width: 260
        },
        {
          id: 'keyword',
          name: this.$t('product.keyword'),
          width: 120
        },
        {
          id: 'sku',
          name: this.$t('product.sku'),
          width: 110
        },
        {
          id: 'price',
          name: this.$t('product.price'),
          width: 90
        },
        {
          id: 'sold',
          name: `${this.$t('product.ordered')} / ${this.$t('product.salable')}`,
          width: 110
        }
      ],
      dialogEditProductVisible: false
    }
  },

  computed: {
    ...mapGetters('Me', ['storeName', 'currentStore', 'isEnterprise', 'enterpriseSlug', 'currencySymbol', 'keywordTip', 'enabledMerchandise']),

    postStatus () {
      // @todo Post/PublishedProducts 也有一模一樣的 computed，需要抽出去
      // 建立/temp
      if (this.$route.name === 'PostCreate') return 'temp'

      const publication = getPublication(this.post.publications)

      // 草稿/draft
      if (!publication) return 'draft'

      if (publication.live_status === 'live') {
        // 直播中/live
        return 'live'
      } else if (publication.live_status === 'unpublished') {
        // 待開播
        return 'unpublished'
      } else {
        // 直播結束/finish
        return 'finish'
      }
    },
    filterProducts () {
      let products = this.products.filter(product => {
        const keywords = [product.name]
        // product keyword
        if (product.keyword) keywords.push(product.keyword)
        // skus keyword
        product.skus.forEach(sku => keywords.push(sku.keyword))
        const content = keywords.join('!$').toLowerCase()
        return content.includes(this.searchRule.keyword.toLowerCase())
      })

      return products
    },

    filteredProductsKeywords () {
      return this.filterProducts.map(item => item.keyword)
    },

    chunkProducts () {
      return this.filterProducts.reduce((prve, product, i) => {
        // 每 n 筆後，產生新頁
        if (i % this.pageSize === 0) {
          prve.push([])
        }

        // 計算資料屬於第幾頁
        const page = parseInt(i / this.pageSize)
        prve[page].push(product)

        return prve
      }, [])
    },
    getCurrentProducts () {
      return this.chunkProducts[this.currentPage - 1]
    }

  },

  methods: {
    ...mapActions('ProductDetail', ['setEditProduct', 'updateDefaultKeyword']),

    getImgUrl (images) {
      if (!images || images.length === 0) return

      return normalizeImage(images[0].url, 36)
    },

    async handleSearchEnterPrise () {
      if (!this.searchEnterPriseProduct) return

      this.isBtnLoading = true

      try {
        let api = `/api/v1/enterprise/stores/${this.storeName}/products`
        const params = { keyword: this.searchEnterPriseProduct }
        const { data: { data: products } } = await this.axios.get(api, { params })

        if (products.length) {
          this.$emit('handlePickProduct', products)
        } else {
          this.$message({
            showClose: true,
            duration: 2000,
            message: this.$t('Post.PublishedProducts.search_error'),
            type: 'error'
          })
        }
      } catch (e) {
        console.error(e)
      }

      this.searchEnterPriseProduct = ''
      this.isBtnLoading = false
    },
    getOrderedCount (sku) {
      if (!sku.post_lock_quantity || !sku.post_sold_quantity) return 0

      return sku.post_lock_quantity + sku.post_sold_quantity > 999
        ? 999
        : sku.post_lock_quantity + sku.post_sold_quantity
    },
    handleChangeSearch () {
      this.currentPage = 1
    },
    handleRefresh () {
      this.searchRule.keyword = null
      this.currentPage = 1
    },

    handleSelectProduct (product) {
      this.setEditProduct(_.cloneDeep(product))

      // Note:
      // 因為 Post 裡的商品有自己的 keyword 了，
      // 就不應該延用商品的 default_keyword，
      // 所以需要將 default_keyword 的值改為 Post Product Keyword。
      this.updateDefaultKeyword(product.keyword)

      this.dialogEditProductVisible = true
    },
    handleDeleteProduct (product, notShowConfirm = false) {
      // 直播結束，不提供刪除
      if (this.postStatus === 'finish') return

      // 判斷是否已有售出商品
      const hasSoldCount = product.skus.some(sku => !!this.getOrderedCount(sku))

      if (hasSoldCount) {
        this.$alert(this.$t('Post.Products.cant_delete'), this.$t('Post.Products.remind'), {
          confirmButtonText: this.$t('button.confirm')
        })
        return
      }

      // 若是由 DialogPostProduct 不需再詢問
      if (notShowConfirm) {
        this.$emit('handleDeleteProduct', product)
        return
      }

      this.$confirm(this.$t('Post.Products.cant_see'), this.$t('Post.Products.remind'), {
        confirmButtonText: this.$t('button.confirm'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'error'
      }).then(() => {
        this.$emit('handleDeleteProduct', product)
      }).catch(() => {})
    },
    handleUpdateProduct (product) {
      this.$emit('handleUpdateProduct', product)
    },
    openDialogPickProductVisible () {
      this.$emit('handleOpenDialogPickProduct')
    }
  }
}
</script>

<template lang="pug" src="./ProductTable.pug"></template>
<style lang="scss" src="./ProductTable.scss" scoped></style>

<style lang="scss">

.el-popover-post-action {
  @include flex(center);

  flex-direction: column;
  min-width: 90px;
  padding: 8px 0;

  .el-button + .el-button {
    margin: 0;
  }

  .el-button {
    padding-right: 20px;
    padding-left: 20px;
    color: $black;
  }

  .btn-activity {
    padding-bottom: 8px;

    &:hover {
      color: $primary;
    }
  }

  .btn-edit {
    padding-top: 8px;
    padding-bottom: 8px;

    &:hover {
      color: $primary;
    }
  }

  .btn-delete {
    padding-top: 8px;

    &:hover {
      color: $danger;
    }
  }
}

</style>
