<script>
import VueTypes from 'vue-types'
import { mapGetters } from 'vuex'
import { updateMe } from '@/views/User/service/apis'

export default {
  name: 'PreviewImage',

  props: {
    uploadHeaders: VueTypes.object.def({}),
    avatarUrl: VueTypes.string.def('')
  },

  computed: {
    ...mapGetters('Me', ['imageUploadUrl'])
  },

  methods: {
    async handleAvatarSuccess (response, file) {
      try {
        // 更新圖像
        await updateMe({
          'avatar_id': response.id
        })

        this.$emit('refreshMe')
      } catch (err) {
        console.error(err)
      }
    },
    handleBeforeUpload (file) {
      const IMAGE_MAX_LIMIT = 2
      const fileMB = file.size / (1024 ** 2)

      if (fileMB > IMAGE_MAX_LIMIT) {
        this.$message.error(this.$t('Setting.Store.message.upload_limit'))
        return false
      }

      return true
    }
  }
}
</script>

<template lang="pug" src="./PreviewImage.pug"></template>
