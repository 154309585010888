<script>
import { mapGetters, mapActions } from 'vuex'
import AddressSelect from '@/components/AddressSelect'
import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import * as loadingState from '@/constants/loadingState'

export default {
  name: 'DialogEditAddress',
  components: { AddressSelect },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loadingState,
      SHIPPING_TYPES,
      form: {
        name: this.order.receiverName,
        phone: this.order.receiverPhone,
        address: this.order.receiverAddress
      }
    }
  },
  computed: {
    ...mapGetters('Me', ['currentStore', 'currencySymbol']),
    rules () {
      return {
        name: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')}`,
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: `${this.$t('input.entry_placeholder')}`,
            trigger: 'blur'
          }
        ]
      }
    },
    isStore () {
      const storeTypes = [SHIPPING_TYPES.SEVEN, SHIPPING_TYPES.FAMILY]

      return storeTypes.includes(this.order.shippingType)
    },
    isSelfPickUp () {
      const pickUpTypes = [SHIPPING_TYPES.SELF_PICK_UP, SHIPPING_TYPES.CUSTOM_SELF_PICK_UP]

      return pickUpTypes.includes(this.order.shippingType)
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    handleClose () {
      this.$emit('update:dialogVisible', false)
    },

    async submit () {
      try {
        await this.$refs.editForm.validate()
        if (!this.isStore && !this.isSelfPickUp) {
          await this.$refs.addressForm.validateForm()
        }
        const addressFields = ['level_one', 'level_two', 'level_three', 'level_four', 'postcode', 'level_one_code']

        const postData = {
          receiver_name: this.form.name,
          receiver_phone: this.form.phone,
          receiver_address: _.pick(this.form.address, addressFields)
        }

        this.$emit('submit', postData)
      } catch (e) {
        this.$message.error(this.$t('message.form_incomplete'))
      }
    },

    updateAddress (obj) {
      this.form.address = obj
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>
