<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SettingTitle',
  computed: {
    ...mapGetters('LiveSetting', ['isChanged'])
  },
  methods: {
    ...mapActions('LiveSetting', ['updateShowSaveConfirm']),
    goBack () {
      history.back()
    }
  }
}
</script>

<template lang="pug" src="./SettingTitle.pug"></template>
<style lang="scss" src="./SettingTitle.scss" scoped></style>
