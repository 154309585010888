<script>
import VueApexCharts from 'vue-apexcharts'

import {
  TOLERANCE_LATENCY
} from '../../../../constants'

const roundTo2Decimal = num => Math.round(num * 1000) / 1000

export default {
  components: {
    apexchart: VueApexCharts
  },

  props: {
    speciments: {
      type: Array
    },

    xcategory: {
      type: Array
    }
  },

  computed: {
    options () {
      return {
        chart: {
          id: 'network-latency-chart'
        },

        colors: ['#e91e63', '#9aecdb'],

        xaxis: {
          labels: {
            formatter (value) {
              return `${value}`
            },

            show: true
          },
          // Category is the number of speciments we took for network examining
          categories: this.xcategory.map((_, i) => i + 1)
        },

        yaxis: {
          labels: {
            formatter (value) {
              return `${roundTo2Decimal(value)}ms`
            }
          },

          title: {
            text: 'latency(milliseconds)'
          },
          axisTicks: {
            show: true
          }
        }
      }
    },

    series () {
      const { speciments, xcategory } = this

      const toleranceLatency = xcategory.map(_ => TOLERANCE_LATENCY)
      return [
        {
          name: 'tolerance-latency',
          data: toleranceLatency
        },
        {
          name: 'latency',
          type: 'column',
          data: speciments
        }
      ]
    }
  }
}
</script>

<template lang="pug" src="./template.pug" />
<style lang="scss" src="./style.scss" />
