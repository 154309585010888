import axios from '@/plugins/http.js'

export default class JpZip {
  get = async (zipcode) => {
    try {
      const api = `/api/v1/jp-zip`
      const { data } = await axios.get(api, { params: { zipcode: zipcode } })

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }
}
