<script>
import { mapGetters, mapActions } from 'vuex'
import VueTypes from 'vue-types'

import { LOADING } from '@/constants/loadingState'

import { STEP_THREE } from '../../constants/steps'

export default {
  name: 'Step2',

  props: {
    isMobile: VueTypes.bool
  },

  data: () => ({
    LOADING,
    tag_ids: []
  }),

  computed: {
    ...mapGetters('RegisterInfluencer', {
      celebrityTags: 'getCelebrityTags',
      getCelebrityTagsLoadingState: 'getCelebrityTagsLoadingState',
      updateTagsLoadingState: 'updateTagsLoadingState'
    })
  },

  created () {
    this.getCelebrityTags()
  },

  methods: {
    ...mapActions('RegisterInfluencer', [
      'getCelebrityTags',
      'updateCelebrityTags'
    ]),

    handleNextStep () {
      this.updateCelebrityTags({
        tag_ids: this.tag_ids
      })
        .then(() => this.$emit('next-step', STEP_THREE))
    }
  }
}
</script>

<template lang="pug" src="./Step2.pug"></template>
<style lang="scss" src="./Step2.scss" scoped></style>
