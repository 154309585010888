import * as loadingState from '@/constants/loadingState'
import { updateSetting } from '../api'

const SETTING_KEY_MAP = {
  liveweb_shop_icon_show: 'enable',
  liveweb_member_count_show: 'enable',
  liveweb_heart_animation: 'enable',
  liveweb_festival_animation: 'array',
  liveweb_join_room_show: 'enable',
  liveweb_product_list_show: 'enable',
  liveweb_cart_show: 'enable',
  liveweb_sales_product_show: 'enable',
  liveweb_add_cart_notice: 'enable',
  liveweb_product_purchase_word: 'string',
  comment_analysis: 'enable'
}

export const types = {
  SAVE_NEW_SETTINGS: 'SAVE_NEW_SETTINGS',
  SAVE_NEW_SETTINGS_SUCCESS: 'SAVE_NEW_SETTINGS_SUCCESS',
  SAVE_NEW_SETTINGS_FAILED: 'SAVE_NEW_SETTINGS_FAILED',
  UPDATE_NEW_SETTINGS: 'UPDATE_NEW_SETTINGS',
  UPDATE_NEW_SETTING: 'UPDATE_NEW_SETTING'
}

const INIT_DATA = {
  newSettings: {},

  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  liveSettingLoadingState: state => state.loadingState,
  newSettings: state => state.newSettings
}

const mutations = {
  [types.SAVE_NEW_SETTINGS] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.SAVE_NEW_SETTINGS_SUCCESS] (state) {
    state.loadingState = loadingState.READY
  },

  [types.SAVE_NEW_SETTINGS_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  },

  [types.UPDATE_NEW_SETTINGS] (state, settings) {
    state.newSettings = settings
  },

  [types.UPDATE_NEW_SETTING] (state, { setting, value }) {
    state.newSettings[setting] = value
  }
}

const actions = {
  saveNewSettings ({ state, commit, rootGetters }) {
    commit(types.SAVE_NEW_SETTINGS)

    return updateSetting(rootGetters['Me/storeName'], state.newSettings)
      .then(() => commit(types.SAVE_NEW_SETTINGS_SUCCESS))
      .catch(err => {
        commit(types.SAVE_NEW_SETTINGS_FAILED, err)
        throw err
      })
  },
  getCurrentSetting ({ commit, rootGetters }) {
    const currentSettings = rootGetters['Me/currentStore'].settings
    const newSettings = currentSettings.reduce((acc, val) => {
      if (!SETTING_KEY_MAP[val.name]) return acc

      if (SETTING_KEY_MAP[val.name] === 'array') {
        acc[val.name] = JSON.parse(val.string) || []
      } else {
        acc[val.name] = val[SETTING_KEY_MAP[val.name]]
      }
      return acc
    }, {})
    commit(types.UPDATE_NEW_SETTINGS, newSettings)
  },

  updateNewSetting ({ dispatch, commit }, { setting, value }) {
    commit(types.UPDATE_NEW_SETTING, { setting, value })
    dispatch('updateIsChanged', true)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
