import BaseApi from './baseApi'
import storeId from './_storeId'
import axios from '@/plugins/http.js'

export default class Service extends BaseApi {
  uri = `/api/v1/stores/${storeId()}/additional-services`
  logPagination = { links: {}, meta: {} }
  paymentPagination = { links: {}, meta: {} }

  addValue = async (serviceName, postData) => {
    try {
      const api = `/api/v1/stores/${storeId()}/additional-services/${serviceName}/add-value`
      const { data } = await axios.post(api, postData)
      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  getShow = async (serviceName) => {
    try {
      const api = `/api/v1/stores/${storeId()}/additional-services/${serviceName}`
      const { data: { data } } = await axios.get(api)

      return data
    } catch (e) {
      console.error(e)
      return null
    }
  }

  logPaginator = {
    data: () => this.logPagination,

    to: async page => this.getLogs({ ...this.logPagination.params, page })
  }

  getLogs = async (params) => {
    try {
      const api = `/api/v1/stores/${storeId()}/additional-services/logs`
      const { data } = await axios.get(api, {
        params
      })
      this.logPagination = { ...data, params }

      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }

  paymentPaginator = {
    data: () => this.paymentPagination,

    to: async page => this.getPaymentList({ ...this.paymentPagination.params, page })
  }

  getPaymentList = async () => {
    try {
      const api = `/api/v1/stores/${storeId()}/additional-services/payment-list`
      const { data } = await axios.get(api)
      this.paymentPagination = { ...data }

      return data
    } catch (e) {
      console.error(e)
      return []
    }
  }
}
