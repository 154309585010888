<script>
import { mapGetters, mapActions } from 'vuex'

import getSocialIcon from '@/utils/getSocialIcon'

import PaginationPure from '@/components/PaginationPure'

import { exportBillItems } from '../../services/apis/bill'

// order 表示這筆收費是來自訂單後收抽成
const ITEMABLE_TYPE = {
  ORDER: 'order',
  ENTERPRISE_REPORT: 'enterprise_report_log'
}

export default {
  name: 'AccountList',
  components: { PaginationPure },

  computed: {
    ...mapGetters('Me', ['storeName', 'currencySymbol', 'isEnterprise']),
    ...mapGetters('AccountManagement', {
      list: 'paymentListData',
      meta: 'paymentListMeta',
      search: 'paymentListSearch'
    }),

    searchDate: {
      get () {
        return this.search.date
      },
      set (val) {
        this.updatePaymentListSearch({
          date: val
        })
      }
    },

    searchPerPage: {
      get () {
        return this.search.per_page
      },
      set (val) {
        this.updatePaymentListSearch({
          'per_page': val
        })
      }
    },

    normalizeList () {
      return this.list.map(item => {
        // itemable_type order 表示這筆收費是來自訂單後收抽成
        // 未來可能會有 renewal 型別，表示年月費
        const isDeferred = item.itemable_type === ITEMABLE_TYPE.ORDER || ITEMABLE_TYPE.ENTERPRISE_REPORT
        const isPriceUpdate = item.exclusive_order_items && item.exclusive_order_items.length
        const isOverpayment = item.overpayment_order_items && item.overpayment_order_items.length

        const subtotal = isOverpayment
          ? -Number(item.commission_base_amount)
          : Number(item.commission_base_amount)

        const transactionFee = isOverpayment
          ? -Number(item.overpayment)
          : Number(item.price)

        /**
         * 企業版帳務明細說明:
         * 1 因為企業版的訂單, 是在企業自己的官網成立, 所以 hu 不會有訂購人 (buyer_name), 跟收件人 (receiver_name) 的資訊
         * 2 企業官網會打 hu 的 api 把訂單資訊回拋給 hu, 這時候會給 buyer_name
         * 3 因為只有 buyer_name, 所以企業版訂單的訂購人跟收件人名稱都設定為 buyer_name
         */
        const buyerName = this.isEnterprise ? (item.itemable && item.itemable.buyer_name) : (item.buyer && item.buyer.name)
        const receiverName = this.isEnterprise ? buyerName : (item.shipping && item.shipping.receiver_name)

        return {
          ...item,
          isDeferred,
          isPriceUpdate,
          isOverpayment,
          subtotal,
          transactionFee,
          buyerName,
          receiverName
        }
      })
    }
  },

  methods: {
    ...mapActions('AccountManagement', [
      'fetchPaymentList',
      'updatePaymentListSearch'
    ]),
    getSocialIcon,

    fetchData () {
      this.fetchPaymentList(this.storeName)
    },

    handleExportBillItems () {
      exportBillItems(this.storeName, this.searchDate)
    },

    handleUpdateRows (page) {
      this.updatePaymentListSearch({ page })
      this.fetchData()
    }
  }
}
</script>

<template lang="pug" src="./AccountList.pug"></template>
<style lang="scss" src="./AccountList.scss" scoped></style>
