import * as loadingState from '@/constants/loadingState'
import { getTicketList } from '../apis'

export const actionTypes = {
  GET_TICKET_LIST: 'GET_TICKET_LIST',
  GET_TICKET_LIST_SUCCESS: 'GET_TICKET_LIST_SUCCESS',
  GET_TICKET_LIST_FAILED: 'GET_TICKET_LIST_FAILED'
}

const state = {
  ticketList: [],
  loadingState: loadingState.EMPTY,
  error: null
}

const getters = {
  ticketList: state => state.ticketList,
  getTicketListLoadingState: state => state.loadingState,
  isTicketListLoading: state => state.loadingState === loadingState.LOADING
}

const mutations = {
  [actionTypes.GET_TICKET_LIST] (state) {
    state.loadingState = loadingState.LOADING
  },

  [actionTypes.GET_TICKET_LIST_SUCCESS] (state, payload) {
    state.ticketList = payload
    state.loadingState = loadingState.READY
  },

  [actionTypes.GET_TICKET_LIST_FAILED] (state, error) {
    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  }
}

const actions = {
  getTicketList ({ commit }, { storeId }) {
    commit(actionTypes.GET_TICKET_LIST)

    getTicketList(storeId)
      .then(({ data }) => commit(actionTypes.GET_TICKET_LIST_SUCCESS, data))
      .catch(err => commit(actionTypes.GET_TICKET_LIST_FAILED, err))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
