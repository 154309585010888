import * as loadingState from '@/constants/loadingState'
import { getFestivals } from '../api'

export const types = {
  FETCH_FESTIVALS: 'FETCH_FESTIVALS',
  FETCH_FESTIVALS_SUCCESS: 'FETCH_FESTIVALS_SUCCESS',
  FETCH_FESTIVALS_FAILED: 'FETCH_FESTIVALS_FAILED'
}

const INIT_DATA = {
  festivals: [],

  loadingState: loadingState.EMPTY,
  error: null
}

const state = _.cloneDeep(INIT_DATA)

const getters = {
  festivalsLoadingState: state => state.loadingState,
  activeFestivals: state => state.festivals ? state.festivals.map(festival => festival.name) : []
}

const mutations = {
  [types.FETCH_FESTIVALS] (state) {
    state.loadingState = loadingState.LOADING
  },

  [types.FETCH_FESTIVALS_SUCCESS] (state, festivals) {
    state.loadingState = loadingState.READY
    state.festivals = festivals
  },

  [types.FETCH_FESTIVALS_FAILED] (state, error) {
    // 初始化資料
    Object.assign(state, _.cloneDeep(INIT_DATA))

    state.loadingState = loadingState.ERROR
    state.error = error
    console.error(error)
  }
}

const actions = {
  getFestivals ({ commit, rootGetters }) {
    return getFestivals(rootGetters['Me/storeName'])
      .then(response => commit(types.FETCH_FESTIVALS_SUCCESS, response?.data?.data))
      .catch(err => {
        commit(types.FETCH_FESTIVALS_FAILED, err)
        throw err
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
