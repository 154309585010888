<script>
import { mapGetters, mapActions } from 'vuex'

import ApiPost from '@/api/post'
import ApiProduct from '@/api/product'

import * as LIVE_STATUS from '@/constants/liveStatus'
import * as ACTIVITY_TYPE from '@/constants/activityType'
import * as ACTIVITY_STATUS from '@/constants/activityStatus'

import DialogPickProduct from '@/components/DialogPickProduct'
import DialogPickProductGeneric from '@/components/DialogPickProductGeneric'
import DialogAddOrder from '@/components/DialogAddOrder'
import DialogLiveAuthorization from '@/components/DialogLiveAuthorization'
import DialogSetLiveTime from '@/components/DialogSetLiveTime'
import DialogSetPostTime from '@/components/DialogSetPostTime'
import DialogSetCover from '@/components/DialogSetCover'
import DialogSetRtmp from '@/components/DialogSetRtmp'

import * as TABS from '@/views/Post/constants/tabs'

import DialogAddProduct from '../../components/DialogAddProduct'
import PostComments from '../../components/Comments'

import DrawAnimation from '../../components/DrawAnimation'
import RibbonAnimation from '../../components/RibbonAnimation'
import DialogUpdateCart from '../../components/DialogUpdateCart'
import ConfirmStopLive from '../../components/ConfirmStopLive'
import DialogCloseNotice from '../../components/DialogCloseNotice'

import DialogPrivateSetting from '../../components/DialogPrivateSetting'
import Interactions from '../../components/Interactions'

import PublishedHeader from './components/PublishedHeader'
import PostProducts from './components/PublishedProducts'
import VideoPlayer from './components/VideoPlayer'
import IvsPlayer from './components/IvsPlayer'
import ActionItem from './components/ActionItem'
import PostAnalysisV2 from './components/ShowAnalysisV2'

const STREAM_TYPE = {
  SRS: 'handsup_srs',
  IVS: 'amazon_ivs',
  WANSU: 'wansu'
}

export default {
  name: 'Published',
  components: {
    DialogAddProduct,
    DialogPickProduct,
    DialogPickProductGeneric,
    DialogAddOrder,

    Interactions,
    PostProducts,
    PostComments,
    PostAnalysisV2,
    PublishedHeader,

    DrawAnimation,
    RibbonAnimation,

    DialogUpdateCart,
    DialogSetLiveTime,
    DialogSetPostTime,
    DialogLiveAuthorization,
    DialogSetCover,
    DialogSetRtmp,
    DialogPrivateSetting,
    DialogCloseNotice,
    ConfirmStopLive,
    VideoPlayer,
    IvsPlayer,
    ActionItem
  },
  data () {
    return {
      TABS,
      ...LIVE_STATUS,
      apiPost: new ApiPost(),
      apiProduct: new ApiProduct(),

      dialogAddProductVisible: false,
      dialogAddOrderVisible: false,
      dialogPickProductGenericVisible: false,

      selectedComment: null,
      inputSearchCart: '',
      cart: null,
      cartData: [],

      showActionTooltip: false,
      showExportBuyerListTooltip: false,
      goReplayEdit: false
    }
  },

  computed: {
    ...mapGetters('Lang', ['locale']),
    ...mapGetters('Me', [
      'stores',
      'storeName',
      'currentStore',
      'isEnterprise',
      'isEnterpriseGeneric',
      'enterpriseSlug',
      'enabledPostAnalysis',
      'enabledReplay',
      'enabledCheckoutTime',
      'enabledDialogSetPostTime'
    ]),
    ...mapGetters('Post', ['activeTab', 'isSelfStop']),
    ...mapGetters('Post', ['activities']),
    ...mapGetters('Post', ['hasCurrentBidding']),
    ...mapGetters('Post', ['hasCurrentDraw', 'showDrawAnimation', 'showRibbonAnimation']),
    ...mapGetters('Post', ['blacklist']),
    ...mapGetters('Me/StoreChannels', ['channels']),
    ...mapGetters('Post', ['streamInfo']),
    ...mapGetters('Post', ['celebrityToken', 'postId', 'post', 'publications', 'liveStatus', 'publication', 'isCelebrityEnterprise', 'isCelebrityStoreOwner']),
    ...mapGetters('Post', ['products']),
    ...mapGetters('Post', ['dialogStopLiveVisible', 'dialogCloseNoticeVisible', 'dialogSetCoverVisible', 'dialogSetRtmpVisible', 'dialogSetLiveTimeVisible', 'dialogSetPostTimeVisible', 'dialogLiveAuthorizationVisible', 'dialogPrivateVisible']),

    ...mapGetters('ChatMessage/ChannelNames', {
      groupName: 'getGroupName'
    }),

    ...mapGetters('SettingsChecked', ['paymentShippingChecked']),

    isStoreOwner () {
      return this.stores.some(store => {
        if (store.pivot && store.pivot.type !== 'owner') return false

        const postAgentSlug = this.post.store && this.post.store.slug

        if (store.slug === postAgentSlug) return true
      })
    },

    fanName () {
      // 若使用網紅授權開播，所以抓取第一個 Channel Name 視為粉絲名稱
      return _.get(this.post, 'channels[0].name', '')
    },

    videoComponent () {
      return {
        [STREAM_TYPE.SRS]: 'VideoPlayer',
        [STREAM_TYPE.WANSU]: 'VideoPlayer',
        [STREAM_TYPE.IVS]: 'IvsPlayer'
      }[this.streamInfo && this.streamInfo.type] || 'VideoPlayer'
    }
  },

  created () {
    this.detectObs()

    this.showActionTooltip = !localStorage.getItem('hideActionTooltip')
    this.showExportBuyerListTooltip = !localStorage.getItem('hideExportBuyerListTooltip')
  },

  async mounted () {
    this.setLoading(true)

    // 必要資訊
    try {
      if (this.liveStatus !== LIVE_STATUS.LIVE_STOPPED) {
        await Promise.all([
          this.fetchActivities(this.postId),
          this.fetchStreamInfo(this.postId)
        ])
      } else {
        await Promise.all([
          this.fetchActivities(this.postId),
          this.fetchReplayInfo(this.postId)
        ])
      }
    } catch (error) {
      console.error(error)
    }

    this.setLoading(false)

    // Pubnub 留言
    this.connectSocketIO()

    // 其他資訊
    this.fetchAnalysis(this.postId)
    this.fetchViewersCount(this.postId)
    this.fetchBlacklist(this.storeName)
    this.fetchChannels(this.storeName)

    // 非直播結束 且 非網紅授權 不顯示活動
    if (this.liveStatus !== LIVE_STATUS.LIVE_STOPPED && !this.celebrityToken) {
      this.checkAuctionActivity()
      this.checkDrawActivity()
      this.checkBiddingActivity()
    }
  },

  beforeDestroy () {
    if (!this.goReplayEdit) {
      this.resetPostData()
    }
    this.unsubscribe({ channelGroup: this.groupName })
    this.resetMessages()
  },
  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('Post', ['updateActiveTab', 'updateSelfStop', 'checkStopPost', 'stopPost']),
    ...mapActions('Post', ['fetchAll']),
    ...mapActions('Post', ['fetchActivities']),
    ...mapActions('Post', ['fetchStreamInfo', 'fetchReplayInfo']),
    ...mapActions('Post', ['connectSocketIO']),
    ...mapActions('Post', ['fetchViewersCount', 'updatePubnubViewersCount']),
    ...mapActions('Post', ['checkBiddingActivity', 'getBiddingWinners']),
    ...mapActions('Post', ['checkAuctionActivity', 'setSelectAuction', 'updateDialogAuctionVisible']),
    ...mapActions('Post', ['checkDrawActivity', 'toggleDrawForm', 'setSelectDraw']),
    ...mapActions('Post', ['fetchAnalysis']),
    ...mapActions('Post', ['fetchBlacklist']),
    ...mapActions('Post', ['fetchPost', 'resetPostData']),
    ...mapActions('Me/StoreChannels', ['fetchChannels']),
    ...mapActions('Post', ['pickProduct', 'updateOriginalProduct', 'updatePostProduct', 'setSellingProduct']),
    ...mapActions('Post', ['toggleDialogStopLive', 'toggleDialogCloseNotice', 'toggleDialogSetCover', 'toggleDialogSetRtmp', 'toggleDialogSetLiveTime', 'toggleDialogSetPostTime', 'toggleDialogLiveAuthorization', 'toggleDialogPrivate', 'resetDialogVisible']),
    ...mapActions('Post', ['downloadPostBuyerList']),
    ...mapActions('Pubnub', ['unsubscribe']),
    ...mapActions('ChatMessage/Messages', [ 'resetMessages' ]),

    detectObs () {
      if (!this.$route.query.obs) return

      this.$router.replace(this.$route.path)

      this.$confirm(
        `<h5>${this.$t('Post.Show.message.obs.confirm')}<br>
          ${this.$t('Post.Show.message.obs.confirm_2')}</h5>`, '',
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t('Post.Show.message.obs.btn.confirm'),
          cancelButtonText: this.$t('Post.Show.message.obs.btn.cancel'),
          center: true,
          confirmButtonClass: 'w-40',
          cancelButtonClass: 'w-40'
        })
        .then(() => {
          this.toggleDialogSetRtmp(true)
        }).catch(() => {})
    },

    triggerActionTooltip () {
      localStorage.setItem('hideActionTooltip', 'true')
      this.showActionTooltip = false
    },

    triggerExportBuyerListTooltip () {
      localStorage.setItem('hideExportBuyerListTooltip', 'true')
      this.showExportBuyerListTooltip = false
    },

    handleDialogAddProduct () {
      this.dialogAddProductVisible = true
    },
    handleDialogPickProduct () {
      if (this.isEnterprise) {
        // 企業通用版
        this.dialogPickProductGenericVisible = true
      } else {
        this.$refs['DialogPickProduct'].toggle(true)
      }
    },

    async querySearchAsync (input, cb) {
      if (!input) {
        cb()
      } else {
        const result = await this.apiPost.searchPostCart(this.postId, input)
        cb(result)
      }
    },

    async handleDeleteProduct (item) {
      const success = await this.apiProduct.deletePostProduct(this.postId, item.id)

      if (success) {
        this.$message({
          type: 'success',
          message: this.$t('Post.Show.message.delete_success'),
          duration: 2000,
          showClose: true
        })

        this.fetchPost()
      }
    },
    handleUpdateProduct (item) {
      this.setLoading(true)

      Promise.all([
        this.updateOriginalProduct(item),
        this.updatePostProduct(item)
      ])
        .then(() => {
          this.fetchAll()
        })
        .catch(err => {
          const errMessage = _.get(err, 'data.message', '')
          this.$message.error(errMessage)
        })
        .finally(() => {
          this.setLoading(false)
        })
    },

    showDialogAddOrder (comment) {
      this.selectedComment = comment
      this.dialogAddOrderVisible = true
    },
    tabClick (e) {
      this.$refs.PostAnalysis.resizeCharts()
    },

    showActivityDialog (activity) {
      if (!activity) return

      // 判斷目前有無正在進行的活動
      const isActivating = this.checkActivating()

      if (isActivating) {
        this.$message({
          type: 'warning',
          message: this.$t('Post.Show.message.activating'),
          duration: 2000,
          showClose: true
        })
      } else {
        // 開啟 Activity
        const activityMapping = {
          [ACTIVITY_TYPE.DRAW]: this.openActivityDraw,
          [ACTIVITY_TYPE.AUCTION]: this.openActivityAuction,
          [ACTIVITY_TYPE.BIDDING]: this.openActivityBidding
        }

        activityMapping[activity.type](activity)
      }
    },
    checkActivating () {
      return this.hasCurrentBidding || this.hasCurrentDraw
    },
    openActivityDraw (activity) {
      this.setSelectDraw(activity.id)

      if (activity.status !== ACTIVITY_STATUS.DONE) {
        this.toggleDrawForm(true)
      }
    },
    openActivityAuction (activity) {
      this.setSelectAuction(activity.id)
      this.updateDialogAuctionVisible(true)
    },
    openActivityBidding (activity) {
      this.getBiddingWinners(activity.id)
    },

    async updateOrder (data) {
      if (!data) {
        this.cart = null
        return
      }

      const success = await this.apiPost.putCart(this.postId, this.cart.cart_id, data)
      if (success) {
        this.$message({ type: 'success', message: this.$t('Post.Show.message.update_success') })
        this.cart = null
      }
    },
    async selectCart (item) {
      const data = await this.apiPost.getCart(this.postId, item.cart_id)
      if (data) {
        this.cartData = data
        this.cart = item
        this.inputSearchCart = ''
      }
    },

    goReplayEditSignal () {
      this.goReplayEdit = true
    },

    changeTab (tab) {
      this.updateActiveTab(tab)
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss" scoped></style>

<style lang="scss">
.update-reminder.el-tooltip__popper {
  background: #03aaf4;
  color: #fff;

  a:not([href]):not([tabindex]) {
    text-decoration: underline;
    color: inherit;
  }

  &.el-tooltip__popper[x-placement^="left"] .popper__arrow{
    border-left-color: #03aaf4;

    &:after{
      border-left-color: #03aaf4;
    }
  }

  &.el-tooltip__popper[x-placement^="bottom"] .popper__arrow{
    border-bottom-color: #03aaf4;

    &:after{
      border-bottom-color: #03aaf4;
    }
  }
}
</style>
