<script>
import Cookies from 'js-cookie'
import { mapGetters, mapActions } from 'vuex'

import * as SHIPPING_TYPES from '@/constants/shippingTypes'
import { INIT } from '@/constants/shippingStatus'

import * as PAYMENT_STATUS from '@/constants/paymentStatus'
import * as PAYMENT_TYPES from '@/constants/paymentTypes'
import * as REGIONS from '@/constants/regions'

import ApiOrder from '@/api/order'
import ApiCart from '@/api/cart'
import ApiChannel from '@/api/channel'
import ApiPayment from '@/api/payment'
import ApiShipping from '@/api/shipping'
import ApiPaymentSetting from '@/api/paymentSetting'

import orderNormalizer from '@/utils/orderNormalizer'
import getSocialIcon from '@/utils/getSocialIcon'

import Pagination from '@/components/Pagination'
import SimpleDialog from '@/components/SimpleDialog'
import DialogImportData from '@/components/DialogImportData'
import DialogOfflineAtm from '@/components/DialogOfflineAtm'
import GmoDeferredStatus from '@/views/Order/components/GmoDeferredStatus'
import DialogExportData from './components/DialogExportData'
import DialogSendNotification from './components/DialogSendNotification'

const TABS = {
  CART: 'cart',
  ALL: 'all',
  UNPAID: 'unpaid',
  UNDELIVERED: 'undelivered',
  DELIVERING: 'delivering',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
  RETURNING: 'returning'
}

const ORDER_COMPLETE_TYPE = {
  AUTOMATIC: 'automatic',
  MANUAL: 'manual'
}

export default {
  name: 'List',
  components: {
    Pagination,
    SimpleDialog,
    DialogImportData,
    DialogExportData,
    DialogSendNotification,
    DialogOfflineAtm,
    GmoDeferredStatus
  },
  data () {
    return {
      popoverVisible: false,
      INIT,
      ...SHIPPING_TYPES,
      PAYMENT_STATUS,
      TABS,
      ORDER_COMPLETE_TYPE,

      dialogImportDataVisible: false,
      dialogSendNotificationVisible: false,
      apiCart: new ApiCart(),
      apiOrder: new ApiOrder(),
      tabs: [
        {
          label: 'Order.List.tabs.cart',
          name: TABS.CART,
          count: 0
        },
        {
          label: 'Order.List.tabs.all',
          name: TABS.ALL,
          count: 0
        },
        {
          label: 'Order.List.tabs.unpaid',
          name: TABS.UNPAID,
          count: 0
        },
        {
          label: 'Order.List.tabs.undelivered',
          name: TABS.UNDELIVERED,
          count: 0
        },
        {
          label: 'Order.List.tabs.delivering',
          name: TABS.DELIVERING,
          count: 0
        },
        {
          label: 'Order.List.tabs.completed',
          name: TABS.COMPLETED,
          count: 0
        },
        {
          label: 'Order.List.tabs.canceled',
          name: TABS.CANCELED,
          count: 0
        },
        {
          label: 'Order.List.tabs.returning',
          name: TABS.RETURNING,
          count: 0
        }
      ],
      activeTab: TABS.ALL,
      activeOrder: null,
      activePageName: null,
      channels: [],
      paginator: null,
      filterDateRange: null,
      filter: {
        keyword: null,
        selected: {
          from: null,
          to: null,
          paymentType: null,
          shippingType: null,
          channel: null
        },
        paymentTypeOptions: [],
        shippingTypeOptions: [],
        channelOptions: [],
        perPage: 15
      },
      selectedOrder: null,
      exportDateRang: null,
      isAppIntroduceVisible: false,
      rawData: null,
      dialogOfflineAtmVisible: false,
      paymentSettings: [],
      ignoreOverpayment: JSON.parse(localStorage.getItem('ignoreOverpayment')) || []
    }
  },
  computed: {
    ...mapGetters('Me', ['storeName', 'currentStore', 'currencySymbol', 'region', 'showInstructionLink', 'apiUrl', 'enabledParentStore']),
    ...mapGetters('OrderList', ['orderPageState']),
    tableData () {
      if (!this.rawData || this.rawData.length <= 0) return []

      const tableType = this.rawData[0].slug ? 'order' : 'cart'
      return this.rawData.map(row => orderNormalizer(tableType, row))
    },
    showBatchApprove () {
      return this.activeTab === TABS.UNPAID && this.paymentSettings.some(setting => {
        return setting.type === PAYMENT_TYPES.OFFLINE_ATM && setting.enabled
      })
    },
    showBatchShipping () {
      return this.activeTab === TABS.UNDELIVERED && this.region !== REGIONS.JP
    },
    importUploadPath () {
      return `${this.apiUrl}/api/v1/shippings/import`
    }
  },
  async created () {
    // 取得目前的 tab 及 filter 的條件
    this.activeTab = this.orderPageState.tabName || this.activeTab
    this.filter = _.cloneDeep(this.orderPageState.filter)
    if (this.filter.selected.from && this.filter.selected.to) {
      this.filterDateRange = [
        new Date(this.filter.selected.from),
        new Date(this.filter.selected.to)
      ]
    }

    // 搜尋的 Select Option
    this.loadChannel()
    this.loadPaymentType()
    this.loadShippingType()

    // 取得 資料
    this.fetchData()
    this.fetchPaymentSettings()
    this.showTip()
  },
  beforeDestroy () {
    // 紀錄目前的 tab 及 filter 的條件
    if (this.isGoingToOrderDetail) {
      this.updateOrderPageState({
        tabName: this.activeTab,
        page: this.paginator.data().meta.current_page,
        filter: this.filter
      })
    } else {
      this.resetOrderPageState()
    }
  },
  methods: {
    ...mapActions(['setLoading']),
    ...mapActions('OrderList', ['updateOrderPageState', 'resetOrderPageState']),

    getSocialIcon,
    async fetchData () {
      this.setLoading(true)

      // 搜尋條件
      const activeTab = this.activeTab
      const orderStatus = activeTab === TABS.CART || activeTab === TABS.ALL ? null : this.activeTab
      const orderPage = this.orderPageState.page || 1
      const orderFrom = this.filterDateRange && this.filterDateRange[0]
      const orderTo = this.filterDateRange && this.filterDateRange[1]
      const orderChannel = this.filter.selected.channel !== 'manual' ? this.filter.selected.channel : undefined
      const orderPaymentType = this.filter.selected.paymentType
      const orderShippingType = this.filter.selected.shippingType
      const orderKeyword = this.filter.keyword
      const perPage = this.filter.perPage
      const isManual = this.filter.selected.channel === 'manual' ? true : undefined

      // 購物車 & 訂單 資料
      const cartData = await this.apiCart.list({
        page: orderPage,
        from: orderFrom,
        to: orderTo,
        channel_id: orderChannel,
        keyword: orderKeyword,
        per_page: perPage
      })

      const orderData = await this.apiOrder.list({
        status: orderStatus,
        page: orderPage,
        from: orderFrom,
        to: orderTo,
        payment_type: orderPaymentType,
        shipping_type: orderShippingType,
        channel_id: orderChannel,
        keyword: orderKeyword,
        per_page: perPage,
        is_manual: isManual
      })

      // 頁籤 & 分頁器
      this.paginator = activeTab === TABS.CART ? this.apiCart.paginator : this.apiOrder.paginator
      const cartMeta = this.apiCart.pagination.meta
      const orderMeta = this.apiOrder.pagination.meta

      // 計算 頁籤 之 count 數
      this.normalizeTabs(cartMeta, orderMeta)

      // Table
      this.rawData = activeTab === TABS.CART ? cartData : orderData
      this.setLoading(false)
    },

    async fetchPaymentSettings () {
      const apiPaymentSetting = new ApiPaymentSetting()
      this.paymentSettings = await apiPaymentSetting.list({}, 'all')
    },

    showTip () {
      const currentToken = Cookies.get('api_token')
      const lastToken = localStorage.getItem('lastTokenPending')

      if (currentToken === lastToken) {
        this.isAppIntroduceVisible = false
      } else {
        this.isAppIntroduceVisible = true
      }
    },
    closeTip () {
      this.isAppIntroduceVisible = false
      const currentToken = Cookies.get('api_token')
      localStorage.setItem('lastTokenPending', currentToken)
    },
    clearSearch () {
      this.filter = Object.assign(this.filter, {
        keyword: null,
        selected: {
          from: null,
          to: null,
          paymentType: null,
          shippingType: null,
          channel: null
        }
      })
      this.filterDateRange = null

      this.fetchData()
    },
    async loadChannel () {
      // Channel 清單
      const apiChannel = new ApiChannel()
      const { data: channels } = await apiChannel.list({}, true)
      if (!channels) return
      this.channels = channels
      this.filter.channelOptions = this.channels.map((channel) => {
        let obj = {
          value: channel.id,
          label: channel.name
        }
        if (channel.type === 'handsup') obj.label = 'HandsUp'
        return obj
      })
      // 後台建立選項
      this.filter.channelOptions = this.filter.channelOptions.concat({
        value: 'manual',
        label: this.$t('OrderManual.filter.manual_created_order')
      })
    },
    async loadPaymentType () {
      const apiPayment = new ApiPayment()
      const paymentTypes = await apiPayment.getTypes(this.storeName)
      if (!paymentTypes) return

      this.filter.paymentTypeOptions = paymentTypes.map((option) => ({
        label: this.$t(`payment.type.${option}`),
        value: option
      }))
    },
    async loadShippingType () {
      const apiShipping = new ApiShipping()
      const shippingTypes = await apiShipping.getTypes(this.storeName)
      if (!shippingTypes) return

      this.filter.shippingTypeOptions = shippingTypes.types.map((option) => ({
        label: this.$t(`shipping.type.${option}`),
        value: option
      }))
    },
    normalizeTabs (cartMeta, orderMeta) {
      this.tabs = this.tabs.map(tab => {
        return tab.name === TABS.CART
          ? Object.assign(tab, { count: cartMeta.total })
          : Object.assign(tab, { count: orderMeta[`${tab.name}_count`] || 0 })
      })
    },
    updateRows (rows) {
      this.rawData = rows
    },
    handleChangeTab () {
      this.fetchData()
    },
    toOrderDetail (id) {
      let path = `/${this.storeName}/order/${id}`

      if (this.activeTab === TABS.CART) {
        path += '?cart=true'
      }

      this.isGoingToOrderDetail = true

      this.$router.push({ path })
    },
    shouldShowAmountPayable (order) {
      return order.amountPayable &&
        order.paymentStatus !== 'paid' &&
        order.status !== 'canceled'
    },
    showShipmentStatus (order) {
      this.selectedOrder = order

      this.$refs.shipmentStatus.toggle()
    },
    hsinchuPrint () {
      const path = `/${this.storeName}/hsinchu-print`
      window.open(path, '_blank')
    },
    async handleExportOrderExcel (isWithDescendants = false) {
      this.setLoading(true)

      this.popoverVisible = false

      // 搜尋條件
      const orderFrom = this.filterDateRange && this.filterDateRange[0]
      const orderTo = this.filterDateRange && this.filterDateRange[1]
      const orderChannel = this.filter.selected.channel
      const orderPaymentType = this.filter.selected.paymentType
      const orderShippingType = this.filter.selected.shippingType
      const orderKeyword = this.filter.keyword

      const params = {
        from: orderFrom,
        to: orderTo,
        payment_type: orderPaymentType,
        shipping_type: orderShippingType,
        keyword: orderKeyword
      }

      if (orderChannel === 'manual') {
        params.is_manual = 1
      } else {
        params.channel_id = orderChannel
      }

      if (isWithDescendants) {
        params.with_descendants = 1
      }

      const apiOrder = new ApiOrder()
      await apiOrder.downloadAllOrder(params)
      // [Facebook Pixel] 追蹤訂單點擊Tab事件
      this.$analytics.fbq.event('ClickReg', {
        Page: 'Merchant-Platform',
        Content: 'Order',
        Target: 'Order-Export'
      })
      this.$ga.event({
        eventCategory: 'Bottom-Click',
        eventAction: 'Order-Export',
        eventLabel: 'Merchant-Platform'
      })

      this.setLoading(false)
    },
    toBatch () {
      const path = `/${this.storeName}/batch-shipping`
      this.$router.push(path)
    },
    toApprove () {
      const path = `/${this.storeName}/batch-approve`
      window.open(path, '_blank')
    },
    async handleSendNotifiction (order) {
      this.activeOrder = order

      this.setLoading(true)

      try {
        if (this.activeTab === 'cart') {
          await this.apiOrder.sendCartNotification(this.activeOrder.id)
        } else {
          await this.apiOrder.sendOrderNotification(this.activeOrder.id)
        }
        this.fetchData()
        this.$message.success(this.$t('Order.List.message.send_success'))
      } catch (e) {
        if (e.data.type === 'Facebook\\Exceptions\\FacebookResponseException' &&
      e.data.message.includes('551')) {
          this.$msgbox({
            title: '',
            center: true,
            shoewClose: true,
            customClass: 'facebookError',
            message: this.$t('Order.List.message.notification'),
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.$t('Order.List.message.understand')
          })

          console.error(e)
        }
      }

      this.setLoading(false)
      this.dialogSendNotificationVisible = false
    },
    isOfflineAtm (order) {
      return order.paymentType === PAYMENT_TYPES.OFFLINE_ATM
    },
    handleOfflineAtm (order) {
      this.selectedOrder = order
      this.dialogOfflineAtmVisible = true
    },
    async handleBankReceipt (payload) {
      this.setLoading(true)
      try {
        await this.apiOrder.putBankReceipts(this.selectedOrder.id, payload.bankReceipt)
        const reviewPayload = {
          order_ids: [this.selectedOrder.id],
          payment_status: payload.paymentStatus
        }
        await this.apiOrder.putOfflineAtmReview(this.storeName, reviewPayload)
        this.fetchData()
      } catch (e) {
        this.$message.error(this.$t('message.save_fail'))
      }
      this.setLoading(false)
      this.dialogOfflineAtmVisible = false
    },
    showOverpaymentTip (order) {
      // 當溢付金額大於 0 ，代表溢付
      return !this.ignoreOverpayment.includes(order.id) &&
        Number(order.overpayment) > 0
    },
    toOrderManual () {
      this.$router.push({ path: `/${this.storeName}/order/manual` })
    }
  }
}
</script>

<template lang="pug" src="./template.pug"></template>
<style lang="scss" src="./style.scss"></style>
