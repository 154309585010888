import i18n from '@/plugins/i18n'

/**
 * Only for element-ui form invoice validation
 * 發票資訊非必填, 因此有值才做檢查
 * @see https://element.eleme.io/#/en-US/component/form#form
 */
export function validateInvoice (rule, value, callback) {
  const invoicePattern = /^[a-zA-Z]{2}-?[0-9]{8}$/g
  if (value && !invoicePattern.test(value)) {
    const errorMessage = i18n.t('Order.DialogShipmentFreight.form.invoice_number_invalid')
    callback(new Error(errorMessage))
  } else {
    callback()
  }
}
