<script>
import ContainerInfo from './components/ContainerInfo'
import ContainerSetting from './components/ContainerSetting'

export default {
  name: 'Information',

  components: { ContainerInfo, ContainerSetting }

}
</script>

<template lang="pug" src="./Information.pug"></template>
