import Pubnub from 'pubnub'

const pubnub = new Pubnub({
  subscribeKey: process.env.VUE_APP_PUBNUB_SUBSCRIBE_KEY
})

export const fetchListOfChannels = groupName => (
  new Promise((resolve, reject) => {
    pubnub
      .channelGroups
      .listChannels({ channelGroup: groupName }, (status, channels) => {
        // @TODO what is status? we should reject this promise
        // if status is wrong.
        resolve(channels)
      })
  })
)

/**
 * Fetches historical comments via pubnub API. Notice that this function will need to know the last retrieval token
 * for each channel in order to retrieve the next sector of historical messages. retrieval token is stored in
 * "chanOffsetMap". Moreover, this function determines whether a given channel has reached the end of the message list
 * by supplying "channelHasReachedTop".
 *
 *  chanOffsetMap:
 *  {
 *    [CHANNEL_ONE]: 15xxxxxx, ---> time token we'll be using to load the next sector from the history
 *    [CHANNEL_TWO]: 15xxxxxx,
 *  }
 *
 *  hasReachTopMap:
 *  {
 *    [CHANNEL_ONE]: true, ---> This channel reaches the end in the history
 *    [CHANNEL_TWO]: false, ---> There are more in the history to load
 *  }
 * @param {Array} channels - list of channels to fetch history from.
 * @param {Object} chanOffsetMap - map of channel to it's last retrieval time token.
 * @return {Array} [messages, timeTokenMap, hasReachTopMap]
 */
export const fetchHistoryFromAllChannels = (channels, chanOffsetMap = {}) => {
  const promises = channels.map(channel => (
    pubnub.history({
      channel,
      count: 100,
      start: chanOffsetMap[channel]
    })
  ))

  return Promise.all(promises)
    .then(chans => {
      const messages = chans.reduce((accu, curr) => accu.concat(curr.messages), [])

      const timeTokenMap = chans.reduce((accu, curr, index) => {
        const channelName = channels[index]

        accu[channelName] = curr.startTimeToken
        return accu
      }, {})

      const hasReachTopMap = chans.reduce((accu, curr, index) => {
        const channelName = channels[index]

        accu[channelName] = curr.messages.length < 100

        return accu
      }, {})

      return [messages, timeTokenMap, hasReachTopMap]
    })
}
