<script>
import { mapActions, mapGetters } from 'vuex'

import ConfirmLeave from '@/components/ConfirmLeave'

import ProductFormName from './components/ProductFormName'
import ProductFormSku from './components/ProductFormSku'
import ProductFormOther from './components/ProductFormOther'

import ConfirmAddProduct from './components/ConfirmAddProduct'
import ConfirmSuccess from './components/ConfirmSuccess'
import ProductFormImageSortable from './components/ProductFormImageSortable'

export default {
  name: 'ProductDetail',

  components: {
    ConfirmLeave,
    ProductFormName,
    ProductFormSku,
    ProductFormOther,
    ConfirmAddProduct,
    ConfirmSuccess,
    ProductFormImageSortable
  },

  data () {
    return {
      key: '',
      isBtnLoading: false,
      isSave: false,
      confirmLeaveVisible: false,
      confirmAddProductVisible: false,
      confirmSuccessVisible: false
    }
  },

  computed: {
    ...mapGetters('ProductDetail', ['editProduct', 'id'])
  },

  mounted () {
    this.key = this.id || new Date().getTime()
  },

  beforeDestroy () {
    this.resetEditProduct()
  },

  methods: {
    ...mapActions('ProductList', [
      'setCurrentPage'
    ]),
    ...mapActions('ProductDetail', [
      'setEditProduct',
      'resetEditProduct',
      'createProduct',
      'updateProduct',
      'updateId'
    ]),

    comeback () {
      if (this.isSave) {
        this.toProductList()
      } else {
        this.confirmLeaveVisible = true
      }
    },

    toProductList () {
      this.$router.push({ name: 'ProductList' })
    },

    async validateFormName () {
      try {
        await this.$refs.ProductFormName.validateForm()
        return true
      } catch (error) {
        return false
      }
    },

    validateFormSku () {
      try {
        return this.$refs.ProductFormSku.validateForm()
      } catch (error) {
        return false
      }
    },

    validateFormOther () {
      try {
        return this.$refs.ProductFormOther.validateForm()
      } catch (error) {
        return false
      }
    },

    submit: _.debounce(async function () {
      const [validateName, validateSku, validateOther] = await Promise.all([
        this.validateFormName(),
        this.validateFormSku(),
        this.validateFormOther()
      ])

      if (!validateName || !validateSku || !validateOther) return

      this.isBtnLoading = true

      try {
        if (this.editProduct) {
          await this.updateProduct(this.id)

          this.confirmSuccessVisible = true
        } else {
          const { data } = await this.createProduct()

          this.setEditProduct(data)
          this.updateId(data?.id)
          this.key = data?.id
          this.confirmAddProductVisible = true

          // 商品列表頁數回至第一頁
          this.setCurrentPage(1)
        }

        this.isSave = true
      } catch (err) {
        console.error(err)
      }

      this.isBtnLoading = false
    }, 500),

    createNewProduct () {
      this.resetEditProduct()
      this.key = new Date().getTime()
      this.isSave = false
      this.confirmAddProductVisible = false
    }
  }
}
</script>

<template lang="pug" src="./ProductDetail.pug"></template>
