if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    for (let registration of registrations) {
      registration.unregister()
    }
  })
}

window.caches &&
  caches.keys &&
  caches.keys().then(keyList => {
    return Promise.all(
      keyList.map(key => {
        return caches.delete(key)
      })
    )
  })
